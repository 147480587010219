import { gql, useApolloClient, useQuery } from '@apollo/client';
import { AttachmentForm } from 'components/attachment';
import { Textarea } from 'components/rsuite';
import { parseISO } from 'date-fns';
import { GET_CUSTOMER } from 'gql/customer';
import { getEnv } from 'lib/env';
import { useQueryString } from 'lib/hooks';
import { startCase } from 'lodash';
import React, { useContext, useEffect, useRef, useState } from 'react';
import TextareaAutosize from 'react-autosize-textarea';
import { useRouteMatch } from 'react-router-dom';
import { Form, Loader, Panel, SelectPicker, Input, Radio, Grid, Col, DatePicker, Row } from 'rsuite';
import { ApplicationContext, DrawerFormWrapper } from 'shared';
import { useViewport } from 'shared/ViewportProvider';
import { v4 as uuidv4 } from 'uuid';

const GET_SITE_REVIEW = gql`
  query siteReview($guid: ID!) {
    siteReview(guid: $guid) {
      review
      userId
      dueDate
      templateId
      attachments {
        guid
        fileName
        filePath
        fileId
        fileGroup
        visibility
        type
        description
      }
    }
  }
`;

interface ISiteReviewForm {
  drawer?: boolean,
  show?: boolean,
  guid?: any,
  action?: any,
  customerId?: number,
  onHide?: () => void,
  onUpdate?: (data: any) => void
}

const SiteReviewForm = ({ show, guid, action, drawer, customerId, onHide, onUpdate }: ISiteReviewForm) => {
  let form: any = useRef();
  const container = useRef() as React.MutableRefObject<HTMLDivElement>;
  const match = useRouteMatch();
  const query = useQueryString();
  const client = useApolloClient();
  const { state } = useViewport();
  const { showSuccess, showError, showDrawer } = useContext(ApplicationContext);
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [reviewTemplateGuid, setReviewTemplateGuid] = useState((state.templates || []).filter((t: any) => t.type === 'review')?.[0].guid);
  const [reviewForm, setReviewForm] = useState<any>(undefined);
  const [reviewGuid, setReviewGuid] = useState(guid);
  const [userId, setUserId] = useState<any>(undefined);
  const [dueDate, setDueDate] = useState<any>(undefined);
  const [attachments, setAttachments] = useState<any>([]);
  const [allAttachments, setAllAttachments] = useState<any>([]);
  const customer = useQuery(GET_CUSTOMER, { variables: { id: +(customerId || -1) } });
  const review = guid && useQuery(GET_SITE_REVIEW, { variables: { guid } });
  const readOnly = action === 'view';
  const crews = state.users.filter((u: any) => u.userlevel === 'franchise');

  guid = query?.guid || (match?.params as any)?.guid || guid;
  action = query?.action || action || (match?.params as any)?.action || 'add';

  useEffect(() => {
    (async function getTemplate() {
      try {
        if (reviewTemplateGuid) {
          const data = await client.query({
            query: gql`
              query template($guid: ID) {
                template(guid: $guid) {
                  id
                  guid
                  type
                  key
                  text
                  subject
                  services
                }
              }
            `,
            variables: {
              guid: reviewTemplateGuid
            },
            fetchPolicy: 'no-cache'
          });

          if (!reviewGuid) {
            setReviewForm(data?.data?.template);
            setReviewGuid(uuidv4());
          }
        }
      } catch (err) {
        console.log(err);
      }
    })();
  }, [reviewTemplateGuid, reviewGuid]);

  useEffect(() => {
    if (review?.data?.siteReview) {
      setReviewTemplateGuid(review?.data?.siteReview?.templateId);
      setReviewForm(review?.data?.siteReview?.review);
      setAttachments(review?.data?.siteReview?.attachments);
      setAllAttachments(review?.data?.siteReview?.attachments);
      setUserId(review?.data?.siteReview?.userId);
      setDueDate(review?.data?.siteReview?.dueDate ? parseISO(review?.data?.siteReview?.dueDate) : undefined);
    }
  }, [review]);

  const handleSubmit = async () => {
    setSaving(true);

    const upsertSiteReview = gql`
      mutation upsertSiteReview($input: UpsertSiteReview) {
        upsertSiteReview(input: $input) {
          success
          code
          message
          result
        }
      }
    `;

    const response: any = await client.mutate({
      mutation: upsertSiteReview, variables: {
        input: {
          customerId: customer?.data?.customer?.id,
          review: reviewForm,
          guid: reviewGuid,
          userId: userId,
          dueDate: dueDate,
          attachments
        }
      }
    });

    if (response.data.upsertSiteReview.success) {
      if (onUpdate) {
        onUpdate(null);
      }
      showSuccess(response.data.upsertSiteReview.message);

      if (onHide) {
        onHide();
      }
    } else {
      showError(response.data.upsertSiteReview.message);
      setSaving(false);
    }
  }

  const handleUpdateReview = (guid: string, color: string) => {
    setReviewForm({
      ...reviewForm,
      services: {
        groups: reviewForm.services.groups,
        services: reviewForm.services.services.map((s: any) => {
          if (s.guid === guid && s.color === color) {
            s.color = undefined;
          } else if (s.guid === guid) {
            s.color = color;
          }

          return s;
        })
      }
    });
  }

  const handleUpdateComments = (guid: string, comments: string) => {
    setReviewForm({
      ...reviewForm,
      services: {
        groups: reviewForm.services.groups.map((g: any) => {
          if (g.guid === guid) {
            g.comments = comments;
          }

          return g;
        }),
        services: reviewForm.services.services
      }
    });
  }

  const Wrapper = show === undefined
    ? <div />
    : <DrawerFormWrapper
      loading={saving}
      show={show}
      title={startCase(action) + ' Site Review'}
      guid={guid}
      size={'lg'}
      action={action}
      form={`/app/${getEnv()}/irrigation-system/form/view/${guid}`}
      onHide={onHide}
      onSave={handleSubmit}
    />;

  return React.cloneElement(Wrapper, {
    children: <div>
      {!drawer &&
        <div></div>
      }

      <Panel className="content" ref={container}>
        {loading
          ? <Loader content="Loading..." />
          : <div>
            <div className="mb-16">
              {getEnv() === 'dev' && <div className="mb-16">
                <label>UUID:</label>
                <div>{reviewGuid}</div>
              </div>
              }

              {action === 'edit'
                ? <>
                  <div className="mb-16">
                    <label>Review:</label>
                    <div>
                      {(state.templates || []).find((t: any) => t.guid === reviewTemplateGuid)?.name}
                    </div>
                  </div>
                </>
                : <>
                  {(state.templates || []).filter((t: any) => t.type === 'review').length > 1 &&
                    <div className="mb-16">
                      <Form.ControlLabel>Select Review:</Form.ControlLabel>
                      <div>
                        <SelectPicker
                          data={(state.templates || []).filter((t: any) => t.type === 'review')}
                          labelKey='name'
                          valueKey='guid'
                          cleanable={false}
                          value={reviewTemplateGuid}
                          block
                          onChange={(val: any) => {
                            setReviewTemplateGuid(val);
                            setReviewGuid(undefined);
                          }}
                        />
                      </div>
                    </div>
                  }
                </>
              }

              <div>
                <label>Crew Assigned:</label>
              </div>
              <SelectPicker
                block
                data={crews}
                labelKey='operatorName'
                valueKey='id'
                value={userId}
                onChange={setUserId}
              />
            </div>

            <div className="mb-16">
              <div>
                <label>Due Date:</label>
              </div>
              <DatePicker
                block
                format={'MMM d, yyyy'}
                value={dueDate}
                onChange={setDueDate}
                oneTap
              />
            </div>

            <div>
              <label>Review:</label>
            </div>

            {(reviewForm?.services?.groups || []).map((g: any) => <div className='mb-16' key={g.guid}>
              <div><label>{g.description}</label></div>
              <Grid>

                {(reviewForm?.services?.services.filter((s: any) => s.group_guid === g.guid)).map((s: any) =>
                  <Row key={s.guid}>
                    <Col md={3} xs={8} className='p-0'>
                      <Radio disabled={readOnly} checked={s?.color === 'green'} color="green" className='outline' onClick={() => handleUpdateReview(s.guid, 'green')} />
                      <Radio disabled={readOnly} checked={s?.color === 'orange'} color="orange" className='outline' onClick={() => handleUpdateReview(s.guid, 'orange')} />
                      <Radio disabled={readOnly} checked={s?.color === 'red'} color="red" className='outline' onClick={() => handleUpdateReview(s.guid, 'red')} />
                    </Col>
                    <Col md={21} xs={16} className='p-0'>
                      <div className={'pt-6'}>
                        {s.description}<span> </span>
                        {s?.color === 'green' && <span className='text-green'>Meets or exceeds expectations</span>}
                        {s?.color === 'orange' && <span className='text-orange'>Needs improvement</span>}
                        {s?.color === 'red' && <span className='text-red'>Significant issues; requires immediate attention</span>}
                      </div>
                    </Col>
                  </Row>
                )}
                <Row>
                  <Col xsPush={8} md={21} mdPush={3} className='pl-0 mt-4'>
                    <label>Comments:</label>
                    <Input readOnly={readOnly} 
                      
                      value={g.comments} as={TextareaAutosize} onChange={(val: string) => handleUpdateComments(g.guid, val)} style={{ width: '96%' }} />
                  </Col>
                </Row>
                <Row>
                  <Col md={21} xsPush={8} mdPush={3} className='pl-0 mt-4'>
                    <label>Photos Before:</label>
                    <AttachmentForm
                      preUploadAction='photoResize'
                      viewMode='gallery'
                      showType={false}
                      type={'photo_before'}
                      showVisibility={false}
                      showUploadLargeFile={false}
                      referenceGuid={reviewGuid}
                      fileGroup={g.guid}
                      attachments={attachments}
                      handleTypeFilter={(a: any) => a.fileGroup === g.guid && a.type === 'photo_before'}
                      onChange={(data) => setAttachments(data)}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={21} xsPush={8} mdPush={3} className='pl-0 mt-4'>
                    <label>Photos After:</label>
                    <AttachmentForm
                      preUploadAction='photoResize'
                      viewMode='gallery'
                      showType={false}
                      type={'photo_after'}
                      showVisibility={false}
                      showUploadLargeFile={false}
                      referenceGuid={reviewGuid}
                      fileGroup={g.guid}
                      attachments={attachments}
                      handleTypeFilter={(a: any) => a.fileGroup === g.guid && a.type === 'photo_after'}
                      onChange={(data) => setAttachments(data)}
                    />
                  </Col>
                </Row>
              </Grid>
            </div>
            )}


          </div>
        }
      </Panel>
    </div>
  });
}

export default SiteReviewForm;