import { useContext, useEffect, useState } from 'react';
import { Grid, Row, Col, CheckPicker, SelectPicker, IconButton, Whisper, Tooltip, DatePicker } from 'rsuite';
import { ApplicationContext, DateRangeFilter, InputFilter } from 'shared';
import { DRAWER, ROLE } from 'lib/env';
import { useHistory } from 'react-router-dom';
import queryString from 'query-string';
import store from 'lib/storage';
import { isMobileOnly } from 'react-device-detect';
import { useFilteredParams } from './FilterProvider';
import { CONFIG_APPLICATION_ROLES, CONFIG_STATUS } from 'tenant/config';
import INTL from 'tenant/intl';
import ColumnChooser from 'components/table/ColumnChooser';
import { useViewport } from './ViewportProvider';
import { filterFieldsByParentKey } from 'lib/helpers/field';
import { parseISO } from 'lib/date';
import { isMultiCrew, IS_KALADI_KITCHENS, IS_KALADI_PROPERTIES } from 'lib/tenant';
import { usePrairieAuth } from 'contexts/AuthPrairieProvider';
import { useLocalStorage } from 'lib/hooks';
import { addMonths, format } from 'date-fns';
import { MdFilterAlt, MdFlag, MdSort } from 'react-icons/md';
import { orderBy } from 'lodash';
import { arrayRange } from 'lib/helpers/customer';

interface IFilter {
  id: string,
  dateRange?: [Date?, Date?],
  dateRangeCleanable?: boolean | undefined,
  dateRangeColumns?: Array<{ [label: string]: string }> | undefined,
  width?: number,
  onChange: (filter: any, order?: any) => void,
  textFilter?: string,
  applicationFilter?: string[]
  jobFilter?: string[],
  jobStatusFilter?: string | null,
  workOrderStatusFilter?: string | null,
  crewFilter?: number[],
  crewFilterCleanable?: boolean | undefined,
  paymentStatusFilter?: string | null,
  documentTypeFilter?: string | null,
  customerLabelFilter?: string | null,
  recurrenceFilter?: string | null,
  squareFootageFilter?: string[],
  documentStatusFilter?: string | undefined,
  showPaymentStatus?: boolean | null,
  showJobStatus?: boolean | null,
  showDateFilter?: boolean | undefined,
  showDateRangeFilter?: boolean | undefined,
  showMultipleCrewFilter?: boolean | undefined,
  showGroupFilter?: boolean | undefined,
  showCrewFilter?: boolean | undefined,
  showTextFilter?: boolean | undefined,
  showDocumentStatusFilter?: boolean | undefined,
  showDocumentTypeFilter?: boolean | undefined,
  showDocumentTemplateFilter?: boolean | undefined,
  showCustomerLabelFilter?: boolean | undefined,
  showCompletedByFilter?: boolean | undefined,
  showTrackingFlagFilter?: boolean | undefined,
  showColumnChooser?: boolean | undefined,
  showRecurrenceFilter?: boolean | undefined,
  showEndingFilter?: boolean | undefined,
  showWorkOrderStatusFilter?: boolean | undefined,
  showUserRoleFilter?: boolean | undefined,
  showSquareFootageFilter?: boolean | undefined,
  showSiteReviewTypeFilter?: boolean | undefined,
  clearJobs?: () => void,
  children?: any,
  includeFilterByUnassignedCrew?: boolean
  showEmailEventFilter?: boolean | undefined,
  showEmailEventTypeFilter?: boolean | undefined,
  tableDataRef?: any,
  showAllSearchfilters?: boolean | undefined
};

interface ISearchFilter {
  date?: Date,
  range?: [Date?, Date?],
  rangeColumn?: string,
  applications?: string[],
  jobs?: string[],
  crew?: number[],
  jobStatus?: string,
  workOrderStatus?: string,
  paymentStatus?: string,
  documentType?: string,
  customerLabel?: string[],
  documentStatus?: string[],
  listType?: string,
  query?: string,
  recurrence?: string,
  emailEvent?: string,
  emailEventType?: string,
  endingPeriod?: string,
  completedBy?: number[],
  documentTemplate?: string[],
  trackingFlag?: string[],
  squareFootage?: string[],
  siteReview?: string
}

let timeout: NodeJS.Timeout;

const Filter = ({
  id,
  dateRange,
  dateRangeCleanable,
  width,
  onChange,
  textFilter,
  applicationFilter,
  jobFilter,
  jobStatusFilter,
  workOrderStatusFilter,
  squareFootageFilter,
  crewFilter,
  crewFilterCleanable,
  paymentStatusFilter,
  documentTypeFilter,
  documentStatusFilter,
  customerLabelFilter,
  recurrenceFilter,
  children,
  showMultipleCrewFilter,
  showGroupFilter,
  showCrewFilter,
  showJobStatus,
  showSiteReviewTypeFilter,
  showPaymentStatus,
  showDocumentStatusFilter,
  showDocumentTypeFilter,
  showDocumentTemplateFilter,
  showCustomerLabelFilter,
  showCompletedByFilter,
  showDateFilter,
  showDateRangeFilter,
  showTextFilter,
  showColumnChooser,
  showEndingFilter,
  showRecurrenceFilter,
  showUserRoleFilter,
  dateRangeColumns,
  clearJobs,
  includeFilterByUnassignedCrew,
  showEmailEventFilter,
  showEmailEventTypeFilter,
  showWorkOrderStatusFilter,
  showSquareFootageFilter,
  showTrackingFlagFilter,
  showAllSearchfilters,
  tableDataRef
}: IFilter) => {
  const history = useHistory();
  const { isRole } = usePrairieAuth();
  const { params } = useFilteredParams(id);
  const { state } = useViewport();
  const { showDrawer } = useContext(ApplicationContext);
  const [showFilter, setShowFilter] = useState(showAllSearchfilters ? showAllSearchfilters : !isMobileOnly);
  const [showColumnChooserDrawer, setShowColumnChooserDrawer] = useState(false);
  const [selectedCrew, setSelectedCrew] = useState<any>(params.crew);
  const [selectedJobStatus, setSelectedJobStatus] = useState<any>(Array.isArray(params.jobStatus) ? params.jobStatus : [params.jobStatus]);
  const [selectedDocumentTemplate, setSelectedDocumentTemplate] = useState<any>(params.documentTemplate);
  const [selectedDocumentStatus, setSelectedDocumentStatus] = useState<any>(params.documentStatus);
  const [selectedTrackingFlag, setSelectedTrackingFlag] = useState<any>(params.trackingFlag);
  const [selectedSquareFootage, setSelectedSquareFootage] = useState<any>(params.squareFootage);
  const [completedByCrew, setCompletedByCrew] = useState<any>(params.completedBy);
  const workGroupTypes = filterFieldsByParentKey(state.fields, 'work_groups');
  const [showMoreFilters, setShowMoreFilters] = useLocalStorage('show-more-filters', false);
  const showMoreSelector = showRecurrenceFilter || showEndingFilter || showCustomerLabelFilter || showDocumentTypeFilter || showWorkOrderStatusFilter
    || showCompletedByFilter || showTrackingFlagFilter;
  const order = JSON.parse(store.session.get(`${id}-order`, '[]'));
  const trackingFlags = state.fields.filter((s: any) => s.parentKey === 'tracking_flags');

  useEffect(() => {
    if (showMoreFilters === false) {
      setShowMoreFilters(params.customerLabel.length > 0 || params.recurrence.length > 0 || params.endingPeriod.length > 0);
    }
  }, []);

  const handleDateRangeChange = (range: [Date?, Date?]) => {
    onChange({ ...params, range, activeRange: range });
    updateQueryParams({ range });
  };

  const handleDateRangeClean = () => {
    store.session.set(`${id}-active-range-filter`, '[]');
    onChange({ ...params, activeRange: [] });
  };

  const handleDateRangeColumnChange = (rangeColumn: string) => {
    onChange({ ...params, rangeColumn });
    updateQueryParams({ rangeColumn });
  };

  const handleDateChange = (date: Date | null) => {
    if (date) {
      onChange({ ...params, date });
      updateQueryParams({ date });
    }
  };

  const handleGroupJobFilterClean = () => {
    onChange({ ...params, applications: [], jobs: [] });
    updateQueryParams({ applications: [], jobs: [] });
  }

  const handleWorkGroupFilterChange = (val: string[]) => {
    const applications = val.filter((a: string) => a.length > 0);
    onChange({ ...params, applications });
    updateQueryParams({ applications });
  }

  const handleJobTypeFilterChange = (val: string[]) => {
    const jobs = val.filter((a: string) => a.length > 0);
    onChange({ ...params, jobs });
    updateQueryParams({ jobs });
  }

  const handleJobStatusFilter = (jobStatus: any) => {
    onChange({ ...params, jobStatus });
    updateQueryParams({ jobStatus });
  }

  const handleWorkOrderStatusFilter = (workOrderStatus: any) => {
    onChange({ ...params, workOrderStatus });
    updateQueryParams({ workOrderStatus });
  }

  const handleEmailEventFilter = (emailEvent: any) => {
    onChange({ ...params, emailEvent });
    updateQueryParams({ emailEvent });
  }

  const handleEmailEventTypeFilter = (emailEventType: any) => {
    onChange({ ...params, emailEventType });
    updateQueryParams({ emailEventType });
  }

  const handleTextFilterChange = (text: string) => {
    onChange({ ...params, text });
    updateQueryParams({ query: text });
  }

  const handleCheckPickerChange = (val: any, key: string) => {
    if (val.length === 0) {
      onChange({ ...params, [key]: [] });
      updateQueryParams({ [key]: [] });
    } else {
      if (timeout) {
        clearTimeout(timeout);
      }

      timeout = setTimeout(() => {
        if (key === 'crew') {
          val = val.filter((c: number) => c > -2).length > 0 ? val.filter((c: number) => c > -2) : [];
        }

        onChange({ ...params, [key]: val });
        updateQueryParams({ [key]: val });
      }, 500);
    }
  }

  // const handleCrewFilterChange = (val: any) => {
  //   if (val.length === 0) {
  //     onChange({ ...params, crew: [] });
  //     updateQueryParams({ crew: [] });
  //   } else {
  //     if (timeout) {
  //       clearTimeout(timeout);
  //     }

  //     timeout = setTimeout(() => {
  //       const crew = val.filter((c: number) => c > -2).length > 0 ? val.filter((c: number) => c > -2) : [];
  //       onChange({ ...params, crew });
  //       updateQueryParams({ crew });
  //     }, 500);
  //   }
  // }

  const handlePaymentStatusFilter = (paymentStatus: any) => {
    updateQueryParams({ paymentStatus });
    onChange({ ...params, paymentStatus });
  }

  const handleDocumentTypeFilter = (documentType: any) => {
    updateQueryParams({ documentType });
    onChange({ ...params, documentType });
  }

  const handleCustomerLabelFilter = (customerLabel: string[]) => {
    updateQueryParams({ customerLabel });
    onChange({ ...params, customerLabel });
  }

  const handleCompletedByFilter = (val: any) => {
    if (val.length === 0) {
      onChange({ ...params, completedBy: [] });
      updateQueryParams({ completedBy: [] });
    } else {
      if (timeout) {
        clearTimeout(timeout);
      }

      timeout = setTimeout(() => {
        const completedBy = val.filter((c: number) => c > -2).length > 0 ? val.filter((c: number) => c > -2) : [];
        onChange({ ...params, completedBy });
        updateQueryParams({ completedBy });
      }, 500);
    }
  }

  const handleRecurrenceFilter = (recurrence: any) => {
    updateQueryParams({ recurrence });
    onChange({ ...params, recurrence });
  }

  const handleEndingFilter = (endingPeriod: any) => {
    updateQueryParams({ endingPeriod });
    onChange({ ...params, endingPeriod });
  }

  //
  // new format
  //
  const handleFilterUpdate = (key: string, value: string | string[] | undefined) => {
    const parsed: any = queryString.parse(history.location.search);

    if (value === undefined) {
      delete parsed[key];
      store.remove(`${id}-${key}-filter`);
    } else {
      const queryStringValue = Array.isArray(value) ? value.join('+') : value;
      parsed[key] = queryStringValue;
      store.set(`${id}-${key}-filter`, queryStringValue);
    }

    onChange({ ...params, [key]: value });
    history.push(`${window.location.pathname}?${queryString.stringify(parsed)}`);
  }

  const updateQueryParams = (params: ISearchFilter) => {
    const parsed = queryString.parse(history.location.search);

    if (params?.range && params.range.length > 0) {
      parsed.startDate = (params as any).range[0].toISOString();
      parsed.endDate = (params as any).range[1].toISOString();
      store.session.set(`${id}-range-filter`, JSON.stringify(params.range));
      store.session.set(`${id}-active-range-filter`, JSON.stringify(params.range));
    } else {
      delete parsed.endDate;
      delete parsed.startDate;
    }

    if (params.applications) {
      parsed.group = params.applications.join('-');
      store.session.set(`${id}-application-filter`, JSON.stringify(params.applications));
    }

    if (params.jobs) {
      parsed.jobs = params.jobs.join('-');
      store.session.set(`${id}-job-filter`, JSON.stringify(params.jobs));
    }

    if (params.crew && params.crew.length > 0) {
      parsed.crew = (Array.isArray(params.crew) ? params.crew : [params.crew]).map((c: any) => c.toString());
      store.session.set(`${id}-crew-filter`, params.crew);
    } else if (params.crew && params.crew.length === 0) {
      delete parsed.crew;
      store.session.remove(`${id}-crew-filter`);
    }

    if (params.documentTemplate && params.documentTemplate.length > 0) {
      parsed.documentTemplate = Array.isArray(params.documentTemplate) ? params.documentTemplate : [params.documentTemplate];
      store.session.set(`${id}-documentTemplate-filter`, params.documentTemplate);
    } else if (params.documentTemplate && params.documentTemplate.length === 0) {
      delete parsed.documentTemplate;
      store.session.remove(`${id}-documentTemplate-filter`);
    }

    if (params.trackingFlag && params.trackingFlag.length > 0) {
      parsed.trackingFlag = Array.isArray(params.trackingFlag) ? params.trackingFlag : [params.trackingFlag];
      store.session.set(`${id}-trackingFlag-filter`, params.trackingFlag);
    } else if (params.trackingFlag && params.trackingFlag.length === 0) {
      delete parsed.trackingFlag;
      store.session.remove(`${id}-trackingFlag-filter`);
    }

    if (params.squareFootage && params.squareFootage.length > 0) {
      parsed.squareFootage = Array.isArray(params.squareFootage) ? params.squareFootage : [params.squareFootage];
      store.session.set(`${id}-squareFootage-filter`, params.squareFootage);
    } else if (params.squareFootage && params.squareFootage.length === 0) {
      delete parsed.squareFootage;
      store.session.remove(`${id}-squareFootage-filter`);
    }

    if (params.jobStatus) {
      parsed.jobStatus = Array.isArray(params.jobStatus) ? params.jobStatus : [params.jobStatus];
      store.session.set(`${id}-jobStatus-filter`, params.jobStatus);
    } else if (params.jobStatus === null) {
      delete parsed.jobStatus;
      store.session.remove(`${id}-jobStatus-filter`);
    }

    if (params.workOrderStatus) {
      parsed.workOrderStatus = encodeURI(params.workOrderStatus);
      store.session.set(`${id}-workOrderStatus-filter`, params.workOrderStatus);
    } else if (params.workOrderStatus === null) {
      delete parsed.workOrderStatus;
      store.session.remove(`${id}-workOrderStatus-filter`);
    }

    if (params.paymentStatus) {
      parsed.paymentStatus = encodeURI(params.paymentStatus);
      store.session.set(`${id}-paymentStatus-filter`, params.paymentStatus);
    } else if (params.paymentStatus === null) {
      delete parsed.paymentStatus;
      store.session.remove(`${id}-paymentStatus-filter`);
    }

    if (params.documentType) {
      parsed.documentType = encodeURI(params.documentType);
      store.session.set(`${id}-documentType-filter`, params.documentType);
    } else if (params.documentType === null) {
      delete parsed.documentType;
      store.session.remove(`${id}-documentType-filter`);
    }

    if (params.recurrence) {
      parsed.recurrence = encodeURI(params.recurrence);
      store.set(`${id}-recurrence-filter`, params.recurrence);
    } else if (params.recurrence === null) {
      delete parsed.recurrence;
      store.remove(`${id}-recurrence-filter`);
    }

    if (params.documentStatus) {
      parsed.documentStatus = Array.isArray(params.documentStatus) ? params.documentStatus : [params.documentStatus];
      store.session.set(`${id}-documentStatus-filter`, params.documentStatus);
    } else if (params.documentStatus === null) {
      delete parsed.documentStatus;
      store.session.remove(`${id}-documentStatus-filter`);
    }

    if (params.customerLabel) {
      parsed.customerLabel = encodeURI(params.customerLabel.join('-'));
      store.set(`${id}-customerLabel-filter`, JSON.stringify(params.customerLabel));
    } else if (params.customerLabel === null) {
      delete parsed.customerLabel;
      store.remove(`${id}-customerLabel-filter`);
    }

    if (params.rangeColumn) {
      parsed.rangeColumn = encodeURI(params.rangeColumn);
      store.session.set(`${id}-rangeColumn-filter`, params.rangeColumn);
    } else if (params.rangeColumn === null) {
      delete parsed.rangeColumn;
      store.session.remove(`${id}-rangeColumn-filter`);
    }

    if (params.listType) {
      parsed.listType = encodeURI(params.listType);
      store.set(`${id}-listType-filter`, params.listType);
    } else if (params.listType === null) {
      delete parsed.listType;
      store.remove(`${id}-listType-filter`);
    }

    if (params.query && params.query?.trim().length > 0) {
      parsed.query = encodeURI(params.query);
    } else if (params.listType === null || params.query?.trim().length === 0) {
      delete parsed.query;
    }

    if (params.endingPeriod) {
      parsed.endingPeriod = encodeURI(params.endingPeriod);
      store.set(`${id}-endingPeriod-filter`, params.endingPeriod);
    } else if (params.endingPeriod === null) {
      delete parsed.endingPeriod;
      store.remove(`${id}-endingPeriod-filter`);
    }

    if (params.emailEvent) {
      parsed.emailEvent = encodeURI(params.emailEvent);
      store.set(`${id}-emailEvent-filter`, params.emailEvent);
    } else if (params.emailEvent === null) {
      delete parsed.emailEvent;
      store.remove(`${id}-emailEvent-filter`);
    }

    if (params.emailEventType) {
      parsed.emailEventType = encodeURI(params.emailEventType);
      store.set(`${id}-emailEventType-filter`, params.emailEventType);
    } else if (params.emailEventType === null) {
      delete parsed.emailEventType;
      store.remove(`${id}-emailEventType-filter`);
    }

    if (params.completedBy && params.completedBy.length > 0) {
      parsed.completedBy = params.completedBy.join('_');
      store.session.set(`${id}-completedBy-filter`, JSON.stringify(params.completedBy));
    } else if (params.completedBy && params.completedBy.length === 0) {
      delete parsed.completedBy;
      store.session.remove(`${id}-completedBy-filter`);
    }

    if (params.date) {
      parsed.date = params.date.toISOString();
      store.session.set(`${id}-date-filter`, params.date);
    }

    history.push(`${window.location.pathname}?${queryString.stringify(parsed)}`);
  }

  const getWorkOrderStatusFilter = () => {
    return workOrderStatusFilter || params.workOrderStatus;
  }

  const getWorkGroupFilter = () => {
    return [...(applicationFilter || params.applications), ...(jobFilter || params.jobs)];
  }

  const getApplicationGroupFilter = () => {
    const selected = [...(applicationFilter || params.applications), ...(jobFilter || params.jobs)];
    const parentApplicationGroup = selected
      .concat((state.fields || []).filter((f: any) => selected.includes(f.key)).map((f: any) => f.applicationGroup))
      .filter((f: any) => f);

    return parentApplicationGroup;
  }

  const getDocumentTypeFilter = () => {
    return documentTypeFilter || params.documentType;
  }

  const getCustomerLabelFilter = () => {
    const tempLabel: any = customerLabelFilter || params.customerLabel;
    if (tempLabel && !Array.isArray(tempLabel)) {
      return [tempLabel];
    }

    return tempLabel || [];
  }

  const getEmailEventFilter = () => {
    return params.emailEvent;
  }

  const getEmailEventTypeFilter = () => {
    return params.emailEventType;
  }

  const getDateFilter = () => {
    return params.date;
  }

  const getCompletedByFilter = () => {
    return +params.completedBy;
  }

  const getRecurrenceFilter = () => {
    return recurrenceFilter || params.recurrence;
  }

  const getEndingFilter = () => {
    return params.endingPeriod;
  }

  const getDateRangeFilter = () => {
    // cleanable date ranges have active key, other they don't
    const key = dateRangeCleanable ? 'active-range-filter' : 'range-filter';

    if (dateRangeCleanable && store.session.get(`${id}-${key}`) !== null) {
      try {
        const dateRange = JSON.parse(store.session.get(`${id}-${key}`));
        return dateRange.map((d: any) => parseISO(d));
      } catch {
        return [];
      }
    }

    return params.range;
  }

  const getTextFilter = () => {
    return textFilter || params.text;
  }

  return (
    <div className="mb-8 filter">
      <Grid fluid>
        <Row>
          {showDateFilter === true &&
            <Col md={2} xs={24} className="pl-4">
              <div style={{ padding: '4px' }}>
                <DatePicker
                  block
                  size="sm"
                  oneTap
                  value={getDateFilter()}
                  renderValue={(date: any) => format(date, 'EEE, d MMM')}
                  cleanable={false}
                  onChange={handleDateChange}
                />
              </div>
            </Col>
          }

          {(showDateRangeFilter === undefined ? true : showDateRangeFilter) &&
            <Col md={dateRangeColumns ? 6 : 5} xs={24} className="pl-4">
              <div style={{ padding: '3px 0 3px 4px' }}>
                <DateRangeFilter
                  filterKey={id}
                  dateRange={getDateRangeFilter()}
                  rangeColumn={params.rangeColumn}
                  cleanable={dateRangeCleanable}
                  isMobileOnly={isMobileOnly}
                  showOneCalendar={isMobileOnly}
                  handleDateRangeChange={handleDateRangeChange}
                  handleDateRangeClean={handleDateRangeClean}
                  handleDateRangeColumnChange={handleDateRangeColumnChange}
                  handleFilterToggle={() => setShowFilter(!showFilter)}
                  showFilter={showFilter}
                  columns={dateRangeColumns}
                />
              </div>
            </Col>
          }

          {(showTextFilter === undefined ? true : showTextFilter) &&
            <Col md={3} xs={24}>
              <div style={{ padding: '4px' }}>
                <InputFilter
                  onChange={handleTextFilterChange}
                  value={getTextFilter()}
                />
              </div>
            </Col>
          }

          {/* Work Group */}
          {(showFilter && (showGroupFilter === undefined ? true : showGroupFilter)) &&
            <Col md={3} xs={24}>
              <div style={{ padding: '4px' }}>
                <CheckPicker
                  sticky
                  block
                  size="sm"
                  value={getWorkGroupFilter()}
                  searchable
                  placeholder={`${INTL.WORK_GROUP}`}
                  onClean={handleGroupJobFilterClean}
                  onSelect={(val, item: any) => {
                    const groups = workGroupTypes.map((g: any) => g.key);
                    if (item.group === 'jobType') {
                      handleJobTypeFilterChange(val.filter((v: any) => !groups.includes(v)));
                    } else {
                      handleWorkGroupFilterChange(val.filter((v: any) => groups.includes(v)));
                    }
                  }}
                  groupBy={'role'}
                  data={[
                    ...workGroupTypes
                      .filter((g: any) => {
                        return isRole(ROLE.ADMIN, ROLE.MANAGER) ? true : (state.profile.accessGroup || []).includes(g.key)
                      })
                      .map((g: any) => ({
                        ...g,
                        group: 'workType',
                        role: INTL.WORK_GROUP,
                        value: g.key,
                        label: g.title
                      })),
                    ...(isRole(ROLE.CLIENT)
                      ? []
                      : filterFieldsByParentKey(state.fields, 'work_types')
                        .filter((f: any) => {
                          return isRole(ROLE.ADMIN, ROLE.MANAGER) ? true : (state.profile.accessGroup || []).includes(f.applicationGroup)
                        })
                        .map((f: any, index: number) => ({
                          value: f.key,
                          label: f.title,
                          group: 'jobType',
                          role: workGroupTypes.find((wf: any) => wf.key === f.applicationGroup)?.title
                        }))
                    )
                  ]}
                />
              </div>
            </Col>
          }

          {(isMultiCrew(state.users) && showFilter && (showCrewFilter === undefined ? true : showCrewFilter)) &&
            <Col md={3} xs={24}>
              <div style={{ padding: '4px' }}>
                {showMultipleCrewFilter
                  ? <CheckPicker
                    sticky
                    size="sm"
                    block
                    value={selectedCrew}
                    searchable={true}
                    placeholder={INTL.CREW}
                    data={state.users.filter((u: any) => (u.accessGroup || []).find((a: any) => getApplicationGroupFilter().length === 0 || getApplicationGroupFilter().includes(a)))}
                    groupBy="role"
                    cleanable={crewFilterCleanable}
                    onChange={(val: any) => {
                      setSelectedCrew(val.map((v: string) => +v));
                      handleCheckPickerChange(val.map((v: string) => +v), 'crew');
                    }}
                    onClean={() => {
                      setSelectedCrew([]);
                      handleCheckPickerChange([], 'crew');
                    }}
                  />
                  : <SelectPicker
                    size="sm"
                    block
                    value={Array.isArray(selectedCrew) ? selectedCrew[0] : selectedCrew}
                    searchable={true}
                    placeholder={`User`}
                    onChange={(val: any) => {
                      setSelectedCrew([+val]);
                      handleCheckPickerChange([+val], 'crew');
                    }}
                    data={state.users}
                    groupBy="role"
                    cleanable={crewFilterCleanable}
                    onClean={() => {
                      setSelectedCrew([]);
                      handleCheckPickerChange([], 'crew');
                    }}
                  />
                }
              </div>
            </Col>
          }

          {showSiteReviewTypeFilter &&
            <>
              <Col md={3} xs={24}>
                <div style={{ padding: '4px' }}>
                  <SelectPicker
                    searchable={false}
                    labelKey='name'
                    valueKey='guid'
                    block
                    size="sm"
                    onClean={() => handleFilterUpdate('template', undefined)}
                    onSelect={(value: string) => handleFilterUpdate('template', value)}
                    value={params.template}
                    placeholder={'Site Review'}
                    data={(state.templates || []).filter((f: any) => f.type === 'review')}
                  />
                </div>
              </Col>
              <Col md={3} xs={24}>
                <div style={{ padding: '4px' }}>
                  <SelectPicker
                    searchable={false}
                    block
                    size="sm"
                    onClean={() => handleFilterUpdate('siteReviewStatus', undefined)}
                    onSelect={(value: string) => handleFilterUpdate('siteReviewStatus', value)}
                    value={params.siteReviewStatus}
                    placeholder={'Status'}
                    data={[
                      { label: 'New', value: 'new' },
                      { label: 'Completed', value: 'completed' },
                      { label: 'Rejected', value: 'rejected' },
                    ]}
                  />
                </div>
              </Col>
            </>
          }

          {(showFilter && showJobStatus) &&
            <Col md={3} xs={24}>
              <div style={{ padding: '4px' }}>
                <CheckPicker
                  block
                  size="sm"
                  value={selectedJobStatus}
                  searchable={false}
                  placeholder={'Status'}
                  data={IS_KALADI_PROPERTIES
                    ? (state.fields || []).filter((f: any) => f.parentKey === 'job_statuses').map((f: any) => ({ label: f.title, value: f.key, color: f.color }))
                    : CONFIG_STATUS}
                  onChange={(val: any) => {
                    setSelectedJobStatus(val.filter((v: any) => v.length > 0));
                    handleCheckPickerChange(val.filter((v: any) => v.length > 0), 'jobStatus');
                  }}
                  onClean={() => {
                    setSelectedJobStatus([]);
                    handleCheckPickerChange([], 'jobStatus');
                  }}
                />
              </div>
            </Col>
          }

          {(showFilter && showPaymentStatus) &&
            <Col md={3} xs={24}>
              <div style={{ padding: '4px' }}>
                <SelectPicker
                  block
                  size="sm"
                  value={paymentStatusFilter}
                  searchable={false}
                  placeholder={'Payment Status'}
                  onChange={handlePaymentStatusFilter}
                  data={[
                    { label: 'Paid', value: 'paid' },
                    { label: 'Not Paid', value: 'not_paid' }
                  ]}
                />
              </div>
            </Col>
          }

          {(showFilter && showDocumentStatusFilter) &&
            <Col md={3} xs={24}>
              <div style={{ padding: '4px' }}>
                <CheckPicker
                  sticky
                  block
                  size="sm"
                  value={selectedDocumentStatus}
                  placeholder={'Document Status'}
                  data={orderBy(filterFieldsByParentKey(state.fields, 'documents').filter((f: any) => !f.deletedAt), ['title'], ['asc'])}
                  valueKey="key"
                  labelKey="title"
                  onChange={(val: any) => {
                    setSelectedDocumentStatus(val);
                    handleCheckPickerChange(val, 'documentStatus');
                  }}
                  onClean={() => {
                    setSelectedDocumentStatus([]);
                    handleCheckPickerChange([], 'documentStatus');
                  }}
                />
              </div>
            </Col>
          }

          {(showFilter && showUserRoleFilter) &&
            <Col md={3} xs={24}>
              <div style={{ padding: '4px' }}>
                <SelectPicker
                  searchable={false}
                  block
                  size="sm"
                  onClean={() => handleFilterUpdate('userRole', undefined)}
                  onSelect={(value: string) => handleFilterUpdate('userRole', value)}
                  value={params.userRole}
                  placeholder={'User Role'}
                  data={CONFIG_APPLICATION_ROLES}
                />
              </div>
            </Col>
          }

          {(showFilter && showEmailEventFilter) &&
            <Col md={3} xs={24}>
              <div style={{ padding: '4px' }}>
                <SelectPicker
                  block
                  size="sm"
                  value={getEmailEventFilter()}
                  placeholder={'Email Event'}
                  onChange={handleEmailEventFilter}
                  data={[
                    { "label": "Bounce", "value": "bounce" },
                    { "label": "Click", "value": "click" },
                    { "label": "Complaint", "value": "complaint" },
                    { "label": "Deferred", "value": "deferred" },
                    { "label": "Delivered", "value": "delivered" },
                    { "label": "Delivery", "value": "delivery" },
                    { "label": "Dropped", "value": "dropped" },
                    { "label": "Group Resubscribe", "value": "group_resubscribe" },
                    { "label": "Group Unsubscribe", "value": "group_unsubscribe" },
                    { "label": "Open", "value": "open" },
                    { "label": "Processed", "value": "processed" },
                    { "label": "Send", "value": "send" },
                    { "label": "Spam Report", "value": "spamreport" },
                    { "label": "Unsubscribe", "value": "unsubscribe" }
                  ]}
                />
              </div>
            </Col>
          }

          {(showFilter && showEmailEventTypeFilter) &&
            <Col md={3} xs={24}>
              <div style={{ padding: '4px' }}>
                <SelectPicker
                  block
                  size="sm"
                  searchable={false}
                  value={getEmailEventTypeFilter()}
                  placeholder={'Type'}
                  onChange={handleEmailEventTypeFilter}
                  data={[
                    { "label": "Document", "value": "document" },
                    { "label": "Invoice", "value": "invoice" },
                    { "label": "Job", "value": "job" },
                    { "label": "Work Order", "value": "work-order" }
                  ]}
                />
              </div>
            </Col>
          }

          {/* add filter here in order to show more icon */}
          {(!IS_KALADI_KITCHENS && !isMobileOnly) &&
            <Col md={2} xs={24}>
              {(showMoreSelector) &&

                <Whisper placement="bottom" trigger="hover" speaker={<Tooltip>{showMoreFilters ? 'Less' : 'More'} Filters</Tooltip>}>
                  <IconButton
                    appearance={showMoreFilters ? 'link' : 'subtle'}
                    style={{ marginTop: '2px' }}
                    icon={<MdFilterAlt style={{ fontWeight: showMoreFilters ? 600 : 400 }} />}
                    onClick={() => setShowMoreFilters(!showMoreFilters)}
                  />
                </Whisper>
              }

              {(tableDataRef?.current || []).filter((c: any) => c.sortable).length > 0 &&
                <Whisper placement="bottom" trigger="hover" speaker={<Tooltip>Advanced Sort</Tooltip>}>
                  <IconButton
                    appearance={order.length > 0 ? 'link' : 'subtle'}
                    style={{ marginTop: '2px' }}
                    icon={<MdSort style={{ fontWeight: order.length > 0 ? 600 : 400 }} />}
                    onClick={() => {
                      showDrawer(DRAWER.ADVANCED_FILTER, {
                        id: id,
                        sortableColumns: tableDataRef.current.filter((c: any) => c.sortable).map((c: any, index: number) => ({
                          value: c.dataKey,
                          label: c.Header,
                          sortOrder: index,
                          sort: ''
                        }))
                      }, () => { });
                    }}
                  />
                </Whisper>
              }
            </Col>
          }

          {children && children}
        </Row>

        {showMoreFilters &&
          <Row>
            <Col md={(dateRangeColumns || []).length > 0 ? 6 : 5}>&nbsp;</Col>

            {(showFilter && showDocumentTypeFilter) &&
              <Col md={3} xs={24}>
                <div style={{ padding: '4px' }}>
                  <SelectPicker
                    block
                    size="sm"
                    value={getDocumentTypeFilter()}
                    placeholder={'Document Type'}
                    onChange={handleDocumentTypeFilter}
                    data={filterFieldsByParentKey(state.fields, 'document_types')}
                    valueKey="key"
                    labelKey="title"
                  />
                </div>
              </Col>
            }

            {(showFilter && showDocumentTemplateFilter) &&
              <Col md={3} xs={24}>
                <div style={{ padding: '4px' }}>
                  <CheckPicker
                    sticky
                    block
                    size="sm"
                    value={selectedDocumentTemplate}
                    placeholder={'Document Template'}
                    data={state.templates.filter((t: any) => t.type === 'document').sort()}
                    valueKey="id"
                    labelKey="name"
                    onChange={(val: any) => {
                      setSelectedDocumentTemplate(val);
                      handleCheckPickerChange(val, 'documentTemplate');
                    }}
                    onClean={() => {
                      setSelectedDocumentTemplate([]);
                      handleCheckPickerChange([], 'documentTemplate');
                    }}
                  />
                </div>
              </Col>
            }

            {(showFilter && showCustomerLabelFilter) &&
              <Col md={3} xs={24}>
                <div style={{ padding: '4px' }}>
                  <CheckPicker
                    sticky
                    block
                    size="sm"
                    value={getCustomerLabelFilter()}
                    placeholder={'Customer Label'}
                    onChange={handleCustomerLabelFilter}
                    data={filterFieldsByParentKey(state.fields, 'customer_label')}
                    valueKey="key"
                    labelKey="title"
                  />
                </div>
              </Col>
            }

            {(showFilter && showCompletedByFilter) &&
              <Col md={3} xs={24}>
                <div style={{ padding: '4px' }}>
                  <CheckPicker
                    sticky
                    size="sm"
                    block
                    value={completedByCrew}
                    searchable={true}
                    placeholder={'Completed By'}
                    onChange={(val: any) => {
                      setCompletedByCrew(val.map((v: string) => +v));
                      handleCompletedByFilter(val.map((v: string) => +v));
                    }}
                    data={state.users.filter((u: any) => (u.accessGroup || []).find((a: any) => getApplicationGroupFilter().length === 0 || getApplicationGroupFilter().includes(a)))}
                    groupBy="role"
                    cleanable
                    onClean={() => {
                      setSelectedCrew([]);
                      handleCompletedByFilter([]);
                    }}
                  />
                </div>
              </Col>
            }

            {(showFilter && showTrackingFlagFilter) &&
              <Col md={3} xs={24}>
                <div style={{ padding: '4px' }}>
                  <CheckPicker
                    sticky
                    size="sm"
                    block
                    value={selectedTrackingFlag}
                    searchable={false}
                    placeholder={'Tracking Flag'}
                    renderMenuItem={(label: any, item: any) => <div><MdFlag color={item.title.toLowerCase()} /> {item?.description || ''}</div>}
                    data={trackingFlags}
                    cleanable
                    valueKey='key'
                    labelKey='description'
                    onChange={(val: any) => {
                      setSelectedTrackingFlag(val.map((v: string) => v));
                      handleCheckPickerChange(val.map((v: string) => v), 'trackingFlag');
                    }}
                    onClean={() => {
                      setSelectedTrackingFlag([]);
                      handleCheckPickerChange([], 'trackingFlag');
                    }}
                  />
                </div>
              </Col>
            }

            {(showFilter && showRecurrenceFilter) &&
              <Col md={3} xs={24}>
                <div style={{ padding: '4px', marginLeft: '4px' }}>
                  <SelectPicker
                    block
                    size="sm"
                    searchable={false}
                    value={getRecurrenceFilter()}
                    placeholder={'Work Order Recurrence'}
                    onChange={handleRecurrenceFilter}
                    data={[
                      { label: 'One Time - Variable', value: 'onetime' },
                      { label: 'One Time - Fixed', value: 'onetime_fixed' },
                      { label: 'Weekly', value: 'weekly' },
                      { label: 'Bi-Weekly', value: 'biweekly' },
                      { label: 'On-Demand', value: 'ondemand' }
                    ]}
                  />
                </div>
              </Col>
            }

            {(showFilter && showEndingFilter) &&
              <Col md={3} xs={24}>
                <div style={{ padding: '4px' }}>
                  <SelectPicker
                    block
                    size="sm"
                    searchable={false}
                    value={getEndingFilter()}
                    placeholder={'Document Expiry'}
                    onChange={handleEndingFilter}
                    data={[
                      { label: `This Month (${format(new Date(), 'MMM')})`, value: 'this_month' },
                      { label: `Next Month (${format(addMonths(new Date(), 1), 'MMM')})`, value: 'next_month' },
                      { label: `Two Months (${format(addMonths(new Date(), 2), 'MMM')})`, value: 'two_months' },
                      { label: `Three Months (${format(addMonths(new Date(), 3), 'MMM')})`, value: 'three_months' },
                    ]}
                  />
                </div>
              </Col>
            }

            {(showFilter && showWorkOrderStatusFilter) &&
              <Col md={3} xs={24}>
                <div style={{ padding: '4px' }}>
                  <SelectPicker
                    block
                    size="sm"
                    value={getWorkOrderStatusFilter()}
                    searchable={false}
                    placeholder={'Work Order Status'}
                    onChange={handleWorkOrderStatusFilter}
                    data={CONFIG_STATUS}
                  />
                </div>
              </Col>
            }

            {(showFilter && showSquareFootageFilter) &&
              <Col md={3} xs={24}>
                <div style={{ padding: '4px' }}>
                  <CheckPicker
                    block
                    size="sm"
                    value={selectedSquareFootage}
                    searchable={false}
                    placeholder={'Square Footage'}
                    onChange={(val: any) => {
                      setSelectedSquareFootage(val);
                      handleCheckPickerChange(val, 'squareFootage');
                    }}
                    onClean={() => {
                      setSelectedSquareFootage([]);
                      handleCheckPickerChange([], 'squareFootage');
                    }}
                    data={arrayRange(0, 500000, 25000).map((r: any) => ({
                      label: `${r.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}-${(r + 25000).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} sqft`,
                      value: `${r}-${r + 25000}`
                    }))}
                  />
                </div>
              </Col>
            }
          </Row>
        }
      </Grid>

      <ColumnChooser
        storageKey={id}
        show={showColumnChooserDrawer}
        onHide={() => setShowColumnChooserDrawer(false)}
        onSave={() => {
          window.location.reload();
        }}
      />
    </div>
  )
}

export default Filter;
export type {
  ISearchFilter
};
