import { useCallback, useRef, useState } from 'react';
import { useEffect } from 'react';
import { Form, Button, Schema, toaster, Message } from 'rsuite';
import INTL from 'tenant/intl';
import { IS_KALADI_KITCHENS } from 'lib/tenant';
import { getEnv, getTenant } from 'lib/env';
import { useApi } from 'lib';
import AuthForgotPasswordForm from './AuthForgotPasswordForm';
import { Link } from 'react-router-dom';
import store from 'lib/storage';

const { StringType } = Schema.Types;

const model = Schema.Model({
  username: StringType().isRequired('This field is required'),
  password: StringType().isRequired('This field is required')
});

interface IAuthSignInForm {
  history: any
}

const AuthSignInForm = ({ history }: IAuthSignInForm) => {
  let form: any = useRef();
  const api = useApi();
  const formRef = useCallback((node: any) => {
    form.current = node;
  }, [])

  const [processing, setProcessing] = useState(false);
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [formError, setFormError] = useState<any>({});
  const [formValue, setFormValue] = useState<any>({
    username: '',
    password: ''
  });

  useEffect(() => {
    if (store.get('username')) {
      setFormValue({ username: store.get('username'), password: '' });
    }
  }, []);

  useEffect(() => {
    const listener = (event: any) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        event.preventDefault();
        handleSubmit(form.current, formValue);
      }
    };

    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [form.props, formValue]);

  const handleSubmit = async (form: any, formValue: any) => {
    if (!form.check()) {
      toaster.push(
        <Message type="error" showIcon closable>Login form has errors which need to be corrected</Message>
      );
      return;
    }

    setProcessing(true);
    const response: any = await api.post('/oauth/v2/token', {
      ...formValue,
      client_id: getTenant().id,
      grant_type: 'password'
    });

    if (response.access_token) {
      if (getTenant().id === 'app') {
        window.location.href = `https://${response.client_id[0]}.prairiehq.${getEnv() === 'dev' ? 'net' : 'com'}/auth?access_token=${response.access_token}&refresh_token=${response.refresh_token}`;
      } else {
        window.location.href = `/`;
      }
    } else {
      setProcessing(false);
      toaster.push(
        <Message type="error" showIcon closable duration={3000}>{response.message}</Message>
      );
    }
  }

  const handleForgotPassword = async (form: any, formValue: any) => {
    if (!form.check()) {
      toaster.push(
        <Message type="error" showIcon closable>Reset form has errors which need to be corrected</Message>
      );
      return;
    }

    setProcessing(true);
    const result: any = await api.post('/oauth/v2/change_password', { ...formValue, client_id: getTenant().id });
    setProcessing(false);

    if (result.success) {
      toaster.push(<Message type="success" showIcon closable>{result.message}</Message>);
    } else {
      toaster.push(<Message type="error" showIcon closable>{result.message}</Message>);
    }
  }

  return (
    <div>
      {showForgotPassword
        ? <AuthForgotPasswordForm 
            setShowForgotPassword={() => setShowForgotPassword(false)}
            processing={processing} handleSubmit={handleForgotPassword} history={history} />
        : <Form
          fluid
          ref={formRef}
          formValue={formValue}
          formError={formError}
          onChange={setFormValue}
          onCheck={setFormError}
          model={model}
        >
          <Form.Group>
            <Form.ControlLabel>Username or email address</Form.ControlLabel>
            <Form.Control 
              name="username" 
              // rsuite5
              // tabIndex="1" 
            />
          </Form.Group>
          <Form.Group>
            <Form.ControlLabel>Password</Form.ControlLabel>
            <Form.Control 
              name="password" 
              type="password" 
              // rsuite5
              // tabIndex="2" 
            />
          </Form.Group>
          <Form.Group>
            <div className="text-center">
              <Button size="sm" appearance="primary" loading={processing} onClick={() => handleSubmit(form.current, formValue)}>Sign in</Button>
              <div>
                <Button size="sm" onClick={() => setShowForgotPassword(!showForgotPassword)} 
                  // rsuite5
                  as={Link} appearance="link" to="/auth/reset">Forgot password</Button>
              </div>

              {(IS_KALADI_KITCHENS) &&
                <Button appearance="link" disabled={processing} onClick={() => history.push(`/auth/register`)}>{INTL.REGISTER}</Button>
              }
              <div className="text-center mb-6 mt-6">or</div>
              <div className="text-center">
                <Button size="sm" appearance='ghost' style={{ color: '#888e96', borderColor: '#e5e5ea' }} onClick={() => {
                  // setGoogleLoginProcessed(false);
                  window.location.href = `https://accounts.google.com/o/oauth2/auth?client_id=292801444118-957s3laanjr72t55tu6ugn85dpnckvm4.apps.googleusercontent.com&redirect_uri=https://app.prairiehq.${getEnv() === 'dev' ? 'net' : 'com'}/auth/google/signin&scope=openid%20email%20profile&response_type=code&state=client_id%3D${window.location.hostname.split('.')[0]}`;
                }}>
                  <img src="/google-icon.png" style={{ width: '18px' }} /> &nbsp;<strong>Sign in with Google&nbsp;&nbsp;&nbsp;&nbsp;</strong>
                </Button>
              </div>

              {/* <div className="text-center mt-6">
                <Button appearance='ghost' style={{ color: '#888e96', borderColor: '#e5e5ea' }} onClick={() => {
                  // setGoogleLoginProcessed(false);
                  window.location.href = `https://login.microsoftonline.com/organizations/oauth2/v2.0/authorize?client_id=6023b1e1-f9e4-4f47-b8c1-b8f0a48207c8&redirect_uri=https://app.prairiehq.${getEnv() === 'dev' ? 'net' : 'com'}/auth/microsoft/signin&scope=offline_access%20User.Read&response_type=code&state=client_id%3D${window.location.hostname.split('.')[0]}`;
                }}>
                  <img src="/microsoft-icon.png" style={{ width: '18px' }} /> &nbsp;<strong>Sign in with Microsoft</strong>
                </Button>
              </div> */}
            </div>
          </Form.Group>
        </Form>
      }
    </div>
  );
}

export default AuthSignInForm;
