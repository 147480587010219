import { isNil } from 'lodash';
import { Fragment, Dispatch, SetStateAction } from 'react';
import { Pagination as RSuitePagination, Divider, SelectPicker } from 'rsuite';
import { isMobileOnly } from 'react-device-detect';

interface IPagination {
  offset: number,
  totalCount: number,
  recordsPerPage: number,
  onChangeOffset: Dispatch<SetStateAction<number>> | (() => string),
  onChangeLength: Dispatch<SetStateAction<number>> | (() => string),
  showPageSelection?: boolean
}

const Pagination = ({
  offset,
  totalCount,
  recordsPerPage,
  onChangeOffset,
  onChangeLength,
  showPageSelection
}: IPagination) => {
  const getPageCount = () => {
    const divisor: number = Math.floor(totalCount / recordsPerPage);
    const mod: number = totalCount % recordsPerPage;
    return divisor + (mod === 0 ? 0 : 1);
  }

  return <Fragment>
    {totalCount > 0 &&
      <div className="rs-table-pagination-toolbar pt-0 p-10">
        <div className="rs-table-pagination-start">
          {/* <div className="rs-table-pagination-length-menu">
            <SelectPicker
              size="xs"
              // rsuite5
              // title="20"
              searchable={false}
              cleanable={false}
              appearance="subtle"
              defaultValue={20}
              value={recordsPerPage}
              onSelect={onChangeLength}
              placement="topStart"
              data={[
                { label: '10', value: 10 },
                { label: '20', value: 20 },
                { label: '50', value: 50 },
                { label: '100', value: 100 },
                { label: '200', value: 200 }
              ]}
            /> {!isMobileOnly && ' / page'}
          </div> */}

          {/* <Divider vertical />

          <div className="rs-table-pagination-length-menu">
            {offset + 1} to {totalCount < recordsPerPage ? totalCount : offset + recordsPerPage} of {totalCount}
          </div> */}

          {/* <Divider vertical /> */}

          <div className="rs-table-pagination-page-info">
            {(isNil(showPageSelection) || showPageSelection === true) &&
              <RSuitePagination
                layout={['total', '|', 'limit', '|', 'pager']}
                size="xs"
                className={"pt-8"}
                first={!isMobileOnly}
                last={!isMobileOnly}
                prev
                next
                limit={recordsPerPage}
                pages={getPageCount()}
                total={totalCount}
                maxButtons={5}
                ellipsis
                boundaryLinks
                activePage={offset / recordsPerPage + 1}
                onSelect={(val: any) => onChangeOffset((val - 1) * recordsPerPage)}
                limitOptions={[10, 20, 50, 100, 200]}
                onChangeLimit={onChangeLength}
              />
            }
          </div>
        </div>

      </div>
    }
  </Fragment>
}

export default Pagination;
