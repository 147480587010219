import { Fragment, useEffect, useState, useContext } from 'react';
import { ViewCalendar, ClockInOut } from './components';
import { toaster, Message, Col, DOMHelper } from 'rsuite';
import { Filter, ApplicationContext } from 'shared';
import { useApolloClient } from '@apollo/client';
import { queryJobsCalendar } from '../../gql/jobs';
import { useFilteredParams } from 'shared/FilterProvider';
import { useRouteMatch } from 'react-router-dom';
import { isBetween, rescheduleJob } from 'lib/helpers';
import { parseNumber, ROLE, setHeaderTitle, STATUS } from 'lib/env';
import { getFilter } from 'lib/helpers/filter';
import { addHours, differenceInMinutes, endOfMonth, startOfMonth } from 'date-fns';
import { getDefaultDate, parseISO, toTimeZone } from 'lib/date';
import { IS_KALADI_KITCHENS, moneyFormatter } from 'lib/tenant';
import useLocalStorage from 'lib/hooks/useLocalStorage';
import { useViewport } from 'shared/ViewportProvider';
import { flatten, uniq } from 'lodash';
import { getSort } from 'lib/helpers/sort';
import { isBrowser } from 'react-device-detect';
import ReactTooltip from 'react-tooltip';
import { ExportField } from 'components/form';
import { usePrairieAuth } from 'contexts/AuthPrairieProvider';

const JobsCalendar = (props: any) => {
  setHeaderTitle('Job Calendar');
  const client = useApolloClient();
  const match: any = useRouteMatch();
  const { state, dispatch } = useViewport();
  const { isRole } = usePrairieAuth();
  const { showError } = useContext(ApplicationContext);
  const { params } = useFilteredParams('jobs');
  const [jobs, setJobs] = useState([]);
  const [status, setStatus] = useState(STATUS.LOADING);
  const [offset, setOffset] = useState(0);
  const [filter, setFilter] = useState<any>(params);
  const [modifiedAt, setModifiedAt] = useState(new Date().toISOString());
  const [recordsPerPage, setRecordsPerPage] = useLocalStorage('recordsPerPage', 20);
  const [clockInOutJob, setClockInOutJob] = useState<any>(undefined);
  const [clockInOutMode, setClockInOutMode] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (filter.range.length > 0) {
      (async function getJobs() {
        try {
          const whereFilter: any = getFilter('jobs', filter, state.profile);

          setStatus(STATUS.LOADING);
          setJobs([]);

          const data = await client.query({
            query: queryJobsCalendar,
            variables: {
              offset: 0,
              limit: 5000,
              where: whereFilter,
              order: getSort('jobs')
            },
            fetchPolicy: 'no-cache'
          });

          const results = data.data.jobs.edges.node
            .filter((n: any) => (props?.showMyBookings && isRole(ROLE.CLIENT, ROLE.CLEANER)) ? n.userId === state.profile.id : true)
            .filter((n: any) => IS_KALADI_KITCHENS ? (n.status !== 'canceled') : true);

          //== estimates
          //
          const times: any = [];
          const estimates: any = uniq(flatten(results.map((r: any) => {
            const tempDates = [];
            let start = parseISO(r.startDate);

            while (start <= parseISO(r.endDate)) {
              tempDates.push(toTimeZone(getDefaultDate(start), state.timeZone).toISOString());
              start = addHours(start, 24);
            }
            return tempDates;
          }))).reduce((p: any, n: any) => { p[n] = 0; return p; }, {});

          Object.keys(estimates).forEach((k: any) => {
            const estimatedJobs = results.filter((r: any) =>
              isBetween(parseISO(r.startDate), new Date(parseISO(k).setHours(0, 0, 0, 0)), new Date(parseISO(k).setHours(23, 59, 59, 59)))
              && r.status !== 'canceled'
            );

            if (estimatedJobs.length > 0) {
              estimates[k] = estimatedJobs.reduce((p: any, n: any) => p + (n.totalCost || n.perCostTypeCost || n.workOrderPerCostTypeCost), 0);

              if (IS_KALADI_KITCHENS) {
                times[k] = results.filter((r: any) =>
                  isBetween(parseISO(r.startDate), new Date(parseISO(k).setHours(0, 0, 0, 0)), new Date(parseISO(k).setHours(23, 59, 59, 59)))
                  && !(r.customerName === 'Booked' || r.status === 'canceled')
                )
                  .reduce((p: any, n: any) => p + differenceInMinutes(parseISO(n.endDate), parseISO(n.startDate)), 0);
              }
            } else {
              delete estimates[k];
            }
          });

          let jobEstimates: any = Object.keys(estimates).map((e: any) => ({
            guid: -1,
            status: 'daily_estimate',
            customerName: IS_KALADI_KITCHENS ? 'Est. Hours Booked: ' : 'Est. Daily Cost: ',
            startDate: e,
            endDate: e,
            cost: IS_KALADI_KITCHENS ? ((times[e] || 0) / 60.0).toFixed(2) : moneyFormatter.format(parseNumber(estimates[e]))
          }));

          if (isRole(ROLE.WORKER)) {
            setJobs(results);
          } else {
            setJobs(jobEstimates.concat(results));
          }

          DOMHelper.scrollTop(window as any, 0);
        } catch (err) {
          console.log(err);
        }

        setStatus(STATUS.LOADED);
        ReactTooltip.rebuild();
      })();
    }
  }, [offset, filter, client, modifiedAt, recordsPerPage, props?.showMyBookings, match?.params?.view]);

  const handleJobReschedule = (guid: string, date: Date | Date[]) => {
    (async function jobReschedule() {
      let job: any;

      try {
        const nextJobs: any = jobs.map((j: any) => {
          if (j.guid === guid) {
            job = j;

            if (Array.isArray(date)) {
              j.startDate = date[0].toISOString();
              j.endDate = date[1].toISOString();
            } else {
              j.startDate = j.endDate = date.toISOString();
            }
          }

          return j;
        });

        const res: any = await rescheduleJob(client, guid, job.startDate, job.endDate);

        if (res.data.rescheduleJob.success) {
          setJobs(nextJobs);
          toaster.push(
            <Message type="success" showIcon closable>{res.data.rescheduleJob.message}</Message>
          );
        } else {
          toaster.push(
            <Message type="error" showIcon closable>{res.data.rescheduleJob.message}</Message>
          );
        }

        ReactTooltip.rebuild();
      } catch (err) {
        showError(err);
      }
    })();
  }

  const handleClearJobs = () => {
    setJobs([]);
  }

  return (
    <Fragment>
      <Filter
        id='jobs'
        showJobStatus={!isRole(ROLE.CLIENT, ROLE.CLEANER, ROLE.VIEWER)}
        onChange={(val) => {
          setFilter(val);
          setOffset(0);
        }}
        clearJobs={handleClearJobs}
        showMultipleCrewFilter={true}
        showGroupFilter={!isRole(ROLE.CLIENT, ROLE.CLEANER)}
        showTextFilter={!isRole(ROLE.CLIENT, ROLE.CLEANER)}
        dateRangeColumns={isRole(ROLE.CLIENT, ROLE.CLEANER, ROLE.WORKER, ROLE.VIEWER)
          ? undefined
          : [
            { startDate: 'Start Date' },
            { endDate: 'End Date' },
            { createdAt: 'Created At' },
            { modifiedAt: 'Modified At' },
            { startEndInclusive: 'Overlaps Start/End' }
          ]
        }
      >
        <Fragment>
          <Col md={3} xs={24} style={{ float: 'right', textAlign: 'right' }}>
            {!isRole(ROLE.CLIENT, ROLE.CLEANER) && <ExportField />}
          </Col>
        </Fragment>
      </Filter>

      <div className='mt-8'>
        <ViewCalendar
          loading={status === STATUS.LOADING}
          events={jobs}
          showColorCodes={props.showColorCodes || false}
          visibleRange={{ start: filter.range[0], end: filter.range[1] } as any}
          handleJobReschedule={handleJobReschedule}
          onUpdate={() => setModifiedAt(new Date().toISOString())}
          handleClockInOutShow={(mode: string | undefined, job: any) => {
            setClockInOutMode(mode);
            setClockInOutJob(job);
          }}
        />
      </div>

      <ClockInOut
        show={clockInOutJob !== undefined}
        mode={clockInOutMode}
        job={clockInOutJob}
        onHide={() => {
          setClockInOutJob(undefined);
          setClockInOutMode(undefined);
        }}
        onUpdate={() => {
          setModifiedAt(new Date().toISOString());
        }}
      />

      {isBrowser && <ReactTooltip effect="solid" className="tooltip" html={true} />}
    </Fragment>
  );
};

export default JobsCalendar;
