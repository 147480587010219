import INTL from 'tenant/intl';
import { useContext, useEffect, useRef, useState } from 'react';

import {
  Drawer,
  Form,
  SelectPicker,
  Button,
  Schema,
  CheckPicker,
  toaster,
  Message,
  Whisper,
  Tooltip,
  Loader,
  DatePicker,
  Input,
  Popover,
  InputNumber,
} from 'rsuite';

import { isMobileOnly } from 'react-device-detect';
import { FORMAT, getEnv, hasQuickBooksDesktopIntegration } from 'lib/env';
import { gql, useApolloClient, useQuery } from '@apollo/client';
import { flatten, orderBy, pick, startCase } from 'lodash';
import { ApplicationContext } from 'shared';
import { useViewport } from 'shared/ViewportProvider';
import CopyToClipboard from 'react-copy-to-clipboard';
import { v4 as uuidv4 } from 'uuid';
import { filterFieldsByParentKey } from 'lib/helpers/field';
import { GET_TEMPLATES } from 'gql/templates';
import TextareaAutosize from 'react-autosize-textarea';
import { MdContentCopy } from 'react-icons/md';
import { format, parseISO } from 'date-fns';
import { SwatchesPicker } from 'react-color';
import { saveField } from 'modules/settings';
const { StringType } = Schema.Types;

const model = Schema.Model({
  section: StringType().isRequired('This field is required.'),
  title: StringType().isRequired('This field is required.')
});

interface ISettingsFieldForm {
  action?: string,
  guid?: string,
  parentGuid?: string,
  onHide: () => void,
  onUpdate?: (data: any) => void
}

const SettingsFieldForm = ({
  action,
  guid,
  parentGuid,
  onHide,
  onUpdate
}: ISettingsFieldForm) => {
  let form: any = useRef();
  let parentGroupId: any;
  const container = useRef() as React.MutableRefObject<HTMLDivElement>;
  const client = useApolloClient();
  const { state } = useViewport();
  const { showError } = useContext(ApplicationContext);
  const workGroupTypes = filterFieldsByParentKey(state.fields, 'work_groups');

  const [formValue, setFormValue] = useState<any>({
    guid,
    section: parentGuid ? state.fields.find((f: any) => f.guid === parentGuid)?.key : state.fields.find((f: any) => f.key === 'work_types').key,
    title: '',
    quickbooksCode: '',
    description: '',
    group: '',
    visibleOnBooking: true,
    visibleOnJob: true,
    isVisible: true,
    defaults: [],
    allowFreeEntry: false,
    emailTemplateGuid: undefined,
    invoiceTemplateGuid: undefined,
    termsConditionsVersionGuid: undefined,
    serviceDescriptionsVersionGuid: [],
    isDefault: false,
    startDate: undefined,
    endDate: undefined,
    color: undefined,
    salesPerManHourTarget: undefined
  });
  const [formError, setFormError] = useState<any>({});
  const [saving, setSaving] = useState(false);
  const [snippetTemplates, setSnippetTemplates] = useState([]);
  const fields: any = state.fields.filter((f: any) => f.parentId === parentGroupId).map((f: any) => ({
    label: f.title,
    value: f.id,
    group: f.applicationGroup
  }));
  const sections: any = state.fields.filter((f: any) => f.parentId === 0).map((f: any) => ({
    label: f.title,
    value: f.key,
    id: f.id
  }));
  const templates = useQuery(GET_TEMPLATES, { variables: { offset: 0, limit: 999, where: { deletedAt: { is: null } }, order: [{ name: 'ASC' }] } });

  useEffect(() => {
    (async function getSnippets() {
      const snippetListResult: any = await client.query({
        query: gql`
        query templates($limit: Int!, $offset: Int!, $where: TemplatesWhere) {
          templates(filter: {
            limit: $limit,
            offset: $offset,
            where: $where
          }) {
            edges {
              node {
                guid
                id
                name
                subject
                type
                file
                format
                emailId
                isVisible
                versions {
                  id
                  guid
                  version
                  createdAt
                  modifiedAt
                  fileName
                }
              }
            },
            totalCount
          }
        }`,
        variables: {
          offset: 0,
          limit: 999,
          where: {
            AND: { type: { is: 'snippet' }, deletedAt: { is: null } }
          }
        }
      });

      const versions: any = [];
      snippetListResult.data.templates.edges.node.forEach((n: any) => {
        versions.push(n.versions.map((v: any) => ({ ...v, group: n.name })))
      });
      setSnippetTemplates(orderBy(flatten(versions), ['group'], ['asc']));
    })();
  }, []);

  useEffect(() => {
    (async function getField() {
      if (guid) {
        const row = state.fields.find((f: any) => f.guid === guid);

        setFormValue({
          guid: row.guid,
          section: row.parentKey,
          title: row.title || '',
          quickbooksCode: row.quickbooksCode || '',
          quickbooksDescription: row.quickbooksDescription || '',
          description: row.description || '',
          group: row.applicationGroup || '',
          visibleOnJob: row.visibleOnJob,
          visibleOnBooking: row.visibleOnBooking,
          isVisible: row.isVisible,
          defaults: row.defaults,
          key: row.key,
          allowFreeEntry: row.allowFreeEntry,
          emailTemplateGuid: row.emailTemplateGuid,
          invoiceTemplateGuid: row.invoiceTemplateGuid,
          termsConditionsVersionGuid: row.termsConditionsVersionGuid,
          serviceDescriptionsVersionGuid: row.serviceDescriptionsVersionGuid || [],
          isDefault: row.isDefault,
          props: JSON.stringify(row.props || {}),
          startDate: row.startDate,
          endDate: row.endDate,
          color: row.color,
          salesPerManHourTarget: row.salesPerManHourTarget || ''
        });
      } else {
        setFormValue({ ...formValue, guid: uuidv4() });
      }
    })();
  }, [guid]);

  const handleSubmit = async () => {
    try {
      setSaving(true);
      const response: any = await saveField(client, {
        ...formValue,
        section: state.fields.find((f: any) => f.key === formValue.section).id,
        parentId: state.fields.find((f: any) => f.key === formValue.section).id,
        salesPerManHourTarget: (formValue.salesPerManHourTarget || '').length > 0 ? +formValue.salesPerManHourTarget : null,
        applicationGroup: formValue.group
      });

      if (response.data.upsertField.success) {
        toaster.push(
          <Message type="success" showIcon closable>{response.data.upsertField.message}</Message>
        );

        if (onUpdate) {
          onUpdate(response.data.upsertField.result);
        }
        onHide();
      } else {
        showError(response);
      }
      setSaving(false);
    } catch (err) {
      showError(err);
      setSaving(false);
    } finally {
      onHide();
      setSaving(false);
    }
  }

  return (
    <Drawer backdrop='static' open onClose={onHide} full={isMobileOnly}>
      <Drawer.Header>
        <Drawer.Title>{startCase(action)} Field</Drawer.Title>
      </Drawer.Header>
      <Drawer.Body>
        {(templates.loading)
          ? <Loader content="Loading..." />
          : <div ref={container}>
            <Form
              ref={(ref: any) => form = ref}
              fluid
              model={model}
              formValue={formValue}
              formError={formError}
              onChange={setFormValue}
              onCheck={setFormError}
            >
              {getEnv() === 'dev' &&
                <Form.Group>
                  <Form.ControlLabel>UUID:</Form.ControlLabel>
                  <p>{formValue.guid}</p>
                </Form.Group>
              }

              {['work_groups', 'job_statuses', 'tracking_flags'].includes(formValue.section) &&
                <Form.Group>
                  <Form.ControlLabel>Color:</Form.ControlLabel>
                  <Whisper trigger="click" speaker={
                    <Popover className="p-0">
                      <SwatchesPicker
                        onChange={(color) => setFormValue({ ...formValue, color: color.hex })}
                      />
                    </Popover>
                  }>
                    <Button style={{ backgroundColor: formValue.color ?? 'transparent' }} size="sm">
                      {formValue.color ?? 'Select'}
                    </Button>
                  </Whisper>
                  <Form.HelpText>Used as a color identifier on calendar display</Form.HelpText>
                </Form.Group>
              }

              <Form.Group>
                <Form.ControlLabel className="required">Section:</Form.ControlLabel>
                <Form.Control
                  cleanable={false}
                  block
                  name="section"
                  disabled={formValue.section === 'work_groups' && action === 'edit'}
                  accepter={SelectPicker}
                  data={sections}
                  container={() => container && container.current}
                  searchable
                />
              </Form.Group>

              {(!['work_groups', 'notes'].includes(formValue.section)) &&
                <Form.Group>
                  <Form.ControlLabel>{INTL.WORK_GROUP}:</Form.ControlLabel>
                  {formValue.section && sections && (sections.find((s: any) => s.value === formValue.section)?.label === 'P&L Expenses')
                    ? <Form.Control
                      block
                      searchable
                      name="group"
                      accepter={SelectPicker}
                      data={[
                        { label: 'Labour', value: 'labour' },
                        { label: 'Montly Expenses', value: 'monthly_expenses' }
                      ]}
                      container={() => container && container.current} />
                    : <Form.Control
                      block
                      name="group"
                      accepter={SelectPicker}
                      data={workGroupTypes}
                      labelKey="title"
                      valueKey="key"
                      cleanable={false}
                      container={() => container && container.current} />
                  }
                </Form.Group>
              }

              {(formValue.section === 'work_types') &&
                <Form.Group>
                  <Form.ControlLabel>Defaults:</Form.ControlLabel>
                  <Form.Control
                    block
                    name="defaults"
                    accepter={CheckPicker}
                    data={orderBy(state.fields.filter((f: any) => f.applicationGroup === formValue.group && f.isVisible), 'title', 'asc')}
                    labelKey={'title'}
                    valueKey={'id'}
                    container={() => container && container.current} />
                  <Form.HelpText>Automatically preselect related options to this type. Fields under selected Group are displayed as options.</Form.HelpText>
                </Form.Group>
              }

              <Form.Group>
                <Form.ControlLabel className="required">
                  <span>{formValue.section === 'tracking_flags' ? 'Color' : 'Title'}:</span>
                  {formValue.key &&
                    <CopyToClipboard text={formValue.key} onCopy={() => toaster.push(<Message type="info" showIcon closable>Copied</Message>)}>
                      <Whisper placement="top" trigger="hover" speaker={<Tooltip>Copy API Key</Tooltip>}>
                        <span><MdContentCopy style={{ color: '#337ab7' }} /></span>
                      </Whisper>
                    </CopyToClipboard>
                  }
                </Form.ControlLabel>
                <Form.Control name="title" />
              </Form.Group>

              <Form.Group>
                <Form.ControlLabel>Description:</Form.ControlLabel>
                <Form.Control name="description" />
                <Form.HelpText>
                  Tokens will be replaced where provide:
                  <ul style={{ paddingLeft: '16px' }}>
                    <li>{`{{month}}`}: full capitalized month name, ie. January</li>
                    <li>{`{{rate}}`}: if rate is used per item</li>
                  </ul>
                </Form.HelpText>
              </Form.Group>

              {(formValue.section === 'work_types') &&
                <Form.Group>
                  <Form.ControlLabel>Sales Per Man Hour Target:</Form.ControlLabel>
                  <Form.Control
                    accepter={InputNumber}
                    name="salesPerManHourTarget"
                    max={999.99}
                  />
                  <Form.HelpText>Take precedence over sales per man hour target set on company</Form.HelpText>
                </Form.Group>
              }

              <Form.Group>
                <Form.ControlLabel>Email Template:</Form.ControlLabel>
                <Form.Control
                  block
                  name="emailTemplateGuid"
                  accepter={SelectPicker}
                  data={templates.data.templates.edges.node.filter((t: any) => t.type === 'email' && t.isVisible).map((d: any) => ({
                    label: d.name,
                    value: d.guid
                  }))}
                  container={() => container && container.current} />
                <Form.HelpText>Email template to use when notifying {INTL.CUSTOMERS}</Form.HelpText>
              </Form.Group>

              {['work_groups'].includes(formValue.section) &&
                <Form.Group>
                  <Form.ControlLabel>Invoice Template:</Form.ControlLabel>
                  <Form.Control
                    block
                    cleanable={false}
                    name="invoiceTemplateGuid"
                    accepter={SelectPicker}
                    data={templates.data.templates.edges.node.filter((t: any) => t.type === 'invoice' && t.isVisible).map((d: any) => ({
                      label: d.name,
                      value: d.guid
                    }))}
                    container={() => container && container.current} />
                  <Form.HelpText>Invoice template to use for {INTL.CUSTOMERS}</Form.HelpText>
                </Form.Group>
              }

              {['work_groups'].includes(formValue.section) &&
                <Form.Group>
                  <Form.ControlLabel>Service Descriptions Template:</Form.ControlLabel>
                  <Form.Control
                    block
                    cleanable
                    name="serviceDescriptionsVersionGuid"
                    accepter={CheckPicker}
                    data={snippetTemplates}
                    valueKey='guid'
                    labelKey='guid'
                    groupBy='group'
                    renderMenuItem={(label: any, item: any) => {
                      return <div>{`Version: ${item.version}, Modified On: ${format(parseISO(item.modifiedAt), FORMAT.DAY_MONTH_TIME24)}`}</div>;
                    }}
                    renderValue={(value: any) => {
                      return <div>{value.map((v: any) => {
                        const item: any = (snippetTemplates || []).find((s: any) => v === s.guid);
                        return item
                          ? `${item.group} - Version: ${item.version}, Modified On: ${format(parseISO(item.modifiedAt), FORMAT.DAY_MONTH_TIME24)}`
                          : '';
                      }).join(' ')}</div>;
                    }}
                    container={() => container && container.current} />
                  <Form.HelpText>Template snippet to use for {INTL.CUSTOMERS}</Form.HelpText>
                </Form.Group>
              }

              {['work_groups'].includes(formValue.section) &&
                <Form.Group>
                  <Form.ControlLabel>Terms & Conditions Template:</Form.ControlLabel>
                  <Form.Control
                    block
                    cleanable
                    name="termsConditionsVersionGuid"
                    accepter={SelectPicker}
                    data={snippetTemplates}
                    valueKey='guid'
                    labelKey='guid'
                    groupBy='group'
                    renderMenuItem={(label: any, item: any) => {
                      return <div>{`Version: ${item.version}, Modified On: ${format(parseISO(item.modifiedAt), FORMAT.DAY_MONTH_TIME24)}`}</div>;
                    }}
                    renderValue={(value: any) => {
                      const item: any = (snippetTemplates || []).find((s: any) => s.guid === value);
                      return item
                        ? <div>{`${item.group} - Version: ${item.version}, Modified On: ${format(parseISO(item.modifiedAt), FORMAT.DAY_MONTH_TIME24)}`}</div>
                        : <div><span>Select</span></div>;
                    }}
                    container={() => container && container.current} />
                  <Form.HelpText>Template snippet to use for {INTL.CUSTOMERS}</Form.HelpText>
                </Form.Group>
              }

              {!['work_types', 'work_groups'].includes(formValue.section) &&
                <Form.Group>
                  <Form.ControlLabel>Visible:</Form.ControlLabel>
                  <SelectPicker
                    block
                    searchable={false}
                    value={formValue.isVisible.toString()}
                    data={[
                      { label: 'Yes', value: 'true' },
                      { label: 'No', value: 'false' }
                    ]}
                    onChange={(val: any) => setFormValue({ ...formValue, isVisible: val === 'true' })}
                    container={() => container && container.current}
                  />
                  <Form.HelpText>Visible to crews and customers on job list</Form.HelpText>
                </Form.Group>
              }

              {['work_types', 'work_groups'].includes(formValue.section) &&
                <Form.Group>
                  <Form.ControlLabel>Visible on Booking:</Form.ControlLabel>
                  <SelectPicker
                    block
                    searchable={false}
                    value={formValue.visibleOnBooking.toString()}
                    data={[
                      { label: 'Yes', value: 'true' },
                      { label: 'No', value: 'false' }
                    ]}
                    onChange={(val: any) => setFormValue({ ...formValue, visibleOnBooking: val === 'true' })}
                    container={() => container && container.current}
                  />
                  <Form.HelpText>Visible internally on work order form</Form.HelpText>
                </Form.Group>
              }

              {(formValue.section === 'work_types') &&
                <Form.Group>
                  <Form.ControlLabel>Visible on Job:</Form.ControlLabel>
                  <SelectPicker
                    block
                    searchable={false}
                    value={formValue.visibleOnJob.toString()}
                    data={[
                      { label: 'Yes', value: 'true' },
                      { label: 'No', value: 'false' }
                    ]}
                    onChange={(val: any) => setFormValue({ ...formValue, visibleOnJob: val === 'true' })}
                    container={() => container && container.current}
                  />
                  <Form.HelpText>Visible to crews and customers on job list</Form.HelpText>
                </Form.Group>
              }

              {(formValue.section === 'work_types') &&
                <Form.Group>
                  <Form.ControlLabel>Allow Free Entry:</Form.ControlLabel>
                  <SelectPicker
                    block
                    searchable={false}
                    value={(formValue.allowFreeEntry || '').toString()}
                    data={[
                      { label: 'Yes', value: 'true' },
                      { label: 'No', value: 'false' }
                    ]}
                    onChange={(val: any) => setFormValue({ ...formValue, allowFreeEntry: val === 'true' })}
                    container={() => container && container.current}
                  />
                  <Form.HelpText>Allow user to provide additional information during entry</Form.HelpText>
                </Form.Group>
              }

              {(formValue.section === 'work_types') &&
                <Form.Group>
                  <Form.ControlLabel>Start Date:</Form.ControlLabel>
                  <Form.Control
                    block
                    name="startDate"
                    accepter={DatePicker}
                    container={() => container && container.current}
                    oneTap
                    ranges={[]} />
                  <Form.HelpText>Default start date</Form.HelpText>
                </Form.Group>
              }

              {(formValue.section === 'work_types') &&
                <Form.Group>
                  <Form.ControlLabel>End Date:</Form.ControlLabel>
                  <Form.Control
                    block
                    name="endDate"
                    accepter={DatePicker}
                    container={() => container && container.current}
                    oneTap
                    ranges={[]} />
                  <Form.HelpText>Default end date</Form.HelpText>
                </Form.Group>
              }

              {(formValue.section === 'document_types') &&
                <Form.Group>
                  <Form.ControlLabel>Default:</Form.ControlLabel>
                  <SelectPicker
                    block
                    searchable={false}
                    value={formValue.isDefault.toString()}
                    data={[
                      { label: 'Yes', value: 'true' },
                      { label: 'No', value: 'false' }
                    ]}
                    onChange={(val: any) => setFormValue({ ...formValue, isDefault: val === 'true' })}
                    container={() => container && container.current}
                  />
                  <Form.HelpText>Visible to crews and customers on job list</Form.HelpText>
                </Form.Group>
              }

              {hasQuickBooksDesktopIntegration(state.companies) &&
                <fieldset>
                  <legend>QuickBooks</legend>
                  <Form.Group>
                    <Form.ControlLabel>Code:</Form.ControlLabel>
                    <Form.Control name="quickbooksCode" />
                  </Form.Group>
                  <Form.Group>
                    <Form.ControlLabel>Description:</Form.ControlLabel>
                    <Form.Control name="quickbooksDescription" />
                    <Form.HelpText>
                      Tokens will be replaced where provide:
                      <ul style={{ paddingLeft: '16px' }}>
                        <li>{`{{month}}`}: full capitalized month name, ie. January</li>
                        <li>{`{{rate}}`}: if rate is used per item</li>
                      </ul>
                    </Form.HelpText>
                  </Form.Group>
                </fieldset>
              }

              {getEnv() === 'dev' &&
                <fieldset>
                  <legend>Internal</legend>
                  <Form.Group>
                    <Form.ControlLabel className="required">Props:</Form.ControlLabel>
                    <Input as={TextareaAutosize} value={formValue.props} onChange={(val: string) => setFormValue({ ...formValue, props: val })} />
                    <Form.HelpText>
                      <div>
                        JSON logic block, <a href="/" onClick={(e: any) => {
                          e.preventDefault();
                          setFormValue({ ...formValue, props: JSON.stringify(JSON.parse(formValue.props), null, 2) })
                        }}>click here</a> to format
                      </div>
                    </Form.HelpText>
                  </Form.Group>
                </fieldset>
              }
            </Form>
          </div>
        }
      </Drawer.Body>
      <Drawer.Footer>
        <Button onClick={() => {
          if (!form.check()) {
            toaster.push(
              <Message type="error" showIcon closable>{`Form has errors which need to be corrected`}</Message>
            );
            return;
          }

          handleSubmit();
        }} appearance="primary" loading={saving} size="sm">Save</Button>
        <Button onClick={() => {
          setFormError({});
          onHide();
        }} appearance="subtle" disabled={saving} size="sm">Close</Button>
      </Drawer.Footer>
    </Drawer>
  );
}

export default SettingsFieldForm;
