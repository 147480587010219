import { useState, useContext } from 'react';
import {
  SelectPicker,
  Form,
  Grid,
  Row,
  Col,
  Input,
  CheckPicker,
  DateRangePicker,
  Schema,
  Whisper,
  Tooltip,
  DatePicker,
  Message,
} from 'rsuite';
import startCase from 'lodash/startCase';
import { ApplicationContext } from 'shared';
import { FORMAT, getEnv } from 'lib/env';
import { format } from 'date-fns';
import INTL from 'tenant/intl';
import { CONFIG_APPLICATION_FIELDS } from 'tenant/config';
import { parseISO } from 'date-fns';
import { useViewport } from 'shared/ViewportProvider';
import { filterFieldsByParentKey } from 'lib/helpers/field';
import TextareaAutosize from 'react-autosize-textarea';
import { usePrairieAuth } from 'contexts/AuthPrairieProvider';
import { IS_KALADI_KITCHENS, IS_KALADI_PROPERTIES } from 'lib/tenant';

interface IDocumentFields {
  container: any,
  statusFields: Array<any>,
  action: string,
  formValue: any,
  formError: any,
  handleSetRef: (ref: any) => void,
  handleServicesAutoPopulate: (val: number) => void,
  handleTemplateChange: (templateId: number | undefined) => void,
  handleVersionChange: (templateVersionId: any) => void,
  handleChange: (formValue: any) => void,
  handleCheck: (formError: any) => void,
  status: Array<any>,
  drawer: boolean | undefined,
  templateId: number | undefined,
  templateVersionId: number | undefined,
  templates: any,
  readOnly: boolean,
  tenants?: any
}

const { ArrayType, NumberType } = Schema.Types;
const model = Schema.Model({
  workType: ArrayType().minLength(1, 'This field is required'),
  startEndDate: ArrayType().minLength(1, 'This field is required'),
  templateId: NumberType().addRule((value: any) => {
    return +value > 0;
  }, 'This field is required', true),
});

const DocumentFields = ({
  container,
  action,
  formValue,
  formError,
  handleSetRef,
  handleServicesAutoPopulate,
  handleTemplateChange,
  handleVersionChange,
  handleChange,
  handleCheck,
  status,
  drawer,
  statusFields,
  templateId,
  templateVersionId,
  templates,
  readOnly,
  tenants
}: IDocumentFields) => {
  const { state } = useViewport();
  const { can, canNot, isRole } = usePrairieAuth();
  const { showConfirmation } = useContext(ApplicationContext);
  const [changeVersion, setChangeVersion] = useState(false);
  const documentTypes = filterFieldsByParentKey(state.fields, 'document_types').filter((d: any) => d.key === formValue.entryType || !d.deletedAt);
  const workGroupTypes = filterFieldsByParentKey(state.fields, 'work_groups');

  const getVersion = (tId: number, vId: number) => {
    const template = templates.find((t: any) => t.id === tId);

    if (template) {
      const version = template.versions.find((v: any) => v.id === vId);
      const versionIndex = template.versions.findIndex((v: any) => v.id === vId);

      if (version) {
        return (versionIndex === 0 ? 'Current version' : `Version ${version.version}`) + ' - Modified: ' + format(parseISO(version.modifiedAt), FORMAT.DATE_TIME);
      } else {
        return 'No version set';
      }
    }

    return 'No version selected';
  }

  const getTemplates = (templates: any, templateId: any) => {
    const result = templates.filter((t: any) => t.type === 'document'
      && t.name.indexOf('[OLD]') === -1
      && t.isVisible
      && t.deletedAt === null
    );

    // check if templateId exists, some of the deleted ones are not shown in default list
    const exists = result.find((t: any) => t.id === templateId);
    const curr = templates.find((t: any) => t.id === templateId);

    if (!exists && curr) {
      result.push(curr);
    }

    return result;
  }

  return (
    <div>
      {(!getVersion(templateId || 0, templateVersionId || 0).toLowerCase().includes('current')
        && getVersion(templateId || 0, templateVersionId || 0).toLowerCase() !== 'no version selected') &&
        <div className="mb-8">
          <Message type='error'>Document is using an older template version. Newer version is available.</Message>
        </div>
      }

      <Form
        ref={(ref: any) => handleSetRef(ref)}
        checkTrigger="change"
        formValue={formValue}
        onChange={handleChange}
        onCheck={handleCheck}
        formError={formError}
        model={model}
        fluid>
        <fieldset>
          <Grid fluid>
            <Row>
              <Col sm={24}>
                <legend>Document</legend>
              </Col>
              <Col sm={24} md={drawer ? 16 : 10}>
                {getEnv() === 'dev' &&
                  <div className="mb-12">
                    <label>UUID:</label>
                    <p>{formValue?.guid}</p>
                  </div>
                }

                {IS_KALADI_PROPERTIES &&
                    <Form.Group>
                      <Form.ControlLabel className='required'>Tenant:</Form.ControlLabel>
                      <Form.Control
                        name="tenantId"
                        accepter={SelectPicker}
                        data={tenants.filter((t: any) => t.status === 'active' || t.guid === formValue.tenantId)}
                        valueKey='guid'
                        labelKey='name'
                        style={{ width: '100%' }}
                        disabled={canNot('document:update')} />

                      {tenants.filter((t: any) => t.status === 'active' && t.guid === formValue.tenantId).length === 0 &&
                        <Form.HelpText className='text-danger'>
                          Tenant is inactive, changing the tenant cannot be undone
                        </Form.HelpText>
                      }
                    </Form.Group>
                }

                {!IS_KALADI_PROPERTIES &&
                  <Form.Group>
                    <Form.ControlLabel>Title:</Form.ControlLabel>
                    <Form.Control
                      name="title"
                      accepter={Input}
                      style={{ width: '100%' }}
                      disabled={canNot('document:update')} />
                  </Form.Group>
                }


                <Form.Group>
                  <Form.ControlLabel className="required">Type:</Form.ControlLabel>
                  <Form.Control
                    container={() => container?.current}
                    name="entryType"
                    accepter={SelectPicker}
                    value={formValue.entryType || documentTypes.find((d: any) => d.isDefault)?.key}
                    searchable
                    block
                    cleanable={false}
                    data={documentTypes}
                    labelKey='title'
                    valueKey='key'
                    disabled={canNot('document:update')} />
                </Form.Group>

                <Form.Group>
                  <Form.ControlLabel>Number:</Form.ControlLabel>
                  <Form.Control
                    name="documentNumber"
                    accepter={Input}
                    style={{ width: '100%' }}
                    disabled={canNot('document:update')} />
                  <Form.HelpText>Used for internal reference</Form.HelpText>
                </Form.Group>

                <Form.Group>
                  <Form.ControlLabel className="required">{INTL.WORK_GROUP}:</Form.ControlLabel>
                  <Form.Control
                    container={() => container?.current}
                    name="workType"
                    accepter={CheckPicker}
                    searchable
                    block
                    valueKey="key"
                    labelKey="title"
                    data={workGroupTypes}
                    disabled={canNot('document:update')}
                  />
                </Form.Group>

                <Form.Group>
                  <Form.ControlLabel className="required">Template:</Form.ControlLabel>
                  <Form.Control
                    container={() => container?.current}
                    style={{ width: '100%' }}
                    accepter={SelectPicker}
                    block
                    cleanable={false}
                    name="templateId"
                    data={getTemplates(templates, formValue.templateId).map((t: any) => ({ value: t.id, label: t.name }))}
                    onSelect={(value: any) => {
                      showConfirmation(
                        <p>All services set on document will be replaced by services in the template? Changes will take effect on save.</p>,
                        'Replace Services',
                        () => {
                          handleServicesAutoPopulate(+value);
                        }
                      );

                      handleTemplateChange(value);
                      handleVersionChange(templates.find((t: any) => t.id === value).versions[0].id);
                    }}
                    disabled={readOnly && canNot('document:update')}
                  />
                  {readOnly &&
                    <div>
                      {getVersion(templateId || 0, templateVersionId || 0)}
                    </div>
                  }

                  {(!changeVersion && templateVersionId && can('document:update') && !readOnly) &&
                    <>
                      <Form.HelpText>
                        <Whisper placement="bottom" speaker={<Tooltip>Click to change</Tooltip>}>
                          <a href="#" onClick={(e: any) => { e.preventDefault(); setChangeVersion(true); }}>{getVersion(templateId || 0, templateVersionId || 0)}</a>
                        </Whisper>
                      </Form.HelpText>
                      <Form.HelpText>
                        If template is not provided first attachment will be used as default document.
                      </Form.HelpText>
                    </>
                  }
                </Form.Group>

                {changeVersion &&
                  <Form.Group>
                    <Form.ControlLabel className="required">Version:</Form.ControlLabel>
                    <Form.Control
                      name="version"
                      container={() => container?.current}
                      style={{ width: '100%' }}
                      accepter={SelectPicker}
                      block
                      cleanable={false}
                      value={templateVersionId}
                      data={templateId ? templates.find((t: any) => t.id === templateId).versions.map((v: any, i: number) => ({
                        value: v.id,
                        label: (i === 0 ? 'Current version' : `Version ${templates.find((t: any) => t.id === templateId).versions.length - i}`) + ` - Modified: ${format(parseISO(v.modifiedAt), FORMAT.DATE_TIME)}`
                      })) : []}
                      onSelect={handleVersionChange}
                      disabled={canNot('document:update')}
                    />
                  </Form.Group>
                }

                <Form.Group>
                  <Form.ControlLabel className="required">Start/End Date:</Form.ControlLabel>
                  <Form.Control
                    container={() => container?.current}
                    name="startEndDate"
                    accepter={DateRangePicker}
                    cleanable={true}
                    block
                    renderValue={(date: Date[]) => format(date[0], FORMAT.DAY_MONTH_DATE) + ' ~ ' + format(date[1], FORMAT.DAY_MONTH_DATE)}
                    disabled={canNot('document:update')}
                  />
                </Form.Group>

                <Form.Group>
                  <Form.ControlLabel>Acceptance Date:</Form.ControlLabel>
                  <Form.Control
                    container={() => container?.current}
                    name="expiryDate"
                    accepter={DatePicker}
                    cleanable={true}
                    block
                    renderValue={(date: Date) => format(date, FORMAT.DAY_MONTH_DATE)}
                    disabled={canNot('document:update')} />
                  <Form.HelpText>When provided, limits document signing to midnight on date selected day/{state.timeZone}</Form.HelpText>
                </Form.Group>

                <Form.Group>
                  <Form.ControlLabel>Document Date:</Form.ControlLabel>
                  <Form.Control
                    container={() => container?.current}
                    name="documentDate"
                    accepter={DatePicker}
                    cleanable={true}
                    block
                    renderValue={(date: Date) => format(date, FORMAT.DAY_MONTH_DATE)}
                    disabled={canNot('document:update')} />
                  <Form.HelpText>When provided, overrides default document created at date on the PDF</Form.HelpText>
                </Form.Group>

                {IS_KALADI_KITCHENS &&
                  <>
                    <Form.Group>
                      <Form.ControlLabel>Base Rent Monthly Cost:</Form.ControlLabel>
                      <Form.Control
                        name="customField_baseRentMonthlyCost"
                        disabled={canNot('document:update')}
                      />
                      <Form.HelpText>Enter value before GST. GST will be added automatically.</Form.HelpText>
                    </Form.Group>

                    <Form.Group>
                      <Form.ControlLabel>Security Deposit:</Form.ControlLabel>
                      <Form.Control
                        name="customField_securityDeposit"
                        disabled={canNot('document:update')}
                      />
                    </Form.Group>
                  </>
                }

                <Form.Group>
                  <Form.ControlLabel>Assign User:</Form.ControlLabel>
                  <Form.Control
                    container={() => container?.current}
                    name="userId"
                    accepter={CheckPicker}
                    block
                    placeholder="Assign later"
                    data={state.users.filter((u: any) => u.userlevel !== 'worker')}
                    groupBy="role"
                    disabled={canNot('document:update')} />
                  <Form.HelpText>Click multiple Crews to assign document for multiple reviews</Form.HelpText>
                </Form.Group>

                <Form.Group>
                  <Form.ControlLabel>Crew Notes:</Form.ControlLabel>
                  <Input
                    as={TextareaAutosize}
                    rows={2}
                    value={formValue.customerComments}
                    style={{ width: '100%' }}
                    disabled={canNot('document:update')}
                    onChange={(val: string) => handleChange({ ...formValue, customerComments: val })}
                  />
                </Form.Group>

                {CONFIG_APPLICATION_FIELDS.document.includes('customerComments') &&
                  <Form.Group>
                    <Form.ControlLabel>Customer Notes:</Form.ControlLabel>
                    <Input
                      as={TextareaAutosize}
                      rows={2}
                      style={{ width: '100%' }}
                      disabled={canNot('document:update')}
                      value={formValue.additionalNotes}
                      onChange={(val: string) => handleChange({ ...formValue, additionalNotes: val })}
                    />
                  </Form.Group>
                }
              </Col>
            </Row>
          </Grid>
        </fieldset>
        <fieldset>
          <Grid fluid>
            <Row>
              <Col sm={24}>
                <legend>Status</legend>
              </Col>
              <Col sm={24} md={10}>
                {(status && status.length > 0) &&
                  <Form.Group>
                    {status.map((s: any, index: number) =>
                      <div key={`${s.guid}-${index}`}>
                        {startCase(s.title)} - {s.operatorName} on {format(parseISO(s.createdAt), FORMAT.DATE_TIME)} {s.notes && ' - ' + s.notes}
                      </div>
                    )}
                  </Form.Group>
                }

                {can('document:update', 'document:update-cost') &&
                  <Form.Group>
                    <Form.ControlLabel>New Status:</Form.ControlLabel>
                    <Form.Control
                      container={() => container?.current}
                      name="newStatus"
                      accepter={SelectPicker}
                      block
                      data={statusFields}
                      valueKey="key"
                      labelKey="title" />
                  </Form.Group>
                }
                {can('document:update', 'document:update-cost') &&
                  <Form.Group>
                    <Form.ControlLabel>Status Notes:</Form.ControlLabel>
                    <Input
                      as={TextareaAutosize}
                      rows={2}
                      style={{ width: '100%' }}
                      onChange={(val: string) => handleChange({ ...formValue, notes: val })}
                    />
                  </Form.Group>
                }
              </Col>
            </Row>
          </Grid>
        </fieldset>
      </Form>
    </div>
  );
}

export {
  DocumentFields
}
