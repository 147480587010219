import { useContext, useEffect, useRef, useState } from 'react';
import { Drawer, Form, SelectPicker, Button, Schema, CheckPicker, toaster, Message, Input, Grid, DatePicker, Row, Col, Whisper, Tooltip, IconButton } from 'rsuite';
import TextareaAutosize from 'react-autosize-textarea';
import { isMobileOnly } from 'react-device-detect';
import { gql, useApolloClient } from '@apollo/client';
import { pick } from 'lodash';
import { ApplicationContext } from 'shared';
import { v4 as uuidv4 } from 'uuid';
import { DAILY_FINANCIALS_OFFICE_ACTION_DEFAULT_GUID, DAILY_FINANCIALS_CREW_ACTION_DEFAULT_GUID } from 'lib/tenant';
import { COLOR, FORMAT, getEnv } from 'lib/env';
import { MdAdd, MdDelete, MdHelpOutline } from 'react-icons/md';

interface IDailyFinancialsRule {
  guid: string,
  date: Date | undefined,
  recipients: string | undefined
}

const INITIAL_DAILY_FINANCIALS_RULE: IDailyFinancialsRule = {
  guid: uuidv4(),
  date: undefined,
  recipients: undefined
};

const { StringType, ArrayType, NumberType } = Schema.Types;
let model = Schema.Model({
  title: StringType().isRequired('This field is required.'),
  rule: StringType()
    .addRule((value) => {
      try {
        JSON.parse(value);
        return true;
      } catch {
        return false;
      }
    }, 'Rule should be a valid JSON format')
    .isRequired('This field is required'),
  repeatPeriod: NumberType().addRule((value, data) => {
    return data.repeat === false || data.repeat && data.repeatPeriod;
  }, 'Occurence is required if repeat is set', true),
  emailTemplateGuid: StringType().isRequired('This field is required.'),
  events: ArrayType().minLength(1, 'This field is required.'),
  resource: ArrayType().minLength(1, 'This field is required.')
});

interface ISettingsFieldForm {
  action?: string,
  guid?: string,
  onHide: () => void,
  onUpdate?: (data: any) => void
}

const SettingsActionForm = ({
  action,
  guid,
  onHide,
  onUpdate
}: ISettingsFieldForm) => {
  let form: any = useRef();
  const client = useApolloClient();
  const container = useRef() as React.MutableRefObject<HTMLDivElement>;
  const { showError } = useContext(ApplicationContext);
  const [formValue, setFormValue] = useState<any>({
    title: '',
    isActive: true,
    events: ['insert'],
    repeat: false,
    repeatPeriod: 0,
    userRole: 'user',
    emailTemplateGuid: '',
    notify: false
  });
  const [formError, setFormError] = useState<any>({});
  const [saving, setSaving] = useState(false);
  const [templates, setTemplates] = useState<any[]>([]);
  const [dailyFinancialsRules, setDailyFinancialsRules] = useState<Array<IDailyFinancialsRule>>([{ ...INITIAL_DAILY_FINANCIALS_RULE, guid: uuidv4() }]);

  useEffect(() => {
    (async function getField() {
      const templatesData: any = await client.query({
        query: gql`
          query templates($limit: Int!, $offset: Int!) {
            templates(filter: {
              limit: $limit,
              offset: $offset
            }) {
              edges {
                node {
                  guid
                  id
                  name
                  subject
                  type
                  format
                }
              },
              totalCount
            }
          }`,
        variables: {
          offset: 0,
          limit: 999
        }
      });
      setTemplates(templatesData.data.templates.edges.node);

      if (guid) {
        const actions: any = await client.query({
          query: gql`
            query actions($sort: ActionsSort) {
              actions(filter: {
                sort: $sort
              }) {
                edges {
                  node {
                    guid
                    title
                    isActive
                    rule
                    userRole
                    templateGuid
                    events
                    resource
                    repeat
                    notify
                    repeatPeriod
                    deletedAt
                    template {
                      name
                      guid
                    }
                  }
                },
                totalCount
              }
            }`,
          variables: {
            sort: { title: 'ASC' }
          }
        });

        const row = actions.data.actions.edges.node.find((a: any) => a.guid === guid);
        setFormValue({
          guid: row.guid,
          title: row.title,
          isActive: row.isActive,
          events: row.events,
          resource: row.resource,
          rule: JSON.stringify(row.rule),
          repeat: row.repeat,
          repeatPeriod: row?.repeatPeriod || 0,
          userRole: row.userRole,
          emailTemplateGuid: row.template?.guid,
          notify: row.notify
        });

        if ([DAILY_FINANCIALS_OFFICE_ACTION_DEFAULT_GUID, DAILY_FINANCIALS_CREW_ACTION_DEFAULT_GUID].includes(row.guid)) {
          model = Schema.Model({
            title: StringType().isRequired('This field is required.')
          }) as any;

          if ((row.rule || []).length > 0) {
            setDailyFinancialsRules(row.rule as any);
          }
        }
      } else {
        setFormValue({ ...formValue, guid: uuidv4() });
      }
    })();
  }, [guid]);

  const handleSubmit = async () => {
    const formData = { ...formValue };
    try {
      setSaving(true);
      const upsertAction = gql`
        mutation upsertAction($input: UpsertActionInput!) {
          upsertAction(input: $input) {
            success
            code
            message
            result
          }
        }`;

      if ([DAILY_FINANCIALS_OFFICE_ACTION_DEFAULT_GUID, DAILY_FINANCIALS_CREW_ACTION_DEFAULT_GUID].includes(formData.guid)) {
        formData.rule = JSON.stringify(dailyFinancialsRules.map((df: any) => {
          df.recipients = (df.recipients || '').replace(/\s+/g, '');
          return df;
        }));
      }

      const response: any = await client.mutate({
        mutation: upsertAction, variables: {
          // the array is taken from the input list
          input: pick({
            ...formData,
          }, ['guid', 'title', 'isActive', 'rule', 'userRole', 'templateGuid', 'events', 'resource', 'repeat', 'notify', 'repeatPeriod', 'emailTemplateGuid'])
        }
      });


      if (response.data.upsertAction.success) {
        toaster.push(
          <Message type="success" showIcon closable>{response.data.upsertAction.message}</Message>
        );

        if (onUpdate) {
          onUpdate(response.data.upsertAction.response);
        }
      } else {
        showError(response);
      }
      setSaving(false);
    } catch (err) {
      showError(err);
      setSaving(false);
    } finally {
      onHide();
      setSaving(false);
    }
  }

  const handleUpdateDailyFinancialsRules = async (val: Date | string | undefined | null, el: any, guid?: any) => {
    const key = typeof (el) === 'string' ? el : el.target.name;
    guid = typeof (guid) === 'undefined' ? el.target.dataset.guid : guid;
    setDailyFinancialsRules(dailyFinancialsRules.map((c: any) => {
      if (c.guid === guid && key) {
        c[key] = val;
      }
      return c;
    }));
  }

  return (
    <Drawer backdrop='static' open onClose={onHide} full={isMobileOnly}>
      <Drawer.Header>
        <Drawer.Title>{(formValue?.guid === '-1' || !formValue?.guid) ? 'Add' : 'Edit'} Action</Drawer.Title>
      </Drawer.Header>
      <Drawer.Body>
        <div ref={container}>
          <Form
            ref={(ref: any) => form = ref}
            fluid
            model={model}
            formValue={formValue}
            formError={formError}
            onChange={setFormValue}
            onCheck={setFormError}
          >
            {getEnv() === 'dev' &&
              <Form.Group>
                <Form.ControlLabel className="required">UUID:</Form.ControlLabel>
                <div>{formValue.guid}</div>
              </Form.Group>
            }

            <Form.Group>
              <Form.ControlLabel className="required">Title:</Form.ControlLabel>
              <Form.Control name="title" />
            </Form.Group>

            <Form.Group>
              <Form.ControlLabel className="required">Active:</Form.ControlLabel>
              <SelectPicker
                block
                searchable={false}
                value={formValue.isActive.toString()}
                data={[
                  { label: 'Yes', value: 'true' },
                  { label: 'No', value: 'false' }
                ]}
                onChange={(val: any) => setFormValue({ ...formValue, isActive: val === 'true' })}
                container={() => container && container.current}
              />
            </Form.Group>

            {([DAILY_FINANCIALS_OFFICE_ACTION_DEFAULT_GUID, DAILY_FINANCIALS_CREW_ACTION_DEFAULT_GUID].includes(formValue.guid))
              ? <>
                <Form.Group>
                  <Form.ControlLabel className="required">Rule:</Form.ControlLabel>
                  <Grid fluid className='p0'>
                    <Row>
                      <Col md={4} className='pl-0 font-medium'>Delivery Time:</Col>
                      {[DAILY_FINANCIALS_OFFICE_ACTION_DEFAULT_GUID].includes(formValue.guid) &&
                        <Col md={20} className='font-medium'>
                          <Whisper placement="top" trigger="hover" speaker={<Tooltip>Separate multiple emails with commas</Tooltip>}>
                            <span>Recipient Emails: <MdHelpOutline /></span>
                          </Whisper>
                        </Col>
                      }
                    </Row>
                    {dailyFinancialsRules.map((row: any, index: number) =>
                      <Row style={{ marginTop: index === 0 ? 0 : '4px' }} key={`df-${row.guid}`}>
                        <Col md={4} className='pl-0'>
                          <DatePicker
                            size="sm"
                            block
                            format={FORMAT.TIME24}
                            cleanable={false}
                            onChange={(date: Date | null) => handleUpdateDailyFinancialsRules(date, 'date', row.guid)}
                            defaultValue={typeof (row.date) === 'string' ? new Date(row.date) : row.date}
                            hideMinutes={minute => minute % 15 !== 0}
                          />
                        </Col>
                        {[DAILY_FINANCIALS_OFFICE_ACTION_DEFAULT_GUID].includes(formValue.guid) &&
                          <Col md={18}>
                            <Input defaultValue={row.recipients} data-guid={row.guid} name="recipients" size="sm" onChange={handleUpdateDailyFinancialsRules} />
                          </Col>
                        }
                        <Col md={2}>
                          {index === 0
                            ? <IconButton appearance="link" icon={<MdAdd />} onClick={() => setDailyFinancialsRules([...dailyFinancialsRules, { ...INITIAL_DAILY_FINANCIALS_RULE, guid: uuidv4() }])} />
                            : <IconButton appearance="link" icon={<MdDelete />} style={{ color: COLOR.DANGER }} onClick={() => setDailyFinancialsRules(dailyFinancialsRules.filter((c: any) => c.guid !== row.guid))} />
                          }</Col>
                      </Row>
                    )}
                  </Grid>
                </Form.Group>

                {[DAILY_FINANCIALS_CREW_ACTION_DEFAULT_GUID].includes(formValue.guid) &&
                  <Form.Group>
                    <Form.ControlLabel className="required">Email Template:</Form.ControlLabel>
                    <Form.Control
                      cleanable={false}
                      block
                      name="emailTemplateGuid"
                      accepter={SelectPicker}
                      data={templates.filter((t: any) => t.type === 'email').map((t: any) => ({ label: t.name, value: t.guid }))} />
                  </Form.Group>
                }
              </>
              : <>
                <Form.Group>
                  <Form.ControlLabel className="required">Resource:</Form.ControlLabel>
                  <Form.Control
                    name="resource"
                    accepter={CheckPicker}
                    block
                    searchable={false}
                    data={[
                      { label: 'Customer', value: 'customer' },
                      { label: 'Document', value: 'document' },
                      { label: 'Job', value: 'job' },
                      { label: 'Work Order', value: 'work-order' }
                    ]} />
                </Form.Group>


                <Form.Group>
                  <Form.ControlLabel className="required">Rule:</Form.ControlLabel>
                  <Input as={TextareaAutosize} value={formValue.rule} onChange={(val: string) => setFormValue({ ...formValue, rule: val })} />
                  <Form.HelpText>
                    JSON logic block, <a href="/" onClick={(e: any) => {
                      e.preventDefault();
                      setFormValue({ ...formValue, rule: JSON.stringify(JSON.parse(formValue.rule), null, 2) })
                    }}>click here</a> to format
                  </Form.HelpText>
                </Form.Group>


                <Form.Group>
                  <Form.ControlLabel className="required">Notify:</Form.ControlLabel>
                  <SelectPicker
                    block
                    searchable={false}
                    value={formValue.notify.toString()}
                    data={[
                      { label: 'Yes', value: 'true' },
                      { label: 'No', value: 'false' }
                    ]}
                    onChange={(val: any) => setFormValue({ ...formValue, notify: val === 'true' })}
                    container={() => container && container.current}
                  />
                  <Form.HelpText>Create application notification in addition to email</Form.HelpText>
                </Form.Group>

                <Form.Group>
                  <Form.ControlLabel className="required">Events:</Form.ControlLabel>
                  <Form.Control
                    cleanable={false}
                    block
                    searchable={false}
                    name="events"
                    accepter={CheckPicker}
                    data={[
                      { label: 'Created', value: 'insert' },
                      { label: 'Updated', value: 'update' },
                      { label: 'Deleted', value: 'delete' },
                    ]} />
                  <Form.HelpText>Occurs on new, or updated, or removed records</Form.HelpText>
                </Form.Group>

                <Form.Group>
                  <Form.ControlLabel className="required">Repeat:</Form.ControlLabel>
                  <SelectPicker
                    block
                    searchable={false}
                    value={formValue.repeat.toString()}
                    data={[
                      { label: 'Yes', value: 'true' },
                      { label: 'No', value: 'false' }
                    ]}
                    onChange={(val: any) => setFormValue({ ...formValue, repeat: val === 'true' })}
                    container={() => container && container.current}
                  />
                </Form.Group>


                {formValue?.repeat &&
                  <Form.Group>
                    <Form.ControlLabel className="required">Repeat Occurence:</Form.ControlLabel>
                    <Form.Control
                      name="repeatPeriod"
                      accepter={SelectPicker}
                      searchable={false}
                      block
                      data={[
                        { label: '6 hours', value: 6 },
                        { label: '12 hours', value: 12 },
                        { label: '24 hours - 1 day', value: 24 },
                        { label: '48 hours - 2 days', value: 48 },
                        { label: '72 hours - 3 days', value: 72 },
                        { label: '168 hours - 7 days', value: 168 }
                      ]} />
                    <Form.HelpText>Each notification will be repeated periodically after initial notification</Form.HelpText>
                  </Form.Group>
                }

                <Form.Group>
                  <Form.ControlLabel className="required">Notification Target:</Form.ControlLabel>
                  <Form.Control
                    cleanable={false}
                    block
                    searchable={false}
                    name="userRole"
                    accepter={SelectPicker}
                    data={[
                      { label: 'Customer', value: "customer" },
                      { label: 'Office', value: "office" },
                      { label: 'User', value: "user" }
                    ]} />
                  <Form.HelpText>Send notification to user or customer attached to record or the office email on company file</Form.HelpText>
                </Form.Group>

                <Form.Group>
                  <Form.ControlLabel className="required">Email Template:</Form.ControlLabel>
                  <Form.Control
                    cleanable={false}
                    block
                    placement="topStart"
                    name="emailTemplateGuid"
                    accepter={SelectPicker}
                    data={templates.filter((t: any) => t.type === 'email').map((t: any) => ({ label: t.name, value: t.guid }))} />
                </Form.Group>
              </>
            }
          </Form>
        </div>
      </Drawer.Body>
      <Drawer.Footer>
        <Button loading={saving} onClick={() => {
          if (!form.check()) {
            toaster.push(
              <Message type="error" showIcon closable>{`Form has errors which need to be corrected`}</Message>
            );
            return;
          }

          handleSubmit();
        }} appearance="primary" size="sm">Save</Button>
        <Button disabled={saving} onClick={() => {
          setFormError({});
          onHide();
        }} appearance="subtle" size="sm">Close</Button>
      </Drawer.Footer>
    </Drawer >
  );
}

export default SettingsActionForm;
