import { lazy, Suspense, useState } from 'react';
import { Switch, Route, Redirect, Link } from 'react-router-dom';
import { useApolloClient } from '@apollo/client';
import { Container, Loader, Header, Content, Sidenav, Navbar, Button, Nav, Sidebar, Message as RSuiteMessage } from 'rsuite';
import { getEnv, getTenant, ROLE } from 'lib/env';
import CashFlow from 'containers/cashflow/CashFlow';
import { MainNavigation, SubNavigation } from 'shared/Navigation';
import { isMobileOnly } from 'react-device-detect';
import useLocalStorage from 'lib/hooks/useLocalStorage';
import SearchAll from 'shared/SearchAll';
import { useEffect } from 'react';
import { getAllFields } from 'lib/helpers/field';
import { getUsers } from 'lib/helpers/user';
import { useViewport } from './ViewportProvider';
import { IS_KALADI_KITCHENS } from 'lib/tenant';
import ApplicationDrawer from 'shared/ApplicationDrawer';
import { getCompanies } from 'lib/helpers/company';
import { Message } from 'drawers';
import Jobs from 'containers/jobs/Jobs';
import Auth from 'containers/auth/Auth';
import { usePrairieAuth } from 'contexts/AuthPrairieProvider';
import { endOfMonth, startOfMonth } from 'date-fns';
import store from 'lib/storage';
import { NavigationNewDropdown } from 'components/navigation/NavigationNewDropdown';
import { useLogger } from 'lib/logger';
import { MdMenu } from 'react-icons/md';
import Icon from '@rsuite/icons/lib/Icon';
import * as Sentry from "@sentry/react";
import { getTemplates } from 'lib/helpers';
import { uniq } from 'lodash';
import { SiteReviewList } from 'containers/sitereview';

const JobsPrint = lazy(() => import('../containers/jobs/JobsPrint'));
const Workbook = lazy(() => import('../containers/workbook/Workbook'));
const Workorder = lazy(() => import('../containers/workorder/WorkOrder'));
const Document = lazy(() => import('../containers/document/Document'));
const WorkBookExplorer = lazy(() => import('../containers/workbook/WorkbookExplorer'));
const Financials = lazy(() => import('../containers/financials/Financials'));
const Settings = lazy(() => import('../containers/settings/Settings'));
const Users = lazy(() => import('../containers/users/Users'));
const Reporting = lazy(() => import('../containers/reporting/Reporting'));
const Templates = lazy(() => import('../containers/templates/Templates'));
const Pnl = lazy(() => import('../containers/pnl/Pnl'));
const Customer = lazy(() => import('../containers/customer/Customer'));
const Invoice = lazy(() => import('../containers/invoice/Invoice'));
const WorkLog = lazy(() => import('../containers/worklog/WorkLog'));
const Test = lazy(() => import('../containers/test/Test'));
const Storage = lazy(() => import('../containers/storage/Storage'));
const Resourcing = lazy(() => import('../containers/resourcing'));

// const ProtectRoute = ({ component: Component, ...rest }: any) => {
//   return <Route {...rest} render={(props) => (rest?.token ? <Component {...props} /> : <Redirect to={{ pathname: `/auth` }} />)} />
// }

// const AdminProtectedRoute = ({ component: Component, ...rest }: any) => (
//   <Route {...rest} render={(props) => (
//     isRole(ROLE.ADMIN, ROLE.MANAGER) ?
//       <Component {...props} /> : <Redirect to={{ pathname: `/app/${getEnv()}/jobs/list` }} />
//   )} />
// );

// const OfficeProtectedRoute = ({ component: Component, ...rest }: any) => (
//   <Route {...rest} render={(props) => (
//     isRole(ROLE.ADMIN, ROLE.MANAGER, ROLE.FRANCHISE) ?
//       <Component {...props} /> : <Redirect to={{ pathname: `/app/${getEnv()}/jobs/list` }} />
//   )} />
// );

const ApplicationContainer = () => {
  const print = window.location.href.indexOf('/jobs/print') > -1;
  const client = useApolloClient();
  const { state, dispatch } = useViewport();
  const [expandMenu, setExpandMenu] = useLocalStorage('expandMenu', isMobileOnly ? false : true);
  const { profile, isAuthenticated, isRole, error } = usePrairieAuth();
  const marginLeft = (IS_KALADI_KITCHENS || print) ? 0 : '180px';
  const [company, setCompany] = useState<any>({});

  useEffect(() => {
    (async function getFieldsData() {
      if (profile?.user_id) {
        const [users, fields, companies, templates] = await Promise.all([
          getUsers(client),
          getAllFields(client),
          getCompanies(client),
          getTemplates(client)
        ]);

        const myProfile = users.find((u: any) => u.id === +profile.user_id);
        setCompany(companies.find((c: any) => c.id === myProfile.companyId));

        dispatch({
          type: 'SET_MULTIPLE',
          fields,
          templates,
          notificationCount: 0,
          users: users.filter((u: any) => u.deletedAt === null),
          profile: myProfile,
          companies,
          loaded: true
        });
      }
    })();
  }, [profile?.user_id]);

  return <div>
    {(isAuthenticated)
      ? <>
        {!state.loaded
          ? <Loader content="Loading..." />
          : <div>
            <div className={`frame ${[print ? 'print' : '', isMobileOnly ? 'mobile' : ''].join(' ')}`}>
              {(!IS_KALADI_KITCHENS && !print) &&
                <Sidebar
                  style={{ display: isMobileOnly ? 'none' : 'flex', flexDirection: 'column' }}
                  width={expandMenu ? 184 : 56}
                  collapsible
                >
                  {(!isMobileOnly && !print) &&
                    <Sidenav expanded={expandMenu} defaultOpenKeys={['work-manager', 'financials', 'resourcing', 'reporting']}>
                      <Sidenav.Body>
                        <div style={{ margin: '8px 2px' }}>
                          <Button appearance="subtle" onClick={() => {
                            setExpandMenu(!expandMenu);
                          }}>
                            <Icon as={MdMenu} />
                          </Button>
                        </div>
                        <MainNavigation expanded={expandMenu} type="sidebar" />
                      </Sidenav.Body>
                    </Sidenav>
                  }
                </Sidebar>
              }
              <Container style={{ marginLeft: isMobileOnly ? 0 : (expandMenu ? marginLeft : (print ? 0 : '41px')) }}>
                <Content>
                  {!print &&
                    <Header style={{ zIndex: 6 }}>
                      <Navbar appearance="subtle">
                        <Nav className='rs-navbar-brand'>
                          {isMobileOnly &&
                            <Button style={{ marginTop: '-2px' }} size="sm" appearance="default" onClick={() => {
                              setExpandMenu(!expandMenu);
                            }}>
                              <MdMenu />
                            </Button>
                          }
                          {!isMobileOnly &&
                            <a href="/"
                              style={{
                                textDecoration: 'none',
                                display: 'inline-block',
                                paddingTop: '3px',
                                paddingLeft: '4px',
                              }}
                              title={profile?.tenant}>
                                {company?.companyName}
                            </a>
                          }
                        </Nav>
                        <Nav>
                          {isRole(ROLE.MANAGER, ROLE.ADMIN, ROLE.FRANCHISE) &&
                            <>
                              <div style={{ display: 'inline-block', margin: '10px 8px 0 8px' }}>
                                <SearchAll />
                              </div>
                              <span className='add-new'>
                                <NavigationNewDropdown />
                              </span>
                            </>
                          }
                        </Nav>
                        <Nav pullRight style={{ paddingRight: (isMobileOnly || IS_KALADI_KITCHENS) ? 0 : (expandMenu ? '180px' : '40px') }}>
                          <SubNavigation />
                        </Nav>
                      </Navbar>
                    </Header>
                  }

                  <div className='rs-subnav' style={{ marginTop: print ? 0 : isMobileOnly ? '60px' : '55px', marginLeft: isMobileOnly ? '2px' : '8px', marginRight: isMobileOnly || IS_KALADI_KITCHENS ? '2px' : '8px' }}>
                    <Sentry.ErrorBoundary fallback={<RSuiteMessage type="error">Something went wrong. This error has been automatically logged for review and will be resolved shortly.</RSuiteMessage>}>
                      <Suspense fallback={<Loader content="Loading..." />}>
                        <Switch>
                          <Route path='/app/:env/customer' component={Customer} />
                          <Route path='/app/:env/document' component={Document} />
                          <Route path='/app/:env/workorder' component={Workorder} />
                          <Route path='/app/:env/work-order' component={Workorder} />
                          <Route path='/app/:env/workbook/explorer/:customerId/:tab' component={WorkBookExplorer} />
                          <Route path='/app/:env/workbook/explorer/:customerId' component={WorkBookExplorer} />
                          <Route path='/app/:env/workbook' component={Workbook} />
                          <Route path='/app/:env/jobs/print' component={JobsPrint} />
                          <Route path='/app/:env/on-demand' component={Jobs} />
                          <Route path='/app/:env/site-reviews' render={() => <SiteReviewList showScores={true} />} />
                          <Route path='/app/:env/jobs' render={() => {
                            if (IS_KALADI_KITCHENS && !store.session.get('jobCalendar-calendarView-filter') && !isMobileOnly) {
                              store.session.set('jobs-active-range-filter', JSON.stringify([startOfMonth(new Date()).toISOString(), endOfMonth(new Date()).toISOString()]));
                              store.session.set('jobs-range-filter', JSON.stringify([startOfMonth(new Date()).toISOString(), endOfMonth(new Date()).toISOString()]));
                              store.session.set('jobCalendar-calendarView-filter', 'month');
                            }

                            return <Jobs />;
                          }} />
                          <Route path='/app/:env/pnl' component={Pnl} />
                          <Route path='/app/:env/cash-flow' component={CashFlow} />
                          <Route path='/app/:env/financials' component={Financials} />
                          <Route path='/app/:env/invoice' component={Invoice} />
                          <Route path='/app/:env/settings/:view?' component={Settings} />
                          <Route path='/app/:env/users' component={Users} />
                          <Route path='/app/:env/templates' component={Templates} />
                          <Route path='/app/:env/reporting/:view?' component={Reporting} />
                          <Route path='/app/:env/worklog' component={WorkLog} />
                          <Route path='/app/:env/test' component={Test} />
                          <Route path='/app/:env/storage' component={Storage} />
                          <Route path='/app/:env/resourcing' component={Resourcing} />
                          <Route render={() => {
                            if (IS_KALADI_KITCHENS && !store.session.get('jobCalendar-calendarView-filter') && !isMobileOnly) {
                              store.session.set('jobs-active-range-filter', JSON.stringify([startOfMonth(new Date()).toISOString(), endOfMonth(new Date()).toISOString()]));
                              store.session.set('jobs-range-filter', JSON.stringify([startOfMonth(new Date()).toISOString(), endOfMonth(new Date()).toISOString()]));
                              store.session.set('jobCalendar-calendarView-filter', 'month');
                            }

                            if (IS_KALADI_KITCHENS) {
                              return <Redirect to={{ pathname: `/app/${getEnv()}/jobs/calendar` }} />
                            }

                            return <Redirect to={{ pathname: `/app/${getEnv()}/jobs/list` }} />
                          }} />
                        </Switch>
                      </Suspense>
                    </Sentry.ErrorBoundary>
                  </div>
                </Content>
              </Container>
            </div>

            {isMobileOnly && <MainNavigation type="drawer" show={expandMenu} onHide={() => {
              setExpandMenu(false);
            }} />}

            <ApplicationDrawer />
            <Message />
          </div>
        }
      </>
      : <>
        {(isAuthenticated === undefined && !error)
          ? <Loader content="Loading..." />
          : <div>
            <Header style={{ zIndex: 6 }}>
              <Navbar appearance="subtle">
                <Navbar.Brand>
                  &nbsp;
                  <a href="#">
                    {window.location.host.indexOf('bugaboo') > -1 && <span>Bugaboo Landscaping Ltd.</span>}
                    {window.location.host.indexOf('assiniboine') > -1 && <span>Assiniboine Landscaping Ltd.</span>}
                    {window.location.host.indexOf('kaladikitchens') > -1 && <span>Kaladi Kitchens</span>}
                    {window.location.host.indexOf('kaladiproperties') > -1 && <span>Kaladi Properties</span>}
                  </a>
                </Navbar.Brand>
              </Navbar>
            </Header>
            {error
              ? <div className={'text-center'} style={{ paddingTop: '10rem', color: '#666' }}>
                <h4>Something went wrong...</h4>
                <p>
                  Server is not responding, it's likely not an issue on your end.
                  <br /><a href="/" onClick={(e: any) => {
                    e.preventDefault();
                    store.clearAll();
                    store.session.clearAll();
                    window.location.href = window.location.href;
                  }}>Click here</a> to clear cache and refresh while we check the issue.
                </p>
              </div>
              : <Auth />
            }
          </div>
        }
      </>
    }
  </div>
}

export default ApplicationContainer;
