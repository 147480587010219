import { useContext } from 'react';

import {
  ButtonGroup,
  ButtonToolbar,
  IconButton,
  Tooltip,
  Whisper,
  Dropdown,
  toaster,
  Message,
} from 'rsuite';

import { DRAWER, FORMAT, getEnv, ROLE } from 'lib/env';
import { ApplicationContext, PopoverDropdownMenu } from 'shared';
import CopyToClipboard from 'react-copy-to-clipboard';
import { Link } from 'react-router-dom';
import { gql, useApolloClient } from '@apollo/client';
import { useViewport } from 'shared/ViewportProvider';
import { format, parseISO } from 'date-fns';
import { usePrairieAuth } from 'contexts/AuthPrairieProvider';
import { MdAttachFile, MdAttachment, MdBuild, MdCameraAlt, MdClose, MdComment, MdEdit, MdFileUpload } from 'react-icons/md';
import Icon from '@rsuite/icons/lib/Icon';
import { IS_KALADI_PROPERTIES } from 'lib/tenant';

interface IJobActions {
  job: any,
  handleShowDrawer: (jobGuid: string, tab: string) => void,
  handleJobRefresh: (jobGuid: string) => void
}

const JobActionsDesktop = ({ job, handleShowDrawer, handleJobRefresh }: IJobActions) => {
  const client = useApolloClient();
  const { isRole } = usePrairieAuth();
  const { dispatch } = useViewport();
  const { showError, showMessage, showDrawer, showConfirmation, showConfirmationWithReason } = useContext(ApplicationContext);
  const showAttachmentsDrawer = IS_KALADI_PROPERTIES;

  const handleShareDocument = async (guid: string) => {
    try {
      const shareResource = gql`
        mutation shareResource($resource: String!, $guid: ID!) {
          shareResource(resource: $resource, guid: $guid) {
            success
            code
            message
          }
        }`;

      const response: any = await client.mutate({
        mutation: shareResource, variables: { resource: 'work-order', guid }
      });

      if (response.data.shareResource.success) {
        showMessage(
          <div>
            <CopyToClipboard text={response.data.shareResource.message} onCopy={() => toaster.push(<Message type="info" showIcon closable>Copied</Message>)}>
              <div>
                Click to copy public share link:<br /><strong>{response.data.shareResource.message}</strong>
              </div>
            </CopyToClipboard>
          </div>, `Share job`);
      } else {
        showError(response.message);
      }
    } catch (error) {
      showError(error);
    }
  }

  return (
    <ButtonToolbar style={{ textAlign: 'right' }} className="link-group">
      <ButtonGroup>
        {(job.applicationGroup !== 'construction' && job.applicationGroup !== 'roofing__59634106__1710128534') &&
          <Whisper placement="bottom" trigger="hover" speaker={<Tooltip>Photos</Tooltip>}>
            <IconButton
              appearance={'link'}
              onClick={() => handleShowDrawer(job.guid, 'photos')}
              icon={<MdCameraAlt />}
            />
          </Whisper>
        }

        {showAttachmentsDrawer &&
          <Whisper placement="bottom" trigger="hover" speaker={<Tooltip>Attachments</Tooltip>}>
            <IconButton
              appearance={'link'}
              onClick={() => showDrawer(DRAWER.ATTACHMENT_UPLOAD_FORM, { resourceGuid: job.guid, titleSuffix: job?.customer?.displayName })}
              icon={<MdAttachFile />}
            />
          </Whisper>
        }

        <Whisper placement="bottom" trigger="hover" speaker={<Tooltip>Notes</Tooltip>}>
          <IconButton
            appearance={'link'}
            onClick={() => handleShowDrawer(job.guid, 'notes')}
            icon={<MdComment />}
          />
        </Whisper>

        {(job.applicationGroup === 'construction' || job.applicationGroup === 'roofing__59634106__1710128534') &&
          <Whisper placement="bottom" trigger="hover" speaker={<Tooltip>Work Log</Tooltip>}>
            <IconButton
              appearance={'link'}
              as={Link}
              to={`/app/${getEnv()}/worklog/edit/${job.workOrderGuid}`}
              icon={<MdBuild />}
            />
          </Whisper>
        }

        {(!isRole(ROLE.CLIENT) && !showAttachmentsDrawer) &&
          <Whisper placement="bottom" trigger="hover" speaker={<Tooltip>Invoice</Tooltip>}>
            <IconButton
              appearance={'link'}
              onClick={() => {
                showDrawer(DRAWER.INVOICE_FORM, { title: job.customerName, action: 'edit', invoiceGuid: job.invoiceGuid })
              }}
              icon={<MdEdit />}
            />
          </Whisper>
        }

        {!isRole(ROLE.CLIENT, ROLE.WORKER) &&
          <PopoverDropdownMenu>
            {showAttachmentsDrawer && 
              <Dropdown.Item onSelect={() => showDrawer(DRAWER.INVOICE_FORM, { title: job.customerName, action: 'edit', invoiceGuid: job.invoiceGuid })}>Edit Invoice</Dropdown.Item>
            }
            <Dropdown.Item onSelect={() => showDrawer(DRAWER.TIME_CARD_FORM, { jobGuid: job.guid })}>Time Card</Dropdown.Item>
            <Dropdown.Item eventKey={`/app/${getEnv()}/document/share/${job.workOrderGuid}`} onSelect={() => handleShareDocument(job.workOrderGuid)}>Share</Dropdown.Item>
            <Dropdown.Item onSelect={() => {
              showDrawer(DRAWER.RESOURCE_HISTORY, { resourceGuid: job.guid, resource: 'job' });
            }}>History</Dropdown.Item>

            {job.status === 'canceled'
              ? <Dropdown.Item onSelect={() => {
                showConfirmation(`Are you sure you want to uncancel ${job.customer.displayName} on ${format(parseISO(job.startDate), FORMAT.MONTH_DATE)}?`, 'Unancel Job', async () => {
                  const response = await client.mutate({
                    mutation: gql`mutation updateJob($guid: ID!, $status: String) { updateJob(guid: $guid, status: $status) { success, code, message }}`,
                    variables: { guid: job.guid, status: null }
                  });
                  toaster.push(
                    <Message type="success" showIcon closable>{response.data.updateJob.message}</Message>
                  );
                  handleJobRefresh(job.guid);
                });
              }}>Uncancel</Dropdown.Item>
              : <Dropdown.Item onSelect={() => {
                showConfirmationWithReason(`Are you sure you want to cancel ${job.customer.displayName} on ${format(parseISO(job.startDate), FORMAT.MONTH_DATE)}?`, 'Cancel Job', async (reason: string | undefined) => {
                  const response = await client.mutate({
                    mutation: gql`mutation updateJob($guid: ID!, $status: String, $canceledReason: String) { updateJob(guid: $guid, status: $status, canceledReason: $canceledReason) { success, code, message }}`,
                    variables: { guid: job.guid, status: 'canceled', canceledReason: reason }
                  });
                  toaster.push(
                    <Message type="success" showIcon closable>{response.data.updateJob.message}</Message>
                  );
                  handleJobRefresh(job.guid);
                });
              }}>Cancel</Dropdown.Item>
            }

            {(getEnv() === 'dev') &&
              <Dropdown.Item onSelect={async () => {
                const response = await client.mutate({
                  mutation: gql`mutation updateJobInvoices($guid: ID!) { updateJobInvoices(guid: $guid) { success, code, message }}`,
                  variables: { guid: job.guid }
                });
                toaster.push(
                  <Message type="success" showIcon closable>{response.data.updateJobInvoices.message}</Message>
                );
                handleJobRefresh(job.guid);
              }}>Update Invoice</Dropdown.Item>
            }

            <Dropdown.Item onSelect={() => {
              showConfirmation(`Are you sure you want to reset ${job.customer.displayName} on ${format(parseISO(job.startDate), FORMAT.MONTH_DATE)}? This will clear all completion information on this job.`, 'Reset Job', async () => {
                const response = await client.mutate({
                  mutation: gql`mutation resetJob($guid: ID!) { resetJob(guid: $guid) { success, code, message }}`,
                  variables: { guid: job.guid }
                });
                toaster.push(
                  <Message type="success" showIcon closable>{response.data.resetJob.message}</Message>
                );
                handleJobRefresh(job.guid);
              });
            }}>Reset</Dropdown.Item>

            {isRole(ROLE.ADMIN) &&
              <Dropdown.Item onSelect={() => {
                showConfirmation(`Are you sure you want to delete ${job.customer.displayName} on ${format(parseISO(job.startDate), FORMAT.MONTH_DATE)}?`, 'Delete Job', async () => {
                  const response = await client.mutate({
                    mutation: gql`mutation deleteJob($guid: ID!) { deleteJob(guid: $guid) { success, code, message }}`,
                    variables: { guid: job.guid }
                  });
                  toaster.push(
                    <Message type="success" showIcon closable>{response.data.deleteJob.message}</Message>
                  );
                  dispatch({ type: 'SET_MODIFIED_AT' });
                });
              }}>Delete</Dropdown.Item>
            }
          </PopoverDropdownMenu>
        }
      </ButtonGroup>
    </ButtonToolbar >
  );
}

const JobActionsMobile = ({ job, handleShowDrawer, handleJobRefresh }: IJobActions) => {
  const client = useApolloClient();
  const { isRole } = usePrairieAuth();
  const { showDrawer, showConfirmation, showConfirmationWithReason } = useContext(ApplicationContext);
  const { dispatch } = useViewport();
  const showAttachmentsDrawer = IS_KALADI_PROPERTIES;

  return (
    <>
      {/* <Divider className="mt-10 mb-10" /> */}

      <ButtonToolbar className="mt-18">
        <ButtonGroup>
          {(job.applicationGroup !== 'construction' && job.applicationGroup !== 'roofing__59634106__1710128534') && 
            <IconButton size="sm" appearance={'link'} icon={<Icon as={MdCameraAlt} />} onClick={() => handleShowDrawer(job.guid, 'photos')}>Photos</IconButton>
          }

          <IconButton size="sm" icon={<Icon as={MdComment} />} appearance={'link'} onClick={() => handleShowDrawer(job.guid, 'notes')}>Notes</IconButton>

          {(['construction', 'roofing__59634106__1710128534'].includes(job.applicationGroup)) && 
            <IconButton
              as={Link}
              icon={<Icon as={MdBuild} />}
              appearance={'link'}
              to={`/app/${getEnv()}/worklog/edit/${job.workOrderGuid}`}>Work Log</IconButton>
          }

          {(!isRole(ROLE.CLIENT, ROLE.WORKER) && !showAttachmentsDrawer) &&
            <IconButton
              size="sm"
              appearance='link'
              onClick={() => {
                showDrawer(DRAWER.INVOICE_FORM, { title: job.customerName, action: 'edit', invoiceGuid: job.invoiceGuid })
              }}
              icon={<Icon as={MdEdit} />}>
              Invoice
            </IconButton>
          }

          {job.status === 'canceled'
            ? <IconButton
              appearance='link'
              icon={<Icon as={MdClose} />}
              size="sm"
              color='orange' onClick={() => {
                showConfirmation(`Are you sure you want to uncancel ${job.customer.displayName} on ${format(parseISO(job.startDate), FORMAT.MONTH_DATE)}?`, 'Uncancel Job', async () => {
                  const response = await client.mutate({
                    mutation: gql`mutation updateJob($guid: ID!, $status: String) { updateJob(guid: $guid, status: $status) { success, code, message }}`,
                    variables: { guid: job.guid, status: 'null' }
                  });
                  toaster.push(
                    <Message type="success" showIcon closable>{response.data.updateJob.message}</Message>
                  );
                  handleJobRefresh(job.guid);
                });
              }}>Cancel</IconButton>
            : <IconButton
              size="sm"
              icon={<Icon as={MdClose} />}
              appearance='link' color='orange' onClick={() => {
                showConfirmationWithReason(`Are you sure you want to cancel ${job.customer.displayName} on ${format(parseISO(job.startDate), FORMAT.MONTH_DATE)}?`, 'Cancel Job', async (reason: string | undefined) => {
                  const response = await client.mutate({
                    mutation: gql`mutation updateJob($guid: ID!, $status: String, $canceledReason: String) { updateJob(guid: $guid, status: $status, canceledReason: $canceledReason) { success, code, message }}`,
                    variables: { guid: job.guid, status: 'canceled', canceledReason: reason }
                  });
                  toaster.push(
                    <Message type="success" showIcon closable>{response.data.updateJob.message}</Message>
                  );
                  handleJobRefresh(job.guid);
                });
              }}>Cancel</IconButton>
          }
        </ButtonGroup>
      </ButtonToolbar>
    </>
  );
}

export {
  JobActionsDesktop,
  JobActionsMobile
}
