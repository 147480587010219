import { isAfter, isBefore, isEqual, min, max, parseISO } from "date-fns";

const isBetween = (date: Date, from: Date, to: Date, inclusivity = '[]') => {
  if (!['()', '[]', '(]', '[)'].includes(inclusivity)) {
      throw new Error('Inclusivity parameter must be one of (), [], (], [)');
  }

  const isBeforeEqual = inclusivity[0] === '[',
      isAfterEqual = inclusivity[1] === ']';

  return (isBeforeEqual ? (isEqual(from, date) || isBefore(from, date)) : isBefore(from, date)) &&
      (isAfterEqual ? (isEqual(to, date) || isAfter(to, date)) : isAfter(to, date));
};

const daysBetween = (start: string, end: string, inclusive?: boolean | undefined) => {
  if (typeof (inclusive) === 'undefined') {
    inclusive = true;
  }

  const date1: any = parseISO(start);
  const date2: any = parseISO(end);
  const diffTime = Math.abs(date2 - date1);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  return diffDays + (end.substring(11,19) === '00:00:00' && inclusive ? 1 : 0);
};

const intervalsOverlap = (from1: Date, to1: Date, from2: Date, to2: Date) => {
  // min(ends)>max(starts)
  return min([to1, to2]) > max([from1, from2]);
}

export {
  isBetween,
  daysBetween,
  intervalsOverlap
};
