import { Fragment, useEffect, useState } from 'react';
import { DatePicker, DateRangePicker } from 'rsuite';
import { FORMAT, ROLE } from 'lib/env';
import { addMinutes, format, parseISO, subMinutes } from 'date-fns';
import { IS_GREYROCK, IS_KALADI_KITCHENS } from 'lib/tenant';
import { CONFIG_TIME_PADDING } from 'tenant/config';
import { useViewport } from 'shared/ViewportProvider';
import { toLocalTimeZone, toTimeZone } from 'lib/date';
import { usePrairieAuth } from 'contexts/AuthPrairieProvider';

interface IWorkDate {
  job: any
  handleJobReschedule: any,
}

const WorkDate = ({
  job,
  handleJobReschedule
}: IWorkDate) => {
  const { state } = useViewport();
  const { isRole } = usePrairieAuth();
  const [readOnly, setReadOnly] = useState(isRole(ROLE.CLIENT) || IS_KALADI_KITCHENS);
  
  useEffect(() => {
    if (IS_GREYROCK && !isRole(ROLE.MANAGER, ROLE.ADMIN)) {
      setReadOnly(true);
    }
  }, []);

  return  <div>
    {readOnly
      ? <span>
        {format(subMinutes(parseISO(job.startDate), CONFIG_TIME_PADDING.start), FORMAT.MONTH_DATE)}
        </span>
      : <Fragment>
        {(job.status === 'completed' && !isRole(ROLE.MANAGER, ROLE.ADMIN))
          ? <span style={{ whiteSpace: 'nowrap' }}>{format(parseISO(job.startDate), FORMAT.MONTH_DATE)}</span>
          : <Fragment>
            {(format(parseISO(job.startDate), FORMAT.ISO_DATE) === format(parseISO(job.endDate), FORMAT.ISO_DATE))
              ? <DatePicker
                block
                cleanable={false}
                value={toTimeZone(parseISO(job.startDate), state.timeZone)}
                onChange={(date: any) => {
                  const prevDate = parseISO(job.startDate);
                  const newDate = new Date(date.getFullYear(), date.getMonth(), date.getDate(), prevDate.getHours(), prevDate.getMinutes(), prevDate.getSeconds(), 0);
                  handleJobReschedule(job.guid, toLocalTimeZone(newDate, state.timeZone));
                }}
                renderValue={(date: any) => format(date, FORMAT.MONTH_DATE)}
                size="sm"
              /> :
              <DateRangePicker
                block
                renderValue={(date: Date[]) => format(date[0], FORMAT.MONTH_DATE)}
                cleanable={false}
                defaultValue={[parseISO(job.startDate), parseISO(job.endDate)]}
                onChange={(dates: any) => {
                  handleJobReschedule(job.guid, dates);
                }}
                size="sm"
              />
            }
          </Fragment>
        }
      </Fragment>
    }
  </div>
}

export default WorkDate;
