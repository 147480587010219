import { Schema } from 'rsuite';
import { TENANT } from "lib/tenant";
import { CONFIG_APPLICATION_GROUPS } from 'tenant/config';

const { StringType, ObjectType, NumberType } = Schema.Types;

let workOrdersPerTenant: any = {
  default: {
    maintenance: Schema.Model({
      workType: Schema.Types.ArrayType().minLength(1, 'Select at least one job type')
    }),
    christmas_lights: Schema.Model({
      workType: Schema.Types.StringType().isRequired('This field is required'),
      strandOwnership: StringType().addRule((value: any, data: any) => {
        const defaultGroup = CONFIG_APPLICATION_GROUPS.find((g: any) => g.value === data.applicationGroup);
        if (!defaultGroup.required.includes('strandOwnership')) return true;

        if (data.workType === 'gemstone') {
          return true;
        }

        return value !== undefined && value.trim().length > 0;
      }, 'This field is required', true),
      numberOfStrands: StringType().addRule((value: any, data: any) => {
        const defaultGroup = CONFIG_APPLICATION_GROUPS.find((g: any) => g.value === data.applicationGroup);
        if (!defaultGroup.required.includes('numberOfStrands')) return true;

        if (data.workType === 'setup') {
          return value !== undefined && value.trim().length > 0;
        }

        return true;
      }, 'This field is required', true),
      numberOfTrees: StringType().addRule((value: any, data: any) => {
        const defaultGroup = CONFIG_APPLICATION_GROUPS.find((g: any) => g.value === data.applicationGroup);
        if (!defaultGroup.required.includes('numberOfTrees')) return true;

        if (data.workType === 'setup') {
          return value !== undefined && value.trim().length > 0;
        }

        return true;
      }, 'This field is required', true),
      typeOfLights: StringType().addRule((value: any, data: any) => {
        const defaultGroup = CONFIG_APPLICATION_GROUPS.find((g: any) => g.value === data.applicationGroup);
        if (!defaultGroup.required.includes('typeOfLights')) return true;

        if (data.workType === 'setup') {
          return value !== undefined && value.trim().length > 0;
        }

        return true;
      }, 'This field is required', true),
      perCostTypeCost: NumberType().addRule((value: any, data: any) => {
        const defaultGroup = CONFIG_APPLICATION_GROUPS.find((g: any) => g.value === data.applicationGroup);
        if (!defaultGroup.required.includes('perCostTypeCost')) return true;

        if (data.workType === 'setup') {
          return value !== undefined && value.toString().trim().length > 0;
        }

        return true;
      }, 'This field is required', true)
    }),
    construction: Schema.Model({}),
    snow_removal: Schema.Model({
      workType: Schema.Types.ArrayType().minLength(1, 'Select at least one job type')
    }),
    default: Schema.Model({})
  },
  kaladikitchens: {
    default: Schema.Model({
      workType: StringType().isRequired('This field is required'),
      startEndDate: ObjectType()
        .addRule((data: any) => {
          return Array.isArray(data) && new Date(data[0]) <= new Date(data[1])
        }, 'End time must be greater then start time')
    })
  }
};

const workOrders = workOrdersPerTenant[workOrdersPerTenant.hasOwnProperty(TENANT) ? TENANT : 'default'];

export default workOrders;
