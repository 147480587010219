import { useEffect, useState, Fragment, useContext } from 'react';
import { useApolloClient, gql } from '@apollo/client';
import { Table, Drawer, Button, toaster, Message } from 'rsuite';
import { FORMAT, getEnv, ROLE } from 'lib/env';
import { orderBy, reverse, startCase } from 'lodash';
import { isMobileOnly } from 'react-device-detect';
import { IS_KALADI_KITCHENS } from 'lib/tenant';
import { format, parseISO } from 'date-fns';
import CopyToClipboard from 'react-copy-to-clipboard';
import { ApplicationContext, CallToAction, ResponsiveTable } from 'shared';
import { usePrairieAuth } from 'contexts/AuthPrairieProvider';

interface IJobSummaryLookup {
  show: boolean,
  activeWorkOrderGuid: string | null,
  handleHideDrawer: () => void
}

const GET_JOBS = gql`
  query jobs($limit: Int!, $where: JobsWhere, $order: [JobsSort]) {
    jobs(filter: {
      limit: $limit,
      where: $where
      order: $order
    }) {
      edges {
        node {
          id
          guid
          startDate
          endDate
          status
          completedAt
        }
      },
      totalCount
    }
  }
`;
const { Column, HeaderCell, Cell } = Table;

const JobSummaryLookup = ({
  activeWorkOrderGuid,
  show,
  handleHideDrawer
}: IJobSummaryLookup) => {
  const client = useApolloClient();
  const { showConfirmation } = useContext(ApplicationContext);
  const [jobs, setJobs] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const { isRole } = usePrairieAuth();

  useEffect(() => {
    if (activeWorkOrderGuid) {
      setJobs([]);

      (async function getWorkOrders() {
        try {
          setJobs([]);
          setLoading(true);

          const data = await client.query({
            query: GET_JOBS,
            variables: {
              limit: 500,
              where: {
                AND: [
                  {
                    workOrderGuid: { is: activeWorkOrderGuid }
                  }
                ]
              },
              order: [
                { startDate: "DESC" }
              ]
            },
            fetchPolicy: 'no-cache'
          });

          setJobs(data.data.jobs.edges.node);
        } catch (err) {
          console.log(err);
        }

        setLoading(false)
      })();
    }
  }, [activeWorkOrderGuid]);

  return (
    <Drawer
      open={show}
      onClose={handleHideDrawer}
      full={isMobileOnly}
      size={'md'}
    >
      <Drawer.Header>
        <Drawer.Title>Jobs</Drawer.Title>
      </Drawer.Header>
      <Drawer.Body>
        <ResponsiveTable
          loading={loading}
          data={reverse(jobs)}
          wordWrap
          html5
        >
          <Column>
            <HeaderCell>ID</HeaderCell>
            <Cell>
              {(row: any) => <CopyToClipboard text={row.guid} onCopy={() => toaster.push(<Message type="info" showIcon closable>Copied</Message>)}><span>{row.id}</span></CopyToClipboard>}
            </Cell>
          </Column>
          <Column flexGrow={1}>
            <HeaderCell>Date</HeaderCell>
            <Cell>
              {(row: any) => IS_KALADI_KITCHENS
                ? <span>{format(parseISO(row.startDate), 'EEE, MMM d, yyyy h:mma')} - {format(parseISO(row.endDate), 'h:mma')}</span>
                : <span>{format(parseISO(row.startDate), FORMAT.DAY_MONTH_DATE)}</span>
              }
            </Cell>
          </Column>
          <Column flexGrow={1}>
            <HeaderCell>Status</HeaderCell>
            <Cell>
              {(row: any) => {
                return <>
                  {(() => {
                    switch (row?.status) {
                      case 'completed':
                        return <span className="text-success">{startCase(row.status)}</span>;
                      case 'not_completed':
                        return <span className="text-danger">{startCase(row.status)}</span>;
                      case 'canceled':
                        return <span className="text-warning">{startCase(row.status)}</span>;
                      default:
                        return <span>{startCase(row.status)}</span>;
                    }
                  })()}
                </>
              }}
            </Cell>
          </Column>
          <Column flexGrow={1}>
            <HeaderCell>Completed</HeaderCell>
            <Cell>
              {(row: any) => {
                return <span>{row.completedAt === null ? '' : format(row.completedAt, FORMAT.SHORT_DAY_MONTH_TIME)}</span>;
              }}
            </Cell>
          </Column>
          {(isRole(ROLE.ADMIN, ROLE.MANAGER) && jobs.length > 1) &&
            <Column width={50}>
              <HeaderCell>&nbsp;</HeaderCell>
              <Cell>
                {(row: any) =>
                  <CallToAction
                    text={'Delete'}
                    tooltip={'Delete'}
                    icon="trash"
                    link={`/app/${getEnv()}/workorder/delete_job/${row.guid}`}
                    onClick={() => showConfirmation(
                      <p>Are you sure you want to delete the job for {format(parseISO(row.startDate), FORMAT.DAY_MONTH_DATE)}?</p>,
                      'Delete Job',
                      async () => {
                        const response = await client.mutate({
                          mutation: gql`mutation deleteJob($guid: ID!) { deleteJob(guid: $guid) { success, code, message }}`,
                          variables: { guid: row.guid }
                        });

                        toaster.push(
                          <Message type="success" showIcon closable>{response.data.deleteJob.message}</Message>
                        );
                        setJobs(orderBy(jobs.filter((j: any) => j.guid !== row.guid), 'startDate', 'desc'));
                      }
                    )}
                  />
                }
              </Cell>
            </Column>
          }
        </ResponsiveTable>

      </Drawer.Body>
      <Drawer.Footer>
        <Button onClick={handleHideDrawer} appearance="subtle">Close</Button>
      </Drawer.Footer>
    </Drawer>
  );
}

export default JobSummaryLookup;
