import { useApi } from 'lib';
import { useCompany } from 'lib/hooks';
import { IS_ASSINIBOINE, IS_BUGABOO, POWERED_BY_GOOGLE } from 'lib/tenant';
import { Fragment, useEffect, useState } from 'react';

import {
  AutoComplete,
  Col,
  Form,
  Input,
  InputGroup,
  Panel,
  Row,
  SelectPicker,
  Tooltip,
  Whisper,
  
} from 'rsuite';

import LegacyQuestionCircleIcon from "@rsuite/icons/legacy/QuestionCircle";
import { v4 as uuidv4 } from 'uuid';

interface ICustomerFormAddressDetailLineItem {
  action: string,
  row: number,
  guid: string,
  address: string,
  city: string,
  province: string,
  postalCode: string,
  notes: string,
  primary: boolean,
  label: string,
  container: any,
  unit: string,
  googlePlacesResult: any,
  onBlur: (guid: string, address: string, city: string, postalCode: string, province: string, unit: string, googlePlacesResult: any) => void,
  onAdd: () => void,
  onRemove: (guid: string) => void,
  onSetPrimary: (guid: string) => void
}

const CustomerFormAddressDetailLineItem = ({
  row,
  action,
  guid,
  unit,
  address,
  city,
  province,
  postalCode,
  container,
  primary,
  notes,
  label,
  googlePlacesResult,
  onBlur,
  onAdd,
  onRemove,
  onSetPrimary
}: ICustomerFormAddressDetailLineItem) => {
  const company = useCompany();
  const api = useApi();
  const [formValue, setFormValue] = useState<any>({
    address: address || '',
    city: city || (action === 'add' ? (company?.city || (IS_BUGABOO || IS_ASSINIBOINE) ? 'Calgary' : '') : ''),
    postalCode: postalCode || '',
    province: province || '',
    unit: unit || '',
  });
  const [showFormUnit, setShowFormUnit] = useState((unit || '').length > 0)
  const [predictions, setPredictions] = useState([]);
  const [googlePlacesResultResponse, setGooglePlacesResultResponse] = useState<any>(googlePlacesResult);
  const [sessionKey, setSessionKey] = useState(uuidv4());

  useEffect(() => {
    if (googlePlacesResultResponse?.url) {
      handleBlur();
    }
  }, [googlePlacesResultResponse?.url]);

  useEffect(() => {
    handleBlur();
  }, [formValue]);

  const addressAutoComplete = async (address: string) => {
    if (address.trim().length > 0) {
      const results = await api.get(`/address/autocomplete/${sessionKey}?input=${encodeURI(address)}`);
      const formPredictions = results.predictions.map((r: any) => ({
        label: r.description,
        value: r.place_id
      }));

      formPredictions.push({
        value: '-1',
        label: POWERED_BY_GOOGLE
      })
      setPredictions(formPredictions);
    } else {
      setPredictions([]);
    }
  }

  const addressDetails = async (item: any) => {
    if (item.value === '-1') {
      setFormValue({ ...formValue, address: '' });
      return;
    }

    const results = await api.get(`/address/details/${sessionKey}?place_id=${item.value}`);
    setSessionKey(uuidv4());
    setGooglePlacesResultResponse(results.result);
    const parser = new DOMParser();
    const doc = parser.parseFromString(results.result.adr_address, 'text/html');

    let province = results.result.address_components.find((a: any) => a.types.includes('administrative_area_level_1'));
    let address = '';
    let city = '';
    let postalCode = '';

    if (province) {
      province = province.long_name;
    }

    doc.querySelectorAll("span").forEach((s: any) => {
      switch (s.className) {
        case 'street-address':
          address = s.innerHTML;
          break;
        case 'locality':
          city = s.innerHTML;
          break;
        case 'region':
          // setFormProvince(s.innerHTML);
          break;
        case 'postal-code':
        case 'zip-code':
          postalCode = s.innerHTML;
          break;
      }
    });

    setFormValue({ ...formValue, address, city, postalCode, province });
  }

  const handleBlur = () => {
    onBlur(guid, formValue.address, formValue.city, formValue.postalCode, formValue.province, formValue.unit, googlePlacesResultResponse);
  }

  return (
    <Fragment>
      <Row>
        {showFormUnit &&
          <Col md={7}>
            <label>Unit(s):</label>
          </Col>
        }
        <Col md={7}>
          {IS_BUGABOO
            ? <label className={label === 'site' ? 'required' : ''}>Site:</label>
            : <>
              <span>
                <label className={label === 'site' ? 'required' : ''}>
                  {label === 'site' ? 'Site/Billing' : 'Work'}:
                </label>
                {label === 'site' &&
                  <span> <Whisper placement="top" trigger="hover" speaker={<Tooltip>If billing and site address are the same, enter them here. In some cases when there is a different work address, the site becomes billing address.</Tooltip>}><LegacyQuestionCircleIcon /></Whisper></span>
                }
              </span>
            </>
          }
        </Col>
        <Col md={3}>City</Col>
        <Col md={3}>Postal Code:</Col>
        <Col md={3}>Province:</Col>
        {/* <Col md={5}>Notes</Col>
        <Col md={1}>Primary</Col>
        <Col md={2}>&nbsp;</Col> */}
      </Row>


      <Row className={'mb-5'}>
        <Form
          formValue={formValue}
          onChange={setFormValue}
        >
          {showFormUnit &&
            <Col md={7}>
              <Form.Control name="unit" tabIndex={0} />
            </Col>
          }
          <Col md={7}>
            <InputGroup style={{ width: '100%' }}>
              {!showFormUnit &&
                <Whisper placement="bottom" trigger="hover" speaker={<Tooltip>Provide additional units on same address for accurate geocoding</Tooltip>}>
                  <InputGroup.Button onClick={() => setShowFormUnit(true)}>Add Units</InputGroup.Button>
                </Whisper>
              }

              {(IS_BUGABOO || IS_ASSINIBOINE)
                ? <AutoComplete
                container={() => container && container.current}
                data={predictions}
                name="address"
                value={formValue.address}
                onChange={(val: string) => {
                  setFormValue({ ...formValue, address: val });
                  addressAutoComplete(val);
                }}
                renderMenuItem={(label: any, item: any) => {
                  return (
                    <div key={item.value} dangerouslySetInnerHTML={{ __html: item.label }}></div>
                  );
                }}
                onSelect={(item: any, data: any) => {
                  if (data.value !== '-1') {
                    addressDetails(data);
                  }
                }} />
                : <Form.Control name="address" tabIndex={0} />
              }
            </InputGroup>
          </Col>
          <Col md={3}>
            <Form.Control name="city" tabIndex={0} />
          </Col>
          <Col md={3}>
            <Form.Control name="postalCode" tabIndex={0} />
          </Col>
          <Col md={3}>
            <Form.Control
              accepter={SelectPicker}
              searchable={false}
              block
              container={() => container && container.current}
              data={[
                { value: 'Alberta', label: 'Alberta' },
                { value: 'British Columbia', label: 'British Columbia' },
                { value: 'Manitoba', label: 'Manitoba' },
                { value: 'New Brunswick', label: 'New Brunswick' },
                { value: 'Newfoundland and Labrador', label: 'Newfoundland and Labrador' },
                { value: 'Northwest Territories', label: 'Northwest Territories' },
                { value: 'Nova Scotia', label: 'Nova Scotia' },
                { value: 'Nunavut', label: 'Nunavut' },
                { value: 'Ontario', label: 'Ontario' },
                { value: 'Prince Edward Island', label: 'Prince Edward Island' },
                { value: 'Quebec', label: 'Quebec' },
                { value: 'Saskatchewan', label: 'Saskatchewan' },
                { value: 'Yukon', label: 'Yukon' }
              ]}
              name="province"
              tabIndex={0} />
          </Col>
          {/*
        <Col md={5}>
          <Input value={formNotes} onChange={setFormNotes} onBlur={handleBlur} tabIndex={0} />
        </Col>
        <Col md={1}>
          <Radio defaultChecked={primary} onChange={() => onSetPrimary(guid) } />
        </Col>
        <Col md={2} className="pt-5">
          <Whisper placement="top" trigger="hover" speaker={<Tooltip>Add</Tooltip>}>
            <IconButton
              size="xs"
              appearance="link"
              icon={<Icon icon="plus" />}
              onClick={onAdd}
            />
          </Whisper>
          <Whisper placement="top" trigger="hover" speaker={<Tooltip>Remove</Tooltip>}>
            <IconButton
              size="xs"
              appearance="link"
              icon={<Icon icon="trash" />}
              onClick={() => onRemove(guid)}
            />
          </Whisper>
        </Col> */}
          {label === 'work' &&
            <Col xs={24}>
              <Form.HelpText>Provide work address if different from site/billing address.</Form.HelpText>
            </Col>
          }
        </Form>
      </Row>
    </Fragment>
  );
}

export default CustomerFormAddressDetailLineItem;
