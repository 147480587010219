import { gql, useQuery } from "@apollo/client";
import Icon from "@rsuite/icons/lib/Icon";
import { usePrairieAuth } from "contexts/AuthPrairieProvider";
import { format, parseISO } from "date-fns";
import { QUERY_SITE_REVIEWS } from "gql/siteReviews";
import { DRAWER, FORMAT, getEnv, ROLE } from "lib/env";
import { getFilter } from "lib/helpers/filter";
import startCase from "lodash/startCase";
import { useContext, useEffect, useState } from "react";
import { MdAdd, MdEco } from "react-icons/md";
import { Link, useHistory } from "react-router-dom";
import { Badge, IconButton, Loader, Panel, Table, Tooltip, Whisper } from "rsuite";
import { ApplicationContext, Filter, ResponsiveTable } from "shared";
import { useFilteredParams } from "shared/FilterProvider";
import IconButtonWrapper from "shared/IconButtonWrapper";
import { useViewport } from "shared/ViewportProvider";
import INTL from "tenant/intl";
const { Column, HeaderCell, Cell } = Table;

interface ISiteReviewList {
  customerId?: string,
  showScores?: boolean
}

const SiteReviewList = ({
  customerId,
  showScores
}: ISiteReviewList) => {
  const filterKey = 'siteReviews';
  const { state } = useViewport();
  const { params } = useFilteredParams(filterKey);
  const [filter, setFilter] = useState(params);
  const { showDrawer } = useContext(ApplicationContext);
  const { isRole } = usePrairieAuth();
  const siteReviews = useQuery(QUERY_SITE_REVIEWS, {
    variables: {
      where: getFilter(filterKey, { ...filter, viewPortCustomerId: customerId }, state.profile)
    }
  });
  const [modifiedAt, setModifiedAt] = useState(new Date().toISOString());
  const [groups, setGroups] = useState<any>([]);

  useEffect(() => {
    const prevGroups: any = [];

    if (showScores && (siteReviews?.data?.siteReviews?.edges?.node || []).length > 0) {
      const nodes = siteReviews?.data?.siteReviews?.edges?.node;
      for (const node of nodes) {
        for (const group of node.review.services.groups) {
          const found = prevGroups.find((g: any) => g.guid === group.guid);

          if (!found) {
            prevGroups.push(group);
          }
        }
      }
    }

    setGroups(prevGroups);
  }, [showScores, siteReviews])

  return <div key={modifiedAt}>
    {!customerId && <>
      <Filter
        dateRangeCleanable={true}
        id={filterKey}
        showJobStatus={false}
        showMultipleCrewFilter={true}
        showGroupFilter={false}
        showTextFilter={!isRole(ROLE.CLIENT, ROLE.CLEANER)}
        showSiteReviewTypeFilter={true}
        onChange={(val: any) => {
          setFilter(val);
        }}
      />
    </>}

    {siteReviews?.loading
      ? <Loader content="Loading..." />
      : <div>

        {((siteReviews?.data?.siteReviews?.edges?.node || []).length === 0 && isRole(ROLE.ADMIN, ROLE.MANAGER)) ?
          <Panel>
            <div className="help-message-box">
              <div className="icon"><MdEco
                style={{
                  color: '#ccc',
                  fontSize: "4em"
                }} /></div>
              <h6>No data found</h6>
              <div>Start by creating a {INTL.SITE_REVIEW}.</div>
              <div className="cta">
                <IconButton
                  icon={<Icon as={MdAdd} />}
                  appearance="primary"
                  size="sm"
                  onClick={() => showDrawer(DRAWER.SITE_REVIEW_FORM, { action: 'add', customerId: +(customerId || 0) }, () => {
                    setModifiedAt(new Date().toISOString());
                    siteReviews.refetch();
                  })}>Add {INTL.SITE_REVIEW}</IconButton>
              </div>
            </div>
          </Panel>
          : <>{((showScores && groups.length > 0) || !showScores) ?
            <ResponsiveTable
              data={siteReviews?.data?.siteReviews?.edges?.node || []}
              filterKey='site-reviews'
            >
              {!customerId &&
                <Column flexGrow={1}>
                  <HeaderCell>
                    Customer
                  </HeaderCell>
                  <Cell>
                    {(row: any) => {
                      return (
                        <a href='/'
                          onClick={(e) => {
                            e.preventDefault();
                            showDrawer(DRAWER.CUSTOMER_VIEW, {
                              customerId: row?.customer?.id,
                              customerGuid: row?.customer?.guid
                            });
                          }}
                        >{row?.customer?.displayName}</a>
                      )
                    }}
                  </Cell>
                </Column>
              }

              <Column flexGrow={1}>
                <HeaderCell>
                  Review
                </HeaderCell>
                <Cell>
                  {(row: any) => {
                    return (
                      <a href="/" onClick={(e: any) => {
                        e.preventDefault();
                        showDrawer(DRAWER.SITE_REVIEW_VIEW, { action: 'view', guid: row.guid, customerId: row.customerId });
                      }}>{row.template?.name}</a>
                    );
                  }}
                </Cell>
              </Column>

              <Column>
                <HeaderCell>
                  Status
                </HeaderCell>
                <Cell>
                  {(row: any) => startCase(row.status)}
                </Cell>
              </Column>

              {groups.map((g: any) =>
                <Column key={g.guid}>
                  <HeaderCell>
                    <Whisper placement="bottom" trigger="hover" speaker={<Tooltip>{g.description}</Tooltip>}>
                      <span>{g.description}</span>
                    </Whisper>
                  </HeaderCell>
                  <Cell>{(row: any) => row.review.services.groups.find((rg: any) => rg.guid === g.guid)?.score || '-'}</Cell>
                </Column>
              )}

              <Column sortable>
                <HeaderCell>Due Date</HeaderCell>
                <Cell data-column='Due Date' dataKey="dueDate">{(row: any) => row.dueDate ? format(parseISO(row.dueDate), FORMAT.MONTH_DATE) : ''}</Cell>
              </Column>

              <Column>
                <HeaderCell>
                  {INTL.CREW}
                </HeaderCell>
                <Cell>
                  {(row: any) => {
                    return (
                      <span>{row.user?.operatorName}</span>
                    );
                  }}
                </Cell>
              </Column>

              {/* <Column sortable>
            <HeaderCell>Created At</HeaderCell>
            <Cell data-column='Created At' dataKey="createdAt">{(row: any) => format(parseISO(row.createdAt), FORMAT.MONTH_DATE)}</Cell>
          </Column>

          <Column sortable>
            <HeaderCell>Modified At</HeaderCell>
            <Cell data-column='Modified At' dataKey="modifiedAt">{(row: any) => format(parseISO(row.modifiedAt), FORMAT.MONTH_DATE)}</Cell>
          </Column> */}

              {/* <Column>
            <HeaderCell>
              Created By
            </HeaderCell>
            <Cell>
              {(row: any) => {
                return (
                  <span>{row.owner?.operatorName}</span>
                );
              }}
            </Cell>
          </Column> */}

              <Column fixed="right" width={120}>
                <HeaderCell>&nbsp;</HeaderCell>
                <Cell className="link-group text-right">
                  {(row: any) => {
                    return (
                      <>
                        <Whisper placement="top" trigger="hover" speaker={<Tooltip>View</Tooltip>}>
                          <IconButtonWrapper
                            onClick={() => {
                              showDrawer(DRAWER.SITE_REVIEW_VIEW, { action: 'view', guid: row.guid, customerId: row.customerId });
                            }}
                            appearance="link"
                            icon={'view'}
                          />
                        </Whisper>
                        {isRole(ROLE.ADMIN, ROLE.MANAGER) &&
                          <Whisper placement="top" trigger="hover" speaker={<Tooltip>Edit</Tooltip>}>
                            <IconButtonWrapper
                              onClick={() => {
                                showDrawer(DRAWER.SITE_REVIEW_FORM, { action: 'edit', guid: row.guid, customerId: row.customerId }, () => {
                                  setModifiedAt(new Date().toISOString());
                                  siteReviews.refetch();
                                });
                              }}
                              appearance="link"
                              icon={'edit'}
                            />
                          </Whisper>
                        }
                        {!isRole(ROLE.CLIENT, ROLE.CLEANER) &&
                          <Badge content={(row.lastConfirmationEmail || []).length === 0 ? false : row.lastConfirmationEmail.length} className="email-badge">
                            <Whisper placement="bottom" trigger="hover" speaker={<Tooltip><div className="text-left" dangerouslySetInnerHTML={{ __html: '<div>Email</div>' }}></div></Tooltip>}>
                              <IconButtonWrapper
                                onClick={() => {
                                  showDrawer(DRAWER.EMAIL_FORM, {
                                    resources: [row],
                                    resourceGuid: row.guid,
                                    group: row.applicationGroup,
                                    type: 'site_review',
                                    templateId: row.templateId,
                                    users: [row?.user?.id]
                                  }, () => setModifiedAt(new Date().toISOString()));
                                }}
                                appearance="link"
                                icon={'email'}
                              />
                            </Whisper>
                          </Badge>
                        }
                      </>
                    );
                  }}
                </Cell>
              </Column>
            </ResponsiveTable>
            : <>No groups</>
          }</>
        }
      </div>
    }
  </div>
}

export default SiteReviewList;