import { getTenant } from "lib/env";

const AuthLogo = () => {
  return <>
    <a href="/">
      <img
        src={`https://${window.location.hostname}/api/tenant/logo/${getTenant().id}`}
        style={{ width: '150px' }}
        alt="PrairieHQ"
      />
    </a>
  </>
}

export default AuthLogo;
