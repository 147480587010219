import { useQuery } from "@apollo/client";
import Icon from "@rsuite/icons/lib/Icon";
import { GET_CUSTOMER } from "gql/customer";
import { DRAWER } from "lib/env";
import { startCase } from "lodash";
import { useContext } from "react";
import { MdEdit } from "react-icons/md";
import { Button, Drawer, IconButton, Loader } from "rsuite";
import { ApplicationContext } from "shared";

interface IIrrigationSystem {
  customerId: number,
  drawer?: boolean,
  onHide?: () => void
}

const IrrigationSystem = ({
  customerId,
  drawer,
  onHide
}: IIrrigationSystem) => {
  const { showDrawer } = useContext(ApplicationContext);
  const customer = useQuery(GET_CUSTOMER, { variables: { id: +(customerId || 0) } });

  const dataFields = (irrigationSystem: any) => {
    return <>
      <dl>
        <div className={!irrigationSystem?.hasOwnProperty('numberOfControllers') ? 'text-mutted' : ''}>
          <dt>Number of Controllers:</dt>
          <dd>{irrigationSystem?.numberOfControllers || '-'}</dd>
        </div>

        <div className={!irrigationSystem?.hasOwnProperty('numberOfZones') ? 'text-mutted' : ''}>
          <dt>Number of Zones:</dt>
          <dd>{irrigationSystem?.numberOfZones || '-'}</dd>
        </div>

        <div className={!irrigationSystem?.hasOwnProperty('numberOfWaterSupplyValves') ? 'text-mutted' : ''}>
          <dt>Number of Water Supply Valves:</dt>
          <dd>{irrigationSystem?.numberOfWaterSupplyValves || '-'}</dd>
        </div>

        <div className={!irrigationSystem?.hasOwnProperty('numberOfBlowOutPorts') ? 'text-mutted' : ''}>
          <dt>Number of Blow Out Ports:</dt>
          <dd>{irrigationSystem?.numberOfBlowOutPorts || '-'}</dd>
        </div>

        <div className={!irrigationSystem?.hasOwnProperty('accessInformation') ? 'text-mutted' : ''}>
          <dt>Access Information:</dt>
          <dd>{irrigationSystem?.accessInformation || '-'}</dd>
        </div>

        <div className={!irrigationSystem?.hasOwnProperty('controllerQuantityLocationInformation') ? 'text-mutted' : ''}>
          <dt>Controller/Quantity/Location/Information:</dt>
          <dd>{irrigationSystem?.controllerQuantityLocationInformation || '-'}</dd>
        </div>

        <div className={!irrigationSystem?.hasOwnProperty('waterSupplyLocationInformation') ? 'text-mutted' : ''}>
          <dt>Water Supply Location/Information:</dt>
          <dd>{irrigationSystem?.waterSupplyLocationInformation || '-'}</dd>
        </div>

        <div className={!irrigationSystem?.hasOwnProperty('blowOutPortsLocationInformation') ? 'text-mutted' : ''}>
          <dt>Blow Out Ports Location/Information:</dt>
          <dd>{irrigationSystem?.blowOutPortsLocationInformation || '-'}</dd>
        </div>

        <div className={!irrigationSystem?.hasOwnProperty('notesWorkComments') ? 'text-mutted' : ''}>
          <dt>Notes / Work Comments:</dt>
          <dd>{irrigationSystem?.notesWorkComments || '-'}</dd>
        </div>

        <div className={!irrigationSystem?.hasOwnProperty('repairsMaterialsRecommendations') ? 'text-mutted' : ''}>
          <dt>Repairs/Materials/Recommendations:</dt>
          <dd>{irrigationSystem?.repairsMaterialsRecommendations || '-'}</dd>
        </div>

        <div className={!irrigationSystem?.hasOwnProperty('irrigationSchedule') ? 'text-mutted' : ''}>
          <dt>Irrigation Schedule</dt>
          <dd>{irrigationSystem?.irrigationSchedule || '-'}</dd>
        </div>

        <div className={!irrigationSystem?.hasOwnProperty('cutDays') ? 'text-mutted' : ''}>
          <dt>Cut Days</dt>
          <dd>{(irrigationSystem?.cutDays || []).length === 0 ? '-' : (irrigationSystem?.cutDays || []).map((i: string) => startCase(i)).join(', ')}</dd>
        </div>
      </dl>
    </>
  };

  return drawer
    ? <Drawer open={true} onClose={onHide}>
      <Drawer.Header>
        <Drawer.Title>Irrigation System</Drawer.Title>
      </Drawer.Header>
      <Drawer.Body>
        {customer?.loading
          ? <Loader content="Loading..." />
          : <div>
            {dataFields(customer?.data?.customer?.irrigationSystem)}
          </div>
        }
      </Drawer.Body>
      <Drawer.Footer>
        <Button size="sm" onClick={() => {
          showDrawer(undefined);
        }} appearance="subtle">Close</Button>
      </Drawer.Footer>
    </Drawer>
    : <div>
      {customer?.loading
        ? <Loader content="Loading..." />
        : <div>
          {dataFields(customer?.data?.customer?.irrigationSystem)}
          <IconButton
            size='sm'
            appearance='primary'
            onClick={() => {
              showDrawer(DRAWER.IRRIGATION_SYSTEM_FORM, { customerId }, () => customer.refetch());
            }}
            icon={<Icon as={MdEdit} />}
          >Edit</IconButton>
        </div>
      }
    </div>;
}

export {
  IrrigationSystem
}