import { useContext } from 'react';
import { ApplicationContext } from 'shared';
import { WorkOrderForm as WorkOrderFormContainer } from 'containers/workorder/WorkOrderForm';
import { WorkOrderPhotos as WorkOrderPhotosContainer } from 'containers/workorder/WorkOrderPhotos';
import { WorkOrderRepeat as WorkOrderRepeatContainer } from 'components/workorder/WorkOrderRepeat';
import { WorkOrderCompare as WorkOrderCompareContainer } from 'components/workorder/WorkOrderCompare';

const WorkOrderForm = () => {
  const {
    drawerData,
    showDrawer,
    onUpdateDrawerCallback
  } = useContext(ApplicationContext);

  return <WorkOrderFormContainer
    drawer
    show
    onHide={() => showDrawer(undefined)}
    onUpdate={onUpdateDrawerCallback}
    guid={drawerData?.workOrderGuid}
    customerId={drawerData?.customerId}
    action={drawerData?.action}
    startEndDate={drawerData?.startEndDate}
    defaultWorkGroup={drawerData?.workGroup}
  />
}

const WorkOrderPhotos = () => {
  const {
    drawerData,
    showDrawer
  } = useContext(ApplicationContext);

  return <WorkOrderPhotosContainer
    onHide={() => showDrawer(undefined)}
    guid={drawerData?.workOrderGuid}
  />
}

const WorkOrderRepeat = () => {
  const {
    drawerData,
    showDrawer,
    onUpdateDrawerCallback
  } = useContext(ApplicationContext);

  return <WorkOrderRepeatContainer
    group={drawerData?.group || false}
    onUpdate={onUpdateDrawerCallback}
    onHide={() => showDrawer(undefined)}
    jobGuids={drawerData?.jobGuids || []}
  />
}

const WorkOrderCompare = () => {
  const {
    drawerData,
    showDrawer,
    onUpdateDrawerCallback
  } = useContext(ApplicationContext);

  return <WorkOrderCompareContainer
    onUpdate={onUpdateDrawerCallback}
    onHide={() => showDrawer(undefined)}
  />
}

export {
  WorkOrderForm,
  WorkOrderPhotos,
  WorkOrderRepeat,
  WorkOrderCompare
}
