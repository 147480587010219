import { gql, useApolloClient } from "@apollo/client";
import { queryStorage } from "gql/storage";
import { getEnv } from "lib/env";
import { pick } from "lodash";
import startCase from "lodash/startCase";
import { cloneElement, useContext, useEffect, useRef, useState } from "react"
import { useRouteMatch } from "react-router-dom";
import {
  toaster,
  Message,
  Button,
  ButtonToolbar,
  Form,
  Loader,
  Panel,
  Schema,
  SelectPicker,
  Uploader,
} from "rsuite";
import { ApplicationContext, DrawerFormWrapper } from "shared";
import { CONFIG_APPLICATION_WORK_GROUPS } from "tenant/config";
import INTL from "tenant/intl";
import { v4 as uuidv4 } from 'uuid';

interface IStorageForm {
  action?: string,
  show?: boolean,
  guid?: string,
  drawer?: boolean,
  onHide?: () => void,
  onUpdate?: (data: any) => void
}

const { ObjectType, StringType } = Schema.Types;

const model = Schema.Model({
  workGroup: StringType().isRequired('This field is required'),
  name: StringType().isRequired('This field is required'),
  map: ObjectType().isRequired('This field is required'),
  layout: ObjectType().isRequired('This field is required')
});

const StorageForm = ({ guid, action, drawer, show, onHide, onUpdate }: IStorageForm) => {
  let form: any = useRef();
  const client = useApolloClient();
  const match: any = useRouteMatch();
  const { showError } = useContext(ApplicationContext);
  const [loading, setLoading] = useState(true);
  const [formError, setFormError] = useState({});
  const [saving, setSaving] = useState(false);
  const [formValue, setFormValue] = useState<any>({
    guid: uuidv4(),
    name: '',
    map: undefined,
    layout: undefined
  });
  guid = match?.params?.guid || guid;
  action = match?.params?.action || action || 'add';

  useEffect(() => {
    if (action === 'edit') {
      (async function getStorage() {
        try {
          setLoading(true);
          const data: any = await client.query({
            query: queryStorage,
            variables: {
              offset: 0,
              limit: 1,
              where: { guid: { is: guid } }
            },
            fetchPolicy: 'no-cache'
          });

          setFormValue(data.data.storage.edges.node[0]);
          setLoading(false);
        } catch (err) {
          console.log(err);
        }
      })();
    } else {
      setLoading(false);
    }
  }, [client, guid, action]);

  const handleSubmit = async () => {
    const errorMessage = 'Form has errors which need to be corrected';
    const validation = form.check();

    if (!validation) {
      toaster.push(<Message type="error" showIcon closable>{errorMessage}</Message>);
    } else {
      setSaving(true);

      const upsertStorage = gql`
          mutation upsertStorage($input: UpsertStorageInput!) {
              upsertStorage(input: $input) {
                  success
                  code
                  message
              }
          }
      `;

      try {
        const response: any = await client.mutate({
          mutation: upsertStorage, variables: { input: pick(formValue, ['guid', 'name', 'workGroup', 'map', 'layout']) }
        });

        if (response.data.upsertStorage.success) {
          toaster.push(
            <Message type="success" showIcon closable>{response.data.upsertStorage.message}</Message>
          );
          setSaving(false);

          if (onHide) {
            onHide();
          }
          if (onUpdate) {
            onUpdate(response.data.upsertStorage.result);
          }
        } else {
          toaster.push(
            <Message type="error" showIcon closable>{response.data.upsertStorage.message}</Message>
          );
          setSaving(false);
        }
      } catch (err) {
        showError(err);
        setSaving(false);
      }
    }
  }

  const Wrapper = show === undefined
    ? <div />
    : <DrawerFormWrapper
      action={action || 'add'}
      loading={saving}
      show={show}
      title={startCase(action) + ' Storage'}
      guid={guid}
      form={`/app/${getEnv()}/storage/${action}/${guid}`}
      onHide={onHide}
      onSave={handleSubmit}
    />;

  return cloneElement(Wrapper, {
    children: <Panel className="content">
      {loading
        ? <Loader content="Loading..." />
        : <Form
          ref={(ref: any) => form = ref}
          fluid
          formValue={formValue}
          formError={formError}
          onChange={setFormValue}
          onCheck={setFormError}
          model={model}
        >
          <fieldset>
            <legend>Storage</legend>

            {getEnv() === 'dev' &&
              <Form.Group>
                <Form.ControlLabel>UUID:</Form.ControlLabel>
                <p>{formValue?.guid}</p>
              </Form.Group>
            }

            <Form.Group>
              <Form.ControlLabel className="required">Name:</Form.ControlLabel>
              <Form.Control name="name" />
            </Form.Group>

            <Form.Group>
              <Form.ControlLabel className="required">{INTL.WORK_GROUP}</Form.ControlLabel>
              <Form.Control
                accepter={SelectPicker}
                cleanable={false}
                searchable={false}
                name="workGroup"
                data={CONFIG_APPLICATION_WORK_GROUPS}
                block />
            </Form.Group>

            <Form.Group className="rs-uploader-file-items-show-latest">
              <Form.ControlLabel className="required">Map:</Form.ControlLabel>
              <Form.Control
                accepter={Uploader}
                name="map"
                removable={false}
                action={`https://${window.location.host}/api/attachments/${formValue.guid}`}
                onSuccess={(data: any) => setFormValue({ ...formValue, map: { name: data.result.name, fileKey: data.result.fileKey } })}
                withCredentials
                defaultFileList={formValue.map ? [formValue.map] : []} />
            </Form.Group>

            <Form.Group className="rs-uploader-file-items-show-latest">
              <Form.ControlLabel className="required">Layout:</Form.ControlLabel>
              <Form.Control
                accepter={Uploader}
                name="layout"
                removable={false}
                action={`https://${window.location.host}/api/attachments/${formValue.guid}`}
                onSuccess={(data: any) => setFormValue({ ...formValue, layout: { name: data.result.name, fileKey: data.result.fileKey } })}
                withCredentials
                defaultFileList={formValue.layout ? [formValue.layout] : []} />
            </Form.Group>
          </fieldset>

          {!drawer &&
            <ButtonToolbar>
              <Button
                appearance="primary"
                onClick={() => handleSubmit()}
                loading={saving}>Save</Button>
            </ButtonToolbar>
          }
        </Form>
      }
    </Panel>
  });
}

export {
  StorageForm
}
