import { useEffect, useRef, useState } from 'react';
import {
  Form,
  Button,
  Schema,
  Grid,
  Row,
  Col,
  RadioGroup,
  Radio,
  CheckboxGroup,
  Checkbox,
  Message,
  toaster,
} from 'rsuite';
import TextareaAutosize from 'react-autosize-textarea';
import INTL from 'tenant/intl';
import { IS_APP } from 'lib/tenant';
import { getTenant } from 'lib/env';
import { useApi } from 'lib';
import { useLogger } from 'lib/logger';
import { useHistory } from 'react-router';

const { StringType, ArrayType } = Schema.Types;

const model = Schema.Model({
  fullName: StringType().isRequired('This field is required'),
  phoneNumber: StringType().isRequired('This field is required'),
  email: StringType().isRequired('This field is required'),
  companyName: StringType().isRequired('This field is required'),
  legalCompanyName: StringType().isRequired('This field is required'),
  newBusiness: StringType().isRequired('This field is required'),
  businessType: ArrayType().minLength(1, 'This field is required'),
  estimatedStartDate: StringType().isRequired('This field is required'),
  preferedLocation: ArrayType().minLength(1, 'This field is required')
});

const initialFormValue = {
  fullName: '',
  phoneNumber: '',
  email: '',
  companyName: '',
  legalCompanyName: '',
  newBusiness: '',
  businessType: [],
  estimatedStartDate: '',
  preferedLocation: [],
  howDidYouHear: [],
  message: '',
  finalProduct: '',
  specialRequirements: '',
  howManyPeople: '',
  membershipType: [],
  hoursPerMonth: ''
};

const AuthRegisterForm = () => {
  let form: any = useRef();
  const api = useApi();
  const history = useHistory();
  const logger = useLogger();
  const [formError, setFormError] = useState<any>({});
  const [formValue, setFormValue] = useState<any>(initialFormValue);
  const [processing, setProcessing] = useState(false);

  const handleSubmit = async (form: any, formValue: any) => {
    if (!form.check()) {
      toaster.push(
        <Message type="error" showIcon closable>Registration form has required fields which need to be completed</Message>
      );
      return;
    }

    setProcessing(true);
    const result: any = await api.post(`/oauth/v2/register/${getTenant().id}`, formValue);
    setProcessing(false);

    if (result.success) {
      toaster.push(
        <Message type="success" showIcon closable duration={5000}>{result.message}</Message>
      );
    } else {
      logger.error(formValue);
      toaster.push(
        <Message type="error" showIcon closable duration={5000}>{result.message}</Message>
      );
    }
  }

  return (
    <Form
      fluid
      ref={(ref: any) => form = ref}
      formValue={formValue}
      formError={formError}
      onChange={setFormValue}
      onCheck={setFormError}
      model={model}
    >
      <Form.Group>
        <Form.ControlLabel className="required">Full Name</Form.ControlLabel>
        <Form.Control name="fullName" />
      </Form.Group>
      <Form.Group>
        <Form.ControlLabel className="required">Phone Number</Form.ControlLabel>
        <Form.Control name="phoneNumber" />
      </Form.Group>
      <Form.Group>
        <Form.ControlLabel className="required">Email</Form.ControlLabel>
        <Form.Control name="email" />
      </Form.Group>
      <Grid fluid className="p-0 mb-24">
        <Row>
          <Col xs={24} md={12}>
            <Form.Group>
              <Form.ControlLabel className="required">Company Name</Form.ControlLabel>
              <Form.Control name="companyName" />
            </Form.Group>
          </Col>
          <Col xs={24} md={12}>
            <Form.Group>
              <Form.ControlLabel className="required">Legal Company Name</Form.ControlLabel>
              <Form.Control name="legalCompanyName" />
            </Form.Group>
          </Col>
        </Row>
      </Grid>

      <Form.Group>
        <Form.ControlLabel className="required">New Business?</Form.ControlLabel>
        <Form.Control name="newBusiness" accepter={RadioGroup} inline>
          <Radio value="Yes">Yes</Radio>
          <Radio value="No">No</Radio>
        </Form.Control>
      </Form.Group>

      <Form.Group>
        <Form.ControlLabel className="required">Business Type</Form.ControlLabel>
        <Form.Control accepter={CheckboxGroup} name="businessType" inline>
          <Checkbox value="Caterer">Caterer</Checkbox>
          <Checkbox value="Baker">Baker</Checkbox>
          <Checkbox value="Meal Prep">Meal Prep</Checkbox>
          <Checkbox value="Specialty Producer">Specialty Producer</Checkbox>
          <Checkbox value="Food Truck">Food Truck</Checkbox>
          <Checkbox value="Other">Other</Checkbox>
        </Form.Control>
      </Form.Group>

      <Form.Group>
        <Form.ControlLabel className="required">Estimated Start Date</Form.ControlLabel>
        <Form.Control accepter={RadioGroup} name="estimatedStartDate" inline>
          <Radio value="Now">Now</Radio>
          <Radio value="1 month">1 month</Radio>
          <Radio value="1-2 months">1-2 months</Radio>
          <Radio value="Not sure">Not sure</Radio>
        </Form.Control>
      </Form.Group>

      <Form.Group>
        <Form.ControlLabel className="required">Which location would you prefer to cook from?</Form.ControlLabel>
        <Form.Control accepter={CheckboxGroup} name="preferedLocation" inline>
          <Checkbox value="Calgary SE">Calgary SE</Checkbox>
          <Checkbox value="Calgary NE">Calgary NE</Checkbox>
          <Checkbox value="Edmonton">Edmonton</Checkbox>
        </Form.Control>
      </Form.Group>

      <Form.Group>
        <Form.ControlLabel>How did you hear about us?</Form.ControlLabel>
        <Form.Control accepter={CheckboxGroup} name="howDidYouHear" inline>
          <Checkbox value="Google">Google</Checkbox>
          <Checkbox value="LinkedIn">LinkedIn</Checkbox>
          <Checkbox value="Facebook">Facebook</Checkbox>
          <Checkbox value="Instagram">Instagram</Checkbox>
          <Checkbox value="Referal">Referal</Checkbox>
          <Checkbox value="Printed Publicity">Printed Publicity</Checkbox>
        </Form.Control>
      </Form.Group>

      <Form.Group>
        <Form.ControlLabel>Message</Form.ControlLabel>
        <Form.Control name="message" accepter={TextareaAutosize} rows={2} />
      </Form.Group>

      <Form.Group>
        <Form.ControlLabel className="fieldset">Business Information</Form.ControlLabel>
      </Form.Group>

      <Form.Group>
        <Form.ControlLabel>Describe your final product:</Form.ControlLabel>
        <Form.Control name="finalProduct" />
      </Form.Group>

      <Form.Group>
        <Form.ControlLabel>Any special requirements:</Form.ControlLabel>
        <Form.Control name="specialRequirements" />
      </Form.Group>

      <Form.Group>
        <Form.ControlLabel>How many people will be cooking:</Form.ControlLabel>
        <Form.Control name="howManyPeople" />
      </Form.Group>

      <Form.Group>
        <Form.ControlLabel>Membership Type</Form.ControlLabel>
        <Form.Control accepter={CheckboxGroup} name="membershipType" inline>
          <Checkbox value="One Time Booking">One Time Booking</Checkbox>
          <Checkbox value="Ongoing Bookings">Ongoing Bookings</Checkbox>
          <Checkbox value="Cold Storage Freezer">Cold Storage Freezer</Checkbox>
          <Checkbox value="Cold Storage Fridge">Cold Storage Fridge</Checkbox>
          <Checkbox value="Dry Good Storage">Dry Good Storage</Checkbox>
        </Form.Control>
      </Form.Group>

      {(formValue.membershipType.includes('One Time Booking')
        || formValue.membershipType.includes('Ongoing Bookings')) &&
        <Form.Group>
          <Form.ControlLabel>How many hours roughly per month:</Form.ControlLabel>
          <Form.Control name="hoursPerMonth" />
        </Form.Group>
      }

      <Message
        className="mb-24"
        type="info">Due to high volume, please allow us 48 business hours to get back to you.</Message>
      <Form.Group>
        <Button size="sm" block appearance="primary" loading={processing} onClick={() => handleSubmit(form, formValue)}>{INTL.REGISTER}</Button>
        <Button size="sm" block appearance="link" disabled={processing} onClick={() => history.push(`/auth`)}>Sign In</Button>
      </Form.Group>
    </Form>
  );
}

export {
  AuthRegisterForm
}
