import { gql } from "@apollo/client";

const queryDocument = gql`
  query document($guid: ID) {
    document(guid:$guid) {
      id
      guid
      entryType
      title
      customerName
      customerId
      billingCustomerId
      workType
      startDate
      endDate
      expiryDate
      modifiedAt
      source
      documentNumber
      source
      templateId
      templateVersionId
      responseTime
      additionalNotes
      customerComments
      estimatedCost
      userId
      approvalStatus
      lastEmail
      tenantId
      users {
        id
        operatorName
        color
      }
      services {
        id
        guid
        workDescription
        workCost
        previousWorkCost
        manHours
        people
        service
        sortOrder
        isVisible
        status
      }
      attachments {
        guid
        fileName
        filePath
        fileId
        fileSize
        description
        visibility
      }
      comments {
        guid
        comment
        childGuid
        createdAt
      }
      customer {
        id
        guid
        customerName
        displayName
        customerAddress
        workAddress
        email
        contactDetails
        displayAddress
        lat
        lng
      }
      emailActivity {
        guid
      }
    }
  }
`;

const queryDocuments = gql`
  query documents($limit: Int!, $offset: Int!, $where: DocumentsWhere, $sort: DocumentsSort) {
    documents(filter: {
      limit: $limit,
      offset: $offset,
      where: $where
      sort: $sort
    }) {
      edges {
        node {
          id
          guid
          title
          entryType
          workType
          startDate
          endDate
          expiryDate
          createdAt
          modifiedAt
          username
          source
          documentNumber
          lastEmail
          status
          statusDisplay
          approvalStatus
          additionalNotes
          customerComments
          estimatedCost
          templateId
          templateVersionId
          users {
            id
            guid
            contactEmail
            operatorName
            color
          }
          customer {
            id
            guid
            email
            customerName
            displayName
            contactDetails
            displayAddress
            lat
            lng
          }
          billing {
            email
          }
          services {
            guid
            workDescription
            workCost
            isVisible
            service
            status
          }
          statuses {
            title
            status
            operatorName,
            notes
          }
          workOrders {
            guid
            id
            parentId
            startDate
            endDate
            services
            applicationGroup
            parentGuid
          }
          tenant {
            id
            name
          }
          attachments {
            guid
            description
            fileName
          }
        }
      },
      totalCount
    }
  }
`;

const queryDocumentForm = gql`
  query document($guid: ID) {
    document(guid:$guid) {
      id
      guid
      title
      entryType
      customerName
      customerId
      billingCustomerId
      workType
      startDate
      endDate
      expiryDate
      documentDate
      modifiedAt
      source
      documentNumber
      source
      templateId
      templateVersionId
      responseTime
      additionalNotes
      customerComments
      estimatedCost
      approvalStatus
      userId
      approvalStatus
      clientDateSigned
      clientSignature
      clientFullName
      clientComments
      clientJobTitle
      representativeDateSigned
      representativeSignature
      representativeFullName
      representativeComments
      representativeJobTitle
      workOrdersLinked
      customFields
      tenantId
      customer {
        email
      }
      services {
        id
        guid
        workDescription
        workCost
        previousWorkCost
        manHours
        people
        service
        sortOrder
        isVisible
        status
        templateServiceId
        costBreakdown
      }
      attachments {
        guid
        fileName
        filePath
        fileId
        fileSize
        description
        visibility
      }
      statuses {
        guid
        operatorName
        createdAt
        notes
        status
        title
      }
      users {
        guid
        id
        contactEmail
        operatorName
        color
      }
    }
  }
`;

export {
  queryDocument,
  queryDocuments,
  queryDocumentForm
}
