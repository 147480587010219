import { DRAWER, getEnv, ROLE } from 'lib/env';
import { Fragment, useContext, useState, useEffect } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import * as htmlToImage from 'html-to-image';
import { toPng } from 'html-to-image';

import {
  Sidenav,
  Nav,
  Dropdown,
  Button,
  Drawer,
  SelectPicker,
  toaster,
  Message,
  Divider,
  Loader,
} from 'rsuite';

import LegacyCogIcon from "@rsuite/icons/legacy/Cog";
import { ApplicationContext } from 'shared';
import { isMobileOnly } from 'react-device-detect';
import intl from 'tenant/intl';
import { IS_KALADI_PROPERTIES, TENANT } from 'lib/tenant';
import { useViewport } from './ViewportProvider';
import { NavigationNewDropDownMenu } from 'components/navigation/NavigationNewDropdown';
import { useApi } from 'lib';
import { usePrairieAuth } from 'contexts/AuthPrairieProvider';
import jwtDecode from 'jwt-decode'

import {
  MdWork, MdSchedule, MdMoney, MdReceipt, MdAutoStories, MdAssignment, MdArticle, MdDateRange, MdRequestPage, MdPeople, MdPayments,
  MdAccessTimeFilled, MdBedtime, MdAreaChart, MdAssignmentAdd, MdPersonSearch,
  MdRateReview
} from 'react-icons/md';

import { Feedback } from 'drawers';
import { uniq, without } from 'lodash';
import Icon from '@rsuite/icons/lib/Icon';

interface IMainNavigation {
  type: 'sidebar' | 'navbar' | 'drawer',
  show?: boolean,
  onHide?: () => void,
  expanded?: boolean
}

const MainNavigation = ({
  type,
  show,
  onHide,
  expanded
}: IMainNavigation) => {
  const location = useLocation();
  const { can, profile, isRole } = usePrairieAuth();
  const { state } = useViewport();
  const user = state.users.find((u: any) => u.guid === profile?.user);

  if (typeof (onHide) === 'undefined') {
    onHide = () => { }
  }

  const options: any = {
    kaladikitchens: <Nav>
      <Dropdown eventKey="work-manager" renderTitle={() => <div style={{ height: 0, fontSize: 0 }}>&nbsp;</div>}>
        <Dropdown.Item as={Link} to={`/app/${getEnv()}/jobs/list`} onClick={onHide}>{intl.CREW_LIST}</Dropdown.Item>
        <Dropdown.Item as={Link} to={`/app/${getEnv()}/jobs/calendar`} onClick={onHide}>{intl.WORK_CALENDAR}</Dropdown.Item>
        {can('work-order:list') &&
          <Dropdown.Item as={Link} to={`/app/${getEnv()}/jobs/work-orders/list`} onClick={onHide}>{intl.WORK_ORDERS}</Dropdown.Item>
        }
      </Dropdown>
    </Nav>,
    default: <Nav>
      {(isRole(ROLE.ADMIN, ROLE.MANAGER)) &&
        <Nav.Item as={Link}
          to={`/app/${getEnv()}/jobs/schedule`}
          active={location.pathname === `/app/${getEnv()}/jobs/schedule`}
          onClick={onHide}
          icon={<Icon as={MdSchedule} />}>
          <div>{intl.WORK_SCHEDULE}</div>
        </Nav.Item>
      }

      <Nav.Item as={Link}
        to={`/app/${getEnv()}/jobs/list`}
        active={location.pathname === `/app/${getEnv()}/jobs` || location.pathname === `/app/${getEnv()}/jobs/list`}
        onClick={onHide} icon={<Icon as={MdAssignment} />}><div>{intl.CREW_LIST}</div></Nav.Item>

      {!IS_KALADI_PROPERTIES &&
        <Nav.Item as={Link}
          to={`/app/${getEnv()}/jobs/on-demand/list`}
          active={location.pathname === `/app/${getEnv()}/jobs/on-demand` || location.pathname === `/app/${getEnv()}/jobs/on-demand/list`}
          onClick={onHide} icon={<Icon as={MdAssignmentAdd} />}><div>On-Demand List</div></Nav.Item>
      }

      <Nav.Item as={Link}
        to={`/app/${getEnv()}/jobs/calendar`}
        active={location.pathname === `/app/${getEnv()}/jobs/calendar`}
        onClick={onHide} icon={<Icon as={MdDateRange} />}><div>{intl.WORK_CALENDAR}</div></Nav.Item>

      {can('work-order:list') &&
        <Nav.Item
          as={Link}
          to={`/app/${getEnv()}/jobs/work-orders/list`}
          active={location.pathname === `/app/${getEnv()}/jobs/work-orders/list` || location.pathname === `/app/${getEnv()}/jobs/work-orders`}
          onClick={onHide} icon={<Icon as={MdWork} />}>
          <div>{intl.WORK_ORDERS}</div>
        </Nav.Item>
      }

      {can('work-order:list') &&
        <Nav.Item
          as={Link}
          to={`/app/${getEnv()}/site-reviews`}
          active={location.pathname === `/app/${getEnv()}/site-reviews` || location.pathname === `/app/${getEnv()}/jobs/work-orders`}
          onClick={onHide} icon={<Icon as={MdRateReview} />}>
          <div>{intl.SITE_REVIEWS}</div>
        </Nav.Item>
      }

      {!IS_KALADI_PROPERTIES &&
        <>
          <Divider />
          {can('daily-financial:list') &&
            <Nav.Item
              as={Link}
              to={`/app/${getEnv()}/financials/daily-financials`}
              onClick={onHide}
              active={location.pathname === `/app/${getEnv()}/financials/daily-financials`}
              icon={<Icon as={MdRequestPage} />}>
              <div>Daily Financials</div>
            </Nav.Item>
          }

          {can('pnl:list') &&
            <Nav.Item
              as={Link}
              to={`/app/${getEnv()}/financials/pnls`}
              active={location.pathname === `/app/${getEnv()}/financials/pnls`}
              onClick={onHide} icon={<Icon as={MdMoney} />}>
              <div>P&amp;Ls</div></Nav.Item>
          }

          {can('invoice:list') &&
            <Nav.Item
              as={Link}
              active={location.pathname === `/app/${getEnv()}/financials/invoices`}
              to={`/app/${getEnv()}/financials/invoices`} onClick={onHide} icon={<Icon as={MdArticle} />}>
              <div>Invoices</div>
            </Nav.Item>
          }

          {can('cash-flow:list') &&
            <Nav.Item
              as={Link}
              active={location.pathname === `/app/${getEnv()}/financials/cash-flow`}
              to={`/app/${getEnv()}/financials/cash-flow`} onClick={onHide} icon={<Icon as={MdReceipt} />}>
              <div>Cash Flow</div></Nav.Item>
          }

          {can('seasonal-financial:list') &&
            <Nav.Item
              as={Link}
              active={location.pathname === `/app/${getEnv()}/financials/seasonal-financials`}
              to={`/app/${getEnv()}/financials/seasonal-financials`} onClick={onHide} icon={<Icon as={MdAutoStories} />}>
              <div>Seasonal Financials</div></Nav.Item>
          }
        </>
      }

      {can('daily-financial:list', 'pnl:list', 'invoice:list', 'cash-flow:list', 'seasonal-financial:list') &&
        <Divider />
      }

      {(can('employee:list') && !IS_KALADI_PROPERTIES) &&
        <Nav.Item
          as={Link}
          active={location.pathname === `/app/${getEnv()}/resourcing/employees`}
          to={`/app/${getEnv()}/resourcing/employees`} onClick={onHide} icon={<Icon as={MdPeople} />}>
          <div>Employees</div>
        </Nav.Item>
      }

      {(can('payroll:list') && !IS_KALADI_PROPERTIES) &&
        <Nav.Item
          as={Link}
          active={location.pathname === `/app/${getEnv()}/resourcing/payroll`}
          to={`/app/${getEnv()}/resourcing/payroll`} onClick={onHide} icon={<Icon as={MdPayments} />}>
          <div>Payroll</div>
        </Nav.Item>
      }
      {can('clock-in-out:list', 'clock-in-out:create') &&
        <Nav.Item
          as={Link}
          active={location.pathname === `/app/${getEnv()}/resourcing/clock-in-out`}
          to={`/app/${getEnv()}/resourcing/clock-in-out`} onClick={onHide} icon={<Icon as={MdAccessTimeFilled} />}>
          <div>Clock In/Out</div>
        </Nav.Item>
      }

      {can('time-off:list') &&
        <Nav.Item
          as={Link}
          active={location.pathname === `/app/${getEnv()}/resourcing/time-off`}
          to={`/app/${getEnv()}/resourcing/time-off`} onClick={onHide} icon={<Icon as={MdBedtime} />}>
          <div>Time Off</div>
        </Nav.Item>
      }

      {can('employee:list', 'payroll:list', 'clock-in-out:list', 'time-off:list') &&
        <Divider />
      }

      {can('daily-financial:list') &&
        <Nav.Item as={Link} to={`/app/${getEnv()}/reporting/gross-revenue`} onClick={onHide} icon={<Icon as={MdAreaChart} />}>
          <div>Reports</div>
        </Nav.Item>
      }
    </Nav>
  };

  return (
    <Fragment>
      {type === 'navbar' && options[options.hasOwnProperty(TENANT) ? TENANT : 'default']}
      {type === 'sidebar' && options[options.hasOwnProperty(TENANT) ? TENANT : 'default']}
      {
        type === 'drawer' && <Drawer backdrop='static' open={show} onClose={onHide} size={'xs'} style={{ width: '70%' }} placement="left">
          <Drawer.Header>
            <Drawer.Title>Menu</Drawer.Title>
          </Drawer.Header>
          <Drawer.Body style={{ margin: '10px 20px' }}>
            <div className='sidebar'>
              <Sidenav
                defaultOpenKeys={['work-manager', 'financials', 'resourcing', 'reporting']}
                expanded={expanded}
              >
                <Sidenav.Body>
                  {options[options.hasOwnProperty(TENANT) ? TENANT : 'default']}
                </Sidenav.Body>
              </Sidenav>
            </div>
          </Drawer.Body>
        </Drawer>
      }
    </Fragment >
  );
}

const SubNavigation = () => {
  const api = useApi();
  const history = useHistory();
  const { state } = useViewport();
  const { showDrawer } = useContext(ApplicationContext);
  const [feedback, setFeedback] = useState({ show: false, resource: '' });
  const { isRole, can, token } = usePrairieAuth();
  const [decoded, setDecoded] = useState<any>({});
  const [loadingScreenshot, setLoadingScreenshot] = useState(false);

  useEffect(() => {
    if (token) {
      setDecoded(jwtDecode(token));
    }
  }, [token]);

  const impersonate = async (sub: string) => {
    const response: any = await api.post('/oauth/v2/impersonate', {
      sub
    });

    if (response.access_token) {
      for (let [key] of Object.entries(localStorage)) {
        // clear everything but range filter
        if (key.indexOf('range-filter') === -1) {
          localStorage.removeItem(key);
        }
      }

      window.location.href = `/`;
    } else {
      toaster.push(
        <Message type="error" showIcon closable>Invalid username to impersonate</Message>
      );
    }
  }

  return (
    <>
      {!isMobileOnly &&
        <Nav.Item
          id="feedback"
          disabled={loadingScreenshot}
          onClick={() => {
            if (!loadingScreenshot) {
              setLoadingScreenshot(true);
              toaster.push(<Message type="info" showIcon closable>Capturing screenshot</Message>, { duration: 3000 });

              setTimeout(async () => {
                const resource = await htmlToImage.toPng(document.body);
                setFeedback({ show: true, resource });
                setLoadingScreenshot(false);
              }, 1000);
            }
          }}
        >{loadingScreenshot ? <Loader /> : <span>Feedback</span>}</Nav.Item>
      }

      <NavigationNewDropDownMenu />

      {(decoded?.act && !isMobileOnly) &&
        <Nav.Item
          className='font-base'
          as={Button}
          onClick={(e: any) => impersonate(decoded?.act.sub)}
          appearance="subtle">Exit {state.profile.operatorName.substr(0, 6)}...</Nav.Item>
      }

      {(can('user:impersonate', 'worker:impersonate') && state.users.length > 1) &&
        <SelectPicker
          className="impersonate"
          placement="bottomEnd"
          placeholder={isMobileOnly ? <span><MdPersonSearch size={'16px'} /></span> : <span>Impersonate</span>}
          appearance={"subtle"}
          data={state.users.filter((u: any) => u.id !== state.profile.id)}
          groupBy="role"
          valueKey="id"
          onSelect={(val: string) => {
            const user = state.users.find((u: any) => +u.id === +val);
            impersonate(user.guid);
          }}
          searchBy={(keyword: string, label: any, item: any) => {
            return item?.username.toLowerCase().includes(keyword.toLowerCase()) || item?.operatorName.toLowerCase().includes(keyword.toLowerCase());
          }}
        />
      }

      <Dropdown title={isMobileOnly ? <LegacyCogIcon /> : 'Account'} placement="bottomEnd" noCaret={isMobileOnly}>
        <Fragment>
          {can('profile:update') &&
            <Dropdown.Item onSelect={() => showDrawer(DRAWER.USER_MY_PREFERENCE_FORM)}>My Preferences</Dropdown.Item>
          }

          {can('field:list', 'action:list', 'company:list', 'billing:list', 'integration:list') &&
            <Dropdown.Item onSelect={() => history.push(`/app/${getEnv()}/settings`)}>Settings</Dropdown.Item>
          }

          {can('user:list', 'worker:list') &&
            <Dropdown.Item onSelect={() => history.push(`/app/${getEnv()}/users`)}>Users</Dropdown.Item>
          }

          {can('template:list') &&
            <Dropdown.Item onSelect={() => history.push(`/app/${getEnv()}/templates`)}>Templates</Dropdown.Item>
          }

          {state.companies.length > 1 &&
            <Fragment>
              <Dropdown.Item divider />
              {state.companies.map((c: any) =>
                <Dropdown.Item as={Link} to={`/company/change_company/${c.id}`}
                  onClick={() => window.location.href = `/company/change_company/${c.id}/`} key={`company-${c.id}`}>
                  {c.id === state.profile.companyId ? <strong>{c.name}</strong> : c.name}
                </Dropdown.Item>
              )}
            </Fragment>
          }

          {can('profile:update', 'field:list', 'action:list', 'company:list', 'billing:list', 'integration:list', 'user:list', 'worker:list', 'template:list') &&
            <Dropdown.Separator />
          }
        </Fragment>

        {decoded?.act &&
          <Dropdown.Item onClick={() => impersonate(decoded.act.sub)}>Exit {state.profile.operatorName}</Dropdown.Item>
        }

        {isMobileOnly &&
          <Dropdown.Item
            id="feedback"
            disabled={loadingScreenshot}
            onClick={async () => {
              setLoadingScreenshot(true);
              toaster.push(<Message type="info" showIcon closable>Capturing screenshot</Message>, { duration: 3000 });

              setTimeout(async () => {
                const resource = await htmlToImage.toPng(document.body);
                setFeedback({ show: true, resource });
                setLoadingScreenshot(false);
              }, 1000);
            }}>Feedback</Dropdown.Item>
        }

        {isRole(ROLE.CLIENT, ROLE.CLEANER) &&
          <Fragment>
            <Dropdown.Item as={Link} to={`/app/${getEnv()}/workbook/explorer/${state.profile?.customer?.id}/profile`}>My Profile</Dropdown.Item>
          </Fragment>
        }

        <Dropdown.Item onSelect={async () => {
          localStorage.clear();
          await api.post('/oauth/v2/logout', {});
          window.location.href = '/auth';
        }}>Sign Out</Dropdown.Item>
      </Dropdown>

      {feedback.show &&
        <Feedback
          onHide={() => setFeedback({ show: false, resource: '' })}
          resource={feedback.resource}
        />
      }
    </>
  );
}

export {
  MainNavigation,
  SubNavigation
}
