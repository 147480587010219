import { useEffect, useState } from 'react';
import { Input, InputGroup, Tooltip, Whisper } from 'rsuite';
import LegacyFilterIcon from "@rsuite/icons/legacy/Filter";
import LegacyCloseIcon from "@rsuite/icons/legacy/Close";

interface IInputFilter {
  onChange: (val: string) => void,
  onShowAdvancedFilter?: () => void,
  value?: string
  placeholder?: string
}

let timeout: NodeJS.Timeout;

const InputFilter = ({
  onChange,
  onShowAdvancedFilter,
  value,
  placeholder
}: IInputFilter): JSX.Element => {
  const [filter, setFilter] = useState(value || '');

  useEffect(() => {
    setFilter(value || '');
  }, [value]);

  const handleOnChange = (val: string) => {
    setFilter(val);

    if (timeout) {
      clearTimeout(timeout);
    }

    timeout = setTimeout(() => {
      onChange(val);
    }, 500);
  };

  return (
    <div>
      {onShowAdvancedFilter !== undefined
        ?
        <InputGroup inside>
          <Input
            size="sm"
            placeholder={placeholder || 'Search in anything'}
            onChange={(val: string) => handleOnChange(val)}
            value={filter}
            autoComplete='off'
            onPressEnter={(e: any) => {
              handleOnChange(e.target.value);
            }}
          />
          <InputGroup.Addon>
            <Whisper placement="bottom" trigger="hover" speaker={<Tooltip>Advanced Filter</Tooltip>}>
              <LegacyFilterIcon
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  onShowAdvancedFilter();
                }} />
            </Whisper>
          </InputGroup.Addon>
        </InputGroup>
        : <InputGroup inside>
          <Input
            size="sm"
            placeholder={placeholder || 'Search in anything'}
            onChange={(val: string) => handleOnChange(val)}
            value={filter}
            autoComplete='off'
            onPressEnter={(e: any) => {
              handleOnChange(e.target.value);
            }}
          />
          {filter.length > 0 &&
            <InputGroup.Addon>
              <LegacyCloseIcon
                style={{ cursor: 'pointer', fontSize: '10px', color: '#a6a6a6' }}
                onClick={() => handleOnChange('')} />
            </InputGroup.Addon>
          }
        </InputGroup>
      }
    </div>
  );
}

export default InputFilter;
