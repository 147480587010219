import Icon from '@rsuite/icons/lib/Icon';
import { omit } from 'lodash';
import { forwardRef } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { MdAdd, MdContentCopy, MdDelete, MdDownload, MdEdit, MdEmail, MdMoreVert, MdQuestionMark, MdRestore, MdSearch, MdViewColumn, MdVisibility } from 'react-icons/md';
import { IconButton } from 'rsuite';

const IconButtonWrapper = forwardRef((props: any, ref: any) => {
  const newProps: any = omit(props, 'children', 'text', 'expanded', 'icon');
  let text = '';
  let icon = props?.icon || undefined;
  let mdIcon = MdQuestionMark;

  switch (icon) {
    case 'eye':
    case 'view':
      text = 'View';
      mdIcon = MdVisibility;
      break;
    case 'edit2':
    case 'edit':
      text = 'Edit';
      mdIcon = MdEdit;
      break;
    case 'envelope':
    case 'email':
      text = 'Email';
      mdIcon = MdEmail;
      break;
    case 'plus':
    case 'add':
      text = 'Add';
      mdIcon = MdAdd
      break;
    case 'trash':
    case 'delete':
      text = 'Delete';
      mdIcon = MdDelete;
      break;
    case 'ellipsis-v':
      text = 'More';
      mdIcon = MdMoreVert;
      break;
    case 'download':
      text = 'Download';
      mdIcon = MdDownload;
      break;
    case 'columns':
      text = 'Detach';
      mdIcon = MdViewColumn;
      break;
    case 'copy':
      text = 'Clone';
      mdIcon = MdContentCopy;
      break;
    case 'search':
      text = 'Search';
      mdIcon = MdSearch;
      break;
    case 'undo':
    case 'folder-open':
      text = 'Restore';
      mdIcon = MdRestore;
      break;
    default:
      text = props.text ? props.text : '';
  }

  return (isMobileOnly || props.expanded)
    ? <IconButton ref={ref} icon={<Icon as={mdIcon} />} {...newProps} size="sm"> <span className='font-base'>{text}</span></IconButton>
    : <IconButton ref={ref} icon={<Icon as={mdIcon} />} {...newProps} />;
});

export default IconButtonWrapper;
