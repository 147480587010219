import { useRef, useState } from 'react';
import { Form, Button, Schema, InputGroup, Input, toaster, Message } from 'rsuite';
import { IS_APP } from 'lib/tenant';
import { useApi } from 'lib';
import { useLogger } from 'lib/logger';
import { useHistory } from 'react-router-dom';
import { getEnv } from 'lib/env';

const { StringType } = Schema.Types;

const model = Schema.Model({
  companyName: StringType().isRequired('This field is required'),
  companyId: StringType().isRequired('This field is required'),
  email: StringType().isRequired('This field is required'),
  password: StringType().isRequired('This field is required')
});

const initialFormValue = {
  companyName: '',
  companyId: '',
  email: '',
  password: ''
};

const AuthRegisterForm = () => {
  let form: any = useRef();
  const api = useApi();
  const history = useHistory();
  const logger = useLogger();
  const [formError, setFormError] = useState<any>({});
  const [formValue, setFormValue] = useState<any>(initialFormValue);
  const [processing, setProcessing] = useState(false);

  const handleSubmit = async (form: any, formValue: any) => {
    if (!form.check()) {
      toaster.push(
        <Message type="error" showIcon closable>Registration form has required fields which need to be completed</Message>
      );
      return;
    }

    setProcessing(true);
    const result: any = await api.post(`/oauth/v2/register/${formValue.companyId}`, formValue);
    setProcessing(false);

    if (result.success) {
      toaster.push(
        <Message type="success" showIcon closable duration={5000}>{result.message}</Message>
      );
      window.location.href = 'https://' + window.location.hostname.replace('app.', `${result.result.client_id}.`) + `?access_token=${result.result.access_token}&refresh_token=${result.result.refresh_token}`;
    } else {
      logger.error(formValue);
      toaster.push(
        <Message type="error" showIcon closable duration={5000}>{result.message}</Message>
      );
    }
  }

  const handleProviderSubmit = (provider: string) => {
    const error: any = {};

    if (formValue.companyName.trim().length === 0) {
      error.companyName = 'This field is required';
    }

    if (formValue.companyId.trim().length === 0) {
      error.companyId = 'This field is required';
    }

    if (Object.keys(error).length > 0) {
      setFormError(error);      
      toaster.push(
        <Message type="error" showIcon closable>Registration form has required fields which need to be completed</Message>
      );
      return;
    }

    if (provider === 'google') {
      window.location.href = `https://accounts.google.com/o/oauth2/auth?client_id=292801444118-957s3laanjr72t55tu6ugn85dpnckvm4.apps.googleusercontent.com&redirect_uri=https://app.prairiehq.${getEnv() === 'dev' ? 'net' : 'com'}/auth/google/signup&scope=openid%20email%20profile&response_type=code&state=company_id%3D${formValue.companyId}%2Bcompany_name%3D${encodeURIComponent(formValue.companyName)}`;
    } else if (provider === 'microsoft') {
      window.location.href = `https://login.microsoftonline.com/organizations/oauth2/v2.0/authorize?client_id=6c5c03a9-aa6b-46ac-ac1a-8740d449cb1c&redirect_uri=https://app.prairiehq.${getEnv() === 'dev' ? 'net' : 'com'}/auth/microsoft/signin&scope=offline_access%20User.Read&response_type=code&state=company_id%3D${formValue.companyId}%2Bcompany_name%3D${encodeURIComponent(formValue.companyName)}`;
    }
  }

  return (
    <Form
      fluid
      ref={(ref: any) => form = ref}
      formValue={formValue}
      formError={formError}
      onChange={setFormValue}
      onCheck={setFormError}
      model={model}
    >
      <Form.Group>
        <Form.ControlLabel className="required">Company name</Form.ControlLabel>
        <Form.Control
          name="companyName"
          onChange={(val: string) => {
            setFormValue({
              ...formValue,
              companyName: val,
              companyId: val.toLowerCase().replace(/[^a-zA-Z0-9]+/g, '')
            })
          }} />
      </Form.Group>

      <Form.Group>
        <Form.ControlLabel className="required">Your URL</Form.ControlLabel>
        <InputGroup style={{ width: '100%' }}>
          <Form.Control name="companyId" />
          <InputGroup.Addon>.prairiehq.com</InputGroup.Addon>
        </InputGroup>
      </Form.Group>

      <div className="text-center">
        <Button appearance='ghost' style={{ color: '#888e96', borderColor: '#e5e5ea' }} onClick={() => handleProviderSubmit('google')}>
          <img src="/google-icon.png" style={{ width: '18px' }} /> &nbsp;<strong>Sign up with Google&nbsp;&nbsp;&nbsp;&nbsp;</strong>
        </Button>
      </div>

      <div className="text-center mt-6">
        {/* <Button appearance='ghost' style={{ color: '#888e96', borderColor: '#e5e5ea' }} onClick={() => handleProviderSubmit('microsoft')}>
          <img src="/microsoft-icon.png" style={{ width: '18px' }} /> &nbsp;<strong>Sign up with Microsoft</strong>
        </Button> */}
        <div className="mt-12 mb-12">or<br />use other</div>
      </div>


      <Form.Group>
        <Form.ControlLabel className="required">Email</Form.ControlLabel>
        <Form.Control name="email" />
      </Form.Group>

      <Form.Group>
        <Form.ControlLabel className="required">Password</Form.ControlLabel>
        <Form.Control name="password" type="password" />
      </Form.Group>


      <Form.Group>
        <div className="text-center">
          <Button appearance="primary" loading={processing} onClick={() => handleSubmit(form, formValue)}>Sign up</Button>
        </div>
        <div className="text-center mt-12">
          <Button appearance="link" disabled={processing} onClick={() => history.push(`/auth`)}>Sign In</Button>
        </div>
      </Form.Group>
    </Form>
  );
}

export {
  AuthRegisterForm
}
