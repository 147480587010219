import React, { Fragment } from 'react';
import startCase from 'lodash/startCase';
import { useViewport } from 'shared/ViewportProvider';

interface IWorkOrderStatus {
  status: string
};

const WorkOrderStatus = ({ status }: IWorkOrderStatus) => {
  const { state } = useViewport();
  const field = (state.fields || []).find((f: any) => f.key === status);

  return <>
    {field
      ? <>
        <span style={{ color: field.color }}>{field.title}</span>
      </>
      : <>
        {status === 'deleted' && <strong className="text-danger">Deleted</strong>}
        {status === 'canceled' && <span className="text-warning">Canceled</span>}
        {status === 'completed' && <span className="text-success">Completed</span>}
        {['not_completed', 'scheduled'].includes(status) && <span className="text-danger">Not Completed</span>}
        {!['deleted', 'canceled', 'completed', 'not_completed'].includes(status) && <span>{startCase(status)}</span>}
      </>
    }
  </>
}

export default WorkOrderStatus;
