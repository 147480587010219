import React, { useEffect, useState, Fragment, useContext, useRef } from 'react';

import {
  Panel,
  Form,
  Grid,
  Row,
  Col,
  InputNumber,
  Whisper,
  Tooltip,
  IconButton,
  Input,
  DatePicker,
  Button,
  ButtonToolbar,
  SelectPicker,
  Loader,
  toaster,
  Message,
  InputGroup,
} from 'rsuite';

import LegacyTrashIcon from "@rsuite/icons/legacy/Trash";
import LegacyPlusIcon from "@rsuite/icons/legacy/Plus";
import { useApolloClient, gql } from '@apollo/client';
import { v4 as uuidv4 } from 'uuid';
import { FORMAT, ROLE, getEnv } from 'lib/env';
import { ApplicationContext, DrawerFormWrapper } from 'shared';
import { BrowserView, isBrowser, isMobileOnly, MobileView } from 'react-device-detect';
import FormField from '../../components/form/FormField';
import { queryJobs } from 'gql/jobs';
import { format, parseISO } from 'date-fns';
import { differenceInMinutes } from 'date-fns/esm';
import { getSort } from 'lib/helpers/sort';
import startCase from 'lodash/startCase';
import { useViewport } from 'shared/ViewportProvider';
import { omit, round, uniq } from 'lodash';
import { IS_BUGABOO } from 'lib/tenant';
import { useQueryString } from 'lib/hooks';
import { useHistory } from 'react-router-dom';
import { usePrairieAuth } from 'contexts/AuthPrairieProvider';
import { getCostPerJob, getTotalHoursWorked, getTotalSPMH } from 'lib/financials';
import { MdHelpOutline } from 'react-icons/md';
import { Sales } from './components';

const GET_DAILY_FINANCIALS = gql`
  query dailyFinancials($limit: Int!, $offset: Int!, $where: DailyFinancialsWhere, $order: [DailyFinancialsSort]) {
    dailyFinancials(filter: {
      limit: $limit,
      offset: $offset,
      where: $where
      order: $order
    }) {
      edges {
        node {
          guid
          refDate
          status
          userId
          royaltyPercentage
          dateOnCalendar
          labour {
            guid
            employeeName
            employeeType
            startTime
            endTime
            hoursWorked
            hourlyRate
            totalWage
            comments
          }
          expenses {
            guid
            expenseType
            itemName
            itemCost
          }
        }
      }
    }
  }
`;

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

const defaultFormValue = {
  labour: [
    {
      guid: uuidv4(),
      employeeName: undefined,
      employeeType: 'contract',
      startTime: undefined,
      endTime: undefined,
      hoursWorked: '',
      hourlyRate: 0,
      totalWage: 0
    }
  ] as any,
  expenses: [
    {
      guid: uuidv4(),
      expenseType: '',
      itemName: '',
      itemCost: ''
    }
  ] as any
};

interface IDailyFinancialsForm {
  show?: boolean,
  guid?: string,
  action?: string,
  drawer?: boolean,
  userId?: number,
  date?: Date,
  onHide?: () => void,
  onUpdate?: (data: any) => void,
}

const DailyFinancialsForm = ({ show, guid, action, userId, date, drawer, onHide, onUpdate }: IDailyFinancialsForm) => {
  const { isRole } = usePrairieAuth();
  const containerRef = useRef() as React.MutableRefObject<HTMLDivElement>;
  const client = useApolloClient();
  const history: any = useHistory();
  const query = useQueryString();
  const { state } = useViewport();
  const { showError } = useContext(ApplicationContext);
  const [formDate, setFormDate] = useState<Date>(date || new Date());
  const [jobs, setJobs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingJobs, setLoadingJobs] = useState(false);
  const [loadingLabour, setLoadingLabour] = useState(false);
  const [saving, setSaving] = useState(false);
  const [formValue, setFormValue] = useState({
    userId,
    royaltyPercentage: 0,
    labour: [],
    expenses: [],
    guid: query.guid || uuidv4(),
    dateOnCalendar: new Date()
  });
  const [formUserId, setFormUserId] = useState(userId);
  const [totalSales, setTotalSales] = useState(0);
  const [totalLabour, setTotalLabour] = useState(0);
  const [totalLabourHours, setTotalLabourHours] = useState(0);
  const [totalExpenses, setTotalExpenses] = useState(0);
  const [totalNetProfit, setTotalNetProfit] = useState(0);
  const [totalRoyalty, setTotalRoyalty] = useState(0);
  const [formCheck, setFormCheck] = useState(false);
  const [dailyFinancialsFound, setDailyFinancialsFound] = useState(false);
  const [royaltyPercentage, setRoyaltyPercentage] = useState(state.profile.royalty_percentage);
  const [operatorName, setOperatorName] = useState<string>('');
  const [formAction, setFormAction] = useState(action || 'add');
  const [formGuid, setFormGuid] = useState(guid || query.guid || undefined);
  const [hourlyEstimates, setHourlyEstimates] = useState<any[]>([]);
  const salesPerManHourTarget = state.companies[0].salesPerManHourTarget;

  useEffect(() => setFormGuid(guid), [guid]);

  useEffect(() => {
    setFormGuid(undefined);
    setFormUserId(userId);
    setFormDate(date || new Date());

    if (date) {
      setFormValue({
        userId,
        royaltyPercentage: 0,
        labour: [],
        expenses: [],
        guid: query.guid || uuidv4(),
        dateOnCalendar: date
      });
    }
  }, [userId, date, query.guid]);

  useEffect(() => {
    if (query.userId) {
      setFormUserId(+query.userId);
    }

    if (query?.date) {
      setFormDate(parseISO(query.date));
    }
  }, [query]);

  useEffect(() => {
    (async function getDailyFinancials() {
      let data: any = null;
      let currentUser = state.users.find((u: any) => u.id === formUserId);
      setLoading(true);

      if (formGuid) {
        data = await client.query({
          query: GET_DAILY_FINANCIALS,
          variables: {
            offset: 0,
            limit: 100,
            where: {
              AND: {
                guid: { is: formGuid }
              }
            },
            order: getSort('daily-financials')
          },
          fetchPolicy: 'no-cache'
        });

        const nextFormValue = data.data.dailyFinancials.edges.node[0];
        nextFormValue.dateOnCalendar = typeof (nextFormValue.dateOnCalendar) === 'string' ? parseISO(nextFormValue.dateOnCalendar) : nextFormValue.dateOnCalendar;

        setRoyaltyPercentage(nextFormValue.royaltyPercentage);
        setOperatorName(state.users.find((c: any) => c.id === nextFormValue.userId)?.label || '');
        await getJobs(nextFormValue.userId, nextFormValue.dateOnCalendar);
        setFormValue(nextFormValue);
        setDailyFinancialsFound(true);
        setFormAction('edit');
      } else if (formUserId) {
        setDailyFinancialsFound(false);
        setFormAction('add');
        setRoyaltyPercentage(state.profile.userRoyaltyPercentage || 0);

        data = await client.query({
          query: GET_DAILY_FINANCIALS,
          variables: {
            offset: 0,
            limit: 100,
            where: {
              AND: {
                status: { is: 'completed' },
                userId: { is: formUserId }
              }
            },
            order: getSort('daily-financials')
          },
          fetchPolicy: 'no-cache'
        });

        if (data && data.data.dailyFinancials.edges.node.length > 0) {
          let df: any = data.data.dailyFinancials.edges.node.find((n: any) => format(parseISO(n.dateOnCalendar), FORMAT.ISO_DATE) === format(formDate, FORMAT.ISO_DATE));

          if (df) {
            setFormGuid(df.guid);
          } else {
            df = data.data.dailyFinancials.edges.node[0];
            setOperatorName(state.users.find((c: any) => c.id === formUserId)?.label || '');
            await getJobs(formUserId, formDate);
            const labourData = await getLabour(formUserId, formDate);

            let newLabour: any = [];
            newLabour = df.labour.map((l: any) => ({ ...l, guid: uuidv4() }));
            const clockInOutLabour = labourData
              .filter((e: any) => e.clockOut)
              .map((e: any) => ({
                ...e,
                totalWage: parseFloat(e.totalWage || 0).toFixed(2)
              }));

            if (clockInOutLabour.length > 0) {
              newLabour = clockInOutLabour.map((e: any) => ({
                employeeName: e.employee?.employeeName || '',
                employeeType: e.employee?.employeeType || 'contract',
                endTime: e.clockOut,
                guid: uuidv4(),
                hourlyRate: e.hourlyPay,
                hoursWorked: e.hoursWorked,
                startTime: e.clockIn,
                totalWage: parseFloat(e.totalPaid).toFixed(2)
              }));
            }

            const newExpenses = df.expenses.map((e: any) => ({ ...e, guid: uuidv4() }));
            newLabour = newLabour.sort((a: any, b: any) => a.employeeName.localeCompare(b.employeeName));

            formValue.guid = uuidv4();
            formValue.labour = newLabour;
            formValue.expenses = newExpenses;
            formValue.dateOnCalendar = formDate;
            formValue.userId = formUserId;
            formValue.royaltyPercentage = currentUser.royaltyPercentage;

            setFormValue(formValue);
          }
        } else {
          await getJobs(formUserId, formDate);

          // nothing found, first time user
          setFormValue({
            ...defaultFormValue,
            guid: uuidv4(),
            userId: formUserId,
            dateOnCalendar: formDate,
            royaltyPercentage: currentUser.royaltyPercentage,
            labour: [],
            expenses: []
          });
        }
      }

      setLoading(false);
    })();
  }, [formGuid, formDate, formUserId]);

  const getJobs = async (userId: number, date: Date) => {
    setLoadingJobs(true);
    setTotalSales(-1);

    const andFilter: any = [
      {
        isActive: { is: 1 },
        AND: [
          // { startDate: { gte: new Date(new Date(+date).setUTCHours(0, 0, 0, 0)) } },
          // { startDate: { lte: new Date(new Date(+date).setUTCHours(23, 59, 59, 0)) } }
          { startDate: { gte: new Date(new Date(+date).setHours(0, 0, 0, 0)) } },
          { startDate: { lte: new Date(new Date(+date).setHours(23, 59, 59, 0)) } }
        ],
        status: { is: 'completed' }
      }
    ];

    andFilter[0].userId = { is: userId };

    const data = await client.query({
      query: queryJobs,
      variables: {
        offset: 0,
        limit: 100,
        where: {
          AND: andFilter
        },
        order: getSort('jobs')
      },
      fetchPolicy: 'no-cache'
    });

    const dailyJobs = data.data.jobs.edges.node;
    const totalSalesVal = dailyJobs.reduce((prev: any, next: any) => prev + getCostPerJob(next), 0);

    const workOrderHourlyEstimates = await client.query({
      query: gql`
        query workOrderHourlyEstimates($guid: [ID]) {
          workOrderHourlyEstimates(guid:$guid) {
            edges {
              node {
                guid
                totalCost
                perCostTypeCost
                hoursSpent
                hoursEstimated
                hoursRemaining
              }
            }
          }
        }
      `,
      variables: {
        guid: uniq(dailyJobs.map((j: any) => j.workOrderGuid))
      },
      fetchPolicy: 'no-cache'
    });

    setHourlyEstimates(workOrderHourlyEstimates.data.workOrderHourlyEstimates?.edges?.node || []);

    setJobs(dailyJobs);
    setTotalSales(totalSalesVal);
    setLoadingJobs(false);
  };

  const getLabour = async (userId: number, date: Date) => {
    setLoadingLabour(true);

    const data: any = await client.query({
      query: gql`
        query employeesPayrolls($limit: Int!, $offset: Int!, $where: EmployeesPayrollsWhere, $sort: EmployeesPayrollsSort) {
          employeesPayrolls(filter: {
            limit: $limit
            offset: $offset
            where: $where
            sort: $sort
          }) {
            edges {
              node {
                hourlyPay
                totalPaid
                hoursWorked
                clockIn
                clockOut
                userId
                employee {
                  employeeName
                  employeeType
                }
              }
            },
            totalCount
          }
        }`,
      variables: {
        offset: 0,
        limit: 20,
        where: { userId: { is: userId }, clockInOutDate: { is: date } },
        sort: { dateOnCalendar: 'DESC' }
      }
    });

    setLoadingLabour(false);
    return data.data.employeesPayrolls.edges.node;
  };

  useEffect(() => {
    calculateTotals(formValue.labour, formValue.expenses);
  }, [formValue, royaltyPercentage, totalSales]);

  const calculateTotals = (formValueLabour: any, formValueExpenses: any) => {
    let labour = formValueLabour.reduce((prev: any, next: any) => {
      prev = prev + +next.totalWage;
      return prev;
    }, 0);

    let labourHours = formValueLabour.reduce((prev: any, next: any) => {
      prev = prev + +next.hoursWorked;
      return prev;
    }, 0);

    let expenses = formValueExpenses.reduce((prev: any, next: any) => {
      prev = prev + +next.itemCost;
      return prev;
    }, 0);

    expenses = isNaN(expenses) ? 0 : expenses;
    labour = isNaN(labour) ? 0 : labour;
    const totalRoyaltyVal = totalSales * royaltyPercentage / 100.0;

    setTotalLabourHours(labourHours);
    setTotalLabour(labour);
    setTotalExpenses(expenses);
    setTotalRoyalty(totalRoyaltyVal);
    setTotalNetProfit(totalSales - labour - expenses - totalRoyaltyVal);
    setFormCheck(false);
  }

  const addLabour = () => {
    const tmp = JSON.parse(JSON.stringify(formValue));
    const labour = JSON.parse(JSON.stringify(defaultFormValue.labour[0]));
    labour.guid = uuidv4();
    tmp.labour.push(labour);
    tmp.dateOnCalendar = parseISO(tmp.dateOnCalendar);
    setFormValue(tmp);
  }

  const addExpenses = () => {
    const tmp = JSON.parse(JSON.stringify(formValue));
    const expenses = JSON.parse(JSON.stringify(defaultFormValue.expenses[0]));
    expenses.guid = uuidv4();
    tmp.expenses.push(expenses);
    tmp.dateOnCalendar = parseISO(tmp.dateOnCalendar);
    setFormValue(tmp);
  }

  const clearLabour = () => {
    const tmp = JSON.parse(JSON.stringify(formValue));
    tmp.labour = [];
    tmp.dateOnCalendar = parseISO(tmp.dateOnCalendar);
    setFormValue(tmp);
  }

  const clearExpenses = () => {
    const tmp = JSON.parse(JSON.stringify(formValue));
    tmp.expenses = [];
    tmp.dateOnCalendar = parseISO(tmp.dateOnCalendar);
    setFormValue(tmp);
  }

  const handleFormFieldOnChange = (group: string, guid: string, key: string, val: string | Date | number) => {
    const tmp = JSON.parse(JSON.stringify(formValue));
    tmp.dateOnCalendar = parseISO(tmp.dateOnCalendar);

    tmp[group].forEach((g: any) => {
      if (g.guid === guid) {
        g[key] = val;

        if (g.startTime && typeof (g.startTime) === 'string') {
          g.startTime = parseISO(g.startTime);
        }

        if (g.endTime && typeof (g.endTime) === 'string') {
          g.endTime = parseISO(g.endTime);
        }

        if (g.startTime && g.endTime) {
          const hoursSpent = (differenceInMinutes(g.endTime, g.startTime) / 60).toFixed(2);

          g.hoursWorked = +hoursSpent;
          g.totalWage = g.employeeType === 'salaried' ? (+g.hourlyRate || 0) : g.hoursWorked * (+g.hourlyRate || 0);
          g.totalWage = isNaN(g.totalWage) ? 0 : g.totalWage.toFixed(2);
        }
      }
    });

    setFormValue(tmp);
  }

  const remove = (guid: string) => {
    const tmp = JSON.parse(JSON.stringify(formValue));
    tmp.dateOnCalendar = parseISO(tmp.dateOnCalendar);

    tmp.labour = tmp.labour.map((l: any) => {
      return l.guid === guid ? null : l
    }).filter((l: any) => l);

    tmp.expenses = tmp.expenses.map((l: any) => {
      return l.guid === guid ? null : l
    }).filter((l: any) => l);

    setFormValue(tmp);
  }

  const handleSubmit = async (stay: boolean) => {
    const errorMessage = 'Daily financials have errors which need to be corrected';
    let hasErrors = false;
    setFormCheck(true);

    formValue.labour.forEach((l: any) => {
      hasErrors = ((l.employeeName || '').length === 0)
        || ((l.startTime || '').length === 0)
        || ((l.endTime || '').length === 0)
        || (l.hourlyRate.length === 0);

      if (hasErrors) {
        return;
      }
    });

    if (!hasErrors) {
      formValue.expenses.forEach((l: any) => {
        hasErrors = ((l.expenseType || '').length === 0)
          || ((l.itemCost || '').length === 0);

        if (hasErrors) {
          return;
        }
      });
    }

    if (hasErrors) {
      toaster.push(<Message type="error" showIcon closable>{errorMessage}</Message>);
    } else {
      setSaving(true);

      try {
        const upsertDailyFinancials = gql`
        mutation upsertDailyFinancials($input: UpsertDailyFinancialsInput!) {
          upsertDailyFinancials(input: $input) {
            success
            code
            message
            result
          }
        }`;
        ;
        const response: any = await client.mutate({
          mutation: upsertDailyFinancials, variables: {
            input: omit({
              ...formValue,
              royaltyPercentage,
              totalExpenses,
              totalNetProfit,
              totalLabour,
              totalSales,
              totalRoyalty,
              salesPerManHour: getTotalSPMH(totalSales, jobs, formValue.labour).value,
              totalLabourHours: getTotalHoursWorked(formValue.labour)
            }, ['refDate', 'status'])
          }
        });

        if (response.data.upsertDailyFinancials.success) {
          toaster.push(
            <Message type="success" showIcon closable>{response.data.upsertDailyFinancials.message}</Message>
          );

          if (drawer) {
            onHide && onHide();
            onUpdate && onUpdate(response);
          } else if (stay) {
            history.push(`/financials/dailies/form/${response.data.upsertDailyFinancials.result.guid}`);
          } else if (!stay) {
            history.push(`/financials/dailies`);
          }
        } else {
          showError(response);
        }
      } catch (err) {
        showError(err);
      }

      setSaving(false);
    }
  }

  const getPercentage = (val: any) => {
    if (isNaN(val) || val === Number.POSITIVE_INFINITY || val === Number.NEGATIVE_INFINITY) {
      return 0;
    }

    return val.toFixed(0);
  }

  const getForm = () => {
    if (formAction === 'edit') {
      return `/financials/dailies/edit/${formValue.guid}`;
    } else if (action === 'add' && formUserId) {
      return `/financials/dailies/add/${formUserId}/${formDate.toISOString()}`;
    } else {
      return `/financials/dailies/add`;
    }
  }

  const Wrapper = show === undefined
    ? <div />
    : <DrawerFormWrapper
      loading={saving || loading}
      show={show}
      title={startCase(action) + ' Daily Financials'}
      guid={guid}
      action={formAction}
      form={getForm()}
      onHide={onHide}
      onSave={handleSubmit}
    />;

  return React.cloneElement(Wrapper, {
    children: <div ref={drawer ? containerRef : undefined}>
      <Panel className={['content', isMobileOnly || drawer ? 'reset' : ''].join(' ')}>
        <Form formValue={formValue} fluid>
          <Grid fluid>
            <Row className={['mb-5', isMobileOnly && 'rs-panel-card'].join(' ')}>
              <Col sm={24}>
                <legend>Daily Financials for {operatorName} on {format(formValue.dateOnCalendar, FORMAT.MONTH_DATE)}</legend>
              </Col>
              {getEnv() === 'dev' &&
                <Col md={24} className="mb-12">
                  <label>UUID:</label>
                  <p>{formValue?.guid}</p>
                </Col>
              }
              <Col sm={4} md={4}>
                {state.users.filter((s: any) => s.userlevel !== 'worker').length > 1
                  ? <Form.Group>
                    <Form.ControlLabel>Select User:</Form.ControlLabel>
                    <SelectPicker
                      block
                      container={() => containerRef?.current}
                      size="sm"
                      searchable={true}
                      cleanable={false}
                      onChange={(val: any) => {
                        setFormUserId(+val);
                        setFormGuid(undefined);
                      }}
                      value={formValue.userId}
                      data={state.users.filter((s: any) => s.userlevel !== 'worker')}
                    />
                  </Form.Group>
                  : <Form.Group>
                    <Form.ControlLabel>User:</Form.ControlLabel>
                    <p>{state.users.find((u: any) => u.id === formUserId)?.operatorName}</p>
                  </Form.Group>
                }

                <Form.Group>
                  <Form.ControlLabel>Change Date:</Form.ControlLabel>
                  <Form.Control
                    name="formDate"
                    accepter={DatePicker}
                    container={() => containerRef?.current}
                    data-lpignore="true"
                    cleanable={false}
                    style={{ width: "100%" }}
                    value={formValue.dateOnCalendar}
                    oneTap
                    block
                    onChange={(val: any) => {
                      setFormDate(val);
                      setFormGuid(undefined);
                    }} />
                </Form.Group>
              </Col>
            </Row>

            <br />

            {(!dailyFinancialsFound && !loading) &&
              <Fragment>
                <Message type="info" title="No daily financials entered">Completed jobs for this day and history from previous completion have been imported to assist with completion.</Message><br />
              </Fragment>
            }

            <Row>
              <Col sm={24}>
                <legend>Sales</legend>
              </Col>
            </Row>
            {loadingJobs
              ? <Loader content="Loading..." />
              : <div className={['mb-5', isMobileOnly && 'rs-panel-card'].join(' ')}>
                {jobs.length === 0
                  ? <p>No <strong>completed</strong> jobs found on this date. Please ensure your jobs are completed
                    to include them into sales.<br /><br /></p>
                  : <Sales jobs={jobs} salesPerManHourTarget={salesPerManHourTarget} loading={loadingJobs} />
                }
              </div>
            }

            <Row>
              <Col sm={24}>
                <legend>Labour</legend>
              </Col>

              {loadingLabour
                ? <Loader content="Loading..." />
                : <Grid fluid>
                  {formValue.labour.map((l: any, index: number) =>
                    <Row key={l.guid} className={['mb-5', isMobileOnly && 'rs-panel-card'].join(' ')}>
                      <Col xs={24} md={4}>
                        <FormField label="Employee Name" index={index}>
                          <Form.Control
                            name="employeeName"
                            accepter={Input}
                            style={{ width: '100%' }}
                            data-lpignore="true"
                            value={l.employeeName || ''}
                            onChange={(val: string) => handleFormFieldOnChange('labour', l.guid, 'employeeName', val)}
                            errorMessage={(formCheck && (l.employeeName || '').length === 0) ? 'Field is required' : ''} />
                        </FormField>
                      </Col>
                      <Col xs={24} md={3}>
                        <FormField label="Type" index={index}>
                          <Form.Control
                            name="type"
                            accepter={SelectPicker}
                            container={() => containerRef?.current}
                            style={{ width: '100%' }}
                            data-lpignore="true"
                            searchable={false}
                            cleanable={false}
                            value={l.employeeType || 'contract'}
                            data={
                              [
                                { label: 'Salaried', value: 'salaried' },
                                { label: 'Contract', value: 'contract' }
                              ]
                            }
                            onChange={(val: any) => handleFormFieldOnChange('labour', l.guid, 'employeeType', val)}
                            errorMessage={(formCheck && (l.employeeType || '').length === 0) ? 'Field is required' : ''} />
                        </FormField>
                      </Col>
                      <Col xs={24} md={2}>
                        <FormField label="Start" index={index}>
                          <Form.Control
                            name="startTime"
                            value={l.startTime ? (typeof (l.startTime) === 'string' ? parseISO(l.startTime) : l.startTime) : undefined}
                            accepter={DatePicker}
                            format="HH:mm"
                            container={() => containerRef?.current}
                            style={{ width: '100%' }}
                            placement={isMobileOnly ? "bottomEnd" : "bottomStart"}
                            onChange={(val: any) => handleFormFieldOnChange('labour', l.guid, 'startTime', val)}
                            errorMessage={(formCheck && (l.startTime || '').length === 0) ? 'Field is required' : ''}
                            ranges={[]} />
                        </FormField>
                      </Col>
                      <Col xs={24} md={2}>
                        <FormField label="End" index={index}>
                          <Form.Control
                            name="endTime"
                            accepter={DatePicker}
                            format="HH:mm"
                            placement={isMobileOnly ? "bottomEnd" : "bottomStart"}
                            container={() => containerRef?.current}
                            style={{ width: '100%' }}
                            value={l.endTime ? (typeof (l.endTime) === 'string' ? parseISO(l.endTime) : l.endTime) : undefined}
                            onChange={(val: any) => handleFormFieldOnChange('labour', l.guid, 'endTime', val)}
                            errorMessage={(formCheck && (l.endTime || '').length === 0) ? 'Field is required' : ''}
                            ranges={[]} />
                        </FormField>
                      </Col>
                      <Col xs={24} md={2}>
                        <FormField label="Hours Worked" index={index}>
                          <Form.Control
                            name={`labour[${index}].hoursWorked`}
                            readOnly={true}
                            accepter={Input}
                            value={l.hoursWorked}
                            style={{ width: '100%', backgroundColor: '#fafafa' }} />
                        </FormField>
                      </Col>
                      <Col xs={24} md={2}>
                        <FormField label="Rate" index={index}>
                          <InputGroup inside>
                            <Form.Control
                              name={`labour[${index}].hourlyRate`}
                              accepter={InputNumber}
                              data-lpignore="true"
                              style={{ width: '100%' }}
                              value={l.hourlyRate}
                              onChange={(val: number | string) => handleFormFieldOnChange('labour', l.guid, 'hourlyRate', val)}
                              errorMessage={(formCheck && l.hourlyRate.length === 0) ? 'Field is required' : ''} />
                            <InputGroup.Addon>{(l.employeeType || 'contract') === 'salaried' ? '/day' : '/hr'}</InputGroup.Addon>
                          </InputGroup>
                        </FormField>
                      </Col>
                      <Col xs={24} md={2}>
                        <FormField label="Total Wage" index={index}>
                          <Form.Control
                            name={`labour[${index}].totalWage`}
                            readOnly={true}
                            accepter={Input}
                            value={l.totalWage}
                            style={{ width: '100%', backgroundColor: '#fafafa' }} />
                        </FormField>
                      </Col>
                      <Col xs={24} md={5}>
                        <FormField label="Comments" index={index}>
                          <Form.Control
                            name={`labour[${index}].comments`}
                            value={l.comments}
                            onChange={(val: string) => handleFormFieldOnChange('labour', l.guid, 'comments', val)}
                          />
                        </FormField>
                        <MobileView>
                          <div className="text-right mt-10">
                            <FormField label="" index={index}>
                              <Button size="xs" appearance="link" onClick={() => remove(l.guid)}>
                                <LegacyTrashIcon /> Remove
                              </Button>
                            </FormField>
                          </div>
                        </MobileView>
                      </Col>
                      <BrowserView>
                        <Col sm={4} md={2} style={{ marginTop: (isBrowser && index === 0) ? 27 : 0 }}>
                          <Whisper placement="top" trigger="hover" speaker={<Tooltip>Add</Tooltip>}>
                            <IconButton size="xs" appearance="link" onClick={addLabour} icon={<LegacyPlusIcon />} />
                          </Whisper>
                          <Whisper placement="top" trigger="hover" speaker={<Tooltip>Remove</Tooltip>}>
                            <IconButton size="xs" appearance="link" onClick={() => remove(l.guid)} icon={<LegacyTrashIcon />} />
                          </Whisper>
                        </Col>
                      </BrowserView>
                    </Row>
                  )}
                  <Row>
                    <Col md={11}>
                      <div>
                        <Button appearance="link" onClick={addLabour} size="sm">Add Labour</Button>
                        <Button appearance="subtle" onClick={clearLabour} size="sm">Clear All</Button>
                      </div>
                    </Col>
                    <Col>
                      <div  className='mt-4'>
                        <strong>Total Hours Worked: {getTotalHoursWorked(formValue.labour)}</strong>
                      </div>
                    </Col>
                  </Row>
                </Grid>
              }
            </Row>

            <br />
            <Row>
              <Col sm={24}>
                <legend>Expenses</legend>
              </Col>

              <Grid fluid>
                {formValue.expenses.map((e: any, index: number) =>
                  <Row key={e.guid} className={['mb-5', isMobileOnly && 'rs-panel-card'].join(' ')}>
                    <Col sm={24} md={4}>
                      <FormField label="Expense Type" index={index}>
                        <Form.Control
                          name="expenseType"
                          accepter={SelectPicker}
                          style={{ width: '100%' }}
                          data-lpignore="true"
                          value={e.expenseType || ''}
                          searchable={false}
                          cleanable={false}
                          container={() => containerRef?.current}
                          data={
                            [
                              { label: 'Daily Overhead', value: 'daily_overhead' },
                              { label: 'Dumping', value: 'dumping' },
                              { label: 'Fuel', value: 'fuel' },
                              { label: 'Miscellaneous', value: 'miscellaneous' },
                              { label: 'Other', value: 'other' },
                              { label: 'Product', value: 'product' },
                              { label: 'Supplies', value: 'supplies' }
                            ]
                          }
                          onChange={(val: any) => handleFormFieldOnChange('expenses', e.guid, 'expenseType', val)}
                          errorMessage={(formCheck && (e.expenseType || '').length === 0) ? 'Field is required' : ''} />
                      </FormField>
                    </Col>
                    <Col sm={24} md={6}>
                      <FormField label="Item Name" index={index}>
                        <Form.Control
                          accepter={Input}
                          style={{ width: '100%' }}
                          data-lpignore="true"
                          name="itemName"
                          autoComplete="off"
                          value={e.itemName || ''}
                          onChange={(val: string) => handleFormFieldOnChange('expenses', e.guid, 'itemName', val)} />
                      </FormField>
                    </Col>
                    <Col sm={24} md={3}>
                      <FormField label="Cost" index={index}>
                        <Form.Control
                          name={`itemCost`}
                          accepter={InputNumber}
                          data-lpignore="true"
                          style={{ width: '100%' }}
                          value={e.itemCost || ''}
                          onChange={(val: number | string) => handleFormFieldOnChange('expenses', e.guid, 'itemCost', val)}
                          errorMessage={(formCheck && (e.itemCost || '').length === 0) ? 'Field is required' : ''} />
                      </FormField>
                      <MobileView>
                        <div className="text-right mt-10">
                          <FormField label="" index={index}>
                            <Button size="xs" appearance="link" onClick={() => remove(e.guid)}>
                              <LegacyTrashIcon /> Remove
                            </Button>
                          </FormField>
                        </div>
                      </MobileView>
                    </Col>
                    <BrowserView>
                      <Col sm={4} md={2} style={{ marginTop: (isBrowser && index === 0) ? 27 : 0 }}>
                        <Whisper placement="top" trigger="hover" speaker={<Tooltip>Add</Tooltip>}>
                          <IconButton size="xs" appearance="link" onClick={addExpenses} icon={<LegacyPlusIcon />} />
                        </Whisper>
                        <Whisper placement="top" trigger="hover" speaker={<Tooltip>Remove</Tooltip>}>
                          <IconButton size="xs" appearance="link" onClick={() => remove(e.guid)} icon={<LegacyTrashIcon />} />
                        </Whisper>
                      </Col>
                    </BrowserView>
                  </Row>
                )}
                <Button appearance="link" onClick={addExpenses} size="sm">Add Expense</Button>
                <Button appearance="subtle" onClick={clearExpenses} size="sm">Clear All</Button>

                <br /><br />

                <Row>
                  <Col sm={24}>
                    <legend>Totals</legend>
                  </Col>

                  <Grid fluid className={['mb-5', isMobileOnly && 'rs-panel-card'].join(' ')}>
                    <Row>
                      <Col xs={12} md={4} className="text-right"><strong>Total Sales:</strong></Col>
                      <Col xs={6} md={7}>{formatter.format(totalSales)}</Col>
                    </Row>
                    {(IS_BUGABOO) &&
                      <Row>
                        <Col xs={12} md={4} className="text-right"><strong>{`Royalty (${royaltyPercentage}%)`}:</strong></Col>
                        <Col xs={6} md={3}>{formatter.format(totalRoyalty)}</Col>
                        <Col xs={6} md={3}>{getPercentage(totalRoyalty / totalSales * 100.0)}%</Col>
                      </Row>
                    }
                    <Row>
                      <Col xs={12} md={4} className="text-right"><strong>Labour:</strong></Col>
                      <Col xs={6} md={3}>{formatter.format(totalLabour)}</Col>
                      <Col xs={6} md={3}>{getPercentage(totalLabour / totalSales * 100)}%</Col>
                    </Row>
                    <Row>
                      <Col xs={12} md={4} className="text-right"><strong>Operating Expenses:</strong></Col>
                      <Col xs={6} md={3}>{formatter.format(totalExpenses)}</Col>
                      <Col xs={6} md={3}>{getPercentage(totalExpenses / totalSales * 100)}%</Col>
                    </Row>
                    <Row>
                      <Col xs={12} md={4} className="text-right"><strong>Net Profit:</strong></Col>
                      <Col xs={6} md={3}>{formatter.format(totalNetProfit)}</Col>
                      <Col xs={6} md={3}>{getPercentage(totalNetProfit / totalSales * 100)}%</Col>
                    </Row>
                    <Row>
                      <Col xs={12} md={4} className="text-right"><strong>Sales Per Man Hour:</strong></Col>
                      <Col xs={6} md={7}>
                        {formatter.format(getTotalSPMH(totalSales, jobs, formValue.labour).value)}<span> </span>
                        <Whisper placement='bottom' speaker={<Tooltip>{getTotalSPMH(totalSales, jobs, formValue.labour).label}</Tooltip>}><span><MdHelpOutline /></span></Whisper>
                      </Col>
                    </Row>
                  </Grid>
                </Row>

                {!drawer &&
                  <Row className="mt-24">
                    <Col xs={12}>
                      <ButtonToolbar>
                        <Button appearance="primary" onClick={() => handleSubmit(false)} disabled={loading} loading={saving}>Save</Button>
                        <Button appearance="ghost" onClick={() => handleSubmit(true)} loading={saving}>Save &amp; Stay</Button>
                      </ButtonToolbar>
                    </Col>
                  </Row>
                }
              </Grid>
            </Row>
          </Grid>
        </Form>
      </Panel>
    </div>
  });
}

export default DailyFinancialsForm;
