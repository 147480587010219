import { Fragment, useEffect, useState } from 'react';

import { SelectPicker, Row, Col, Input, IconButton, Whisper, Tooltip, Dropdown, InputGroup } from 'rsuite';
import LegacyCheckIcon from "@rsuite/icons/legacy/Check";
import LegacyCloseIcon from "@rsuite/icons/legacy/Close";
import LegacyEllipsisVIcon from "@rsuite/icons/legacy/EllipsisV";
import LegacySearchIcon from "@rsuite/icons/legacy/Search";
import LegacyRefreshIcon from "@rsuite/icons/legacy/Refresh";
import LegacyPlusIcon from "@rsuite/icons/legacy/Plus";
import LegacyTrashIcon from "@rsuite/icons/legacy/Trash";
import LegacyEyeIcon from "@rsuite/icons/legacy/Eye";
import LegacyEyeSlashIcon from "@rsuite/icons/legacy/EyeSlash";
import LegacyUndoIcon from "@rsuite/icons/legacy/Undo";
import { SortableHandle } from 'react-sortable-hoc';
import TextareaAutosize from 'react-autosize-textarea';
import { useViewport } from 'shared/ViewportProvider';
import { filterFieldsByParentKey } from 'lib/helpers/field';
import { orderBy } from 'lodash';
import { COLOR, ROLE } from 'lib/env';
import { usePrairieAuth } from 'contexts/AuthPrairieProvider';
import { IS_BUGABOO, moneyFormatter } from 'lib/tenant';
import { v4 as uuidv4 } from 'uuid';
import { MdAdd, MdDelete } from 'react-icons/md';

interface IDocumentService {
  id: string,
  guid: string,
  service: string,
  workDescription: string,
  previousWorkDescription?: string | undefined,
  workCost: string,
  previousWorkCost: string,
  people: string,
  manHours: string,
  isVisible: boolean,
  previousIsVisible: boolean,
  sortOrder: number,
  status: string | undefined,
  templateServiceId: string | undefined,
  workType: string,
  costBreakdown: any
}

interface IDocumentServiceCostBreakdown {
  guid: string,
  description: string,
  cost: string
}

interface IDocumentServiceLineItem extends IDocumentService {
  handleAddService: (guid: string) => void,
  handleRemoveService: (guid: string) => void,
  handleUpdateService: (guid: string, key: string, val: any) => void,
  handleUpdateServiceAll: (guid: string, service: string, description: string, templateServiceId: string) => void,
  handleUpdateVisibility: (guid: string, isVisible: boolean) => void,
  readOnly: boolean,
  container: any,
  template: any,
  includePreviousCost: boolean,
  disableFormatting: boolean,
  documentStatus: any
  includeCostBreakdown: boolean
}

const DragHandle = SortableHandle(() => <span style={{ cursor: 'grab' }}>&nbsp;::</span>);

const DocumentServiceLineItem = ({
  id,
  guid,
  service,
  workDescription,
  previousWorkDescription,
  workCost,
  previousWorkCost,
  people,
  manHours,
  isVisible,
  previousIsVisible,
  sortOrder,
  status,
  container,
  handleAddService,
  handleRemoveService,
  handleUpdateService,
  handleUpdateServiceAll,
  handleUpdateVisibility,
  readOnly,
  template,
  includePreviousCost,
  disableFormatting,
  templateServiceId,
  workType,
  documentStatus,
  includeCostBreakdown,
  costBreakdown
}: IDocumentServiceLineItem) => {
  const serviceOccurrence = ['APP', 'SERVICE', 'MONTH', 'LABOUR HOUR', 'HOUR', 'DAY', 'TREE\\SHRUB', 'STUMP', 'DEVICE'].sort();
  const { state } = useViewport();
  const { isRole, can } = usePrairieAuth();
  const [lineItem, setLineItem] = useState<IDocumentService>({
    id,
    guid,
    service,
    workDescription,
    previousWorkDescription,
    workCost,
    previousWorkCost,
    people,
    manHours,
    isVisible,
    previousIsVisible,
    sortOrder,
    status,
    templateServiceId,
    workType,
    costBreakdown
  });
  const [formCostBreakdown, setFormCostBreakdown] = useState<Array<IDocumentServiceCostBreakdown>>(costBreakdown || [{ guid: uuidv4(), description: '', cost: '' }]);

  useEffect(() => {

  }, [guid]);

  const handleUpdateLineFromTemplate = (guid: string, service: string, description: string, templateServiceId: string) => {
    const newState: any = { ...lineItem };
    const temp = state.fields.find((t: any) => t.key === service);

    if (temp) {
      newState.service = service;
    }

    newState.workDescription = description;
    setLineItem(newState);
    handleUpdateServiceAll(guid, newState.service, description, templateServiceId);
  }

  const handleUpdateLine = (guid: string, key: string, val: string) => {
    const newState: any = { ...lineItem };
    const temp = state.fields.find((t: any) => t.key === val);

    if (key === 'service' && temp && temp.description) {
      (newState as any).workDescription = temp.description || '';
      handleUpdateService(guid, 'workDescription', temp.description || '');
    }

    if (key === 'service_from_template' && temp) {
      key = 'service';
    }

    newState[key] = val;
    setLineItem(newState);
    handleUpdateService(guid, key, val);
  }

  const calculateEstimatedCost = () => {
    setTimeout(() => {
      const costs = [...(document as any).querySelectorAll('input[name="workCost"]')]
        .filter((el) => el.parentNode.parentNode.className.indexOf('mutted') === -1)
        .map((el: any) => el.value.replace('$', ''))
        .map((num: any) => isNaN(num) ? undefined : +num)
        .filter((num: any) => num);
      const estCost = costs.reduce((prev: any, next: any) => { prev = prev + next; return prev; }, 0);

      if ((document as any).getElementById('estimated-cost')) {
        (document as any).getElementById('estimated-cost').value = estCost.toString();
      }
    });
  }

  const getStatusIcon = (status: string | undefined): any => {
    switch (status) {
      case 'confirmed':
        return (
          <Fragment>
            <Whisper placement="top" trigger="hover" speaker={<Tooltip>Confirmed</Tooltip>}>
              <LegacyCheckIcon style={{ color: '#5cb85c' }} />
            </Whisper>
          </Fragment>
        );
      case 'declined':
        return (
          <Fragment>
            <Whisper placement="top" trigger="hover" speaker={<Tooltip>Declined</Tooltip>}>
              <LegacyCloseIcon style={{ color: '#d9534f' }} />
            </Whisper>
          </Fragment>
        );
      default:
        return (
          <Fragment>
            <Whisper placement="top" trigger="hover" speaker={<Tooltip>Status</Tooltip>}>
              <LegacyEllipsisVIcon style={{ color: '#1860a8' }} />
            </Whisper>
          </Fragment>
        );
    }
  }

  const getServicesDropdown = (template: any) => {
    const services: any = [];

    (template?.services?.groups || []).filter((s: any) => s.description.length > 0).forEach((s: any) => {
      services.push({
        ...s,
        value: s.guid,
        label: s.description
      });

      template.services.services.filter((sv: any) => sv.group_guid === s.guid).forEach((sv: any) => {
        services.push({
          ...sv,
          value: sv.guid,
          label: sv.description
        });
      });
    });

    return services;
  }

  const updateSuffix = (lineItemGuid: string, value: string, suffix: string) => {
    const match = value.replace(/\\/g, '').match(/\$([0-9.,-]+)?\/[a-zA-Z0-9\s]+$/);
    if (match && match.length > 1) {
      if (((match[1] as any) || '').trim().length === 0) {
        handleUpdateLine(lineItem.guid, 'workCost', '$/' + suffix);
      } else {
        handleUpdateLine(lineItem.guid, 'workCost', moneyFormatter.format(((match[1] as any) || '').replace(/[^0-9.-]/g, '')) + '/' + suffix);
      }
    }
  }

  const handleUpdateCostBreakdown = async (val: string, el: any, guid?: any) => {
    const key = typeof (el) === 'string' ? el : el.target.name;
    guid = typeof (guid) === 'undefined' ? el.target.dataset.guid : guid;

    const newFormCostBreakdown = formCostBreakdown.map((c: any) => {
      if (c.guid === guid && key) {
        c[key] = val;
      }
      return c;
    });
    setFormCostBreakdown(newFormCostBreakdown);
    handleUpdateService(lineItem.guid, 'costBreakdown', newFormCostBreakdown);
  }

  return (
    <Row key={lineItem.guid} className={`mb-5 ${lineItem.isVisible ? '' : 'mutted'}`}>
      <Col md={5}>
        <div style={{ display: 'inline-block', width: '9%' }}>
          {readOnly
            ? <span style={{ color: '#ccc' }}> ::</span>
            : <DragHandle />
          }
          &nbsp;
        </div>
        <div style={{ display: 'inline-block', width: '12%' }} className="template-service-lookup">
          <SelectPicker
            disabled={readOnly}
            value={lineItem.templateServiceId}
            container={() => container?.current}
            menuClassName='template-service-lookup'
            appearance='subtle'
            cleanable={false}
            renderMenuItem={(label: any, item: any) => {
              if (item?.group_guid) {
                return <span>{label}</span>
              }

              return <strong>{label}</strong>;
            }}
            renderValue={() => <IconButton icon={<LegacySearchIcon style={{ color: COLOR.LINK }} />} placement="right" appearance="subtle" size="sm" />}
            placeholder={<IconButton icon={<LegacySearchIcon />} placement="right" appearance="subtle" size="sm" />}
            data={getServicesDropdown(template)}
            onChange={(value: any) => {
              let item = template.services.groups.find((g: any) => g.guid === value);
              if (!item) {
                item = template.services.services.find((g: any) => g.guid === value);
              }

              handleUpdateLineFromTemplate(lineItem.guid, item.op_code, item.description, value);
            }}
          />
        </div>

        <div style={{ display: 'inline-block', width: '79%' }}>
          <SelectPicker
            container={() => container?.current}
            // rsuite5
            // toggleComponentClass='span'
            disabled={readOnly}
            name="workType"
            groupBy='group'
            placement='autoVerticalStart'
            value={lineItem.service}
            block
            data={
              [
                { group: 'Display', value: 'header', label: 'Header' },
                { group: 'Display', value: 'footer', label: 'Footer' },
                { group: 'Display', value: 'hidden', label: 'Hidden' }
              ]
                .concat(orderBy(filterFieldsByParentKey(state.fields, 'work_types')
                  .map((o: any) => ({ group: 'Services', value: o.key, label: o.title }))
                  , 'label', 'asc'))
            }
            onChange={(val: any) => {
              handleUpdateLine(lineItem.guid, 'service', val);
            }}
          />
        </div>
      </Col>

      <Col md={includePreviousCost ? 9 : 12}>
        <Input
          // disabled={readOnly || (!can('document:update-description') && IS_BUGABOO && isRole(ROLE.FRANCHISE, ROLE.CREW) && !workType.includes('construction'))}
          disabled={readOnly}
          as={TextareaAutosize}
          value={lineItem.workDescription}
          onChange={(val) => handleUpdateLine(lineItem.guid, 'workDescription', val)}
        />
        {(
          (lineItem.previousWorkDescription && lineItem.previousWorkDescription !== lineItem.workDescription) ||
          (lineItem.previousIsVisible !== lineItem.isVisible)
        ) &&
          <div style={{ backgroundColor: '#fafafa', padding: '5px 11px', border: '1px solid #e5e5ea', borderTop: 'none' }}>
            <span style={{ color: COLOR.DANGER, textDecoration: isVisible ? "none" : "line-through" }}>{lineItem.previousWorkDescription}</span>
            <span>&nbsp;&nbsp;</span>
            <Whisper placement='bottom' speaker={<Tooltip>Revert Change</Tooltip>}>
              <a href="/" onClick={(e: any) => {
                e.preventDefault();
                handleUpdateLine(lineItem.guid, 'workDescription', lineItem.previousWorkDescription || '');
                handleUpdateVisibility(lineItem.guid, lineItem.previousIsVisible);
              }}><LegacyRefreshIcon /></a>
            </Whisper>
          </div>
        }

        {includeCostBreakdown && <div className='pt-4 mt-4 mb-12 divider-top'>
          {formCostBreakdown.map((row: any, index: number) => <div key={row.guid} className={index === 0 ? '' : 'mt-4'}>
            <Input size="sm" defaultValue={row.description} name="description" onChange={handleUpdateCostBreakdown} data-guid={row.guid} tabIndex={index} />
          </div>)}
        </div>}
      </Col>
      {includePreviousCost &&
        <Col md={3}>
          <Input
            disabled={readOnly || (IS_BUGABOO && isRole(ROLE.FRANCHISE, ROLE.CREW))}
            name='previousWorkCost'
            value={lineItem.previousWorkCost}
            onChange={(val) => handleUpdateLine(lineItem.guid, 'previousWorkCost', val)}
          />
        </Col>
      }

      <Col md={4}>
        {(!disableFormatting && /\$([0-9.,-]+)?\/[a-zA-Z0-9\s]+$/i.test((lineItem.workCost || '').replace(/\\/g, ''))
          && new RegExp(serviceOccurrence.join('|'), 'i').test((lineItem.workCost || '').replace(/\\/g, '')))
          ? <InputGroup>
            <InputGroup.Addon>$</InputGroup.Addon>
            <Input
              disabled={readOnly && documentStatus[0]?.status !== 'up_for_renewal_0qn9cjmu2p'}
              name='workCost'
              value={lineItem.workCost.match(/\$([0-9,-.]+)\/.*/) === null ? '' : (lineItem.workCost.match(/\$([0-9,-.]+)\/.*/) as any)[1]}
              onChange={(val: any, e: any) => {
                const match = lineItem.workCost.match(/\$([0-9.,-]+)?\/([a-zA-Z0-9\s]+)$/);

                if (match && match.length > 1) {
                  handleUpdateLine(lineItem.guid, 'workCost', '$' + val + '/' + match[2]);
                }
              }}
              onBlur={(e: any) => {
                const match = lineItem.workCost.match(/\$([0-9.,-]+)?\/([a-zA-Z0-9\s]+)$/);

                if (match && match.length > 1) {
                  if (e.target.value.trim().length === 0) {
                    handleUpdateLine(lineItem.guid, 'workCost', '$/' + match[2]);
                  } else {
                    handleUpdateLine(lineItem.guid, 'workCost', moneyFormatter.format(+e.target.value.replace(/[^0-9.-]/g, '')) + '/' + match[2]);
                  }
                }
              }}
              onKeyUp={calculateEstimatedCost}
            />
            <InputGroup.Addon style={{ padding: 0 }}>
              <Dropdown title={lineItem.workCost.replace(/[0-9$,./]/g, '').substring(0, 7)}>
                {serviceOccurrence.map((s: any) =>
                  <Dropdown.Item onSelect={() => updateSuffix(lineItem.guid, lineItem.workCost, s)}>{s}</Dropdown.Item>
                )}
                {!serviceOccurrence.includes(lineItem.workCost.replace(/[0-9$,./]/g, '')) &&
                  <Dropdown.Item onSelect={() => updateSuffix(lineItem.guid, lineItem.workCost, lineItem.workCost.replace(/[0-9$,./]/g, ''))}>
                    {lineItem.workCost.replace(/[0-9$,./]/g, '')}
                  </Dropdown.Item>
                }
              </Dropdown>
            </InputGroup.Addon>
          </InputGroup>
          : <Input
            disabled={readOnly && documentStatus[0]?.status !== 'up_for_renewal_0qn9cjmu2p'}
            name='workCost'
            value={lineItem.workCost}
            onChange={(val) => handleUpdateLine(lineItem.guid, 'workCost', val)}
            onKeyUp={calculateEstimatedCost}
          />
        }

        {includeCostBreakdown && <div className='pt-4 mt-4 mb-12 divider-top'>
          {formCostBreakdown.map((row: any, index: number) => <div key={row.guid} className={index === 0 ? '' : 'mt-4'}>
            <Input size="sm" defaultValue={row.cost} name="cost" onChange={handleUpdateCostBreakdown} data-guid={row.guid} tabIndex={index} />
          </div>)}
        </div>}
      </Col>
      <Col md={3}>
        <Whisper placement="top" trigger="hover" speaker={<Tooltip>Add</Tooltip>}>
          <IconButton
            disabled={readOnly}
            size="xs"
            appearance="link"
            onClick={() => handleAddService(lineItem.guid)} icon={<LegacyPlusIcon />}
          />
        </Whisper>
        <Whisper placement="top" trigger="hover" speaker={<Tooltip>Remove</Tooltip>}>
          <IconButton
            disabled={readOnly}
            size="xs"
            appearance="link"
            onClick={() => handleRemoveService(lineItem.guid)} icon={<LegacyTrashIcon />}
          />
        </Whisper>
        {lineItem.isVisible
          ? <Whisper placement="top" trigger="hover" speaker={<Tooltip>Hide</Tooltip>}>
            <IconButton
              disabled={readOnly}
              size="xs"
              appearance="link"
              onClick={() => {
                handleUpdateVisibility(lineItem.guid, false);
                calculateEstimatedCost();
              }}
              icon={<LegacyEyeIcon />}
            />
          </Whisper>
          : <Whisper placement="top" trigger="hover" speaker={<Tooltip>Show</Tooltip>}>
            <IconButton
              disabled={readOnly}
              size="xs"
              appearance="link"
              onClick={() => {
                handleUpdateVisibility(lineItem.guid, true);
                calculateEstimatedCost();
              }}
              icon={<LegacyEyeSlashIcon />}
            />
          </Whisper>
        }

        {!['header', 'footer'].includes(lineItem.service) &&
          <Dropdown appearance='subtle' disabled={readOnly} title={getStatusIcon(lineItem.status)}>
            <Dropdown.Item onClick={() => handleUpdateLine(lineItem.guid, 'status', '')}>
              <IconButton
                size="xs"
                appearance="link"
                icon={<LegacyUndoIcon style={{ color: '#1860a8' }} />}
              />
            </Dropdown.Item>
            <Dropdown.Item onClick={() => handleUpdateLine(lineItem.guid, 'status', 'confirmed')}>
              <IconButton
                size="xs"
                appearance="link"
                icon={<LegacyCheckIcon style={{ color: '#5cb85c' }} />}
              />
            </Dropdown.Item>
            <Dropdown.Item onClick={() => handleUpdateLine(lineItem.guid, 'status', 'declined')}>
              <IconButton
                size="xs"
                appearance="link"
                icon={<LegacyCloseIcon style={{ color: '#d9534f' }} />}
              />
            </Dropdown.Item>
          </Dropdown>
        }

        {includeCostBreakdown && <div className='pt-4 mt-4 mb-12 divider-top' style={{ marginTop: '14px' }}>
          {formCostBreakdown.map((row: any, index: number) => <div key={row.guid}>
            {index === 0
              ? <IconButton appearance="link" icon={<MdAdd />} onClick={() => setFormCostBreakdown([...formCostBreakdown, { guid: uuidv4(), description: '', cost: '' }])} />
              : <IconButton appearance="link" icon={<MdDelete />} style={{ color: COLOR.DANGER }} onClick={() => setFormCostBreakdown(formCostBreakdown.filter((c: any) => c.guid !== row.guid))} />
            }
          </div>)}
        </div>}
      </Col>
    </Row>
  );
}

export default DocumentServiceLineItem;
export type { IDocumentService };
