import { useState, useEffect, Fragment, useContext } from 'react';
import {
  Drawer,
  Button,
  Dropdown,
  ButtonToolbar,
  toaster,
  Message,
  ButtonGroup,
  IconButton,
} from 'rsuite';
import { useApolloClient, gql } from '@apollo/client';
import { DRAWER, getEnv, ROLE } from 'lib/env';
import { isMobileOnly } from 'react-device-detect';
import { useHistory } from 'react-router-dom';
import WorkbookCustomer from 'containers/workbook/WorkbookCustomer';
import { queryWorkOrder } from 'gql/workOrders';
import { useApi } from 'lib';
import { ApplicationContext } from 'shared';
import { GoogleMap, LoadScriptNext, Marker } from '@react-google-maps/api';
import { CustomerForm as CustomerFormContainer } from 'containers/customer/CustomerForm';
import { useViewport } from 'shared/ViewportProvider';
import { usePrairieAuth } from 'contexts/AuthPrairieProvider';
import { MdOutlineOutbound } from 'react-icons/md';
import Icon from '@rsuite/icons/lib/Icon';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

const GET_SEARCH = gql`
  query search($limit: Int, $where: SearchResultsWhere) {
    search(filter: {
      limit: $limit
      where: $where
    }) {
      edges {
        node {

          id
          guid
          customerName
          businessName
          customerAddress
          postalCode
          phone
          alternatePhone
          customerId
          entryType
          applicationGroup
          paymentStatus
          workOrderMonth
          startDate
          endDate
          customerEmail
          resourceGroup
          customerEmail
          documentId
          customerComments
        }
      }
    }
  }
`;

const CustomerView = () => {
  const { drawerData, showDrawer, showConfirmation } = useContext(ApplicationContext);
  const history = useHistory();
  const api = useApi();
  const client = useApolloClient();
  const { isRole } = usePrairieAuth();
  const { state, dispatch } = useViewport();
  const [loading, setLoading] = useState(true);
  const [record, setRecord] = useState<any | undefined>(undefined);
  const [workOrder, setWorkOrder] = useState<any>(undefined);
  const stripe = loadStripe(state.companies[0].integrations?.stripePublishableKey || 'pk_na');

  useEffect(() => {
    if (drawerData?.workOrderGuid && drawerData?.drawer === DRAWER.CUSTOMER_VIEW) {
      (async function getWorkOrders() {
        const result = await client.query({ query: queryWorkOrder, variables: { guid: drawerData?.workOrderGuid } });
        setWorkOrder(result.data.workOrder);
      })();
    }
  }, [drawerData]);

  useEffect(() => {
    if (drawerData?.customerId && drawerData?.drawer === DRAWER.CUSTOMER_VIEW) {
      (async function getWorkOrders() {
        try {
          setLoading(true);
          const data = await client.query({
            query: GET_SEARCH,
            variables: {
              limit: 20,
              where: {
                OR: [
                  {
                    customerId: { is: drawerData?.customerId }
                  }
                ]
              }
            },
            fetchPolicy: 'no-cache'
          });

          const workOrder = data.data.search.edges.node.find((n: any) => n.resourceGroup === 'work_order' && n.guid === drawerData?.workOrderGuid);
          const document = workOrder
            ? data.data.search.edges.node.find((n: any) => n.resourceGroup === 'document' && +n.id === workOrder.documentId)
            : null;

          setRecord({
            customer: data.data.search.edges.node.find((n: any) => n.resourceGroup === 'customer'),
            workOrder: workOrder,
            document: document
          } as any);
        } catch (err) {
          console.log(err);
        }

        setLoading(false);
      })();
    }
  }, [drawerData]);

  return (
    <Drawer
      open
      size={isMobileOnly ? 'full' : 'md'}
      onClose={() => {
        showDrawer(undefined);
      }}
    >
      <Drawer.Header>
        <Drawer.Title>View Customer</Drawer.Title>
      </Drawer.Header>
      <Drawer.Body>
        {record &&
          <Elements stripe={stripe}>
            <WorkbookCustomer customerId={drawerData?.customerId} />
          </Elements>
        }
      </Drawer.Body>
      <Drawer.Footer>
        <ButtonToolbar>
          {/* <Button onClick={() => onUpdateDrawerCallback({ test: 'callback' })}>Test Callback</Button> */}

          <ButtonGroup>
            <IconButton
              placement='right'
              size={'sm'}
              onClick={() => {
                history.push(`/app/${getEnv()}/workbook/explorer/${drawerData?.customerId}/profile`);
                showDrawer(undefined);
              }}
              icon={<Icon as={MdOutlineOutbound} onClick={(e: any) => {
                e.stopPropagation();
                e.preventDefault();
                window.open(`/app/${getEnv()}/workbook/explorer/${drawerData?.customerId}/profile`);
                showDrawer(undefined);
              }} />}
              appearance="primary">View Profile</IconButton>
          </ButtonGroup>

          <Button size="sm" onClick={() => {
            showDrawer(DRAWER.CUSTOMER_FORM, { action: 'edit', customerGuid: drawerData?.customerGuid, customerId: drawerData?.customerId });
          }}>Edit</Button>

          {(workOrder && isRole(ROLE.ADMIN, ROLE.MANAGER, ROLE.FRANCHISE)) &&
            <Dropdown title="More..." placement="topStart" size="sm">
              {(workOrder && workOrder.documentGuid)
                && <Dropdown.Item onSelect={() => window.location.href = `${api.baseUrl}/documents/download/${workOrder.documentGuid}`}>Download Document</Dropdown.Item>
              }
              {(workOrder && ['construction', 'roofing__59634106__1710128534'].includes(workOrder.applicationGroup))
                && <Dropdown.Item onSelect={() => {
                  history.push(`/app/${getEnv()}/worklog/edit/${workOrder.guid}`);
                  showDrawer(undefined);
                }}>Work Log</Dropdown.Item>
              }
              {(workOrder) &&
                <Dropdown.Item onSelect={() => {
                  showDrawer(DRAWER.WORK_ORDER_FORM, { workOrderGuid: workOrder.parentGuid || workOrder.guid, action: 'edit' });
                }}>Work Order</Dropdown.Item>
              }
              {(drawerData.jobGuid && isRole(ROLE.ADMIN)) &&
                <Dropdown.Item onSelect={() => {
                  showDrawer(undefined);
                  showConfirmation(
                    <p>Are you sure you want to delete the job?</p>,
                    'Delete Job',
                    async () => {
                      const response = await client.mutate({
                        mutation: gql`mutation deleteJob($guid: ID!) { deleteJob(guid: $guid) { success, code, message }}`,
                        variables: { guid: drawerData.jobGuid }
                      });
                      toaster.push(
                        <Message type="success" showIcon closable>{response.data.deleteJob.message}</Message>
                      );
                      dispatch({ type: 'SET_MODIFIED_AT' });
                    }
                  );
                }}>Delete Job</Dropdown.Item>
              }
              {workOrder?.applicationGroup === 'irrigation__d9d78aaa__1680553175' &&
                <Dropdown.Item onSelect={() => {
                  showDrawer(DRAWER.IRRIGATION_SYSTEM_VIEW, { customerId: drawerData?.customerId });
                }}>Irrigation System</Dropdown.Item>
              }
            </Dropdown>
          }
          <Button size="sm" onClick={() => {
            showDrawer(undefined);
          }} appearance="subtle">Close</Button>
        </ButtonToolbar>
      </Drawer.Footer>
    </Drawer>
  );
}

const CustomerMapView = () => {
  const { drawerData, showDrawer } = useContext(ApplicationContext);

  return (
    <Drawer
      open
      size={'md'}
      full={isMobileOnly}
      onClose={() => {
        showDrawer(undefined);
      }}
    >
      <Drawer.Header>
        <Drawer.Title>Location</Drawer.Title>
      </Drawer.Header>
      <Drawer.Body>
        <LoadScriptNext id="script-loader" googleMapsApiKey={(import.meta as any).env?.VITE_APP_GOOGLE_MAPS_API_KEY || ''}>
          <GoogleMap
            id='job-map'
            mapContainerStyle={{
              height: '65vh',
              width: '100%'
            }}
            zoom={17}
            center={{ lat: drawerData.customer.lat, lng: drawerData.customer.lng }}
            options={{
              controlSize: 26,
              mapTypeId: 'roadmap',
              mapTypeControlOptions: {
                style: 1,
                mapTypeIds: [
                  'roadmap',
                  'hybrid'
                ]
              }
            }}
          >
            <Marker position={{ lat: drawerData.customer.lat, lng: drawerData.customer.lng }} />
          </GoogleMap>
        </LoadScriptNext>
      </Drawer.Body>
      <Drawer.Footer>
        <ButtonToolbar>
          <Button onClick={() => {
            showDrawer(undefined);
          }} appearance="subtle">Close</Button>
        </ButtonToolbar>
      </Drawer.Footer>
    </Drawer>
  );
}

const CustomerForm = () => {
  const {
    drawerData,
    showDrawer,
    onUpdateDrawerCallback
  } = useContext(ApplicationContext);

  return <CustomerFormContainer
    drawer
    show
    customerId={drawerData?.customerId}
    onHide={() => showDrawer(undefined)}
    onUpdate={onUpdateDrawerCallback}
    guid={drawerData?.customerGuid}
    action={drawerData?.action}
  />
}

export {
  CustomerForm,
  CustomerView,
  CustomerMapView
}
