import { useContext } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import listPlugin from '@fullcalendar/list';
import { Button, Loader, Tooltip, Whisper } from 'rsuite';
import LegacyEnvelopeOIcon from "@rsuite/icons/legacy/EnvelopeO";
import startCase from 'lodash/startCase';
import interactionPlugin from '@fullcalendar/interaction';
import { DRAWER, FORMAT, getEnv, getFieldPrintable, ROLE } from 'lib/env';
import { CONFIG_COLOR_SCHEME, CONFIG_TIME_PADDING } from 'tenant/config';
import { format, addDays, addMinutes, subMinutes, differenceInDays } from 'date-fns';
import { parseISO, toTimeZone } from 'lib/date';
import { IS_APP, IS_GREYROCK, IS_KALADI_KITCHENS, moneyFormatter } from 'lib/tenant';
import { isMobileOnly } from 'react-device-detect';
import { useFilteredParams } from 'shared/FilterProvider';
import { useViewport } from 'shared/ViewportProvider';
import { ApplicationContext } from 'shared';
import { usePrairieAuth } from 'contexts/AuthPrairieProvider';
import { EventInput } from '@fullcalendar/core';

interface IViewCalendar {
  events: any[],
  loading: boolean,
  showColorCodes: boolean,
  visibleRange: { start: Date, end: Date },
  handleJobReschedule: (guid: string, date: Date | Date[]) => void,
  onUpdate: () => void,
  handleClockInOutShow: (mode: string, job: any) => void
}

const ViewCalendar = ({
  events,
  loading,
  visibleRange,
  showColorCodes,
  handleJobReschedule,
  onUpdate,
  handleClockInOutShow
}: IViewCalendar) => {
  const { showDrawer, showToaster } = useContext(ApplicationContext);
  const { params } = useFilteredParams('jobCalendar');
  const { state } = useViewport();
  const colors = state.fields.filter((f: any) => f.color).reduce((p: any, n: any) => {
    if ((n.key || '').trim().length > 0) {
      p[n.key] = n.color;
    }
    return p;
  }, {});
  const { isRole } = usePrairieAuth();

  const getDefaultView = () => {
    const start = visibleRange.start;
    const end = visibleRange.end;
    const diff = differenceInDays(end, start);

    if (isMobileOnly && (!params.calendarView || params.calendarView === 'auto')) {
      return diff > 7 ? 'listMonth' : 'listWeek';
    } else {
      if (params.calendarView === 'week') {
        return 'dayGridWeek';
      } else if (params.calendarView === 'month') {
        return 'dayGridMonth';
      } else if (params.calendarView === 'list') {
        return diff > 7 ? 'listMonth' : 'listWeek';
      } else {
        return diff > 7 ? 'dayGridMonth' : 'dayGridWeek';
      }
    }
  }

  const getReadableDuration = (eventInput: EventInput) => {
    const start = parseISO(eventInput.startDate);
    const end = parseISO(eventInput.endDate || eventInput.startDate);

    if (format(start, FORMAT.DATE_TIME) === format(end, FORMAT.DATE_TIME)) {
      return format(start, FORMAT.DAY_MONTH_DATE);
    } else if (!IS_KALADI_KITCHENS) {
      return format(start, FORMAT.DAY_MONTH_TIME);
    }

    if (IS_KALADI_KITCHENS) {
      return 'Start Time: ' + format(start, FORMAT.DATE_TIME) + '<br />'
        + 'End Time: ' + format(addMinutes(end, CONFIG_TIME_PADDING.end), FORMAT.DATE_TIME);
    }

    return format(start, FORMAT.DATE_TIME) + ' to ' + format(addMinutes(end, CONFIG_TIME_PADDING.end), FORMAT.DATE_TIME);
  }

  const getColor = (event: any) => {
    if (CONFIG_COLOR_SCHEME === 'work_type') {
      const field = state.fields.find((f: any) => f.key === (event.workType || event?.workOrderWorkType));

      if (event.custom && event.custom.KitchenNumber && event.status !== 'canceled') {
        return event.custom.KitchenNumber;
      } else if (field && field.custom) {
        if (event.status === 'canceled') {
          return '#f0ad4e';
        }
        return field.custom.Color;
      }
    } else {
      switch (event.status) {
        case 'not_completed':
        case 'scheduled':
        case null:
          return event.paymentCollected > 0 ? '#3788d8' : '#d9534f';
        case 'completed':
          return '#5cb85c';
        case 'in_review':
          return '#4d646c';
        case 'canceled':
          return '#f0ad4e';
      }
    }

    return '#3788d8';
  }

  const getTitle = (event: any) => {
    let startTime = format(toTimeZone(parseISO(event.startDate), state.timeZone), "H:mm");
    let endTime = format(toTimeZone(parseISO(event.endDate), state.timeZone), "H:mm");

    if (IS_KALADI_KITCHENS && event.status !== 'daily_estimate') {
      startTime = format(subMinutes(toTimeZone(parseISO(event.startDate), state.timeZone), CONFIG_TIME_PADDING.start), "H:mm");
      endTime = format(addMinutes(toTimeZone(parseISO(event.endDate), state.timeZone), CONFIG_TIME_PADDING.end), "H:mm");

      let clockedInIcon = event?.attachmentCount > 0 && event?.props?.timeOnSiteNotes 
        ? //rs-icon-camera 
        '<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 22 22" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0V0z"></path><path d="M20 4h-3.17L15 2H9L7.17 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 14H4V6h4.05l1.83-2h4.24l1.83 2H20v12zM12 7c-2.76 0-5 2.24-5 5s2.24 5 5 5 5-2.24 5-5-2.24-5-5-5zm0 8c-1.65 0-3-1.35-3-3s1.35-3 3-3 3 1.35 3 3-1.35 3-3 3z"></path></svg>' 
        : //rs-icon-check-circle
        '<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 22 22" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0V0z" opacity=".87"></path><path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm3.59-13L12 10.59 8.41 7 7 8.41 10.59 12 7 15.59 8.41 17 12 13.41 15.59 17 17 15.59 13.41 12 17 8.41z"></path></svg>'
        
      if (event?.props?.timeOnSiteNotes) {
        // rs-icon-close-circle
        clockedInIcon = '<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 22 22" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0V0zm0 0h24v24H0V0z"></path><path d="M16.59 7.58L10 14.17l-3.59-3.58L5 12l5 5 8-8zM12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"></path></svg>';
      }

      if (event?.props?.timeOnSiteNotes?.kitchenStationClean === false || event?.props?.timeOnSiteNotes?.noMaintenanceIssues) {
        // exclamation icon
        clockedInIcon = '<svg fill="#ffffff" width="1em" height="1em" viewBox="-25 -25 512 512" id="_16_Exclamation" data-name="16 Exclamation" xmlns="http://www.w3.org/2000/svg"><path id="Path_21" data-name="Path 21" d="M256,512C114.625,512,0,397.391,0,256,0,114.625,114.625,0,256,0S512,114.625,512,256C512,397.391,397.375,512,256,512Zm0-448C149.969,64,64,149.969,64,256s85.969,192,192,192,192-85.969,192-192S362.031,64,256,64ZM224,320h64v64H224Zm0-192h64V288H224Z" fill-rule="evenodd"/></svg>';
      }

      let clockedOutIcon = event?.attachmentCount > 0 && event?.props?.timeFromSiteNotes 
        ? //rs-icon-camera 
        '<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 22 22" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0V0z"></path><path d="M20 4h-3.17L15 2H9L7.17 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 14H4V6h4.05l1.83-2h4.24l1.83 2H20v12zM12 7c-2.76 0-5 2.24-5 5s2.24 5 5 5 5-2.24 5-5-2.24-5-5-5zm0 8c-1.65 0-3-1.35-3-3s1.35-3 3-3 3 1.35 3 3-1.35 3-3 3z"></path></svg>' 
        : //rs-icon-check-circle
        '<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 22 22" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0V0z" opacity=".87"></path><path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm3.59-13L12 10.59 8.41 7 7 8.41 10.59 12 7 15.59 8.41 17 12 13.41 15.59 17 17 15.59 13.41 12 17 8.41z"></path></svg>'
        
      if (event?.props?.timeFromSiteNotes) {
        // rs-icon-close-circle
        clockedOutIcon = '<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 22 22" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0V0zm0 0h24v24H0V0z"></path><path d="M16.59 7.58L10 14.17l-3.59-3.58L5 12l5 5 8-8zM12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"></path></svg>';
      }

      startTime = `<span> ${clockedInIcon} ${clockedOutIcon} ${startTime}</span>`;
    }

    let title = (startTime === endTime ? '' : `${startTime} - ${endTime} `) +
      (event.customer
        ? event.customer.displayName
        : event.customerName + (event.status === 'daily_estimate' ? event.cost : '')
      );

    if (event.notifiedAt) {
      title = '<span><svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0z"></path><path d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 14H4V8l8 5 8-5v10zm-8-7L4 6h16l-8 5z"></path></svg> </span>' + title;
    }

    if (colors[event.applicationGroup] && showColorCodes) {
      title += `<div class="rs-badge rs-badge-independent rs-badge-dot" style="position: absolute !important; right: 5px; top: 5px; background-color: ${colors[event.applicationGroup]};"></div>`;
    }

    return title;
  }

  const getEndDate = (eventInput: any) => {
    // all day events will have same start and end date
    let daysToAdd = 0;

    // add days if start date is not the same as end date and end date falls on midnight
    if (eventInput.endDate) {
      const sameDays = format(parseISO(eventInput.startDate), FORMAT.ISO_DATE) === format(parseISO(eventInput.endDate), FORMAT.ISO_DATE);
      const isMidnight = format(parseISO(eventInput.endDate), 'HH:mm:ss') === '00:00:00';
      daysToAdd = (sameDays && isMidnight) ? 1 : 0;
    }

    if (IS_KALADI_KITCHENS) {
      return addMinutes(parseISO(eventInput.endDate), CONFIG_TIME_PADDING.end);
    }

    return addDays(parseISO(eventInput.endDate), daysToAdd);
  }

  return (
    <div className="mb-24">
      {loading && <div style={{ position: 'absolute', zIndex: 99999, marginTop: '50px', left: '48%' }}><Loader content="Loading..." /></div>}
      <FullCalendar
        key={visibleRange.start.toISOString() + '_' + visibleRange.end.toISOString()}
        initialView={getDefaultView()}
        initialDate={visibleRange.start}
        height={'auto'}
        plugins={[dayGridPlugin, interactionPlugin, listPlugin]}
        headerToolbar={{ left: '', center: '', right: '' }}
        dayCellContent={(data: any) => {
          return (isRole(ROLE.CLIENT, ROLE.VIEWER) || IS_APP)
            ? <div>{data.dayNumberText}</div>
            : <div>
              {data.dayNumberText}<span>&nbsp;&nbsp;&nbsp;</span>
              <Whisper placement="top" trigger="hover" speaker={<Tooltip>Send Notification</Tooltip>}>
                <Button
                  onClick={() => {
                    const notify = events
                      .filter((e: any) => e.guid !== -1)
                      .filter((e: any) => format(toTimeZone(parseISO(e.startDate), state.timeZone), FORMAT.ISO_DATE) === data.date.toISOString().split('T')[0])
                      .filter((e: any) => e.status !== 'canceled');

                    showDrawer(DRAWER.EMAIL_FORM, {
                      resources: notify,
                      type: 'job',
                      group: 'job',
                      applicationEvent: 'email_job_notification_to_client'
                    }, () => onUpdate());
                  }}
                  appearance="link"
                  style={{ padding: 0 }}><LegacyEnvelopeOIcon /></Button>
              </Whisper>
            </div>;
        }}
        events={events}
        displayEventTime={false}
        eventOrder={'order'}
        eventDisplay={'block'}
        selectable
        select={(event: any) => {
          if (!isRole(ROLE.VIEWER)) {
            showDrawer(DRAWER.WORK_ORDER_FORM, { 
              startEndDate: [event.start, event.start],
              workGroup: params?.applications?.[0], 
            }, () => onUpdate());
          }
        }}
        eventDrop={(info: any) => {
          const prevDate = info.oldEvent.start;
          const start = info.event.start;
          const end = info.event.end || info.event.start;

          const newStartDate = new Date(start.getFullYear(), start.getMonth(), start.getDate(), prevDate.getHours(), prevDate.getMinutes(), prevDate.getSeconds(), 0);
          const newEndDate = new Date(end.getFullYear(), end.getMonth(), end.getDate(), prevDate.getHours(), prevDate.getMinutes(), prevDate.getSeconds(), 0);
          handleJobReschedule(info.event.id, [newStartDate, newEndDate]);
        }}
        eventDidMount={(info: any) => {
          if (info.event.extendedProps.status !== 'daily_estimate') {
            info.el.setAttribute('data-tip', info.event.extendedProps.description);
          }

          if (getEnv() === 'dev') {
            info.el.setAttribute('data-job-guid', info.event.extendedProps.jobGuid);
          }
        }}
        eventDataTransform={(eventInput: EventInput) => {
          let className = eventInput.status;

          if (!['canceled', 'completed', 'not_completed'].includes(className)) {
            className = 'not_completed';
          }

          const printableFields = getFieldPrintable(state.fields, 'work_types', (eventInput.workType || eventInput?.workOrderWorkType || '').split(';'), eventInput.otherServices, ', ');

          if (['roofing__59634106__1710128534', 'construction'].includes(eventInput.applicationGroup) && eventInput.paymentCollected > 0 && eventInput.status === 'not_completed') {
            className = '';
          }

          let editable = eventInput.status !== 'daily_estimate' && !IS_KALADI_KITCHENS;

          if (IS_GREYROCK && !isRole(ROLE.ADMIN, ROLE.MANAGER)) {
            editable = false;
          }

          return {
            id: eventInput.guid,
            title: getTitle(eventInput),
            start: toTimeZone(parseISO(eventInput.startDate), state.timeZone),
            end: getEndDate(eventInput),
            className: className,
            editable: editable,
            color: getColor(eventInput),
            eventTextColor: isMobileOnly ? '#333333' : '#fff',
            attachmentCount: eventInput.attachmentCount,
            extendedProps: {
              job: eventInput,
              jobGuid: eventInput.guid,
              workOrderGuid: eventInput.workOrderGuid,
              status: eventInput.status,
              applicationGroup: eventInput.applicationGroup,
              customerId: eventInput.customerId,
              notifiedAt: eventInput.notifiedAt,
              customerLink: '/customer/view/' + eventInput.customerId + '/plain/maintenance/' + eventInput.workOrderId + '/' + eventInput.jobId,
              description: ''
                + (IS_KALADI_KITCHENS ? '' : `<p><strong>${startCase(eventInput.status)}</strong></p>`)
                + (eventInput.customer ? eventInput.customer.displayName : eventInput.customerName)
                + '<br />' + startCase(eventInput.applicationGroup)
                + '<span> </span>'
                + (printableFields.length > 0 ? '<strong> - ' + printableFields + '</strong>' : '')
                + '<br />' + getReadableDuration(eventInput)
                + (eventInput.notifiedAt ? '<br />Notified At: ' + format(toTimeZone(parseISO(eventInput.notifiedAt), state.timeZone), FORMAT.DAY_MONTH_TIME) : '')
                + (IS_KALADI_KITCHENS ? '' : ('<br />Crew: ' + eventInput.operatorName))
                + (eventInput.workerOperatorName ? '<br />Worker: ' + eventInput.workerOperatorName : '')
                + (eventInput.displayAddress ? IS_KALADI_KITCHENS ? '' : '<br />Address: ' + eventInput.displayAddress : '')
                + (eventInput.timeInstructions ? '<br />Time Instructions: ' + eventInput.timeInstructions : '')
                + ((eventInput.props && eventInput.paymentCollected) ? '<br />Revenue: ' + moneyFormatter.format(eventInput.paymentCollected) : '')
            }
          }
        }}
        eventClick={(info) => {
          if (info.event.extendedProps.job.customerName.toLowerCase() === 'booked') {
            return false;
          }
          
          if (info.event.extendedProps.status !== 'daily_estimate') {
            if (IS_KALADI_KITCHENS) {
              handleClockInOutShow('view', info.event.extendedProps.job);
            } else {
              showDrawer(DRAWER.CUSTOMER_VIEW, {
                customerId: info.event.extendedProps.customerId,
                workOrderGuid: info.event.extendedProps.workOrderGuid,
                jobGuid: info.event.extendedProps.jobGuid
              });
            }
          }
        }}
        eventContent={(arg: any) => {
          return { html: getTitle(arg.event.extendedProps.job) };
        }}
      />
    </div>
  );
}

export default ViewCalendar;