import { ROLE } from 'lib/env';
import { getDefaultDate, toLocalTimeZone } from 'lib/date';
import { addMonths, endOfMonth, startOfMonth, subHours } from 'date-fns';

const getFilter = (id: string, filter: any, profile?: any, dateColumn?: any) => {
  switch (id) {
    case 'jobs':
    case 'jobsondemand':
      return getJobsFilter(id, filter, profile);
    case 'employeesPayroll':
      return getEmployeesPayrollFilter(id, filter, profile);
    case 'workOrdersCompare':
      return getWorkOrdersCompareFilter(filter, false, profile, dateColumn);
    case 'workOrders':
      return getWorkOrdersFilter(filter, false, profile);
    case 'workOrdersCustomer':
      return getWorkOrdersFilter(filter, true, profile);
    case 'documents':
    case 'documentsCustomer':
      return getDocumentsFilter(filter, profile);
    case 'invoices':
      return getInvoicesFilter(filter, false, profile);
    case 'invoicesCustomer':
      return getInvoicesFilter(filter, true, profile);
    case 'email-activities':
      return getEmailActivitiesFilter(filter, profile);
    case 'pnls':
      return getPnlsFilter(filter, profile);
    case 'users':
      return getUsersFilter(filter);
    case 'fields':
      return getFieldsFilter(filter);
    case 'notifications':
      return getNotificationsFilter(filter, profile);
    case 'customers':
      return getCustomersFilter(filter, profile);
    case 'employees':
      return getEmployeesFilter(filter, profile);
    case 'siteReviews':
      return getSiteReviewsFilter(filter, profile);
  }
}

const getRangeAtTimeZone = (range: any, timeZone: string) => {
  // return [
  //   toLocalTimeZone(new Date(new Date(+range[0]).setHours(0, 0, 0, 0)), timeZone),
  //   toLocalTimeZone(new Date(new Date(+range[1]).setHours(23, 59, 59, 59)), timeZone)
  // ];

  return [
    toLocalTimeZone(new Date(new Date(+range[0]).setHours(0, 0, 0, 0)), timeZone),
    toLocalTimeZone(new Date(new Date(+range[1]).setHours(23, 59, 59, 59)), timeZone)
  ];

  // return range;
}

const getJobsFilter = (id: any, filter: any, profile: any) => {
  const range = getRangeAtTimeZone(filter.range, profile.company.timezone);

  let whereFilter: any = [];

  if ((filter?.jobStatus || []).includes('canceled')) {
    whereFilter.push({
      userId: { not: null },
      workOrderStatus: { not_in: ['inactive'] }
    });
  } else {
    whereFilter.push({
      userId: { not: null },
      workOrderStatus: { not_in: ['inactive', 'canceled'] }
    });
  }

  if (filter.range.length > 0 && filter.rangeColumn !== 'startEndInclusive') {
    if (id === 'jobsondemand') {
      if (filter.activeRange.length > 0) {
        const activeRange = getRangeAtTimeZone(filter.activeRange, profile.company.timezone);
        whereFilter.push({ [filter.rangeColumn ? filter.rangeColumn : 'endDate']: { gte: activeRange[0] } });
        whereFilter.push({ [filter.rangeColumn ? filter.rangeColumn : 'startDate']: { lte: activeRange[1] } });
      }
    } else if (filter.viewPortRecentlyCompleted) {
      whereFilter.push({ [filter.rangeColumn ? filter.rangeColumn : 'endDate']: { gte: subHours(range[0], 24) } });
      whereFilter.push({ [filter.rangeColumn ? filter.rangeColumn : 'startDate']: { lte: range[1] } });
      whereFilter.push({ customerId: { in: filter.viewPortCustomerIdsFound || [] } });
      whereFilter.push({ status: { is: 'completed' } });
    } else {
      whereFilter.push({ [filter.rangeColumn ? filter.rangeColumn : 'endDate']: { gte: range[0] } });
      whereFilter.push({ [filter.rangeColumn ? filter.rangeColumn : 'startDate']: { lte: range[1] } });
    }
  } else {
    whereFilter.push({
      startDate: {
        overlaps: {
          with: 'end_date',
          // start: getDefaultDate(new Date(), 'start'),
          // end: getDefaultDate(new Date(), 'end')
          start: range[0],
          end: range[1]
        }
      }
    });
  }

  if (filter.text.length > 0) {
    if (/^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(filter.text)) {
      whereFilter.push({
        AND: [
          {
            OR: [
              { guid: { is: filter.text } }
            ]
          }
        ]
      });
    } else {
      whereFilter.push({
        AND: [
          {
            OR: [
              { businessName: { contains: filter.text } },
              { customerName: { contains: filter.text } },
              { operatorName: { contains: filter.text } },
              { workDescription: { contains: filter.text } },
              { displayAddress: { contains: filter.text } }
            ]
          }
        ]
      });
    }
  }

  if (filter.applications.length > 0) {
    whereFilter.push(
      {
        applicationGroup: { in: filter.applications }
      }
    );
  }

  if ((filter.customerLabel || []).length > 0) {
    whereFilter.push({ customerLabel: { in: filter.customerLabel } });
  }

  // apply application group if not user or manager
  if (profile.userlevel === ROLE.VIEWER) {
    whereFilter.push(
      {
        applicationGroup: { in: profile.accessGroup || [] }
      }
    );
  }

  if (filter.trackingFlag.length > 0) {
    whereFilter.push(
      {
        trackingFlag: { in: filter.trackingFlag }
      }
    );
  }

  if (filter.jobs.length > 0) {
    whereFilter.push({
      AND: [{ OR: filter.jobs.map((j: number) => ({ services: { array_contains: j } })) }]
    });
  }

  if ((filter?.jobStatus || []).includes('not_completed')) {
    whereFilter.push({ status: { is: null } });
  } else if ((filter?.jobStatus || []).length > 0) {
    whereFilter.push({ status: { in: filter.jobStatus } });
  }

  if (filter.crew && filter.crew.filter((c: any) => c).length > 0) {
    whereFilter.push({
      AND: [{ OR: filter.crew.map((c: number) => ({ userId: { is: +c } })) }]
    });
  }

  if (filter.completedBy && filter.completedBy.filter((c: any) => c).length > 0) {
    whereFilter.push({
      AND: [{ OR: filter.completedBy.map((c: number) => ({ completedByUserId: { is: +c } })) }]
    });
  }

  // this filter is processed server side to exclude jobs from the list that don't belong to the user
  // the calendar shows all jobs.
  if (window.location.pathname.match(/\/app\/.*\/jobs\/list.*/) && [ROLE.CLIENT, ROLE.CLEANER].includes(profile.userlevel)) {
    whereFilter.push({ customerName: { not: 'Booked' } });
  }

  if ([ROLE.CLIENT, ROLE.CLEANER].includes(profile.userlevel)) {
    whereFilter.push(
      {
        applicationGroup: { in: profile.accessGroup || [] }
      }
    );
  }


  return { AND: whereFilter };
}

const getWorkOrdersFilter = (filter: any, cleanableDateRange: boolean, profile: any) => {
  const whereFilter: any = [
    { childrenCount: { is: 0 } }
  ];

  if (!filter.viewPortLinkedCustomers) {
    // whereFilter.push(
    //   {
    //     status: {
    //       in: [
    //         'completed',
    //         'not_completed',
    //         'approved',
    //         'not_approved',
    //         'canceled'
    //       ]
    //     }
    //   });
  }

  if ((filter?.text || '').length > 0) {
    whereFilter.push({
      AND: [
        {
          OR: [
            { customerName: { contains: filter.text } },
            { businessName: { contains: filter.text } }
          ]
        }
      ]
    });
  }

  if ((filter?.applications || []).length > 0) {
    whereFilter.push(
      {
        applicationGroup: { in: filter.applications }
      }
    );
  }

  if ((filter?.jobs || []).length > 0) {
    whereFilter.push({
      AND: [{ OR: filter.jobs.map((j: number) => ({ services: { array_contains: j } })) }]
    });
  }

  if ((filter?.jobStatus || []).length > 0) {
    whereFilter.push(
      {
        status: { in: filter.jobStatus }
      }
    );
  }

  if (filter.recurrence) {
    whereFilter.push(
      {
        recurrence: { is: filter.recurrence }
      }
    );
  }

  if (filter.viewPortLinkedCustomers) {
    whereFilter.push({ customerId: { in: filter.viewPortLinkedCustomers || [] } });
  }

  if (filter.viewPortQueryId) {
    whereFilter.push({ id: { in: (filter.viewPortQueryId || '').split(',').map((id: any) => +id) } })
  }

  if ((filter.customerLabel || []).length > 0) {
    whereFilter.push({ customerLabel: { in: filter.customerLabel } });
  }

  if ((filter.squareFootage || []).length > 0) {
    whereFilter.push({ squareFootage: { in: filter.squareFootage } });
  }

  if (filter.crew && filter.crew.length > 0) {
    whereFilter.push({
      AND: [{ OR: filter.crew.filter((c: any) => +c > 0).map((c: number) => ({ userId: { is: +c } })) }]
    });

    if (filter.crew.includes(-1)) {
      whereFilter[whereFilter.length - 1].AND[0].OR.push({ userId: { is: null } });
    }
  }

  // mostly for kaladikitchens
  if (profile.userlevel === 'client') {
    return { AND: whereFilter };
  }

  const dateRange = cleanableDateRange ? filter.activeRange : filter.range;

  if (dateRange && dateRange.length > 0 && filter.rangeColumn !== 'startEndInclusive') {
    const range = getRangeAtTimeZone(dateRange, profile.company.timezone);
    whereFilter.push({ [filter.rangeColumn ? filter.rangeColumn : 'startDate']: { gte: range[0] } });
    whereFilter.push({ [filter.rangeColumn ? filter.rangeColumn : 'startDate']: { lte: range[1] } });
  } else if (dateRange && dateRange.length > 0) {
    const range = getRangeAtTimeZone(dateRange, profile.company.timezone);
    whereFilter.push({
      startDate: {
        overlaps: {
          with: 'end_date',
          start: range[0],
          end: range[1]
        }
      }
    });
  } else if (!cleanableDateRange) {
    whereFilter.push({
      startDate: {
        overlaps: {
          with: 'end_date',
          start: getDefaultDate(new Date(), 'start'),
          end: getDefaultDate(new Date(), 'end')
        }
      }
    });
  }

  return { AND: whereFilter };
}

const getWorkOrdersCompareFilter = (filter: any, cleanableDateRange: boolean, profile: any, dateColumn: any) => {
  const whereFilter: any = [
    {
      status: {
        in: [
          'completed',
          'not_completed',
          'approved',
          'not_approved'
        ]
      }
    },
    { childrenCount: { is: 0 } }
  ];

  if ((filter?.text || '').length > 0) {
    whereFilter.push({
      AND: [
        {
          OR: [
            { customerName: { contains: filter.text } },
            { businessName: { contains: filter.text } }
          ]
        }
      ]
    });
  }

  if ((filter?.applications || []).length > 0) {
    whereFilter.push(
      {
        applicationGroup: { in: filter.applications }
      }
    );
  }

  if ((filter?.jobs || []).length > 0) {
    whereFilter.push({
      AND: [{ OR: filter.jobs.map((j: number) => ({ services: { array_contains: j } })) }]
    });
  }

  if ((filter?.jobStatus || []).length > 0) {
    whereFilter.push(
      {
        status: { in: filter.jobStatus }
      }
    );
  }

  if (filter.recurrence) {
    whereFilter.push(
      {
        recurrence: { is: filter.recurrence }
      }
    );
  }

  if ((filter.customerLabel || []).length > 0) {
    whereFilter.push({ customerLabel: { in: filter.customerLabel } });
  }

  if (filter.crew && filter.crew.length > 0) {
    whereFilter.push({
      AND: [{ OR: filter.crew.filter((c: any) => +c > 0).map((c: number) => ({ userId: { is: +c } })) }]
    });

    if (filter.crew.includes(-1)) {
      whereFilter[whereFilter.length - 1].AND[0].OR.push({ userId: { is: null } });
    }
  }

  if (filter.compareDateColumn !== 'startEndInclusive') {
    whereFilter.push({
      AND: [
        {
          AND: [
            { [filter.compareDateColumn ? filter.compareDateColumn : 'startDate']: { gte: filter.compareFirstRange[0] } },
            { [filter.compareDateColumn ? filter.compareDateColumn : 'startDate']: { lte: filter.compareFirstRange[1] } }
          ]
        },
        {
          OR: [
            {
              AND: [
                { [filter.compareDateColumn ? filter.compareDateColumn : 'startDate']: { gte: filter.compareSecondRange[0] } },
                { [filter.compareDateColumn ? filter.compareDateColumn : 'startDate']: { lte: filter.compareSecondRange[1] } }
              ]
            }
          ]
        }
      ]
    });
  } else {
    whereFilter.push({
      AND: [
        {
          AND: [
            { startDate: { overlaps: { with: 'end_date', start: filter.compareFirstRange[0], end: filter.compareFirstRange[1] } } }
          ]
        },
        {
          OR: [
            {
              AND: [
                { startDate: { overlaps: { with: 'end_date', start: filter.compareSecondRange[0], end: filter.compareSecondRange[1] } } }
              ]
            }
          ]
        }
      ]
    });
  }

  return { AND: whereFilter };
}

const getDocumentsFilter = (filter: any, profile: any) => {
  const andFilter = [];
  if (filter.text.length > 0) {
    andFilter.push({
      AND: [
        {
          OR: [
            { displayName: { contains: filter.text } },
            { workType: { contains: filter.text } },
            { entryType: { contains: filter.text } },
            { source: { contains: filter.text } },
            { title: { contains: filter.text } }
          ]
        }
      ]
    });
  }

  if ((filter.documentStatus || []).length > 0) {
    andFilter.push({ status: { in: filter.documentStatus } });
  }

  if ((filter.documentTemplate || []).length > 0) {
    andFilter.push({ templateId: { in: filter.documentTemplate } });
  }

  if (filter.documentType) {
    andFilter.push({ entryType: { is: filter.documentType } });
  }

  if ((filter.customerLabel || []).length > 0) {
    andFilter.push({ customerLabel: { in: filter.customerLabel } });
  }

  if ((filter.squareFootage || []).length > 0) {
    andFilter.push({ squareFootage: { in: filter.squareFootage } });
  }

  if (filter.crew && filter.crew.length > 0) {
    andFilter.push({
      AND: [{ OR: filter.crew.filter((c: any) => +c > 0).map((c: number) => ({ userId: { array_contains: c } })) }]
    });

    if (filter.crew.includes(-1)) {
      (andFilter[andFilter.length - 1] as any).AND[0].OR.push({ userId: { is: null } });
    }
  }

  if (filter.applications && filter.applications.length > 0) {
    andFilter.push({
      AND: [{ OR: filter.applications.map((a: string) => ({ workType: { contains: a } })) }]
    });
  }

  if (filter.endingPeriod) {
    switch (filter.endingPeriod) {
      case 'this_month':
        andFilter.push({ ['endDate']: { gte: startOfMonth(new Date()) } });
        andFilter.push({ ['endDate']: { lte: endOfMonth(new Date()) } });
        break;
      case 'next_month':
        andFilter.push({ ['endDate']: { gte: startOfMonth(addMonths(new Date(), 1)) } });
        andFilter.push({ ['endDate']: { lte: endOfMonth(addMonths(new Date(), 1)) } });
        break;
      case 'two_months':
        andFilter.push({ ['endDate']: { gte: startOfMonth(addMonths(new Date(), 2)) } });
        andFilter.push({ ['endDate']: { lte: endOfMonth(addMonths(new Date(), 2)) } });
        break;
      case 'three_months':
        andFilter.push({ ['endDate']: { gte: startOfMonth(addMonths(new Date(), 3)) } });
        andFilter.push({ ['endDate']: { lte: endOfMonth(addMonths(new Date(), 3)) } });
        break;

    }
  }

  if (filter.activeRange.length > 0) {
    const range = getRangeAtTimeZone(filter.activeRange, profile.company.timezone);
    andFilter.push({ [filter.rangeColumn ? filter.rangeColumn : 'startDate']: { gte: range[0] } });
    andFilter.push({ [filter.rangeColumn ? filter.rangeColumn : 'startDate']: { lte: range[1] } });
  }

  return { AND: andFilter };
}

const getInvoicesFilter = (filter: any, cleanableDateRange: boolean, profile: any) => {
  const whereFilter = [];
  const dateRange = cleanableDateRange ? filter.activeRange : filter.range;

  if (dateRange.length > 0) {
    const range = getRangeAtTimeZone(dateRange, profile.company.timezone);
    whereFilter.push({ invoiceDate: { gte: range[0] } });
    whereFilter.push({ invoiceDate: { lte: range[1] } });
  }

  if (filter.applications && filter.applications.length > 0) {
    whereFilter.push({ applicationGroup: { in: filter.applications } });
  }

  if ((filter.customerLabel || []).length > 0) {
    whereFilter.push({ customerLabel: { in: filter.customerLabel } });
  }

  if (filter.workOrderStatus) {
    whereFilter.push({ workOrderStatus: { is: filter.workOrderStatus } });
  }

  if (filter.crew && filter.crew.length) {
    whereFilter.push({
      AND: [{ OR: [{ usersId: { json_contains_any_raw: `ARRAY[${filter.crew}]` } }] }]
    });

    // andFilter.push({ usersId: { json_contains_any_raw: `ARRAY[${filter.crew}]` } });

    if (filter.crew.includes(-1)) {
      (whereFilter[whereFilter.length - 1] as any).AND[0].OR.push({ userId: { is: null } });
    }
  }

  if (filter.paymentStatus) {
    whereFilter.push({ paymentStatus: { is: filter.paymentStatus } });
  }

  if ((filter.customerLabel || []).length > 0) {
    whereFilter.push({ customerLabel: { in: filter.customerLabel } });
  }

  if (filter.jobs.length > 0) {
    whereFilter.push({
      AND: [{ OR: filter.jobs.map((j: number) => ({ services: { array_contains: j } })) }]
    });
  }

  if (filter.text.length > 0) {
    whereFilter.push({
      AND: [
        {
          OR: [
            { customerName: { contains: filter.text } },
            // { operatorName: { contains: filter.text } },
            { textId: { contains: filter.text } }
          ]
        }
      ]
    });
  }

  return { AND: whereFilter };
}

const getEmailActivitiesFilter = (filter: any, profile: any) => {
  const andFilter = [];

  if (filter.text.length > 0) {
    andFilter.push({
      AND: [
        {
          OR: [
            { displayName: { contains: filter.text } },
            { subject: { contains: filter.text } },
            { email: { contains: filter.text } }
          ]
        }
      ]
    });
  }

  if (filter.crew && filter.crew.length) {
    andFilter.push({
      AND: [{ OR: filter.crew.filter((c: any) => +c > 0).map((c: number) => ({ usersId: { array_contains: c } })) }]
    });
  }

  if (filter.activeRange.length > 0) {
    const range = getRangeAtTimeZone(filter.activeRange, profile.company.timezone);
    andFilter.push({ createdAt: { gte: range[0] } });
    andFilter.push({ createdAt: { lte: range[1] } });
  }

  if (filter.emailEvent) {
    andFilter.push({ event: { is: filter.emailEvent } });
  }

  if (filter.emailEventType) {
    andFilter.push({ reference: { is: filter.emailEventType } });
  }

  return { AND: andFilter };
}

const getPnlsFilter = (filter: any, profile: any) => {
  const andFilter: any = [];

  if (filter.activeRange && filter.activeRange.length > 0) {
    const range = getRangeAtTimeZone(filter.activeRange, profile.company.timezone);

    if (range.length === 2) {
      andFilter.push({ dateOnCalendar: { gte: range[0] } });
      andFilter.push({ dateOnCalendar: { lte: range[1] } });
    }
  }

  if ((filter?.jobStatus || []).length > 0) {
    andFilter.push(
      {
        status: { in: filter.jobStatus }
      }
    );
  }


  if (filter.crew && (filter.crew || []).filter((c: any) => +c > 0).length) {
    andFilter.push(
      {
        userId: { is: +filter.crew[0] }
      }
    );
  }

  return { AND: andFilter };
}

const getNotificationsFilter = (filter: any, profile: any) => {
  const andFilter: any = [];

  if (filter === null) {
    if (profile.userlevel === ROLE.MANAGER || profile.userlevel === ROLE.ADMIN) {
      andFilter.push({ forUserRole: { is: 'office' } });
    } else {
      andFilter.push({ forUserRole: { is: 'user' } });
    }

    if (profile?.lastNotificationSeenAt) {
      andFilter.push({ createdAt: { gte: profile.lastNotificationSeenAt } });
    }
  } else {
    if (profile.userlevel === ROLE.MANAGER || profile.userlevel === ROLE.ADMIN) {
      andFilter.push({ forUserRole: { is: 'office' } });
    } else {
      andFilter.push({ forUserRole: { is: 'user' } });
    }
  }

  return { AND: andFilter };
}

const getUsersFilter = (filter: any) => {
  const whereFilter = [];
  if (filter.text.length > 0) {
    whereFilter.push({
      AND: [
        {
          OR: [
            { username: { contains: filter.text } },
            { operatorName: { contains: filter.text } },
            { contactEmail: { contains: filter.text } }
          ]
        }
      ]
    });
  }

  if (filter.applications && filter.applications.length > 0) {
    whereFilter.push({
      AND: [{ OR: filter.applications.map((a: string) => ({ workGroup: { array_contains: a } })) }]
    });
  }

  return { AND: whereFilter };
}

const getFieldsFilter = (filter: any) => {
  const whereFilter = [];
  if (filter.text.length > 0) {
    whereFilter.push({
      AND: [
        {
          OR: [
            { title: { contains: filter.text } }
          ]
        }
      ]
    });
  }

  if (filter.applications && filter.applications.length > 0) {
    whereFilter.push({
      AND: [{ OR: filter.applications.map((a: string) => ({ applicationGroup: { is: a } })) }]
    });
  }

  return { AND: whereFilter };
}

const getCustomersFilter = (filter: any, profile: any) => {
  let whereFilter: any = {
    AND: []
  };

  if (filter?.isActiveFilter === 1) {
    whereFilter.isActive = { is: 1 };
  } else if (filter?.isActiveFilter === 0) {
    whereFilter.isActive = { is: 0 };
  }

  if (filter.textFilter && filter.textFilter.length > 0) {
    whereFilter.AND.push({
      OR: [
        { customerName: { contains: filter.textFilter } },
        { businessName: { contains: filter.textFilter } },
        { customerAddress: { contains: filter.textFilter } },
        { email: { contains: filter.textFilter } },
        { postalCode: { contains: filter.textFilter } },
        { phone: { contains: filter.textFilter } },
        { alternatePhone: { contains: filter.textFilter } }
      ]
    });
  }

  if (filter.viewPortShowAdvancedFilter) {
    const advancedFilter: any = {
      AND: [
        filter.viewPortCustomerName ? { customerName: { contains: filter.viewPortCustomerName } } : undefined,
        filter.viewPortCustomerAddress ? { customerAddress: { contains: filter.viewPortCustomerAddress } } : undefined,
        filter.viewPortEmail ? { email: { contains: filter.viewPortEmail } } : undefined,
        filter.viewPortPostalCode ? { postalCode: { contains: filter.viewPortPostalCode } } : undefined,
        filter.viewPortPhone ? { phone: { contains: filter.viewPortPhone } } : undefined,
        filter.viewPortPhone ? { alternatePhone: { contains: filter.viewPortPhone } } : undefined,
        filter.viewPortYearsActive ? { yearsActive: { is: +filter.viewPortYearsActive } } : undefined, 
      ].filter((f: any) => f)
    };
    
    whereFilter.AND.push(advancedFilter);
  }

  return whereFilter;
}

const getEmployeesPayrollFilter = (id: any, filter: any, profile: any) => {
  const andFilter: any = [];

  if (filter.range && filter.range.length > 0) {
    const range = getRangeAtTimeZone(filter.range, profile.company.timezone);

    if (range.length === 2) {
      andFilter.push({ clockIn: { gte: range[0] } });
      andFilter.push({ clockIn: { lte: range[1] } });
    }
  }

  if (filter.text.length > 0) {
    andFilter.push({
      AND: [{
        OR: [
          { employeeName: { contains: filter.text } },
          { operatorName: { contains: filter.text } }
        ]
      }]
    });
  }

  if (filter.crew.length > 0) {
    andFilter.push({ userId: { in: filter.crew } });
  }

  return { AND: andFilter };
}

const getEmployeesFilter = (filter: any, profile: any) => {
  const whereFilter: any = {};

  if (filter.text.length > 0) {
    if (!whereFilter.AND) {
      whereFilter.AND = [];
    }

    whereFilter.AND.push({ employeeName: { contains: filter.text } });
  }

  if (filter.crew.length > 0) {
    if (!whereFilter.AND) {
      whereFilter.AND = [];
    }

    whereFilter.AND.push({ userId: { in: filter.crew } });
  }

  if (filter.showDeleted) {
    if (!whereFilter.AND) {
      whereFilter.AND = [];
    }

    whereFilter.AND.push({ deletedAt: { is: null } });
  }

  if (!filter.showDeleted) {
    if (!whereFilter.AND) {
      whereFilter.AND = [];
    }

    whereFilter.AND.push({ deletedAt: { is: null } });
  }

  if (filter.userRole) {
    if (!whereFilter.AND) {
      whereFilter.AND = [];
    }

    whereFilter.AND.push({ type: { is: filter.userRole } });
  }

  return whereFilter;
}

const getSiteReviewsFilter = (filter: any, profile: any) => {
  const whereFilter: any = {};

  if (filter.text.trim().length > 0) {
    if (!whereFilter.AND) {
      whereFilter.AND = [];
    }

    whereFilter.AND.push({
      OR: [
        { businessName: { contains: filter.text } },
        { customerName: { contains: filter.text } },
        // { operatorName: { contains: filter.text } },
      ]
    });
  }

  if (filter.viewPortCustomerId) {
    if (!whereFilter.AND) {
      whereFilter.AND = [];
    }

    whereFilter.AND.push({ customerId: { is: +(filter.viewPortCustomerId || 0) } });
  }

  if (filter.crew.length > 0) {
    if (!whereFilter.AND) {
      whereFilter.AND = [];
    }

    whereFilter.AND.push({ userId: { in: filter.crew } });
  }

  if (filter.template) {
    if (!whereFilter.AND) {
      whereFilter.AND = [];
    }

    whereFilter.AND.push({ templateId: { is: filter.template } });
  }

  if (filter.siteReviewStatus) {
    if (!whereFilter.AND) {
      whereFilter.AND = [];
    }

    whereFilter.AND.push({ status: { is: filter.siteReviewStatus } });
  }

  if (filter.activeRange.length > 0) {
    if (!whereFilter.AND) {
      whereFilter.AND = [];
    }

    const range = filter.activeRange;

    whereFilter.AND.push({ [filter.rangeColumn ? filter.rangeColumn : 'dueDate']: { gte: range[0] } });
    whereFilter.AND.push({ [filter.rangeColumn ? filter.rangeColumn : 'dueDate']: { lte: range[1] } });
  }

  return whereFilter;
}

export {
  getFilter
}
