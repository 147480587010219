import { useContext } from 'react';
import { ApplicationContext } from 'shared';
import {
  PayrollForm as PayrollFormContainer,
  EmployeeForm as EmployeeFormContainer,
  TimeOffForm as TimeOffFormContainer

} from 'components/resourcing';
import ClockInOut from 'containers/resourcing/ClockInOut';

const PayrollForm = () => {
  const {
    drawerData,
    showDrawer,
    onUpdateDrawerCallback
  } = useContext(ApplicationContext);

  return <PayrollFormContainer
    show
    onHide={() => showDrawer(undefined)}
    onUpdate={onUpdateDrawerCallback}
    guid={drawerData?.payrollGuid}
    action={drawerData?.action}
  />
}

const EmployeeForm = () => {
  const {
    drawerData,
    showDrawer,
    onUpdateDrawerCallback
  } = useContext(ApplicationContext);

  return <EmployeeFormContainer
    show
    onHide={() => showDrawer(undefined)}
    onUpdate={onUpdateDrawerCallback}
    guid={drawerData?.employeeGuid}
    action={drawerData?.action}
  />
}

const TimeOffForm = () => {
  const {
    drawerData,
    showDrawer,
    onUpdateDrawerCallback
  } = useContext(ApplicationContext);

  return <TimeOffFormContainer
    onHide={() => showDrawer(undefined)}
    onUpdate={onUpdateDrawerCallback}
    action={drawerData.action || 'add'}
    guid={drawerData?.timeOffGuid}
  />
}

const ClockInOutForm = () => {
  const {
    showDrawer,
    onUpdateDrawerCallback
  } = useContext(ApplicationContext);

  return <ClockInOut
    show
    drawer
    onHide={() => showDrawer(undefined)}
    onUpdate={onUpdateDrawerCallback}
  />
}

export {
  PayrollForm,
  EmployeeForm,
  TimeOffForm,
  ClockInOutForm
}
