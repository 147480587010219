import { useContext } from 'react';
import { ApplicationContext } from 'shared';
import { InvoiceViewDrawer as InvoiceViewDrawerContainer } from 'containers/invoice/InvoiceView';
import { InvoiceForm as InvoiceFormContainer } from 'components/invoices';
import { AccountStatement as AccountStatementContainer } from 'components/invoices';

const InvoiceView = () => {
  const {
    drawerData,
    showDrawer,
  } = useContext(ApplicationContext);

  return <InvoiceViewDrawerContainer
    show
    onHide={() => showDrawer(undefined)}
    guid={drawerData?.invoiceGuid}
    title={drawerData?.customerName}
  />
}

const InvoiceForm = () => {
  const {
    drawerData,
    showDrawer,
    onUpdateDrawerCallback
  } = useContext(ApplicationContext);

  return <InvoiceFormContainer
    show
    drawer
    onHide={() => showDrawer(undefined)}
    guid={drawerData?.invoiceGuid}
    title={drawerData?.title}
    action={drawerData?.action}
    onUpdate={onUpdateDrawerCallback}
  />
}

const InvoiceAccountStatement = () => {
  const {
    drawerData,
    showDrawer,
    onUpdateDrawerCallback
  } = useContext(ApplicationContext);

  return <AccountStatementContainer
    onHide={() => showDrawer(undefined)}
    customerGuid={drawerData?.customerGuid}
  />
}


export {
  InvoiceView,
  InvoiceForm,
  InvoiceAccountStatement
}
