import { FormField, FormLabel } from 'components/form';
import { InputMoney, InputNumber } from 'components/form';
import { FORMAT, parseNumber } from 'lib/env';
import { IS_ASSINIBOINE, IS_GREYROCK, moneyFormatter } from 'lib/tenant';
import { startCase } from 'lodash';
import { Fragment } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { SortableHandle } from 'react-sortable-hoc';
import { Col, DatePicker, Input, InputGroup, Row, Tooltip, Whisper } from 'rsuite';
import IconButtonWrapper from 'shared/IconButtonWrapper';
import TextareaAutosize from 'react-autosize-textarea';
import { format, parseISO } from 'date-fns';
const DragHandle = SortableHandle(() => <span style={{ cursor: 'grab' }}>&nbsp;::</span>);

interface IInvoiceFormLineItem {
  applicationGroup: string,
  guid: string,
  description: string,
  cost: string,
  date: Date,
  additionalCost: string,
  deicer: string,
  measure: string,
  timeSpent: string,
  group: string,
  costType: string,
  rowNumber: number,
  invoiceDate: Date,
  costGroup: string,
  onChange: (guid: string, item: any) => void,
  onAdd: (guid: string | undefined, costGroup: string) => void,
  onRemove: (guid: string, costGroup: string, applicationGroup: string) => void,
  onLookup: (guid: string) => void,
  onChangeDescription: (guid: string) => void,
  readOnly?: boolean
}

const InvoiceFormLineItem = ({
  rowNumber,
  applicationGroup,
  guid,
  description,
  cost,
  date,
  additionalCost,
  deicer,
  measure,
  timeSpent,
  group,
  invoiceDate,
  costType,
  costGroup,
  readOnly,
  onChange,
  onAdd,
  onRemove,
  onLookup,
  onChangeDescription
}: IInvoiceFormLineItem) => {
  const getFields = (applicationGroup: string, costGroup: string, rowNumber: number) => {
    switch (applicationGroup) {
      case 'christmas_lights':
      case 'roofing__59634106__1710128534':
      case 'construction':
        return (
          <Fragment>
            <Col md={17}>
              <FormField index={rowNumber} label="Description">
                <Input
                  as={TextareaAutosize}
                  data-guid={guid}
                  value={description}
                  onChange={(val: string) => onChange(guid, { description: val })}
                  tabIndex={0}
                  readOnly={readOnly}
                />
              </FormField>
            </Col>
            <Col md={3}>
              <FormField index={rowNumber} label="Cost">
                <InputMoney
                  value={cost}
                  onChange={(val: string) => onChange(guid, { cost: val })}
                  tabIndex={0}
                  readOnly={readOnly}
                />
              </FormField>
            </Col>
          </Fragment>
        );
      case 'snow_removal':
        return (
          <Fragment>
            <Col md={10}>
              <FormField label="Description" index={rowNumber}>
                <Input
                  as={TextareaAutosize}
                  data-guid={guid}
                  value={description || ''}
                  onChange={(val: string) => {
                    onChange(guid, { description: val });
                    onChangeDescription(guid);
                  }}
                  tabIndex={0}
                  readOnly={readOnly}
                />
              </FormField>
            </Col>
            <Col md={3}>
              <FormField index={rowNumber} label="Date">
                <DatePicker
                  disabled={costGroup === 'job'}
                  readOnly={readOnly}
                  cleanable={false}
                  block
                  value={typeof (invoiceDate) === 'string' ? parseISO(invoiceDate) : invoiceDate}
                  renderValue={(date: any) => format(date, FORMAT.DAY_MONTH_DATE)}
                  ranges={[]}
                  oneTap
                  onSelect={(val) => onChange(guid, { invoiceDate: val })}
                />
              </FormField>
            </Col>
            <Col md={2}>
              <FormField index={rowNumber} label="Cost">
                <InputMoney
                  value={moneyFormatter.format(+(cost || 0)).replace('$', '')}
                  onChange={(val: string) => onChange(guid, { cost: val })}
                  tabIndex={0}
                  readOnly={readOnly}
                />
              </FormField>
            </Col>
            <Col md={2}>
              <FormField index={rowNumber} label="Add. Cost">
                <InputMoney
                  disabled={costGroup !== 'job'}
                  readOnly={readOnly}
                  value={additionalCost || ''}
                  onChange={(val: string) => onChange(guid, { additionalCost: val })}
                  tabIndex={0}
                />
              </FormField>
            </Col>
            <Col md={2}>
              <FormField index={rowNumber} label="De-Icer">
                <InputMoney
                  value={deicer || ''}
                  onChange={(val: string) => onChange(guid, { deicer: val })}
                  tabIndex={0}
                  readOnly={readOnly}
                />
              </FormField>
            </Col>
            <Col md={2}>
              <FormField index={rowNumber} label="Measure">
                <Input
                  value={measure || ''}
                  onChange={(val: string) => onChange(guid, { measure: val })}
                  tabIndex={0}
                  readOnly={readOnly}
                />
              </FormField>
            </Col>
          </Fragment>
        );
      default:
        return (
          <Fragment>
            <Col md={12}>
              <FormField index={rowNumber} label="Description">
                <Input
                  as={TextareaAutosize}
                  data-guid={guid}
                  value={description || ''}
                  onChange={(val: string) => {
                    onChange(guid, { description: val });
                    onChangeDescription(guid);
                  }}
                  tabIndex={0}
                  readOnly={readOnly}
                />
              </FormField>
            </Col>
            <Col md={3}>
              <FormField index={rowNumber} label="Date">
                <DatePicker
                  cleanable={false}
                  block
                  value={typeof (invoiceDate) === 'string' ? parseISO(invoiceDate) : invoiceDate}
                  renderValue={(date: any) => format(date, FORMAT.DAY_MONTH_DATE)}
                  ranges={[]}
                  oneTap
                  onSelect={(val) => onChange(guid, { invoiceDate: val })}
                  disabled={readOnly}
                />
              </FormField>
            </Col>
            {/* <Col md={2}>
              <Input disabled value={costGroup} />
            </Col> */}
            <Col md={3}>
              <FormField index={rowNumber} label="Time (Min)" tooltip="Enter time spent in minutes">
                <InputNumber
                  showDecimal={false}
                  value={timeSpent || ''}
                  onChange={(val: string) => onChange(guid, { timeSpent: val })}
                  tabIndex={0}
                  readOnly={readOnly}
                />
              </FormField>
            </Col>
            <Col md={3}>
              <FormField index={rowNumber} tooltip="Per Hour jobs should enter hourly rate" label="Cost">
                <InputGroup inside style={{ width: '100%' }}>
                  <InputMoney
                    allowEmpty
                    value={parseNumber(cost).toString()}
                    onChange={(val: string) => onChange(guid, { cost: val })}
                    tabIndex={0}
                    readOnly={readOnly}
                  />
                  {costType && <InputGroup.Addon>{startCase(costType)}</InputGroup.Addon>}
                </InputGroup>
              </FormField>
            </Col>
          </Fragment>
        );
    }
  }

  return (
    <div className={['mb-5', isMobileOnly && 'rs-panel-card'].join(' ')}>
      <Row>
        {!isMobileOnly &&
          <Col md={1} className="pr-0">
            {rowNumber === 0 && <FormLabel tooltip="Drag to sort" label="Sort" />}
            <DragHandle />
          </Col>
        }
        {getFields(applicationGroup, costGroup, rowNumber)}
        <Col md={((IS_ASSINIBOINE || IS_GREYROCK) && ['labour', 'product'].includes(costGroup)) ? 3 : 2} className={isMobileOnly ? 'mt-10 text-right' : ''}>
          {!isMobileOnly && rowNumber === 0 && <FormLabel label="&nbsp;" />}
          <Whisper placement="top" trigger="hover" speaker={<Tooltip>Add {startCase(group)}</Tooltip>}>
            <IconButtonWrapper
              appearance="link"
              onClick={() => onAdd(guid, applicationGroup === 'christmas_lights' ? costGroup : 'custom')}
              icon={'add'}
              disabled={readOnly}
            />
          </Whisper>
          <Whisper placement="top" trigger="hover" speaker={<Tooltip>{costGroup === 'job' ? 'Mark the job as not completed to remove this line item' : `Remove ${startCase(group)}`}</Tooltip>}>
            <IconButtonWrapper
              disabled={costGroup === 'job' || readOnly}
              appearance="link"
              onClick={() => onRemove(guid, costGroup, applicationGroup)}
              icon={'delete'}
            />
          </Whisper>
          {((IS_ASSINIBOINE || IS_GREYROCK) && ['labour', 'product'].includes(costGroup)) &&
            <Whisper placement="top" trigger="hover" speaker={<Tooltip>Lookup Cost</Tooltip>}>
              <IconButtonWrapper
                disabled={costGroup === 'job' || readOnly}
                appearance="link"
                onClick={() => onLookup(guid)}
                icon={'search'}
              />
            </Whisper>
          }
        </Col>
      </Row>
    </div>
  );
}

export default InvoiceFormLineItem;
