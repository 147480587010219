import React, { Fragment } from 'react';
import { Grid, Row, Col, Form, Whisper, Tooltip } from 'rsuite';
import LegacyHelpOIcon from "@rsuite/icons/legacy/HelpO";
import { MobileView, BrowserView, isMobileOnly } from 'react-device-detect';

const FormField = ({ children, label, index, tooltip }: any) => {
  const className = isMobileOnly ? 'pb-5' : '';

  return (
    <Grid fluid>
      <Row className={className}>
        <MobileView>
          <Col className="text-right pt-5" xs={8} sm={24}>
            {tooltip
              ? <Whisper placement="top" trigger="click" speaker={<Tooltip>{tooltip}</Tooltip>}>
                <Form.ControlLabel>{label} <LegacyHelpOIcon /></Form.ControlLabel>
              </Whisper>
              : <Form.ControlLabel>{label}</Form.ControlLabel>
            }
          </Col>
        </MobileView>
        <BrowserView>
          <Col xs={8} sm={24}>{index === 0 &&
            <Fragment>
              {tooltip
                ? <Whisper placement="top" trigger="hover" speaker={<Tooltip>{tooltip}</Tooltip>}>
                  <Form.ControlLabel>{label} <LegacyHelpOIcon /></Form.ControlLabel>
                </Whisper>
                : <Form.ControlLabel>{label}</Form.ControlLabel>
              }
            </Fragment>
          }
          </Col>
        </BrowserView>
        <Col xs={16} sm={24}>
          {children}
        </Col>
      </Row>
    </Grid>
  );
}

export default FormField;
