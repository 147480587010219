import { useEffect, useState, Fragment, useContext } from 'react';
import { Table, DOMHelper, Panel, toaster, Message, ButtonToolbar, IconButton, Col, Button, Placeholder, Breadcrumb, Whisper, Tooltip, Animation } from 'rsuite';
import LegacyPlusIcon from "@rsuite/icons/legacy/Plus";
import { Pagination, Filter, ResponsiveTable, CallToAction, ApplicationContext } from 'shared';
import { useApolloClient, gql } from '@apollo/client';
import { setHeaderTitle, getEnv, ROLE, FORMAT, DRAWER, getSortType } from 'lib/env';
import { useFilteredParams } from 'shared/FilterProvider';
import { useRouteMatch, Link, useHistory } from 'react-router-dom';
import useLocalStorage from 'lib/hooks/useLocalStorage';
import { format, parseISO } from 'date-fns';
import { isMobileOnly } from 'react-device-detect';
import { usePrairieAuth } from 'contexts/AuthPrairieProvider';
import store from 'lib/storage';
import { ExportField } from 'components/form';
import { without } from 'lodash';
import { MdCheckBoxOutlineBlank, MdOutlineCheckBox } from 'react-icons/md';

const GET_CASH_FLOW = gql`
  query cashFlows($limit: Int!, $offset: Int!, $where: CashFlowsWhere, $order: [CashFlowsSort]) {
    cashFlows(filter: {
      limit: $limit,
      offset: $offset,
      where: $where
      order: $order
    }) {
      edges {
        node {
          id
          guid
          userId
          cashBeginning
          cashEnd
          totalCashIn
          totalCashOutBusiness
          totalCashOutPersonal
          netCash
          month
          operatorName
          dateOnCalendar
        }
      },
      totalCount
    }
  }
`;

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

const { Column, HeaderCell, Cell } = Table;

const CashFlowList = () => {
  setHeaderTitle('Cash Flow');
  const filterKey = 'cashflow';
  const match = useRouteMatch();
  const client = useApolloClient();
  const history = useHistory();
  const { isRole } = usePrairieAuth();
  const { showConfirmation, showDrawer } = useContext(ApplicationContext);
  const { params } = useFilteredParams('cashflow');
  const [offset, setOffset] = useState(0);
  const [filter, setFilter] = useState(params);
  const [cashFlow, setCashFlow] = useState<any[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [recordsPerPage, setRecordsPerPage] = useLocalStorage('recordsPerPage', 20);
  const [modifiedAt, setModifiedAt] = useState(new Date().toISOString());
  const [showAll, setShowAll] = useState((params.crew || []).filter((c: any) => c).length > 0 || isRole(ROLE.CREW, ROLE.FRANCHISE));
  const [sort, setSort] = useLocalStorage<any>(`${filterKey}-sort`, { sortColumn: '', sortType: undefined });
  const [selectedTableRows, setSelectedTableRows] = useState<string[]>([]);

  useEffect(() => {
    (async function getData() {
      try {
        setLoading(true);
        const andFilter: any = [];

        try {
          const dateRange = JSON.parse(store.get(`cashflow-range-filter`));
          if (dateRange.length === 2) {
            andFilter.push({ month: { lte: dateRange[1] } });
            andFilter.push({ month: { get: dateRange[0] } });
          }
        } catch { }

        if (filter.crew.filter((f: any) => f) && filter.crew.filter((f: any) => f).length && isRole(ROLE.MANAGER, ROLE.ADMIN)) {
          andFilter.push(
            {
              userId: { is: +filter.crew[0] }
            }
          );
        }

        const data = await client.query({
          query: GET_CASH_FLOW,
          variables: {
            offset: offset,
            limit: recordsPerPage,
            where: {
              AND: andFilter
            },
            order: (sort.sortType !== undefined && sort?.sortColumn !== 'sortDate')
              ? [{ [sort.sortColumn]: sort.sortType.toUpperCase() }] : [{ month: 'DESC' }, { operatorName: 'ASC' }]
          },
          fetchPolicy: 'no-cache'
        });

        setTotalCount(data.data.cashFlows.totalCount);
        setCashFlow(data.data.cashFlows.edges.node);
        DOMHelper.scrollTop(window as any, 0);
      } catch (err) {
        console.log(err);
      }
      setLoading(false);
    })();
  }, [offset, filter, client, match, modifiedAt, recordsPerPage, sort]);

  const handleTableSelectRow = (val: any, year: number | undefined = undefined) => {
    let selectedRows = ([] as string[]).concat(selectedTableRows);

    if (selectedRows.indexOf(val) > -1) {
      selectedRows = without(selectedRows, val);
      selectedRows = without(selectedRows, 'all' + (year ? year : ''));
    } else {
      selectedRows.push(val);
    }

    setSelectedTableRows(selectedRows);
  }

  const renderTable = (data: any) => {
    return (
      <ResponsiveTable
        loading={loading}
        data={data ? data : []}
        autoHeight={true}
        rowHeight={40}
        onSortColumn={(sortColumn: string) => setSort({
          ...sort,
          sortColumn: getSortType(sort.sortType) === undefined ? undefined : sortColumn,
          sortType: getSortType(sort.sortType)
        })}
        sortColumn={sort.sortColumn}
        sortType={sort.sortType}
      >
        <Column width={40}>
          <HeaderCell>
            <Whisper placement="bottom" speaker={<Tooltip>Select All Visible</Tooltip>}>
              <IconButton
                onClick={() => {
                  if (selectedTableRows.includes('all')) {
                    setSelectedTableRows([]);
                  } else {
                    setSelectedTableRows(data.map((d: any) => d.guid).concat('all'));
                  }
                }}
                icon={selectedTableRows.indexOf('all') > -1 ? <MdOutlineCheckBox /> : <MdCheckBoxOutlineBlank />}
                style={{ marginTop: '-6px' }}
                appearance="link" />
            </Whisper>
          </HeaderCell>
          <Cell className='selectable-checkbox'>
            {(row: any) => {
              return (
                <IconButton
                  onClick={() => handleTableSelectRow(row.guid)}
                  icon={selectedTableRows.indexOf(row.guid) > -1 ? <MdOutlineCheckBox /> : <MdCheckBoxOutlineBlank />}
                  style={{ marginTop: '-6px' }}
                  appearance="link"
                />
              );
            }}
          </Cell>
        </Column>
        <Column flexGrow={1}>
          <HeaderCell>Month</HeaderCell>
          <Cell>
            {/* {(row: any) => <Link to={`/app/${getEnv()}/cash-flow/form/view/${row.guid}`}>{format(parseISO(row.month), FORMAT.MONTH_DATE)}</Link>} */}
            {(row: any) => <Link
              onClick={(e: any) => {
                e.preventDefault();
                showDrawer(DRAWER.CASH_FLOW_FORM, { action: 'view', cashFlowGuid: row.guid });
              }}
              to={`/app/${getEnv()}/cash-flow/form/view/${row.guid}`}>{format(parseISO(row.month), FORMAT.MONTH_DATE)}</Link>}
          </Cell>
        </Column>
        {isRole(ROLE.ADMIN, ROLE.MANAGER) &&
          <Column flexGrow={1} sortable>
            <HeaderCell>Crew</HeaderCell>
            <Cell dataKey='operatorName' />
          </Column>
        }
        <Column flexGrow={1} sortable>
          <HeaderCell>Beginning</HeaderCell>
          <Cell dataKey='cashBeginning'>
            {(row: any) => <span>{formatter.format(row.cashBeginning)}</span>}
          </Cell>
        </Column>
        <Column flexGrow={1} sortable>
          <HeaderCell>End</HeaderCell>
          <Cell dataKey='cashEnd'>
            {(row: any) => <span>{formatter.format(row.cashEnd)}</span>}
          </Cell>
        </Column>
        <Column flexGrow={1} sortable>
          <HeaderCell>Cash In</HeaderCell>
          <Cell dataKey='totalCashIn'>
            {(row: any) => <span>{formatter.format(row.totalCashIn)}</span>}
          </Cell>
        </Column>
        <Column flexGrow={1} sortable>
          <HeaderCell>Cash Out Business</HeaderCell>
          <Cell dataKey='totalCashOutBusiness'>
            {(row: any) => <span>{formatter.format(row.totalCashOutBusiness)}</span>}
          </Cell>
        </Column>
        <Column flexGrow={1} sortable>
          <HeaderCell>Cash Out Personal</HeaderCell>
          <Cell dataKey='totalCashOutPersonal'>
            {(row: any) => <span>{formatter.format(row.totalCashOutPersonal)}</span>}
          </Cell>
        </Column>
        <Column flexGrow={1} sortable>
          <HeaderCell>Net Cash</HeaderCell>
          <Cell dataKey='netCash'>
            {(row: any) => <span>{formatter.format(row.netCash)}</span>}
          </Cell>
        </Column>
        <Column fixed="right" width={110}>
          <HeaderCell>&nbsp;</HeaderCell>
          <Cell className="link-group">
            {(row: any) => {
              return (
                <Fragment>
                  <CallToAction
                    text="View"
                    tooltip="View"
                    icon="eye"
                    link={`/app/${getEnv()}/cash-flow/form/view/${row.guid}`}
                    onClick={() => showDrawer(DRAWER.CASH_FLOW_FORM, { action: 'view', cashFlowGuid: row.guid })}
                    useHistoryPush={true}
                  />

                  <CallToAction
                    text="Edit"
                    tooltip="Edit"
                    icon="edit2"
                    link={`/app/${getEnv()}/cash-flow/form/edit/${row.guid}`}
                    onClick={() => showDrawer(DRAWER.CASH_FLOW_FORM, { action: 'edit', cashFlowGuid: row.guid }, () => setModifiedAt(new Date().toISOString()))}
                    useHistoryPush={true}
                  />

                  <CallToAction
                    text="Remove"
                    tooltip="Remove"
                    icon="trash"
                    link={`#`}
                    onClick={() => {
                      showConfirmation(`Are you sure you want to remove ${row.dateOnCalendar.split('T')[0]} cash flow?`, 'Remove Cash Flow',
                        async () => {
                          await client.mutate({
                            mutation: gql`mutation deleteCashFlow($guid: ID!) { deleteCashFlow(guid: $guid) { success, code, message }}`,
                            variables: { guid: row.guid }
                          });
                          setModifiedAt(new Date().toISOString());
                          toaster.push(<Message type="success" showIcon closable>Cash flow removed</Message>);
                        });
                    }}
                  />
                </Fragment>
              );
            }}
          </Cell>
        </Column>
      </ResponsiveTable>
    );
  }

  return (
    <div>
      <Breadcrumb className='p-0 mb-8' separator=' | '>
        <Breadcrumb.Item>
          Cash Flow
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <IconButton appearance='primary' onClick={() => showDrawer(DRAWER.CASH_FLOW_FORM, { action: 'add', pnlGuid: undefined }, () => setModifiedAt(new Date().toISOString()))} size="xs" icon={<LegacyPlusIcon />}>New</IconButton>
        </Breadcrumb.Item>
      </Breadcrumb>

      <Filter
        id='cash-flow'
        onChange={setFilter}
        crewFilterCleanable={true}
        dateRangeCleanable={true}
        showGroupFilter={false}
        showJobStatus={true}
        showTextFilter={false}
      >
        <Col md={3} xs={24} style={{ float: 'right', textAlign: 'right' }}>
          <ExportField />
        </Col>
      </Filter>

      <Panel className={['content', isMobileOnly ? 'reset' : ''].join(' ')}>
        <Animation.Collapse in={selectedTableRows.length > 0}>
          <div>
            <ButtonToolbar className='mt-8 mb-8'>
              <Button size="xs" disabled={selectedTableRows.length === 0} appearance="ghost"
                onClick={() => history.push(`/app/${getEnv()}/cash-flow/view/${selectedTableRows.join('_')}`)}>Compare</Button>
            </ButtonToolbar>
          </div>
        </Animation.Collapse>

        {showAll
          ? <>{renderTable(cashFlow)}</>
          : <>
            <Message type='info' className='mb-12 text-center'>
              <div><strong>THIS VIEW CAN EXPOSE SENSITIVE INFORMATION FOR ALL USERS!<br />
                Click 'Proceed' or filter by User
              </strong>
              </div>
              <div className='mt-8'>
                <Button size="sm" appearance='primary' onClick={() => setShowAll(true)}>Proceed</Button>
              </div>
            </Message>
            <Placeholder.Grid rows={5} columns={6} active />
          </>
        }
      </Panel>

      <Pagination
        offset={offset}
        totalCount={totalCount}
        recordsPerPage={recordsPerPage}
        onChangeOffset={setOffset}
        onChangeLength={setRecordsPerPage}
      />
    </div>
  );
};

export default CashFlowList;
