import { ApolloClient, ApolloLink, InMemoryCache, concat, createHttpLink } from '@apollo/client';
import { buildAxiosFetch } from "@lifeomic/axios-fetch";
import axios from 'axios';
import axiosRetry from 'axios-retry';
import { v4 as uuidv4 } from 'uuid';
import { IS_LOCALHOST } from './tenant';
axiosRetry(axios, { retries: 5 });

const useAppApolloClient = () => {
  let baseUrl = `https://${window.location.host}/api`;

  if (IS_LOCALHOST) {
    baseUrl = `https://assiniboine.prairiehq.net/api`;
  }

  const instance: any = axios.create({
    baseURL: baseUrl,
    withCredentials: true
  });

  const client = new ApolloClient({
    connectToDevTools: true,
    cache: new InMemoryCache({
      addTypename: false
    }),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'no-cache'
      },
      query: {
        fetchPolicy: 'no-cache'
      },
      mutate: {
        fetchPolicy: 'no-cache'
      }
    },
    link: concat(
      new ApolloLink((operation, forward) => {
        return forward(operation).map((response: any) => {
          return response;
        });
      }),
      ApolloLink.from([
        createHttpLink({
          uri: (operation) => {
            instance.defaults.headers.common['x-request-id'] = uuidv4();
            return `${baseUrl}?operation=${encodeURIComponent(operation.operationName)}`
          },
          credentials: 'include',
          fetch: buildAxiosFetch(instance) as any,
        })
      ])
    )
  });

  return client;
};

export default useAppApolloClient;
