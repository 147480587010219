import { gql } from "@apollo/client";

const QUERY_SITE_REVIEWS = gql`
  query siteReviews($where: SiteReviewsWhere, $order: [SiteReviewsSort]) {
    siteReviews(filter: {
      where: $where
      order: $order
    }) {
      edges {
        node {
          guid
          id
          review
          createdAt
          modifiedAt
          customerId
          dueDate
          userId
          status
          lastConfirmationEmail
          customer {
            id
            guid
            displayName
            displayAddress
            displayCity
            displayPostalCode
            email
            customerName
          }
          owner {
            id
            operatorName
          }
          user {
            id
            guid
            operatorName
            contactEmail
          }
          template {
            id
            guid
            name
          }
        }
      },
      totalCount
    }
  }
`;

export {
  QUERY_SITE_REVIEWS
}