import { Dropdown, Popover, Whisper, Tooltip, IconButton, Button } from 'rsuite';
import { MdMoreVert } from 'react-icons/md';
import React from 'react';

interface ICustomWhisper {
  handleSelectMenu: (args: any, rowData: any) => {},
  rowData: any,
  placement?: any,
  ref?: any,
  options: any,
  children: any
}

const CustomWhisper = ({
  handleSelectMenu,
  rowData,
  placement,
  options,
  children
}: ICustomWhisper): JSX.Element => {
  const triggerRef: any = React.useRef();

  const handleSelectMenuInternal = (eventKey: any, event: any) => {
    // Hide the popover
    triggerRef.current.close();
    // Prevent the row in the table from receiving the "onClick" event
    event.stopPropagation();
    // Notify the callback's owner
    handleSelectMenu && handleSelectMenu(eventKey, rowData);
  };

  return <Whisper
    ref={triggerRef}
    placement={placement || 'auto'}
    trigger="click"
    onClick={(event: any) => event.stopPropagation()}
    // rsuite5
    // triggerRef={(ref: any) => { trigger = ref }}
    speaker={
      <Popover full>
        <Dropdown.Menu onSelect={handleSelectMenuInternal}>
          {options}
        </Dropdown.Menu>
      </Popover>
    }
  >
    <span>{children}</span>
  </Whisper>;
}

const PopoverDropdownMenu = (props: any) => {  
  return (
    <CustomWhisper
      handleSelectMenu={props.onMenuItemSelected}
      rowData={props.rowData}
      options={props.children}
      placement={props.placement || 'auto'}
    >
      {props.renderTitle
        ? <span>{props.renderTitle()}</span>
        : <Whisper placement="top" trigger="hover" speaker={<Tooltip>{props.label || 'More'}</Tooltip>}>
          <IconButton appearance='link' icon={props.icon || <MdMoreVert />} />
        </Whisper>
      }
    </CustomWhisper>
  );
}

export default PopoverDropdownMenu;
