import { Schema } from 'rsuite';

const { StringType } = Schema.Types;

let templates: any = {
  email: Schema.Model({
    name: StringType().isRequired('Name field is required.'),
    subject: StringType().addRule((value: any, data: any) => {
      return value !== undefined && value.trim().length > 0;
    }, 'Subject field is required', true)
  }),
  document: Schema.Model({
    name: StringType().isRequired('Name field is required.'),
    // emailIdContract: NumberType().isRequired('Email Contract field is required.'),
    // emailIdEstimate: NumberType().isRequired('Email Estimate field is required.'),
    // emailIdConcern: NumberType().isRequired('Email Concern field is required.'),
    // emailIdQuote: NumberType().isRequired('Email Quote field is required.')
  }),
  default: Schema.Model({})
};

export default templates;
