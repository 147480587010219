import { useEffect, useState } from 'react';
import { Table, Whisper, Tooltip } from 'rsuite';
import { useApolloClient, gql } from '@apollo/client';
import { FORMAT } from 'lib/env';
import { ResponsiveTable } from 'shared';
import startCase from 'lodash/startCase';
import { format, parseISO } from 'date-fns';
import { flatten, round, uniq } from 'lodash';
import { MdHelpOutline } from 'react-icons/md';
import { calculateSalesPerManHour, getCostPerJob, getJobManHours, getJobTimeSpent, getTimeRemaining } from 'lib/financials';
import { moneyFormatter } from 'lib/tenant';

const { Column, HeaderCell, Cell } = Table;

interface ISales {
  jobs: any,
  loading: boolean,
  salesPerManHourTarget: number
}

const Sales = ({
  jobs,
  loading,
  salesPerManHourTarget
}: ISales) => {
  const client = useApolloClient();
  const [hourlyEstimates, setHourlyEstimates] = useState<any[]>([]);
  const [hourlyEstimatesLoading, setHourlyEstimatesLoading] = useState(true);

  useEffect(() => {
    if (jobs) {
      (async function getDailyFinancials() {
        setHourlyEstimatesLoading(true);
        const workOrderHourlyEstimates = await client.query({
          query: gql`
            query workOrderHourlyEstimates($guid: [ID]) {
              workOrderHourlyEstimates(guid:$guid) {
                edges {
                  node {
                    guid
                    totalCost
                    perCostTypeCost
                    hoursSpent
                    hoursEstimated
                    hoursRemaining
                    monthsDuration
                  }
                }
              }
            }
          `,
          variables: {
            guid: uniq(jobs.map((j: any) => j?.workOrderGuid))
          },
          fetchPolicy: 'no-cache'
        });

        setHourlyEstimates(workOrderHourlyEstimates.data.workOrderHourlyEstimates?.edges?.node || []);
        setHourlyEstimatesLoading(false);
      })();
    }
  }, [jobs]);

  return <ResponsiveTable
    data={jobs}
    autoHeight={true}
    loading={loading || hourlyEstimatesLoading}
    html5
  >
    <Column flexGrow={1}>
      <HeaderCell>Date</HeaderCell>
      <Cell>{(row: any) => format(parseISO(row.startDate), FORMAT.DAY_MONTH_DATE)}</Cell>
    </Column>
    <Column flexGrow={1}>
      <HeaderCell>Job</HeaderCell>
      <Cell>{(row: any) =>
        <a href={`/app/prod/jobs/list?query=jobGuid:${row.guid}`} rel='norel' target='_blank'>{row.customerName}</a>
      }</Cell>
    </Column>
    <Column flexGrow={1}>
      <HeaderCell>Cost</HeaderCell>
      <Cell>{(row: any) => <>
        {moneyFormatter.format(getCostPerJob(row))}
        {row.costType && <span> - {startCase(row.costType)} </span>}
      </>}</Cell>
    </Column>
    <Column flexGrow={1}>
      <HeaderCell><div>Man Hours<br /><strong><>Total Man Hours: {round(jobs.reduce((p: any, n: any) => p += getJobManHours(n, salesPerManHourTarget), 0), 2)}</></strong></div></HeaderCell>
      <Cell>{(row: any) => <span>{getJobManHours(row, salesPerManHourTarget)}</span>}</Cell>
    </Column>
    <Column flexGrow={1}>
      <HeaderCell><div>Time Spent (Hrs)<br /><strong>Total Time Spent: {round(jobs.reduce((p: any, n: any) => p += getJobTimeSpent(n), 0), 2)}</strong></div></HeaderCell>
      <Cell>{(row: any) => getJobTimeSpent(row)}</Cell>
    </Column>
    <Column flexGrow={1}>
      <HeaderCell>SPMH</HeaderCell>
      <Cell>{(row: any) => moneyFormatter.format(calculateSalesPerManHour(row))}</Cell>
    </Column>
    <Column flexGrow={1}>
      <HeaderCell>Time Remaining (Hrs)</HeaderCell>
      <Cell>
        {(row: any) => <div>
            <span>{getTimeRemaining(row, hourlyEstimates, salesPerManHourTarget).value}</span>
            <span> </span>
            <Whisper placement="top" speaker={<Tooltip>{getTimeRemaining(row, hourlyEstimates, salesPerManHourTarget).label}</Tooltip>}>
              <span><MdHelpOutline /></span>
            </Whisper>
          </div>
        }
      </Cell>
    </Column>
  </ResponsiveTable>
}

export default Sales;