import { Fragment, useState, useEffect, useContext } from 'react';
import { ApplicationContext, CustomerLookup } from 'shared';
import {
  Form,
  SelectPicker,
  DatePicker,
  Button,
  toaster,
  Message,
  Schema,
  Drawer,
  Checkbox,
  Input,
} from 'rsuite';
import { v4 as uuidv4 } from 'uuid';
import { useApolloClient, gql } from '@apollo/client';
import { isMobileOnly } from 'react-device-detect';
import TextareaAutosize from 'react-autosize-textarea';
import { filterFieldsByParentKey } from 'lib/helpers/field';
import { useViewport } from 'shared/ViewportProvider';
import { pick, uniq } from 'lodash';
import { useQuery } from '@apollo/client';
import { GET_CUSTOMER_CONTACT_INFORMATION } from 'gql/customer';
import { useApi } from 'lib';
import { FORMAT, getEnv } from 'lib/env';

const GET_NOTE = gql`
  query note($guid: ID) {
    note(guid: $guid) {
      guid
      customerId
      applicationGroup
      notes
      status
      visibility
    }
  }
`;

const defaultFormValue = {
  guid: uuidv4(),
  notes: '',
  status: '',
  dateTime: new Date(),
  visibility: 'private'
};

const { StringType } = Schema.Types;
let model = Schema.Model({
  notes: StringType().isRequired()
});

const NoteForm = () => {
  let form: any = null;
  const client = useApolloClient();
  const { state } = useViewport();
  const {
    drawerData,
    showDrawer,
    onUpdateDrawerCallback,
    showError
  } = useContext(ApplicationContext);

  const api = useApi();
  const { noteGuid, customerId } = drawerData;
  const [saving, setSaving] = useState(false);
  const [formValue, setFormValue] = useState<any>(defaultFormValue);
  const [formError, setFormError] = useState<any>({});
  const [hasOther, setHasOther] = useState(false);
  const [otherEmails, setOtherEmails] = useState('');
  const { data } = useQuery(GET_CUSTOMER_CONTACT_INFORMATION, { variables: { guid: drawerData?.customerGuid } });
  const [selectedEmails, setSelectedEmails] = useState<string[]>(uniq((data?.customer?.email || []) as string[]).filter((e) => e.trim().length > 0));

  useEffect(() => {
    (async function getNote() {
      if (noteGuid) {
        const data = await client.query({ query: GET_NOTE, variables: { guid: noteGuid } });
        setFormValue({ ...data.data.note });
      } else {
        setFormValue({ ...defaultFormValue, guid: uuidv4() });
      }
    })();
  }, [noteGuid, client]);

  const handleSubmit = async (email: boolean) => {
    const errorMessage = 'Note form has errors which need to be corrected';
    const validation = form.check();

    if (!validation) {
      toaster.push(<Message type="error" showIcon closable>{errorMessage}</Message>);
    } else {
      setSaving(true);

      try {
        const upsertNote = gql`
        mutation upsertNote($input: UpsertNoteInput!) {
          upsertNote(input: $input) {
            success
            code
            message
            result
          }
        }`;

        const response: any = await client.mutate({
          mutation: upsertNote, variables: {
            // the array is taken from the input list
            input: pick({
              ...formValue,
              customerId: +(customerId || formValue.customerId),
              guid: noteGuid || formValue.guid
            }, ['guid', 'status', 'applicationGroup', 'customerId', 'notes', 'visibility', 'dateTime'])
          }
        });

        if (response.data.upsertNote.success == true) {
          if (email) {
            let sendToSelectedEmails: any = [].concat(selectedEmails as any);
            let sendToOtherEmails: any = [].concat(otherEmails.split(',') as any);

            const response = await api.post(`/emails/send/note/${formValue.guid}`, {
              templateSubject: formValue.status,
              templateText: formValue.notes,
              selectedEmails: sendToSelectedEmails,
              otherEmails: (hasOther ? sendToOtherEmails.map((e: string) => e.trim()) : []),
              id: drawerData.id,
              guid: formValue.guid,
              group: 'note'
            });
          }

          toaster.push(
            <Message type="success" showIcon closable>{response.data.upsertNote.message}</Message>
          );
          onUpdateDrawerCallback && onUpdateDrawerCallback(response.data.upsertNote.result);
          showDrawer(undefined);
        } else {
          showError(response);
        }
      } catch (err) {
        showError(err);
      }

      setSaving(false);
    }
  }

  const handleEmailSelect = (val: any) => {
    if (selectedEmails.includes(val)) {
      const tmp = [...selectedEmails];
      tmp.splice(selectedEmails.indexOf(val), 1);
      setSelectedEmails(tmp);
    } else {
      setSelectedEmails([...selectedEmails, val]);
    }
  }

  return (
    <Drawer
      open
      onClose={() => showDrawer(undefined)}
      size={isMobileOnly ? 'xs' : 'sm'}
    >
      <Drawer.Header>
        <Drawer.Title>{noteGuid ? 'Edit' : 'Add'} Note</Drawer.Title>
      </Drawer.Header>
      <Drawer.Body>
        <Form
          ref={(ref: any) => form = ref}
          formValue={formValue}
          onChange={setFormValue}
          formError={formError}
          onError={setFormError}
          model={model}
          fluid
        >
          {/* <CustomerLookup
            bordered={false}
            showTable={false}
            showBreadcrumb={false}
            showAutoCompleted={drawerData?.action === 'add'}
            billingCustomerId={drawerData?.customerId}
            workingCustomerId={drawerData?.customerId}
            handleCustomerIdChange={() => {}}
            handleCustomerFound={() => {}}
          /> */}

          {getEnv() === 'dev' &&
            <Form.Group>
              <Form.ControlLabel>UUID:</Form.ControlLabel>
              <p>{formValue.guid}</p>
            </Form.Group>
          }

          <Form.Group>
            <Form.ControlLabel>Recipients:</Form.ControlLabel>
            <div>
              {(data?.customer?.email || '').split(',').filter((e: any) => e.trim().length > 0).map((e: string, index: number) =>
                <div key={`email-${index}`}>
                  <Checkbox
                    value={e}
                    onChange={handleEmailSelect}
                    defaultChecked={selectedEmails.includes(e)}> {e}</Checkbox>
                </div>
              )}

              <Checkbox onChange={() => setHasOther(!hasOther)}> Other:</Checkbox>
              {hasOther &&
                <div>
                  <Input type="text" onChange={setOtherEmails} value={otherEmails} />
                  <Form.HelpText>Selected emails will be included in the email. Separate multiple addresses using a comma.</Form.HelpText>
                </div>
              }
            </div>
          </Form.Group>


          {!formValue.customerId &&
            <Fragment>
              <Form.Group>
                <Form.ControlLabel className="required">Date/Time:</Form.ControlLabel>
                <Form.Control
                  name="dateTime"
                  accepter={DatePicker}
                  block
                  cleanable={false}
                  // rsuite5
                  format={FORMAT.DAY_MONTH_TIME}
                  ranges={[
                    {
                      label: 'Now',
                      value: new Date()
                    }
                  ]} />
                <Form.HelpText>Select applicable date/time on the note</Form.HelpText>
              </Form.Group>

              <Form.Group>
                <Form.ControlLabel>Type/Subject:</Form.ControlLabel>
                <Form.Control
                  name="status"
                  accepter={SelectPicker}
                  block
                  cleanable={false}
                  searchable={false}
                  data={filterFieldsByParentKey(state.fields, 'notes').map((m: any) => ({ value: m.key, label: m.title }))} />
              </Form.Group>
            </Fragment>
          }

          <Form.Group>
            <Form.ControlLabel className="required">Notes:</Form.ControlLabel>
            <Input as={TextareaAutosize} rows={3} onChange={(val: string) => setFormValue({ ...formValue, notes: val })} value={formValue.notes} />
          </Form.Group>

          {!formValue.customerId &&
            <Form.Group>
              <Form.ControlLabel className="required">Visibility</Form.ControlLabel>
              <Form.Control
                name="visibility"
                accepter={SelectPicker}
                block
                cleanable={false}
                searchable={false}
                data={[
                  { value: 'private', label: 'Private' },
                  { value: 'public', label: 'Public' }
                ]} />
              <Form.HelpText>Private comments are not visible to the client</Form.HelpText>
            </Form.Group>
          }
        </Form>
      </Drawer.Body>
      <Drawer.Footer>
        <Button size="sm" onClick={() => {
          if (!form.check()) {
            toaster.push(
              <Message type="error" showIcon closable>User form has errors which need to be corrected</Message>
            );
            return;
          }

          handleSubmit(false);
        }} appearance="primary">Save</Button>
        <Button size="sm" onClick={() => {
          if (!form.check()) {
            toaster.push(
              <Message type="error" showIcon closable>User form has errors which need to be corrected</Message>
            );
            return;
          }

          handleSubmit(true);
        }} appearance="primary">Save &amp; Email</Button>
        <Button size="sm" onClick={() => {
          setFormError({});
          showDrawer(undefined);
        }} appearance="subtle">Close</Button>
      </Drawer.Footer>
    </Drawer>
  );
}

export {
  NoteForm
}
