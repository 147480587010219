import { useState } from 'react';
import { SelectPicker } from 'rsuite';

interface IPeople {
  peopleOnSite: number,
  jobGuid: string,
  timeCard?: any,
  handleJobUpdate: (guid: string, data: any, selection: any) => void,
  handleTimeEntryUpdate?: (guid: string, input: any) => void
}

const People = ({
  peopleOnSite,
  jobGuid,
  timeCard,
  handleJobUpdate,
  handleTimeEntryUpdate
}: IPeople) => {
  const [people, setPeople] = useState(peopleOnSite || '');

  return <SelectPicker
    block={false}
    cleanable={false}
    searchable={false}
    value={people}
    size='sm'
    onChange={(val: any) => {
      setPeople(val);
      
      if (handleTimeEntryUpdate) {
        handleTimeEntryUpdate(timeCard.guid, { jobGuid: timeCard.jobGuid, peopleOnSite: val });
      } else {
        handleJobUpdate(jobGuid, { peopleOnSite: +val }, []);
      }
    }}
    data={[
      { label: 1, value: 1 },
      { label: 2, value: 2 },
      { label: 3, value: 3 },
      { label: 4, value: 4 },
      { label: 5, value: 5 },
      { label: 6, value: 6 },
      { label: 7, value: 7 },
      { label: 8, value: 8 },
      { label: 9, value: 9 },
      { label: 10, value: 10 }
    ]}
  />
}

export default People;
