import React, { useEffect, useState } from 'react';
import { Input } from 'rsuite';

interface IInputNumber {
  value?: string | undefined,
  showDecimal?: boolean,
  onChange?: (value: any, event: React.SyntheticEvent<HTMLElement>) => void,
  onBlur?: (event: React.SyntheticEvent<HTMLElement>, value: any) => void,
  onFocus?: (event: React.SyntheticEvent<HTMLElement>) => void,
  tabIndex?: number | undefined,
  disabled?: boolean | undefined,
  readOnly?: boolean | undefined,
  style?: any
};

const InputNumber = ({
  style,
  value,
  tabIndex,
  disabled,
  readOnly,
  showDecimal,
  onBlur,
  onChange,
  onFocus
}: IInputNumber) => {
  const [formValue, setFormValue] = useState<any>(value);

  useEffect(() => {
    setFormValue(value);
  }, [value])

  return (
    <Input
      type="text"
      style={style}
      readOnly={readOnly}
      onKeyPress={(event: any) => {
        if (showDecimal === false) {
          if (!/[0-9-]/.test(event.key)) {
            event.preventDefault();
          }
        } else {
          if (!/[0-9.-]/.test(event.key)) {
            event.preventDefault();
          }
        }
      }}
      disabled={disabled}
      tabIndex={tabIndex}
      value={formValue}
      onChange={(val: string, e: any) => {
        setFormValue(val);
        if (onChange) {
          onChange(val, e.target.value);
        }
      }}
      onFocus={onFocus}
      onBlur={(e: any) => {
        if (e.target.value.trim().length === 0) {
          if (onBlur) {
            onBlur(e, e.target.value);
          }
        } else {
          const numberValue = isNaN(+e.target.value) ? '' : +e.target.value;
          setFormValue(numberValue);
          if (onBlur) {
            onBlur(e, e.target.value);
          }
        }
      }}
    />
  );
}

export default InputNumber;
