import { useApolloClient } from "@apollo/client";
import { useContext, useEffect, useState } from "react";
import { useViewport } from "shared/ViewportProvider";
import { getFilter } from 'lib/helpers/filter';
import { getCustomers, isBetween } from 'lib/helpers';
import { queryWorkOrders } from "gql/workOrders";
import { getSort } from "lib/helpers/sort";
import { ApplicationContext } from "shared";
import { fieldTitle, filterFieldsByParentKey } from "lib/helpers/field";
import { orderBy, without } from 'lodash';
import { format, parseISO } from "date-fns";
import { FORMAT } from "lib/env";
import { Button, ButtonToolbar, Checkbox, Form, IconButton } from "rsuite";
import LegacyCheckSquareOIcon from "@rsuite/icons/legacy/CheckSquareO";
import LegacySquareOIcon from "@rsuite/icons/legacy/SquareO";

interface IDocumentWorkOrders {
  customerId: number,
  document: any,
  onChange: (workOrdersLinked: string[]) => void,
  workOrdersLinked: string[]
}

const DocumentWorkOrders = ({
  customerId,
  document,
  onChange,
  workOrdersLinked
}: IDocumentWorkOrders) => {
  const client = useApolloClient();
  const filterKey = 'workOrdersCustomer';
  const { state } = useViewport();
  const { showError } = useContext(ApplicationContext);
  const [loading, setLoading] = useState(false);
  const [workOrders, setWorkOrders] = useState<any>([]);
  const workGroupTypes = filterFieldsByParentKey(state.fields, 'work_groups');
  const serviceKeys = filterFieldsByParentKey(state.fields, 'work_types').filter((w: any) => w.visibleOnBooking).map((w: any) => w.key);
  const [showOutsideOfRange, setShowOutsideOfRange] = useState(false);
  const [selected, setSelected] = useState<any>(workOrdersLinked || []);

  useEffect(() => {
    onChange(selected);
  }, [selected]);

  useEffect(() => {
    (async function getWorkOrders() {
      try {
        setLoading(true);
        setWorkOrders([]);
        const whereFilter: any = getFilter(filterKey, {}, state.profile);
        whereFilter.AND.splice(0, 1);
        const customers = await getCustomers(client, customerId);
        whereFilter.AND.push({ customerId: { in: customers } });
        whereFilter.AND.push({ childrenCount: { is: 0 } });

        const data: any = await client.query({
          query: queryWorkOrders,
          variables: {
            limit: 999,
            offset: 0,
            where: whereFilter,
            order: getSort('work-orders')
          },
          fetchPolicy: 'no-cache'
        });

        setWorkOrders(orderBy(data.data.workOrders.edges.node, 'endDate', 'desc'));
      } catch (err) {
        showError(err);
      }

      setLoading(false)
    })();
  }, [customerId]);

  return (
    <div>
      <ButtonToolbar>
        <IconButton appearance='default'
          size="xs"
          onClick={() => setShowOutsideOfRange(!showOutsideOfRange)}
          icon={showOutsideOfRange ? <LegacyCheckSquareOIcon /> : <LegacySquareOIcon />}>Show Outside of Document Start/End Range</IconButton>
        <Button size="xs" onClick={() => {
          const selection = workOrders.filter((row: any) => showOutsideOfRange ? true : isBetween(parseISO(row.startDate), document.startEndDate[0], document.startEndDate[1]))
            .map((row: any) => row.guid);
          setSelected(selection);
        }}>Select All</Button>
      </ButtonToolbar>
      {workOrders
        .filter((row: any) => showOutsideOfRange ? true : isBetween(parseISO(row.startDate), document.startEndDate[0], document.startEndDate[1]))
        .map((row: any) => <div key={`work-order-${row.guid}`}>
          <Checkbox
            checked={selected.includes(row.guid)}
            onClick={() => {
              let selection: any = [...selected];
              if (selection.includes(row.guid)) {
                selection = without(selection, row.guid);
              } else {
                selection.push(row.guid);
              }
              setSelected(selection);
            }}>{workGroupTypes.find((w: any) => w.key === row.applicationGroup)?.title}: {fieldTitle((row.services || []).filter((s: any) =>
              serviceKeys.includes(s)), state.fields).join(', ')} {row.otherServices && ': ' + row.otherServices} - {format(parseISO(row.startDate), FORMAT.MONTH_DATE)} to {format(parseISO(row.endDate), FORMAT.MONTH_DATE)}
          </Checkbox>
        </div>)}
      <Form.HelpText>Assign work orders to document to repeat as work orders attached to contract renewals</Form.HelpText>
    </div>
  );
}

export default DocumentWorkOrders;
