import { ApolloClient } from '@apollo/client';
import { queryUsers } from 'gql/users';

const getUsers = async (client: ApolloClient<object>) => {
  const results: any = await client.query({
    query: queryUsers,
    variables: {
      offset: 0,
      limit: 999
    },
    fetchPolicy: 'no-cache'
  });
  return results.data.users.edges.node;
}

export {
  getUsers
};
