import { useEffect, useState } from 'react';
import { Drawer, Button, Checkbox, Whisper, Tooltip } from 'rsuite';
import LegacyEdit2Icon from "@rsuite/icons/legacy/Edit2";
import { isMobileOnly } from 'react-device-detect';
import { without } from 'lodash';
import store from 'lib/storage';

interface IColumnChooser {
  show: boolean,
  storageKey: string,
  onHide: () => void,
  onSave: () => void
}

const defaultColumns: any = {
  documents: ['Customer Name', 'Title', 'Status', 'Work Group', 'Crew', 'Start', 'End', 'Last Email'],
  workOrders: ['Work ID', 'Status', 'Customer Name', 'Work Group', 'Work Type', 'Start', 'End', 'Crew', 'Worker'],
  emailActivity: ['Email', 'Type', 'Events', 'Date', 'Subject', 'Destination'],
  jobs: ['Contract Notes']
};

const extraColumns: any = {
  documents: ['Expiry', 'Crew Notes', 'Customer Notes', 'Cost', 'Created At', 'Modified At'],
  workOrders: ['Recurrence', 'Cost Type', 'Cost Per Hour/Visit', 'Total Cost', 'Work Comments', 'Work Comments (Short)', 'Contract Notes', 'Contract Value', 'Created At', 'Modified At'],
  emailActivity: [],
  jobs: []
}

const editableColumns: any = {
  documents: [],
  workOrders: ['Work Comments', 'Contract Notes', 'Cost Per Hour/Visit', 'Total Cost', 'Contract Value', 'Worker'],
  emailActivity: [],
  jobs: []
}

// for customer filtering
Object.keys(defaultColumns).forEach((k: string) => {
  defaultColumns[k + 'Customer'] = defaultColumns[k];
  extraColumns[k + 'Customer'] = extraColumns[k];
  editableColumns[k + 'Customer'] = editableColumns[k];
});

const ColumnChooser = ({
  show,
  storageKey,
  onHide,
  onSave
}: IColumnChooser) => {
  const [selectedColumns, setSelectedColumns] = useState<any>([]);

  useEffect(() => {
    setSelectedColumns(store.get(`${storageKey}Columns`) ? store.get(`${storageKey}Columns`) : defaultColumns[storageKey]);
  }, [show, storageKey]);

  return (
    <Drawer
      open={show}
      full={isMobileOnly}
      onClose={onHide}
    >
      <Drawer.Header>
        <Drawer.Title>Select Columns</Drawer.Title>
      </Drawer.Header>
      <Drawer.Body>
        {defaultColumns[storageKey] && [...defaultColumns[storageKey], ...extraColumns[storageKey]].map((col: any) =>
          <Checkbox
            key={col}
            onChange={(val: any) => {
              if (selectedColumns.includes(val)) {
                setSelectedColumns(without(selectedColumns, val));
              } else {
                setSelectedColumns([...selectedColumns, val]);
              }
            }}
            value={col}
            checked={(selectedColumns || []).includes(col)}>{col}&nbsp;&nbsp;&nbsp;{(editableColumns[storageKey] || []).includes(col) ? <Whisper placement="top" speaker={<Tooltip>Allows Inline Editing</Tooltip>}><LegacyEdit2Icon style={{ color: '#337ab7' }} /></Whisper> : ''}
          </Checkbox>
        )}
      </Drawer.Body>
      <Drawer.Footer>
        <Button onClick={() => {
          store.set(`${storageKey}Columns`, selectedColumns);
          onSave();
          onHide();
        }} appearance="primary">Save</Button>
        <Button onClick={onHide} appearance="subtle">Close</Button>
      </Drawer.Footer>
    </Drawer>
  );
}

export default ColumnChooser;
export { defaultColumns, extraColumns };
