import { useRef, useState } from 'react';
import { Form, Button, Schema } from 'rsuite';
import AuthLogo from './AuthLogo';

const { StringType } = Schema.Types;

const model = Schema.Model({
  password: StringType().isRequired('This field is required'),
  confirmPassword: StringType()
    .addRule((value, data) => {
      if (value !== data.password) {
        return false;
      }

      return true;
    }, 'Password confirmation does not match password')
    .isRequired('This field is required')
});

interface IAuthResetPasswordForm {
  match: any,
  history: any,
  processing: boolean,
  handleSubmit: (form: any, formValue: any, token: string) => void
}

const AuthResetPasswordForm = ({
  match,
  history,
  processing,
  handleSubmit
}: IAuthResetPasswordForm) => {
  let form: any = useRef();
  const [formError, setFormError] = useState<any>({});
  const [formValue, setFormValue] = useState<any>({
    password: '',
    confirmPassword: ''
  })

  return (
    <Form
      fluid
      ref={(ref: any) => form = ref}
      formValue={formValue}
      formError={formError}
      onChange={setFormValue}
      onCheck={setFormError}
      model={model}
    >
      <Form.Group>
        <Form.ControlLabel>New password</Form.ControlLabel>
        <Form.Control type="password" name="password" />
      </Form.Group>
      <Form.Group>
        <Form.ControlLabel>Confirm password</Form.ControlLabel>
        <Form.Control type="password" name="confirmPassword" />
      </Form.Group>
      <Form.Group>
        <div className='text-center'>
          <div>
            <Button appearance="primary" loading={processing} onClick={() => handleSubmit(form, formValue, match.params.token)}>Reset password</Button>
          </div>
        </div>
      </Form.Group>
    </Form>
  );
}

export default AuthResetPasswordForm;
