import { gql, useApolloClient } from '@apollo/client';
import { FORMAT, parseNumber, ROLE } from 'lib/env';
import startCase from 'lodash/startCase';
import React, { Fragment, useContext, useEffect, useRef, useState } from 'react';

import {
  toaster,
  Message,
  Button,
  ButtonToolbar,
  Col,
  Form,
  Grid,
  InputGroup,
  Loader,
  Panel,
  Row,
  SelectPicker,
} from 'rsuite';

import LegacyExportIcon from "@rsuite/icons/legacy/Export";
import { ApplicationContext, DrawerFormWrapper } from 'shared';
import { difference, omit, orderBy, uniq } from 'lodash';
import { format, subMonths, parseISO } from 'date-fns';
import { queryFields } from 'gql/fields';
import { v4 as uuidv4 } from 'uuid';
import { useViewport } from 'shared/ViewportProvider';
import { useQueryString } from 'lib/hooks';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { usePrairieAuth } from 'contexts/AuthPrairieProvider';

const GET_PNL = gql`
  query pnl($guid: ID) {
    pnl(guid: $guid) {
        guid
        dateOnCalendar
        operatorName
        totalIncome
        totalCostOfGoodsSold
        totalExpenses
        netProfit
        userId
        fieldValue
        pnlPercentage
        royaltyAmount
    }
  }
`;

const GET_PNLS = gql`
  query pnls($limit: Int!, $offset: Int!, $where: PnlsWhere) {
    pnls(filter: {
      limit: $limit,
      offset: $offset,
      where: $where
    }) {
      edges {
        node {
          id
          dateOnCalendar
          operatorName
          totalIncome
          totalCostOfGoodsSold
          totalExpenses
          netProfit
          userId
        }
      },
      totalCount
    }
  }
`;

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

interface IPnlForm {
  drawer?: boolean,
  show?: boolean,
  guid?: any,
  action?: any,
  userId?: number,
  date?: Date,
  onHide: () => void,
  onUpdate?: (data: any) => void
}

const PnlForm = ({ show, guid, action, drawer, userId, date, onHide, onUpdate }: IPnlForm) => {
  let form: any = useRef();
  const history = useHistory();
  const query = useQueryString();
  const client = useApolloClient();
  const { state } = useViewport();
  const { isRole } = usePrairieAuth();
  const { showError } = useContext(ApplicationContext);
  const [formValue, setFormValue] = useState<any>({});
  const [formError, setFormError] = useState({});
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [incomeFields, setIncomeFields] = useState<any>(undefined);
  const [expensesFields, setExpensesFields] = useState<any>(undefined);
  const [pnlMonths, setPnlMonths] = useState<any>(undefined);
  const [disabledPnlMonths, setDisabledPnlMonths] = useState<any>(undefined);
  const [totals, setTotals] = useState<any>({
    income: 0
  });
  const [crew, setCrew] = useState(undefined);
  guid = query?.guid || guid;
  action = query?.action || action || 'add';

  useEffect(() => {
    (async function getPnls() {
      setLoading(true);
      const pnls = await client.query({
        query: GET_PNLS,
        variables: {
          offset: 0,
          limit: 10,
        },
        fetchPolicy: 'no-cache'
      });

      const tmpPnlMonths: any = [];
      const tmpDisabledPnlMonth = pnls.data.pnls.edges.node.map((p: any) => p.dateOnCalendar);
      const thisMonthPnl = new Date(new Date().getFullYear(), new Date().getMonth(), 1, 0, 0);
      (new Array(12).fill(0)).map((n: number, i: number) => {
        tmpPnlMonths.push(subMonths(thisMonthPnl, i).toISOString());
      });
      setPnlMonths(tmpPnlMonths);
      setDisabledPnlMonths(tmpDisabledPnlMonth);
      const today = difference(tmpPnlMonths, tmpDisabledPnlMonth);

      const data: any = await client.query({
        query: queryFields,
        variables: {
          offset: 0,
          limit: 999
        }
      });

      const incomeParent = data.data.fields.edges.node.find((f: any) => f.key === 'p_l_income').id;
      const expensesParent = data.data.fields.edges.node.find((f: any) => f.key === 'p_l_cost_of_goods_sold').id;
      const iFields = orderBy(data.data.fields.edges.node.filter((n: any) => n.parentId === incomeParent), 'sortOrder', 'asc');
      const eFields = orderBy(data.data.fields.edges.node.filter((n: any) => n.parentId === expensesParent), 'sortOrder', 'asc');

      const newFormValue: any = {
        guid,
        dateOnCalendar: today.length > 0 ? today[0] : undefined,
        royaltyPercentage: state.profile.pnlPercentage,
        operatingExpenses: 0,
        royalties: 0,
        netProfitAndLoss: 0
      };

      iFields.forEach((f: any) => newFormValue[f.key] = '');
      eFields.forEach((f: any) => {
        newFormValue[f.key] = '';
        newFormValue[f.key + '__percentage'] = '';
      });
      setFormValue(newFormValue);

      const newTotals: any = {
        income: 0
      };

      uniq(eFields.map((f: any) => f.applicationGroup)).map((f: any) => newTotals[f] = 0);
      setTotals(newTotals);

      // get current pnl if guid
      if (guid) {
        const pnl = await client.query({
          query: GET_PNL,
          variables: {
            guid: guid
          },
          fetchPolicy: 'no-cache'
        });

        // const submittedKeys = data.data.fields.edges.node.map((n: any) => n.key);


        setCrew(pnl.data.pnl.operatorName);
        const submittedKeys: any = Object.keys(JSON.parse(pnl.data.pnl.fieldValue));
        const prevFormValue = JSON.parse(pnl.data.pnl.fieldValue);
        const nextFormValue = Object.keys(omit(prevFormValue, 'totals')).reduce((p: any, n: any) => {
          if (prevFormValue.hasOwnProperty(n) && prevFormValue[n].length > 0 && !['dateOnCalendar', 'royaltyPercentage'].includes(n)) {
            p[n] = formatter.format(parseNumber(prevFormValue[n])).replace('$', '');
          }

          return p;
        }, {});

        eFields.forEach((f: any) => {
          if ((!prevFormValue.hasOwnProperty(f.key + '__percentage') || prevFormValue[f.key + '__percentage'].length === 0)
            && prevFormValue.hasOwnProperty(f.key)
            && prevFormValue[f.key].length > 0
          ) {
            nextFormValue[f.key + '__percentage'] = (parseNumber(prevFormValue[f.key]) / +pnl.data.pnl.totalIncome).toFixed(2);
          }
        });

        nextFormValue.royaltyPercentage = pnl.data.pnl.pnlPercentage;
        nextFormValue.dateOnCalendar = pnl.data.pnl.dateOnCalendar;
        nextFormValue.guid = pnl.data.pnl.guid;

        setIncomeFields(iFields.filter((i: any) => submittedKeys.includes(i.key) || i.isVisible));
        setExpensesFields(eFields.filter((i: any) => submittedKeys.includes(i.key) || i.isVisible));
        setFormValue(nextFormValue);
      } else {
        setFormValue({ ...newFormValue, guid: uuidv4() });
        setIncomeFields(iFields.filter((i: any) => i.isVisible));
        setExpensesFields(eFields.filter((i: any) => i.isVisible));
      }

      setLoading(false);
    })();
  }, [guid]);

  useEffect(() => {
    updateTotals(formValue);
  }, [loading]);

  const handleSubmit = async (stay: boolean) => {
    setSaving(true);

    try {
      const upsertPnl = gql`
        mutation upsertPnl($input: UpsertPnlInput!) {
          upsertPnl(input: $input) {
            success
            code
            message
            result
          }
        }`;

      const response: any = await client.mutate({
        mutation: upsertPnl, variables: {
          input: { guid: formValue.guid, values: omit(formValue, ['guid']), totals }
        }
      });


      if (response.data.upsertPnl.success) {
        toaster.push(
          <Message type="success" showIcon closable>{response.data.upsertPnl.message}</Message>
        );
      } else {
        showError(response);
      }

      if (drawer) {
        onUpdate && onUpdate(response.data.upsertPnl.result);
        onHide();
      } else {
        if (stay) {
          history.push(`/pnl/form/edit/${response.data.upsertPnl.result.guid}`);
        } else {
          history.push(`/financials/pnls`);
        }
      }
    } catch (err) {
      showError(err);
    } finally {
      setSaving(false);
    }
  }

  const updateTotals = (form: any) => {
    const nextTotals: any = {};

    if (incomeFields && expensesFields) {
      nextTotals.income = incomeFields.map((f: any) => f.key).reduce((p: number, n: string) => {
        p += +(form[n] || '0').replace(/[^\d.-]/g, '');
        return p;
      }, 0.0);

      uniq(expensesFields.map((e: any) => e.applicationGroup))
        .filter((e: any) => e)
        .map((e: any) => {
          nextTotals[e] = expensesFields.filter((f: any) => f.applicationGroup === e)
            .map((f: any) => f.key)
            .reduce((p: number, n: string) => {
              p += +(form[n] || '0').replace(/[^\d.-]/g, '');
              return p;
            }, 0.0);
          nextTotals[e + '__percentage'] = nextTotals.income > 0 ? (nextTotals[e] / nextTotals.income * 100.0).toFixed(2) : 0;
        });

      setTotals(nextTotals);

      const nextFormValue = { ...form };

      setFormValue({
        ...nextFormValue,
        royalty: (nextTotals.income || 0) * (nextFormValue.royaltyPercentage / 100.0),
        netProfitAndLoss: nextTotals.income -
          (expensesFields && uniq(expensesFields.map((f: any) => f.applicationGroup)).reduce((p: any, n: any) => (p += nextTotals[n] || 0), 0) as number) -
          ((nextTotals.income || 0) * nextFormValue.royaltyPercentage / 100.0),
        operatingExpenses: uniq(expensesFields.map((f: any) => f.applicationGroup)).reduce((p: any, n: any) => (p += nextTotals[n] || 0), 0)
      });
    }
  }

  const cleanFieldValue = (e: any) => {
    const val = (e.target.value || '').replace(/[^\d.-]/g, '').trim();

    if (val.length > 0) {
      const field = formatter.format(val).replace('$', '');
      setFormValue({ ...formValue, [e.target.name]: field });
    } else {
      setFormValue({ ...formValue, [e.target.name]: '' });
    }
  }

  const exportPnl = async (guid: string) => {
    const res = await client.query({
      query: gql`
        query export($guid: String!, $name: String!, $params: JSON) {
          export(guid: $guid, name: $name, params: $params) {
            code,
            message,
            guid
          }
        }
      `,
      variables: {
        guid: uuidv4(),
        name: 'pnls',
        params: {
          filter: { AND: [{ guid: { is: guid } }] }
        }
      },
      fetchPolicy: 'no-cache'
    });

    history.push(`/export/download/${res.data.export.guid}/pnls`);
  }

  const Wrapper = show === undefined
    ? <div />
    : <DrawerFormWrapper
      loading={saving}
      show={show}
      title={startCase(action) + ' PNL'}
      guid={guid}
      size={'md'}
      action={action || ''}
      form={'/'}
      onHide={onHide}
      onSave={handleSubmit}
    />;

  return React.cloneElement(Wrapper, {
    children: <div>
      {!drawer &&
        <ButtonToolbar className="mt-5 mb-20">
          <Button appearance={'link'} as={Link} to={`/financials/pnls`}>P&amp;L</Button>
          <span>/</span>
          <Button appearance={'subtle'}>{startCase(action)}</Button>
          {action === 'view' &&
            <Button as="a" onClick={() => exportPnl(guid)} target="_blank">
              <LegacyExportIcon /> Export
            </Button>
          }
        </ButtonToolbar>
      }

      <Panel className="content">
        {loading
          ? <Loader content="Loading..." />
          : <Fragment>
            <Form
              fluid
              ref={(ref: any) => form = ref}
              formValue={formValue}
              formError={formError}
              onChange={(formValue: any, e: any) => {
                if (e.target.value === undefined) {
                  setFormValue(formValue);
                  return;
                }

                const name = e.target.name;
                const val = e.target.value.replace(/[^\d.-]/g, '').trim();

                if (name && val.length > 0 && totals.income > 0) {
                  formValue[`${name}__percentage`] = ((+val / +totals.income) * 100.0).toFixed(2);
                }

                updateTotals(formValue);
              }}
              onCheck={setFormError}
            >

              <fieldset>
                <legend>Date</legend>
                <Form.Group>
                  <Grid fluid>
                    {crew &&
                      <Row className="mb-5">
                        <Col md={8} className='text-right'><strong>Crew:</strong></Col>
                        <Col md={5}>{crew}</Col>
                      </Row>
                    }
                    <Row className={'mb-5'}>
                      <Col md={8}>
                        <Form.ControlLabel className={'pt-4 text-right'}>Month:</Form.ControlLabel>
                      </Col>
                      <Col md={6}>
                        {((action === 'view' || (action === 'edit' && isRole(ROLE.FRANCHISE, ROLE.CREW)))  && formValue.dateOnCalendar)
                          ? <div className="pt-4">{format(parseISO(formValue.dateOnCalendar), FORMAT.MONTH_YEAR)}</div>
                          : <Form.Control
                          block
                          readOnly={action === 'view'}
                          cleanable={false}
                          name="dateOnCalendar"
                          searchable={false}
                          style={{ width: '100%' }}
                          accepter={SelectPicker}
                          data={pnlMonths && pnlMonths.map((p: any) => ({
                            value: p,
                            label: format(parseISO(p), 'MMMM, yyyy')
                          }))}
                          disabledItemValues={disabledPnlMonths} />
                        }
                      </Col>
                    </Row>
                    {(['add', 'edit'].includes(action) && isRole(ROLE.MANAGER, ROLE.ADMIN)) &&
                      <Row>
                        <Col md={8}>
                          <Form.ControlLabel className={'pt-4 text-right'}>Royalty:</Form.ControlLabel>
                        </Col>
                        <Col md={6}>
                          <InputGroup style={{ width: '100%' }}>
                            <Form.Control
                              // rsuite5
                              // tabIndex="-1"
                              name={'royaltyPercentage'}
                              style={{ width: '100%' }}
                              placeholder="0.00" />
                            <InputGroup.Addon>%</InputGroup.Addon>
                          </InputGroup>
                          <Form.HelpText>Applied to this month</Form.HelpText>
                        </Col>
                      </Row>
                    }
                  </Grid>
                </Form.Group>
              </fieldset>


              <Form.Group>
                <Form.ControlLabel className="fieldset">Income</Form.ControlLabel>
              </Form.Group>

              <Grid fluid>
                {incomeFields && incomeFields.map((f: any) =>
                  <Row key={f.guid} className="text-right mb-5">
                    <Col md={8}>
                      <Form.ControlLabel className={'pt-4'}>{f.title}:</Form.ControlLabel>
                    </Col>
                    <Col md={6}>
                      <InputGroup>
                        <InputGroup.Addon>$</InputGroup.Addon>
                        <Form.Control
                          readOnly={action === 'view'}
                          style={{ width: '100%' }}
                          name={f.key}
                          placeholder="0.00"
                          onBlur={cleanFieldValue} />
                      </InputGroup>
                    </Col>
                  </Row>
                )}
                <Row>
                  <Col md={8}>
                    <div className={'pt-4 text-right'}><strong>Total Income:</strong></div>
                  </Col>
                  <Col md={6} className="pt-4">
                    <strong>{formatter.format(totals.income || 0)}</strong>
                  </Col>
                </Row>
              </Grid>

              <p>&nbsp;</p>

              <Form.Group>
                <Form.ControlLabel className="fieldset">Operating Expenses</Form.ControlLabel>
              </Form.Group>
              <Grid fluid>
                {expensesFields && uniq(expensesFields.map((f: any) => f.applicationGroup)).sort().map((g: any) =>
                  <Fragment key={g}>
                    <Row className="text-right mb-5">
                      <Col md={8}>
                        <Form.ControlLabel style={{ fontSize: '14px' }}>{startCase(g)}</Form.ControlLabel>
                      </Col>
                    </Row>
                    {expensesFields.filter((f: any) => f.applicationGroup === g).map((f: any) =>
                      <Row key={f.guid} className="text-right mb-5">
                        <Col md={8}>
                          <Form.ControlLabel className={'pt-4'}>{f.title}:</Form.ControlLabel>
                        </Col>
                        <Col md={6}>
                          <InputGroup>
                            <InputGroup.Addon>$</InputGroup.Addon>
                            <Form.Control
                              readOnly={action === 'view'}
                              style={{ width: '100%' }}
                              name={f.key}
                              placeholder="0.00"
                              onBlur={cleanFieldValue} />
                          </InputGroup>
                        </Col>
                        <Col md={4} className="text-left pl-15 pt-5">
                          {formValue[f.key + '__percentage'] || 0} %
                        </Col>
                      </Row>
                    )}
                    <Row className="mb-10">
                      <Col md={8}>
                        <div className={'pt-4 text-right'}><strong>Total {startCase(g)}:</strong></div>
                      </Col>
                      <Col md={6} className="pt-4">
                        <strong>{formatter.format(totals[g] || 0)}</strong>
                      </Col>
                      <Col md={6} className="pt-4">
                        <strong>{totals[g + '__percentage'] || 0} %</strong>
                      </Col>
                    </Row>
                  </Fragment>
                )}
              </Grid>

              <p>&nbsp;</p>

              <Form.Group>
                <Form.ControlLabel className="fieldset">Overall Totals</Form.ControlLabel>
              </Form.Group>
              <Grid fluid>
                <Row>
                  <Col md={8}><div className={'pt-4 text-right'}><strong>Total Income:</strong></div></Col>
                  <Col md={6} className="pt-4"><strong>{formatter.format(totals.income || 0)}</strong></Col>
                </Row>
                <Row>
                  <Col md={8}><div className={'pt-4 text-right'}><strong>Total Operating Expenses:</strong></div></Col>
                  <Col md={2} className="pt-4">
                    {expensesFields &&
                      <strong>
                        {formatter.format(parseNumber(formValue.operatingExpenses))}
                      </strong>
                    }
                  </Col>
                  <Col md={2} className="pt-4 text-right">
                    {expensesFields &&
                      <strong>
                        {parseNumber(+formValue.operatingExpenses / +totals.income * 100).toFixed(2)}%
                      </strong>
                    }
                  </Col>
                </Row>
                <Row>
                  <Col md={8}>
                    <div className={'pt-4 text-right'}>
                      <strong>
                        Royalties ({formValue.royaltyPercentage}%):
                      </strong>
                    </div>
                  </Col>
                  <Col md={2} className="pt-4"><strong>{formatter.format(formValue.royalty)}</strong></Col>
                  <Col md={2} className="pt-4 text-right">
                    <strong>
                      {parseNumber(+formValue.royalty / +totals.income * 100).toFixed(2)}%
                    </strong>
                  </Col>
                </Row>
                <Row>
                  <Col md={8}><div className={'pt-4 text-right'}><strong>Net Income:</strong></div></Col>
                  <Col md={2} className="pt-4">
                    <strong>
                      {formatter.format(formValue.netProfitAndLoss)}
                    </strong>
                  </Col>
                  <Col md={2} className="pt-4 text-right">
                    <strong>
                      {+formValue.netProfitAndLoss < 0 ? '-' : ''}{parseNumber((+formValue.netProfitAndLoss + (+formValue.netProfitAndLoss < 0 ? +totals.income : 0)) / +totals.income * 100).toFixed(2)}%
                    </strong>
                  </Col>
                </Row>
              </Grid>
            </Form>

            {(!drawer && ['add', 'edit'].includes(action)) &&
              <ButtonToolbar className="mt-24">
                <Button appearance="primary" onClick={() => handleSubmit(false)} loading={saving}>Save</Button>
                <Button appearance="ghost" onClick={() => handleSubmit(true)} loading={saving}>Save &amp; Stay</Button>
              </ButtonToolbar>
            }
          </Fragment>
        }
      </Panel>
    </div>
  });
};

export default PnlForm;
