import { useContext } from 'react';
import { ApplicationContext } from 'shared';
import {
  DailyFinancialsForm as DailyFinancialsFormContainer,
  DailyFinancialsView as DailyFinancialsViewContainer,
  PnlForm as PnlFormContainer,
  CashFlowForm as CashFlowFormContainer

} from 'components/financials';

const DailyFinancialsForm = () => {
  const {
    drawerData,
    showDrawer,
    onUpdateDrawerCallback
  } = useContext(ApplicationContext);

  return <DailyFinancialsFormContainer
    drawer
    show
    onHide={() => showDrawer(undefined)}
    onUpdate={onUpdateDrawerCallback}
    guid={drawerData?.dailyFinancialsGuid}
    action={drawerData?.action}
    userId={drawerData?.userId}
    date={drawerData?.date}
  />
}

const DailyFinancialsView = () => {
  const {
    drawerData,
    showDrawer
  } = useContext(ApplicationContext);

  return <DailyFinancialsViewContainer
    drawer
    show
    onHide={() => showDrawer(undefined)}
    userId={drawerData?.userId}
    startDate={drawerData?.startDate}
    endDate={drawerData?.endDate}
    guid={drawerData?.dailyFinancialsGuid}
  />
}

const PnlsForm = () => {
  const {
    drawerData,
    showDrawer,
    onUpdateDrawerCallback
  } = useContext(ApplicationContext);

  return <PnlFormContainer
    drawer
    show
    userId={drawerData?.userId}
    onHide={() => showDrawer(undefined)}
    onUpdate={onUpdateDrawerCallback}
    guid={drawerData?.pnlGuid}
    action={drawerData?.action}
    date={drawerData?.date}
  />
}

const CashFlowForm = () => {
  const {
    drawerData,
    showDrawer,
    onUpdateDrawerCallback
  } = useContext(ApplicationContext);

  return <CashFlowFormContainer
    drawer
    show
    onHide={() => showDrawer(undefined)}
    onUpdate={onUpdateDrawerCallback}
    guid={drawerData?.cashFlowGuid}
    action={drawerData?.action}
    date={drawerData?.date}
  />
}

export {
  DailyFinancialsForm,
  DailyFinancialsView,
  PnlsForm,
  CashFlowForm
}
