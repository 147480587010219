import { useContext, useEffect, useRef, useState } from 'react';
import { Button, Col, Drawer, Form, Grid, Row, Schema, toaster, Message, SelectPicker } from 'rsuite';
import { ApplicationContext } from 'shared';
import { isNil, omit, pick } from 'lodash';
import { isMobileOnly } from 'react-device-detect';
import { queryUser } from 'gql/users';
import { gql, useApolloClient } from '@apollo/client';
import { ROLE } from 'lib/env';
import { useViewport } from 'shared/ViewportProvider';
import { usePrairieAuth } from 'contexts/AuthPrairieProvider';
import { useLocalStorage } from 'lib/hooks';
import store from 'lib/storage';

const { StringType } = Schema.Types;

interface IUsersMyPreferencesForm {
  onHide?: () => void
}

const UsersMyPreferencesForm = ({
  onHide
}: IUsersMyPreferencesForm) => {
  let form: any = useRef();
  const { state } = useViewport();
  const { showError } = useContext(ApplicationContext);
  const { isRole } = usePrairieAuth();
  const [formValue, setFormValue] = useState<any>({
    username: '',
    password: '',
    confirmPassword: '',
    showInDailyFinancialsEmail: false,
    hideUser: false,
    timeEntryDesktop: 'picker',
    timeEntryMobile: 'picker',
    calendarRange: 'custom',
    userlevel: 'franchise',
    allowImpersonation: [],
    color: undefined,
    accessGroup: ['maintenance', 'construction', 'christmas_lights', 'snow_removal', 'roofing__59634106__1710128534'],
    contactName: '',
    operatorName: '',
    contactNumber: '',
    alternateContactNumber: '',
    contactEmail: '',
    pnlPercentage: 0,
    userRoyaltyPercentage: 0,
    address: '',
    city: '',
    province: '',
    postalCode: '',
    businessNumber: '',
    wcbNumber: '',
    sin: '',
    workingUnderId: undefined,
  });
  const [formError, setFormError] = useState({});
  const model = Schema.Model({
    confirmPassword: StringType()
      .addRule((value, data) => {
        if (value !== data.password) {
          return false;
        }

        return true;
      }, 'The two passwords do not match'),
    contactName: StringType().isRequired('This field is required'),
    contactNumber: StringType().isRequired('This field is required'),
    contactEmail: StringType().isRequired('This field is required'),
  });
  const [showUserForm, setShowUserForm] = useState(false);
  const client = useApolloClient();
  const [saving, setSaving] = useState(false);
  const [theme, setTheme] = useLocalStorage('theme', 'light');
  const [userTheme, setUserTheme] = useState(store.get('theme', 'light'));

  // user preferences
  useEffect(() => {
    (async function getUser() {
      if (state?.profile?.guid) {
        const data: any = await client.query({
          query: queryUser,
          variables: {
            guid: state.profile.guid
          }
        });

        setFormValue({
          ...omit(data.data.user, 'password'),
          allowImpersonation: data.data.user?.allowImpersonation || []
        });
      }
    })();
  }, []);


  const handleSubmit = async (form: any) => {
    try {
      store.set('theme', userTheme);

      const upsertUser = gql`
        mutation upsertUser($input: UpsertUserInput!) {
          upsertUser(input: $input) {
            success
            code
            message
          }
        }
      `;

      const response: any = await client.mutate({
        mutation: upsertUser, variables: {
          // the array is taken from the input list
          input: pick({ ...formValue, workGroup: formValue.accessGroup }, ['guid', 'accessGroup', 'username', 'password', 'operatorName', 'userlevel', 'contactName', 'contactNumber', 'contactEmail', 'alternateContactNumber', 'workGroup', 'isLocked', 'calendarColor', 'percentageOfNet', 'address', 'city', 'province', 'postalCode', 'businessNumber', 'wcbNumber', 'sin', 'companyId', 'workingUnderId', 'allowImpersonation', 'showInDailyFinancialsEmail', 'hideUser', 'timeEntryDesktop', 'timeEntryMobile', 'color', 'pnlPercentage', 'userRoyaltyPercentage'])
        }
      });

      if (response.data.upsertUser.success) {
        toaster.push(
          <Message type="success" showIcon closable>{response.data.upsertUser.message}</Message>
        );

        
        if (userTheme !== theme) {
          location.href = location.href;
        }
        
        if (onHide) {
          onHide();
        }
      } else {
        toaster.push(
          <Message type="error" showIcon closable>{response.data.upsertUser.message}</Message>
        );
      }
    } catch (err) {
      showError(err);
    }
  }

  return (
    <div>
      <Drawer
        open
        onClose={() => {
          if (onHide) {
            onHide();
          }
          setShowUserForm(false);
        }}
        size={isMobileOnly ? 'xs' : 'md'}
      >
        <Drawer.Header>
          <Drawer.Title>My Preferences - {formValue.username}</Drawer.Title>
        </Drawer.Header>
        <Drawer.Body>
          <Grid fluid>
            <Row>
              <Col xs={23}>
                <Form
                  ref={(ref: any) => form = ref}
                  fluid
                  model={model}
                  formValue={formValue}
                  formError={formError}
                  onChange={setFormValue}
                  onCheck={setFormError}
                >
                  <fieldset>
                    <legend>Login Information</legend>
                    <Form.Group>
                      <Grid fluid className="p-0">
                        <Row>
                          {!isNil(formValue.guid) &&
                            <Col xs={24} className="mb-10">
                              <Message type="info">Type in a new password only if you wish to change it.</Message>
                            </Col>
                          }
                          <Col xs={24} md={12}>
                            <Form.ControlLabel className="required">Password:</Form.ControlLabel>
                            <Form.Control name="password" type="password" autoComplete="new-password" />
                          </Col>
                          <Col xs={24} md={12}>
                            <Form.ControlLabel className="required">Confirm Password:</Form.ControlLabel>
                            <Form.Control name="confirmPassword" type="password" autoComplete="new-password" />
                          </Col>
                        </Row>
                      </Grid>
                    </Form.Group>

                  </fieldset>

                  {!isRole(ROLE.CLIENT) &&
                    <fieldset>
                      <legend>Profile</legend>
                      <Form.Group>
                        <Grid fluid className="p-0">
                          <Row>
                            <Col xs={24} md={12}>
                              <Form.ControlLabel className="required">First and Last Name:</Form.ControlLabel>
                              <Form.Control name="contactName" />
                            </Col>
                            <Col xs={24} md={12}>
                              <Form.ControlLabel>Crew Name:</Form.ControlLabel>
                              <Form.Control name="operatorName" />
                              <Form.HelpText>Displayed in software and invoices</Form.HelpText>
                            </Col>
                          </Row>
                        </Grid>
                      </Form.Group>

                      <Form.Group>
                        <Grid fluid className="p-0">
                          <Row>
                            <Col xs={24} md={12}>
                              <Form.ControlLabel className="required">Phone Number:</Form.ControlLabel>
                              <Form.Control name="contactNumber" />
                            </Col>
                            <Col xs={24} md={12}>
                              <Form.ControlLabel>Alternate Phone Number:</Form.ControlLabel>
                              <Form.Control name="alternateContactNumber" />
                            </Col>
                          </Row>
                        </Grid>
                      </Form.Group>

                      <Form.Group>
                        <Grid fluid className="p-0">
                          <Row>
                            <Col xs={24} md={12}>
                              <Form.ControlLabel className="required">Email Address:</Form.ControlLabel>
                              <Form.Control name="contactEmail" />
                            </Col>
                            <Col xs={24} md={12}>
                              <Form.ControlLabel>Theme:</Form.ControlLabel>
                              <SelectPicker
                                cleanable={false}
                                block
                                searchable={false}
                                data={[
                                  { value: 'light', label: 'Light' },
                                  { value: 'dark', label: 'Dark' }
                                ]}
                                value={userTheme}
                                onChange={setUserTheme}
                              />
                            </Col>
                          </Row>
                        </Grid>
                      </Form.Group>
                    </fieldset>
                  }

                  {!isRole(ROLE.CLIENT) &&
                    <fieldset>
                      <legend>Contact Address</legend>
                      <Form.Group>
                        <Form.ControlLabel>Address</Form.ControlLabel>
                        <Form.Control name="address" />
                      </Form.Group>

                      <Form.Group>
                        <Grid fluid className="p-0">
                          <Row>
                            <Col xs={24} md={8}>
                              <Form.ControlLabel>City:</Form.ControlLabel>
                              <Form.Control name="city" />
                            </Col>
                            <Col xs={24} md={8}>
                              <Form.ControlLabel>Province:</Form.ControlLabel>
                              <Form.Control name="province" />
                            </Col>
                            <Col xs={24} md={8}>
                              <Form.ControlLabel>Postal Code:</Form.ControlLabel>
                              <Form.Control name="postalCode" />
                            </Col>
                          </Row>
                        </Grid>
                      </Form.Group>
                    </fieldset>
                  }

                  {!isRole(ROLE.CLIENT) &&
                    <fieldset>
                      <legend>Business Information</legend>
                      <Form.Group>
                        <Grid fluid className="p-0">
                          <Row>
                            <Col xs={24} md={8}>
                              <Form.ControlLabel>Business Number:</Form.ControlLabel>
                              <Form.Control name="businessNumber" />
                            </Col>
                            <Col xs={24} md={8}>
                              <Form.ControlLabel>WCB Number:</Form.ControlLabel>
                              <Form.Control name="wcbNumber" />
                            </Col>
                            <Col xs={24} md={8}>
                              <Form.ControlLabel>SIN:</Form.ControlLabel>
                              <Form.Control name="sin" />
                            </Col>
                          </Row>
                        </Grid>
                      </Form.Group>
                    </fieldset>
                  }

                  {/* <fieldset>
                      <legend>Settings</legend>
                      <Form.Group>
                        <Grid fluid className="p-0">
                          <Row>
                            <Col xs={24} md={8}>
                              <Form.ControlLabel>Time Entry Desktop:</Form.ControlLabel>
                              <FormControl
                                block
                                cleanable={false}
                                searchable={false}
                                name="timeEntryDesktop"
                                accepter={SelectPicker}
                                data={[
                                  { value: 'picker', label: 'Picker' },
                                  { value: 'entry', label: 'Entry' }
                                ]}
                              />
                            </Col>
                            <Col xs={24} md={8}>
                              <Form.ControlLabel>Time Entry Mobile:</Form.ControlLabel>
                              <FormControl
                                block
                                cleanable={false}
                                searchable={false}
                                name="timeEntryMobile"
                                accepter={SelectPicker}
                                data={[
                                  { value: 'picker', label: 'Picker' },
                                  { value: 'entry', label: 'Entry' }
                                ]}
                              />
                            </Col>
                            <Col xs={24} md={8}>
                              <Form.ControlLabel>Calendar Range:</Form.ControlLabel>
                              <FormControl
                                block
                                cleanable={false}
                                searchable={false}
                                name="calendarRange"
                                accepter={SelectPicker}
                                data={[
                                  { value: 'custom', label: 'Custom' },
                                  { value: 'week', label: 'Week' },
                                  { value: 'month', label: 'Month' }
                                ]}
                              />
                            </Col>
                          </Row>
                        </Grid>
                      </Form.Group>
                    </fieldset> */}
                </Form>
              </Col>
            </Row>
          </Grid>
        </Drawer.Body>
        <Drawer.Footer>
          <Button
            disabled={saving}
            loading={saving}
            onClick={() => {
              if (!form.check()) {
                toaster.push(
                  <Message type="error" showIcon closable>User form has errors which need to be corrected</Message>
                );
                return;
              }

              handleSubmit(formValue);
            }} appearance="primary" size="sm">Save</Button>
          <Button
            disabled={saving}
            onClick={() => {
              setFormError({});
              setShowUserForm(false);

              if (onHide) {
                onHide();
              }
            }} appearance="subtle" size="sm">Close</Button>
        </Drawer.Footer>
      </Drawer>
    </div>
  );
}

export {
  UsersMyPreferencesForm
}
