import reducer from 'lib/reducer';
import React, { useReducer } from 'react';

const viewportContext = React.createContext({});

const initialState = {
  notificationCount: 0,
  fields: [],
  templates: [],
  users: [],
  companies: [],
  profile: {},
  timeZone: 'America/Edmonton',
  loaded: false,
  modifiedAt: new Date().toISOString(),
  recording: false
};

const ViewportProvider = ({ children }: any) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <viewportContext.Provider value={{ state, dispatch }}>
      {children}
    </viewportContext.Provider>
  );
};

const useViewport = () => {
  const { state, dispatch }: any = React.useContext(viewportContext);
  return { state, dispatch };
}

export {
  ViewportProvider,
  useViewport
};
