import { ApplicationProvider } from './shared';
import { ApolloProvider } from '@apollo/client';
import ApplicationContainer from 'shared/ApplicationContainer';
import { ViewportProvider } from 'shared/ViewportProvider';
import { BrowserRouter } from 'react-router-dom';
import useAppApolloClient from 'lib/client';
import AuthPrairieProvider from 'contexts/AuthPrairieProvider';
import * as Sentry from "@sentry/react";
import { useEffect } from 'react';
import { CustomProvider } from 'rsuite';
import { useLocalStorage } from 'lib/hooks';
import store from 'lib/storage';
import { useApi } from 'lib';

if ((import.meta as any).env?.VITE_APP_ENV === 'production') {
  Sentry.init({
    dsn: `https://7e7ff06dcf9d82d172ea5da4ecad118b@${window.location.host}/api/sentry/5`,
    // dsn: "https://7e7ff06dcf9d82d172ea5da4ecad118b@sentry.prairiehq.net/5",
    // dsn: "https://07ed4c6d834e4aa48ee12c209e5f5274@o180500.ingest.sentry.io/1537604",
    integrations: [],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    tracesSampleRate: 0.2,
    environment: (import.meta as any).env?.VITE_APP_ENV
  });
}

const App = () => {
  const api = useApi();
  const client = useAppApolloClient();
  const [theme] = useLocalStorage('theme', 'light');

  useEffect(() => {
    const onScroll = () => {
      if (document.getElementById('scrollwrapper')) {
        if (window.scrollY > 0 && (document.getElementById('scrollwrapper') as any).className !== 'scrolled') {
          (document.getElementById('scrollwrapper') as any).className = 'scrolled';
        } else {
          (document.getElementById('scrollwrapper') as any).className = '';
        }
      }
    };
    window.removeEventListener('scroll', onScroll);
    window.addEventListener('scroll', onScroll, { passive: true });
    return () => window.removeEventListener('scroll', onScroll);
  }, []);


  useEffect(() => {
    (async function logout() {
      if (window.location.pathname === '/logout') {
        await api.post('/oauth/v2/logout', {});
        store.clearAll();
        store.session.clearAll();
      }
    })();
  }, []);

  return (
    <ViewportProvider>
      <BrowserRouter>
        <ApplicationProvider>
          <ApolloProvider client={client}>
            <AuthPrairieProvider>
              <CustomProvider theme={theme}>
                {window.location.pathname === '/logout'
                  ? <div>Session logged out. <a href="/">Click here</a> to sign in.</div>
                  : <ApplicationContainer />
                }
              </CustomProvider>
            </AuthPrairieProvider>
          </ApolloProvider>
        </ApplicationProvider>
      </BrowserRouter>
    </ViewportProvider>
  );
}

export default App;
