import React from 'react';
import { isMobileOnly } from 'react-device-detect';
import { Form, Tooltip, Whisper } from 'rsuite';
import LegacyHelpOIcon from "@rsuite/icons/legacy/HelpO";

interface IFormLabel {
  tooltip?: string,
  label: string
}

const FormLabel = ({
  tooltip,
  label
}: IFormLabel) => {
  return tooltip
    ? <Whisper placement="top" trigger={isMobileOnly ? 'click' : 'hover'} speaker={<Tooltip>{tooltip}</Tooltip>}>
      <Form.ControlLabel>{label} <LegacyHelpOIcon /></Form.ControlLabel>
    </Whisper>
    : <Form.ControlLabel>{label}</Form.ControlLabel>;
}

export default FormLabel;
