import { useContext } from 'react';
import { ApplicationContext } from 'shared';
import { DocumentForm as DocumentFormContainer } from 'containers/document/DocumentForm';

const DocumentForm = () => {
  const {
    drawerData,
    showDrawer,
    onUpdateDrawerCallback
  } = useContext(ApplicationContext);

  return <DocumentFormContainer
    drawer
    show
    customerId={drawerData?.customerId}
    onHide={() => showDrawer(undefined)}
    onUpdate={onUpdateDrawerCallback}
    guid={drawerData?.documentGuid}
    action={drawerData?.action}
  />
}

export {
  DocumentForm
}
