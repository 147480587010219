import { useEffect } from 'react';
import { gql, useApolloClient } from '@apollo/client';
import { usePrairieAuth } from 'contexts/AuthPrairieProvider';
import { addDays, addHours, format, parseISO } from 'date-fns';
import { FORMAT, getEnv, ROLE } from 'lib/env';
import { startCase } from 'lodash';
import { useContext, useRef, useState } from 'react';
import {
  Drawer,
  Form,
  SelectPicker,
  Button,
  toaster,
  Message,
  DatePicker,
  FlexboxGrid,
  CheckboxGroup,
  Checkbox,
  Input,
} from 'rsuite';
import { ApplicationContext } from 'shared';
import { useViewport } from 'shared/ViewportProvider';
import { Frequency, RRule } from 'rrule';
import { zonedTimeToUtc } from 'date-fns-tz';
import { v4 as uuidv4 } from 'uuid';
import INTL from 'tenant/intl';

// const rule = RRule.fromString("FREQ=WEEKLY;BYDAY=FR;INTERVAL=1;UNTIL=20221231T000000Z");

interface IFinancialsTimeOffForm {
  onHide: () => void,
  onUpdate?: (data: any) => void,
  guid?: string,
  action?: string
}

const initialFormData = {
  dtstart: new Date(new Date().setHours(0, 0, 0, 0)),
  until: addDays(new Date(new Date().setHours(0, 0, 0, 0)), 0),
  notes: '',
  freq: Frequency.YEARLY,
  interval: 1,
  wkst: RRule.MO,
  byweekday: [],
  count: undefined,
  noCache: true
};

const TimeOffForm = ({ onHide, onUpdate, action, guid }: IFinancialsTimeOffForm) => {
  let form: any = useRef();
  const container = useRef() as React.MutableRefObject<HTMLDivElement>;
  const { state } = useViewport();
  const { isRole } = usePrairieAuth();
  const client = useApolloClient();
  const { showError } = useContext(ApplicationContext);
  const [loading, setLoading] = useState(false);
  const [formValue, setFormValue] = useState<any>({ ...initialFormData, userId: +state.profile.id });
  const [dates, setDates] = useState<Array<Date>>([]);
  const [rrule, setRrule] = useState('');

  useEffect(() => {
    if (!guid) {
      setFormValue({ ...initialFormData, userId: +state.profile.id, guid: uuidv4() });
      return;
    }

    (async function getTimeOff() {
      const data: any = await client.query({
        query: gql`
          query timeOff($guid: ID!) {
            timeOff(guid: $guid) {
              guid
              id
              startDate
              endDate
              notes
              userId
              rrule
              user {
                operatorName
              }
            }
          }`,
        variables: {
          guid
        }
      });

      let formData: any = {
        userId: data.data.timeOff.userId,
        dtstart: parseISO(data.data.timeOff.startDate),
        until: parseISO(data.data.timeOff.endDate),
        notes: data.data.timeOff.notes,
        wkst: RRule.MO,
        byweekday: [],
        noCache: true,
        guid
      };

      if (data.data.timeOff.rrule) {
        const rule = RRule.fromString(data.data.timeOff.rrule);

        formData = {
          userId: data.data.timeOff.userId,
          dtstart: rule.options.dtstart,
          until: rule.options.until,
          notes: data.data.timeOff.notes,
          freq: rule.options.freq,
          interval: rule.options.interval,
          wkst: RRule.MO,
          byweekday: rule.options.byweekday || [],
          count: rule.options.count,
          noCache: true,
          guid
        };
      }

      setFormValue(formData);
      generateDates(formData);
    })();
  }, [guid]);

  useEffect(() => {
    generateDates(initialFormData);
  }, []);

  const generateDates = (data: any) => {
    const rule = new RRule(Object.keys({
      freq: data.freq,
      dtstart: data.dtstart,
      until: data.until,
      interval: data.interval,
      byweekday: data.byweekday
    }).reduce((p: any, n: any) => {
      if (data[n] !== undefined) {
        p[n] = data[n];
      }

      return p;
    }, {}));

    // fix timezone
    const startTimeOffset = (zonedTimeToUtc(data.dtstart, 'America/Edmonton').getTimezoneOffset() - 360) / 60;
    setDates(rule.all().map((d: Date) => {
      const diffInHours = (zonedTimeToUtc(d, 'America/Edmonton').getTimezoneOffset() - 360) / 60 - startTimeOffset;
      return addHours(d, diffInHours);
    }));

    setRrule(rule.toString());
  }

  return (
    <Drawer open onClose={onHide}>
      <Drawer.Header>
        <Drawer.Title>{startCase(action)} Time Off</Drawer.Title>
      </Drawer.Header>
      <Drawer.Body>
        <div ref={container}>
          <Form
            ref={(ref: any) => form = ref}
            fluid
            formValue={formValue}
            onChange={(data: any) => {
              setFormValue(data);
              generateDates(data);
            }}
          >
            <FlexboxGrid>
              {getEnv() === 'dev' &&
                <FlexboxGrid.Item colspan={24} className={'mb-12'}>
                  <Form.Group>
                    <Form.ControlLabel>UUID:</Form.ControlLabel>
                    <p>{formValue.guid}</p>
                  </Form.Group>
                </FlexboxGrid.Item>
              }
              <FlexboxGrid.Item colspan={24} className={'mb-12'}>
                <Form.Group>
                  <Form.ControlLabel className="required">{INTL.CREW}:</Form.ControlLabel>
                  <Form.Control
                    block
                    searchable={true}
                    cleanable={false}
                    readOnly={isRole(ROLE.FRANCHISE, ROLE.WORKER)}
                    name="userId"
                    accepter={SelectPicker}
                    container={() => container && container.current}
                    data={state.users}
                    labelKey="operatorName"
                    valueKey="id" />
                </Form.Group>
              </FlexboxGrid.Item>
              <FlexboxGrid.Item colspan={12} className={'mb-12'}>
                <Form.Group className='pr-6'>
                  <Form.ControlLabel className="required">Start Date:</Form.ControlLabel>
                  <Form.Control
                    name="dtstart"
                    accepter={DatePicker}
                    oneTap
                    container={() => container && container.current}
                    ranges={[]}
                    cleanable={false}
                    block
                    renderValue={(date: Date) => format(date, FORMAT.MONTH_DATE)}
                    disabledMinutes={(minute: any) => ![0, 30].includes(minute)}
                    hideMinutes={(minute: any) => ![0, 30].includes(minute)} />
                </Form.Group>
              </FlexboxGrid.Item>
              <FlexboxGrid.Item colspan={12} className={'mb-12'}>
                <Form.Group>
                  <Form.ControlLabel className="required">End Date:</Form.ControlLabel>
                  <Form.Control
                    name="until"
                    accepter={DatePicker}
                    oneTap
                    container={() => container && container.current}
                    cleanable={false}
                    ranges={[]}
                    block
                    renderValue={(date: Date) => format(date, FORMAT.MONTH_DATE)}
                    disabledMinutes={(minute: any) => ![0, 30].includes(minute)}
                    hideMinutes={(minute: any) => ![0, 30].includes(minute)} />
                </Form.Group>
              </FlexboxGrid.Item>
              <FlexboxGrid.Item colspan={24} className={'mb-12'}>
                <Form.Group>
                  <Form.ControlLabel className="required">Repeat:</Form.ControlLabel>
                  <Form.Control
                    name="freq"
                    cleanable={false}
                    accepter={SelectPicker}
                    container={() => container && container.current}
                    searchable={false}
                    data={[
                      { value: Frequency.YEARLY, label: 'Does not repeat' },
                      { value: Frequency.DAILY, label: 'Repeat daily' },
                      { value: Frequency.WEEKLY, label: 'Repeat weekly' },
                      { value: Frequency.MONTHLY, label: 'Repeat monthly' }
                    ]}
                    block />
                </Form.Group>
              </FlexboxGrid.Item>
              {[Frequency.WEEKLY, Frequency.MONTHLY].includes(formValue.freq) &&
                <FlexboxGrid.Item colspan={4} className={'mb-12'}>
                  <Form.ControlLabel>Week:</Form.ControlLabel>
                  <Form.Control
                    name="interval"
                    cleanable={false}
                    accepter={SelectPicker}
                    container={() => container && container.current}
                    searchable={false}
                    block
                    data={[
                      { label: 1, value: 1 },
                      { label: 2, value: 2 },
                      { label: 3, value: 3 },
                      { label: 4, value: 4 },
                      { label: 5, value: 5 }
                    ]} />
                </FlexboxGrid.Item>
              }
              {[Frequency.DAILY, Frequency.WEEKLY, Frequency.MONTHLY].includes(formValue.freq) &&
                <FlexboxGrid.Item colspan={[Frequency.WEEKLY, Frequency.MONTHLY].includes(formValue.freq) ? 20 : 24} className={'mb-12'}>
                  <Form.Group>
                    <Form.ControlLabel>Days:</Form.ControlLabel>
                    <Form.Control name="byweekday" accepter={CheckboxGroup} inline>
                      <Checkbox value={RRule.MO.weekday}>Mon</Checkbox>
                      <Checkbox value={RRule.TU.weekday}>Tue</Checkbox>
                      <Checkbox value={RRule.WE.weekday}>Wed</Checkbox>
                      <Checkbox value={RRule.TH.weekday}>Thu</Checkbox>
                      <Checkbox value={RRule.FR.weekday}>Fri</Checkbox>
                      <Checkbox value={RRule.SA.weekday}>Sat</Checkbox>
                      <Checkbox value={RRule.SU.weekday}>Sun</Checkbox>
                    </Form.Control>
                  </Form.Group>
                </FlexboxGrid.Item>
              }
              <FlexboxGrid.Item colspan={24} className={'mb-12'}>
                <Form.Group>
                  <Form.ControlLabel>Notes:</Form.ControlLabel>
                  <Input as={'textarea'} value={formValue.notes} name="notes" onChange={(val: any) => setFormValue({ ...formValue, notes: val })} />
                </Form.Group>
              </FlexboxGrid.Item>
              <FlexboxGrid.Item colspan={24} className={'mb-12'}>
                <Form.Group>
                  <Form.ControlLabel>Days Off:</Form.ControlLabel>
                  {dates.map((d: any) => <div key={d.toISOString()}>{format(d, FORMAT.DAY_MONTH_DATE)}</div>)}
                </Form.Group>
              </FlexboxGrid.Item>
            </FlexboxGrid>
          </Form>
        </div>
      </Drawer.Body>
      <Drawer.Footer>
        <Button size="sm" loading={loading} disabled={loading} onClick={async () => {
          setLoading(true);
          try {
            const response = await client.mutate({
              mutation: gql`
                mutation upsertTimeOff(
                  $startDate: DateTime
                  $endDate: DateTime
                  $notes: String
                  $userId: Int
                  $rrule: String
                  $guid: ID
                ) {
                  upsertTimeOff(
                    startDate: $startDate
                    endDate: $endDate
                    notes: $notes
                    userId: $userId
                    rrule: $rrule
                    guid: $guid
                  ) {
                    code
                    success
                    message
                    result
                  }
                }
              `,
              variables: {
                guid: formValue.guid,
                startDate: formValue.dtstart,
                endDate: formValue.until,
                userId: formValue.userId,
                notes: formValue.notes,
                rrule: rrule
              }
            });

            setLoading(false);

            if (response.data.upsertTimeOff.code === 200) {
              toaster.push(
                <Message type="success" showIcon closable>{response.data.upsertTimeOff.message}</Message>
              );
              onUpdate && onUpdate(response.data.upsertTimeOff.result);
              onHide();
            } else {
              showError(new Error(response.data.upsertTimeOff.message));
            }
          } catch (err) {
            setLoading(false);
            showError(err);
          }
        }} appearance="primary">Save</Button>
        <Button disabled={loading} onClick={() => onHide()} size="sm" appearance="subtle">Close</Button>
      </Drawer.Footer>
    </Drawer >
  );
}

export default TimeOffForm;
