import { gql } from "@apollo/client";

const queryCustomerLabels = gql`
  query customerLabels {
    customerLabels {
      labels
    }
  }
`;

const GET_CUSTOMER_CONTACT_INFORMATION = gql`
  query customer($guid: ID) {
    customer(guid: $guid) {
      id,
      guid,
      email
      parentId,
      isActive,
      doNotService,
      doNotContact,
      displayName
    }
  }
`;

const QUERY_CUSTOMER_CONTACT_INFORMATION_BY_ID = gql`
  query customer($id: Int) {
    customer(id: $id) {
      id,
      guid,
      email
      parentId,
      isActive,
      doNotService,
      doNotContact,
      displayName
      customerName
      deletedAt
      customFields
    }
  }
`;

const GET_CUSTOMER = gql`
  query customer($guid: ID, $id: Int) {
    customer(guid: $guid, id: $id) {
      guid
      id
      parentId
      isActive
      displayName
      displayAddress
      quickbooksCode
      customerName
      businessName
      customerAddress
      workAddress
      workPostalCode
      workCity
      workProvince
      city
      province
      postalCode
      addressUnit
      phone
      alternatePhone
      faxNumber
      parentId
      doNotContact
      doNotService
      propertyManager
      label
      comments
      addressDetails
      contactDetails
      email
      userId
      props
      lat
      lng
      deletedAt
      customFields
      stripeCustomerId
      xeroCustomerId
      irrigationSystem
      squareFootage
      billing {
        id
        guid
        dataKey
        maskedPan
        expMonth
        expYear
        cvd
        notes
        isDefault
        createdAt
      }
      billingHistory {
        id
        guid
        response
        createdAt
      }
      attachments {
        guid
        fileName
        filePath
        fileId
        fileSize
        description
        visibility
        type
      }
      user {
        username
        accessGroup
      }
      parent {
        id
        displayName
        guid
        displayAddress
      }
    }
  }
`;

const queryCustomers = gql`
  query customers($where: CustomersWhere, $order: [CustomersSort]) {
    customers(filter: {
      where: $where
      order: $order
    }) {
      edges {
        node {
          guid
          id
          displayName
          displayAddress
          quickbooksCode
          customerName
          businessName
          customerAddress
          workAddress
          workPostalCode
          workCity
          workProvince
          province
          city
          postalCode
          addressUnit
          phone
          alternatePhone
          faxNumber
          parentId
          email
          isActive
          label
          comments
          registration
          addressDetails
          contactDetails
          propertyManager
          doNotContact
          doNotService
          deletedAt
          lat
          lng
          deletedReason
          customFields
          stripeCustomerId
          xeroCustomerId
          props
          squareFootage
          lastWorkOrderDate
          firstWorkOrderDate
          yearsActive
          billing {
            guid
            maskedPan
            expMonth
            expYear
            cvd
            notes
            isDefault
            dataKey
          }
          attachments {
            guid
            fileName
            filePath
            fileId
            fileSize
            description
            visibility
            type
          }
          user {
            accessGroup
          }
          parent {
            id
            displayName
            displayAddress
            guid
          }
        }
      },
      totalCount
    }
  }
`;

const QUERY_CUSTOMERS = gql`
  query customers($limit: Int!, $offset: Int!, $where: CustomersWhere, $sort: CustomersSort) {
    customers(filter: {
      limit: $limit
      offset: $offset
      where: $where
      sort: $sort
    }) {
      edges {
        node {
          id
          parentId
          guid
          customerName
          businessName
          customerAddress
          workAddress
          phone
          alternatePhone
          email
          postalCode
          displayAddress
          displayName
          label
          propertyManager
          createdAt
          modifiedAt
          contactDetails
          squareFootage
          yearsActive
        }
      },
      totalCount
    }
  }
`;


const QUERY_CUSTOMERS_MINIMAL = gql`
  query customers($where: CustomersWhere) {
    customers(filter: {
      where: $where
    }) {
      edges {
        node {
          id
          parentId
          guid
        }
      },
      totalCount
    }
  }
`;

const queryCustomer = GET_CUSTOMER;

export {
  GET_CUSTOMER,
  GET_CUSTOMER_CONTACT_INFORMATION,
  QUERY_CUSTOMER_CONTACT_INFORMATION_BY_ID,
  queryCustomer,
  queryCustomers,
  queryCustomerLabels,
  QUERY_CUSTOMERS,
  QUERY_CUSTOMERS_MINIMAL
}
