import { parseNumber } from 'lib/env';
import { useEffect, useRef, useState } from 'react';
import { Button, ButtonToolbar, Drawer, Form, Input, SelectPicker } from 'rsuite';
import labourCosts from 'data/ChristmasLightsLabourCosts';
import { startCase } from 'lodash';
import { moneyFormatter } from 'lib/tenant';

interface IInvoiceLookupChristmasLightsCost {
  guid: string,
  description: string,
  cost: string,
  show: boolean,
  productCosts: any,
  costGroup: string,
  onApply: (guid: string, item: any, refresh: boolean) => void,
  onHide: () => void
}

const InvoiceLookupChristmasLightsCost = ({
  guid,
  description,
  show,
  productCosts,
  costGroup,
  onHide,
  onApply
}: IInvoiceLookupChristmasLightsCost) => {
  const container = useRef() as React.MutableRefObject<HTMLDivElement>;
  const [type, setType] = useState<any>([]);
  const [location, setLocation] = useState<any>([]);
  const [pricing, setPricing] = useState<any>([]);

  const [formSeason, setFormSeason] = useState<any>('');
  const [formType, setFormType] = useState<any>('');
  const [formLocation, setFormLocation] = useState<any>('');
  const [formPricing, setFormPricing] = useState<any>('');
  const [formPrice, setFormPrice] = useState<any>('');
  const [formQuantity, setFormQuantity] = useState<any>('');
  const [formProduct, setFormProduct] = useState<number>(0);
  const [formDescription, setFormDescription] = useState('');

  useEffect(() => {
    setType([]);
    setLocation([]);
    setPricing([]);
    setFormSeason('');
    setFormType('');
    setFormLocation('');
    setFormPricing('');
    setFormPrice('');
    setFormQuantity('');
    setFormProduct(0);
    setFormDescription('');
  }, []);

  useEffect(() => {
    if (formSeason) {
      const children1: any = labourCosts.find((c: any) => c.name === formSeason)?.children;
      setType(children1?.map((c: any) => ({ label: c.name, value: c.name })));
      setLocation([]);
      setPricing([]);
      setFormType('');
      setFormLocation('');
      setFormPricing('');
    }
  }, [formSeason]);

  useEffect(() => {
    if (formSeason && formType) {
      const children1: any = labourCosts.find((c: any) => c.name === formSeason)?.children;
      const children2: any = children1.find((c: any) => c.name === formType)?.children;
      setLocation(children2?.map((c: any) => ({ label: c.name, value: c.name })));
      setPricing([]);
    }
  }, [formType]);

  useEffect(() => {
    if (formSeason && formType && formLocation) {
      const children1: any = labourCosts.find((c: any) => c.name === formSeason)?.children;
      const children2: any = children1.find((c: any) => c.name === formType)?.children;
      const children3: any = children2.find((c: any) => c.name === formLocation)?.children;
      setPricing(children3?.map((c: any) => ({ label: c.name, value: c.name })));

      if (formPricing) {
        setFormPrice(children3?.find((c: any) => c.name === formPricing).children[0].name);
      }
    }
  }, [formLocation, formPricing]);

  return (
    <Drawer backdrop='static' open={show} size={'xs'} onClose={onHide}>
      <Drawer.Header>
        <Drawer.Title>Lookup {startCase(costGroup)} Cost</Drawer.Title>
      </Drawer.Header>
      <Drawer.Body>
        <div ref={container}>
          {costGroup == 'labour'
            ? <Form>
              <Form.Group>
                <Form.ControlLabel>Select Season:</Form.ControlLabel>
                <SelectPicker
                  container={() => container && container.current}
                  searchable={false}
                  cleanable={false}
                  block
                  data={labourCosts.map((h: any) => ({ label: h.name, value: h.name }))}
                  onChange={setFormSeason}
                  value={formSeason}
                />
              </Form.Group>

              <Form.Group>
                <Form.ControlLabel>Type of Lights:</Form.ControlLabel>
                <SelectPicker
                  container={() => container && container.current}
                  searchable={false}
                  cleanable={false}
                  block
                  data={type}
                  onChange={setFormType}
                  value={formType}
                />
              </Form.Group>

              <Form.Group>
                <Form.ControlLabel>Location (where are they being installed):</Form.ControlLabel>
                <SelectPicker
                  container={() => container && container.current}
                  searchable={false}
                  cleanable={false}
                  block
                  data={location}
                  onChange={setFormLocation}
                  value={formLocation}
                />
              </Form.Group>

              <Form.Group>
                <Form.ControlLabel>Pricing Per:</Form.ControlLabel>
                <SelectPicker
                  container={() => container && container.current}
                  searchable={false}
                  cleanable={false}
                  block
                  data={pricing}
                  onChange={setFormPricing}
                  value={formPricing}
                />
              </Form.Group>

              <Form.Group>
                <Form.ControlLabel>Price Per Unit:</Form.ControlLabel>
                <Input
                  disabled={!formSeason || !formType || !formLocation || !formPricing}
                  onChange={setFormPrice}
                  value={formPrice}
                />
                <Form.HelpText>Enabled once all of the options above are selected</Form.HelpText>
              </Form.Group>

              <Form.Group>
                <Form.ControlLabel>Quantity Used:</Form.ControlLabel>
                <Input
                  disabled={!formSeason || !formType || !formLocation || !formPricing}
                  onChange={setFormQuantity}
                  value={formQuantity}
                />
                <Form.HelpText>Enabled once all of the options above are selected</Form.HelpText>
              </Form.Group>
            </Form>
            : <Form>
              <Form.Group>
                <Form.ControlLabel>Select Product:</Form.ControlLabel>
                <SelectPicker
                  container={() => container && container.current}
                  block
                  cleanable={false}
                  data={productCosts.map((t: any) => ({ value: t.id, label: t.title }))}
                  onChange={(val: any) => {
                    setFormProduct(val);
                    const item = productCosts.find((p: any) => p.id === val);
                    setFormPrice(item.description || '0');
                    setFormQuantity('1');
                    setFormDescription(item.title);
                  }}
                  value={formProduct}
                />
              </Form.Group>

              <Form.Group>
                <Form.ControlLabel>Price Per Unit:</Form.ControlLabel>
                <Input
                  disabled={!formProduct}
                  onChange={setFormPrice}
                  value={formPrice}
                />
                <Form.HelpText>Enabled once all of the options above are selected</Form.HelpText>
              </Form.Group>

              <Form.Group>
                <Form.ControlLabel>Quantity Used:</Form.ControlLabel>
                <Input
                  disabled={!formProduct}
                  onChange={setFormQuantity}
                  value={formQuantity}
                />
                <Form.HelpText>Enabled once all of the options above are selected</Form.HelpText>
              </Form.Group>
            </Form>
          }
        </div>
      </Drawer.Body>
      <Drawer.Footer>
        <ButtonToolbar>
          <Button
            onClick={() => {
              onApply(guid, {
                description: description.length === 0
                  ? (formQuantity + ' x ' + (formDescription || formType) + (formLocation ? ' - ' + formLocation : '')
                    + ' @ $' + formPrice + '/per ' + (formPricing || 'unit'))
                  : description,
                cost: moneyFormatter.format(parseNumber(+formPrice * +formQuantity)).replace('$', '')
              }, false);
              onHide();
            }}
            appearance="primary"
            disabled={formPrice.length === 0 || formQuantity.length === 0}
          >Apply</Button>
          <Button onClick={onHide} appearance="subtle">Close</Button>
        </ButtonToolbar>
      </Drawer.Footer>
    </Drawer>
  );
}

export default InvoiceLookupChristmasLightsCost;
