import { DRAWER, getEnv, ROLE } from "lib/env";
import { Link, useHistory, useRouteMatch } from "react-router-dom";
import { Badge, Dropdown, IconButton } from 'rsuite';
import LegacyPlusIcon from "@rsuite/icons/legacy/Plus";
import LegacyBriefcaseIcon from "@rsuite/icons/legacy/Briefcase";
import CustomLink from "shared/CustomLink";
import INTL from "tenant/intl";
import { isMobileOnly } from 'react-device-detect';
import { IS_KALADI_KITCHENS, IS_KALADI_PROPERTIES } from "lib/tenant";
import { useViewport } from "shared/ViewportProvider";
import { useContext } from "react";
import { ApplicationContext } from "shared";
import { usePrairieAuth } from "contexts/AuthPrairieProvider";
import Icon from "@rsuite/icons/lib/Icon";
import { MdAdd } from "react-icons/md";

interface NavigationDropdown {
  onUpdate?: (data: any) => void
}

const NavigationNewDropdown = ({ onUpdate }: NavigationDropdown) => {
  const match = useRouteMatch();
  const history = useHistory();
  const { state, dispatch } = useViewport();
  const { showDrawer } = useContext(ApplicationContext);
  const { can } = usePrairieAuth();

  return can('customer:create', 'document:create', 'work-order:create', 'note:create', 'storage-create')
    ? <Dropdown title={<IconButton icon={<Icon as={MdAdd} />} style={{ padding: '6px', marginTop: '-4px' }} placement="left" appearance="primary" size="sm" />} noCaret>
      {can('customer:create') &&
        <Dropdown.Item onSelect={() => showDrawer(DRAWER.CUSTOMER_FORM, {}, (data: any) => {
          history.push(`/app/${getEnv()}/workbook/explorer/${data.id}/profile`);
        })}>{INTL.CUSTOMER}</Dropdown.Item>
      }

      {(can('customer:create') && IS_KALADI_PROPERTIES) &&
        <Dropdown.Item onSelect={() => {
          const customerId = window.location.pathname.includes('/workbook/explorer') ? +window.location.pathname.split('/')[5] : undefined;
          showDrawer(DRAWER.TENANT_FORM, { action: 'add', customerId }, () => dispatch({ type: 'SET_MODIFIED_AT' }));
        }}>{INTL.TENANT}</Dropdown.Item>
      }

      {can('document:create') &&
        <Dropdown.Item onSelect={() => {
          const customerId = window.location.pathname.includes('/workbook/explorer') ? +window.location.pathname.split('/')[5] : undefined;
          showDrawer(DRAWER.DOCUMENT_FORM, { customerId });
        }}>{INTL.DOCUMENT}</Dropdown.Item>
      }

      {can('work-order:create') &&
        <Dropdown.Item onSelect={() => {
          const customerId = window.location.pathname.includes('/workbook/explorer') ? +window.location.pathname.split('/')[5] : undefined;
          showDrawer(DRAWER.WORK_ORDER_FORM, {
            customerId
          }, () => dispatch({ type: 'SET_MODIFIED_AT' }));
        }}>{INTL.WORK_ORDER}</Dropdown.Item>
      }

      <Dropdown.Separator />

      {!IS_KALADI_KITCHENS &&
        <>
          <Dropdown.Item onSelect={() => {
            showDrawer(DRAWER.DAILY_FINANCIALS_FORM, { userId: state.profile.id, date: new Date() }, () => dispatch({ type: 'SET_MODIFIED_AT' }));
          }}>Daily Financials</Dropdown.Item>
          <Dropdown.Item onSelect={() => {
            showDrawer(DRAWER.PNL_FORM, { userId: state.profile.id }, () => dispatch({ type: 'SET_MODIFIED_AT' }));
          }}>P&amp;L</Dropdown.Item>
          <Dropdown.Item onSelect={() => {
            showDrawer(DRAWER.CASH_FLOW_FORM, { userId: state.profile.id, date: new Date() }, () => dispatch({ type: 'SET_MODIFIED_AT' }));
          }}>Cash Flow</Dropdown.Item>
          <Dropdown.Item onSelect={() => {
            showDrawer(DRAWER.TIME_OFF_FORM, {}, () => dispatch({ type: 'SET_MODIFIED_AT' }));
          }}>Time Off</Dropdown.Item>

          <Dropdown.Separator />

          <Dropdown.Item onSelect={() => {
            showDrawer(DRAWER.EMPLOYEE_FORM, { action: 'add', userId: state.profile.id }, () => dispatch({ type: 'SET_MODIFIED_AT' }));
          }}>Employee</Dropdown.Item>
          <Dropdown.Item onSelect={() => {
            showDrawer(DRAWER.PAYROLL_FORM, { action: 'add', userId: state.profile.id }, () => dispatch({ type: 'SET_MODIFIED_AT' }));
          }}>Payroll</Dropdown.Item>
          <Dropdown.Item onSelect={() => {
            showDrawer(DRAWER.TIME_OFF_FORM, {}, () => dispatch({ type: 'SET_MODIFIED_AT' }));
          }}>Time Off</Dropdown.Item>
        </>
      }

      {IS_KALADI_KITCHENS &&
        <Dropdown.Item onSelect={() => {
          showDrawer(DRAWER.STORAGE_FORM, { action: 'add' }, (data: any) => {
            history.push(`/app/${getEnv()}/workbook/storage`);
            if (onUpdate) {
              onUpdate(data);
            }
          });
        }}>Storage</Dropdown.Item>
      }
      {/* <Dropdown.Item as={Link} to={`/app/${getEnv()}/note/add?customer_id=${(match as any).params.customerId}`}>Note</Dropdown.Item> */}
    </Dropdown>
    : null;
}

const NavigationNewDropDownMenu = () => {
  const history = useHistory();
  const { state, dispatch } = useViewport();
  const { showDrawer } = useContext(ApplicationContext);
  const { can, isRole } = usePrairieAuth();

  return can('customer:create', 'customer:list', 'document:create', 'document:list', 'work-order:create', 'work-order:list',
    'invoice:list', 'job:order', 'notification:list', 'note:create', 'storage-create',) ?
    <Dropdown title={isMobileOnly
      ? state.notificationCount > 0 ? <Badge content={state.notificationCount > 9 ? '9+' : state.notificationCount}><LegacyBriefcaseIcon /></Badge> : <LegacyBriefcaseIcon />
      : state.notificationCount > 0 ? <Badge content={state.notificationCount > 9 ? '9+' : state.notificationCount}><span>Workbook</span></Badge> : <span>Workbook</span>
    } noCaret={isMobileOnly}>
      {can('customer:create', 'document:create', 'work-order:create', 'note:create', 'storage-create') &&
        <Dropdown.Menu title={<strong>New</strong>} icon={<LegacyPlusIcon />}>
          {can('customer:create') &&
            <Dropdown.Item onSelect={() => showDrawer(DRAWER.CUSTOMER_FORM, {}, (data: any) => {
              history.push(`/app/${getEnv()}/workbook/explorer/${data.id}/profile`);
            })}>{INTL.CUSTOMER}</Dropdown.Item>
          }

          {(can('customer:create') && IS_KALADI_PROPERTIES) &&
            <Dropdown.Item onSelect={() => {
              const customerId = window.location.pathname.includes('/workbook/explorer') ? +window.location.pathname.split('/')[5] : undefined;
              showDrawer(DRAWER.TENANT_FORM, { action: 'add', customerId }, () => dispatch({ type: 'SET_MODIFIED_AT' }));
            }}>{INTL.TENANT}</Dropdown.Item>
          }

          {can('document:create') &&
            <Dropdown.Item onSelect={() => showDrawer(DRAWER.DOCUMENT_FORM)}>{INTL.DOCUMENT}</Dropdown.Item>
          }

          {can('work-order:create') &&
            <Dropdown.Item onSelect={() => showDrawer(DRAWER.WORK_ORDER_FORM, {}, () => dispatch({ type: 'SET_MODIFIED_AT' }))}>{INTL.WORK_ORDER}</Dropdown.Item>
          }

          {IS_KALADI_KITCHENS &&
            <Dropdown.Item can={['storage:create']} as={CustomLink} to={`/app/${getEnv()}/storage/add`}>Storage</Dropdown.Item>
          }
          {/* <Dropdown.Item
            onSelect={() => showDrawer(DRAWER.NOTE_FORM, { action: 'add' })}
            can={['note:create']}
            as={CustomLink} to={`/app/${getEnv()}/note/add`}>Note</Dropdown.Item> */}
        </Dropdown.Menu>
      }

      {can('customer:create', 'document:create', 'work-order:create', 'note:create', 'storage-create') &&
        <Dropdown.Separator />
      }

      <Dropdown.Item
        can={['customer:list']}
        as={CustomLink}
        to={`/app/${getEnv()}/workbook/customers`}
      >{INTL.CUSTOMERS}</Dropdown.Item>

      {IS_KALADI_PROPERTIES &&
        <Dropdown.Item
          can={['customer:list']}
          as={CustomLink}
          to={`/app/${getEnv()}/workbook/tenants`}
        >{INTL.TENANTS}</Dropdown.Item>
      }

      <Dropdown.Item
        can={['document:list']}
        as={CustomLink}
        to={isRole(ROLE.CLIENT, ROLE.CLEANER) ? `/app/${getEnv()}/workbook/explorer/${state?.profile?.customer?.id}/documents` : `/app/${getEnv()}/workbook/documents`}
      >{INTL.DOCUMENTS}</Dropdown.Item>
      <Dropdown.Item
        can={['work-order:list']}
        as={CustomLink}
        to={isRole(ROLE.CLIENT, ROLE.CLEANER) ? `/app/${getEnv()}/workbook/explorer/${state?.profile?.customer?.id}/work-orders` : `/app/${getEnv()}/workbook/work-orders/list`}
      >{INTL.WORK_ORDERS}</Dropdown.Item>
      {IS_KALADI_KITCHENS &&
        <Dropdown.Item can={['storage:list']} as={CustomLink} to={`/app/${getEnv()}/workbook/storage`}>Storage</Dropdown.Item>
      }
      <Dropdown.Item can={['invoice:list']} as={CustomLink} to={`/app/${getEnv()}/workbook/invoices`}>Invoices</Dropdown.Item>

      {(IS_KALADI_KITCHENS && isRole(ROLE.ADMIN, ROLE.MANAGER, ROLE.CLEANER)) &&
        <>
          <Dropdown.Separator />
          <Dropdown.Item as={Link} to={`/app/${getEnv()}/resourcing/clock-in-out`}>Clock In/Out</Dropdown.Item>
          {isRole(ROLE.ADMIN) &&
            <Dropdown.Item as={Link} to={`/app/${getEnv()}/resourcing/payroll`}>Payroll</Dropdown.Item>
          }
        </>
      }

      {!isRole(ROLE.CLIENT, ROLE.CLEANER) &&
        <Dropdown.Separator />
      }

      {!IS_KALADI_KITCHENS &&
        <Dropdown.Item can={['job:order']} as={CustomLink} to={`/app/${getEnv()}/workorder/order-list`}>Order List</Dropdown.Item>
      }

      {can('email-activity:list') &&
        <Dropdown.Item can={['email-activity:list']} as={CustomLink} to={`/app/${getEnv()}/workbook/email-activity`}>Email Activity</Dropdown.Item>
      }

      {can('notification:view') &&
        <Dropdown.Item can={['notification:view']} as={CustomLink} to={`/app/${getEnv()}/workbook/notifications`}>
          {state.notificationCount > 0
            ? <Badge content={state.notificationCount > 9 ? '9+' : state.notificationCount}><span>Notifications</span></Badge>
            : <span>Notifications</span>
          }
        </Dropdown.Item>
      }
    </Dropdown>
    : null;
}

export {
  NavigationNewDropdown,
  NavigationNewDropDownMenu
}
