import React, { Fragment, forwardRef } from 'react';
import { Whisper, Tooltip, Button, IconButton } from 'rsuite';
import { isBrowser } from 'react-device-detect';
// import { IconNames } from 'rsuite/lib/Icon';
// import { SVGIcon } from 'rsuite/lib/@types/common';
import { useHistory } from 'react-router-dom';
import { MdAdd, MdDelete, MdDownload, MdEdit, MdEmail, MdManageAccounts, MdMoreVert, MdQuestionMark, MdVisibility } from 'react-icons/md';

interface ICallToAction {
  text: string,
  tooltip: string,
  disabled?: boolean | undefined,
  icon: string,
  link: string,
  onClick?: () => void | undefined,
  useHistoryPush?: boolean | undefined
}

const CallToAction = forwardRef(({
  text,
  tooltip,
  disabled,
  icon,
  link,
  onClick
}: ICallToAction, ref: any) => {
  const history = useHistory();
  let mdIcon = <MdQuestionMark />

  if (disabled === undefined) {
    disabled = false;
  }

  if (onClick === undefined) {
    onClick = () => { history.push(link) };
  }

  switch (icon) {
    case 'eye':
    case 'view':
      mdIcon = <MdVisibility />;
      break;
    case 'edit2':
    case 'edit':
      mdIcon = <MdEdit />;
      break;
    case 'envelope':
    case 'email':
      mdIcon = <MdEmail />;
      break;
    case 'plus':
      mdIcon = <MdAdd />
      break;
    case 'trash':
      mdIcon = <MdDelete />;
      break;
    case 'ellipsis-v':
      mdIcon = <MdMoreVert />;
      break;
    case 'download':
      mdIcon = <MdDownload />;
      break;
    case 'user':
      mdIcon = <MdManageAccounts />;
      break;
    case 'columns':
      text = 'Detach';
      break;
    case 'copy':
      text = 'Clone';
      break;
    case 'search':
      text = 'Search';
      break;
    case 'undo':
    case 'folder-open':
      text = 'Restore';
      break;
  }

  return <>
    {isBrowser
      ? <>
        {disabled
          ? <Whisper placement="bottom" trigger="hover" speaker={<Tooltip>{tooltip}</Tooltip>}>
            <IconButton appearance="subtle" icon={mdIcon} />
          </Whisper>
          : <Whisper placement="bottom" trigger="hover" speaker={<Tooltip>{tooltip}</Tooltip>}>
            <IconButton onClick={onClick} appearance="link" icon={mdIcon} />
          </Whisper>
        }
      </>
      : <>
        {disabled
          ? <span>
            <IconButton appearance="subtle" onClick={onClick} icon={mdIcon} /> {text}
          </span>
          : <span>
            <IconButton appearance="link" onClick={onClick} icon={mdIcon} /> {text}
          </span>
        }
      </>
    }
  </>
});

export default CallToAction;
