import { gql, useApolloClient, useQuery } from '@apollo/client';
import { Textarea } from 'components/rsuite';
import { GET_CUSTOMER } from 'gql/customer';
import { getEnv } from 'lib/env';
import { useQueryString } from 'lib/hooks';
import { startCase } from 'lodash';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import {
  toaster,
  Message,
  Form,
  Loader,
  Panel,
  Radio
} from 'rsuite';
import { ApplicationContext, DrawerFormWrapper } from 'shared';

interface IICustomerMerge {
  drawer?: boolean,
  show?: boolean,
  guid?: any,
  action?: any,
  customerIds?: Array<string>,
  onHide?: () => void,
  onUpdate?: (data: any) => void
}

const GET_CUSTOMERS = gql`
  query customers($limit: Int!, $offset: Int!, $where: CustomersWhere, $sort: CustomersSort) {
    customers(filter: {
      limit: $limit
      offset: $offset
      where: $where
      sort: $sort
    }) {
      edges {
        node {
          id
          parentId
          guid
          customerName
          businessName
          customerAddress
          workAddress
          phone
          alternatePhone
          email
          postalCode
          displayAddress
          displayName
          label
          propertyManager
          createdAt
          modifiedAt
          contactDetails
        }
      },
      totalCount
    }
  }
`;

const CustomerMerge = ({ show, guid, action, drawer, customerIds, onHide, onUpdate }: IICustomerMerge) => {
  let form: any = useRef();
  const match = useRouteMatch();
  const query = useQueryString();
  const client = useApolloClient();
  const [customers, setCustomers] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [parentId, setParentId] = useState(undefined);
  const { showError, showSuccess } = useContext(ApplicationContext);

  guid = query?.guid || (match?.params as any)?.guid || guid;
  action = query?.action || action || (match?.params as any)?.action || 'add';

  useEffect(() => {
    (async function anyNameFunction() {
      setLoading(true);

      try {
        const data = await client.query({
          query: GET_CUSTOMERS,
          variables: {
            offset: 0,
            limit: 99,
            where: {
              guid: { in: customerIds }
            }
          },
          fetchPolicy: 'no-cache'
        });

        setCustomers(data.data.customers.edges.node);
      } catch (err) {
        console.log(err);
      }

      setLoading(false);
    })();
  }, [customerIds]);

  const handleSubmit = async (stay: boolean) => {
    console.log(parentId);
    setSaving(true);

    const mergeCustomer = gql`
        mutation mergeCustomer($parentGuid: ID!, $childrenGuid: [ID]!) {
          mergeCustomer(parentGuid: $parentGuid, childrenGuid: $childrenGuid) {
            success
            code
            message
            result
          }
        }
      `;

    try {
      const response: any = await client.mutate({
        mutation: mergeCustomer, variables: {
          parentGuid: parentId,
          childrenGuid: customerIds
        }
      });

      if (response.data.mergeCustomer.success) {
        showSuccess(response.data.mergeCustomer.message);

        onHide && onHide();
        onUpdate && onUpdate(response.data.mergeCustomer.result);
      } else {
        showError(response.data.mergeCustomer.message);
      }
    } catch (err) {
      showError(err);
    } finally {
      setSaving(false);      
    }
  }

  const Wrapper = show === undefined
    ? <div />
    : <DrawerFormWrapper
      loading={saving}
      show={show}
      title={startCase(action) + ' Customer'}
      guid={guid}
      size={'md'}
      action={action}
      form={`/app/${getEnv()}/irrigation-system/form/view/${guid}`}
      onHide={onHide}
      onSave={handleSubmit}
      saveDisabled={!parentId}
    />;

  return React.cloneElement(Wrapper, {
    children: <div>
      {!drawer &&
        <div></div>
      }

      <Panel className="content">
        {loading
          ? <Loader content="Loading..." />
          : <>
            <Message className='mb-16'>Select customer record to become parent record:</Message>
            {customers.map((row: any) => <div key={row.guid}>
              <Radio checked={row.guid === parentId} onClick={() => setParentId(row.guid)}>
                {row.displayName}<br />
                {row.displayAddress}{row.postalCode ?? `, ${row.postalCode}`}<br />
                {(row?.contactDetails || []).find((c: any) => c.primary)?.name || ''}<br />
                {(row?.contactDetails || []).find((c: any) => c.primary)?.phone || row.phone}<br />
                {(row?.contactDetails || []).find((c: any) => c.primary)?.email || row.email}
              </Radio>
            </div>)}
          </>
        }
      </Panel>
    </div>
  });
}

export default CustomerMerge;