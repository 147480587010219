import { useEffect, useState, useContext } from 'react';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import { useApolloClient } from '@apollo/client';
import { Table, DOMHelper, Panel, Col } from 'rsuite';
import { DRAWER, FORMAT, getEnv, getSortType, parseNumber, ROLE, setHeaderTitle } from 'lib/env';
import { Filter, Pagination, ApplicationContext, ResponsiveTable } from 'shared';
import { useFilteredParams } from 'shared/FilterProvider';
import { getFilter } from 'lib/helpers/filter';
import { orderBy } from 'lodash';
import { isMobileOnly } from 'react-device-detect';
import { parseISO, format } from 'date-fns';
import useLocalStorage from 'lib/hooks/useLocalStorage';
import { IS_BUGABOO, moneyFormatter } from 'lib/tenant';
import INTL from 'tenant/intl';
import { useViewport } from 'shared/ViewportProvider';
import { queryWorkOrdersGrouped } from 'gql/workOrders';
import { getCustomers, isBetween } from 'lib/helpers';
import { fieldTitle, filterFieldsByParentKey } from 'lib/helpers/field';
import { getSort } from 'lib/helpers/sort';
import { usePrairieAuth } from 'contexts/AuthPrairieProvider';
import { intervalsOverlap } from 'lib/helpers/date';

const { Column, HeaderCell, Cell } = Table;

const initialColumnEditing: { column: string | undefined, guid: string | undefined, value: any } = {
  column: undefined,
  guid: undefined,
  value: undefined
};

interface IWorkOrderCompare {
  headless?: boolean,
  customerId?: number,
  showBreadCrumb?: boolean,
  onSelectedRows?: (selectedRows: any) => void
}

const WorkOrderCompare = ({
  headless,
  customerId,
  showBreadCrumb,
  onSelectedRows
}: IWorkOrderCompare) => {
  setHeaderTitle('Work Orders Compare');
  const filterKey = 'workOrdersCompare' + (customerId === undefined ? '' : 'Customer');
  const match = useRouteMatch();
  const client = useApolloClient();
  const { state } = useViewport();
  const { showError, showConfirmation, showDrawer } = useContext(ApplicationContext);
  const { params } = useFilteredParams(filterKey);
  const { isRole, can } = usePrairieAuth();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [workOrderEvent, setWorkOrderEvent] = useState<any>({ action: undefined, guid: undefined });
  const [offset, setOffset] = useState(0);
  const [workOrders, setWorkOrders] = useState<any>([]);
  const [filter, setFilter] = useState(params);
  const [totalCount, setTotalCount] = useState(0);
  const [selectedTableRows, setSelectedTableRows] = useState<string[]>([]);
  const [showAssignWorkerModal, setShowAssignWorkerModal] = useState(false);
  const [selectedGlobalWorker, setSelectedGlobalWorker] = useState('');
  const [recordsPerPage, setRecordsPerPage] = useLocalStorage('recordsPerPage', 20);
  const [modifiedAt, setModifiedAt] = useState(new Date().toISOString());
  const [sort, setSort] = useLocalStorage<any>(`${filterKey}-sort`, { sortColumn: '', sortType: undefined });
  const workers = state.users.filter((u: any) => u.userlevel === 'worker');
  const fieldServices = filterFieldsByParentKey(state.fields, 'work_types').filter((w: any) => !w.visibleOnBooking);
  const bookingServices = filterFieldsByParentKey(state.fields, 'work_types').filter((w: any) => w.visibleOnBooking);
  const serviceKeys = filterFieldsByParentKey(state.fields, 'work_types').filter((w: any) => w.visibleOnBooking).map((w: any) => w.key);
  const workGroupTypes = filterFieldsByParentKey(state.fields, 'work_groups');

  // beta: inline edit
  const [columnEditing, setColumnEditing] = useState(initialColumnEditing);

  showBreadCrumb = typeof (showBreadCrumb) === 'undefined' ? true : false;

  useEffect(() => {
    window.document.addEventListener('keydown', escFunction, false);

    return () => {
      window.document.removeEventListener('keydown', escFunction, false);
    };
  }, []);

  useEffect(() => {
    if (onSelectedRows) {
      onSelectedRows(selectedTableRows);
    }
  }, [selectedTableRows]);

  useEffect(() => {
    (async function getWorkOrders() {
      try {
        setLoading(true);
        setWorkOrders([]);
        setSelectedTableRows([]);
        const whereFilter: any = getFilter(filterKey, filter, state.profile, 'compareFirstRange');

        if (customerId) {
          whereFilter.AND.splice(0, 1);
          const customers = await getCustomers(client, +(match as any).params.customerId);
          whereFilter.AND.push({ customerId: { in: customers } });
          whereFilter.AND.push({ childrenCount: { is: 0 } });
        }

        const data: any = await client.query({
          query: queryWorkOrdersGrouped,
          variables: {
            limit: customerId ? 999 : recordsPerPage,
            offset: offset,
            where: whereFilter,
            order: sort.sortType !== undefined ? [{ [sort.sortColumn]: sort.sortType.toUpperCase() }] : getSort('work-orders-compare')
          },
          fetchPolicy: 'no-cache'
        });

        if (customerId && !sort.sortType) {
          setWorkOrders(orderBy(data.data.workOrdersGrouped.edges.node, 'endDate', 'desc'));
        } else {
          setWorkOrders(data.data.workOrdersGrouped.edges.node);
        }

        setTotalCount(data.data.workOrdersGrouped.totalCount);
        DOMHelper.scrollTop(window as any, 0);
      } catch (err) {
        showError(err);
      }

      setLoading(false)
    })();
  }, [offset, filter, client, recordsPerPage, modifiedAt, sort]);

  const escFunction = (event: any) => {
    if (event.keyCode === 27) {
      setColumnEditing(initialColumnEditing);
    }
  }

  const filterByDate = (workOrders: any, params: any, compareRange: 'compareFirstRange' | 'compareSecondRange') => {
    let filteredWorkOrders = workOrders;
      
    if (params.compareDateColumn === 'startEndInclusive') {
      filteredWorkOrders = workOrders.filter((w: any) => intervalsOverlap(parseISO(w.startDate), parseISO(w.endDate), params[compareRange][0], params[compareRange][1]));
    } else {
      filteredWorkOrders = workOrders.filter((w: any) => isBetween(parseISO(w.startDate), params[compareRange][0], params[compareRange][1]));
    }

    filteredWorkOrders.sort((a: any, b: any) => (a.services < b.services) ? -1 : 1);
    return filteredWorkOrders;
  }

  const renderTable = (row: any, range: 'compareFirstRange' | 'compareSecondRange') => {
    return <table className="table">
      <thead>
        <tr>
          <th>Work Group</th>
          <th>Services</th>
          <th>Date</th>
          <th>Type Cost</th>
          <th>Total Cost</th>
        </tr>
      </thead>
      <tbody>
        {filterByDate(row.workOrders, params, range).map((workOrder: any) =>
          <tr key={workOrder.guid}>
            <td width="20%">
              <a href="/" onClick={(e: any) => {
                e.preventDefault();
                showDrawer(DRAWER.WORK_ORDER_FORM, { action: 'edit', workOrderGuid: workOrder.parentGuid }, () => setModifiedAt(new Date().toISOString()));
              }}>{workGroupTypes.find((w: any) => w.key === workOrder.applicationGroup)?.title}</a>
            </td>
            <td width="20%">
              <span>{fieldTitle((workOrder.services || []).filter((s: any) =>
                serviceKeys.includes(s)), state.fields).join(', ')}{workOrder.otherServices && ': ' + row.otherServices}</span>
            </td>
            <td width="20%">
              {format(parseISO(workOrder.startDate), FORMAT.MONTH_YEAR_SHORT)} - {format(parseISO(workOrder.endDate), FORMAT.MONTH_YEAR_SHORT)}
            </td>
            <td width="20%">
              <span>{moneyFormatter.format(parseNumber(workOrder.perCostTypeCost))}</span>
            </td>
            <td width="20%">
              <span>{moneyFormatter.format(parseNumber(row.totalCost))}</span>
            </td>
          </tr>
        )}
      </tbody>
    </table>;
  }

  return <div>
    {!isRole(ROLE.CLIENT) &&
      <Filter
        id={filterKey}
        showDateRangeFilter={false}
        showJobStatus={true}
        showMultipleCrewFilter={true}
        includeFilterByUnassignedCrew={true}
        showGroupFilter={!isRole(ROLE.CLIENT)}
        showCustomerLabelFilter={!isRole(ROLE.CLIENT) && IS_BUGABOO}
        onChange={(val) => {
          setFilter(val);
          setOffset(0);
        }}
        showRecurrenceFilter
      >
        <Col md={3} xs={24} style={{ float: 'right', textAlign: 'right' }}>
          {/* <Whisper placement="bottom" trigger="hover" speaker={<Tooltip>Columns</Tooltip>}>
            <IconButton
              size="sm"
              onClick={() => showDrawer(DRAWER.COLUMN_CHOOSER, {
                storageKey: filterKey
              })}
              appearance="link"
              icon={<Icon icon="columns" />}
            />
          </Whisper>

          <ExportField /> */}
        </Col>
      </Filter>
    }


    <Panel className={['content', isMobileOnly ? 'reset' : 'list'].join(' ')}>
      <ResponsiveTable
        key={selectedTableRows.join('_')}
        loading={loading}
        data={workOrders}
        html5
        filterKey={filterKey}
        sortColumn={sort.sortColumn}
        sortType={sort.sortType}
        onSortColumn={(sortColumn: string) => setSort({
          ...sort,
          sortColumn: getSortType(sort.sortType) === undefined ? undefined : sortColumn,
          sortType: getSortType(sort.sortType)
        })}
        rowClassName={(data: any) => data?.guid ? (data?.status || 'not_completed') : 'undefined'}
      >
        <Column width={40} sortable>
          <HeaderCell>{INTL.CUSTOMER}</HeaderCell>
          <Cell dataKey="customerName" data-column={!customerId ? 'Customer Name' : new Date().toISOString()}>
            {(row: any) => <Link to={`/app/${getEnv()}/workbook/explorer/${row.customerId}/work-orders`}
              onClick={(e: any) => { e.preventDefault(); history.push(`/app/${getEnv()}/workbook/explorer/${row.customerId}/work-orders`); }}
            >{row?.customer?.displayName}</Link>
            }
          </Cell>
        </Column>

        <Column flexGrow={1}>
          <HeaderCell>First Range ({format((params as any).compareFirstRange[0], FORMAT.MONTH_DATE)} - {format((params as any).compareFirstRange[1], FORMAT.MONTH_DATE)})</HeaderCell>
          <Cell>
            {(row: any) => renderTable(row, 'compareFirstRange')}
          </Cell>
        </Column>

        <Column flexGrow={1}>
          <HeaderCell>Second Range ({format((params as any).compareSecondRange[0], FORMAT.MONTH_DATE)} - {format((params as any).compareSecondRange[1], FORMAT.MONTH_DATE)})</HeaderCell>
          <Cell>
            {(row: any) => renderTable(row, 'compareSecondRange')}
          </Cell>
        </Column>
      </ResponsiveTable>
    </Panel>

    {!customerId &&
      <Pagination
        offset={offset}
        totalCount={totalCount}
        recordsPerPage={recordsPerPage}
        onChangeOffset={setOffset}
        onChangeLength={setRecordsPerPage}
      />
    }
  </div>;
};

export default WorkOrderCompare;
