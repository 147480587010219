import { gql } from "@apollo/client";

const GET_TENANTS = gql`
  query tenants($limit: Int!, $offset: Int!, $where: TenantsWhere, $order: [TenantsSort]) {
    tenants(filter:{
      limit:$limit
      where: $where
      offset: $offset
      order: $order
    }) {
      edges {
        node {
          guid
          customerId
          name
          phone
          email
          notes
          rents
          contacts
          customerId
          customerGuid
          deposit
          status
          property {
            displayAddress
          }
          document {
            id
            guid
          }
        }
      }
      totalCount
    }
  }
`;

const GET_TENANT = gql`
  query tenants($guid: ID!) {
    tenant(guid: $guid) {
      guid
      name
      phone
      email
      notes
      rents
      contacts
      customerId
      customerGuid
      deposit
      status
      property {
        displayAddress
      }
      attachments {
        guid
        fileName
        filePath
        fileId
        fileSize
        description
        visibility
      }
    }
  }
`;

export {
  GET_TENANT,
  GET_TENANTS
}
