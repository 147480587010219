import { useLogger } from 'lib/logger';
import { createContext, useState } from 'react';
import { Message, toaster } from 'rsuite';


interface IDrawerDataProps {
  /** Drawer to show */
  drawer?: string | undefined,

  /** Resources */
  customerId?: number,
  companyGuid?: string,
  customerGuid?: string,
  workOrderGuid?: string,
  documentGuid?: string,
  jobGuid?: string,
  jobGuids?: [string],
  dailyFinancialsGuid?: string,
  invoiceGuid?: string,
  storageGuid?: string,
  storageSpaceGuid?: string,
  fieldGuid?: string,
  actionGuid?: string,
  userGuid?: string,
  pnlGuid?: string,
  cashFlowGuid?: string,
  employeeGuid?: string,
  payrollGuid?: string,
  noteGuid?: string,
  timeOffGuid?: string,
  tenantGuid?: string,

  /** Resource */
  resource?: string,

  /** This could be any of the above, sometimes it's a generic request */
  resourceGuid?: string | string[],

  // Use this to send multiple emails, handle multiple operations in a drawer
  resources?: Array<any>[],

  /** Additional parameters mapped by extending these props */
  [key: string]: any
}

interface IDrawerProps extends IDrawerDataProps {
  action?: any,
  onHide?: () => void,
  onUpdate?: (data: any) => void,
}

interface ApplicationContextType {
  showNotice(response: any, operation?: string | undefined): any,
  showSuccess(val: any): void;
  showToaster(val: any, type?: string): void;
  showError(err?: any, val?: any | undefined): void;
  showMessage(message: any, title: string, size?: any): void;
  showConfirmation(message: any, title: string, callback: () => void): void;
  showConfirmationWithReason(message: any, title: string, callback: (reason?: string) => void): void;
  hideError(): void;
  errorIsVisible: boolean;
  reasonIsVisible: boolean;
  messageBody: any;
  messageTitle: string;
  buttons: Array<string>;
  size: any;
  confirmationCallback: (data: string) => void,
  showDrawer(drawer: any, data?: IDrawerDataProps, onUpdate?: (data: any) => void): void, // see env DRAWER types
  setReasonMessage(message: string): void,
  drawerData: IDrawerDataProps,
  onUpdateDrawerCallback: (data: any) => void
}

const ApplicationContext = createContext<ApplicationContextType>({
  showToaster: () => { },
  showNotice: () => { },
  showSuccess: () => { },
  showError: () => { },
  showMessage: () => { },
  showConfirmation: () => { },
  showConfirmationWithReason: () => { },
  hideError: () => { },
  errorIsVisible: false,
  messageBody: '',
  messageTitle: '',
  buttons: [],
  size: 'xs',
  confirmationCallback: () => { },
  showDrawer: () => { },
  setReasonMessage: (message: string) => { },
  drawerData: {},
  onUpdateDrawerCallback: (data: any) => { },
  reasonIsVisible: false
});

const ApplicationProvider = ({ children }: any) => {
  const logger = useLogger();
  const [errorIsVisible, setErrorIsVisible] = useState(false);
  const [reasonIsVisible, setReasonIsVisible] = useState(false);
  const [reason, setReason] = useState<any>('');
  const [messageBody, setMesageBody] = useState<any>('');
  const [messageTitle, setMessageTitle] = useState('');
  const [size, setSize] = useState<any>('xs');
  const [buttons, setButtons] = useState(['close']);
  const [confirmationCallback, setConfirmationCallback] = useState<any>(undefined);
  const [drawerData, setDrawerData] = useState<any>(undefined);
  const [onUpdateDrawerCallback, setOnUpdateDrawerCallback] = useState<any>(undefined);

  const showSuccess = (val: any) => {
    toaster.push(
      <Message type="success" showIcon closable>{val}</Message>
    );
  }

  const showNotice = (response: any, operation?: string | undefined): { success: boolean, operation: any } | undefined => {
    if (!response) {
      return undefined;
    }

    const notices = operation
      ? Array.isArray(response[operation]) ? response[operation] : [response[operation]]
      : Array.isArray(response) ? response : [response];

    for (let notice of notices) {
      if (notice?.hasOwnProperty('success')) {
        toaster.push(
          <Message type={notice?.success ? 'success' : 'error'} showIcon closable>
            <span dangerouslySetInnerHTML={{ __html: notice?.message.replace(/\|/g, '<br />') }} />
          </Message>
          , { duration: notice?.success ? 3000 : 5000 });
      }
    }

    return {
      success: (notices || []).filter((n: any) => !n.success).length === 0,
      operation: notices.find((n: any) => n.operation === operation)
    }
  }

  const showToaster = (val: any, type?: string) => {
    type = type || 'success';
    toaster.push(
      <Message type={type as any} showIcon closable>{val}</Message>
    );
  }

  const showError = (err?: any, msg?: any | undefined) => {
    if (err) {
      logger.error(err);
    }
    // if (err.response) {
    //   logger.error(err);
    // } else {
    //   logger.error({}, err);
    // }

    console.log(err);
    // <p><i>Stack Trace: {err.toString()}</i></p>
    setMesageBody(msg || <div><p>Application raised an error. Refresh and try again while administrators investigate the issue.</p></div>);
    setMessageTitle('Something went wrong');
    setErrorIsVisible(true);
    setButtons(['close', 'close_feedback']);
  }

  const showMessage = (message: any, title: string, size?: any) => {
    setMesageBody(message);
    setMessageTitle(title);
    setErrorIsVisible(true);
    setSize(size || 'xs');
    setButtons(['ok']);
  }

  const showConfirmation = (message: any, title: string, callback: () => void) => {
    setMesageBody(message);
    setMessageTitle(title);
    setErrorIsVisible(true);
    setReasonIsVisible(false);
    setButtons(['cancel', 'ok']);
    setConfirmationCallback(() => callback);
  }

  const showConfirmationWithReason = (message: any, title: string, callback: () => void) => {
    setMesageBody(message);
    setMessageTitle(title);
    setErrorIsVisible(true);
    setReasonIsVisible(true);
    setButtons(['cancel', 'ok']);
    setConfirmationCallback(() => callback);
  }

  const hideError = () => {
    setErrorIsVisible(false);
  }

  const setReasonMessage = (message: string) => {
    setReason(message);
  }


  const showDrawer = (drawer: string | undefined, data: any, onUpdate?: (data: any) => void) => {
    if (drawer === undefined) {
      setDrawerData({ drawer: undefined });
      setOnUpdateDrawerCallback(undefined);
      // history.replace(`${window.location.pathname}`);
    } else {
      setDrawerData({ drawer, ...data });
      // history.replace(`${window.location.pathname}?${queryString.stringify({ ...data })}`);
    }

    if (onUpdate !== undefined) {
      setOnUpdateDrawerCallback(() => onUpdate);
    }
  }

  return (
    <ApplicationContext.Provider value={{
      showSuccess,
      showNotice,
      showToaster,
      showError,
      showMessage,
      showConfirmation,
      showConfirmationWithReason,
      hideError,
      showDrawer,
      setReasonMessage,
      confirmationCallback,
      errorIsVisible,
      reasonIsVisible,
      messageBody,
      messageTitle,
      buttons,
      size,
      drawerData,
      onUpdateDrawerCallback
    }}>
      {children}
    </ApplicationContext.Provider>
  );
}

const ConditionalWrapper = ({ condition, wrapper, children }: any) => condition ? wrapper(children) : children;

export {
  ApplicationContext,
  ApplicationProvider,
  ConditionalWrapper
}

export type {
  IDrawerDataProps,
  IDrawerProps
}
