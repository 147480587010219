import { DRAWER } from 'lib/env';
import { useContext, useEffect, useState } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { Modal, Button, Input, Form } from 'rsuite';
import { ApplicationContext } from 'shared';

const Message = () => {
  const {
    errorIsVisible,
    messageBody,
    hideError,
    messageTitle,
    buttons,
    confirmationCallback,
    showDrawer,
    setReasonMessage,
    size,
    reasonIsVisible,
  } = useContext(ApplicationContext);
  const [reason, setReason] = useState<any>('');
  const [errorReasonRequired, setErrorReasonRequired] = useState(false);

  return (
    <Modal backdrop="static" open={errorIsVisible} onClose={hideError} size={size}>
      <Modal.Header><Modal.Title>{messageTitle}</Modal.Title></Modal.Header>
      <Modal.Body>
        {messageBody}
        {reasonIsVisible && <div className='mt-8'>
          <label className='required'>Reason:</label>
          <Input value={reason} onChange={setReason} />  
          {errorReasonRequired &&
            <span style={{ color: '#d9534f' }}>This field is required</span>
          }
        </div>}
      </Modal.Body>
      <Modal.Footer>
        {buttons.includes('ok') &&
          <Button size="sm" onClick={() => {
            if (confirmationCallback !== undefined && reasonIsVisible) {
              if (reason.trim().length === 0) {
                setErrorReasonRequired(true);
                return;
              } else {
                confirmationCallback(reason);
              }
            } else if(confirmationCallback !== undefined) {
              confirmationCallback('');
            }
            
            setErrorReasonRequired(false);
            hideError();
            setReason('');
          }} appearance="primary">OK</Button>
        }

        {buttons.includes('close_feedback') &&
          <Button size="sm" onClick={() => {
            hideError();
            setTimeout(() => showDrawer(DRAWER.FEEDBACK));
          }} appearance="primary">Report Issue</Button>
        }

        {buttons.includes('close') &&
          <Button size="sm" onClick={hideError} appearance={buttons.length === 1 ? 'primary' : 'subtle'}>Close</Button>
        }

        {buttons.includes('cancel') &&
          <Button size="sm" onClick={hideError} appearance={buttons.length === 1 ? 'primary' : 'subtle'}>Cancel</Button>
        }
      </Modal.Footer>
    </Modal>
  );
}

export {
  Message
}
