import { useEffect, useState } from 'react';
import { CheckPicker, Col, IconButton, Input, Radio, Row, Tooltip, Whisper } from 'rsuite';
import LegacyPlusIcon from "@rsuite/icons/legacy/Plus";
import LegacyTrashIcon from "@rsuite/icons/legacy/Trash";
import { SortableHandle } from 'react-sortable-hoc';
import { useViewport } from 'shared/ViewportProvider';

interface ICustomerFormContactDetailLineItem {
  guid: string,
  row: number,
  name: string,
  email: string,
  phone: string,
  ext: string,
  primary: boolean,
  status: string[],
  onBlur: (guid: string, name: string, email: string, phone: string, ext: string, status: string[]) => void,
  onAdd: () => void,
  onRemove: (guid: string) => void,
  onSetPrimary: (guid: string) => void
}

const DragHandle = SortableHandle(() => <span style={{ cursor: 'grab' }}>&nbsp;::</span>);

const CustomerFormContactDetailLineItem = ({
  row,
  guid,
  name,
  email,
  phone,
  ext,
  primary,
  status,
  onBlur,
  onAdd,
  onRemove,
  onSetPrimary
}: ICustomerFormContactDetailLineItem) => {
  const { state } = useViewport();
  const [formName, setFormName] = useState(name || '');
  const [formEmail, setFormEmail] = useState(email || '');
  const [formPhone, setFormPhone] = useState(phone || '');
  const [formExt, setFormExt] = useState(ext || '');
  const [formStatus, setFormStatus] = useState(status || []);

  const handleBlur = () => {
    onBlur(guid, formName, formEmail, formPhone, formExt, formStatus);
  }

  return (
    <Row className={'mb-5'}>
      <Col md={7}>
        <div style={{ display: 'inline-block', width: '9%' }}>
          <DragHandle />&nbsp;
        </div>
        <div style={{ display: 'inline-block', width: '91%' }}>
          <Input value={formName} onChange={setFormName} onBlur={handleBlur} tabIndex={0} />
        </div>
      </Col>
      <Col md={6}>
        <Input value={formEmail} onBlur={handleBlur} onChange={(val: string) => setFormEmail(val.trim())} tabIndex={0} />
      </Col>
      <Col md={4}>
        <Input value={formPhone} onBlur={handleBlur} onChange={setFormPhone} tabIndex={0} />
      </Col>
      <Col md={2}>
        <Input value={formExt} onBlur={handleBlur} onChange={setFormExt} tabIndex={0} />
      </Col>
      <Col md={2}>
        <CheckPicker
          searchable={false}
          block
          data={state.fields.filter((f: any) => f.parentKey === 'contact_status')}
          labelKey='title'
          valueKey='key'
          onSelect={(value: any) => {
            setFormStatus(value);
          }}
          value={formStatus}
          onClose={handleBlur}
        />
      </Col>
      <Col md={1}>
        <Radio checked={primary} value={'true'} onChange={() => onSetPrimary(guid)} />
      </Col>
      <Col md={2} className="pt-5">
        <Whisper placement="top" trigger="hover" speaker={<Tooltip>Add</Tooltip>}>
          <IconButton
            size="xs"
            appearance="link"
            icon={<LegacyPlusIcon />}
            onClick={onAdd}
          />
        </Whisper>
        <Whisper placement="top" trigger="hover" speaker={<Tooltip>Remove</Tooltip>}>
          <IconButton
            size="xs"
            appearance="link"
            icon={<LegacyTrashIcon />}
            onClick={() => onRemove(guid)}
          />
        </Whisper>
      </Col>
    </Row>
  );
}

export default CustomerFormContactDetailLineItem;
