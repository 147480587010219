import { useContext, useEffect, useRef, useState } from 'react';

import {
  Button,
  Checkbox,
  CheckboxGroup,
  Col,
  Drawer,
  Form,
  Grid,
  CheckPicker,
  Popover,
  Row,
  Schema,
  SelectPicker,
  Whisper,
  toaster,
  Message,
  Tooltip,
} from 'rsuite';

import LegacyHelpOIcon from "@rsuite/icons/legacy/HelpO";
import { SwatchesPicker } from 'react-color';
import startCase from 'lodash/startCase';
import orderBy from 'lodash/orderBy';
import { ApplicationContext } from 'shared';
import { isNil, omit, pick } from 'lodash';
import { isMobileOnly } from 'react-device-detect';
import { ACL_LIST, getEnv, hasQuickBooksDesktopIntegration, ROLE } from 'lib/env';
import { CONFIG_APPLICATION_GROUPS, CONFIG_APPLICATION_ROLES } from 'tenant/config';
import INTL from 'tenant/intl';
import { useViewport } from 'shared/ViewportProvider';
import { IS_ASSINIBOINE, IS_KALADI_KITCHENS } from 'lib/tenant';
import { gql, useApolloClient, useQuery } from '@apollo/client';
import { v4 as uuidv4 } from 'uuid';
import { GET_USER, GET_USERS } from 'gql/users';
import { GET_COMPANIES } from 'gql/companies';
import ResponsiveNav from '@rsuite/responsive-nav';
import { usePrairieAuth } from 'contexts/AuthPrairieProvider';
import { setUser } from '@sentry/react';
const { StringType, NumberType } = Schema.Types;

interface IUsersForm {
  guid: string,
  userlevel: string | undefined,
  onHide?: () => void,
  onSubmit?: (form: any) => void,
  onUpdate?: (data: any) => void
}

const UsersForm = ({
  guid,
  userlevel,
  onSubmit,
  onHide,
  onUpdate
}: IUsersForm) => {
  let form: any = useRef();
  const container = useRef() as React.MutableRefObject<HTMLDivElement>;
  const client = useApolloClient();
  const { state } = useViewport();
  const { isRole } = usePrairieAuth();
  const { showError } = useContext(ApplicationContext);
  const [formValue, setFormValue] = useState<any>({});
  const [formError, setFormError] = useState({});
  const user = useQuery(GET_USER, { variables: { guid: guid || 'd9b946c9-deb6-41be-9558-9bfd62538c89' } });
  const users = useQuery(GET_USERS);
  const companies = useQuery(GET_COMPANIES, { variables: { limit: 20, offset: 0 } });
  const [activeTab, setActiveTab] = useState<any>('profile');

  useEffect(() => {
    const prevUser: any = {
      ...omit(user?.data?.user || {}, 'password'),
      allowImpersonation: user?.data?.user?.allowImpersonation || [],
      accessGroup: user?.data?.user?.accessGroup || [],
      permissionsAdd: user?.data?.user?.permissionsAdd || [],
      permissionsRemove: user?.data?.user?.permissionsRemove || []
    };

    if (prevUser.accessGroup) {
      prevUser.accessGroup = prevUser.accessGroup.filter((a: any) => a && CONFIG_APPLICATION_GROUPS.map((c: any) => c.value).includes(a));
    }

    setFormValue({
      guid: uuidv4(),
      username: '',
      password: '',
      confirmPassword: '',
      showInDailyFinancialsEmail: false,
      hideUser: false,
      hideInSeasonalFinancials: false,
      companyId: state.profile.companyId,
      timeEntryDesktop: 'picker',
      timeEntryMobile: 'picker',
      userlevel: userlevel || 'franchise',
      allowImpersonation: [],
      color: undefined,
      accessGroup: [],
      permissionsAdd: [],
      permissionsRemove: [],
      contactName: '',
      operatorName: '',
      contactNumber: '',
      alternateContactNumber: '',
      contactEmail: '',
      pnlPercentage: '',
      userRoyaltyPercentage: '',
      address: '',
      city: '',
      province: '',
      postalCode: '',
      businessNumber: '',
      wcbNumber: '',
      sin: '',
      isLocked: false,
      workingUnderId: undefined,
      hourlyRate: '',
      quickbooksCode: '',
      ...prevUser
    });
  }, [user?.data?.user]);

  const addModel = Schema.Model({
    username: StringType()
      .addRule((value) => {
        if (value.search(/^[a-z0-9]*$/) === -1) {
          return false;
        }

        return true;
      }, 'Use lowercase alphabet and numbers only, no spaces or special characters')
      .addRule((value) => {
        return !(users?.data?.users?.edges?.node || []).map((u: any) => u.username).includes(value);
      }, 'This username is taken')
      .isRequired('This field is required'),
    password: StringType().isRequired('This field is required'),
    confirmPassword: StringType()
      .addRule((value, data) => {
        if (value !== data.password) {
          return false;
        }

        return true;
      }, 'The two passwords do not match')
      .isRequired('This field is required'),
    workingUnderId: NumberType()
      .addRule((value, data) => {
        if (data.userlevel === ROLE.WORKER && !value) {
          return false;
        }

        return true;
      }, 'This field is required for worker'),
    userlevel: StringType().isRequired('This field is required'),
    contactName: StringType().isRequired('This field is required'),
    contactNumber: StringType().isRequired('This field is required'),
    contactEmail: StringType().isRequired('This field is required'),
  });

  const editModel = Schema.Model({
    confirmPassword: StringType()
      .addRule((value, data) => {
        if (value !== data.password) {
          return false;
        }

        return true;
      }, 'The two passwords do not match'),
    userlevel: StringType().isRequired('This field is required'),
    contactName: StringType().isRequired('This field is required'),
    contactNumber: StringType().isRequired('This field is required'),
    contactEmail: StringType().isRequired('This field is required'),
    operatorName: StringType().isRequired('This field is required')
  });

  const handleSubmit = async (form: any) => {
    try {
      const upsertUser = gql`
        mutation upsertUser($input: UpsertUserInput!) {
          upsertUser(input: $input) {
            success
            code
            message
          }
        }
      `;

      let input: any = pick({ ...formValue, workGroup: formValue.accessGroup }, ['guid', 'accessGroup', 'username', 'password', 'operatorName', 'userlevel', 'contactName', 'contactNumber', 'contactEmail', 'alternateContactNumber', 'workGroup', 'isLocked', 'calendarColor', 'percentageOfNet', 'address', 'city', 'province', 'postalCode', 'businessNumber', 'wcbNumber', 'sin', 'companyId', 'workingUnderId', 'allowImpersonation', 'showInDailyFinancialsEmail', 'hideUser', 'hideInSeasonalFinancials', 'timeEntryDesktop', 'timeEntryMobile', 'color', 'pnlPercentage', 'userRoyaltyPercentage', 'hourlyRate', 'permissionsAdd', 'permissionsRemove', 'quickbooksCode']);

      ['percentageOfNet', 'pnlPercentage', 'userRoyaltyPercentage', 'hourlyRate'].forEach((k: string) => {
        if (!isNaN(input[k])) {
          input[k] = +input[k];
        } else {
          input[k] = undefined;
        }
      });

      const response: any = await client.mutate({
        mutation: upsertUser, variables: { input }
      });

      if (response.data.upsertUser.success) {
        toaster.push(
          <Message type="success" showIcon closable>{response.data.upsertUser.message}</Message>
        );

        if ((input.password || '').trim().length > 0) {
          location.href = location.href;
        }

        onUpdate && onUpdate({});

        if (onHide) {
          onHide();
        }
        if (onSubmit) {
          onSubmit(formValue);
        }
      } else {
        toaster.push(
          <Message type="error" showIcon closable>{response.data.upsertUser.message}</Message>
        );
      }
    } catch (err) {
      showError(err);
    }
  }

  return (
    <div>
      <Drawer
        open
        onClose={onHide}
        size={isMobileOnly ? 'xs' : 'md'}
        backdrop='static'
      >
        <Drawer.Header>
          <Drawer.Title>{user?.data?.user === null ? 'Add' : 'Edit'} User</Drawer.Title>
        </Drawer.Header>
        <Drawer.Body>
          <div ref={container}>
            <ResponsiveNav className='mb-8' appearance="subtle" activeKey={activeTab} onSelect={setActiveTab}>
              <ResponsiveNav.Item eventKey="profile">Profile</ResponsiveNav.Item>
              {(!IS_KALADI_KITCHENS && isRole(ROLE.ADMIN) && formValue.userlevel !== 'admin') &&
                <ResponsiveNav.Item eventKey="permissions">Permissions</ResponsiveNav.Item>
              }
            </ResponsiveNav>

            <Form
              ref={(ref: any) => form = ref}
              fluid
              model={isNil(formValue.guid) ? addModel : editModel}
              formValue={formValue}
              formError={formError}
              onChange={setFormValue}
              onCheck={setFormError}
            >

              <div className='mr-24'>
                {activeTab === 'profile' &&
                  <>
                    <fieldset>
                      {getEnv() === 'dev' &&
                        <Form.Group>
                          <Form.ControlLabel>UUID:</Form.ControlLabel>
                          <p>{formValue?.guid}</p>
                        </Form.Group>
                      }

                      <Form.Group>
                        <Grid fluid className="p-0">
                          <Row>
                            <Col xs={24} md={12}>
                              <Form.ControlLabel className="required">Username:</Form.ControlLabel>
                              <Form.Control name="username" />
                            </Col>

                            <Col xs={24} md={12}>
                              <Form.ControlLabel>
                                Impersonation:<span> </span>
                                <Whisper placement="top" speaker={<Tooltip>By default all crews can impersonate workers working under them.</Tooltip>}>
                                  <LegacyHelpOIcon />
                                </Whisper>
                              </Form.ControlLabel>
                              <Form.Control
                                block
                                searchable
                                container={() => container && container.current}
                                name="allowImpersonation"
                                accepter={CheckPicker}
                                data={orderBy((users?.data?.users?.edges?.node || []).filter((c: any) => c.id !== formValue?.id && ['franchise', 'worker'].includes(c.userlevel) && c.deletedAt === null)
                                  .map((c: any) => ({
                                    value: +c.id,
                                    label: c.operatorName,
                                    role: startCase(c.userlevel === 'franchise' ? 'crew' : c.userlevel)
                                  })
                                  ), 'label', 'asc')}
                                groupBy="role" />

                            </Col>
                          </Row>
                        </Grid>
                      </Form.Group>

                      <Form.Group>
                        <Grid fluid className="p-0">
                          <Row>
                            {!isNil(formValue.guid) &&
                              <Col xs={24} className="mb-10">
                                <Message type="info">Type in a new password only if you wish to change it. Changing the password will log you out of <strong>ALL</strong> existing sessions.</Message>
                              </Col>
                            }
                            <Col xs={24} md={12}>
                              <Form.ControlLabel className="required">Password:</Form.ControlLabel>
                              <Form.Control name="password" type="password" autoComplete="new-password" />
                            </Col>
                            <Col xs={24} md={12}>
                              <Form.ControlLabel className="required">Confirm Password:</Form.ControlLabel>
                              <Form.Control name="confirmPassword" type="password" autoComplete="new-password" />
                            </Col>
                          </Row>
                        </Grid>
                      </Form.Group>

                    </fieldset>
                    <fieldset>
                      <legend>Profile</legend>

                      <Form.Group>
                        <Grid fluid>
                          <Row>
                            <Col xs={24} md={formValue.userlevel === ROLE.WORKER ? 12 : 24}>
                              <Form.ControlLabel className="required">Role:</Form.ControlLabel>
                              <Form.Control
                                block
                                searchable={false}
                                cleanable={false}
                                name="userlevel"
                                accepter={SelectPicker}
                                container={() => container && container.current}
                                data={CONFIG_APPLICATION_ROLES} />
                            </Col>
                            {formValue.userlevel === ROLE.WORKER &&
                              <Col xs={24} md={12}>
                                <Form.ControlLabel className="required">Working Under:</Form.ControlLabel>
                                <Form.Control
                                  block
                                  name="workingUnderId"
                                  accepter={SelectPicker}
                                  data={users?.data?.users?.edges?.node || []}
                                  cleanable={false}
                                  valueKey="id"
                                  labelKey="operatorName" />
                              </Col>
                            }
                          </Row>
                        </Grid>
                      </Form.Group>

                      <Form.Group>
                        <div>
                          <Checkbox
                            name="isLocked"
                            value="true"
                            checked={formValue.isLocked}
                            onChange={() => setFormValue({ ...formValue, isLocked: !formValue.isLocked })}
                          >Lock User / Disable Login</Checkbox>
                          <Form.HelpText>Logged in users will be logged out.</Form.HelpText>
                        </div>

                        <div>
                          <Checkbox
                            name="hideUser"
                            value="true"
                            checked={formValue.hideUser}
                            onChange={() => setFormValue({ ...formValue, hideUser: !formValue.hideUser })}
                          >Hide in Owner / Operator Report</Checkbox>
                          <Form.HelpText>Admin and manager role can still view the user in report.</Form.HelpText>
                        </div>

                        <div>
                          <Checkbox
                            name="hideInSeasonalFinancials"
                            value="true"
                            checked={formValue.hideInSeasonalFinancials}
                            onChange={() => setFormValue({ ...formValue, hideInSeasonalFinancials: !formValue.hideInSeasonalFinancials })}
                          >Hide in Seasonal Financials</Checkbox>
                        </div>
                        <div>
                          <Checkbox
                            name="showInDailyFinancialsEmail"
                            value="true"
                            checked={formValue.showInDailyFinancialsEmail}
                            onChange={() => setFormValue({ ...formValue, showInDailyFinancialsEmail: !formValue.showInDailyFinancialsEmail })}
                          >Show In Daily Financials Email</Checkbox>
                          <Form.HelpText>Show user in daily report that is emailed out.</Form.HelpText>
                        </div>
                      </Form.Group>

                      {/*
                    <Form.Group>
                      <FormControl
                        accepter={CheckboxGroup}
                      >
                        <Checkbox>Prevent Look Ahead</Checkbox>
                      </FormControl>
                      <HelpBlock>Selecting this option will prevent this user from seeing work assigned past current day (christmas lights only).</HelpBlock>
                    </Form.Group>
                    */}

                      <Form.Group>
                        <Form.ControlLabel>Color:</Form.ControlLabel>
                        <Whisper placement="top" trigger="click" speaker={
                          <Popover className="p-0">
                            <SwatchesPicker
                              onChange={(color) => setFormValue({ ...formValue, color: color.hex })}
                            />
                          </Popover>
                        }>
                          <Button style={{ backgroundColor: formValue.color ?? 'transparent' }}>
                            {formValue.color ?? 'Select'}
                          </Button>
                        </Whisper>
                        <Form.HelpText>Used as a pin identifier on map display.</Form.HelpText>
                      </Form.Group>

                      <Form.Group>
                        <Form.ControlLabel className="required">Company:</Form.ControlLabel>
                        <Form.Control
                          block
                          cleanable={false}
                          searchable={false}
                          name="companyId"
                          accepter={SelectPicker}
                          data={(companies?.data?.companies?.edges?.node || []).map((c: any) => ({ value: c.id, label: c.name }))} />
                      </Form.Group>

                      <Form.Group>
                        <Form.ControlLabel>{INTL.WORK_GROUP}:</Form.ControlLabel>
                        <Form.Control inline accepter={CheckboxGroup} name="accessGroup">
                          {state.fields.filter((s: any) => s.parentKey === 'work_groups').map((s: any) =>
                            <Checkbox key={s.key} value={s.key}>{s.title}</Checkbox>
                          )}
                        </Form.Control>
                      </Form.Group>

                      <Form.Group>
                        <Grid fluid className="p-0">
                          <Row>
                            <Col xs={24} md={12}>
                              <Form.ControlLabel className="required">First and Last Name:</Form.ControlLabel>
                              <Form.Control name="contactName" />
                            </Col>
                            <Col xs={24} md={12}>
                              <Form.ControlLabel className="required">Crew Name:</Form.ControlLabel>
                              <Form.Control name="operatorName" />
                            </Col>
                          </Row>
                        </Grid>
                      </Form.Group>

                      <Form.Group>
                        <Grid fluid className="p-0">
                          <Row>
                            <Col xs={24} md={12}>
                              <Form.ControlLabel className="required">Phone Number:</Form.ControlLabel>
                              <Form.Control name="contactNumber" />
                            </Col>
                            <Col xs={24} md={12}>
                              <Form.ControlLabel>Alternate Phone Number:</Form.ControlLabel>
                              <Form.Control name="alternateContactNumber" />
                            </Col>
                          </Row>
                        </Grid>
                      </Form.Group>

                      <Form.Group>
                        <Form.ControlLabel className="required">Email Address:</Form.ControlLabel>
                        <Form.Control name="contactEmail" />
                      </Form.Group>
                    </fieldset>

                    <fieldset>
                      <legend>Contact Address</legend>
                      <Form.Group>
                        <Form.ControlLabel>Address</Form.ControlLabel>
                        <Form.Control name="address" />
                      </Form.Group>

                      <Form.Group>
                        <Grid fluid className="p-0">
                          <Row>
                            <Col xs={24} md={8}>
                              <Form.ControlLabel>City:</Form.ControlLabel>
                              <Form.Control name="city" />
                            </Col>
                            <Col xs={24} md={8}>
                              <Form.ControlLabel>Province:</Form.ControlLabel>
                              <Form.Control name="province" />
                            </Col>
                            <Col xs={24} md={8}>
                              <Form.ControlLabel>Postal Code:</Form.ControlLabel>
                              <Form.Control name="postalCode" />
                            </Col>
                          </Row>
                        </Grid>
                      </Form.Group>
                    </fieldset>

                    <fieldset>
                      <legend>Business Information</legend>
                      <Form.Group>
                        <Grid fluid className="p-0">
                          <Row>
                            <Col xs={24} md={8}>
                              <Form.ControlLabel>Business Number:</Form.ControlLabel>
                              <Form.Control name="businessNumber" />
                            </Col>
                            <Col xs={24} md={8}>
                              <Form.ControlLabel>WCB Number:</Form.ControlLabel>
                              <Form.Control name="wcbNumber" />
                            </Col>
                            <Col xs={24} md={8}>
                              <Form.ControlLabel>SIN:</Form.ControlLabel>
                              <Form.Control name="sin" />
                            </Col>
                          </Row>
                        </Grid>
                      </Form.Group>
                    </fieldset>

                    <fieldset>
                      <legend>Financials</legend>
                      <Form.Group>
                        <Grid fluid className="p-0">
                          <Row>
                            <Col xs={24} md={12}>
                              <Form.ControlLabel>P&amp;L Percentage:</Form.ControlLabel>
                              <Form.Control name="pnlPercentage" />
                              <Form.HelpText>Enter percentage, eg: 17. If no percentage is entered, default company percentage is applied (17%).</Form.HelpText>
                            </Col>
                            <Col xs={24} md={12}>
                              <Form.ControlLabel>Company Royalties:</Form.ControlLabel>
                              <Form.Control name="userRoyaltyPercentage" />
                              <Form.HelpText>If no percentage is entered, default company royalties are applied (17%).</Form.HelpText>
                            </Col>
                            {IS_ASSINIBOINE &&
                              <Col xs={24} md={12}>
                                <Form.ControlLabel>Payout Percentage:</Form.ControlLabel>
                                <Form.Control name="percentageOfNet" />
                                <Form.HelpText>Crew profit percentage calcuated on jobs.</Form.HelpText>
                              </Col>
                            }

                            <Col xs={24} md={12}>
                              <Form.ControlLabel>Hourly Rate:</Form.ControlLabel>
                              <Form.Control name="hourlyRate" />
                              <Form.HelpText>Enter if calculating payrol off hours worked.</Form.HelpText>
                            </Col>
                          </Row>
                        </Grid>
                      </Form.Group>
                    </fieldset>
                  </>
                }

                {hasQuickBooksDesktopIntegration(state.companies) &&
                  <fieldset>
                    <legend>QuickBooks</legend>
                    <Form.Group>
                      <Form.ControlLabel>Code:</Form.ControlLabel>
                      <Form.Control name="quickbooksCode" />
                    </Form.Group>
                  </fieldset>
                }

                {activeTab === 'permissions' &&
                  <div>
                    <Form.Group>
                      <Form.ControlLabel>Add Permissions:</Form.ControlLabel>
                      <Form.Control
                        name="permissionsAdd"
                        accepter={CheckPicker}
                        container={() => container && container.current}
                        block
                        style={{ display: 'block' }}
                        data={ACL_LIST.sort().map((a: string) => ({ label: a, value: a }))} />
                    </Form.Group>

                    <Form.Group>
                      <Form.ControlLabel>Remove Permissions:</Form.ControlLabel>
                      <Form.Control
                        name="permissionsRemove"
                        accepter={CheckPicker}
                        container={() => container && container.current}
                        block
                        style={{ display: 'block' }}
                        data={ACL_LIST.sort().map((a: string) => ({ label: a, value: a }))} />
                    </Form.Group>
                  </div>
                }
              </div>
            </Form>
          </div>
        </Drawer.Body>
        <Drawer.Footer>
          <Button onClick={() => {
            if (!form.check()) {
              toaster.push(
                <Message type="error" showIcon closable>Form has errors which need to be corrected</Message>
              );
              return;
            }

            handleSubmit(formValue);
          }} appearance="primary" size="sm">Save</Button>
          <Button onClick={() => {
            setFormError({});
            if (onHide) {
              onHide();
            }
          }} appearance="subtle" size="sm">Close</Button>
        </Drawer.Footer>
      </Drawer>
    </div>
  );
}

export {
  UsersForm
}
