import { gql, useApolloClient } from '@apollo/client';
import { parseISO } from 'date-fns';
import { v4 as uuidv4 } from 'uuid';
import { queryInvoice } from 'gql/invoices';
import { DRAWER, getEnv, hasXeroIntegration, parseNumber, ROLE } from 'lib/env';
import { Fragment, useEffect, useState, useContext, cloneElement, useRef } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import {
  Form,
  Panel,
  Grid,
  Row,
  Col,
  SelectPicker,
  ButtonToolbar,
  Button,
  toaster,
  Slider,
  Dropdown,
  DOMHelper,
  Checkbox,
  Loader,
  DatePicker,
  Message,
  IconButton,
  Input
} from 'rsuite';
import InvoiceFormLineItem from './InvoiceFormLineItem';
import { flatten, omit, pick, startCase } from 'lodash';
import TextareaAutosize from 'react-autosize-textarea';
import { ApplicationContext, DrawerFormWrapper } from 'shared';
import { useApi } from 'lib';
import { arrayMove, SortableContainer, SortableElement } from 'react-sortable-hoc';
import InvoiceLookupChristmasLightsCost from './InvoiceLookupChristmasLightsCost';
import { queryFields } from 'gql/fields';
import { IS_ASSINIBOINE, IS_GREYROCK, moneyFormatter } from 'lib/tenant';
import { InvoiceViewDrawer } from 'containers/invoice/InvoiceView';
import { isMobileOnly } from 'react-device-detect';
import queryString from 'query-string';
import ResponsiveNav from '@rsuite/responsive-nav';
import { useQueryString } from 'lib/hooks';
import LegacyUserIcon from "@rsuite/icons/legacy/User";
import { usePrairieAuth } from 'contexts/AuthPrairieProvider';
import { read } from 'fs';
import { useViewport } from 'shared/ViewportProvider';

interface IInvoiceForm {
  action?: any,
  guid?: any,
  customerId?: number,
  show?: boolean,
  title?: string,
  drawer?: boolean,
  onHide?: () => void,
  onUpdate?: (data: any) => void
}

const defaultFormItem = {
  guid: uuidv4(),
  description: '',
  cost: '',
  invoiceDate: new Date().toISOString(),
  additionalCost: '',
  deicer: '',
  measure: '',
  timeSpent: '',
  costGroup: 'custom',
  sortOrder: 0,
  updated: false,
};

const defaultForm = {
  applicationGroup: '',
  invoiceNumber: '',
  xeroInvoiceId: '',
  paymentStatus: 'not_paid',
  invoiceDate: new Date(),
  difficulty: 0,
  profitability: 0,
  publicComments: '',
  privateComments: '',
  updateWorkOrderTotalCost: false
};

const SortableItem = SortableElement((props: any) => <InvoiceFormLineItem {...props} />);

const SortableList = SortableContainer((children: any) => <Grid className={isMobileOnly ? 'p-0' : ''} fluid>{children.children}</Grid>);

const InvoiceForm = ({ guid, action, show, onHide, onUpdate, drawer, title }: IInvoiceForm) => {
  const queryParams: any = queryString.parse(window.location.search);
  let form: any;
  const container = useRef() as React.MutableRefObject<HTMLDivElement>;
  const history = useHistory();
  const api = useApi();
  const client = useApolloClient();
  const match: any = useRouteMatch();
  const { state } = useViewport();
  const { isRole } = usePrairieAuth();
  const { showError, showConfirmation, showDrawer, showNotice } = useContext(ApplicationContext);
  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formValue, setFormValue] = useState<any>(defaultForm);
  const [formError, setFormError] = useState({});
  const [invoice, setInvoice] = useState<any | undefined>(undefined);
  const [photos, setPhotos] = useState<any>([]);
  const [formItems, setFormItems] = useState<any>([]);
  const [activeTab, setActiveTab] = useState<any>('invoice');
  const [lookupItem, setLookupItem] = useState<any | undefined>(undefined);
  const [lookupProductCosts, setLookupProductCosts] = useState<any>([]);
  const [modifiedAt, setModifiedAt] = useState<string>(new Date().toISOString());
  const [subtotal, setSubTotal] = useState<number>(0);
  const [showInvoiceViewDrawer, setShowInvoiceViewDrawer] = useState<undefined | string>(undefined);
  const [updateWorkOrderTotalCost, setUpdateWorkOrderTotalCost] = useState(false);
  const [readOnly, setReadOnly] = useState(false);
  const query = useQueryString();

  guid = match?.params?.guid || query?.guid || guid;
  action = match?.params?.guid || query?.action || action || 'add';

  useEffect(() => {
    (async function getInvoice() {
      if (guid) {
        setLoading(true);

        const result = await client.query({ query: queryInvoice, variables: { guid }, fetchPolicy: 'no-cache' });
        const data = result.data.invoice;
        const fieldsResult: any = await client.query({ query: queryFields, variables: { offset: 0, limit: 999 } });
        const productCostId = fieldsResult.data.fields.edges.node.find((f: any) => f.parentId === 0 && f.key === 'product_pricing');

        if (productCostId) {
          setLookupProductCosts(fieldsResult.data.fields.edges.node.filter((f: any) => f.parentId === productCostId.id && f.isVisible));
        }

        setInvoice(data);
        setPhotos(flatten(([] as any).concat(data?.photos || []).concat((data?.items || []).map((i: any) => i.photos))));

        const nextFormItems: any = [].concat((data?.items || []).map((i: any) => {
          return omit(i, 'photos')
        }));

        const nextFormValue = {
          ...formValue,
          applicationGroup: data.applicationGroup,
          invoiceNumber: data.invoiceNumber,
          invoiceDate: parseISO(data.invoiceDate),
          paymentStatus: data.paymentStatus || 'not_paid',
          paymentType: data.paymentType,
          profitability: data.profitability,
          difficulty: data.difficulty,
          privateComments: data.privateComments,
          publicComments: data.publicComments,
          excludeContractValue: data.excludeContractValue,
          excludeWatermark: data.excludeWatermark,
          xeroInvoiceId: data.xeroInvoiceId
        };

        setReadOnly(data?.paymentStatus === 'paid' && !isRole(ROLE.ADMIN, ROLE.MANAGER));

        if (data.applicationGroup === 'christmas_lights') {
          nextFormItems.push({ ...defaultFormItem, guid: uuidv4(), costGroup: 'labour' });
          nextFormItems.push({ ...defaultFormItem, guid: uuidv4(), costGroup: 'product' });
          nextFormItems.push({ ...defaultFormItem, guid: uuidv4(), costGroup: 'other' });
        } else if (data.items.length === 0) {
          nextFormItems.push({ ...defaultFormItem, guid: uuidv4(), costGroup: 'custom', invoiceDate: new Date().toISOString() });
        }

        nextFormItems.push({ ...defaultFormItem, guid: uuidv4(), costGroup: 'revenue' });

        nextFormItems.forEach((item: any, index: number) => {
          item.sortOrder = index;
          item.description = item.description || item.formattedDescription || '';
          item.invoiceDate = parseISO(item.invoiceDate);
          item.measure = item?.props?.deicer_measure;
          item.deicer = item?.props?.de_icer;
          item.additionalCost = item?.additionalCost;
          item.timeSpent = item?.timeSpent;
          item.costType = item?.costType;
          item.updated = false;
        });

        setLoading(false);
        setFormValue(nextFormValue);
        setFormItems(nextFormItems);
        setSubTotal(data?.subtotal || 0);
        DOMHelper.scrollTop(window as any, 0);
      }
    })();
  }, [guid, modifiedAt]);

  const calculateSubtotal = (items: any) => {
    setSubTotal(items.reduce((p: number, n: any) => {
      if (n.costGroup === 'revenue') {
        return p;
      }

      const cost = n.costType === 'per_hour' ? n.cost * +n.timeSpent / 60.0 : n.cost;
      return p + parseNumber(cost) + parseNumber(n.additionalCost)
    }, 0));
  }

  const handleRemove = (guid: string, applicationGroup: string) => {
    const costGroups = applicationGroup === 'christmas_lights' ? ['labour', 'product', 'other', 'revenue'] : ['custom', 'revenue'];
    const nextFormItems: any = [].concat(formItems);
    const index = nextFormItems.findIndex((i: any) => i.guid === guid);
    nextFormItems.splice(index, 1);

    // make sure each cost type has one line item
    if (applicationGroup === 'christmas_lights') {
      const counts = nextFormItems.reduce((p: any, n: any) => {
        if (!p.includes(n.costGroup)) {
          p.push(n.costGroup);
        }
        return p;
      }, []);

      costGroups.forEach((c: any) => {
        if (!counts.includes(c)) {
          nextFormItems.push({ ...defaultFormItem, guid: uuidv4(), costGroup: c });
        }
      });
    } else if (nextFormItems.length === 0) {
      nextFormItems.push({ ...defaultFormItem, guid: uuidv4(), costGroup: 'custom' });
      nextFormItems.push({ ...defaultFormItem, guid: uuidv4(), costGroup: 'revenue' });
    }

    calculateSubtotal(nextFormItems);
    setFormItems(nextFormItems);
  }

  const handleAdd = (guid: string, costGroup: string) => {
    const nextFormItems: any = [].concat(formItems);
    const index = formItems.findIndex((i: any) => i.guid === guid);
    nextFormItems.splice(index + 1, 0, { ...defaultFormItem, guid: uuidv4(), costGroup });
    nextFormItems.forEach((item: any, index: number) => item.sortOrder = index);
    setFormItems(nextFormItems);
  }

  const handleUpdateDescription = (guid: string) => {
    const nextFormItems: any = formItems.map((f: any) => {
      if (f.guid === guid) {
        f.updated = true;
      }

      return f;
    });
    setFormItems(nextFormItems);
  }

  const handleUpdate = (guid: string, item: any, refresh?: boolean) => {
    const nextFormItems: any = formItems.map((f: any) => {
      if (f.guid === guid) {
        ['description', 'cost', 'invoiceDate', 'additionalCost', 'deicer', 'measure', 'timeSpent'].forEach((i: any) => {
          if (i === 'cost') {

            f[i] = +(item.hasOwnProperty(i) ? item[i] : f[i]).toString().replace(/,/g, '');
          } else {
            f[i] = item.hasOwnProperty(i) ? item[i] : f[i];
          }
        });
      }

      return f;
    });

    if (refresh) {
      setModifiedAt(new Date().toISOString());
    }

    calculateSubtotal(nextFormItems);
    setFormItems(nextFormItems);
  }

  const handleSubmit = async (stay: boolean) => {
    try {
      setSaving(true);
      const upsertInvoice = gql`
        mutation upsertInvoice($input: UpsertInvoiceInput!) {
          upsertInvoice(input: $input) {
            success
            code
            message
            result
            operation
          }
        }`;

      const response: any = await client.mutate({
        mutation: upsertInvoice, variables: {
          // the array is taken from the input list
          input: pick({
            ...formValue,
            guid,
            items: formItems.filter((i: any) => i.description.length > 0).map((i: any) => ({
              ...i,
              cost: +i.cost,
              additionalCost: +i.additionalCost,
              timeSpent: + i.timeSpent
            })).map((i: any) => {
              return pick(i, ['guid', 'itemType', 'costType', 'costGroup', 'description', 'cost', 'additionalCost', 'jobGuid', 'timeSpent', 'sortOrder', 'updated', 'invoiceDate'])
            })
          }, ['guid', 'items', 'applicationGroup', 'excludeContractValue', 'excludeWatermark', 'invoiceNumber', 'invoiceDate', 'paymentStatus', 'paymentType', 'difficulty', 'profitability', 'publicComments', 'privateComments', 'updateWorkOrderTotalCost', 'xeroInvoiceId'])
        }
      });

      const notice = showNotice(response.data, 'upsertInvoice');

      if (notice.operation.success) {
        if (drawer) {
          onUpdate && onUpdate(notice?.operation.result);
          onHide && onHide();
        } else if (stay) {
          setSubTotal(notice?.operation.result?.totalCost);
          history.push(query?.redirect);
        } else if (!stay) {
          history.push(query?.redirect);
        } else {
          setSaving(false);
        }
      } else {
        showError(response);
        setSaving(false);
      }
    } catch (err) {
      showError(err);
      setSaving(false);
    } finally {
      setSaving(false);
    }
  }

  const handleSortEnd = ({ oldIndex, newIndex }: any) => {
    const nextFormItems = arrayMove(formItems, oldIndex, newIndex).filter((s) => s).map((s: any, index: number) => {
      s.sortOrder = index;
      return s;
    });
    setFormItems(nextFormItems);
  };

  const handleLookup = (guid: string) => {
    setLookupItem(formItems.find((f: any) => f.guid === guid));
  }

  const handleImportWorkDescription = (costGroup: string) => {
    if (invoice.workOrders.length === 0) {
      toaster.push(
        <Message type="error" showIcon closable>No associated work orders to import</Message>
      );
      return;
    }

    const workDescription = invoice.workOrders[0].workDescription;
    showConfirmation(<div>
      Do you want to import everything in description for faster entry?
      <div className="mt-6" dangerouslySetInnerHTML={{
        __html: (workDescription || '').replace(/\n|\n\r/g, '<br />')
      }} />
    </div>, 'Import Work Description', () => {
      const nextFormItems: any = [].concat(formItems);
      workDescription.replace(/\n|\n\r/g, '<br />').split('<br />').filter((wd: string) => wd).forEach((wd: string) => {
        nextFormItems.push({ ...defaultFormItem, guid: uuidv4(), costGroup, description: wd });
      });

      nextFormItems.forEach((item: any, index: number) => item.sortOrder = index);
      setFormItems(nextFormItems);
    });

  }

  const resetSort = async (guid: string) => {
    const resetInvoiceSort = gql`
    mutation resetInvoiceSort($guid: ID!) {
      resetInvoiceSort(guid: $guid) {
        success
        code
        message
      }
    }`;

    await client.mutate({
      mutation: resetInvoiceSort, variables: {
        guid
      }
    });

    setModifiedAt(new Date().toISOString());
  }

  const getGroups = (applicationGroup: string) => {
    switch (applicationGroup) {
      case 'christmas_lights':
        return ['labour', 'product', 'other', 'revenue'];
      default:
        return ['invoice', 'revenue'];
    }
  }

  const Wrapper = show === undefined
    ? <div />
    : <DrawerFormWrapper
      saveDisabled={readOnly}
      loading={saving}
      show={show}
      title={startCase(action) + ' Invoice for ' + title}
      guid={guid}
      action={action || 'add'}
      form={`/app/${getEnv()}/invoice/${action}/${guid ? guid : ''}?${invoice?.customerId ? `customer_id=${invoice?.customerId}` : ''}`}
      onHide={onHide}
      onSave={handleSubmit}
    />;


  return cloneElement(Wrapper, {
    children: <div ref={container}>
      {(invoice && !drawer) &&
        <ButtonToolbar className="mb-8">
          <IconButton size="xs" appearance={"default"} icon={<LegacyUserIcon />}
            onClick={() => history.push(`/app/${getEnv()}/workbook/explorer/${invoice.customer.id}`)}>
            {invoice.customer?.displayName}
          </IconButton>
        </ButtonToolbar>
      }

      {invoice &&
        <ResponsiveNav appearance="subtle" activeKey={activeTab} onSelect={setActiveTab} className="mb-8">
          <ResponsiveNav.Item eventKey="invoice">Invoice</ResponsiveNav.Item>
          <ResponsiveNav.Item eventKey="photos">Photos ({photos.length})</ResponsiveNav.Item>
        </ResponsiveNav>
      }

      {activeTab === 'photos' &&
        <Panel className="content">
          {photos.length === 0
            ? <p>No photos uploaded</p>
            : <Grid fluid>
              <Row>
                {photos.map((p: any) =>
                  <div key={p.guid}>
                    {p.photoBefore &&
                      <Col md={6} sm={24}>
                        <a href={p.photoBefore} className="thumbnail" target="_blank">
                          <img src={p.photoBefore} style={{ backgroundRepeat: 'no-repeat', marginBottom: 0, height: '640px', width: 'auto', display: 'block' }} />
                          {p.descriptionBefore}
                        </a>
                      </Col>
                    }
                    {p.photoAfter &&
                      <Col md={6} sm={24}>
                        <a href={p.photoAfter} className="thumbnail" target="_blank">
                          <img src={p.photoAfter} style={{ backgroundRepeat: 'no-repeat', marginBottom: 0, height: '640px', width: 'auto', display: 'block' }} />
                          {p.descriptionAfter}
                        </a>
                      </Col>
                    }
                  </div>
                )}
              </Row>
            </Grid>
          }
        </Panel>
      }

      {activeTab === 'invoice' &&

        <Panel className={['content', isMobileOnly ? 'reset' : ''].join(' ')}>
          {loading
            ? <Loader content="Loading..." />
            : <Form
              fluid
              ref={(ref: any) => form = ref}
              formValue={formValue}
              formError={formError}
              onChange={setFormValue}
              onCheck={setFormError}
            >
              <Grid fluid>
                <Row className="rs-panel-card">
                  <Col md={10}>
                    {readOnly &&
                      <Message className='mb-16' type='info'>
                        This invoice has been marked as Paid and cannot be modified.
                      </Message>
                    }
                    {getEnv() === 'dev' &&
                      <>
                        <Form.Group>
                          <Form.ControlLabel>UUID:</Form.ControlLabel>
                          {invoice?.guid}
                        </Form.Group>

                        <Form.Group>
                          <Form.ControlLabel>Work Order/Job:</Form.ControlLabel>
                          {invoice?.workOrderGuid}
                        </Form.Group>

                        <Form.Group>
                          <Form.ControlLabel>Work Order Month:</Form.ControlLabel>
                          {invoice?.workOrderMonth}
                        </Form.Group>
                      </>
                    }

                    <Form.Group>
                      <Form.ControlLabel>Crew:</Form.ControlLabel>
                      <p>{invoice?.operatorName}</p>
                    </Form.Group>

                    <Form.Group>
                      <Form.ControlLabel>Invoice Number:</Form.ControlLabel>
                      <Form.Control name="invoiceNumber" readOnly={readOnly} />
                    </Form.Group>

                    <Form.Group>
                      <Form.ControlLabel>Invoice Date:</Form.ControlLabel>
                      <Form.Control
                        readOnly={readOnly}
                        block
                        container={() => container && container.current}
                        cleanable={false}
                        accepter={DatePicker}
                        name="invoiceDate"
                        format={'MMM dd, yyyy'} />
                      {/* {invoice?.workOrderMonth &&
                        <HelpBlock>{invoice.workOrderMonth}</HelpBlock>
                      } */}
                    </Form.Group>

                    <Form.Group>
                      <Form.ControlLabel>Payment Status:</Form.ControlLabel>
                      <Form.Control
                        block
                        name="paymentStatus"
                        readOnly={readOnly}
                        accepter={SelectPicker}
                        container={() => container && container.current}
                        searchable={false}
                        cleanable={false}
                        data={[
                          { label: 'Not Paid', value: 'not_paid' },
                          { label: 'Paid', value: 'paid' }
                        ]} />

                      <Checkbox
                        value="true"
                        readOnly={readOnly}
                        checked={formValue.excludeWatermark || false}
                        onChange={() => setFormValue({ ...formValue, excludeWatermark: !formValue.excludeWatermark })}
                      >Exclude Watermark</Checkbox>
                      <Form.HelpText>Select to remove payment status watermark</Form.HelpText>
                    </Form.Group>


                    <Form.Group>
                      <Form.ControlLabel>Payment Type:</Form.ControlLabel>
                      <Form.Control
                        container={() => container && container.current}
                        accepter={SelectPicker}
                        name="paymentType"
                        block
                        searchable={false}
                        readOnly={readOnly}
                        cleanable={false}
                        data={[
                          { label: 'Payment Not Collected', value: '' },
                          { label: 'Visa', value: 'visa' },
                          { label: 'MasterCard', value: 'mastercard' },
                          { label: 'American Express', value: 'americanexpress' },
                          { label: 'Cheque', value: 'cheque' },
                          { label: 'Cash', value: 'cash' }
                        ]} />
                    </Form.Group>

                    {invoice?.applicationGroup === 'christmas_lights' &&

                      <Fragment>
                        <Form.Group>
                          <Form.ControlLabel>Difficulty:</Form.ControlLabel>
                          <Slider
                            min={0}
                            step={0.5}
                            max={5}
                            graduated
                            readOnly={readOnly}
                            progress
                            value={formValue.difficulty}
                            renderMark={(mark: any) => mark.toString().indexOf('.5') > -1 ? '' : mark}
                            onChange={(difficulty) => setFormValue({ ...formValue, difficulty })}
                          />
                        </Form.Group>

                        <div className="mt-24">&nbsp;</div>

                        <Form.Group>
                          <Form.ControlLabel>Profitability:</Form.ControlLabel>
                          <Slider
                            min={0}
                            step={0.5}
                            max={5}
                            readOnly={readOnly}
                            graduated
                            progress
                            value={formValue.profitability}
                            renderMark={(mark: any) => mark.toString().indexOf('.5') > -1 ? '' : mark}
                            onChange={(profitability) => setFormValue({ ...formValue, profitability })}
                          />
                        </Form.Group>

                        <div className="mt-24">&nbsp;</div>
                      </Fragment>
                    }

                    <Form.Group>
                      <Form.ControlLabel>Public Comments:</Form.ControlLabel>
                      <Input
                        as={TextareaAutosize}
                        rows={2}
                        style={{ width: '100%' }}
                        readOnly={readOnly}
                        value={formValue.publicComments}
                        onChange={(val: string) => setFormValue({ ...formValue, publicComments: val })}
                      />
                    </Form.Group>

                    <Form.Group>
                      <Form.ControlLabel>Private Comments:</Form.ControlLabel>
                      <Input
                        as={TextareaAutosize}
                        rows={2}
                        style={{ width: '100%' }}
                        readOnly={readOnly}
                        value={formValue.privateComments}
                        onChange={(val: string) => setFormValue({ ...formValue, privateComments: val })}
                      />
                    </Form.Group>

                    <Form.Group>
                      <Form.ControlLabel>Contract notes:</Form.ControlLabel>
                      {invoice?.workOrders[0]?.contractNotes}
                    </Form.Group>

                    {(IS_GREYROCK || IS_ASSINIBOINE) &&
                      <Form.Group>
                        <Form.ControlLabel>Type of lights:</Form.ControlLabel>
                        {invoice?.workOrders[0]?.props?.typeOfLights}
                      </Form.Group>
                    }

                    <Form.Group>
                      <Form.ControlLabel>Work order description:</Form.ControlLabel>
                      <div dangerouslySetInnerHTML={{ __html: (invoice?.workOrders[0]?.workDescription || '').replace(/(?:\r\n|\r|\n)/g, '<br />') }} />
                    </Form.Group>

                    {['snow_removal', 'maintenance'].includes(invoice?.applicationGroup) &&
                      <Form.Group>
                        <Checkbox
                          value="true"
                          checked={formValue.excludeContractValue || false}
                          onChange={() => setFormValue({ ...formValue, excludeContractValue: !formValue.excludeContractValue })}
                        >Exclude Contract Value</Checkbox>
                      </Form.Group>
                    }
                  </Col>
                </Row>

                <Row className={isMobileOnly ? 'mt-12' : 'mt-12 p-0'}>
                  <Col xs={24} className={isMobileOnly ? 'p-0' : ''}>
                    {getGroups(formValue?.applicationGroup).map((o: any, index: number) =>
                      <Form.Group className="mt-24" key={`${o}-${modifiedAt}`}>
                        <Form.ControlLabel className="fieldset">
                          {startCase(o)} Items
                        </Form.ControlLabel>
                        {o === 'revenue' &&
                          <Message
                            type='info'
                            style={{ marginBottom: '12px' }}
                          >Revenue line items are intended to track receivables. Invoice subtotal will not be impacted.</Message>
                        }

                        <ButtonToolbar className="mb-8">
                          {(o === 'product' && formValue?.applicationGroup === 'christmas_lights') &&
                            <Button onClick={() => handleImportWorkDescription(o)} size="xs" disabled={readOnly}>Import Work Description</Button>
                          }
                          <Button onClick={() => resetSort(invoice.guid)} size="xs" disabled={readOnly}>Reset Sort</Button>
                        </ButtonToolbar>

                        {o !== 'revenue'
                          ? <SortableList
                            onSortEnd={handleSortEnd}
                            helperClass="sortableHelper"
                            useDragHandle
                          >
                            {formItems.filter((i: any) => i.costGroup !== 'revenue').filter((i: any) =>
                              (invoice.applicationGroup === 'christmas_lights' && i.costGroup === o) ||
                              (invoice.applicationGroup !== 'christmas_lights' && true)
                            )
                              .map((item: any, rowNumber: number) =>
                                <SortableItem
                                  {...item}
                                  rowNumber={rowNumber}
                                  index={item.sortOrder}
                                  applicationGroup={invoice.applicationGroup}
                                  key={item.guid}
                                  costGroup={item.costGroup}
                                  costType={item.costType}
                                  onAdd={handleAdd}
                                  onRemove={handleRemove}
                                  onChange={handleUpdate}
                                  onChangeDescription={handleUpdateDescription}
                                  onLookup={handleLookup}
                                  readOnly={readOnly}
                                />
                              )}
                          </SortableList>
                          : <SortableList
                            onSortEnd={handleSortEnd}
                            helperClass="sortableHelper"
                            useDragHandle
                          >
                            {formItems.filter((i: any) => i.costGroup === 'revenue')
                              .map((item: any, rowNumber: number) =>
                                <SortableItem
                                  {...item}
                                  rowNumber={rowNumber}
                                  index={item.sortOrder}
                                  applicationGroup={invoice.applicationGroup}
                                  key={item.guid}
                                  costGroup={item.costGroup}
                                  costType={item.costType}
                                  onAdd={handleAdd}
                                  onRemove={handleRemove}
                                  onChange={handleUpdate}
                                  onChangeDescription={handleUpdateDescription}
                                  onLookup={handleLookup}
                                  readOnly={readOnly}
                                />
                              )}
                          </SortableList>
                        }
                      </Form.Group>
                    )}
                  </Col>
                </Row>
                <Row className={['mt-24', isMobileOnly ? 'rs-panel-card' : ''].join(' ')}>
                  <Col md={4} xs={8} className="text-right"><strong>SUBTOTAL:</strong></Col>
                  <Col md={20} xs={16}><strong>{moneyFormatter.format(subtotal)}</strong></Col>

                  <Col md={4} xs={8} className="text-right"><strong>TAXES (GST 5%):</strong></Col>
                  <Col md={20} xs={16}><strong>{moneyFormatter.format(subtotal * 0.05)}</strong></Col>

                  <Col md={4} xs={8} className="text-right"><strong>TOTAL:</strong></Col>
                  <Col md={20} xs={16}><strong>{moneyFormatter.format(subtotal + (subtotal * 0.05))}</strong></Col>
                </Row>
              </Grid>

              <Form.Group>
                <Form.ControlLabel>Update Total Cost:</Form.ControlLabel>
                <Checkbox readOnly={readOnly} checked={updateWorkOrderTotalCost} onClick={(val) => {
                  setUpdateWorkOrderTotalCost(!updateWorkOrderTotalCost);
                  setFormValue({ ...formValue, updateWorkOrderTotalCost: !updateWorkOrderTotalCost });
                }}>Update Work Order, Total Cost</Checkbox>
                <Form.HelpText>When updating invoice update invoice total to accurately capture costs in the future</Form.HelpText>
              </Form.Group>

              {(hasXeroIntegration(state.companies) && isRole(ROLE.MANAGER, ROLE.ADMIN)) &&
                <Grid fluid className={'p-0'}>
                  <Row>
                    <Col md={10}>
                      <Form.Group className={'mt-24'}>
                        <Form.ControlLabel>Xero Invoice Id:</Form.ControlLabel>
                        <Form.Control name="xeroInvoiceId" />
                        <Form.HelpText>Clear the Xero invoice ID to generate a new one. Proceed with caution as it will break the previous invoice reference.</Form.HelpText>
                      </Form.Group>
                    </Col>
                  </Row>
                </Grid>
              }

              {!drawer &&
                <ButtonToolbar className="mt-24">
                  <Button size="sm" appearance="primary" loading={saving} onClick={() => handleSubmit(false)}>Save</Button>
                  <Button size="sm" appearance="ghost" loading={saving} onClick={() => handleSubmit(true)}>Save &amp; Stay</Button>
                  <Dropdown size="sm" title="More..." placement="topStart">
                    <Dropdown.Item onSelect={() => setShowInvoiceViewDrawer(invoice.guid)}>View</Dropdown.Item>
                    <Dropdown.Item onSelect={async () => {
                      const link = await api.download(`/invoices/download/${invoice.guid}/pdf`);
                      window.location.href = link;
                    }}>Download</Dropdown.Item>
                    <Dropdown.Item onSelect={() =>
                      showDrawer(DRAWER.WORK_ORDER_FORM, { workOrderGuid: invoice.workOrderGuid, action: 'edit' }, () => setModifiedAt(new Date().toISOString()))
                    }>Work Order</Dropdown.Item>
                    <Dropdown.Item onSelect={() =>
                      showDrawer(DRAWER.JOB_LIST, { workOrderGuid: invoice.workOrderGuid }, () => setModifiedAt(new Date().toISOString()))
                    }>Jobs</Dropdown.Item>
                    <Dropdown.Item onSelect={() => resetSort(invoice.guid)}>Reset Sort</Dropdown.Item>
                  </Dropdown>
                </ButtonToolbar>
              }
            </Form>
          }
        </Panel>
      }

      <InvoiceLookupChristmasLightsCost
        {...lookupItem}
        show={lookupItem !== undefined}
        productCosts={lookupProductCosts}
        onApply={handleUpdate}
        onHide={() => setLookupItem(undefined)}
        key={uuidv4()}
      />


      <InvoiceViewDrawer
        show={showInvoiceViewDrawer !== undefined}
        title={showInvoiceViewDrawer && invoice?.customer?.displayName}
        guid={showInvoiceViewDrawer}
        onHide={() => setShowInvoiceViewDrawer(undefined)}
      />
    </div>
  });
}

export default InvoiceForm;
