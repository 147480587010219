import ReactDOM from 'react-dom';
import App from './app';
import 'styles/base.less';
import * as serviceWorker from './serviceWorker';

if (document.getElementById('root') !== null) {
  ReactDOM.render(<App />, document.getElementById('root'));
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
