import { useState, useEffect, Fragment } from 'react';
import { Drawer, Button, Dropdown, ButtonToolbar } from 'rsuite';
import { useApolloClient, gql } from '@apollo/client';
import { getEnv, ROLE } from 'lib/env';
import { isMobileOnly } from 'react-device-detect';
import { Link, useHistory } from 'react-router-dom';
import WorkbookCustomer from 'containers/workbook/WorkbookCustomer';
import { queryWorkOrder } from 'gql/workOrders';
import { useApi } from 'lib';
import { usePrairieAuth } from 'contexts/AuthPrairieProvider';

const GET_SEARCH = gql`
  query search($limit: Int, $where: SearchResultsWhere) {
    search(filter: {
      limit: $limit
      where: $where
    }) {
      edges {
        node {

          id
          guid
          customerName
          businessName
          customerAddress
          postalCode
          phone
          alternatePhone
          customerId
          entryType
          applicationGroup
          paymentStatus
          workOrderMonth
          startDate
          endDate
          customerEmail
          resourceGroup
          customerEmail
          documentId
          customerComments
        }
      }
    }
  }
`;

interface ICustomerSummary {
  show: boolean,
  customerId: number | undefined,
  workOrderGuid?: string | undefined,
  jobGuid?: string | undefined,
  handleSummaryHide: () => void
  handleShowDrawer?: (jobId: string, type: string) => void,
  applicationGroup?: string | undefined,
  handleWorkOrderSelect?: (guid: string | undefined, startEndDate: [Date, Date] | undefined) => void
}

const CustomerSummary = ({
  show,
  customerId,
  workOrderGuid,
  jobGuid,
  handleSummaryHide,
  handleShowDrawer,
  handleWorkOrderSelect
}: ICustomerSummary) => {
  const history = useHistory();
    const api = useApi();
  const client = useApolloClient();
  const { isRole } = usePrairieAuth();
  const [loading, setLoading] = useState(true);
  const [record, setRecord] = useState<any | undefined>(undefined);
  const [workOrder, setWorkOrder] = useState<any>(undefined);

  useEffect(() => {
    (async function getWorkOrders() {
      if (workOrderGuid) {
        const result = await client.query({ query: queryWorkOrder, variables: { guid: workOrderGuid } });
        setWorkOrder(result.data.workOrder);
      }
    })();
  }, [workOrderGuid]);

  useEffect(() => {
    (async function getWorkOrders() {
      try {
        if (customerId) {
          setLoading(true);

          const data = await client.query({
            query: GET_SEARCH,
            variables: {
              limit: 20,
              where: {
                OR: [
                  {
                    customerId: { is: customerId }
                  }
                ]
              }
            },
            fetchPolicy: 'no-cache'
          });

          const workOrder = data.data.search.edges.node.find((n: any) => n.resourceGroup === 'work_order' && n.guid === workOrderGuid);
          const document = workOrder
            ? data.data.search.edges.node.find((n: any) => n.resourceGroup === 'document' && +n.id === workOrder.documentId)
            : null;

          setRecord({
            customer: data.data.search.edges.node.find((n: any) => n.resourceGroup === 'customer'),
            workOrder: workOrder,
            document: document
          } as any);

          setLoading(false)
        }
      } catch (err) {
        console.log(err);
      }
    })();
  }, [customerId]);

  return (
    <Drawer
      open={show}
      size={'md'}
      full={isMobileOnly}
      onClose={() => {
        handleSummaryHide();
      }}
    >
      <Drawer.Header>
        <Drawer.Title>View Customer</Drawer.Title>
      </Drawer.Header>
      <Drawer.Body>
        {record &&
          <Fragment>
            <WorkbookCustomer customerId={customerId} />
          </Fragment>
        }
      </Drawer.Body>
      <Drawer.Footer>
        <ButtonToolbar>
          <Button as={Link} target="_blank" appearance="primary" to={`/app/${getEnv()}/workbook/explorer/${customerId}`}>View Profile</Button>
          {(workOrder && isRole(ROLE.ADMIN, ROLE.MANAGER, ROLE.FRANCHISE)) &&
            <Dropdown title="More..." placement="topStart">
              {(workOrder && workOrder.documentGuid)
                && <Dropdown.Item onSelect={() => window.location.href = `${api.baseUrl}/documents/download/${workOrder.documentGuid}`}>Download Document</Dropdown.Item>
              }
              {(workOrder && workOrder.applicationGroup === 'construction')
                && <Dropdown.Item onSelect={() => history.push(`/app/${getEnv()}/worklog/edit/${workOrder.guid}`)}>Work Log</Dropdown.Item>
              }
              {(workOrder && handleWorkOrderSelect) &&
                <Dropdown.Item onSelect={() => {
                  handleSummaryHide();
                  handleWorkOrderSelect(workOrder.parentGuid || workOrder.guid, undefined);
                }}>Work Order</Dropdown.Item>
              }
            </Dropdown>
          }
          <Button onClick={() => {
            handleSummaryHide();
          }} appearance="subtle">Close</Button>
        </ButtonToolbar>
      </Drawer.Footer>
    </Drawer>
  );
}

export default CustomerSummary;
