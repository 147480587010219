import { useContext, useEffect, useState } from 'react';

import {
  Modal,
  Input,
  Form,
  Button,
  Checkbox,
  Whisper,
  Tooltip,
  toaster,
  Message,
  Loader,
} from 'rsuite';

import LegacyQuestionCircleIcon from "@rsuite/icons/legacy/QuestionCircle";
import SignatureCanvas from 'react-signature-canvas';
import { gql } from 'apollo-boost';
import { useApolloClient } from '@apollo/client';
import { ApplicationContext } from 'shared';
import { isMobileOnly } from 'react-device-detect';
import { IS_GREYROCK } from 'lib/tenant';

interface ISignature {
  show: boolean,
  customer?: any,
  onHide: () => void,
  handleSave?: () => void,
  saving?: boolean,
  onApproveDocument: (document: any) => void,
  document: any,
}

const ClientRepresentativeSignature = ({
  show,
  customer,
  document,
  saving,
  onHide,
  onApproveDocument,
  handleSave
}: ISignature) => {
  const client = useApolloClient();
  let sigCanvas: SignatureCanvas | null = null;
  const { showError } = useContext(ApplicationContext);
  const [contractSigned, setContractSigned] = useState(false);
  const [fullName, setFullName] = useState('');
  const [comments, setComments] = useState('');
  const [selectedEmails, setSelectedEmails] = useState<string[]>([]);
  const [currentDocument, setCurrentDocument] = useState<any>(undefined);
  const [loading, setLoading] = useState(false);
  const [jobTitle, setJobTitle] = useState('');

  useEffect(() => {
    setCurrentDocument(document);
    if (customer) {
      setSelectedEmails(customer?.email?.split(';').filter((e: string) => e));
    }
  }, [document, customer]);

  const handleEmailSelect = (val: any) => {
    if (selectedEmails.includes(val)) {
      const tmp = [...selectedEmails];
      tmp.splice(selectedEmails.indexOf(val), 1);
      // console.log(tmp);
      setSelectedEmails(tmp);
    } else {
      setSelectedEmails([...selectedEmails, val]);
    }
  }

  const handleApproveDocument = async (signature: string, fullName: string, comments: string) => {
    try {
      setLoading(true);
      let response: any | undefined = undefined;

      if (customer) {
        // this is the representative signing
        response = await client.mutate({
          mutation: gql`
            mutation approveDocument(
              $guid: ID!
              $signature: String
              $fullName: String
              $comments: String
              $type: String!
              $emails: [String]
              $jobTitle: String
            ) {
              approveDocument(
                guid: $guid
                signature: $signature
                fullName: $fullName,
                comments: $comments
                type: $type,
                emails: $emails
                jobTitle: $jobTitle
              ) {
                success
                code
                message
              }
            }
          `,
          variables: { guid: document.guid, signature, fullName, comments, type: 'representative', emails: selectedEmails, jobTitle }
        });
      } else {
        // this is the customer signing
        response = await client.mutate({
          mutation: gql`
            mutation approveDocument(
              $guid: ID!
              $signature: String
              $fullName: String,
              $comments: String
              $type: String!
              $jobTitle: String
            ) {
              approveDocument(
                guid: $guid
                signature: $signature
                fullName: $fullName,
                comments: $comments
                type: $type
                jobTitle: $jobTitle
              ) {
                success
                code
                message
              }
            }
          `,
          variables: { guid: currentDocument.guid, signature, fullName, comments, type: 'client', jobTitle }
        });
      }

      if (response.data.approveDocument.success) {
        onApproveDocument({
          ...currentDocument,
          approvalStatus: 'approved',
          signature,
          fullName,
          comments
        });
        toaster.push(
          <Message type="success" showIcon closable>{response.data.approveDocument.message}</Message>
        );
      } else {
        showError(response.data.approveDocument.message);
      }
    } catch (err) {
      showError(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal open={show} size={isMobileOnly ? 'xs' : 'sm'} onClose={onHide}>
      <Modal.Header><Modal.Title>Approve &amp; Sign</Modal.Title></Modal.Header>
      <Modal.Body>
        {(customer && handleSave) &&
          <Message type='info' className={'mb-12'}>
            Make sure to save all changes before signing the document. Signed documents emailed to clients will contain saved details.<br />
            {saving ? <Loader /> : <a href="#" onClick={(e: any) => { e.preventDefault(); handleSave(); }}>Save Now</a>}
          </Message>
        }
        <div className="mb-5">
          <p>All fields are required to completed approval.</p>
        </div>
        <div className="mb-5">
          <label>Full name:</label>
          <Input
            disabled={loading}
            type="text"
            value={fullName}
            onChange={setFullName}
          />
        </div>
        {IS_GREYROCK &&
          <div className="mb-5">
            <label>Title:</label>
            <Input
              disabled={loading}
              type="text"
              value={jobTitle}
              onChange={setJobTitle}
            />
          </div>
        }
        <div className="mb-5">
          <label>Comments:</label>
          <Input
            disabled={loading}
            as="textarea"
            rows={2}
            style={{ width: '100%' }}
            value={comments}
            onChange={setComments}
          />
        </div>
        <div>
          <label>Signature:</label>
          <SignatureCanvas
            ref={(ref) => { sigCanvas = ref }}
            penColor='blue'
            canvasProps={{ width: 555, height: 200, className: 'sigCanvas', style: { border: 'solid 1px #ccc' } }}
            onEnd={() => {
              setContractSigned(true);
            }}
          />
          <Form.HelpText>Draw your signature in the provided space. Any signatures/initals found as not legible will be removed and a new signature will be required.</Form.HelpText>
        </div>
        {customer?.email &&
          <div className="mt-5">
            <label>
              Email following clients signed documents:{' '}
              <Whisper placement="top" speaker={<Tooltip>Template: Document Representative Approved</Tooltip>}><LegacyQuestionCircleIcon /></Whisper>
            </label>
            <div>
              {customer?.email.split(';').filter((e: string) => e).map((e: string, index: number) => <div key={`${e}-${index}`}>
                <Checkbox checked={selectedEmails.indexOf(e) > -1} value={e} onChange={handleEmailSelect}>{e}</Checkbox>
              </div>
              )}
            </div>
          </div>
        }
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => {
          if (sigCanvas) {
            setContractSigned(false);
            sigCanvas.clear();
          }
        }} appearance="subtle">Clear Signature</Button>
        <Button
          loading={loading}
          disabled={!contractSigned || fullName.length === 0}
          onClick={() => {
            if (!sigCanvas?.isEmpty()) {
              const canvas = sigCanvas?.getTrimmedCanvas();
              handleApproveDocument((canvas as any).toDataURL(), fullName, comments);
            }
          }} appearance="primary">Finish</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ClientRepresentativeSignature;
