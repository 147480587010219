import { useState } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { Button, ButtonGroup, Dropdown, Tabs } from 'rsuite';

const TabExtended = (props: any): any => {
  const [tabActiveKey, setTabActiveKey] = useState(props?.children?.[0]?.props?.eventKey);
  const [tabActiveTitle, setTabActiveTitle] = useState(props?.children?.[0]?.props?.title);

  return (<>{isMobileOnly
    ? <div>
      <div className='mb-16 tab-mobile'>
        <ButtonGroup>
          <Button size="sm">{tabActiveTitle}</Button>
          <Dropdown title={'More'} appearance='default' size="sm">
            {(props?.children || []).map((c: any, index: number) =>
              <Dropdown.Item onClick={() => {
                setTabActiveKey(c?.props?.eventKey);
                setTabActiveTitle(c?.props?.title);
              }} key={`key-${index}-${c?.props?.eventKey}`}>{c?.props?.title}</Dropdown.Item>
            )}
          </Dropdown>
        </ButtonGroup>
      </div>

      {props?.children.find((c: any) => c?.props?.eventKey === tabActiveKey)?.props?.children}
    </div>
    : <Tabs
      activeKey={tabActiveKey}
      onSelect={
        (eventKey: any, event: any) => {
          setTabActiveKey(eventKey);
        }
      }
      {...props
      }
    >
      {props.children}
    </Tabs>
  }
  </>);
}

TabExtended.Tab = Tabs.Tab;

export {
  TabExtended
}
