import { parseISO } from 'lib/date';
import queryString from 'query-string';
import store from 'lib/storage';

const useFilteredParams = (
  id: string
) => {
  const queryParams: any = queryString.parse(window.location.search);
  let date: Date = new Date();
  let range: [Date?, Date?] = [new Date(), new Date()];
  let activeRange: [Date?, Date?] = [];
  let applications: string[] = [];
  let jobs: string[] = [];
  let crew: number[] = [];
  let jobStatus = '';
  let workOrderStatus = '';
  let documentType = '';
  let documentStatus: string[] = [];
  let documentTemplate: number[] = [];
  let trackingFlag: string[] = [];
  let paymentStatus = '';
  let text = '';
  let rangeColumn = '';
  let listType = '';
  let calendarView = 'auto';
  let recurrence = '';
  let emailEvent = '';
  let emailEventType = '';
  let endingPeriod = '';
  let userRole = '';
  let customerLabel = '';
  let template = '';
  let siteReviewStatus = '';
  let completedBy: number[] = [];
  let compareFirstRange: [Date?, Date?] = [new Date(), new Date()];
  let compareSecondRange: [Date?, Date?] = [new Date(), new Date()];
  let compareDateColumn: string = 'startDate';
  let squareFootage: string[] = [];

  if (queryParams.date) {
    date = parseISO(queryParams.date);
  } else if (store.session.get(`${id}-date-filter`) !== null) {
    try {
      const storeDate = store.session.get(`${id}-date-filter`);
      date = parseISO(storeDate);
    } catch {
      store.session.remove(`${id}-date-filter`);
    }
  }

  if (queryParams.startDate && queryParams.endDate) {
    range = [parseISO(queryParams.startDate), parseISO(queryParams.endDate)];
  } else if (store.session.get(`${id}-range-filter`) !== null) {
    try {
      const storeDateRange = JSON.parse(store.session.get(`${id}-range-filter`));
      range = storeDateRange.map((r: string) => parseISO(r));
    } catch {
      store.session.remove(`${id}-range-filter`);
    }
  }

  if (queryParams.startDate && queryParams.endDate) {
    activeRange = [parseISO(queryParams.startDate), parseISO(queryParams.endDate)];
  } else if (store.session.get(`${id}-active-range-filter`) !== null) {
    try {
      const storeDateRange = JSON.parse(store.session.get(`${id}-active-range-filter`));
      activeRange = storeDateRange.map((r: string) => parseISO(r));
    } catch {
      store.session.remove(`${id}-active-range-filter`);
    }
  }

  if (queryParams.compareFirstRangeStart && queryParams.compareFirstRangeEnd) {
    compareFirstRange = [parseISO(queryParams.compareFirstRangeStart), parseISO(queryParams.compareFirstRangeEnd)];
  }

  if (queryParams.compareDateColumn) {
    compareDateColumn = queryParams.compareDateColumn;
  }

  if (queryParams.compareSecondRangeStart && queryParams.compareSecondRangeEnd) {
    compareSecondRange = [parseISO(queryParams.compareSecondRangeStart), parseISO(queryParams.compareSecondRangeEnd)];
  }

  if (queryParams.group) {
    applications = queryParams.group ? (queryParams.group as any).split('-') : [];
  } else if (store.session.get(`${id}-application-filter`) !== null) {
    try {
      applications = JSON.parse(store.session.get(`${id}-application-filter`));
    } catch {
      store.session.remove(`${id}-application-filter`);
    }
  }

  if (queryParams.jobs) {
    jobs = queryParams.jobs ? (queryParams.jobs as any).split('-') : [];
  } else if (store.session.get(`${id}-job-filter`) !== null) {
    try {
      jobs = JSON.parse(store.session.get(`${id}-job-filter`));
    } catch {
      store.session.remove(`${id}-job-filter`);
    }
  }

  if (queryParams.crew && queryParams.crew.length > 0) {
    crew = Array.isArray(queryParams.crew) ? queryParams.crew : [queryParams.crew];
    crew = crew.map((c: any) => +c);
  } else if (store.session.get(`${id}-crew-filter`) !== null) {
    crew = store.session.get(`${id}-crew-filter`);
    crew = Array.isArray(crew) ? crew.map((c) => +c) : [+crew];
  }

  if (queryParams.documentTemplate && queryParams.documentTemplate.length > 0) {
    documentTemplate = Array.isArray(queryParams.documentTemplate) ? queryParams.documentTemplate : [queryParams.documentTemplate];
    documentTemplate = documentTemplate.map((d: any) => +d);
  } else if (store.session.get(`${id}-documentTemplate-filter`) !== null) {
    documentTemplate = store.session.get(`${id}-documentTemplate-filter`);
    documentTemplate = Array.isArray(documentTemplate) ? documentTemplate.map((d) => +d) : [+documentTemplate];
  }

  if (queryParams.trackingFlag && queryParams.trackingFlag.length > 0) {
    trackingFlag = Array.isArray(queryParams.trackingFlag) ? queryParams.trackingFlag : [queryParams.trackingFlag];
  } else if (store.session.get(`${id}-trackingFlag-filter`) !== null) {
    trackingFlag = store.session.get(`${id}-trackingFlag-filter`);
  }

  if (queryParams.squareFootage && queryParams.squareFootage.length > 0) {
    squareFootage = Array.isArray(queryParams.squareFootage) ? queryParams.squareFootage : [queryParams.squareFootage];
  } else if (store.session.get(`${id}-squareFootage-filter`) !== null) {
    squareFootage = store.session.get(`${id}-squareFootage-filter`);
  }
  
  if (queryParams.jobStatus) {
    jobStatus = Array.isArray(queryParams.jobStatus) ? queryParams.jobStatus : [queryParams.jobStatus];
  } else if (store.session.get(`${id}-jobStatus-filter`) !== null) {
    jobStatus = store.session.get(`${id}-jobStatus-filter`);
  }

  if (queryParams.workOrderStatus) {
    workOrderStatus = queryParams.workOrderStatus.toString();
  } else if (store.session.get(`${id}-workOrderStatus-filter`) !== null) {
    workOrderStatus = store.session.get(`${id}-workOrderStatus-filter`).replace('-', '_');
  }

  if (queryParams.paymentStatus) {
    paymentStatus = queryParams.paymentStatus.toString();
  } else if (store.session.get(`${id}-paymentStatus-filter`) !== null) {
    paymentStatus = store.session.get(`${id}-paymentStatus-filter`);
  }

  if (queryParams.documentType) {
    documentType = decodeURI(queryParams.documentType.toString());
  } else if (store.session.get(`${id}-documentType-filter`) !== null) {
    documentType = decodeURI(store.session.get(`${id}-documentType-filter`));
  }

  if (queryParams.recurrence) {
    recurrence = decodeURI(queryParams.recurrence.toString());
  } else if (store.session.get(`${id}-recurrence-filter`) !== null) {
    recurrence = decodeURI(store.session.get(`${id}-recurrence-filter`));
  }

  if (queryParams.documentStatus) {
    documentStatus = Array.isArray(queryParams.documentStatus) ? queryParams.documentStatus : [queryParams.documentStatus];
  } else if (store.session.get(`${id}-documentStatus-filter`) !== null) {
    documentStatus = store.session.get(`${id}-documentStatus-filter`);
  }

  if (queryParams.listType) {
    listType = decodeURI(queryParams.listType.toString());
  } else if (store.session.get(`${id}-listType-filter`) !== null) {
    listType = decodeURI(store.session.get(`${id}-listType-filter`));
  }

  if (queryParams.calendarView) {
    calendarView = decodeURI(queryParams.calendarView.toString());
  } else if (store.session.get(`${id}-calendarView-filter`) !== null) {
    calendarView = decodeURI(store.session.get(`${id}-calendarView-filter`));
  }

  if (queryParams.rangeColumn) {
    rangeColumn = decodeURI(queryParams.rangeColumn.toString());
  } else if (store.session.get(`${id}-rangeColumn-filter`) !== null) {
    rangeColumn = decodeURI(store.session.get(`${id}-rangeColumn-filter`));
  }

  if (queryParams.emailEvent) {
    emailEvent = decodeURI(queryParams.emailEvent.toString());
  } else if (store.session.get(`${id}-emailEvent-filter`) !== null) {
    emailEvent = decodeURI(store.session.get(`${id}-emailEvent-filter`));
  }

  if (queryParams.emailEventType) {
    emailEventType = decodeURI(queryParams.emailEventType.toString());
  } else if (store.session.get(`${id}-emailEventType-filter`) !== null) {
    emailEventType = decodeURI(store.session.get(`${id}-emailEventType-filter`));
  }

  if (queryParams.query) {
    text = decodeURI(queryParams?.query.toString());
  }

  if (queryParams.endingPeriod) {
    endingPeriod = decodeURI(queryParams.endingPeriod.toString());
  } else if (store.session.get(`${id}-endingPeriod-filter`) !== null) {
    endingPeriod = decodeURI(store.session.get(`${id}-endingPeriod-filter`));
  }

  if (queryParams.userRole) {
    userRole = decodeURI(queryParams.userRole.toString());
  } else if (store.session.get(`${id}-userRole-filter`) !== null) {
    userRole = decodeURI(store.session.get(`${id}-userRole-filter`));
  }

  if (queryParams.completedBy && queryParams.completedBy.length > 0) {
    completedBy = queryParams.completedBy.toString().split('_').map((c: any) => +c);
  } else if (store.session.get(`${id}-completedBy-filter`) !== null) {
    completedBy = JSON.parse(store.session.get(`${id}-completedBy-filter`));
    completedBy = Array.isArray(completedBy) ? completedBy.map((c) => +c) : [+completedBy];
  }

  if (queryParams.customerLabel) {
    customerLabel = queryParams.customerLabel ? (queryParams.customerLabel as any).split('-') : [];
  } else if (store.session.get(`${id}-customerLabel-filter`) !== null) {
    try {
      customerLabel = JSON.parse(store.session.get(`${id}-customerLabel-filter`));
    } catch {
      store.session.remove(`${id}-customerLabel-filter`);
    }
  }

  if (queryParams.template) {
    template = decodeURI(queryParams.template.toString());
  } else if (store.session.get(`${id}-template-filter`) !== null) {
    template = decodeURI(store.session.get(`${id}-template-filter`));
  }

  if (queryParams.siteReviewStatus) {
    siteReviewStatus = decodeURI(queryParams.siteReviewStatus.toString());
  } else if (store.session.get(`${id}-siteReviewStatus-filter`) !== null) {
    siteReviewStatus = decodeURI(store.session.get(`${id}-siteReviewStatus-filter`));
  }

  return {
    range,
    applications,
    crew,
    jobStatus,
    paymentStatus,
    params: {
      date,
      range,
      rangeColumn,
      activeRange,
      applications,
      jobs,
      crew,
      jobStatus,
      workOrderStatus,
      paymentStatus,
      text,
      documentType,
      documentStatus,
      documentTemplate,
      trackingFlag,
      listType,
      calendarView,
      recurrence,
      emailEvent,
      emailEventType,
      endingPeriod,
      userRole,
      customerLabel,
      compareFirstRange,
      compareSecondRange,
      compareDateColumn,
      completedBy,
      squareFootage,
      template,
      siteReviewStatus
    }
  };
}

const toQueryParams = (
  id: string
) => {
  const queryParams: string[] = [];

  if (store.session.get(`${id}-crew-filter`) !== null) {
    const crew = store.session.get(`${id}-crew-filter`);
    queryParams.push(crew);
  }

  if (store.session.get(`${id}-application-filter`) !== null) {
    const applications = JSON.parse(store.session.get(`${id}-application-filter`));
    queryParams.push('group=' + applications.join('-'));
  }

  if (store.session.get(`${id}-range-filter`) !== null) {
    const storeDateRange = JSON.parse(store.session.get(`${id}-range-filter`));

    if (storeDateRange.length === 2) {
      queryParams.push('startDate=' + storeDateRange[0]);
      queryParams.push('endDate=' + storeDateRange[1]);
    }
  }

  if (queryParams.length > 0) {
    return `?${queryParams.join('&')}`;
  }

  return '';
};

export {
  useFilteredParams,
  toQueryParams
};
