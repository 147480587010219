import { useState } from 'react';
import { Button, Drawer, Input, toaster, Message, Loader } from 'rsuite';
import { isMobileOnly } from 'react-device-detect';
import { useApi } from 'lib';
import store from 'lib/storage';
import { useViewport } from 'shared/ViewportProvider';

interface IFeedback {
  onHide: () => void,
  resource: string
}

const Feedback = ({ onHide, resource }: IFeedback) => {
  const api = useApi();
  const { dispatch } = useViewport();
  const [sending, setSending] = useState(false);

  const dumpLocalStorage = () => {
    let store = []
    for (let i = 0, l = localStorage.length; i < l; i++) {
      let key: any = localStorage.key(i);
      try {
        store.push({ key: key, value: JSON.parse(localStorage.getItem(key) || '{}') });
      } catch {
        store.push({ key: key, value: localStorage.getItem(key) });
      }
    }
    return JSON.stringify(store);
  }

  const dumpSessionStorage = () => {
    let store = []
    for (let i = 0, l = sessionStorage.length; i < l; i++) {
      let key: any = sessionStorage.key(i);
      try {
        store.push({ key: key, value: JSON.parse(sessionStorage.getItem(key) || '{}') });
      } catch {
        store.push({ key: key, value: sessionStorage.getItem(key) });
      }
    }
    return JSON.stringify(store);
  }


  const handleCaptureFeedback = async () => {
    const notes = (document as any)?.getElementById('feedbackNotes');

    if (!notes || notes.value.trim().length === 0) {
      const errorMessage = <div>
        <p>Form has errors which need to be corrected:</p>
        <div>
          <span>&bull; Notes are required<br /></span>
        </div>
      </div>;

      toaster.push(
        <Message type="error" showIcon closable duration={2000}>{errorMessage}</Message>
      );
      return;
    }

    setSending(true);

    try {
      const response = await api.post('/dev/logger/feedback', {
        img: resource,
        url: window.location.href,
        localStorage: dumpLocalStorage(),
        sessionStorage: dumpSessionStorage(),
        navigator: JSON.stringify(window.navigator.userAgent),
        notes: (document as any)?.getElementById('feedbackNotes').value,
        source: 'app'
      });

      store.remove('recording-session');
      store.remove('recording-session-data');
      dispatch({ type: 'SET_RECORDING_SESSION', payload: false });

      if (response.success) {
        toaster.push(
          <Message type="success" showIcon closable>Feedback has been received and will be addressed in timely manner.</Message>
        );
      } else {
        toaster.push(
          <Message type="error" showIcon closable>Failed to send feedback, notify the administrator of this issue.</Message>
        );
      }
    } catch (err) {
      console.log(err);
      toaster.push(<Message type="error" showIcon closable>Error</Message>);
    } finally {
      setSending(false);
      onHide();
    }
  }

  return (
    <Drawer open backdrop="static" onClose={onHide} full={isMobileOnly} size={'sm'}>
      <Drawer.Header><Drawer.Title>Feedback</Drawer.Title></Drawer.Header>
      <Drawer.Body>
        <div>
          <p className="mb-5">
            Have feedback? Found an issue? Use the form below to submit comments or questions.
            Screenshot of current location and URL will be shared for faster resolution.
          </p>
          <Message className={"mb-12"} type="info">
            <div>
              <div className="mb-12">In some instances clearing the cache can reset state and fix things. Try it before reporting an issue.</div>
              <Button size="sm"
                appearance="primary" onClick={() => {
                localStorage.clear();
                sessionStorage.clear();
                window.location.href = window.location.pathname;
              }}>Clear Cache</Button>
            </div>
          </Message>
          <div className="mb-5">
            <label className="required">Notes:</label>
            <Input as="textarea" id="feedbackNotes" rows={2} />
          </div>
          <div>
            <label>Screenshot:</label><br />
            <div className="mb-5" style={{ overflow: 'hidden', width: '100%', height: '200px', border: 'solid 1px #eee' }}>
              <img alt="Screenshot" src={resource} style={{ width: '100%' }} />
            </div>
          </div>
        </div>
      </Drawer.Body>
      <Drawer.Footer>
        <Button size="sm" onClick={handleCaptureFeedback} loading={sending} appearance={'primary'}>Send</Button>
        <Button size="sm" onClick={onHide} disabled={sending} appearance={'subtle'}>Cancel</Button>
      </Drawer.Footer>
    </Drawer>
  );
}

export {
  Feedback
}
