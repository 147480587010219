import { gql } from "@apollo/client";

const queryStorage = gql`
  query storage($limit: Int!, $offset: Int!, $where: StoragesWhere, $sort: StoragesSort) {
    storage(filter: {
      limit: $limit,
      offset: $offset,
      where: $where
      sort: $sort
    }) {
      edges {
        node {
          guid
          workGroup
          name
          map {
            fileKey
            name
          }
          layout {
            fileKey
            name
          }
        }
      },
      totalCount
    }
  }
`;

const querySpaces = gql`
  query storage($limit: Int!, $offset: Int!, $where: StoragesWhere, $sort: StoragesSort) {
    storage(filter: {
      limit: $limit,
      offset: $offset,
      where: $where
      sort: $sort
    }) {
      edges {
        node {
          guid
          workGroup
          name
          map {
            fileKey
            name
          }
          layout {
            fileKey
            name
          }
          spaces {
            guid
            name
            parentGuid

            units {
              guid
              name
              size
              cost
              parentGuid
              customerGuid
              sortOrder
            }
          }
        }
      },
      totalCount
    }
  }
`;

export {
  queryStorage,
  querySpaces
}
