import { useContext } from 'react';
import { ApplicationContext } from 'shared';
import { StorageForm as StorageFormContainer } from 'containers/storage/StorageForm';
import { StorageSpaceForm as StorageSpaceFormContainer } from 'containers/storage/StorageSpaceForm';

const StorageForm = () => {
  const {
    drawerData,
    showDrawer,
    onUpdateDrawerCallback
  } = useContext(ApplicationContext);

  return <StorageFormContainer
    drawer
    show
    onHide={() => showDrawer(undefined)}
    onUpdate={onUpdateDrawerCallback}
    guid={drawerData?.storageGuid}
    action={drawerData?.action}
  />
}

const StorageSpaceForm = () => {
  const {
    drawerData,
    showDrawer,
    onUpdateDrawerCallback
  } = useContext(ApplicationContext);

  return <StorageSpaceFormContainer
    drawer
    show
    onHide={() => showDrawer(undefined)}
    onUpdate={onUpdateDrawerCallback}
    guid={drawerData?.storageSpaceGuid}
    storageGuid={drawerData?.storageGuid}
    action={drawerData?.action}
  />
}

export {
  StorageForm,
  StorageSpaceForm
}
