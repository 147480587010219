import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { CashFlowForm, CashFlowView } from 'components/financials';
import CashFlowList from './CashFlowList';

const CashFlow = () => {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route path={`${match.path}/view/:guid?`} component={CashFlowView} /> 
      <Route path={`${match.path}/form/:action(add|edit|view)/:guid?`} component={CashFlowForm} />
      <Route path={`${match.path}/list`} component={CashFlowList} />
      <Route path={`${match.path}`} component={CashFlowList} />
    </Switch>
  );
}

export default CashFlow;
