import { Fragment, useContext } from 'react';
import { CustomerName, JobStatus, JobActionsMobile as JobActions, WorkTimePicker, WorkDate, ClockInOutButton } from './';
import { Badge, Button, Col, Divider, Grid, IconButton, Loader, Panel, Row } from 'rsuite';
import { COLOR, DRAWER } from 'lib/env';
import { IS_KALADI_KITCHENS, IS_KALADI_PROPERTIES } from 'lib/tenant';
import { ApplicationContext } from 'shared';
import { useViewport } from 'shared/ViewportProvider';
import { filterFieldsByParentKey } from 'lib/helpers/field';
import { MdAccessTimeFilled } from 'react-icons/md';
import Icon from '@rsuite/icons/lib/Icon';
import JobStatusSimple from './JobStatusSimple';

const StatusColor = (status: string) => {
  if (status === 'completed') {
    return COLOR.SUCCESS;
  } else if (status === 'canceled') {
    return COLOR.WARNING;
  }

  return COLOR.DANGER;
};

interface IViewMobile {
  jobs: any,
  jobsRecentlyCompleted: any,
  handleJobUpdate: (guid: string, data: any) => void,
  handleJobDoneUpdate: (guid: string) => void,
  handleShowDrawer: (guid: string, drawer: string) => void,
  handleJobReschedule: (guid: string, date: Date | Date[]) => void,
  loading: boolean,
  handleSummaryShow: (customerId: number, workOrderGuid: string) => void,
  handleClockInOutShow: (mode: string, job: any) => void,
  handleWorkOrderSelect: (guid: string | undefined, startEndDate: [Date, Date] | undefined) => void,
  handleJobRefresh: (guid: string) => void
}

const ViewMobile = ({
  jobs,
  jobsRecentlyCompleted,
  handleJobUpdate,
  handleJobDoneUpdate,
  handleShowDrawer,
  handleJobReschedule,
  loading,
  handleSummaryShow,
  handleClockInOutShow,
  handleWorkOrderSelect,
  handleJobRefresh
}: IViewMobile) => {
  const { state } = useViewport();
  const { showDrawer } = useContext(ApplicationContext);

  return (
    <div className={'jobs'}>
      {loading
        ? <Loader content="Loading..." />
        : <Fragment>
          {jobs.length === 0 && <div className="text-center">No data found</div>}
          {jobs.map((j: any, index: number) =>
            <Panel
              key={j.id}
              data-job-guid={j.guid}
              bordered className={'mb-12 pb-0 mobile-card'}
              style={{ padding: '8px', borderTop: `solid 3px ${StatusColor(j.status)}` }}
            >
              <div>
                <CustomerName
                  job={j}
                  jobsRecentlyCompleted={(jobsRecentlyCompleted || []).filter((jrc: any) => jrc.customerId === j.customerId && jrc.applicationGroup === j.applicationGroup)}
                  handleSummaryShow={handleSummaryShow}
                  handleShowDrawer={handleShowDrawer}
                  handleWorkOrderSelect={handleWorkOrderSelect}
                  handleJobRefresh={handleJobRefresh}
                  handleJobUpdate={handleJobUpdate}
                />

                {j.props.time_instructions && <div className={'mt-10'}>
                  <label>Time Instructions</label><br />
                  {j.props.time_instructions}
                </div>}

                <div>
                  {!['construction', 'roofing__59634106__1710128534'].includes(j.applicationGroup) &&
                    <div className='mb-5'>
                      <label>Date</label><br />
                      <WorkDate
                        job={j}
                        handleJobReschedule={handleJobReschedule}
                      />
                    </div>
                  }
                </div>

                <div className={'checkin'}>
                  <div>
                    {['construction', 'roofing__59634106__1710128534'].indexOf(j.applicationGroup) === -1 &&
                      <div>
                        <div className={'mt-10'}>
                          {IS_KALADI_KITCHENS
                            ? <Fragment>
                              <Grid fluid className="p-0">
                                <Row>
                                  <Col xs={12}>
                                    <ClockInOutButton job={j} mode="in" onClick={handleClockInOutShow} />
                                  </Col>
                                  <Col xs={12}>
                                    <ClockInOutButton job={j} mode="out" onClick={handleClockInOutShow} />
                                  </Col>
                                </Row>
                              </Grid>
                            </Fragment>
                            : <Fragment>
                              <label>Time On Site:</label>
                              <WorkTimePicker
                                disabled={false}
                                job={j}
                                time={j.timeOnSite}
                                handleJobUpdate={handleJobUpdate}
                                column={'time_on_site'}
                              />
                            </Fragment>
                          }
                        </div>

                        {!IS_KALADI_KITCHENS &&
                          <>
                            <div className={'mt-10'}>
                              <label>Time From Site:</label>
                              <WorkTimePicker
                                disabled={false}
                                job={j}
                                time={j.timeFromSite}
                                handleJobUpdate={handleJobUpdate}
                                column={'time_from_site'}
                              />
                            </div>
                            <div className={'mt-10'}>
                              <IconButton appearance='link' icon={<Icon as={MdAccessTimeFilled} />} size="sm" onClick={() => showDrawer(DRAWER.TIME_CARD_FORM, { jobGuid: j.guid })}>Time Card</IconButton>
                            </div>
                          </>
                        }
                      </div>
                    }

                    {(!['construction', 'roofing__59634106__1710128534'].includes(j.applicationGroup) && !IS_KALADI_KITCHENS) &&
                      <div className={'mt-10'}>
                        <label>People on site:</label>
                        <div className="people-on-site badge-list">
                          {new Array(10).fill(0).map((key: number, i: number) =>
                            <Button
                              key={`people-${i}`}
                              appearance={j.peopleOnSite === (i + 1) ? 'ghost' : 'subtle'}
                              onClick={() => handleJobUpdate(j.guid, { peopleOnSite: i + 1 })}
                            >{i + 1}</Button>
                          )}
                        </div>
                      </div>
                    }

                    {IS_KALADI_PROPERTIES &&
                      <JobStatusSimple
                        job={j}
                        applicationGroup={j.applicationGroup}
                        jobGuid={j.guid}
                        services={j.services}
                        deIcer={j.props.de_icer}
                        options={j.props}
                        handleJobUpdate={handleJobUpdate}
                        handleJobDoneUpdate={handleJobDoneUpdate}
                        siteChecks={j.siteChecks || []}
                        status={j.status}
                        defaultSiteChecks={filterFieldsByParentKey(state.fields, 'site_checks')}
                        completedServices={j.completedServices || []}
                      />
                    }

                    {(!['construction', 'roofing__59634106__1710128534'].includes(j.applicationGroup) && !IS_KALADI_PROPERTIES) &&
                      <div className={'mt-10'}>
                        <JobStatus
                          job={j}
                          jobGuid={j.guid}
                          services={j.services}
                          deIcer={j.props.de_icer}
                          options={j.props}
                          applicationGroup={j.applicationGroup}
                          handleJobUpdate={handleJobUpdate}
                          handleJobDoneUpdate={handleJobDoneUpdate}
                          siteChecks={j.siteChecks}
                          defaultSiteChecks={filterFieldsByParentKey(state.fields, 'site_checks')}
                          status={j.status}
                          completedServices={j.completedServices || []}
                        />
                      </div>
                    }
                  </div>

                  {!IS_KALADI_KITCHENS &&
                    <JobActions
                      job={j}
                      handleShowDrawer={handleShowDrawer}
                      handleJobRefresh={handleJobRefresh}
                    />
                  }
                </div>
              </div>
            </Panel>
          )}
        </Fragment>
      }
    </div>
  )
}

export default ViewMobile;