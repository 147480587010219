import { Fragment, useContext } from "react";
import { isMobileOnly } from "react-device-detect";
import { Button, Drawer, Loader } from "rsuite";
import { ApplicationContext } from "shared";
import stableStringify from 'json-stable-stringify';
import { useEffect } from "react";
import { gql, useApolloClient } from "@apollo/client";
import { useState } from "react";
import { format, parseISO } from "date-fns";
import { FORMAT } from "lib/env";
import { useViewport } from "shared/ViewportProvider";
import startCase from "lodash/startCase";

const diff = (obj1: any, obj2: any) => {
  if (!obj2 || Object.prototype.toString.call(obj2) !== '[object Object]') {
    return obj1;
  }

  let diffs: any = {};
  let key;

  const arraysMatch = (arr1: any, arr2: any) => {
    if (arr1.length !== arr2.length) return false;

    for (var i = 0; i < arr1.length; i++) {
      if (arr1[i] !== arr2[i]) return false;
    }

    return true;
  };

  const compare = (item1: any, item2: any, key: any) => {
    let type1 = Object.prototype.toString.call(item1);
    let type2 = Object.prototype.toString.call(item2);

    if (type2 === '[object Undefined]') {
      diffs[key] = null;
      return;
    }

    if (type1 !== type2) {
      diffs[key] = item2;
      return;
    }

    if (type1 === '[object Object]') {
      var objDiff = diff(item1, item2);
      if (Object.keys(objDiff).length > 0) {
        diffs[key] = objDiff;
      }
      return;
    }

    if (type1 === '[object Array]') {
      if (!arraysMatch(item1, item2)) {
        diffs[key] = item2;
      }
      return;
    }

    if (type1 === '[object Function]') {
      if (item1.toString() !== item2.toString()) {
        diffs[key] = item2;
      }
    } else {
      if (item1 !== item2) {
        diffs[key] = item2;
      }
    }

  };

  for (key in obj1) {
    if (obj1.hasOwnProperty(key)) {
      compare(obj1[key], obj2[key], key);
    }
  }

  for (key in obj2) {
    if (obj2.hasOwnProperty(key)) {
      if (!obj1[key] && obj1[key] !== obj2[key]) {
        diffs[key] = obj2[key];
      }
    }
  }

  return diffs;
};

const ResourceHistory = () => {
  const { drawerData, showDrawer } = useContext(ApplicationContext);
  const client = useApolloClient();
  const { state } = useViewport();
  const [loading, setLoading] = useState(false);
  const [events, setEvents] = useState<any>([]);

  useEffect(() => {
    (async function getHistory() {
      try {
        setLoading(true);

        const data: any = await client.query({
          query: gql`query ${drawerData.resource} ($guid: ID) { ${drawerData.resource}(guid:$guid) { resourceHistory } }`,
          variables: {
            guid: drawerData.resourceGuid,
          },
          fetchPolicy: 'no-cache'
        });

        const allEvents = data.data[drawerData.resource || 'not-found'].resourceHistory?.events || [];
        const changes = [];

        if (allEvents.length > 1) {
          for (let i = 1; i < allEvents.length; i++) {
            const prevEvent = JSON.parse(allEvents[i - 1].message);
            const nextEvent = JSON.parse(allEvents[i].message);
            const diffs = diff(prevEvent, nextEvent);
            
            if (Object.keys(diffs).length > 1) {
              changes.push({
                old: {
                  ...Object.keys(diffs).reduce((p: any, n: any) => {
                    p[n] = prevEvent[n];
                    return p;
                  }, {})
                },
                new: {
                  ...diffs,
                  modifiedBy: nextEvent?.modifiedBy,
                  modifiedAt: nextEvent?.modifiedAt,
                  modifiedReason: nextEvent?.modifiedReason 
                }
              });
            }
          }
        }

        setEvents(changes.reverse());
        setLoading(false);
      } catch (err) {
        console.log(err);
      }
      setLoading(false);
    })();
  }, []);

  const cleanData = (eventString: any) => {
    const event = JSON.parse(eventString);
    return stableStringify(event, { space: "  " });
  }

  const getTitle = (eventString: any, users: any) => {
    const event = JSON.parse(eventString);
    const user = users.find((u: any) => +event.modifiedBy === +u.id);
    return (event.modifiedAt ? format(parseISO(event.modifiedAt), FORMAT.DAY_MONTH_TIME) : '') + (user ? ' by ' + user.operatorName : '');
  }

  const getHistoryStatus = (e: any, k: string) => {
    if (typeof (e?.new[k]) === 'object') {
      if (k === 'services') {
        return <span>{startCase(k)}: <s><span dangerouslySetInnerHTML={{ __html: (e?.old[k] || []).map((s: any) => s.description + ': ' + s.cost.toString()).join('<br />') }} /></s> &gt; <span dangerouslySetInnerHTML={{ __html: (e?.new[k] || []).map((s: any) => s.description + ': ' + s.cost.toString()).join('<br />') }} /></span>
      }
    } else {
      if (k === 'modifiedBy') {
        return <span>{startCase(k)}: <s>{state.users.find((u: any) => +u.id === +e?.old[k])?.operatorName}</s> &gt; {state.users.find((u: any) => +u.id === +e?.new[k])?.operatorName}</span>;
      }
      
      return <span>{startCase(k)}: <s>{e?.old[k]}</s> &gt; {e?.new[k]}</span>;
    }

    return '';
  }

  return (
    <Drawer
      open
      size={isMobileOnly ? 'full': 'lg'}
      onClose={() => showDrawer(undefined)}
    >
      <Drawer.Header>
        <Drawer.Title>History</Drawer.Title>
      </Drawer.Header>
      <Drawer.Body>
        {loading
          ? <Loader content="Loading..." />
          : <div>
            {(events.length > 0)
              ? <Fragment>
                <div className="mb-12">
                  <p>
                    {events.length} version(s) found, latest changes are at the top.
                  </p>
                </div>

                {events.map((e: any, ei: any) =>
                  <div className="mb-12" key={`event-${ei}`}>
                    <p><strong>Modified: {e.new.modifiedAt ? format(parseISO(e.new.modifiedAt), FORMAT.DAY_MONTH_TIME24) : 'Original'}</strong></p>
                    {Object.keys(e.new).filter((k: string) => k !== 'modifiedAt').map((k: string, ki: number) =>
                      <div key={`event-key-${ei}-${ki}`}>
                        {getHistoryStatus(e, k)}
                      </div>
                    )}
                  </div>
                )}
              </Fragment>
              : <Fragment>
                <p className="mb-12">
                  <strong>No changes found.</strong>
                </p>
              </Fragment>
            }
          </div>
        }
      </Drawer.Body>
      <Drawer.Actions>
        <Button size="sm" onClick={() => showDrawer(undefined)} appearance="subtle">Close</Button>
      </Drawer.Actions>
    </Drawer>
  );
}

export {
  ResourceHistory
}
