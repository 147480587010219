import { useContext } from 'react';
import { ApplicationContext } from 'shared';
import {
  SettingsFieldForm as SettingsFieldFormContainer,
  SettingsActionForm as SettingsActionFormContainer,
  SettingsCompanyForm as SettingsCompanyFormContainer
} from 'components/settings';

const SettingsFieldForm = () => {
  const {
    drawerData,
    showDrawer,
    onUpdateDrawerCallback
  } = useContext(ApplicationContext);

  return <SettingsFieldFormContainer
    onHide={() => showDrawer(undefined)}
    onUpdate={onUpdateDrawerCallback}
    guid={drawerData?.fieldGuid}
    action={drawerData?.action}
    parentGuid={drawerData?.parentGuid}
  />
}

const SettingsActionsForm = () => {
  const {
    drawerData,
    showDrawer,
    onUpdateDrawerCallback
  } = useContext(ApplicationContext);

  return <SettingsActionFormContainer
    onHide={() => showDrawer(undefined)}
    onUpdate={onUpdateDrawerCallback}
    guid={drawerData?.actionGuid}
    action={drawerData?.action}
  />
}

const SettingsCompanyForm = () => {
  const {
    drawerData,
    showDrawer,
    onUpdateDrawerCallback
  } = useContext(ApplicationContext);

  return <SettingsCompanyFormContainer
    onHide={() => showDrawer(undefined)}
    onUpdate={onUpdateDrawerCallback}
    guid={drawerData?.companyGuid}
    action={drawerData?.action}
  />
}

export {
  SettingsFieldForm,
  SettingsActionsForm,
  SettingsCompanyForm
}
