import { useState, useEffect, useContext } from 'react';
import { DatePicker, IconButton, toaster, Message, FlexboxGrid, Button } from 'rsuite';
import camelCase from 'lodash/camelCase';
import { format, parseISO } from 'date-fns';
import { useViewport } from 'shared/ViewportProvider';
import { toLocalTimeZone } from 'lib/date';
import { isMobileOnly } from 'react-device-detect';
import { COLOR, FORMAT } from 'lib/env';
import { MdPunchClock } from 'react-icons/md';
import Icon from '@rsuite/icons/lib/Icon';
import { ApplicationContext } from 'shared/ApplicationProvider';
import { MdAdd, MdClose } from 'react-icons/md';

interface IWorkTimePicker {
  disabled: boolean,
  time: string,
  job: any,
  timeCard?: any,
  handleJobUpdate: (guid: string, data: any, selection: any) => void,
  handleTimeEntryAdd?: (jobGuid: string) => void,
  handleTimeEntryRemove?: (jobGuid: string, timeCardGuid: string) => void,
  handleTimeEntryUpdate?: (guid: string, input: any) => void
  column: string
}

const WorkTimePicker = ({
  disabled,
  time,
  job,
  timeCard,
  handleJobUpdate,
  handleTimeEntryAdd,
  handleTimeEntryRemove,
  handleTimeEntryUpdate,
  column
}: IWorkTimePicker) => {
  const { state } = useViewport();
  const { showConfirmation } = useContext(ApplicationContext);
  const [timeSelected, setTimeSelected] = useState<Date | undefined>(undefined);
  const [modifiedAt, setModifiedAt] = useState(new Date().toISOString());

  useEffect(() => {
    if (timeCard) {
      setTimeSelected(typeof (timeCard[camelCase(column)]) === 'string' ? parseISO(timeCard[camelCase(column)]) : timeCard[camelCase(column)]);
    } else if (job?.guid) {
      setTimeSelected(typeof (job[camelCase(column)]) === 'string' ? parseISO(job[camelCase(column)]) : job[camelCase(column)]);
    }
  }, [column, job, job.timeOnSite, job.timeFromSite, timeCard]);

  return (
    <div>
      {disabled
        ? <span>{format(parseISO(time), FORMAT.TIME24)}</span>
        : <div key={modifiedAt}>
          {isMobileOnly
            ? <div>
              <FlexboxGrid>
                <FlexboxGrid.Item colspan={20}>
                  <DatePicker
                    size="sm"
                    cleanable={false}
                    format={'HH:mm'}
                    renderValue={(date: Date) => format(date, FORMAT.TIME24)}
                    block={true}
                    onChange={(time: any) => {
                      if (column === 'time_from_site' && (job.timeOnSite === null || job.timeOnSite === undefined)) {
                        setTimeSelected(undefined);
                        toaster.push(
                          <Message type="error" showIcon closable>Enter time on site first. Time from site cannot be entered before time on site</Message>
                        );
                      } else {
                        setTimeSelected(time);
                        handleJobUpdate(job.guid, { [camelCase(column)]: time }, []);
                      }
                      setModifiedAt(new Date().toISOString());
                    }}
                    ranges={[
                      {
                        label: 'Now',
                        value: new Date(),
                        closeOverlay: true
                      }
                    ]}
                    value={timeSelected}
                  />
                </FlexboxGrid.Item>
                <FlexboxGrid.Item colspan={4}>
                  <IconButton
                    onClick={() => {
                      handleJobUpdate(job.guid, { [camelCase(column)]: toLocalTimeZone(new Date(), state.timeZone) }, []);
                    }}
                    appearance='link'
                    icon={<Icon as={MdPunchClock} />} />
                </FlexboxGrid.Item>
              </FlexboxGrid>
            </div>
            : <>
              <DatePicker
                format={'HH:mm'}
                renderValue={(date: Date) => format(date, FORMAT.TIME24)}
                size="sm"
                cleanable={false}
                style={{ width: (handleTimeEntryAdd || (timeCard && column === 'time_from_site' && handleTimeEntryRemove)) ? '80%' : '100%' }}
                placeholder="HH:mm"
                onChange={(time: any) => {
                  if (timeCard) {
                    if (handleTimeEntryUpdate) {
                      handleTimeEntryUpdate(timeCard.guid, { jobGuid: timeCard.jobGuid, [column === 'time_on_site' ? 'timeOnSite' : 'timeFromSite']: time ? time.toISOString() : time });
                      setTimeSelected(time);
                    }
                  } else {
                    if (column === 'time_from_site' && (job.timeOnSite === null || job.timeOnSite === undefined)) {
                      setTimeSelected(undefined);
                      toaster.push(
                        <Message type="error" showIcon closable>Enter time on site first. Time from site cannot be entered before time on site.</Message>
                      );
                    } else {
                      setTimeSelected(time);
                      handleJobUpdate(job.guid, { [camelCase(column)]: time }, []);
                    }
                  }
                  setModifiedAt(new Date().toISOString());
                }}
                value={timeSelected}
                ranges={[
                  {
                    label: 'Now',
                    value: new Date(),
                    closeOverlay: true
                  }
                ]}
              />
              {handleTimeEntryAdd &&
                <IconButton icon={<MdAdd />} onClick={() => handleTimeEntryAdd(job.guid)} size="sm" appearance="link" style={{ paddingRight: 0, paddingLeft: 8 }} />
              }

              {(timeCard && column === 'time_from_site' && handleTimeEntryRemove) &&
                <IconButton icon={<MdClose />} size="sm" onClick={() => {
                  showConfirmation('Are you sure you want to remove follow time entry? This action cannot be undone',
                    'Remove Time Entry',
                    () => handleTimeEntryRemove(job.guid, timeCard.guid));
                }} appearance="link" style={{ paddingRight: 0, paddingLeft: 8, color: COLOR.DANGER }} />
              }
            </>
          }

          {/* {column === 'time_on_site' && <div><a href="/" onClick={(e: any) => {
            e.preventDefault();
            showDrawer(DRAWER.TIME_CARD_FORM, { jobGuid: job.guid });
          }}>More...</a></div>} */}
        </div>
      }
    </div>
  );
}

export default WorkTimePicker;