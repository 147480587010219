import gql from 'graphql-tag';
import { ApolloClient } from '@apollo/client';

const rescheduleJob = async (client: ApolloClient<object>, guid: string, startDate: Date, endDate: Date) => {
  const rescheduleJob = gql`
    mutation rescheduleJob(
      $guid: ID!
      $startDate: DateTime!,
      $endDate: DateTime!
    ) {
      rescheduleJob(
        guid: $guid
        startDate: $startDate,
        endDate: $endDate
      ) {
        success
        code
        message
      }
    }
  `;

  return await client.mutate({
    mutation: rescheduleJob,
    variables: { guid, startDate, endDate }
  });
}

const getTotalHours = async (client: ApolloClient<object>, startDate: Date, endDate: Date) => {
  return await client.query({
    query: gql`query jobsHoursTotal($where: JobsWhere) { jobsHoursTotal(filter: { where: $where }) { total } }`,
    variables: {
      where: {
        startDate: {
          overlaps: {
            with: 'end_date',
            start: startDate,
            end: endDate
          }
        }
      }
    },
    fetchPolicy: 'no-cache'
  });
}

export {
  rescheduleJob,
  getTotalHours
};
