import { startCase } from 'lodash';
import React, { Fragment, useContext, useEffect, useRef, useState } from 'react';
import { isMobileOnly } from 'react-device-detect';

import {
  toaster,
  Button,
  ButtonToolbar,
  DatePicker,
  Divider,
  Drawer,
  Dropdown,
  Form,
  Message,
  SelectPicker,
  Table,
  Toggle,
  Grid,
  Row,
  Col,
  Input,
} from 'rsuite';

import LegacyExternalLinkIcon from "@rsuite/icons/legacy/ExternalLink";
import LegacyCheckCircleIcon from "@rsuite/icons/legacy/CheckCircle";
import LegacyTimesCircleIcon from "@rsuite/icons/legacy/TimesCircle";
import TextareaAutosize from 'react-autosize-textarea';
import AttachmentForm from 'components/attachment/AttachmentForm';
import { ApplicationContext, ResponsiveTable } from 'shared';
import { COLOR, FORMAT, getEnv, ROLE } from 'lib/env';
import ResponsiveNav from '@rsuite/responsive-nav';
import { addMinutes, format, isBefore, parseISO, addHours, subMinutes } from 'date-fns';
import { gql, useApolloClient } from '@apollo/client';
import { CONFIG_TIME_PADDING } from 'tenant/config';
import { useViewport } from 'shared/ViewportProvider';
import { filterFieldsByParentKey } from 'lib/helpers/field';
import { usePrairieAuth } from 'contexts/AuthPrairieProvider';
import { Link } from 'react-router-dom';

const GET_JOBS = gql`
  query jobs($limit: Int!, $where: JobsWhere, $order: [JobsSort]) {
    jobs(filter: {
      limit: $limit,
      where: $where
      order: $order
    }) {
      edges {
        node {
          id
          guid
          startDate
          endDate
          status
          completedAt
        }
      },
      totalCount
    }
  }
`;

const GET_EVENTS = gql`
  query events($resourceGuid: ID) {
    events(resourceGuid: $resourceGuid) {
      edges {
        node {
          event
          username
          createdAt
        }
      },
      totalCount
    }
  }
`;

const defaultFormCheckIn = {
  covidSymptoms: false,
  covidContact: false,
  kitchenStationClean: true,
  kitchenStationCleanNotes: '',
  kitchenStationCleanAttachments: [],
  noMaintenanceIssues: false,
  nomaintenanceIssuesNotes: '',
  noMaintenanceIssuesAttachments: []
};

const defaultFormCheckOut = {
  appliances: false,
  counterTopsTables: false,
  floors: false,
  dishwasherArea: false,
  allAppliances: false,
  lastOneToLeave: false,
  turnOffLights: false,
  comments: ''
};

const defaultFormView = {
  notes: ''
};

const { Column, HeaderCell, Cell } = Table;

interface IClockInOut {
  mode: string | undefined,
  show: boolean,
  job: any,
  onHide: () => void,
  onUpdate: () => void
}

const ClockInOut = ({
  show,
  mode,
  job,
  onHide,
  onUpdate
}: IClockInOut) => {
  const container = useRef() as React.MutableRefObject<HTMLDivElement>;
  const client = useApolloClient();
  const { state } = useViewport();
  const { isRole } = usePrairieAuth();
  const { showError, showConfirmation } = useContext(ApplicationContext);
  const [activeTab, setActiveTab] = useState<any>(undefined);
  const [formCheckIn, setFormCheckIn] = useState<any>(defaultFormCheckIn);
  const [formCheckOut, setFormCheckOut] = useState<any>(defaultFormCheckOut);
  const [formView, setFormView] = useState<any>(defaultFormView);
  const [fields, setFields] = useState<any>([]);
  const [events, setEvents] = useState<any>([]);
  const [startDate, setStartDate] = useState<any>(undefined);
  const [endDate, setEndDate] = useState<any>(undefined);
  const [workType, setWorkType] = useState<any>('');
  const [requestChanges, setRequestChanges] = useState(false);
  const [attachments, setAttachments] = useState<any>([]);
  const [jobSeries, setJobSeries] = useState<any>([]);

  useEffect(() => {
    // get all the jobs to determine if this one is in the series
    (async function getJobList() {
      if (job) {
        const result: any = await client.query({
          query: GET_JOBS,
          variables: {
            limit: 500,
            where: {
              AND: [
                {
                  workOrderGuid: { is: job.workOrderGuid }
                }
              ]
            },
            order: [
              { startDate: "DESC" }
            ]
          },
          fetchPolicy: 'no-cache'
        });

        setJobSeries(result.data.jobs.edges.node);
      }
    })();
  }, [job, client]);

  useEffect(() => {
    (async function getFieldsData() {
      if (job) {
        const jobResult = await client.query({
          query: gql`query job($guid: ID!) { job(guid: $guid) { attachments { guid, fileId, filePath, fileName, fileGroup, applicationGroup } } }`,
          variables: {
            guid: job.guid
          }
        });
        setAttachments(jobResult.data.job.attachments);

        const data = await client.query({
          query: GET_EVENTS,
          variables: {
            resourceGuid: job.guid
          },
          fetchPolicy: 'no-cache'
        });

        const parentField = state.fields.find((f: any) => f.key === job.workType);
        setStartDate(parseISO(job.startDate));
        setEndDate(parseISO(job.endDate));
        setEvents(data.data.events.edges.node);
        setFields(filterFieldsByParentKey(state.fields, 'work_types').filter((f: any) => f.applicationGroup === parentField.applicationGroup));
        setWorkType(job.workType);
        setRequestChanges(false);
      }
    })();
  }, [job, client]);

  useEffect(() => {
    setActiveTab(mode);
  }, [mode]);

  useEffect(() => {
    switch (activeTab) {
      case 'in':
        const tmp = { ...(job?.props?.timeOnSiteNotes ? job?.props?.timeOnSiteNotes : defaultFormCheckIn) };
        tmp.kitchenStationCleanAttachments = ((job?.attachments || []).concat(attachments)).filter((j: any) => j.applicationGroup === 'kitchen-station');
        tmp.noMaintenanceIssuesAttachments = ((job?.attachments || []).concat(attachments)).filter((j: any) => j.applicationGroup === 'maintenance-issues');
        setFormCheckIn(tmp);
        break;
      case 'out':
        setFormCheckOut({ ...(job?.props?.timeFromSiteNotes ? job?.props?.timeFromSiteNotes : defaultFormCheckOut) });
        break;
      case 'view':
        setFormView({ ...(job?.notes ? { notes: job?.notes } : defaultFormView) });
        break;
    }
  }, [activeTab, job]);

  const handleSubmit = async () => {
    try {
      let response: any = {};

      if (activeTab === 'in') {
        const updateJob = gql`
          mutation updateJob($guid: ID!, $timeOnSiteNotes: String) {
            updateJob(guid: $guid, timeOnSiteNotes: $timeOnSiteNotes) {
              success
              code
              message
            }
          }
        `;

        response = await client.mutate({
          mutation: updateJob,
          variables: { guid: job.guid, timeOnSiteNotes: JSON.stringify(formCheckIn) }
        });
      } else if (activeTab === 'out') {
        const updateJob = gql`
          mutation updateJob($guid: ID!, $timeFromSiteNotes: String) {
            updateJob(guid: $guid, timeFromSiteNotes: $timeFromSiteNotes) {
              success
              code
              message
            }
          }
        `;

        response = await client.mutate({
          mutation: updateJob,
          variables: { guid: job.guid, timeFromSiteNotes: JSON.stringify(formCheckOut) }
        });
      } else if (activeTab === 'view') {
        const updateJob = gql`
          mutation updateJob($guid: ID!, $notes: String) {
            updateJob(guid: $guid, notes: $notes) {
              success
              code
              message
            }
          }
        `;

        response = await client.mutate({
          mutation: updateJob,
          variables: { guid: job.guid, notes: formView.notes }
        });
      }

      if (response?.data?.updateJob?.success) {
        toaster.push(
          <Message type="success" showIcon closable>{response.data.updateJob.message}</Message>
        );
        onHide();
        onUpdate();
      } else {
        showError(response);
      }
    } catch (e) {
      showError(e);
    }
  }

  const handleCancelAppointment = async (guid: string) => {
    try {
      const updateJob = gql`
        mutation updateJob($guid: ID!, $status: String) {
          updateJob(guid: $guid, status: $status) {
            success
            code
            message
          }
        }
      `;

      const response = await client.mutate({
        mutation: updateJob,
        variables: { guid, status: 'canceled' }
      });

      if (response?.data?.updateJob?.success) {
        toaster.push(<Message type="success" showIcon closable>Job canceled</Message>);
        onHide();
      } else {
        showError(response);
      }
    } catch (e) {
      showError(e);
    }
  }

  const handleDeleteAppointment = async (guid: string) => {
    try {
      const deleteJob = gql`
        mutation deleteJob($guid: ID!) {
          deleteJob(guid: $guid) {
            success
            code
            message
          }
        }
      `;

      const response = await client.mutate({
        mutation: deleteJob,
        variables: { guid }
      });

      if (response?.data?.deleteJob?.success) {
        toaster.push(
          <Message type="success" showIcon closable>{response.data.deleteJob.message}</Message>
        );
        onHide();
      } else {
        showError(response);
      }
    } catch (e) {
      showError(e);
    }
  }

  const handleAddMinutesToAppointment = async (guid: string, minutes: number, workType: string, placement: string) => {
    try {
      const updateJobDateTime = gql`
          mutation updateJobDateTime($guid: ID!, $input: JobInput) {
            updateJobDateTime(guid: $guid, input: $input) {
              success
              code
              message
              result
            }
          }
        `;

      const response = await client.mutate({
        mutation: updateJobDateTime,
        variables: {
          guid,
          input: {
            startDate: placement === 'before' ? subMinutes(parseISO(job.startDate), minutes) : job.startDate,
            endDate: placement === 'after' ? addMinutes(parseISO(job.endDate), minutes) : job.endDate,
            workType,
            lastIntent: 'extendJob'
          }
        }
      });

      if (response?.data?.updateJobDateTime?.success) {
        toaster.push(
          <Message type="success" showIcon closable>{response.data.updateJobDateTime.message}</Message>
        );
        onHide();
        onUpdate();
      } else {
        toaster.push(
          <Message type="error" showIcon closable>{response.data.updateJobDateTime.message}</Message>
        );
      }
    } catch (e) {
      showError(e);
    }
  }

  const handleSubmitDateChange = async (guid: string, startDate: Date, endDate: Date, workType: string) => {
    if (endDate <= startDate) {
      toaster.push(
        <Message type="error" showIcon closable>End time must be greater then start time</Message>
      );
    } else {
      try {
        const updateJobDateTime = gql`
            mutation updateJobDateTime($guid: ID!, $input: JobInput) {
              updateJobDateTime(guid: $guid, input: $input) {
                success
                code
                message
                result
              }
            }
          `;

        const response = await client.mutate({
          mutation: updateJobDateTime,
          variables: { guid, input: { startDate, endDate, workType } }
        });

        if (response?.data?.updateJobDateTime?.success) {
          toaster.push(
            <Message type="success" showIcon closable>{response.data.updateJobDateTime.message}</Message>
          );
          onHide();
          onUpdate();
        } else {
          toaster.push(
            <Message type="error" showIcon closable>{response.data.updateJobDateTime.message}</Message>
          );
        }
      } catch (e) {
        showError(e);
      }
    }
  }

  const handleSetAttachmentsKitchenStation = (attachments: any) => {
    const newForm = JSON.parse(JSON.stringify(formCheckIn));
    newForm.kitchenStationCleanAttachments = [...attachments];
    setFormCheckIn(newForm);
  }

  const handleSetAttachmentsNoMaintenanceIssues = (attachments: any) => {
    const newForm = JSON.parse(JSON.stringify(formCheckIn));
    newForm.noMaintenanceIssuesAttachments = [...attachments];
    setFormCheckIn(newForm);
  }

  const isClockInOutEnabled = (job: any) => {
    if (job?.status === 'canceled') {
      return false;
    }

    if (isRole(ROLE.CLIENT) && job && +state.profile.id === +job.userId) {
      return true;
    } else if (isRole(ROLE.ADMIN, ROLE.MANAGER, ROLE.FRANCHISE)) {
      return true;
    }

    return false;
  }

  return (
    <Drawer open={show} full={isMobileOnly} size={"sm"} onClose={onHide}>
      <Drawer.Header>
        <Drawer.Title>{job?.customer?.displayName}
          <span> </span>
          {job?.workType && ' - ' + fields.find((f: any) => f.key === job?.workType)?.title}
          <span> </span> / {startCase(fields.find((f: any) => f.key === job?.workType)?.applicationGroup)}
        </Drawer.Title>
      </Drawer.Header>
      <Drawer.Body>
        <div ref={container}>
          <ResponsiveNav appearance="subtle" activeKey={activeTab} onSelect={setActiveTab}>
            <ResponsiveNav.Item eventKey="view">Details</ResponsiveNav.Item>
            {isClockInOutEnabled(job) &&
              <ResponsiveNav.Item eventKey="in">Clock In <i style={{ fontSize: '12px' }} className={`icon-content rs-icon ${job?.props?.timeOnSiteNotes ? 'rs-icon-check-circle' : 'rs-icon-close-circle'}`}></i></ResponsiveNav.Item>
            }
            {isClockInOutEnabled(job) &&
              <ResponsiveNav.Item eventKey="out">Clock Out <i style={{ fontSize: '12px' }} className={`icon-content rs-icon ${job?.props?.timeFromSiteNotes ? 'rs-icon-check-circle' : 'rs-icon-close-circle'}`}></i></ResponsiveNav.Item>
            }
            {isRole(ROLE.ADMIN, ROLE.MANAGER) &&
              <ResponsiveNav.Item eventKey="log">Log</ResponsiveNav.Item>
            }
          </ResponsiveNav>
          <br />

          {(activeTab === 'view' && job && startDate && endDate) &&
            <Form fluid formValue={formView} onChange={setFormView}>
              <Grid fluid>
                <Row>
                  <Col md={12} xs={24} className="p-0">
                    {getEnv() === 'dev' &&
                      <div className="mb-12">
                        <label>UUID:</label>
                        <p>{job?.guid}</p>
                      </div>
                    }

                    <Form.Group>
                      <Form.ControlLabel>Contact Info:</Form.ControlLabel>
                      <div>
                        <Link to={`/app/${getEnv()}/workbook/explorer/${job.customer.id}/profile`}>{job.customer.customerName}</Link>
                        <span> </span>
                        <a
                          rel="noreferrer"
                          href={`/app/${getEnv()}/workbook/explorer/${job.customer.id}/profile`}
                          target="_blank"
                        ><LegacyExternalLinkIcon /></a>
                      </div>
                      {job.customer?.phone && <div>
                        Phone: <a href={`tel:${job.customer.phone}`}>{job.customer.phone}</a>
                      </div>}

                      {job.customer?.email &&
                        <div>
                          Email: <a href={`mailto:${job.customer.email}`}>{job.customer.email}</a>
                        </div>}
                    </Form.Group>

                    <Form.Group>
                      <Form.ControlLabel>Kitchen:</Form.ControlLabel>
                      {(isRole(ROLE.ADMIN, ROLE.MANAGER) || (!job?.props?.timeOnSiteNotes && addHours(new Date(), 24) <= parseISO(job.startDate)))
                        ? <SelectPicker
                          block
                          cleanable={false}
                          container={() => container && container.current}
                          data={fields}
                          value={workType}
                          onChange={setWorkType}
                          labelKey="title"
                          valueKey="key"
                          searchable={false}
                        />
                        : <p>{fields.find((f: any) => f.key === job?.workType)?.title}</p>
                      }
                    </Form.Group>
                    <Form.Group>
                      <Form.ControlLabel>Start Time:</Form.ControlLabel>
                      {/** allow time changes if not clocked in and hours more then 24 */}
                      {(isRole(ROLE.ADMIN, ROLE.MANAGER) || (!job?.props?.timeOnSiteNotes && addHours(new Date(), 24) <= parseISO(job.startDate)))
                        ? <DatePicker
                          format='yyyy-MM-dd HH:mm'
                          renderValue={(date: Date) => format(date, FORMAT.DAY_MONTH_TIME)}
                          container={() => container && container.current}
                          cleanable={false}
                          value={startDate}
                          ranges={[]}
                          block
                          onChange={setStartDate}
                          disabledDate={(date: any) => isRole(ROLE.CLIENT) && isBefore(date, new Date())}
                          disabledMinutes={(minute: any) => ![0, 30].includes(minute)}
                          hideMinutes={(minute: any) => ![0, 30].includes(minute)}
                        />
                        : <p>{format(startDate, 'EEE, MMM d, yyyy h:mma')}</p>
                      }

                    </Form.Group>

                    <Form.Group>
                      <Form.ControlLabel>End Time:</Form.ControlLabel>
                      {(isRole(ROLE.ADMIN, ROLE.MANAGER) || (!job?.props?.timeOnSiteNotes && addHours(new Date(), 24) <= parseISO(job.startDate)))
                        ? <DatePicker
                          container={() => container && container.current}
                          format='yyyy-MM-dd HH:mm'
                          renderValue={(date: Date) => format(date, FORMAT.DAY_MONTH_TIME)}
                          cleanable={false}
                          value={endDate}
                          ranges={[]}
                          block
                          onChange={setEndDate}
                          disabledDate={(date: any) => isRole(ROLE.CLIENT) && isBefore(date, startDate)}
                          disabledMinutes={(minute: any) => ![0, 30].includes(minute)}
                          hideMinutes={(minute: any) => ![0, 30].includes(minute)}
                        />
                        : <p>{format(endDate, 'EEE, MMM d, yyyy h:mma')}</p>
                      }
                      {jobSeries.length > 1 &&
                        <div>
                          {jobSeries.map((j: any) => j.guid).indexOf(job.guid)} booking of {jobSeries.length}, last on {format(parseISO(jobSeries[jobSeries.length - 1].startDate), 'EEE, MMM d, yyyy h:mma')}
                        </div>
                      }
                    </Form.Group>

                    <Form.Group>
                      <ButtonToolbar>
                        {(isRole(ROLE.ADMIN, ROLE.MANAGER) || (!job?.props?.timeOnSiteNotes && addHours(new Date(), 24) <= parseISO(job.startDate))) &&
                          <Button onClick={() => handleSubmitDateChange(job.guid, startDate, endDate, workType)} appearance="primary" size="sm">Save</Button>
                        }
                        {isClockInOutEnabled(job) &&
                          <Dropdown
                            size="sm"
                            placement={"bottomStart"}
                            title="Add Minutes"
                            color="green"
                            appearance="primary"
                            onSelect={(val: any) => {
                              const minutes = +val.replace(/after-|before-/g, ''); 

                              if (val.includes('after')) {
                                handleAddMinutesToAppointment(job.guid, minutes, workType, 'after');
                              } else if (val.includes('before')) {
                                handleAddMinutesToAppointment(job.guid, minutes, workType, 'before');
                              }

                              onUpdate();
                            }}>
                            <Dropdown.Menu title={`Before ${format(parseISO(job.startDate), 'h:mma')}`}>
                              <Dropdown.Item eventKey={`before-30`}>30 minutes</Dropdown.Item>
                              <Dropdown.Item eventKey={`before-60`}>60 minutes</Dropdown.Item>
                              <Dropdown.Item eventKey={`before-90`}>90 minutes</Dropdown.Item>
                            </Dropdown.Menu>
                            <Dropdown.Menu title={`After ${format(parseISO(job.endDate), 'h:mma')}`}>
                              <Dropdown.Item eventKey={`after-30`}>30 minutes</Dropdown.Item>
                              <Dropdown.Item eventKey={`after-60`}>60 minutes</Dropdown.Item>
                              <Dropdown.Item eventKey={`after-90`}>90 minutes</Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        }
                      </ButtonToolbar>
                    </Form.Group>

                    <Form.Group>
                      <Form.ControlLabel>Cleaning Start Time:</Form.ControlLabel>
                      <p>{format(parseISO(job.endDate), 'EEE, MMM d, yyyy h:mma')}</p>
                    </Form.Group>

                    <Form.Group>
                      <Form.ControlLabel>Cleaning End Time:</Form.ControlLabel>
                      <p>{format(addMinutes(parseISO(job.endDate), CONFIG_TIME_PADDING.end), 'EEE, MMM d, yyyy h:mma')}</p>
                    </Form.Group>

                    {isClockInOutEnabled(job) &&
                      <Fragment>
                        <Form.Group>
                          <Form.ControlLabel>Request Changes:</Form.ControlLabel>
                          {requestChanges
                            ? <Form.Control name="notes" accepter={TextareaAutosize} />
                            : <a href="/" onClick={(e: any) => { e.preventDefault(); setRequestChanges(true); }}>Click here to add change request notes</a>
                          }
                        </Form.Group>
                        {requestChanges && <Button onClick={handleSubmit} appearance="primary">Submit</Button>}
                      </Fragment>
                    }

                    {(isRole(ROLE.CLIENT) && isClockInOutEnabled(job) && addHours(new Date(), 24) >= parseISO(job.startDate)) &&
                      <Form.Group>
                        <Divider />
                        <Message className="mb-15" type="info">Time appointments must be can be canceled at least 24 hours before start time</Message>
                      </Form.Group>
                    }
                  </Col>
                </Row>
              </Grid>
            </Form>
          }

          {activeTab === 'in' &&
            <Form fluid formValue={formCheckIn} onChange={setFormCheckIn}>
              <p className="mb-24">
                Please answer the following questions to better improve our services for all collective members:
              </p>
              <Form.Group>
                <Form.ControlLabel>Is your kitchen station cleaned?</Form.ControlLabel>
                <div>
                  <Form.Control
                    name="kitchenStationClean"
                    onChange={() => setFormCheckIn({ ...formCheckIn, kitchenStationClean: !formCheckIn.kitchenStationClean })}
                    checked={formCheckIn.kitchenStationClean === false}
                    accepter={Toggle}
                    checkedChildren="No"
                    unCheckedChildren="Yes" />
                </div>
                {!formCheckIn.kitchenStationClean &&
                  <div>
                    <Form.ControlLabel>Upload Photos:</Form.ControlLabel>
                    <AttachmentForm
                      referenceGuid={job?.guid}
                      attachments={formCheckIn.kitchenStationCleanAttachments}
                      onChange={handleSetAttachmentsKitchenStation}
                      applicationGroup={'kitchen-station'}
                      listType={"list"}
                    />
                  </div>
                }
                <div>
                  <div className="mt-10 mb-10">Comments:</div>
                  <Input
                    as={TextareaAutosize}
                    rows={2}
                    style={{ width: '100%' }}
                    value={formCheckIn.kitchenStationCleanNotes}
                    onChange={(val: string) => {
                      setFormCheckIn({ ...formCheckIn, kitchenStationCleanNotes: val })
                    }} />
                </div>
              </Form.Group>

              <Form.Group>
                <Form.ControlLabel>Did you notice any maintenance issues in the common areas?</Form.ControlLabel>
                <div>
                  <Form.Control
                    name="noMaintenanceIssues"
                    accepter={Toggle}
                    checked={formCheckIn.noMaintenanceIssues}
                    checkedChildren="Yes"
                    unCheckedChildren="No" />
                </div>
                {formCheckIn.noMaintenanceIssues &&
                  <div>
                    <Form.ControlLabel>Upload Photos:</Form.ControlLabel>
                    <AttachmentForm
                      referenceGuid={job?.guid}
                      attachments={formCheckIn.noMaintenanceIssuesAttachments}
                      onChange={handleSetAttachmentsNoMaintenanceIssues}
                      applicationGroup={'maintenance-issues'}
                      listType={"list"}
                    />
                  </div>
                }
                <div>
                  <div className="mt-10 mb-10">Comments:</div>
                  <Input
                    as={TextareaAutosize}
                    rows={2}
                    style={{ width: '100%' }}
                    value={formCheckIn.noMaintenanceIssuesNotes}
                    onChange={(val: string) => {
                      setFormCheckIn({ ...formCheckIn, noMaintenanceIssuesNotes: val })
                    }} />
                </div>
              </Form.Group>
            </Form>
          }

          {activeTab === 'out' &&
            <Fragment>
              {!job?.props?.timeOnSiteNotes
                && <Message className="mb-24" type="error">Clock in must be completed before clock out</Message>
              }
              <Form fluid formValue={formCheckOut} onChange={setFormCheckOut} readOnly={!job?.props?.timeOnSiteNotes}>
                <p className="mb-24">
                  Please confirm all areas below are cleaned before clocking out.
                </p>
                <Form.Group>
                  <Form.ControlLabel>Appliances</Form.ControlLabel>
                  <Form.Control
                    name="appliances"
                    accepter={Toggle}
                    checked={formCheckOut.appliances}
                    checkedChildren="Yes"
                    unCheckedChildren="No" />
                </Form.Group>

                <Form.Group>
                  <Form.ControlLabel>Countertops / Tables</Form.ControlLabel>
                  <Form.Control
                    name="counterTopsTables"
                    accepter={Toggle}
                    checked={formCheckOut.counterTopsTables}
                    checkedChildren="Yes"
                    unCheckedChildren="No" />
                </Form.Group>

                <Form.Group>
                  <Form.ControlLabel>Floors</Form.ControlLabel>
                  <Form.Control
                    name="floors"
                    accepter={Toggle}
                    checked={formCheckOut.floors}
                    checkedChildren="Yes"
                    unCheckedChildren="No" />
                </Form.Group>

                <Form.Group>
                  <Form.ControlLabel>Dishwasher Area</Form.ControlLabel>
                  <Form.Control
                    name="dishwasherArea"
                    accepter={Toggle}
                    checked={formCheckOut.dishwasherArea}
                    checkedChildren="Yes"
                    unCheckedChildren="No" />
                </Form.Group>

                <Form.Group>
                  <Form.ControlLabel>Are all appliances including hood fan turned off?</Form.ControlLabel>
                  <Form.Control
                    name="allAppliances"
                    accepter={Toggle}
                    checked={formCheckOut.allAppliances}
                    checkedChildren="Yes"
                    unCheckedChildren="No" />
                </Form.Group>

                <Form.Group>
                  <Form.ControlLabel>Are you the last one to leave?</Form.ControlLabel>
                  <Form.Control
                    name="lastOneToLeave"
                    accepter={Toggle}
                    checked={formCheckOut.lastOneToLeave}
                    checkedChildren="Yes"
                    unCheckedChildren="No" />
                </Form.Group>

                {formCheckOut.lastOneToLeave &&
                  <Form.Group>
                    <Form.ControlLabel>Did you turn off the lights / appliances and hood fan?</Form.ControlLabel>
                    <Form.Control
                      name="turnOffLights"
                      accepter={Toggle}
                      checked={formCheckOut.turnOffLights}
                      checkedChildren="Yes"
                      unCheckedChildren="No" />
                  </Form.Group>
                }

                <Form.Group>
                  <Form.ControlLabel>Additional Comments:</Form.ControlLabel>
                  <Input
                    as={TextareaAutosize}
                    rows={2}
                    style={{ width: '100%' }}
                    value={formCheckOut.comments}
                    onChange={(val: string) => {
                      setFormCheckOut({ ...formCheckOut, comments: val })
                    }} />
                </Form.Group>
              </Form>
            </Fragment>
          }

          {activeTab === 'log' &&
            <ResponsiveTable
              data={events}
              autoHeight={true}
              rowHeight={40}
            >
              <Column flexGrow={1}>
                <HeaderCell>Event</HeaderCell>
                <Cell>{(row: any) => startCase(row.event)}</Cell>
              </Column>
              <Column flexGrow={1}>
                <HeaderCell>Date</HeaderCell>
                <Cell>{(row: any) => format(parseISO(row.createdAt), FORMAT.DATE_TIME)}</Cell>
              </Column>
              <Column flexGrow={1}>
                <HeaderCell>User</HeaderCell>
                <Cell>{(row: any) => row.username}</Cell>
              </Column>
            </ResponsiveTable>
          }
        </div>
      </Drawer.Body>
      <Drawer.Footer>
        <ButtonToolbar className="mb-12">
          {(isClockInOutEnabled(job) && ['in', 'out'].includes(activeTab || '')) &&
            <Button
              onClick={handleSubmit}
              appearance="primary" size="sm">Save</Button>
          }
          <Button onClick={onHide} appearance="subtle" size="sm">Close</Button>

          {isClockInOutEnabled(job) &&
            <Button color="red"
              onClick={() => {
                showConfirmation(
                  <p>Are you sure you want to cancel the booking on {format(parseISO(job.startDate), 'EEE, MMM d, yyyy h:mma')}? This will cancel only a single booking for this day and hour.</p>, 'Cancel Booking'
                  , async () => {
                    await handleCancelAppointment(job.guid);
                    onUpdate();
                  });
              }}
              disabled={isRole(ROLE.CLIENT) && addHours(new Date(), 24) >= parseISO(job.startDate)}
              appearance="primary" size="sm">Cancel</Button>
          }

          {/* {!isRole(ROLE.CLIENT, ROLE.VIEWER) &&
            <Button color="red"
              onClick={() => {
                showConfirmation(
                  <p>Are you sure you want to delete the booking on {format(parseISO(job.startDate), 'EEE, MMM d, yyyy h:mma')}? This will delete only a single booking for this day and hour.</p>, 'Delete Booking'
                  , async () => {
                    await handleDeleteAppointment(job.guid);
                    onUpdate();
                  });
              }}
              disabled={isRole(ROLE.CLIENT) && addHours(new Date(), 24) >= parseISO(job.startDate)}
              appearance="primary">Delete</Button>
          } */}
        </ButtonToolbar>
      </Drawer.Footer>
    </Drawer>
  );
}

interface IClockInOutButton {
  mode: 'in' | 'out' | 'view' | undefined,
  job: any,
  onClick: (guid: string, mode: string) => void
}

const ClockInOutButton = ({
  mode,
  job,
  onClick
}: IClockInOutButton) => {
  return (
    <Button
      block={isMobileOnly}
      appearance="default"
      // rsuite5
      // placement="right"
      onClick={() => onClick((mode as any), job)}
    >{job?.props?.timeOnSiteNotes ? <LegacyCheckCircleIcon
      style={{ color: job?.props?.[mode === 'in' ? 'timeOnSiteNotes' : 'timeFromSiteNotes'] ? COLOR.SUCCESS : COLOR.DANGER }} /> : <LegacyTimesCircleIcon
      style={{ color: job?.props?.[mode === 'in' ? 'timeOnSiteNotes' : 'timeFromSiteNotes'] ? COLOR.SUCCESS : COLOR.DANGER }} />} Clock {startCase(mode)}</Button>
  );
}

export {
  ClockInOut,
  ClockInOutButton
};
