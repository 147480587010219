import { gql, useApolloClient } from '@apollo/client';
import { Tabs } from 'components/rsuite';
import { usePrairieAuth } from 'contexts/AuthPrairieProvider';
import { parseISO } from 'date-fns';
import { FORMAT, getEnv, ROLE } from 'lib/env';
import { getCustomFields, setCustomFields } from 'lib/form';
import { pick, startCase } from 'lodash';
import { useContext, useRef, useState, useEffect } from 'react';
import { isMobileOnly } from 'react-device-detect';
import {
  Drawer,
  Form,
  SelectPicker,
  Button,
  Schema,
  toaster,
  Message,
  InputNumber,
  CheckboxGroup,
  Checkbox,
  DatePicker,
  CheckPicker,
  Grid,
  Row,
  Col
} from 'rsuite';
import { ApplicationContext } from 'shared';
import { useViewport } from 'shared/ViewportProvider';
import { v4 as uuidv4 } from 'uuid';

interface IEmployeeForm {
  drawer?: boolean,
  show?: boolean,
  guid?: string,
  action?: string,
  onHide: () => void,
  onUpdate?: (data: any) => void
}

const { StringType, NumberType } = Schema.Types;

const EmployeeForm = ({ show, guid, action, drawer, onHide, onUpdate }: IEmployeeForm) => {
  let form: any = useRef();
  const client = useApolloClient();
  const { state } = useViewport();
  const { isRole } = usePrairieAuth();
  const { showError } = useContext(ApplicationContext);
  const [saving, setSaving] = useState(false);
  const [formError, setFormError] = useState({});
  const [formValue, setFormValue] = useState<any>({
    userId: [state.profile.userlevel === 'franchise' ? +state.profile.id : undefined].filter((u: any) => u),
    employeeName: '',
    employeeType: 'contract',
    hourlyPay: 0,
    monthlyPay: 0,
    daysWorking: [],
    guid: uuidv4(),
    endDate: undefined
  });

  useEffect(() => {
    (async function getEmployee() {
      if (guid) {
        const response: any = await client.query({
          query: gql`
            query employee($guid: ID!) {
              employee(guid: $guid){
                userId
                employeeName
                employeeType
                hourlyPay
                monthlyPay
                daysWorking
                customFields
              },
            }`,
          variables: {
            guid
          }
        });

        const employee = response.data.employee;
        setFormValue({
          ...setCustomFields(employee?.customFields || {}),
          customField_lastName: employee?.customFields?.lastName || employee.employeeName,
          userId: employee.userId,
          employeeName: employee.employeeName,
          employeeType: employee.employeeType || 'contract',
          hourlyPay: employee.hourlyPay,
          monthlyPay: employee.monthlyPay,
          daysWorking: employee?.daysWorking || [],
          guid
        });
      }
    })();
  }, [guid]);

  const handleSubmit = async () => {
    if (!form.check()) {
      return;
    }

    try {
      setSaving(true);
      const upsertEmployee = gql`
        mutation upsertEmployee($input: UpsertEmployeeInput!) {
          upsertEmployee(input: $input) {
            success
            code
            message
            result
          }
        }`;

      const input: any = pick(formValue, ['guid', 'userId', 'employeeName', 'employeeType', 'hourlyPay', 'monthlyPay', 'daysWorking']);
      input.hourlyPay = +input.hourlyPay;
      input.monthlyPay = +input.monthlyPay;
      input.customFields = getCustomFields(formValue);

      const response: any = await client.mutate({
        mutation: upsertEmployee, variables: {
          input
        }
      });

      if (response.data.upsertEmployee.success) {
        toaster.push(
          <Message type="success" showIcon closable>{response.data.upsertEmployee.message}</Message>
        );

        onUpdate && onUpdate(response.data.upsertEmployee.result);
        onHide();
      } else {
        showError(response);
      }
      setSaving(false);
    } catch (err) {
      showError(err);
      setSaving(false);
    } finally {
      setSaving(false);
    }
  }

  return (
    <Drawer backdrop='static' size={isMobileOnly ? 'full' : 'md'} open onClose={onHide}>
      <Drawer.Header>
        <Drawer.Title>{startCase(action)} Employee</Drawer.Title>
      </Drawer.Header>
      <Drawer.Body>
        <Form
          ref={(ref: any) => form = ref}
          fluid
          formValue={formValue}
          formError={formError}
          model={formValue.employeeType === 'contract'
            ? Schema.Model({
              hourlyPay: NumberType().isRequired('This field is required.'),
              customField_lastName: StringType().isRequired('This field is required.')
            })
            : Schema.Model({
              monthlyPay: NumberType().isRequired('This field is required.'),
              customField_lastName: StringType().isRequired('This field is required.')
            })}
          onError={setFormError}
          onChange={setFormValue}
        >
          {getEnv() === 'dev' &&
            <div className="mb-12">
              <label>UUID:</label>
              <p>{formValue?.guid}</p>
            </div>
          }

          <Tabs defaultActiveKey="pay-hire-details" appearance="subtle">
            <Tabs.Tab eventKey="pay-hire-details" title="Pay & Hire Details">
              <Grid fluid className='p-0'>
                <Row>
                  <Col md={12} xs={12}>
                    <Form.Group>
                      <Form.ControlLabel className='required'>Last Name:</Form.ControlLabel>
                      <Form.Control name="customField_lastName" />
                    </Form.Group>
                  </Col>
                  <Col md={12} xs={12}>
                    <Form.Group>
                      <Form.ControlLabel>First Name:</Form.ControlLabel>
                      <Form.Control name="customField_firstName" />
                    </Form.Group>
                  </Col>

                  <Col md={12} xs={12}>
                    <Form.Group>
                      <Form.ControlLabel className="required">Employee Type:</Form.ControlLabel>
                      <Form.Control
                        block
                        searchable={false}
                        cleanable={false}
                        name="employeeType"
                        accepter={SelectPicker}
                        data={
                          [
                            { label: 'Salaried', value: 'salaried' },
                            { label: 'Contract', value: 'contract' }
                          ]
                        } />
                    </Form.Group>
                  </Col>
                  <Col md={12} xs={12}>
                    <Form.Group>
                      <Form.ControlLabel className="required">Crew:</Form.ControlLabel>
                      <Form.Control
                        block
                        searchable={true}
                        cleanable={false}
                        readOnly={isRole(ROLE.FRANCHISE, ROLE.WORKER)}
                        groupBy={'role'}
                        name="userId"
                        accepter={CheckPicker}
                        data={state.users.filter((u: any) => u.userlevel === 'franchise' || u.userlevel === 'admin')}
                        labelKey="operatorName"
                        valueKey="id" />
                    </Form.Group>
                  </Col>
                  {formValue.employeeType === 'contract'
                    ? <Col md={12} xs={12}>
                      <>
                        <Form.Group>
                          <Form.ControlLabel className="required">Hourly Pay:</Form.ControlLabel>
                          <Form.Control style={{ width: '100%' }} accepter={InputNumber} name="hourlyPay" />
                        </Form.Group>
                      </>
                    </Col>
                    : <>
                      <Col md={12} xs={12}>
                        <Form.Group>
                          <Form.ControlLabel className="required">Montly Pay:</Form.ControlLabel>
                          <Form.Control style={{ width: '100%' }} accepter={InputNumber} name="monthlyPay" />
                        </Form.Group>
                      </Col>
                      <Col md={12} xs={12}>
                        <Form.Group>
                          <Form.ControlLabel className="required">Days Working:</Form.ControlLabel>
                          <Form.Control
                            style={{ width: '100%' }}
                            accepter={CheckboxGroup}
                            name="daysWorking"
                            inline>
                            {['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'].map((day: string) =>
                              <Checkbox key={day} value={day.toLowerCase()}>{day}</Checkbox>
                            )}
                          </Form.Control>
                          <Form.HelpText>Select days that are inclusive in the payroll. This is used to determine the number of days working per month and amount for daily financials.</Form.HelpText>
                        </Form.Group>
                      </Col>
                    </>
                  }
                  <Col md={12} xs={12}>
                    <Form.Group>
                      <Form.ControlLabel>Hire date:</Form.ControlLabel>
                      <Form.Control
                        name="customField_hireDate"
                        accepter={DatePicker}
                        block
                        format={FORMAT.MONTH_DATE}
                        oneTap
                      />
                    </Form.Group>
                  </Col>
                  <Col md={12} xs={12}>
                    <Form.Group>
                      <Form.ControlLabel>End Date:</Form.ControlLabel>
                      <Form.Control block accepter={DatePicker} name="endDate" />
                    </Form.Group>
                  </Col>
                </Row>
              </Grid>
            </Tabs.Tab>
            <Tabs.Tab eventKey="standard-information" title="Standard Information">
              <Grid fluid className='p-0'>
                <Row>
                  <Col md={12} xs={12}>
                    <Form.Group>
                      <Form.ControlLabel>Birth Date:</Form.ControlLabel>
                      <Form.Control
                        accepter={DatePicker}
                        name="customField_birthDate"
                        block
                        format={FORMAT.MONTH_DATE}
                        oneTap
                        value={typeof(formValue?.customField_birthDate) === 'string' ? parseISO(formValue?.customField_birthDate) : formValue?.customField_birthDate}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={12} xs={12}>
                    <Form.Group>
                      <Form.ControlLabel>Gender:</Form.ControlLabel>
                      <Form.Control
                        accepter={SelectPicker}
                        searchable={false}
                        name="customField_gender"
                        data={[
                          { value: 'male', label: 'Male' },
                          { value: 'female', label: 'Female' },
                          { value: 'nonbinary', label: 'Nonbinary' }
                        ]}
                        block
                      />
                    </Form.Group>
                  </Col>
                  <Col md={12} xs={12}>
                    <Form.Group>
                      <Form.ControlLabel>Email:</Form.ControlLabel>
                      <Form.Control name="customField_email" />
                    </Form.Group>
                  </Col>
                  <Col md={12} xs={12}>
                    <Form.Group>
                      <Form.ControlLabel>Mobile phone:</Form.ControlLabel>
                      <Form.Control name="customField_mobilePhone" />
                    </Form.Group>
                  </Col>
                  <Col md={12} xs={12}>
                    <Form.Group>
                      <Form.ControlLabel>Address:</Form.ControlLabel>
                      <Form.Control name="customField_address" />
                    </Form.Group>
                  </Col>
                  <Col md={12} xs={12}>
                    <Form.Group>
                      <Form.ControlLabel>City:</Form.ControlLabel>
                      <Form.Control name="customField_city" />
                    </Form.Group>
                  </Col>
                  <Col md={12} xs={12}>
                    <Form.Group>
                      <Form.ControlLabel>Postal/Zip Code:</Form.ControlLabel>
                      <Form.Control name="customField_postalZipCode" />
                    </Form.Group>
                  </Col>
                </Row>
              </Grid>
            </Tabs.Tab>
            <Tabs.Tab eventKey="emergency-contact" title="Emergency Contact">
              <Grid fluid className='p-0'>
                <Row>
                  <Col md={12} xs={12}>
                    <Form.Group>
                      <Form.ControlLabel>Last Name:</Form.ControlLabel>
                      <Form.Control name="customField_emergencyContactLastName" />
                    </Form.Group>
                  </Col>
                  <Col md={12} xs={12}>
                    <Form.Group>
                      <Form.ControlLabel>First Name:</Form.ControlLabel>
                      <Form.Control name="customField_emergencyContactFirstName" />
                    </Form.Group>
                  </Col>
                  <Col md={12} xs={12}>
                    <Form.Group>
                      <Form.ControlLabel>Mobile phone:</Form.ControlLabel>
                      <Form.Control name="customField_emergencyContactMobilePhone" />
                    </Form.Group>
                  </Col>
                  <Col md={12} xs={12}>
                    <Form.Group>
                      <Form.ControlLabel>Relationship:</Form.ControlLabel>
                      <Form.Control
                        name="customField_emergencyContactRelationship"
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Grid>
            </Tabs.Tab>
          </Tabs>
        </Form>
      </Drawer.Body>
      <Drawer.Actions>
        <Button onClick={() => handleSubmit()} appearance="primary" size="sm">Save</Button>
        <Button onClick={() => onHide()} appearance="subtle" size="sm">Close</Button>
      </Drawer.Actions>
    </Drawer>
  );
}

export default EmployeeForm;
