import { parseNumber } from 'lib/env';
import { moneyFormatter } from 'lib/tenant';
import React, { useEffect, useState } from 'react';
import { Input } from 'rsuite';

interface IInputMoney {
  allowEmpty?: boolean,
  value?: string | undefined,
  onChange?: (value: any, event: React.SyntheticEvent<HTMLElement>) => void,
  onBlur?: (event: React.SyntheticEvent<HTMLElement>, value: any) => void,
  tabIndex?: number | undefined,
  disabled?: boolean | undefined,
  readOnly?: boolean | undefined,
  style?: any
};

let timeout: NodeJS.Timeout;

const InputMoney = ({
  style,
  allowEmpty,
  value,
  tabIndex,
  disabled,
  readOnly,
  onBlur,
  onChange
}: IInputMoney) => {
  const [formValue, setFormValue] = useState(value);

  useEffect(() => {
    setFormValue(value);
  }, [value]);

  const handleOnChange = (val: string, e: any) => {
    setFormValue(val);

    if (onChange) {
      if (timeout) {
        clearTimeout(timeout);
      }

      timeout = setTimeout(() => {
        onChange(val, e);
      }, 1000);
    }
  };

  return (
    <Input
      type="text"
      style={style}
      disabled={disabled}
      readOnly={readOnly}
      tabIndex={tabIndex}
      value={formValue}
      onKeyPress={(event: any) => {
        if (!/[0-9,.-]/.test(event.key)) {
          event.preventDefault();
        }
      }}
      onChange={(val: string, e: any) => {
        handleOnChange(val, e);
      }}
      onBlur={(e: any) => {
        if (typeof (allowEmpty) !== undefined && allowEmpty !== true) {
          const numberValue = moneyFormatter.format(parseNumber(e.target.value)).replace('$', '');
          setFormValue(numberValue);
          if (onBlur) {
            onBlur(e, numberValue);
          }
        }
      }}
    />
  );
}

export default InputMoney;
