import { gql } from "@apollo/client";

const GET_TEMPLATES = gql`
  query templates($limit: Int!, $offset: Int!, $where: TemplatesWhere, $order: [TemplatesSort]) {
    templates(filter: {
      limit: $limit
      offset: $offset
      order: $order
      where: $where
    }) {
      edges {
        node {
          guid
          id
          name
          key
          subject
          type
          file
          format
          emailId
          isVisible
          services
          latestVersionId
          deletedAt
        }
      },
      totalCount
    }
  }
`;

const GET_TEMPLATES_WITH_VERSIONS = gql`
  query templates($limit: Int!, $offset: Int!, $where: TemplatesWhere, $order: [TemplatesSort]) {
    templates(filter: {
      limit: $limit
      offset: $offset
      order: $order
      where: $where
    }) {
      edges {
        node {
          guid
          id
          name
          key
          subject
          type
          file
          format
          emailId
          isVisible
          services
          deletedAt
          versions {
            id
            guid
            version
            createdAt
            modifiedAt
            fileName
          }
        }
      },
      totalCount
    }
  }
`;

const GET_TEMPLATE = gql`
  query template($id: Int, $guid: ID) {
    template(id: $id, guid: $guid) {
      guid
      id
      name
      key
      subject
      type
      file
      format
      emailId
      isVisible
      services
      versions {
        id
        guid
        version
        createdAt
        fileName
      }
    }
  }
`;

export {
  GET_TEMPLATE,
  GET_TEMPLATES,
  GET_TEMPLATES_WITH_VERSIONS
}
