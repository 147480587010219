import { useContext, useEffect, useRef, useState } from 'react';
import { Drawer, Button, Checkbox, Whisper, Tooltip, Grid, Row, Col, SelectPicker } from 'rsuite';
import { isMobileOnly } from 'react-device-detect';
import { orderBy, without } from 'lodash';
import store from 'lib/storage';
import { ApplicationContext } from 'shared';
import { getEnv } from 'lib/env';
import { IS_ASSINIBOINE, IS_BUGABOO, IS_GREYROCK, IS_KALADI_KITCHENS } from 'lib/tenant';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import { useViewport } from 'shared/ViewportProvider';
import { MdEdit } from 'react-icons/md';
import Icon from '@rsuite/icons/lib/Icon';

const defaultColumns: any = {
  documents: ['Document Type', 'Customer Name', 'Title', 'Status', 'Work Group', 'Crew', 'Start', 'End', 'Last Email'],
  workOrders: ['Work ID', 'Status', 'Customer Name', 'Customer Label', 'Work Group', 'Services', 'Start', 'End', 'Crew', 'Total Cost', 'Type Cost'],
  workOrdersCompare: ['Work ID', 'Status', 'Customer Name', 'Work Group', 'Services', 'Start', 'End', 'Crew', 'Total Cost', 'Type Cost'],
  emailActivity: ['Email', 'Type', 'Events', 'Date', 'Subject', 'Destination'],
  users: ['Username', 'Crew Name', 'Impersonation', 'Workers', 'Role', 'Contact', 'Work Group', 'Phone'],
  invoices: ['Number', 'Work ID', 'Status', 'Group', 'Services', 'Crew', 'Date', 'Subtotal', 'Taxes', 'Total'],
  customers: ['Customer ID', 'Customer Name', (IS_ASSINIBOINE ? 'Work Address' : 'Address'), 'Postal Code', 'Contact Name', 'Phone', 'Email', 'Groups'],
  jobs: []
};

if (IS_KALADI_KITCHENS) {
  defaultColumns.documents = ['Document Type', 'Customer Name', 'Start', 'End', 'Last Email', 'Status'];
}

const extraColumns: any = {
  documents: ['Document ID', 'Document Template', 'Customer Address', 'Work Orders', 'Number', 'Expires', 'Crew Notes', 'Customer Notes', 'Cost', 'Created At', 'Modified At', 'Type'],
  workOrders: [
    'Services Detailed',
    (getEnv() === 'dev' ? 'UUID' : null),
    (IS_BUGABOO ? 'Deicer' : null),
    'Customer Address',
    'Document', 'Worker',
    'Recurrence', 'Jobs Included', 'Jobs Completed', 'Jobs Scheduled', 'Repeat On', 'Cost Type', 'Work Comments', 'Work Comments (Preview)', 'Contract Notes', 'Contract Value', 'Contract Type', 'Time Spent',
    'SPMH',
    'Created By', 'Created At', 'Modified At'].filter((s: any) => s),
  workOrdersCompare: [
    'Document', 'Worker',
    'Recurrence', 'Jobs Included', 'Jobs Completed', 'Jobs Scheduled', 'Repeat On', 'Cost Type', 'Work Comments', 'Work Comments (Preview)', 'Contract Notes', 'Contract Value', 'Contract Type', 'Time Spent',
    'SPMH',
    'Created By', 'Created At', 'Modified At'].filter((s: any) => s),
  emailActivity: [],
  users: ['Email', 'P&L Percentage', 'Royalties', 'Last Session', 'Created At', 'Modified At'],
  invoices: [(IS_GREYROCK ? 'Amount Due (Stripe)' : null), (IS_GREYROCK ? 'Amount Paid (Stripe)' : null), 'Work Status', 'Created At', 'Modified At'],
  customers: (IS_KALADI_KITCHENS ? ['Lease Type', 'Lease Hours', 'Lease Extra Hours', 'Lease Ends At', 'Label', 'Created At', 'Modified At'] : ['Contact Details', 'Label', 'Property Manager', ((IS_ASSINIBOINE || IS_GREYROCK) ? 'Site Address' : undefined), 'Square Footage', 'Years Active', 'Created At', 'Modified At'].filter((s: any) => s)),
  jobs: ['Recurrence', 'Contract Notes', 'Completed By', 'Completed At']
}

const editableColumns: any = {
  documents: [],
  workOrders: ['Work Comments', 'Contract Notes', 'Type Cost', 'Total Cost', 'Contract Value', 'Worker', 'Crew', 'Repeat On', 'Cost Type', 'Services Detailed'],
  workOrdersCompare: [],
  emailActivity: [],
  users: [],
  invoices: [],
  customers: ['Square Footage'],
  jobs: []
}

// for customer filtering
Object.keys(defaultColumns).forEach((k: string) => {
  defaultColumns[k + 'Customer'] = defaultColumns[k];
  extraColumns[k + 'Customer'] = extraColumns[k];
  editableColumns[k + 'Customer'] = editableColumns[k];
});

const ColumnChooser = () => {
  const container = useRef() as React.MutableRefObject<HTMLDivElement>;
  const [selectedColumns, setSelectedColumns] = useState<any>([]);
  const {
    drawerData,
    showDrawer,
    onUpdateDrawerCallback
  } = useContext(ApplicationContext);

  useEffect(() => {
    setSelectedColumns(store.session.get(`${drawerData?.storageKey}Columns`) ? store.session.get(`${drawerData?.storageKey}Columns`) : defaultColumns[drawerData?.storageKey]);
  }, [drawerData?.storageKey]);

  const EditableWhisper = (props: any) => {
    return <div key={props.editableKey}>
      {props.editable
        ? <Whisper placement="top" speaker={<Tooltip>Allows Inline Editing</Tooltip>}><span>{props.children} <Icon as={MdEdit} style={{ color: '#337ab7' }} /></span></Whisper>
        : <span>{props.children}</span>
      }
    </div>;
  }

  return (
    <Drawer
      open
      size={isMobileOnly ? 'full' : 'sm'}
      onClose={() => showDrawer(undefined)}
    >
      <Drawer.Header>
        <Drawer.Title>Select Columns</Drawer.Title>
      </Drawer.Header>
      <Drawer.Body>
        {defaultColumns[drawerData?.storageKey] && [...defaultColumns[drawerData?.storageKey], ...(extraColumns[drawerData?.storageKey].filter((k: any) => k))].map((col: any, index: number) =>
          <EditableWhisper key={col} col={col} editableKey={`${col}-${index}`} editable={editableColumns[drawerData?.storageKey].includes(col)}>
            <Checkbox
              onChange={(val: any) => {
                if (selectedColumns.includes(val)) {
                  setSelectedColumns(without(selectedColumns, val));
                } else {
                  setSelectedColumns([...selectedColumns, val]);
                }
              }}
              value={col}
              checked={selectedColumns.includes(col)}>
              {col}
            </Checkbox>
          </EditableWhisper>
        )}
      </Drawer.Body>
      <Drawer.Footer>
        <Button onClick={() => {
          store.session.set(`${drawerData?.storageKey}Columns`, selectedColumns);
          window.location.href = window.location.href;
        }} appearance="primary" size="sm">Save</Button>
        <Button onClick={() => showDrawer(undefined)} appearance="subtle" size="sm">Close</Button>
      </Drawer.Footer>
    </Drawer>
  );
}

interface IAdvancedFilter {
  show: boolean,
  storageKey: string,
  onHide: () => void,
  onSave: () => void
}

const SortableItem = SortableElement((props: any) => <Row className='mb-4'>
  <Col md={1} xs={2}>
    <DragHandle />
  </Col>
  <Col xs={10}>
    {props.label}
  </Col>
  <Col xs={12}>
    <SelectPicker
      searchable={false}
      block
      name={props.value}
      value={props.formValue}
      onChange={(value: any) => props.handleChange({ name: props.value, value })}
      cleanable
      data={[
        { label: 'Ascending', value: 'ASC' },
        { label: 'Descending', value: 'DESC' }
      ]}
      onClean={() => props.handleClean(props.value)}
    />
  </Col>
</Row>
);

const SortableList = SortableContainer((children: any) => <Grid fluid>
  {children.children}
</Grid>
)

const DragHandle = SortableHandle(() => <span style={{ cursor: 'grab' }}>&nbsp;::</span>);

const AdvancedFilter = () => {
  const container = useRef() as React.MutableRefObject<HTMLDivElement>;
  const {
    showDrawer,
    drawerData,
    onUpdateDrawerCallback
  } = useContext(ApplicationContext);
  const [form, setForm] = useState<any>({});
  const { dispatch } = useViewport();
  const drawerOrder = JSON.parse(store.session.get(`${drawerData.id}-order`, '[]'));

  const [sortColumns, setSortColumns] = useState(
    orderBy(
      (drawerData?.sortableColumns || []).map((column: any, sortOrder: number) => {
        const drawerOrderIndex = drawerOrder.findIndex((o: any) => Object.keys(o)[0] === column.value);
        column.sortOrder = drawerOrderIndex === -1 ? sortOrder + 999 : drawerOrderIndex;
        return column;
      }
    ), ['sortOrder'], ['asc']).map((c: any, sortOrder: number) => ({ ...c, sortOrder })));

  useEffect(() => {
    const order = JSON.parse(store.session.get(`${drawerData.id}-order`, '[]'));

    if (order.length > 0) {
      let newForm: any = {};
      order.forEach((o: any) => {
        newForm[Object.keys(o)[0]] = Object.values(o)[0];
      });

      setForm(newForm);
    }
  }, []);

  const handleSortEnd = ({ oldIndex, newIndex }: any) => {
    const newSort = arrayMove(sortColumns, oldIndex, newIndex)
      .map((s: any, index: number) => {
        s.sortOrder = index;
        return s;
      });
    setSortColumns(newSort);
    // console.log(newSort)
  };

  // console.log(sortColumns);

  return (
    <Drawer
      open
      size={isMobileOnly ? 'full' : 'sm'}
      onClose={() => showDrawer(undefined)}
    >
      <Drawer.Header>
        <Drawer.Title>Sort Columns</Drawer.Title>
      </Drawer.Header>
      <Drawer.Body ref={container}>
        <SortableList key={JSON.stringify(sortColumns)} onSortEnd={handleSortEnd} helperClass="sortableHelper" useDragHandle>
          {sortColumns.map((col: any, index: number) =>
            <SortableItem
              {...col}
              index={col.sortOrder}
              key={`item-${col.value}`}
              formValue={form[col.value]}
              handleChange={(data: any) => {
                const newForm = { ...form };
                newForm[data.name] = data.value;
                setForm(newForm);
              }}
              handleClean={(name: string) => {
                const newForm = { ...form };
                delete newForm[name];
                setForm(newForm);
              }}
            />
          )}
        </SortableList>

      </Drawer.Body>
      <Drawer.Footer>
        <Button onClick={() => {
          store.session.set(`${drawerData.id}-order`, JSON.stringify(Object.keys(form).map((f: any) => ({ [f]: form[f] }))));
          dispatch({ type: 'SET_MODIFIED_AT' });
          showDrawer(undefined);
        }} appearance="primary" size="sm">Save</Button>
        <Button onClick={() => {
          store.session.remove(`${drawerData.id}-order`);
          dispatch({ type: 'SET_MODIFIED_AT' });
          showDrawer(undefined);
        }} appearance="subtle" size="sm">Clear All</Button>
        <Button onClick={() => showDrawer(undefined)} appearance="subtle" size="sm">Close</Button>
      </Drawer.Footer>
    </Drawer>
  );
}

export default AdvancedFilter;
export { ColumnChooser, AdvancedFilter, defaultColumns, extraColumns };
