import { useApi } from 'lib';
import { ACL_LIST, getTenant } from 'lib/env';
import { createContext, useContext, useState, useEffect } from 'react'
import { useLocation } from 'react-router';
import { useQueryString } from 'lib/hooks';
import jwtDecode from 'jwt-decode'
import store from 'lib/storage';

interface IAuthPrairieContext {
  token: string | undefined,
  profile: any,
  isAuthenticated: boolean,
  error: boolean,
  scope: string[]
}

const AuthPrairieContext = createContext<IAuthPrairieContext>({
  token: undefined,
  profile: {},
  scope: [],
  isAuthenticated: false,
  error: true
});

const AuthPrairieProvider = ({ children }: any) => {
  const api = useApi();
  const location = useLocation();
  const query = useQueryString();
  const [token, setToken] = useState<string | undefined>(undefined);
  const [profile, setProfile] = useState<any>({});
  const [isAuthenticated, setIsAuthenticated] = useState<any>(undefined);
  const [error, setError] = useState<any>(undefined);
  const [scope, setScope] = useState<string[]>([]);

  useEffect(() => {
    (async function getToken() {
      try {
        setError(undefined);
        const payload: any = {
          grant_type: 'token',
          client_id: getTenant().client_id
        };

        if (query.access_token) {
          payload.access_token = query.access_token;
          payload.refresh_token = query.refresh_token;
        }

        const token: any = await api.post('/oauth/v2/token', payload);

        if (token?.code === 403) {
          setIsAuthenticated(false);
        } else if (token?.access_token) {
          const data: any = jwtDecode(token?.access_token);
          setToken(token.access_token);
          setProfile(data?.data);
          setIsAuthenticated(true);
          setScope(data?.scope ? data?.scope.split(' ') : [...ACL_LIST]);
        }

        if (isAuthenticated === false && location.pathname.indexOf('/auth') === -1) {
          window.location.href = '/auth';
        }
      } catch (err: any) {
        if (err?.response?.status !== 403) {
          setError(err?.response);

          if (location.pathname.indexOf('/auth/logout') > -1) {
            await api.post('/oauth/v2/logout', {});
            store.clearAll();
            window.location.href = '/auth';
          }
        } else {
          setIsAuthenticated(false);

          if (location.pathname.indexOf('/auth') === -1) {
            window.location.href = '/auth';
          }
        }
      }
    })();
  }, [location]);

  return (
    <AuthPrairieContext.Provider value={{ token, profile, isAuthenticated, error, scope }}>
      {children}
    </AuthPrairieContext.Provider>
  )
}

const usePrairieAuth = () => {
  const context = useContext(AuthPrairieContext);

  if (!context) {
    throw new Error('usePrairieAuth must be used inside a `AuthPrairieProvider`');
  }

  const isRole = (...roles: any) => {
    const role = context?.profile?.role || 'worker';
    return roles.includes(role);
  };

  const canNot = (scope: string) => {
    const profilePermissions = context?.scope || [];

    // Administrator has access to everything
    if (context?.profile?.role === 'admin') {
      return false;
    }

    return !profilePermissions.includes(scope);
  }

  const can = (...acl: any) => {
    const profile = context?.profile;
    const profilePermissions = context?.scope;

    // Administrator has access to everything
    if (context?.profile?.role === 'admin' || context?.profile?.role === 'manager') {
      return true;
    }

    if (profilePermissions.length > 0) {
      return profilePermissions.some((a: any) => acl.includes(a));;
    }

    let aclList: string[] = [...ACL_LIST];

    if (profile.tenant === 'kaladikitchens') {
      aclList = aclList.filter((a: string) => !['invoice:list'].includes(a));
    }

    if (profile.role === 'manager') {
      // return true;
    }

    if (profile.role === 'worker') {
      aclList = [
        'clock-in-out:create',
        'job:list',
        'job:calendar',
        'impersonate:create',
        'clock-in-out:list'
      ];
    }

    if (profile.role === 'client') {
      aclList = [
        'job:calendar',
        'job:list',
        'work-order:list'
      ];
    }

    if (profile.role === 'viewer') {
      aclList = [
        'job:calendar',
        'job:list',
      ];
    }

    if (profile.role === 'franchise') {
      aclList = aclList.filter((a: string) => {
        if ([
          'email-activity:list',
          'field:list',
          'action:list',
          'company:list',
          'billing:list',
          'integration:list',
          'template:list'
        ].includes(a)) return false;
        return true;
      });
    }

    if (profile?.plan === 'essential') {
      aclList = [
        'customer:create', 'customer:list',
        'billing:list',
        'impersonate:create',
        'company:create', 'company:list', 'company:update',
        'field:create', 'field:list', 'field:update',
        'job:list', 'job:order', 'job:print-list', 'job:schedule',
        'user:create', 'user:list', 'user:update',
        'worker:create', 'worker:list', 'worker:update',
        'note:create', 'note:list',
        'profile:update',
        'work-order:create', 'work-order:list'
      ];
    }

    return aclList.some((a: any) => acl.includes(a));
  }

  return {
    ...context,
    can,
    canNot,
    isRole,
    profile: context?.profile
  }
}

export {
  usePrairieAuth
}

export default AuthPrairieProvider;
