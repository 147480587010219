import compareVersions from 'compare-versions';
import { usePrairieAuth } from 'contexts/AuthPrairieProvider';
import { Link, useHistory } from 'react-router-dom';
import store from 'lib/storage';

const CustomLink = ({ to, onClick, ...otherProps }: any) => {
  const history = useHistory();
  const { can } = usePrairieAuth();

  return (Array.isArray(otherProps?.can) && can(...otherProps?.can))
    ? <Link
      to={to}
      onClick={(e: any) => {
        e.preventDefault();
        if (typeof (onClick) !== undefined) {
          onClick();
        }

        if (process.env.REACT_APP_VERSION && store.get('clientVersion')) {
          const status = compareVersions(process.env.REACT_APP_VERSION, store.get('clientVersion'));

          if (status !== 0) {
            return (window.location = to);
          }
        }

        return history.push(to);
      }}
      {...otherProps}
    /> : <></>;
};

export default CustomLink;
