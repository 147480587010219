import { useState, useEffect, Fragment, useContext } from 'react';
import {
  Button,
  ButtonToolbar,
  Input,
  toaster,
  SelectPicker,
  Drawer,
  PanelGroup,
  Panel,
  Message,
  Form,
  Checkbox,
  Loader,
} from 'rsuite';
import { hasMonerisIntegration, hasStripeIntegration, parseNumber, ROLE } from 'lib/env';
import { ApplicationContext } from 'shared';
import { isMobileOnly } from 'react-device-detect';
import ResponsiveNav from '@rsuite/responsive-nav';
import { v4 as uuidv4 } from 'uuid';
import { gql, useApolloClient } from '@apollo/client';
import { useViewport } from 'shared/ViewportProvider';
import { usePrairieAuth } from 'contexts/AuthPrairieProvider';
import { QUERY_INVOICE } from 'gql/invoices';
import { CONFIG_NOTIFICATION_DURATION } from 'tenant/config';

interface ICaptureAmount {
  onHide: () => void,
  onProcessed?: () => void,
  show: boolean,
  invoiceGuid: string | undefined,
  defaultTab?: string | undefined
}

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

const CaptureAmount = ({
  onHide,
  onProcessed,
  show,
  invoiceGuid,
  defaultTab
}: ICaptureAmount) => {
  const client = useApolloClient();
  const { state } = useViewport();
  const { isRole } = usePrairieAuth();
  const [selectedAmount, setSelectedAmount] = useState<string>('full');
  const [captureAmount, setCaptureAmount] = useState<string | undefined>('');
  const [captureMultiple, setCaptureMultiple] = useState(false);
  const [paymentNotes, setPaymentNotes] = useState('');
  const [paymentStatus, setPaymentStatus] = useState<any>('paid');
  const [capturing, setCapturing] = useState(false);
  const [activeTab, setActiveTab] = useState<any>(defaultTab);
  const { showError } = useContext(ApplicationContext);
  const [invoice, setInvoice] = useState<any>(undefined);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async function getInvoice() {
      setLoading(true);
      const data = await client.query({
        query: QUERY_INVOICE,
        variables: {
          guid: invoiceGuid
        },
        fetchPolicy: 'no-cache'
      });

      setInvoice(data.data.invoice);
      setLoading(false);
    })();
  }, [invoiceGuid]);

  useEffect(() => {
    if (invoice) {
      setCaptureMultiple(false);
      setCaptureAmount(invoice.total);
      setSelectedAmount('full');
    }
  }, [invoice]);

  useEffect(() => {
    if (invoice) {
      setCaptureAmount(invoice.total);
      setActiveTab(defaultTab || 'capture');
    }
  }, [defaultTab, invoice]);

  const handleSubmit = async (invoice: any) => {
    const val = parseNumber(captureAmount);
    const guid = uuidv4();

    try {
      setCapturing(true);

      const captureBillingAmount = gql`
        mutation captureBillingAmount($input: BillingCaptureInput!) {
          captureBillingAmount(input: $input) {
            success
            code
            message
            result
          }
        }`;

      const response: any = await client.mutate({
        mutation: captureBillingAmount, variables: {
          input: {
            guid,
            invoiceGuid: invoice.guid,
            captureType: selectedAmount,
            amount: +(captureAmount || 0),
            generateNewOrderId: captureMultiple,
            paymentStatus,
            paymentNotes,
            billingCustomerId: invoice.billingProfile
          }
        }
      });

      if (response.data.captureBillingAmount.success) {
        toaster.push(
          <Message type="success" showIcon closable>{response.data.captureBillingAmount.message}</Message>
        );
        onHide();

        if (onProcessed) {
          onProcessed();
        }
      } else {
        toaster.push(
          <Message type="error" showIcon closable>{response.data.captureBillingAmount.message}</Message>, { duration: CONFIG_NOTIFICATION_DURATION }
        );
      }
    } catch (err) {
      showError(err);
    } finally {
      setCapturing(false);
    }
  }

  return (
    <Drawer backdrop='static' open={show} onClose={onHide} size={'sm'} full={isMobileOnly}>
      <Drawer.Header>
        <Drawer.Title>Capture {invoice?.customerName}</Drawer.Title>
      </Drawer.Header>
      <Drawer.Body>
        {loading
          ? <Loader content="Loading..." />
          : <div>
            <ResponsiveNav appearance="subtle" activeKey={activeTab} onSelect={setActiveTab}>
              <ResponsiveNav.Item eventKey="capture">Capture</ResponsiveNav.Item>
              {!isRole(ROLE.WORKER, ROLE.FRANCHISE) && <ResponsiveNav.Item eventKey="history">History</ResponsiveNav.Item>}
            </ResponsiveNav>
            <div className="mt-6">
              {activeTab === 'capture' &&
                <Form>
                  {hasStripeIntegration(state.companies) && <>
                    {invoice.paymentStatus === 'paid' && <Message type="success">This invoice is marked as paid, see <strong>{JSON.parse(invoice.monerisResponse || '{}')?.id}</strong> for reference.</Message>}
                  </>}
                  {(hasMonerisIntegration(state.companies) && state.companies[0].integrations.monerisTest) &&
                    <Message type="warning" className={"mt-10 mb-10"}>Moneris integration is currently operating in test mode.</Message>
                  }

                  {(hasStripeIntegration(state.companies) && state.companies[0].integrations.stripeTest) &&
                    <Message type="warning" className={"mt-10 mb-10"}>Stripe integration is currently operating in test mode. All transactions will appear under Test portal.</Message>
                  }
                  <Form.Group>
                    {hasStripeIntegration(state.companies) &&
                      <>
                        <Form.ControlLabel>Capture Items</Form.ControlLabel>
                        {invoice.items.map((item: any) => 
                          <div>
                            {item.formattedDescription}: {formatter.format(parseNumber(item.cost))}
                          </div>
                        )}
                        <div>Taxes: {formatter.format(invoice.taxes1)}</div>
                        <br />
                      </>
                    }

                    <Form.ControlLabel>Capture Type</Form.ControlLabel>

                    {(hasMonerisIntegration(state.companies) || hasStripeIntegration(state.companies)) &&
                      <SelectPicker
                        block
                        cleanable={false}
                        value={selectedAmount}
                        onChange={(val: any) => {
                          setSelectedAmount(val);
                          setPaymentStatus(val === 'full' ? 'paid' : 'not_paid')
                        }}
                        data={
                          [
                            { value: 'full', label: 'Capture Full Amount: ' + formatter.format(parseNumber(invoice.total)) },
                            { value: 'custom', label: 'Capture Custom Amount: ' + formatter.format(parseNumber(invoice.total)) }
                          ]
                        }
                        searchable={false}
                      />
                    }
                  </Form.Group>
                  {(selectedAmount === 'custom') &&

                    <Form.Group>
                      <Form.ControlLabel>Amount:</Form.ControlLabel>
                      <Input value={captureAmount} onChange={setCaptureAmount} style={{ width: '100%' }} />
                      <Form.HelpText>Custom capture amount will not auto include taxes</Form.HelpText>
                    </Form.Group>
                  }

                  {hasMonerisIntegration(state.companies) &&
                    <Form.Group>
                      <Form.ControlLabel>Capture Multiple:</Form.ControlLabel>
                      <Checkbox checked={captureMultiple} onClick={() => setCaptureMultiple(!captureMultiple)}>Generate new order id</Checkbox>
                      <Form.HelpText>Order ids are unique to prevent duplicate captures. Select to append timestamp to order id and
                        allow multiple captures of same order.
                      </Form.HelpText>
                    </Form.Group>
                  }

                  <Form.Group>
                    <Form.ControlLabel>Payment Status:</Form.ControlLabel>
                    <SelectPicker
                      searchable={false}
                      cleanable={false}
                      value={paymentStatus}
                      data={[
                        { label: 'Not Paid', value: 'not_paid' },
                        { label: 'Paid', value: 'paid' }
                      ]}
                      onChange={setPaymentStatus}
                      style={{ width: '100%' }}
                    />
                    <Form.HelpText>Full capture is automatically marked as paid, and custom amount not paid. Change the invoice status
                      if payment was received via other means.
                    </Form.HelpText>
                  </Form.Group>

                  <Form.Group>
                    <Form.ControlLabel>Payment Notes:</Form.ControlLabel>
                    <Input value={paymentNotes} onChange={setPaymentNotes} style={{ width: '100%' }} />
                  </Form.Group>
                </Form>
              }

              {activeTab === 'history' &&
                <div>
                  {(!invoice || (invoice && invoice.captures.filter((c: any) => c.response).length === 0))
                    ? <p>No invoice capture history found. Future captures will be displayed here.</p>
                    : <PanelGroup>
                      {invoice.captures.map((e: any, index: number) => {
                        const response = JSON.parse(e.response);
                        return response
                          ? <>
                            {hasMonerisIntegration(state.companies) &&
                              <Panel key={`history-${index}`} header={response.Message + ' on ' + response.TransDate + ' ' + response.TransTime}>
                                <div>{JSON.stringify({ notes: e.notes, ...response }, null, 2)}</div>
                              </Panel>
                            }

                            {hasStripeIntegration(state.companies) &&
                              <Panel key={`history-${index}`} header={response.id + ' on ' + new Date(+(response.created + '000')).toString()}>
                                <div>{JSON.stringify({ notes: e.notes, ...response }, null, 2)}</div>
                              </Panel>
                            }
                          </>
                          : <Fragment key={`history-${index}`}></Fragment>
                      })}
                    </PanelGroup>
                  }
                </div>
              }
            </div>
          </div>
        }
      </Drawer.Body>
      <Drawer.Footer>
        <ButtonToolbar>
          <Button
            size='sm'
            onClick={() => handleSubmit(invoice)}
            appearance="primary"
            loading={capturing}
            disabled={loading || !invoice?.guid || (selectedAmount === 'custom' && parseNumber(captureAmount) === 0)}
          >Capture</Button>
          <Button size='sm' onClick={onHide} disabled={capturing} appearance="subtle">Close</Button>
        </ButtonToolbar>
      </Drawer.Footer>
    </Drawer>
  );
}

export default CaptureAmount;
