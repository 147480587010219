import { gql } from "@apollo/client";
import { pick } from "lodash";

const saveField = async (client: any, formValue: any) => {
  const upsertField = gql`
    mutation upsertField($input: UpsertFieldInput!) {
      upsertField(input: $input) {
        success
        code
        message
        result
      }
    }`;
  
  const response: any = await client.mutate({
    mutation: upsertField, variables: {
      // the array is taken from the input list
      input: pick(formValue, ['salesPerManHourTarget', 'section', 'guid', 'emailTemplateGuid', 'quickbooksCode', 'quickbooksDescription', 'isDefault', 
        'invoiceTemplateGuid', 'termsConditionsVersionGuid', 'serviceDescriptionsVersionGuid', 'title', 'parentId', 'sortOrder', 'isVisible', 'visibleOnBooking', 
        'visibleOnJob', 'allowFreeEntry', 'applicationGroup', 'description', 'defaults', 'custom', 'props', 'startDate', 'endDate', 'color'])
    }
  });

  return response;
}

export {
  saveField
}