import { Button, Stack, Table, Tooltip, Whisper } from "rsuite";
import { ResponsiveTable } from "shared";
import IconButtonWrapper from "shared/IconButtonWrapper";

interface IAttachmentList {
  attachments: any,
  showHeader?: boolean
}

const { Column, HeaderCell, Cell } = Table;

const AttachmentList = ({
  attachments,
  showHeader
}: IAttachmentList) => {

  return <Stack wrap spacing={6} style={{ verticalAlign: 'top' }}>
    {attachments.map((file: any) =>
      <div key={file.guid}>
        <div>
          <a href={`https://${window.location.host}/api/attachments/download/${file.guid}`}>
            <img src={`https://${window.location.host}/api/attachments/download/${file.guid}`} style={{ width: '300px' }} />
          </a>
        </div>
        <div>
          Description: {file.description || '-'}
        </div>
      </div>
    )}
  </Stack>
}

export default AttachmentList;