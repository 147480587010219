import { usePrairieAuth } from "contexts/AuthPrairieProvider";
import jwtDecode from "jwt-decode";
import { useViewport } from "shared/ViewportProvider";

const useCompany = () => {
  const { state } = useViewport();
  const { token } = usePrairieAuth();

  const decodedToken: any = jwtDecode(token!);
  return state.companies.find((c: any) => c.guid === decodedToken?.data?.company);
}

export default useCompany;
