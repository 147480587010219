import { gql, useApolloClient, useQuery } from '@apollo/client';
import { AttachmentForm, AttachmentList } from 'components/attachment';
import { format, parseISO } from 'date-fns';
import { GET_CUSTOMER } from 'gql/customer';
import { getEnv } from 'lib/env';
import { useQueryString } from 'lib/hooks';
import { startCase } from 'lodash';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { MdCheckCircle } from 'react-icons/md';
import { useRouteMatch } from 'react-router-dom';
import { Form, Loader, Panel, SelectPicker, Input, Radio, Grid, Col, DatePicker, Row, Button, Message } from 'rsuite';
import { ApplicationContext, DrawerFormWrapper } from 'shared';
import { useViewport } from 'shared/ViewportProvider';
import { v4 as uuidv4 } from 'uuid';

const GET_SITE_REVIEW = gql`
  query siteReview($guid: ID!) {
    siteReview(guid: $guid) {
      review
      userId
      dueDate
      status
      attachments {
        guid
        fileName
        filePath
        fileId
        fileGroup
        visibility
        type
        description
      }
    }
  }
`;

interface ISiteReviewView {
  drawer?: boolean,
  show?: boolean,
  guid?: any,
  action?: any,
  customerId?: number,
  onHide?: () => void,
  onUpdate?: (data: any) => void
}

const SiteReviewView = ({ show, guid, action, drawer, customerId, onHide, onUpdate }: ISiteReviewView) => {
  let form: any = useRef();
  const container = useRef() as React.MutableRefObject<HTMLDivElement>;
  const match = useRouteMatch();
  const query = useQueryString();
  const client = useApolloClient();
  const { state } = useViewport();
  const { showSuccess, showError, showConfirmation } = useContext(ApplicationContext);
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [reviewTemplateGuid, setReviewTemplateGuid] = useState((state.templates || []).filter((t: any) => t.type === 'review')?.[0].guid);
  const [reviewForm, setReviewForm] = useState<any>(undefined);
  const [reviewGuid, setReviewGuid] = useState(guid);
  const [userId, setUserId] = useState<any>(undefined);
  const [dueDate, setDueDate] = useState<any>(undefined);
  const [attachments, setAttachments] = useState<any>([]);
  const [allAttachments, setAllAttachments] = useState<any>([]);
  const customer = useQuery(GET_CUSTOMER, { variables: { id: +(customerId || -1) } });
  const review = guid && useQuery(GET_SITE_REVIEW, { variables: { guid } });
  const readOnly = action === 'view';
  const crews = state.users.filter((u: any) => u.userlevel === 'franchise');

  guid = query?.guid || (match?.params as any)?.guid || guid;
  action = query?.action || action || (match?.params as any)?.action || 'add';

  useEffect(() => {
    (async function getTemplate() {
      try {
        if (reviewTemplateGuid) {
          const data = await client.query({
            query: gql`
              query template($guid: ID) {
                template(guid: $guid) {
                  id
                  guid
                  type
                  key
                  text
                  subject
                  services
                }
              }
            `,
            variables: {
              guid: reviewTemplateGuid
            },
            fetchPolicy: 'no-cache'
          });

          if (!reviewGuid) {
            setReviewForm(data?.data?.template);
            setReviewGuid(uuidv4());
          }
        }
      } catch (err) {
        console.log(err);
      }
    })();
  }, [reviewTemplateGuid, reviewGuid]);

  useEffect(() => {
    if (review?.data?.siteReview) {
      setReviewForm(review?.data?.siteReview?.review);
      setAttachments(review?.data?.siteReview?.attachments);
      setAllAttachments(review?.data?.siteReview?.attachments);
      setUserId(review?.data?.siteReview?.userId);
      setDueDate(review?.data?.siteReview?.dueDate ? parseISO(review?.data?.siteReview?.dueDate) : undefined);
    }
  }, [review]);

  const handleSubmit = async (stay?: boolean, status?: string) => {
    setSaving(true);

    const upsertSiteReview = gql`
      mutation upsertSiteReview($input: UpsertSiteReview) {
        upsertSiteReview(input: $input) {
          success
          code
          message
          result
        }
      }
    `;

    const response: any = await client.mutate({
      mutation: upsertSiteReview, variables: {
        input: {
          customerId: customer?.data?.customer?.id,
          review: reviewForm,
          guid: reviewGuid || uuidv4(),
          userId: userId,
          dueDate: dueDate,
          attachments,
          status
        }
      }
    });

    if (response.data.upsertSiteReview.success) {
      if (onUpdate) {
        onUpdate(null);
      }
      showSuccess(response.data.upsertSiteReview.message);

      if (onHide) {
        onHide();
      }
    } else {
      showError(response.data.upsertSiteReview.message);
      setSaving(false);
    }
  }

  const handleUpdateReview = (guid: string, color: string) => {
    setReviewForm({
      ...reviewForm,
      services: {
        groups: reviewForm.services.groups,
        services: reviewForm.services.services.map((s: any) => {
          if (s.guid === guid) {
            s.color = color;
          }

          return s;
        })
      }
    });
  }

  const handleUpdateComments = (guid: string, comments: string) => {
    setReviewForm({
      ...reviewForm,
      services: {
        groups: reviewForm.services.groups.map((g: any) => {
          if (g.guid === guid) {
            g.comments = comments;
          }

          return g;
        }),
        services: reviewForm.services.services
      }
    });
  }

  const Wrapper = show === undefined
    ? <div />
    : <DrawerFormWrapper
      loading={saving}
      show={show}
      title={startCase(action) + ' Site Review'}
      guid={guid}
      size={'lg'}
      action={action}
      form={`/app/${getEnv()}/irrigation-system/form/view/${guid}`}
      onHide={onHide}
      onSave={handleSubmit}
      showSaveButton={review?.data?.siteReview?.status !== 'completed'}
      additionalActions={<>
        {review?.data?.siteReview?.status === 'completed'
          ? <Button color='red' appearance='primary' size='sm' onClick={() => {
            showConfirmation('Are you sure you want to reject this site review completion?', 'Reject Site Review', () => {
              handleSubmit(false, 'rejected');
            });
          }}>Reject</Button>
          : <Button appearance='primary' size='sm' onClick={() => {
            showConfirmation('Are you sure you want to make this site review as completed? No more changes to this site review can be made after completion', 'Complete Site Review', () => {
              handleSubmit(false, 'completed');
            });
          }}>Completed</Button>
        }
      </>}
    />;

  return React.cloneElement(Wrapper, {
    children: <div>
      {!drawer &&
        <div></div>
      }

      <Panel className="content" ref={container}>
        {loading
          ? <Loader content="Loading..." />
          : <div>
            <div className="mb-16">
              {review?.data?.siteReview?.status === 'completed' &&
                <Message className='mb-16' type='success'>Site review marked as <strong>Completed</strong>.</Message>
              }

              {getEnv() === 'dev' && <div className="mb-16">
                <label>UUID:</label>
                <div>{reviewGuid}</div>
              </div>
              }

              <div>
                <div>
                  <label>Crew Assigned:</label>
                </div>
                <div>
                  {state.users.find((u: any) => u.guid === userId)?.operatorName}
                </div>
              </div>
            </div>

            <div className="mb-16">
              <div>
                <label>Due Date:</label>
              </div>
              <div>
                {dueDate && <span>{format(dueDate, 'MMM d, yyyy')}</span>}
              </div>
            </div>

            <div>
              <label>Review:</label>
            </div>

            {(reviewForm?.services?.groups || []).map((g: any) => <div className='mb-16' key={g.guid}>
              <div><label>{g.description}</label></div>
              <Grid>

                {(reviewForm?.services?.services.filter((s: any) => s.group_guid === g.guid)).map((s: any) =>
                  <Row key={s.guid}>
                    <Col md={1} className='pl-0 pt-4'>
                      <MdCheckCircle color={s?.color || '#ccc'} size={16} />
                    </Col>
                    <Col md={23} className='p-0'>
                      <div>
                        {s.description}<span> </span>
                        {s?.color === 'green' && <span className='text-green'>Meets or exceeds expectations</span>}
                        {s?.color === 'orange' && <span className='text-orange'>Needs improvement</span>}
                        {s?.color === 'red' && <span className='text-red'>Significant issues; requires immediate attention</span>}
                      </div>
                    </Col>
                  </Row>
                )}
                <Row>
                  <Col md={23} mdPush={1} className='p-0'>
                    <label>Comments: {g.comments || '-'}</label>
                  </Col>
                </Row>
                <Row>
                  <Col md={23} mdPush={1} className='p-0'>
                    <div>
                      <label>Photos Before:</label>
                    </div>
                    {attachments.filter((a: any) => a.fileGroup === g.guid && a.type === 'photo_before').length === 0
                      ? <div>-</div>
                      : <AttachmentList
                        showHeader={false}
                        attachments={attachments.filter((a: any) => a.fileGroup === g.guid && a.type === 'photo_before')}
                      />
                    }
                  </Col>
                </Row>
                <Row>
                  <Col md={23} mdPush={1} className='p-0'>
                    <div>
                      <label>Photos After:</label>
                    </div>
                    {review?.data?.siteReview?.status === 'completed'
                      ? <AttachmentList
                        attachments={attachments.filter((a: any) => a.fileGroup === g.guid && a.type === 'photo_before')}
                      />
                      : <AttachmentForm
                        preUploadAction='photoResize'
                        showType={false}
                        viewMode='gallery'
                        type={'photo_after'}
                        showVisibility={false}
                        showUploadLargeFile={false}
                        referenceGuid={reviewGuid}
                        fileGroup={g.guid}
                        attachments={attachments}
                        handleTypeFilter={(a: any) => a.fileGroup === g.guid && a.type === 'photo_after'}
                        onChange={(data) => setAttachments(data)}
                      />
                    }
                  </Col>
                </Row>
              </Grid>
            </div>
            )}


          </div>
        }
      </Panel>
    </div>
  });
}

export default SiteReviewView;