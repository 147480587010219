import { gql } from "@apollo/client";

const queryUser = gql`
  query user($guid: ID) {
    user(guid: $guid) {
      id
      guid
      username
      operatorName
      userlevel
      contactName
      contactNumber
      contactEmail
      deletedAt
      showInDailyFinancialsEmail
      hideUser
      hideInSeasonalFinancials
      companyId
      timeEntryDesktop
      timeEntryMobile
      calendarColor
      calendarRange
      userlevel
      allowImpersonation
      color
      applicationAccess
      contactName
      operatorName
      contactNumber
      alternateContactNumber
      contactEmail
      pnlPercentage
      userRoyaltyPercentage
      percentageOfNet
      address
      city
      province
      postalCode
      businessNumber
      wcbNumber
      sin
      accessGroup
      acl
      workingUnderId
      isLocked
      workGroup
      hourlyRate
      permissionsAdd
      permissionsRemove
      quickbooksCode
      workingUnder {
        operatorName
      }
    }
  }
`;

const queryUsers = gql`
  query users($limit: Int, $offset: Int, $where: UsersWhere, $sort: UsersSort) {
    users(filter:{
      limit:$limit
      where: $where
      offset: $offset
      sort: $sort
    }) {
      edges {
        node {
          id
          guid
          username
          operatorName
          userlevel
          contactName
          contactNumber
          contactEmail
          deletedAt
          showInDailyFinancialsEmail
          hideUser
          companyId
          timeEntryDesktop
          timeEntryMobile
          calendarColor
          userlevel
          allowImpersonation
          color
          applicationAccess
          contactName
          operatorName
          contactNumber
          alternateContactNumber
          contactEmail
          pnlPercentage
          userRoyaltyPercentage
          address
          city
          province
          postalCode
          businessNumber
          wcbNumber
          sin
          calendarRange
          accessGroup
          acl
          workGroup
          workingUnderId
          isLocked
          lastNotificationSeenAt
          percentageOfNet
          createdAt
          modifiedAt
          hourlyRate
          permissionsAdd
          permissionsRemove
          deletedAt
          hideInSeasonalFinancials
          company {
            id
            guid
            name
            salesPerManHourTarget
            timezone
          }
          customer {
            id
            guid
            props
          }
          workingUnder {
            operatorName
          }
        }
      },
      totalCount
    }
  }
`;

const queryUsersWorkingUnder = gql`
  query users($limit: Int, $offset: Int, $where: UsersWhere, $sort: UsersSort) {
    users(filter:{
      limit:$limit
      where: $where
      offset: $offset
      sort: $sort
    }) {
      edges {
        node {
          id
          guid
          username
          operatorName
          userlevel
          contactName
          contactNumber
          contactEmail
          deletedAt
          showInDailyFinancialsEmail
          hideUser
          companyId
          timeEntryDesktop
          timeEntryMobile
          calendarColor
          userlevel
          allowImpersonation
          color
          applicationAccess
          contactName
          operatorName
          contactNumber
          alternateContactNumber
          contactEmail
          pnlPercentage
          userRoyaltyPercentage
          address
          city
          province
          postalCode
          businessNumber
          wcbNumber
          sin
          calendarRange
          accessGroup
          acl
          workGroup
          workingUnderId
          isLocked
          lastNotificationSeenAt
          percentageOfNet
          hourlyRate
          createdAt
          modifiedAt
          deletedAt
          permissionsAdd
          permissionsRemove
          lastSessionCreatedAt
          company {
            id
            guid
            name
            salesPerManHourTarget
            timezone
          }
          customer {
            id
            guid
            props
          }
          workingUnder {
            operatorName
          }
          session {
            expires
            createdAt
            userAgent
          }
        }
      },
      totalCount
    }
  }
`;

const GET_USER = queryUser;
const GET_USERS = queryUsers;

const QUERY_ROLES = gql`
  query roles($limit: Int!, $offset: Int!, $sort: RolesSort) {
    roles(filter: {
      limit: $limit
      offset: $offset
      sort: $sort
    }) {
      edges {
        node {
          id
          label
          value
          permissions
        }
      },
      totalCount
    }
  }`;


export {
  GET_USER,
  GET_USERS,
  queryUsers,
  queryUser,
  queryUsersWorkingUnder,
  queryUsersWorkingUnder as GET_USERS_WORKING_UNDER,
  QUERY_ROLES
}
