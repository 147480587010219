import { Fragment, useEffect, useState } from 'react';
import { Checkbox, Col, DOMHelper } from 'rsuite';
import { Filter } from 'shared';
import { useApolloClient } from '@apollo/client';
import { queryJobsCalendar } from '../../gql/jobs';
import { useFilteredParams } from 'shared/FilterProvider';
import { useRouteMatch } from 'react-router-dom';
import { isBetween } from 'lib/helpers';
import { parseNumber, ROLE, setHeaderTitle, STATUS } from 'lib/env';
import { CustomerSummary } from 'containers/customer';
import { getFilter } from 'lib/helpers/filter';
import { addHours, differenceInMinutes } from 'date-fns';
import { parseISO } from 'lib/date';
import { IS_KALADI_KITCHENS, moneyFormatter } from 'lib/tenant';
import useLocalStorage from 'lib/hooks/useLocalStorage';
import { useViewport } from 'shared/ViewportProvider';
import { flatten, uniq } from 'lodash';
import { getSort } from 'lib/helpers/sort';
import ViewMap from './components/ViewMap';
import { v4 as uuidv4 } from 'uuid';
import { ExportField, MapToggleField } from 'components/form';
import { usePrairieAuth } from 'contexts/AuthPrairieProvider';

const JobsMap = () => {
  setHeaderTitle('Job Map');
  const client = useApolloClient();
  const match: any = useRouteMatch();
  const { state } = useViewport();
  const { isRole } = usePrairieAuth();
  const { params } = useFilteredParams('jobs');
  const [jobs, setJobs] = useState([]);
  const [status, setStatus] = useState(STATUS.LOADING);
  const [offset, setOffset] = useState(0);
  const [showMyBookings, setShowMyBookings] = useLocalStorage('showMyBookings', false);
  const [activeJob, setActiveJob] = useState<any | undefined>(undefined);
  const [activeDrawer, setActiveDrawer] = useState<string | undefined>(undefined);
  const [record, setRecord] = useState<any>(undefined);
  const [filter, setFilter] = useState<any>(params);
  const [modifiedAt, setModifiedAt] = useState(new Date().toISOString());

  useEffect(() => {
    if (filter.range.length > 0) {
      (async function getJobs() {
        try {
          const whereFilter: any = getFilter('jobs', filter, state.profile);

          setStatus(STATUS.LOADING);
          setJobs([]);

          const data = await client.query({
            query: queryJobsCalendar,
            variables: {
              offset: 0,
              limit: 5000,
              where: whereFilter,
              order: getSort('jobs')
            },
            fetchPolicy: 'no-cache'
          });

          const results = data.data.jobs.edges.node
            .filter((n: any) => (showMyBookings && isRole(ROLE.CLIENT)) ? n.userId === state.profile.id : true)
            .filter((n: any) => isRole(ROLE.CLIENT) ? (n.status !== 'canceled' || (n.status === 'canceled' && n.userId === state.profile.id)) : true);

          //== estimates
          //
          const times: any = [];
          const estimates: any = uniq(flatten(results.map((r: any) => {
            const tempDates = [];
            let start = parseISO(r.startDate);

            while (start <= parseISO(r.endDate)) {
              tempDates.push(new Date(new Date(+start).setHours(1, 0, 0, 0)).toISOString());
              start = addHours(start, 24);
            }
            return tempDates;
          }))).reduce((p: any, n: any) => { p[n] = 0; return p; }, {});

          Object.keys(estimates).forEach((k: any) => {
            const estimatedJobs = results.filter((r: any) =>
              isBetween(parseISO(r.startDate), new Date(parseISO(k).setHours(0, 0, 0, 0)), new Date(parseISO(k).setHours(23, 59, 59, 59)))
              && r.status !== 'canceled'
            );

            if (estimatedJobs.length > 0) {
              estimates[k] = estimatedJobs.reduce((p: any, n: any) => p + (n.perCostTypeCost || n.totalCost), 0);

              if (IS_KALADI_KITCHENS) {
                times[k] = results.filter((r: any) =>
                  isBetween(parseISO(r.startDate), new Date(parseISO(k).setHours(0, 0, 0, 0)), new Date(parseISO(k).setHours(23, 59, 59, 59)))
                  && !(r.customerName === 'Booked' || r.status === 'canceled')
                )
                  .reduce((p: any, n: any) => p + differenceInMinutes(parseISO(n.endDate), parseISO(n.startDate)), 0);
              }
            } else {
              delete estimates[k];
            }
          });

          let jobEstimates: any = Object.keys(estimates).map((e: any) => ({
            guid: -1,
            status: 'daily_estimate',
            customerName: IS_KALADI_KITCHENS ? 'Est. Hours Booked: ' : 'Est. Daily Cost: ',
            startDate: e,
            endDate: e,
            cost: IS_KALADI_KITCHENS ? ((times[e] || 0) / 60.0).toFixed(2) : moneyFormatter.format(parseNumber(estimates[e]))
          }));

          if (isRole(ROLE.WORKER)) {
            setJobs(results);
          } else {
            setJobs(jobEstimates.concat(results));
          }

          DOMHelper.scrollTop(window as any, 0);
        } catch (err) {
          console.log(err);
        }

        setStatus(STATUS.LOADED);
      })();
    }
  }, [offset, filter, client, modifiedAt, match?.params?.view]);



  const handleShowDrawer = (jobGuid: string, drawer: string) => {
    const job: any = jobs.find((j: any) => j.guid === jobGuid);
    setActiveDrawer(drawer);
    setActiveJob({ ...job });
  }

  const handleHideDrawer = () => {
    setActiveDrawer(undefined);
    setActiveJob(undefined);
  }

  const handleSummaryHide = () => {
    setRecord(undefined);
  }

  const handleSummaryShow = (customerId: number, workOrderGuid: string) => {
    setRecord({
      customerId,
      workOrderGuid
    });
  }

  const handleClearJobs = () => {
    setJobs([]);
  }

  return (
    <Fragment>
      <Filter
        id='jobs'
        showJobStatus={!isRole(ROLE.CLIENT)}
        onChange={(val) => {
          setFilter(val);
          setOffset(0);
        }}
        clearJobs={handleClearJobs}
        showMultipleCrewFilter={true}
        showGroupFilter={!isRole(ROLE.CLIENT)}
        showTextFilter={!isRole(ROLE.CLIENT)}
        dateRangeColumns={isRole(ROLE.CLIENT, ROLE.WORKER)
          ? undefined
          : [
            { startDate: 'Start Date' },
            { endDate: 'End Date' },
            { createdAt: 'Created At' },
            { modifiedAt: 'Modified At' },
            { startEndInclusive: 'Overlaps Start/End' }
          ]
        }
      >
        {(IS_KALADI_KITCHENS && isRole(ROLE.CLIENT)) &&
          <Col md={3}><Checkbox checked={showMyBookings} onClick={() => setShowMyBookings(!showMyBookings)}>Show My Booking Only</Checkbox></Col>
        }
        {!IS_KALADI_KITCHENS &&
          <Col md={3} xs={24} style={{ float: 'right', textAlign: 'right' }}>
            <MapToggleField />
            <ExportField />
          </Col>
        }
      </Filter>

      <ViewMap
        jobs={jobs.filter((j: any) => j.status !== 'daily_estimate')}
        key={uuidv4()}
        handleShowDrawer={handleShowDrawer}
        handleSummaryShow={handleSummaryShow}
        handleWorkOrderSelect={() => { }}
      />

      <CustomerSummary
        show={record !== undefined}
        customerId={record?.customerId}
        workOrderGuid={record?.workOrderGuid}
        handleSummaryHide={handleSummaryHide}
        handleShowDrawer={() => { }}
        jobGuid={record?.workOrderGuid}
      />
    </Fragment>
  );
};

export default JobsMap;
