import { addDays, addMinutes, format } from 'date-fns';
import { FORMAT } from 'lib/env';
import { isBetween } from 'lib/helpers';
import { nanoid } from 'nanoid';
import { useEffect, useRef, useState } from 'react';
import { Form, Loader, Table, Tooltip, Whisper } from 'rsuite';
import { parseISO } from 'lib/date';
import { IS_KALADI_KITCHENS } from 'lib/tenant';
import { useApolloClient } from '@apollo/client';
import { queryJobDates } from 'gql/jobs';
import { CONFIG_TIME_PADDING } from 'tenant/config';

const { Column, HeaderCell, Cell } = Table;
let scrollPosition = 0;

interface IAvailabilityList {
  times: any,
  workType: string,
  days: any,
  applicationGroup: string,
  startEndDate: any,
  endingDate: Date | undefined,
  recurrence: string,
  rooms: any,
  onSelect: (data: any) => void
}

const AvailabilityList = ({
  times,
  workType,
  days,
  applicationGroup,
  startEndDate,
  endingDate,
  recurrence,
  rooms,
  onSelect
}: IAvailabilityList) => {
  let tableRef: any = useRef();
  const client = useApolloClient();
  const [jobs, setJobs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [availabilityDates, setAvailabilityDates] = useState<any>([]);

  useEffect(() => {
    if (IS_KALADI_KITCHENS) {
      const dates = [];

      let startDate = new Date(+(startEndDate[0]));
      let endDate = endingDate ? endingDate : new Date(+(startEndDate[1]));

      while (startDate <= addDays(endDate, 1)) {
        dates.push({ day: startDate, label: format(startDate, FORMAT.DAY_DATE), workType: undefined });
        rooms.filter((r: any) => !workType || workType === r.key).forEach((r: any) => dates.push({ day: startDate, label: r.title, workType: r.key }));
        startDate = new Date(addDays(startDate, 1));
      }
      setAvailabilityDates(dates);

      (async function getJobs() {
        setLoading(true);
        try {
          const data: any = await client.query({
            query: queryJobDates,
            variables: {
              offset: 0,
              limit: 99999,
              where: {
                AND: [
                  { userId: { not: null } },
                  { workOrderStatus: { not_in: ['inactive', 'canceled'] } },
                  { AND: { OR: [{ status: { is: null } }, { status: { not_in: ['canceled'] } }] } },
                  { endDate: { gte: new Date(new Date(+startEndDate[0]).setHours(0, 0, 0)) } },
                  { startDate: { lte: new Date(new Date(endingDate ? endingDate.toISOString() : +startEndDate[1]).setHours(23, 59, 59)) } },
                  { applicationGroup: { is: applicationGroup } }
                ]
              }
            },
            fetchPolicy: 'no-cache'
          });

          setJobs(data.data.jobs.edges.node.map((j: any) => ({
            ...j,
            endDate: addMinutes(parseISO(j.endDate), CONFIG_TIME_PADDING.end).toISOString()
          })));
        } catch (err) {

        } finally {
          setLoading(false);
        }
      })();
    }
  }, [startEndDate, endingDate, client]);

  setTimeout(() => {
    if (tableRef && tableRef.hasOwnProperty('scrollTo')) {
      tableRef.scrollLeft(scrollPosition * -1);
    }
  }, 200);

  return (
    <Form.Group>
      <Form.ControlLabel>{loading ? <Loader content="Loading availabilities..." /> : <span>Availability:</span>}</Form.ControlLabel>
      {!loading &&
        <div>
          <Table
            onScroll={(val) => scrollPosition = val}
            ref={(ref: any) => tableRef = ref}
            className="availability"
            autoHeight
            data={availabilityDates.filter((d: any) => (recurrence === 'weekly' && days && days.length > 0 ? days.includes(format(d.day, 'E').toLowerCase()) : true))}>
            <Column width={100} align="center" fixed>
              <HeaderCell>Day</HeaderCell>
              <Cell>{(row: any) => row.workType ? <span>{row.label}</span> : <strong>{row.label}</strong>}</Cell>
            </Column>
            {times.map((t: any) =>
              <Column width={70} align="center" key={nanoid()}>
                <HeaderCell><span>{t.label}</span></HeaderCell>
                <Cell>
                  {(row: any) => {
                    const compareWorkType = workType || row.workType;
                    const currentTime = new Date(+(row.day).setHours(t.date.getHours(), t.date.getMinutes(), t.date.getSeconds()));
                    const job = jobs.filter((j: any) => compareWorkType ? (j?.services || []).includes(compareWorkType) : true)
                      .find((j: any) => isBetween(currentTime, parseISO(j.startDate), parseISO(j.endDate), '[)'));

                    return job
                      ? <div key={nanoid()} style={{
                        backgroundColor: '#ff0000',
                        width: '100%',
                        opacity: row.workType ? 0.2 : 0.5
                      }}>&nbsp;</div>
                      : <Whisper placement="top" speaker={<Tooltip>Click to start at {format(t.date, FORMAT.TIME24)}</Tooltip>}>
                        <div onClick={() => onSelect({
                          time: new Date(new Date(+row.day).setHours(t.date.getHours(), t.date.getMinutes(), 0, 0)),
                          room: row.workType
                        })} className="selectable-cell">&nbsp;</div>
                      </Whisper>
                  }}
                </Cell>
              </Column>
            )}
          </Table>
        </div>
      }
    </Form.Group>
  );
}

export default AvailabilityList;