
import { useState, Fragment, useEffect, useRef, useContext } from 'react';

import {
  Radio,
  AutoComplete,
  InputGroup,
  Table,
  Drawer,
  Popover,
  Form,
  Button,
  Schema,
  toaster,
  Message,
  Whisper,
  Panel,
  ButtonToolbar,
  Divider,
  IconButton,
  Tooltip,
} from 'rsuite';

import LegacyUserIcon from "@rsuite/icons/legacy/User";
import LegacySearchIcon from "@rsuite/icons/legacy/Search";
import { gql, useApolloClient } from '@apollo/client';
import omit from 'lodash/omit';
import queryString from 'query-string';
import { getEnv, hasMonerisIntegration, hasStripeIntegration, ROLE } from 'lib/env';
import { useHistory, useParams } from 'react-router-dom';
import { getCustomers } from 'lib/helpers';
import { v4 as uuidv4 } from 'uuid';
import { filterFieldsByParentKey } from 'lib/helpers/field';
import { useViewport } from './ViewportProvider';
import { ApplicationContext } from 'shared';
import { usePrairieAuth } from 'contexts/AuthPrairieProvider';
import Icon from '@rsuite/icons/lib/Icon';
import { MdCheckCircle } from 'react-icons/md';
import { uniqBy } from 'lodash';
import INTL from 'tenant/intl';
import { IS_KALADI_PROPERTIES } from 'lib/tenant';
const { StringType } = Schema.Types;

const GET_CUSTOMERS = gql`
  query customers($where: CustomersWhere) {
    customers(filter: {
      limit: 10,
      offset: 0,
      where: $where
    }) {
      edges {
        node {
          id
          parentId
          guid
          customerName
          displayName
          customerAddress
          displayAddress
          phone
          email
          label
          userId
          user {
            accessGroup
          }
          billing {
            guid
            maskedPan
            expMonth
            expYear
            cvd
            notes
            isDefault
            dataKey
          }
          children {
            id
            guid
            customerName
            displayName
            customerAddress
          },
          parents {
            id
            guid
            customerName
            displayName
            customerAddress
          },
          documents {
            id
            guid
            entryType
            customerName
            customerId
            workType
            startDate
            endDate
            modifiedAt
            username
            source
            title
          }
        }
      }
    }
  }
`;

const { Cell, Column, HeaderCell } = Table;

interface ICustomerLookupV2 {
  bordered?: boolean,
  showTable?: boolean,
  billingCustomerId?: number,
  workingCustomerId?: number,
  breadcrumbTitle?: string | undefined,
  handleCustomerIdChange: (workCustomerId: number, billCustomerId: number, userId: number | undefined) => void,
  handleCustomerFound?: (customer: any) => void,
  showAutoCompleted?: boolean | undefined,
  showRequiredTooltip?: boolean | undefined,
  showBreadcrumb?: boolean | undefined,
  topSection?: string | undefined,
  showBilling?: boolean | undefined,
  workLabel?: string | undefined
}

const model = Schema.Model({
  customerName: StringType().isRequired('This field is required.'),
  email: StringType()
    .isEmail('Please enter a valid email address.')
    .isRequired('This field is required.'),
  customerAddress: StringType().isRequired('This field is required.'),
  customerPhone: StringType().isRequired('This field is required.'),
  city: StringType().isRequired('This field is required.'),
  province: StringType().isRequired('This field is required.'),
  postalCode: StringType().isRequired('This field is required.'),
});

const initialForm = {
  customerName: '',
  customerPhone: '',
  email: '',
  customerAddress: '',
  city: 'Calgary',
  province: 'Alberta',
  postalCode: '',
  contactDetails: [],
  billingDetails: [],
  addressDetails: [],
  attachments: [],
  status: [],
  propertyManager: '',
  label: '',
  customLeaseHours: 0
} as any;

const CustomerLookupV2 = ({
  bordered,
  showTable,
  billingCustomerId,
  workingCustomerId,
  breadcrumbTitle,
  handleCustomerIdChange,
  handleCustomerFound,
  showAutoCompleted,
  showRequiredTooltip,
  showBreadcrumb,
  topSection,
  showBilling,
  workLabel
}: ICustomerLookupV2) => {
  const history = useHistory();
  const parsed: any = queryString.parse(window.location.search);
  const client = useApolloClient();
  let cust: any = [];
  const errorContainerRef: any = useRef();
  const { isRole } = usePrairieAuth();
  const { showError } = useContext(ApplicationContext);
  const { state } = useViewport();
  const [customers, setCustomers] = useState([]);
  const [selected, setSelected] = useState('');
  const [customer, setCustomer] = useState(cust);
  const [addCustomer, setAddCustomer] = useState(false);
  const [billCustomerId, setBillCustomerId] = useState(billingCustomerId || 0);
  const [workCustomerId, setWorkCustomerId] = useState(workingCustomerId || 0);
  const [userId, setUserId] = useState(0);
  const [saving, setSaving] = useState(false);
  const [formValue, setFormValue] = useState({ ...initialForm })
  const [formError, setFormError] = useState({} as any);
  const [showCustomerRequiredTooltip, setShowCustomerRequiredTooltip] = useState(showRequiredTooltip);
  const customerTypes = filterFieldsByParentKey(state.fields, 'customer_label');

  useEffect(() => {
    (async function getCustomer() {
      const parameterCustomerIdMatch: any = window.location.pathname.match(/\/(\d+)\//);
      let parameterCustomerId = null;

      if (parameterCustomerIdMatch && parameterCustomerIdMatch.length > 1) {
        parameterCustomerId = parameterCustomerIdMatch[1];
      } else if (workingCustomerId !== undefined || billingCustomerId !== undefined) {
        const customerLookup = await getCustomers(client, (workingCustomerId || billingCustomerId) as any);
        parameterCustomerId = customerLookup[0];
      }

      if (parameterCustomerId) {
        const data = await client.query({ query: GET_CUSTOMERS, variables: { where: { OR: [{ id: { is: parameterCustomerId } }, { parentId: { is: parameterCustomerId } }] } } });
        const tmp = data.data.customers.edges.node;

        setCustomer(tmp);
        // setWorkCustomerId(workCustomerId || tmp[0].id);
        // setBillCustomerId(billCustomerId || tmp[0].id);
        setUserId(tmp[0].userId);

        if (handleCustomerFound) {
          handleCustomerFound(tmp[0]);
        }
      }
    })();
  }, []);

  // useEffect(() => {
  // if ((workingCustomerId !== undefined || billingCustomerId !== undefined) && !parsed.customer_id) {
  // (async function getCustomer() {
  //   if ((workingCustomerId as any) > 0 || (billingCustomerId as any) > 0) {
  //     const customerLookup = await getCustomers(client, workingCustomerId || billingCustomerId || 0);
  //     const data = await client.query({ query: GET_CUSTOMERS, variables: { where: { AND: [{ id: { in: customerLookup } }] } } });
  //     const tmp = data.data.customers.edges.node;

  //     setCustomer(tmp);
  //     setBillCustomerId(billingCustomerId as any);
  //     setWorkCustomerId(workingCustomerId as any);
  //     setUserId(tmp[0].userId);

  //     if (handleCustomerFound) {
  //       handleCustomerFound(tmp[0]);
  //     }
  //   }
  // })();
  // }
  // }, [billingCustomerId, workingCustomerId]);

  useEffect(() => {
    setShowCustomerRequiredTooltip(showRequiredTooltip);
  }, [showRequiredTooltip]);

  useEffect(() => {
    if (workCustomerId > 0 && billCustomerId > 0) {
      handleCustomerIdChange(workCustomerId, billCustomerId, userId);
    }
  }, [workCustomerId, billCustomerId, userId]);

  const lookupCustomer = async (val: string) => {
    try {
      const data = await client.query({
        query: GET_CUSTOMERS,
        variables: {
          where: {
            AND: [
              { isActive: { is: 1 } }
            ],
            OR: [
              { customerName: { contains: val } },
              { businessName: { contains: val } }
            ]
          }
        }
      });

      setCustomers(data.data.customers.edges.node.map((n: any) => ({
        ...n,
        value: n.guid,
        label: n.customerName + ' - ' + n.displayAddress
      })));
    } catch (err) {
      console.log(err);
    }
  }

  const handleSelect = (val: any, rec: any) => {
    setTimeout(() => { setSelected(rec.customerName); });
    const cust = uniqBy([omit(rec, ['children', 'parents'])].concat(rec.children).concat(rec.parents), 'guid');

    setCustomer(cust);
    setBillCustomerId(+cust[0].id);
    setWorkCustomerId(+cust[0].id);
    setUserId(+cust[0].userId);
    handleCustomerIdChange(cust[0].id, cust[0].id, cust[0].userId);
  }

  const handleCustomerSave = async () => {
    const valid = true;

    try {
      if (valid) {
        setSaving(true);

        const upsertCustomer = gql`
          mutation upsertCustomer($input: UpsertCustomerInput!) {
            upsertCustomer(input: $input) {
              success
              code
              message
              result
            }
          }
        `;

        const response: any = await client.mutate({
          mutation: upsertCustomer, variables: {
            // the array is taken from the input list
            input: {
              guid: uuidv4(),
              customerName: formValue.customerName,
              contactDetails: [
                {
                  guid: uuidv4(),
                  name: formValue.customerName,
                  email: formValue.email,
                  phone: formValue.customerPhone,
                  ext: '',
                  primary: true
                }
              ],
              addressDetails: [
                {
                  label: 'site',
                  guid: uuidv4(),
                  primary: false,
                  address: formValue.customerAddress,
                  city: formValue.city,
                  province: formValue.province,
                  postalCode: formValue.postalCode
                }
              ]
            }
          }
        });

        if (response.data.upsertCustomer.success) {
          toaster.push(
            <Message type="success" showIcon closable>{response.data.upsertCustomer.message}</Message>
          );

          setCustomer([response.data.upsertCustomer.result]);
          setBillCustomerId(response.data.upsertCustomer.result.id);
          setWorkCustomerId(response.data.upsertCustomer.result.id);
          setAddCustomer(false);
        } else {
          toaster.push(
            <Message type="error" showIcon closable duration={3000}>{response.data.upsertCustomer.message}</Message>
          );
        }

        setSaving(false);
      }
    } catch (err) {
      showError(err);
      setSaving(false);
    }
  }

  const getKaladiPropertiesConditions = () => {
    if (IS_KALADI_PROPERTIES &&
      (window.location.pathname.includes('/tenants') || window.location.pathname.includes('/documents'))) {
      return true;
    }

    return false;
  }

  return (
    <div className='form'>
      {showBreadcrumb &&
        <Fragment>
          <ButtonToolbar className='mb-8'>
            {customer.length > 0
              ? <Fragment>
                <IconButton size="xs" appearance={'default'} icon={<LegacyUserIcon />}
                  onClick={() => history.push(`/app/${getEnv()}/workbook/explorer/${customer[0].id}/${topSection ? topSection : 'work-orders'}`)}>
                  {customer[0].displayName}
                </IconButton>
              </Fragment>
              : <Fragment>
              </Fragment>
            }
          </ButtonToolbar>
          {/* {customer.length > 0
            ? <Breadcrumb>
              <Breadcrumb.Item as={Link} to={`/app/${getEnv()}/workbook/explorer/${customer[0].id}/${topSection ? topSection : 'work-orders'}`}>{customer[0].displayName}</Breadcrumb.Item>
              <Breadcrumb.Item active>{breadcrumbTitle}</Breadcrumb.Item>
            </Breadcrumb>
            : <Breadcrumb>
              <Breadcrumb.Item active>{breadcrumbTitle}</Breadcrumb.Item>
            </Breadcrumb>
          } */}
        </Fragment>
      }

      <fieldset>
        {((showAutoCompleted || showAutoCompleted === undefined) && !isRole(ROLE.CLIENT))
          ? <Fragment>
            <label ref={errorContainerRef}>Find customer or (<a onClick={() => {
              setAddCustomer(true);
              setFormValue({ ...initialForm });
            }}>add new customer</a>):</label>
            <InputGroup inside>
              <InputGroup.Addon>
                <LegacySearchIcon />
              </InputGroup.Addon>

              <Fragment>
                <AutoComplete
                  placeholder={'Find customer'}
                  data={customers}
                  onChange={(val: any) => {
                    setSelected(val);
                    lookupCustomer(val);
                    setShowCustomerRequiredTooltip(false);
                  }}
                  value={selected}
                  // rsuite5
                  // renderItem={(item: any) => {
                  //   return (
                  //     <div>
                  //       <p>{item.label} {item.customerAddress && ` at ${item.customerAddress}`}</p>
                  //       {item.customerPhone && <small>{item.customerPhone}</small>}
                  //     </div>
                  //   );
                  // }}
                  onSelect={handleSelect}
                />
              </Fragment>
            </InputGroup>
            <Whisper
              container={() => errorContainerRef.current}
              placement="bottomStart"
              open={showCustomerRequiredTooltip}
              trigger={[]}
              speaker={
                <Popover style={{ padding: '4px 8px', marginTop: '10px', marginLeft: '20px' }}>
                  <span className="text-danger">Add or select customer to proceed</span>
                </Popover>
              }
            >
              <Form.HelpText>Start typing customer to find an existing one or create a new customer profile.</Form.HelpText>
            </Whisper>
          </Fragment>
          : <legend>{INTL.CUSTOMER}</legend>
        }

        {(customer.length > 0 && (showTable === undefined || showTable)) &&
          <Table
            data={customer.filter((c: any) => getKaladiPropertiesConditions() ? c.parentId !== c.id : true)}
            autoHeight={true}
            rowHeight={40}
            key={JSON.stringify(customer.map((c: any) => c.guid).join('_'))}
          >
            <Column>
              <HeaderCell>{workLabel || 'Work'}</HeaderCell>
              <Cell style={{ padding: 0 }}>
                {(row: any) => <>
                  <Radio
                    value={row.id}
                    checked={+workCustomerId === +row.id}
                    onChange={(val: any) => setWorkCustomerId(+val)}
                  />
                </>
                }
              </Cell>
            </Column>
            {(showBilling === undefined || showBilling === true) &&
              <Column>
                <HeaderCell>Billing</HeaderCell>
                <Cell style={{ padding: 0 }}>
                  {(row: any) =>
                    <Radio
                      value={row.id}
                      checked={+billCustomerId === +row.id}
                      onChange={(val: any) => setBillCustomerId(+val)}
                    />
                  }
                </Cell>
              </Column>
            }
            <Column flexGrow={1}>
              <HeaderCell>Name</HeaderCell>
              <Cell>
                {(row: any) => <span>{row.displayName} {(row.label && customerTypes.find((c: any) => c.key === row.label)) ? ` (${customerTypes.find((c: any) => c.key === row.label)?.title})` : ''}</span>}
              </Cell>
            </Column>
            <Column flexGrow={1}>
              <HeaderCell>Address</HeaderCell>
              <Cell>
                {(row: any) => <>{row.displayAddress}</>}
              </Cell>
            </Column>
            <Column flexGrow={1}>
              <HeaderCell>Phone</HeaderCell>
              <Cell dataKey="phone" />
            </Column>
            {(hasMonerisIntegration(state.companies) || hasStripeIntegration(state.companies)) &&
              <Column>
                <HeaderCell>Card On File</HeaderCell>
                <Cell className="text-center">
                  {(row: any) => <> {(row.billing || []).length > 0
                    ? <Whisper placement='left' speaker={<Tooltip>{row.billing.map((r: any) => <div>{r.maskedPan} {r.isDefault && <span> - Primary</span>}</div>)}</Tooltip>}><span><Icon as={MdCheckCircle} color='#5cb85c' /></span></Whisper>
                    : <span><Icon as={MdCheckCircle} color='#d9534f' /></span>}
                  </>}
                </Cell>
              </Column>
            }
          </Table>
        }
      </fieldset>

      <Drawer
        open={addCustomer}
        onClose={() => setAddCustomer(false)}
      >
        <Drawer.Header>
          <Drawer.Title>Create New Customer</Drawer.Title>
        </Drawer.Header>
        <Drawer.Body>
          <Form
            checkTrigger="change"
            onChange={setFormValue}
            onCheck={setFormError}
            formError={formError}
            formValue={formValue}
            model={model}
            onSubmit={handleCustomerSave}
          >
            <Form.Group>
              <Form.ControlLabel className="required">Customer Name:</Form.ControlLabel>
              <Form.Control name="customerName" />
            </Form.Group>
            <Form.Group>
              <Form.ControlLabel className="required">Phone Number:</Form.ControlLabel>
              <Form.Control name="customerPhone" />
            </Form.Group>
            <Form.Group>
              <Form.ControlLabel className="required">Email:</Form.ControlLabel>
              <Form.Control name="email" />
            </Form.Group>
            <Form.Group>
              <Form.ControlLabel className="required">Address</Form.ControlLabel>
              <Form.Control name="customerAddress" />
            </Form.Group>
            <Form.Group>
              <Form.ControlLabel className="required">City</Form.ControlLabel>
              <Form.Control name="city" />
            </Form.Group>
            <Form.Group>
              <Form.ControlLabel className="required">Province</Form.ControlLabel>
              <Form.Control name="province" />
            </Form.Group>
            <Form.Group>
              <Form.ControlLabel className="required">Postal Code</Form.ControlLabel>
              <Form.Control name="postalCode" />
            </Form.Group>
            <Form.Group>
              <Button type="submit" loading={saving} appearance="primary">Save</Button>
            </Form.Group>
          </Form>
        </Drawer.Body>
      </Drawer>
    </div>
  );
}

export {
  CustomerLookupV2
};