import { useEffect, useState, Fragment, useContext } from 'react';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import { gql, useApolloClient } from '@apollo/client';

import {
  Loader,
  Panel,
  Row,
  Grid,
  Col,
  Button,
  toaster,
  Message,
  Whisper,
  Tooltip,
  Table,
  Badge,
  Drawer,
  Dropdown,
  IconButton,
  ButtonToolbar,
  Input,
  ButtonGroup,
  Stack,
  FlexboxGrid,
} from 'rsuite';

import { ROLE, setHeaderTitle, getEnv, DRAWER, hasMonerisIntegration, hasQuickBooksDesktopIntegration, hasStripeIntegration, COLOR, FORMAT, hasXeroIntegration } from 'lib/env';
import { getCustomers } from 'lib/helpers';
import CopyToClipboard from 'react-copy-to-clipboard';
import { v4 as uuidv4 } from 'uuid';
import { queryCustomers } from 'gql/customer';
import { ApplicationContext, ResponsiveTable } from 'shared';
import { CONFIG_APPLICATION_FIELDS, CONFIG_NOTIFICATION_DURATION } from 'tenant/config';
import { IS_ASSINIBOINE, IS_BUGABOO, IS_GREYROCK, IS_KALADI_KITCHENS, IS_KALADI_PROPERTIES } from 'lib/tenant';
import { useViewport } from 'shared/ViewportProvider';
import { fieldTitle } from 'lib/helpers/field';
import { isMobileOnly } from 'react-device-detect';
import { startCase } from 'lodash';
import { usePrairieAuth } from 'contexts/AuthPrairieProvider';
import { useApi } from 'lib';
import { MdContentCopy, MdEdit, MdMap, MdOutlineQuestionMark, MdUndo, MdViewColumn } from 'react-icons/md';
import Icon from '@rsuite/icons/lib/Icon';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import IconButtonWrapper from 'shared/IconButtonWrapper';
import INTL from 'tenant/intl';
import { differenceInYears, format, parseISO } from 'date-fns';

interface IWorkbookCustomer {
  customerId?: number | undefined,
  onCustomerUpdate?: (data: any) => void | undefined,
  showInactive?: boolean
}

const { Column, HeaderCell, Cell } = Table;

const WorkbookCustomer = ({
  customerId,
  onCustomerUpdate,
  showInactive
}: IWorkbookCustomer) => {
  setHeaderTitle('Profile');
  const client = useApolloClient();
  const match: any = useRouteMatch();
  const { state } = useViewport();
  const { isRole } = usePrairieAuth();
  const { showConfirmation, showConfirmationWithReason, showMessage, showDrawer, showError } = useContext(ApplicationContext);
  const [loading, setLoading] = useState(false);
  const [customers, setCustomers] = useState<any[]>([]);
  const [allCustomers, setAllCustomers] = useState<any[]>([]);
  const [showInquiryEvent, setShowInquiryEvent] = useState<any>({ show: false, date: undefined });
  const [showAddCard, setShowAddCard] = useState<{ open: boolean, customerId?: string | undefined, paymentId?: string | undefined }>({
    open: false,
    customerId: undefined,
    paymentId: undefined
  });
  const [cardDetails, setCardDetails] = useState<{ cardNumber?: string | undefined, expMonth?: string | undefined, expYear?: string | undefined, cvc?: string | undefined, notes?: string | undefined }>({
    cardNumber: '',
    expMonth: '',
    expYear: '',
    cvc: '',
    notes: ''
  });
  const [savingCard, setSavingCard] = useState(false);
  const [modifiedAt, setModifiedAt] = useState(new Date().toISOString());
  const history = useHistory();
  const api = useApi();
  const stripe: any = useStripe();
  const elements = useElements();

  useEffect(() => {
    let viewCustomerId = customerId || match.params.customerId;

    (async function getCustomer() {
      try {
        setLoading(true);
        setCustomers([]);
        const attachedCustomers = await getCustomers(client, viewCustomerId);

        const data = await client.query({
          query: queryCustomers,
          variables: {
            where: {
              AND: [
                {
                  id: { in: attachedCustomers }
                }
              ]
            },
            order: [
              { displayAddress: 'ASC' }
            ]
          },
          fetchPolicy: 'no-cache'
        });

        const inactiveCustomers = data.data.customers.edges.node.filter((c: any) => c.deletedAt !== null).length;
        if (inactiveCustomers > 0) {
          (document as any).querySelector('#customers__button_show_inactive > span').innerHTML = '&nbsp;(' + inactiveCustomers + ')';
        }

        let results = data.data.customers.edges.node;

        results.forEach((r: any) => {
          if (!r.contactDetails || r.contactDetails.length === 0) {
            r.contactDetails = [];
            const { email } = r;

            const emails = email ? email.split(';') : [];
            r.contactDetails.push({
              name: !r.businessName ? 'Default' : r.customerName,
              guid: uuidv4(),
              phone: r?.phone?.trim(),
              email: emails.length > 0 ? emails[0].trim() : '',
              primary: true,
              status: []
            });

            if (r.alternatePhone) {
              r.contactDetails.push({
                guid: uuidv4(),
                name: 'Alternate',
                phone: r.alternatePhone.trim(),
                primary: false,
                status: []
              });
            }

            if (r.faxNumber) {
              r.contactDetails.push({
                guid: uuidv4(),
                name: 'Fax number',
                phone: r.faxNumber.trim(),
                primary: false,
                status: []
              });
            }

            if (emails.length > 1) {
              emails.forEach((email: string, index: number) => {
                if (index > 0 && email.replace(/\s+/g, '').length > 0) {
                  r.contactDetails.push({
                    guid: uuidv4(),
                    email,
                    primary: false,
                    status: []
                  });
                }
              })
            }
          }
        });

        setAllCustomers(results);

        if (IS_KALADI_PROPERTIES) {
          results = results.filter((r: any) => r.id === +viewCustomerId);
        }

        setCustomers(results);
      } catch (err) {
        console.log(err);
      }

      setLoading(false)
    })();
  }, [customerId, match?.params?.customerId, modifiedAt]);

  const getAddresses = (customer: any) => {
    if (customer.addressDetails && customer.addressDetails.length > 0) {
      return customer.addressDetails.map((a: any) => ({ ...a, postalCode: a.postalCode || customer.postalCode }));
    }

    const addressList = [];

    if (customer.workAddress && (IS_ASSINIBOINE || IS_GREYROCK)) {
      addressList.push({
        unit: '',
        address: customer.workAddress,
        city: customer.workCity,
        province: customer.workProvince,
        postalCode: customer.workPostalCode,
        propertyManager: '',
        primary: false,
        edit: false,
        guid: customer.guid,
        id: customer.id,
        label: 'work'
      });
    }

    if (customer.customerAddress) {
      addressList.push({
        unit: customer.addressUnit,
        address: customer.customerAddress,
        city: customer.city,
        province: customer.province,
        postalCode: customer.postalCode,
        primary: false,
        edit: false,
        guid: customer.guid,
        id: customer.id,
        label: 'site'
      });
    }

    return addressList;
  }

  const handleGeocode = async (guid: any) => {
    try {
      const geocodeCustomer = gql`
        mutation geocodeCustomer($guid: ID!) {
          geocodeCustomer(guid: $guid) {
            success
            code
            message
          }
        }
      `;

      const response: any = await client.mutate({
        mutation: geocodeCustomer, variables: { guid }
      });

      if (response.data.geocodeCustomer.success) {
        toaster.push(
          <Message type="success" showIcon closable>{response.data.geocodeCustomer.message}</Message>
        );
        setModifiedAt(new Date().toISOString());
      } else {
        toaster.push(
          <Message type="error" showIcon closable>{response.data.upsertUser.message}</Message>
        );
      }
    } catch (err) {
      showError(err);
    }
  }

  const handleRefreshPaymentMethods = async (customerGuid: string) => {
    const refreshStripePayment = gql`
        mutation refreshStripePayment($customerGuid: ID!) {
          refreshStripePayment(customerGuid: $customerGuid) {
                success
                code
                message
            }
        }
    `;

    const response: any = await client.mutate({
      mutation: refreshStripePayment, variables: { customerGuid }
    });

    if (response.data.refreshStripePayment.success) {
      toaster.push(
        <Message type="success" showIcon closable>{response.data.refreshStripePayment.message}</Message>
      );
      setModifiedAt(new Date().toISOString());
    } else {
      toaster.push(
        <Message type="error" showIcon closable>{response.data.refreshStripePayment.message}</Message>, { duration: CONFIG_NOTIFICATION_DURATION }
      );
    }
  }

  const handleDelete = async (guid: string, reason: string | undefined) => {
    const deleteCustomer = gql`
        mutation deleteCustomer($guid: ID!, $reason: String) {
          deleteCustomer(guid: $guid, reason: $reason) {
            success
            code
            message
          }
        }
    `;

    const response: any = await client.mutate({
      mutation: deleteCustomer, variables: { guid, reason }
    });

    if (response.data.deleteCustomer.success) {
      toaster.push(
        <Message type="success" showIcon closable>{response.data.deleteCustomer.message}</Message>
      );
      setModifiedAt(new Date().toISOString());
    } else {
      toaster.push(
        <Message type="error" showIcon closable>{response.data.deleteCustomer.message}</Message>
      );
    }
  }

  const handleRestore = async (guid: string) => {
    const restoreCustomer = gql`
        mutation restoreCustomer($guid: ID!) {
          restoreCustomer(guid: $guid) {
                success
                code
                message
            }
        }
    `;

    const response: any = await client.mutate({
      mutation: restoreCustomer, variables: { guid }
    });

    if (response.data.restoreCustomer.success) {
      toaster.push(
        <Message type="success" showIcon closable>{response.data.restoreCustomer.message}</Message>
      );
      setModifiedAt(new Date().toISOString());
    } else {
      toaster.push(
        <Message type="error" showIcon closable>{response.data.restoreCustomer.message}</Message>
      );
    }
  }

  const handleDetach = async (guid: string) => {
    const detachCustomer = gql`
        mutation detachCustomer($guid: ID!) {
          detachCustomer(guid: $guid) {
                success
                code
                message
            }
        }
    `;

    const response: any = await client.mutate({
      mutation: detachCustomer, variables: { guid }
    });

    if (response.data.detachCustomer.success) {
      toaster.push(
        <Message type="success" showIcon closable>{response.data.detachCustomer.message}</Message>
      );
      setModifiedAt(new Date().toISOString());
    } else {
      toaster.push(
        <Message type="error" showIcon closable>{response.data.detachCustomer.message}</Message>
      );
    }
  }

  const resetCardDetails = () => {
    setCardDetails({
      cardNumber: '',
      expMonth: '',
      expYear: '',
      cvc: '',
      notes: ''
    });
  }

  const formatAddress = (c: any) => {
    return `${c.address.trim()}, ${c.city && c.city + ','} ${c.postalCode && c.postalCode + ','} ${c.province && c.province.trim()}`;
  }

  /**
   * Moneris
   */
  const handleCreateMonerisCard = async () => {
    setSavingCard(true);

    try {
      const response: any = await client.mutate({
        mutation: gql`mutation attachMonerisPayment($customerId: ID!, $cardNumber: String!, $expMonth: String!, $expYear: String!, $cvc: String!, $notes: String) {
          attachMonerisPayment(customerId: $customerId, cardNumber: $cardNumber, expMonth: $expMonth, expYear: $expYear, cvc: $cvc, notes: $notes) {
            success
            code
            message
            result
          }
        }`, variables: { ...cardDetails, customerId: showAddCard.customerId }
      });
      toaster.push(<Message type={response.data.attachMonerisPayment.success ? 'success' : 'error'} showIcon closable>{response.data.attachMonerisPayment.message}</Message>);
    } catch (err: any) {
      toaster.push(<Message type='error' showIcon closable>{err.message}</Message>);
    } finally {
      setSavingCard(false);
      setShowAddCard({ open: false });
      resetCardDetails();
      setModifiedAt(new Date().toISOString());
    }
  }

  const handleUpdateMonerisCard = async () => {
    setSavingCard(true);

    try {
      const response: any = await client.mutate({
        mutation: gql`mutation updateMonerisPayment($customerId: ID!, $paymentId: String!, $expMonth: String!, $expYear: String!, $notes: String) {
          updateMonerisPayment(customerId: $customerId, paymentId: $paymentId, expMonth: $expMonth, expYear: $expYear, notes: $notes) {
            success
            code
            message
            result
          }
        }`, variables: { ...cardDetails, customerId: showAddCard.customerId, paymentId: showAddCard.paymentId }
      });
      toaster.push(<Message type={response.data.updateMonerisPayment.success ? 'success' : 'error'} showIcon closable>{response.data.updateMonerisPayment.message}</Message>);
    } catch (err: any) {
      toaster.push(<Message type='error' showIcon closable>{err.message}</Message>);
    } finally {
      setSavingCard(false);
      setShowAddCard({ open: false });
      resetCardDetails();
      setModifiedAt(new Date().toISOString());
    }
  }

  const handleMonerisUpdatePrimaryPayment = async (customerId: number, paymentId: string) => {
    const response: any = await client.mutate({
      mutation: gql`mutation updateMonerisPrimaryPayment($customerId: Int!, $paymentId: String!) {
        updateMonerisPrimaryPayment(customerId: $customerId, paymentId: $paymentId) {
          success
          code
          message
          result
        }
      }`, variables: { customerId: customerId, paymentId: paymentId }
    });

    toaster.push(<Message type={response.data.updateMonerisPrimaryPayment.success ? 'success' : 'error'} showIcon closable>{response.data.updateMonerisPrimaryPayment.message}</Message>);
    setModifiedAt(new Date().toISOString());
  }

  const handleMonerisDeletePayment = async (customerId: number, paymentId: string) => {
    const response: any = await client.mutate({
      mutation: gql`mutation deleteMonerisPayment($customerId: Int!, $paymentId: String!) {
        deleteMonerisPayment(customerId: $customerId, paymentId: $paymentId) {
          success
          code
          message
          result
        }
      }`, variables: { customerId: customerId, paymentId: paymentId }
    });

    toaster.push(<Message type={response.data.deleteMonerisPayment.success ? 'success' : 'error'} showIcon closable>{response.data.deleteMonerisPayment.message}</Message>);
    setModifiedAt(new Date().toISOString());
  }

  /**
   * Stripe
   */
  const handleCreateStripeCard = async () => {
    setSavingCard(true);

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      setSavingCard(false);
      return;
    }

    const cardElement = elements.getElement('card');
    let response: any = {};

    try {
      response = await client.mutate({
        mutation: gql`mutation upsertStripeCustomer($id: ID!) {
          upsertStripeCustomer(id: $id) {
            success
            code
            message
            result
          }
        }`, variables: { id: showAddCard.customerId }
      });
      toaster.push(<Message type={response.data.upsertStripeCustomer.success ? 'success' : 'error'} showIcon closable>{response.data.upsertStripeCustomer.message}</Message>)
    } catch (err: any) {
      toaster.push(<Message type='error' showIcon closable>{err.message}</Message>);
      setSavingCard(false);
      return;
    }

    if (cardElement) {
      try {
        const result = await stripe.createPaymentMethod({
          type: 'card',
          card: cardElement,
          metadata: {
            notes: cardDetails.notes
          }
        });

        if (result.error) {
          toaster.push(<Message type='error' showIcon closable>{result.error.message}</Message>);
        } else {
          const binding: any = await client.mutate({
            mutation: gql`mutation attachStripePayment($paymentId: String!, $customerId: ID!, $stripeCustomerId: String!) {
              attachStripePayment(paymentId: $paymentId, customerId: $customerId, stripeCustomerId: $stripeCustomerId) {
                success
                code
                message
                result
              }
            }`, variables: { paymentId: result.paymentMethod.id, customerId: showAddCard.customerId, stripeCustomerId: response.data.upsertStripeCustomer.result.stripeCustomerId }
          });

          toaster.push(<Message type={binding.data.attachStripePayment.success ? 'success' : 'error'} showIcon closable>{binding.data.attachStripePayment.message}</Message>);

          if (binding.data.attachStripePayment.success) {
            setShowAddCard({ open: false });
            setModifiedAt(new Date().toISOString());
          }
        }
      } catch (err: any) {
        toaster.push(<Message type='error' showIcon closable>{err.message}</Message>)
      } finally {
        setSavingCard(false);
      }
    }
  }

  const handleUpdateStripeCard = async () => {
    setSavingCard(true);

    try {
      const response: any = await client.mutate({
        mutation: gql`mutation updateStripePayment($customerId: ID!, $paymentId: String!, $expMonth: String!, $expYear: String!, $notes: String) {
          updateStripePayment(customerId: $customerId, paymentId: $paymentId, expMonth: $expMonth, expYear: $expYear, notes: $notes) {
            success
            code
            message
            result
          }
        }`, variables: { ...cardDetails, customerId: showAddCard.customerId, paymentId: showAddCard.paymentId }
      });
      toaster.push(<Message type={response.data.updateStripePayment.success ? 'success' : 'error'} showIcon closable>{response.data.updateStripePayment.message}</Message>);
    } catch (err: any) {
      toaster.push(<Message type='error' showIcon closable>{err.message}</Message>);
    } finally {
      setSavingCard(false);
      setShowAddCard({ open: false });
      resetCardDetails();
      setModifiedAt(new Date().toISOString());
    }
  }

  const handleStripeUpdatePrimaryPayment = async (customerGuid: string, paymentId: string) => {
    const response: any = await client.mutate({
      mutation: gql`mutation updateStripePrimaryPayment($customerGuid: ID!, $paymentId: String!) {
        updateStripePrimaryPayment(customerGuid: $customerGuid, paymentId: $paymentId) {
          success
          code
          message
          result
        }
      }`, variables: { customerGuid: customerGuid, paymentId: paymentId }
    });

    toaster.push(<Message type={response.data.updateStripePrimaryPayment.success ? 'success' : 'error'} showIcon closable>{response.data.updateStripePrimaryPayment.message}</Message>);
    setModifiedAt(new Date().toISOString());
  }

  const handleStripeDeletePayment = async (customerGuid: string, paymentId: string) => {
    const response: any = await client.mutate({
      mutation: gql`mutation deleteStripePayment($customerGuid: ID!, $paymentId: String!) {
        deleteStripePayment(customerGuid: $customerGuid, paymentId: $paymentId) {
          success
          code
          message
          result
        }
      }`, variables: { customerGuid: customerGuid, paymentId: paymentId }
    });

    toaster.push(<Message type={response.data.deleteStripePayment.success ? 'success' : 'error'} showIcon closable>{response.data.deleteStripePayment.message}</Message>);
    setModifiedAt(new Date().toISOString());
  }

  const handleShareProfile = async (guid: string) => {
    try {
      const shareResource = gql`
        mutation shareResource($resource: String!, $guid: ID!) {
          shareResource(resource: $resource, guid: $guid) {
            success
            code
            message
          }
        }`;

      const response: any = await client.mutate({
        mutation: shareResource, variables: { resource: 'customer', guid }
      });

      const customer = customers.find((c: any) => c.guid === guid);

      if (response.data.shareResource.code === 200) {
        showMessage(
          <div>
            <CopyToClipboard text={response.data.shareResource.message} onCopy={() => toaster.push(<Message type="info" showIcon closable>Copied</Message>)}>
              <div>
                Click to copy public share link:<br /><strong>{response.data.shareResource.message}</strong>
              </div>
            </CopyToClipboard>
          </div>, `Share ${customer.displayName}`);
      } else {
        showError(response.data.shareResource.message);
      }
    } catch (error) {
      showError(error);
    }
  }

  return (
    <div>
      {loading
        ? <Loader content="Loading..." />
        : <FlexboxGrid>
          {customers.filter((c: any) => showInactive ? true : c.deletedAt === null).map((cust: any) =>
            <FlexboxGrid.Item colspan={customerId === undefined ? 12 : 24} key={cust.guid}>
              <Panel className={['content', isMobileOnly ? 'reset' : ''].join(' ')}>
                <div>
                  <dl>
                    {getEnv() === 'dev' &&
                      <>
                        <dt>UUID:</dt>
                        <dd>{cust.guid}</dd>
                      </>
                    }

                    {IS_KALADI_PROPERTIES &&
                      <>
                        <dt>Parent:</dt>
                        <dd>
                          {cust?.parent
                            ? <Link to={`/app/${getEnv()}/workbook/explorer/${cust?.parent?.id}/profile`}>{cust?.parent?.displayName}<br /><small>{cust?.parent?.displayAddress}</small></Link>
                            : <span>-</span>
                          }
                        </dd>

                      </>
                    }

                    <dt>{INTL.CUSTOMER}:</dt>
                    <dd>
                      {cust.displayName}
                      {(hasStripeIntegration(state.companies) && !cust.stripeCustomerId) && <><span> </span><Whisper placement="top" trigger="hover" speaker={<Tooltip>Add a billing profile to create Stripe link</Tooltip>}>
                        <Badge content="Missing Stripe Integration" />
                      </Whisper></>}

                      {(hasXeroIntegration(state.companies) && !cust.xeroCustomerId) && <><span> </span><Whisper placement="top" trigger="hover" speaker={<Tooltip>Resave customer to link with Xero or provide Xero contact id</Tooltip>}>
                        <Badge content="Missing Xero Integration" />
                      </Whisper></>}
                    </dd>

                    <dt>{INTL.CUSTOMER} Since:</dt>
                    {cust.firstWorkOrderDate 
                      ? <dd>{format(parseISO(cust.firstWorkOrderDate), FORMAT.MONTH_DATE)} / Active {cust.yearsActive} Years</dd>
                      : <dd>-</dd>
                    }
                    {hasQuickBooksDesktopIntegration(state.companies) &&
                      <>
                        <dt>Quick Books Code:</dt>
                        <dd>{cust.quickbooksCode}</dd>
                      </>
                    }

                    {(!isRole(ROLE.CLIENT) && !IS_KALADI_PROPERTIES) &&
                      <Fragment>
                        <dt>Status:</dt>
                        <dd>
                          <span>{cust.deletedAt === null
                            ? <span className="text-success">Active</span>
                            : <span className="text-danger">
                              Inactive {cust?.deletedReason && ` - Reason: ${cust?.deletedReason}`} &nbsp;
                              <Whisper placement="top" trigger="hover" speaker={<Tooltip>Restore</Tooltip>}>
                                <a href="/" onClick={(e: any) => {
                                  e.preventDefault();
                                  handleRestore(cust.guid);
                                }}><MdUndo /></a>
                              </Whisper>
                            </span>
                          }</span>
                          <span> {cust.doNotContact && <Badge content="Do Not Contact" />}</span>
                          <span> {cust.doNotService && <Badge content="Do Not Service" />}</span>
                        </dd>
                      </Fragment>
                    }

                    {CONFIG_APPLICATION_FIELDS.customer.includes('propertyManager') &&
                      <Fragment>
                        <dt>Property Manager:</dt>
                        <dd>{cust.propertyManager || '-'}</dd>
                      </Fragment>
                    }

                    {!isRole(ROLE.CLIENT) &&
                      <Fragment>
                        <dt>Label:</dt>
                        <dd>{fieldTitle(cust.label, state.fields)}</dd>
                      </Fragment>
                    }

                    {(!isRole(ROLE.CLIENT) && !IS_KALADI_PROPERTIES && !IS_KALADI_KITCHENS) &&
                      <Fragment>
                        <dt>Square Footage:</dt>
                        <dd>{(cust.squareFootage || '').split('-').length > 1
                          ? cust.squareFootage.split('-')[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + '-' + cust.squareFootage.split('-')[1].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' sqft'
                          : '-'
                        }</dd>
                      </Fragment>
                    }

                    {!isRole(ROLE.CLIENT) &&
                      <Fragment>
                        <dt>Comments:</dt>
                        <dd dangerouslySetInnerHTML={{ __html: (cust.comments || '').replace(/(?:\r\n|\r|\n)/g, '<br />') || '-' }} />
                      </Fragment>
                    }
                  </dl>

                  {IS_BUGABOO && <>
                    <dl>
                      <dt>Irrigation System Onsite:</dt>
                      <dd>{cust?.customFields?.irrigationSystemOnsite ? 'Yes' : 'No'}</dd>
                    </dl>
                  </>}

                  {IS_KALADI_PROPERTIES && <>
                    {!cust?.parentId
                      ? <>
                        <dl>
                          <dt>Sqft Lease</dt>
                          <dd>{cust?.customFields?.sqftLease}</dd>
                        </dl>
                        <dl>
                          <dt>Year Built</dt>
                          <dd>{cust?.customFields?.yearBuilt}</dd>
                        </dl>
                        <dl>
                          <dt>Purchase Date</dt>
                          <dd>{cust?.customFields?.purchaseDate ? format(parseISO(cust?.customFields?.purchaseDate), FORMAT.DAY_MONTH_DATE) : ''}</dd>
                        </dl>
                        <dl>
                          <dt>Plumbing Info</dt>
                          <dd>{cust?.customFields?.plumbingInfo}</dd>
                        </dl>
                        <dl>
                          <dt>Electrical Info</dt>
                          <dd>{cust?.customFields?.electricalInfo}</dd>
                        </dl>
                        <dl>
                          <dt>Structure Info</dt>
                          <dd>{cust?.customFields?.structureInfo}</dd>
                        </dl>
                        <dl>
                          <dt>Roof Info</dt>
                          <dd>{cust?.customFields?.roofInfo}</dd>
                        </dl>
                      </>
                      : <>
                        <dl>
                          <dt>Unit Number</dt>
                          <dd>{cust?.customFields?.unitNumber}</dd>
                        </dl>
                        <dl>
                          <dt>Unit Sqft</dt>
                          <dd>{cust?.customFields?.unitSqft}</dd>
                        </dl>
                        <dl>
                          <dt>Rented</dt>
                          <dd>{cust?.customFields?.unitRented === true ? 'Yes' : 'No'}</dd>
                        </dl>
                      </>
                    }
                  </>
                  }
                </div>

                <div className="mt-8 mb-8">
                  <h6 style={{ fontWeight: 500 }}>Address</h6>
                  <ResponsiveTable
                    html5
                    rowHeight={40}
                    wordWrap
                    autoHeight={true}
                    data={getAddresses(customers.filter((row: any) => row.guid === cust.guid)[0])}
                  >
                    <Column width={250}>
                      <HeaderCell>Address</HeaderCell>
                      <Cell>
                        {(a: any) => {
                          const address = formatAddress(a);

                          return (
                            <div>
                              {a.unit} <a href={`https://www.google.com/maps/place/${encodeURI(address)}`} target="_blank">{a.address}</a>
                              {(IS_GREYROCK || IS_ASSINIBOINE)
                                ? <strong> {a.label === 'site' ? 'Site/Billing' : 'Work'}</strong>
                                : <strong> Site</strong>
                              }
                              {(cust.lat && cust.lng) &&
                                <span> <a href="/" onClick={(e: any) => {
                                  e.preventDefault();
                                  showDrawer(DRAWER.CUSTOMER_MAP_VIEW, { customer: cust });
                                }}><MdMap /></a></span>
                              }
                            </div>
                          );
                        }}
                      </Cell>
                    </Column>
                    <Column>
                      <HeaderCell>City</HeaderCell>
                      <Cell dataKey="city" />
                    </Column>
                    <Column>
                      <HeaderCell>Postal Code</HeaderCell>
                      <Cell dataKey="postalCode" />
                    </Column>
                    <Column>
                      <HeaderCell>Province</HeaderCell>
                      <Cell dataKey="province" />
                    </Column>
                  </ResponsiveTable>
                </div>

                <div className="mt-8 mb-8">
                  <h6 style={{ fontWeight: 500 }}>Contacts</h6>
                  <ResponsiveTable
                    html5
                    rowHeight={40}
                    autoHeight
                    wordWrap
                    data={customers.find((row: any) => row.guid === cust.guid).contactDetails.filter((c: any) => c.name?.trim().length > 0 || c.phone?.length > 0 || c.email?.length > 0)}
                  >
                    <Column flexGrow={1}>
                      <HeaderCell> Name/Label</HeaderCell>
                      <Cell>
                        {(c: any) => <div><span>{c.name}</span> {c.primary && <strong>Primary</strong>}</div>}
                      </Cell>
                    </Column>
                    <Column flexGrow={1}>
                      <HeaderCell>Email</HeaderCell>
                      <Cell>
                        {(c: any) => {
                          return (
                            <span>
                              {c.email && <Fragment>
                                <a href={`mailto:${c.email}`} style={{ wordWrap: 'break-word' }}>{c.email}</a>&nbsp;
                                <CopyToClipboard text={c.email} onCopy={() => toaster.push(<Message type="info" showIcon closable>Copied</Message>)}>
                                  <Whisper placement="top" trigger="hover" speaker={<Tooltip>Copy</Tooltip>}>
                                    <span><MdContentCopy style={{ color: '#337ab7' }} /></span>
                                  </Whisper>
                                </CopyToClipboard>
                              </Fragment>
                              }
                            </span>
                          );
                        }}
                      </Cell>
                    </Column>
                    <Column flexGrow={1}>
                      <HeaderCell>Phone</HeaderCell>
                      <Cell>
                        {(c: any) => {
                          return <span>
                            {c.phone &&
                              <Fragment>
                                <a href={`tel:${c.phone}${c.ext ? `,${c.ext}` : ''}`}>{c.phone}</a> {c.ext && <span>ext.{c.ext}</span>}
                              </Fragment>
                            }
                          </span>
                        }}
                      </Cell>
                    </Column>
                    <Column flexGrow={1}>
                      <HeaderCell>Status</HeaderCell>
                      <Cell>
                        {(c: any) => {
                          return <span>
                            {(c.status || []).map((s: any) => ['system_email_bounced'].includes(s)
                              ? <span className="text-danger">{state.services[s]}</span> : <span>{state.services[s]}</span>)}
                          </span>
                        }}
                      </Cell>
                    </Column>
                  </ResponsiveTable>
                </div>


                {(isRole(ROLE.ADMIN, ROLE.MANAGER) && (hasMonerisIntegration(state.companies) || hasStripeIntegration(state.companies))) &&
                  <div className="mt-8 mb-8">
                    <Stack spacing={6}>
                      <h6 style={{ fontWeight: 500 }}>Billing</h6>
                      {(hasMonerisIntegration(state.companies) || hasStripeIntegration(state.companies)) &&
                        <Button size="xs" appearance='primary' onClick={() => {
                          resetCardDetails();
                          setShowAddCard({ open: true, customerId: cust.guid })
                        }}>Add</Button>}

                      {hasStripeIntegration(state.companies) &&
                        <>
                          <Button size="xs" onClick={() => {
                            showConfirmation(
                              <p>Are you sure you want to refresh payment methods for this customer? This action will retrieve and update payment methods from Stripe.</p>,
                              'Refresh Payment Methods', () => handleRefreshPaymentMethods(cust.guid)
                            );
                          }}>Refresh</Button>
                          <Button size="xs" onClick={() => handleShareProfile(cust.guid)}>Share</Button>
                        </>
                      }
                    </Stack>


                    <ResponsiveTable
                      rowHeight={40}
                      html5
                      autoHeight
                      wordWrap
                      data={customers.find((row: any) => row.guid === cust.guid).billing}
                    >
                      <Column flexGrow={1}>
                        <HeaderCell>Credit Card</HeaderCell>
                        <Cell>
                          {(c: any) => <span>{c.maskedPan}</span>}
                        </Cell>
                      </Column>
                      <Column>
                        <HeaderCell>Expiry</HeaderCell>
                        <Cell>
                          {(c: any) => <span>{c.expMonth}/{c.expYear}</span>}
                        </Cell>
                      </Column>
                      <Column flexGrow={2}>
                        <HeaderCell>Notes</HeaderCell>
                        <Cell>
                          {(c: any) => <span>{c.notes}</span>}
                        </Cell>
                      </Column>
                      <Column>
                        <HeaderCell>Primary</HeaderCell>
                        <Cell>
                          {(c: any) => <span>{c.isDefault ? <strong>Primary</strong> : <a href="/" onClick={(e: any) => {
                            e.preventDefault();

                            if (hasMonerisIntegration(state.companies)) {
                              handleMonerisUpdatePrimaryPayment(cust.id, c.dataKey);
                            } else if (hasStripeIntegration(state.companies)) {
                              handleStripeUpdatePrimaryPayment(cust.guid, c.dataKey);
                            }
                          }}>Make Primary</a>}</span>}
                        </Cell>
                      </Column>
                      <Column width={50}>
                        <HeaderCell>&nbsp;</HeaderCell>
                        <Cell className="link-group text-right">
                          {(c: any) => <>
                            <Whisper placement="bottom" trigger="hover" speaker={<Tooltip>Edit</Tooltip>}>
                              <IconButtonWrapper
                                appearance="link"
                                onClick={() => {
                                  setCardDetails({ ...cardDetails, notes: c.notes });
                                  setShowAddCard({ open: true, customerId: cust.guid, paymentId: c.dataKey });
                                }}
                                icon={'edit'}
                              /></Whisper>
                            <Whisper placement="bottom" trigger="hover" speaker={<Tooltip>Delete</Tooltip>}>
                              <IconButtonWrapper
                                color={'red'}
                                appearance="link"
                                onClick={() => showConfirmation(`Are you sure you want to delete payment method ${c.maskedPan}?`, 'Delete Payment Method', () => {
                                  if (hasMonerisIntegration(state.companies)) {
                                    handleMonerisDeletePayment(cust.id, c.dataKey);
                                  } else if (hasStripeIntegration(state.companies)) {
                                    handleStripeDeletePayment(cust.guid, c.dataKey);
                                  }
                                })}
                                icon={'delete'}
                              /></Whisper>
                          </>}
                        </Cell>
                      </Column>
                    </ResponsiveTable>
                  </div>
                }

                {IS_KALADI_KITCHENS && <div className="mt-8 mb-8">
                  <Grid fluid className='p-0'>
                    <h6 style={{ fontWeight: 500 }}>Lease Details</h6>
                    <Row className='mt-8'>
                      <Col md={12}>
                        <dt>Location</dt>
                        <dd>{state.services?.[cust?.user?.accessGroup]}</dd>
                      </Col>
                      <Col md={4}>
                        <dt>Monthly Hours</dt>
                        <dd>{cust?.props?.customLeaseHours}</dd>
                      </Col>
                      <Col md={4}>
                        <dt>Extra Hours</dt>
                        <dd>{cust?.props?.customLeaseExtraHours}</dd>
                      </Col>
                      <Col md={4}>
                        <dt>Lease Type</dt>
                        <dd>{startCase(cust?.props?.customLeaseType)}</dd>
                      </Col>
                    </Row>
                  </Grid>
                </div>
                }

                <div className="mt-8 mb-8">
                  <h6 style={{ fontWeight: 500 }}>Attachments</h6>
                  {IS_KALADI_KITCHENS && <Message className='mt-8' type="info">Tenant {(INTL.DOCUMENTS || 'documents').toLowerCase()} have moved under {INTL.DOCUMENTS} tab.</Message>}
                  <ResponsiveTable
                    rowHeight={40}
                    html5
                    autoHeight
                    wordWrap
                    data={customers.find((row: any) => row.guid === cust.guid).attachments}
                  >
                    <Column flexGrow={1}>
                      <HeaderCell>Name</HeaderCell>
                      <Cell>
                        {(c: any) => <Button size="sm" style={{ padding: 0 }} appearance="link" onClick={() =>
                          window.location.href = `${api.baseUrl}/attachments/download/${c.guid}`}>{c.fileName}</Button>}
                      </Cell>
                    </Column>
                    <Column flexGrow={2}>
                      <HeaderCell>Description</HeaderCell>
                      <Cell dataKey="description" />
                    </Column>
                    {IS_KALADI_KITCHENS &&
                      <Column flexGrow={1}>
                        <HeaderCell>Type</HeaderCell>
                        <Cell>{(c: any) => <span>{fieldTitle(c.type, state.fields)}</span>}</Cell>
                      </Column>
                    }
                    {/* <Column flexGrow={1}>
                        <HeaderCell>Size</HeaderCell>
                        <Cell>{(c: any) => <span>{fileSize(c.fileSize)}</span>}</Cell>
                      </Column> */}
                  </ResponsiveTable>
                </div>

                <div className="mt-24">
                  <ButtonToolbar>
                    <IconButton
                      size='sm'
                      appearance='primary'
                      onClick={() => {
                        showDrawer(DRAWER.CUSTOMER_FORM, { action: 'edit', customerGuid: cust.guid, customerId: cust.id }, (data: any) => {
                          if (onCustomerUpdate) {
                            onCustomerUpdate(data);
                          }
                          setModifiedAt(new Date().toISOString());
                        });
                      }}
                      icon={<Icon as={MdEdit} />}
                    >Edit</IconButton>

                    {!isRole(ROLE.CLIENT) &&
                      <IconButton
                        size='sm'
                        onClick={() => {
                          if (customers.length === 1) {
                            showMessage('Cannot detach single from single customer record', 'Detach Customer');
                          } else {
                            showConfirmation(
                              <p>Are you sure you want to separate this customer record into its own?</p>,
                              'Detach Customer',
                              () => handleDetach(cust.guid)
                            );
                          }
                        }}
                        icon={<Icon as={MdViewColumn} />}
                      >
                        Detach
                      </IconButton>
                    }

                    {!isRole(ROLE.CLIENT) &&
                      <IconButton
                        size='sm'
                        onClick={() => {
                          showDrawer(DRAWER.CUSTOMER_FORM, { action: 'copy', customerGuid: cust.guid, customerId: cust.id }, (data: any) => {
                            history.push(`/app/${getEnv()}/workbook/explorer/${data.id}`);
                          });
                        }}
                        icon={<Icon as={MdContentCopy} />}
                      >Copy</IconButton>
                    }

                    {(!isRole(ROLE.CLIENT) && cust.registration) &&
                      <Button
                        onClick={() => setShowInquiryEvent({ show: true, data: cust.registration })}
                      ><MdOutlineQuestionMark /> Inquiry</Button>
                    }

                    {!isRole(ROLE.CLIENT) &&
                      <Dropdown title="More" placement='topStart' size='sm'>
                        <Dropdown.Item onSelect={() => {
                          showDrawer(DRAWER.EMAIL_FORM, {
                            resources: [{ customer: { ...cust, resourceGuid: cust.guid }, resourceGuid: cust.guid, guid: cust.guid } as any],
                            resourceGuid: cust.guid,
                            group: undefined,
                            type: 'customer',
                            templateId: undefined,
                            users: []
                          }, () => setModifiedAt(new Date().toISOString()));
                        }}>Email</Dropdown.Item>
                        <Dropdown.Item
                          onSelect={() => {
                            showDrawer(DRAWER.CUSTOMER_FORM, { action: 'clone', customerGuid: cust.guid, customerId: cust.id }, (data: any) => {
                              history.push(`/app/${getEnv()}/workbook/explorer/${data.id}`);
                            });
                          }}
                        >Clone</Dropdown.Item>
                        <Dropdown.Item
                          onSelect={() => {
                            showDrawer(DRAWER.RESOURCE_HISTORY, {
                              resourceGuid: cust.guid,
                              resource: 'customer'
                            });
                          }}
                        >History</Dropdown.Item>
                        <Dropdown.Item onSelect={() => handleGeocode(cust.guid)}>Geocode</Dropdown.Item>
                        {cust.deletedAt === null
                          ? <Dropdown.Item
                            style={{ color: COLOR.DANGER }}
                            onSelect={() => {
                              showConfirmationWithReason(
                                <p>Are you sure you want to remove this customer?</p>,
                                'Remove Customer', (reason: string | undefined) => {
                                  handleDelete(cust.guid, reason);
                                }
                              );
                            }}
                          ><span className="text-danger">Delete</span></Dropdown.Item>
                          : <Dropdown.Item onSelect={() => handleRestore(cust.guid)}>Restore</Dropdown.Item>
                        }
                      </Dropdown>

                    }
                  </ButtonToolbar>
                </div>

                <div className="mt-24">
                </div>
              </Panel>
            </FlexboxGrid.Item>
          )}
        </FlexboxGrid>
      }

      <Drawer
        open={showInquiryEvent.show}
        onClose={() => setShowInquiryEvent({ show: false, data: undefined })}
        full={isMobileOnly}
      >
        <Drawer.Header>
          <Drawer.Title>Inquiry Details</Drawer.Title>
        </Drawer.Header>
        <Drawer.Body>
          <dl>
            {Object.keys(showInquiryEvent.data || {}).map((d: any) =>
              <Fragment>
                <dt>{startCase(d)}</dt>
                <dd>{showInquiryEvent.data[d]}</dd>
              </Fragment>
            )}
          </dl>
        </Drawer.Body>
        <Drawer.Footer>
          <Button size="sm" appearance="subtle" onClick={() => {
            setShowInquiryEvent({ show: false, data: undefined });
            resetCardDetails();
          }}>Close</Button>
        </Drawer.Footer>
      </Drawer>

      <Drawer
        open={showAddCard.open}
        onClose={() => setShowAddCard({ open: false })}
        size='sm'
      >
        <Drawer.Header>
          <Drawer.Title>
            {showAddCard?.paymentId ? 'Edit' : 'Add'} Card
            {hasMonerisIntegration(state.companies) && <span> - Moneris</span>}
            {hasStripeIntegration(state.companies) && <span> - Stripe</span>}
          </Drawer.Title>
        </Drawer.Header>
        <Drawer.Body>
          <div className='mb-8'>
            <label>Card information {showAddCard.paymentId && <span> - {showAddCard.paymentId}</span>}: </label>
            {hasMonerisIntegration(state.companies) && <>
              <Grid fluid className='p-0'>
                {showAddCard?.paymentId
                  ? <Row>
                    <Col xs={3}>
                      <Input id={'creditcard_expiry_month'} maxLength={2} placeholder='MM' onChange={(val: string) => {
                        setCardDetails({ ...cardDetails, expMonth: val });
                        if (val.length === 2) {
                          document.getElementById('creditcard_expiry_year')?.focus();
                        }
                      }} />
                    </Col>
                    <Col xs={3}>
                      <Input id={'creditcard_expiry_year'} maxLength={2} placeholder='YY' onChange={(val: string) => {
                        setCardDetails({ ...cardDetails, expYear: val });
                        if (val.length === 2) {
                          document.getElementById('creditcard_cvc')?.focus();
                        }
                      }} />
                    </Col>
                  </Row>
                  : <Row>
                    <Col xs={15}>
                      <Input placeholder='Card number' maxLength={16} onChange={(val: string) => {
                        setCardDetails({ ...cardDetails, cardNumber: val });
                        if (val.length === 16) {
                          document.getElementById('creditcard_expiry_month')?.focus();
                        }
                      }} />
                    </Col>
                    <Col xs={3}>
                      <Input id={'creditcard_expiry_month'} maxLength={2} placeholder='MM' onChange={(val: string) => {
                        setCardDetails({ ...cardDetails, expMonth: val });
                        if (val.length === 2) {
                          document.getElementById('creditcard_expiry_year')?.focus();
                        }
                      }} />
                    </Col>
                    <Col xs={3}>
                      <Input id={'creditcard_expiry_year'} maxLength={2} placeholder='YY' onChange={(val: string) => {
                        setCardDetails({ ...cardDetails, expYear: val });
                        if (val.length === 2) {
                          document.getElementById('creditcard_cvc')?.focus();
                        }
                      }} />
                    </Col>
                    <Col xs={3}>
                      <Input id={'creditcard_cvc'} maxLength={4} placeholder='CVC' onChange={(val: string) => setCardDetails({ ...cardDetails, cvc: val })} />
                    </Col>
                  </Row>
                }
              </Grid>
            </>}

            {hasStripeIntegration(state.companies) && <>
              {showAddCard?.paymentId
                ? <Grid fluid className='p-0'>
                  <Row>
                    <Col xs={3}>
                      <Input id={'creditcard_expiry_month'} maxLength={2} placeholder='MM' onChange={(val: string) => {
                        setCardDetails({ ...cardDetails, expMonth: val });
                        if (val.length === 2) {
                          document.getElementById('creditcard_expiry_year')?.focus();
                        }
                      }} />
                    </Col>
                    <Col xs={3}>
                      <Input id={'creditcard_expiry_year'} maxLength={2} placeholder='YY' onChange={(val: string) => {
                        setCardDetails({ ...cardDetails, expYear: val });
                        if (val.length === 2) {
                          document.getElementById('creditcard_cvc')?.focus();
                        }
                      }} />
                    </Col>
                  </Row>
                </Grid>
                : <CardElement options={{ hidePostalCode: true }} />
              }
            </>}
          </div>
          <div>
            <label>Notes:</label>
            <Input onChange={(val: any) => setCardDetails({ ...cardDetails, notes: val })} value={cardDetails.notes} />
          </div>
        </Drawer.Body>
        <Drawer.Footer>
          <Button size="sm" appearance="primary" onClick={() => {
            if (showAddCard?.paymentId) {
              // edit
              if (hasMonerisIntegration(state.companies)) {
                handleUpdateMonerisCard();
              } else if (hasStripeIntegration(state.companies)) {
                handleUpdateStripeCard();
              } else {
                showMessage('No payment method defined', 'Missing Payment');
              }
            } else {
              // add
              if (hasMonerisIntegration(state.companies)) {
                handleCreateMonerisCard();
              } else if (hasStripeIntegration(state.companies)) {
                handleCreateStripeCard();
              } else {
                showMessage('No payment method defined', 'Missing Payment');
              }
            }
          }} loading={savingCard}>Save</Button>
          <Button size="sm" appearance="subtle" onClick={() => {
            setShowAddCard({ open: false });
            resetCardDetails();
          }}>Close</Button>
        </Drawer.Footer>
      </Drawer>
    </div>
  );
};

export default WorkbookCustomer;
