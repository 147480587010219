import { gql, useApolloClient, useQuery } from '@apollo/client';
import { Textarea } from 'components/rsuite';
import { GET_CUSTOMER } from 'gql/customer';
import { getEnv } from 'lib/env';
import { useQueryString } from 'lib/hooks';
import { startCase } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import {
  toaster,
  Message,
  Form,
  Loader,
  Panel,
  CheckPicker
} from 'rsuite';
import { DrawerFormWrapper } from 'shared';

interface IIrrigationSystemForm {
  drawer?: boolean,
  show?: boolean,
  guid?: any,
  action?: any,
  customerId?: number,
  onHide?: () => void,
  onUpdate?: (data: any) => void
}

const IrrigationSystemForm = ({ show, guid, action, drawer, customerId, onHide, onUpdate }: IIrrigationSystemForm) => {
  let form: any = useRef();
  const container = useRef() as React.MutableRefObject<HTMLDivElement>;
  const match = useRouteMatch();
  const query = useQueryString();
  const client = useApolloClient();
  const [formValue, setFormValue] = useState<any>({});
  const [formError, setFormError] = useState({});
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const customer = useQuery(GET_CUSTOMER, { variables: { id: +(customerId || -1) } });
  guid = query?.guid || (match?.params as any)?.guid || guid;
  action = query?.action || action || (match?.params as any)?.action || 'add';

  useEffect(() => {
    if (customer?.data?.customer) {
      setFormValue(customer?.data?.customer?.irrigationSystem || {});
    }
  }, [customer]);

  const handleSubmit = async (stay: boolean) => {
    setSaving(true);

    const upsertCustomer = gql`
      mutation upsertCustomer($input: UpsertCustomerInput!) {
        upsertCustomer(input: $input) {
          success
          code
          message
          result
        }
      }
    `;

    const response: any = await client.mutate({
      mutation: upsertCustomer, variables: {
        input: {
          guid: customer?.data?.customer?.guid,
          irrigationSystem: formValue
        }
      }
    });

    if (response.data.upsertCustomer.success) {
      toaster.push(
        <Message type="success" showIcon closable>Irrigation system updated</Message>
      );

      onHide && onHide();
      onUpdate && onUpdate(response.data.upsertCustomer.result);
    } else {
      toaster.push(
        <Message type="error" showIcon closable duration={3000}>Failed to update irrigation system</Message>
      );
    }

    setSaving(false);
  }

  const Wrapper = show === undefined
    ? <div />
    : <DrawerFormWrapper
      loading={saving}
      show={show}
      title={startCase(action) + ' Irrigation System'}
      guid={guid}
      size={'md'}
      action={action}
      form={`/app/${getEnv()}/irrigation-system/form/view/${guid}`}
      onHide={onHide}
      onSave={handleSubmit}
    />;

  return React.cloneElement(Wrapper, {
    children: <div>
      {!drawer &&
        <div></div>
      }

      <Panel className="content" ref={container}>
        {loading
          ? <Loader content="Loading..." />
          : <>
            <Message className='mb-16'>Press <strong>Enter</strong> when typing to enter multiple line items.</Message>
            <Form
              fluid
              className={action === 'view' ? 'readonly' : ''}
              ref={(ref: any) => form = ref}
              formValue={formValue}
              formError={formError}
              onChange={setFormValue}
              onCheck={setFormError}
            >
              <Form.Group>
                <Form.ControlLabel>Number of Controllers:</Form.ControlLabel>
                <Form.Control name={'numberOfControllers'} accepter={Textarea} rows={1} />
              </Form.Group>
              <Form.Group>
                <Form.ControlLabel>Number of Zones:</Form.ControlLabel>
                <Form.Control name={'numberOfZones'} accepter={Textarea} rows={1} />
              </Form.Group>
              <Form.Group>
                <Form.ControlLabel>Number of Water Supply Valves:</Form.ControlLabel>
                <Form.Control name={'numberOfWaterSupplyValves'} accepter={Textarea} rows={1} />
              </Form.Group>
              <Form.Group>
                <Form.ControlLabel>Number of Blow Out Ports:</Form.ControlLabel>
                <Form.Control name={'numberOfBlowOutPorts'} accepter={Textarea} rows={1} />
              </Form.Group>
              <Form.Group>
                <Form.ControlLabel>Access Information:</Form.ControlLabel>
                <Form.Control name={'accessInformation'} accepter={Textarea} rows={1} />
              </Form.Group>
              <Form.Group>
                <Form.ControlLabel>Controller/Quantity/Location/Information:</Form.ControlLabel>
                <Form.Control name={'controllerQuantityLocationInformation'} accepter={Textarea} rows={1} />
              </Form.Group>
              <Form.Group>
                <Form.ControlLabel>Water Supply Location/Information:</Form.ControlLabel>
                <Form.Control name={'waterSupplyLocationInformation'} accepter={Textarea} rows={1} />
              </Form.Group>
              <Form.Group>
                <Form.ControlLabel>Blow Out Ports Location/Information:</Form.ControlLabel>
                <Form.Control name={'blowOutPortsLocationInformation'} accepter={Textarea} rows={1} />
              </Form.Group>
              <Form.Group>
                <Form.ControlLabel>Notes/Work Comments:</Form.ControlLabel>
                <Form.Control name={'notesWorkComments'} accepter={Textarea} rows={1} />
              </Form.Group>
              <Form.Group>
                <Form.ControlLabel>Repairs/Materials/Recommendations:</Form.ControlLabel>
                <Form.Control name={'repairsMaterialsRecommendations'} accepter={Textarea} rows={1} />
              </Form.Group>
              <Form.Group>
                <Form.ControlLabel>Irrigation Schedule:</Form.ControlLabel>
                <Form.Control name={'irrigationSchedule'} accepter={Textarea} rows={1} />
              </Form.Group>
              <Form.Group>
                <Form.ControlLabel>Cut Days:</Form.ControlLabel>
                <Form.Control
                  name="cutDays"
                  accepter={CheckPicker}
                  block
                  container={() => container && container.current}
                  searchable={false}
                  cleanable={false}
                  data={[
                    { label: 'Mon', value: 'mon' },
                    { label: 'Tue', value: 'tue' },
                    { label: 'Wed', value: 'wed' },
                    { label: 'Thu', value: 'thu' },
                    { label: 'Fri', value: 'fri' },
                    { label: 'Sat', value: 'sat' },
                    { label: 'Sun', value: 'sun' }
                  ]} />
              </Form.Group>
            </Form>
          </>
        }
      </Panel>
    </div>
  });
}

export default IrrigationSystemForm;