import { useState, useEffect } from 'react';
import {
  Button,
  ButtonToolbar,
  SelectPicker,
  Drawer,
  Table,
  Input,
  DateRangePicker,
  toaster,
  Message,
  Loader
} from 'rsuite';
import { startCase } from 'lodash';
import INTL from 'tenant/intl';
import { gql, useApolloClient, useQuery } from '@apollo/client';
import { queryWorkOrders } from 'gql/workOrders';
import { ResponsiveTable } from 'shared';
import { fieldTitle, filterFieldsByParentKey } from 'lib/helpers/field';
import { useViewport } from 'shared/ViewportProvider';
import { addYearToDates } from 'lib/date';
import { IS_BUGABOO } from 'lib/tenant';
import { FORMAT } from 'lib/env';
import { format } from 'date-fns';
import { MdCancel, MdCheckCircle } from 'react-icons/md';
import { CONFIG_COST_TYPE } from 'tenant/config';

const { Column, HeaderCell, Cell } = Table;

interface IWorkOrderRepeat {
  onHide: () => void,
  onUpdate: (data: any) => void,
  jobGuids: string[],
  group: boolean
}

const WorkOrderRepeat = ({
  onHide,
  onUpdate,
  jobGuids,
  group
}: IWorkOrderRepeat) => {
  const client = useApolloClient();
  const { state } = useViewport();
  const serviceKeys = filterFieldsByParentKey(state.fields, 'work_types').filter((w: any) => w.visibleOnBooking).map((w: any) => w.key);
  const [formValues, setFormValues] = useState<any>([]);
  const { data } = useQuery(queryWorkOrders, { variables: { limit: 999, offset: 0, where: { AND: [{ guid: { in: jobGuids } }] } } });
  const [perCostTypeCosts, setPerCostTypeCosts] = useState<any>({});
  const [costType, setCostType] = useState<any>({});
  const [totalCosts, setTotalCosts] = useState<any>({});
  const [saving, setSaving] = useState(false);
  const [status, setStatus] = useState<any>({});

  useEffect(() => {
    const repeatCostType: any = {};
    const repeatPerCostTypeCosts: any = {};
    const repeatTotalCosts: any = {};

    (data?.workOrders?.edges?.node || []).map((w: any) => {
      repeatPerCostTypeCosts[w.guid] = w.perCostTypeCost;
      repeatTotalCosts[w.guid] = w.totalCost;
      repeatCostType[w.guid] = w.costType;
    });

    setCostType(repeatCostType);
    setPerCostTypeCosts(repeatPerCostTypeCosts);
    setTotalCosts(repeatTotalCosts);
    setFormValues((data?.workOrders?.edges?.node || []).map((w: any) => ({
      ...w,
      startEndDate: addYearToDates([w.startDate, w.endDate], 1),
      totalCost: w?.totalCost || '',
      status: null
    })));
    setStatus((data?.workOrders?.edges?.node || []).map((w: any) => w.guid).reduce((p: any, n: any) => ({ ...p, [n]: 'processing' }), {}));
  }, [data?.workOrders?.edges?.node]);


  const handleSubmit = async (form: any) => {
    setSaving(true);
    let response: any = null;

    const repeatWorkOrders = gql`
      mutation repeatWorkOrders ($input: [RepeatWorkOrdersInput]) {
        repeatWorkOrders(input: $input) {
          code
          success
          message
          result
        }
      }
    `;

    const updatedState: any = {};

    for (const f of form) {
      try {
        response = await client.mutate({
          mutation: repeatWorkOrders, variables: {
            input: [
              {
                jobGuid: f.guid,
                perCostTypeCost: perCostTypeCosts[f.guid] || undefined,
                costType: costType[f.guid] || undefined,
                totalCost: totalCosts[f.guid] || undefined,
                userId: f.userId,
                workerId: f.workerId,
                startEndDate: [f.startEndDate[0], f.startEndDate[1]],
                recurrence: (IS_BUGABOO && f.recurrence === 'onetime_fixed') ? 'onetime' : f.recurrence,
                createAsSingleWorkOrder: group
              }
            ]
          }
        });

        updatedState[f.guid] = 'completed';
      } catch (err: any) {
        updatedState[f.guid] = 'failed';
      } finally {
        updatedState[f.guid] = 'completed';
      }

      setStatus({ ...status, ...updatedState });
    }

    if (response?.data?.repeatWorkOrders?.success) {
      toaster.push(
        <Message type="success" showIcon closable>{response.data.repeatWorkOrders.message}</Message>
      );
      setSaving(false);
      onHide && onHide();
      onUpdate && onUpdate(response);
    } else {
      toaster.push(
        <Message type="error" showIcon closable>Workers renewed but with some errors. Administrator has been notified and these will be reviewed.</Message>
      );

      setSaving(false);
      onUpdate && onUpdate(response);
    }
  }

  return (
    <Drawer backdrop='static' open onClose={onHide} full>
      <Drawer.Header><Drawer.Title>Repeat {group ? ' & Group' : ''} {INTL.WORK_ORDERS}</Drawer.Title></Drawer.Header>
      <Drawer.Body>
        {group &&
          <div className='mb-8'>
            Group following work orders under a new parent. This will put all jobs completed on a single invoice.<br />
          </div>
        }

        <ResponsiveTable html5 data={formValues}>
          <Column>
            <HeaderCell>Group</HeaderCell>
            <Cell>
              {(row: any) => <div>
                {(saving && status[row.guid] === 'processing') && <Loader style={{ display: 'inline' }} />}
                {(saving && status[row.guid] === 'completed') && <MdCheckCircle style={{ color: '#5cb85c' }} />}
                {(saving && status[row.guid] === 'failed') && <MdCancel style={{ color: '#5cb85c' }} />}
                <span> </span>
                {startCase(row.applicationGroup)}
              </div>
              }
            </Cell>
          </Column>
          <Column>
            <HeaderCell>Services</HeaderCell>
            <Cell>
              {(row: any) => {
                return <span>{fieldTitle((row.services || []).filter((s: any) =>
                  serviceKeys.includes(s)), state.fields).join(', ')}{row.otherServices && ': ' + row.otherServices}</span>
              }}
            </Cell>
          </Column>
          <Column>
            <HeaderCell>Start/End Date:</HeaderCell>
            <Cell>
              {(row: any) => <DateRangePicker
                cleanable={false}
                renderValue={(date: Date[]) => format(date[0], FORMAT.DAY_MONTH_DATE) + ' ~ ' + format(date[1], FORMAT.DAY_MONTH_DATE)}
                block
                value={row.startEndDate}
                onChange={(val: any) => {
                  setFormValues(formValues.map((v: any) => ({
                    ...v,
                    startEndDate: v.guid === row.guid ? val : v.startEndDate
                  })));
                }}
              />}
            </Cell>
          </Column>
          <Column width={60}>
            <HeaderCell>Cost Type</HeaderCell>
            <Cell>
              {(row: any) => <SelectPicker
                value={costType[row.guid]}
                cleanable={false}
                block
                data={CONFIG_COST_TYPE.concat(row.applicationGroup === 'arborcare__242153b6__1644606571' ? { label: 'Per Tree/Shrub', value: 'per_tree_shrub' } : null).filter((ct: any) => ct)}
                onChange={(val: any) => {
                  const tmpCosts = { ...costType };
                  tmpCosts[row.guid] = val;
                  setCostType(tmpCosts);
                }}
              />}
            </Cell>
          </Column>
          <Column width={50}>
            <HeaderCell>Per Visit</HeaderCell>
            <Cell>
              {(row: any) => <Input value={perCostTypeCosts[row.guid]} onChange={(val: any) => {
                const tmpCosts = { ...perCostTypeCosts };

                if (isNaN(parseFloat(val))) {
                  setPerCostTypeCosts('');
                } else {
                  tmpCosts[row.guid] = parseFloat(val);
                  setPerCostTypeCosts(tmpCosts);
                }
              }} />}
            </Cell>
          </Column>
          <Column width={50}>
            <HeaderCell>Total Cost</HeaderCell>
            <Cell>
              {(row: any) => <Input value={totalCosts[row.guid]} onChange={(val: any) => {
                const tmpCosts = { ...totalCosts };

                if (isNaN(parseFloat(val))) {
                  setTotalCosts('');
                } else {
                  tmpCosts[row.guid] = parseFloat(val);
                  setTotalCosts(tmpCosts);
                }
              }} />}
            </Cell>
          </Column>
          <Column>
            <HeaderCell>User</HeaderCell>
            <Cell>
              {(row: any) => <SelectPicker
                block
                value={row.workerId || row.userId}
                groupBy="role"
                data={state.users.filter((u: any) => (u.workGroup || []).includes(row.applicationGroup))}
                onChange={(val: any) => {
                  const user = state.users.find((u: any) => u.id === val);

                  if (user.userlevel === 'worker') {
                    setFormValues(formValues.map((v: any) => ({
                      ...v,
                      userId: v.guid === row.guid ? user.workingUnderId : v.userId,
                      workerId: v.guid === row.guid ? val : v.userId,
                    })));
                  } else {
                    setFormValues(formValues.map((v: any) => ({
                      ...v,
                      userId: v.guid === row.guid ? user.id : v.userId,
                      workerId: v.guid === row.guid ? null : v.workerId,
                    })));
                  }
                }}
              />
              }
            </Cell>
          </Column>
        </ResponsiveTable>
      </Drawer.Body>
      <Drawer.Footer>
        <ButtonToolbar>
          <Button size="sm" onClick={() => handleSubmit(formValues)} appearance="primary" loading={saving}>Repeat</Button>
          <Button size="sm" onClick={onHide} appearance="subtle" disabled={saving}>Close</Button>
        </ButtonToolbar>
      </Drawer.Footer>
    </Drawer>
  );
}

export {
  WorkOrderRepeat
}
