import gql from 'graphql-tag';
import { ApolloClient } from '@apollo/client';

const getTemplates = async (client: ApolloClient<object>) => {
  const results = await client.query({
    query: gql`
      query templates($limit: Int!, $offset: Int!) {
        templates(filter: {
          limit: $limit,
          offset: $offset
        }) {
          edges {
            node {
              guid
              id
              name
              type
            }
          },
          totalCount
        }
      }`,
    variables: {
      offset: 0,
      limit: 999
    },
    fetchPolicy: 'no-cache'
  });

  return results.data.templates.edges.node;
}

export {
  getTemplates
}
