import { gql, useApolloClient } from "@apollo/client";
import { Message } from "components/rsuite";
import { queryDocumentForm } from "gql/documents";
import { useApi } from "lib";
import { COLOR } from "lib/env";
import { createRef, useState } from "react";
import { Button, ButtonToolbar, Form, Loader, Panel, Progress } from "rsuite";

interface ILargeAttachmentForm {
  resourceGuid: string,
  handleUpload: (data: any) => void,
  handleHide: () => void
}

const LargeAttachmentForm = ({ resourceGuid, handleUpload, handleHide }: ILargeAttachmentForm) => {
  const client = useApolloClient();
  const api = useApi();
  const [saving, setSaving] = useState(false);
  const [fileSize, setFileSize] = useState(1);
  const [uploadedSize, setUploadedSize] = useState(0);
  let fileRef: any = createRef();

  const handleSubmit = async () => {
    setSaving(true);
    const fileField = fileRef.files[0];
    setFileSize(fileField.size);

    const data = await client.mutate({
      mutation: gql`
      mutation signedUrlForAttachments($resourceGuid: ID!, $fileName: String, $fileSize: Float) {
        signedUrlForAttachments(resourceGuid: $resourceGuid, fileName: $fileName, fileSize: $fileSize) {
          code
          success
          message
          result
        }
      }`,
      variables: {
        resourceGuid,
        fileName: fileField.name,
        fileField: fileField.size
      }
    });

    const formData = new FormData();
    Object.keys(data.data.signedUrlForAttachments.result.fields).forEach((f: any) => {
      formData.append(f, data.data.signedUrlForAttachments.result.fields[f]);
    });

    formData.append('file', fileField);
    await api.postS3(data.data.signedUrlForAttachments.result.url, formData, {
      onUploadProgress: (progressEvent: any) => setUploadedSize(progressEvent.loaded)
    });

    await client.mutate({
      mutation: gql`
      mutation upsertAttachment($input: [UpsertAttachmentInput]) {
        upsertAttachment(input: $input) {
          code
          success
          message
          result
        }
      }`,
      variables: {
        input: [{ guid: data.data.signedUrlForAttachments.result.fields.guid, deletedAt: null }]
      }
    });

    const documentQuery = await client.query({ query: queryDocumentForm, variables: { guid: resourceGuid } });
    handleUpload(documentQuery.data.document.attachments);
    setSaving(false);
    handleHide();
  }

  return (
    <Panel className="content pl-10 pr-10">
      <Message type="info" style={{ marginBottom: '1rem' }}>
        <p>
          <strong>This form is intended for attachments over 10MB.</strong>
        </p>
      </Message>

      {saving
        ? <>
          <Loader content="Saving... this may take a few seconds. This window will automatically close when finished." />
          <Progress.Line percent={Math.floor(uploadedSize / fileSize * 100)} strokeWidth={4} strokeColor={COLOR.LINK} />
        </>
        : <div>
          <input type="file" ref={(ref: any) => fileRef = ref} name="file" />
          <Form.HelpText>Maximum upload size is 150MB, file type is restricted to application/pdf.</Form.HelpText>
          <ButtonToolbar style={{ marginTop: '1rem' }}>
            <Button loading={saving} onClick={handleSubmit} appearance="primary" size="sm">Upload</Button>
            <Button disabled={saving} onClick={handleHide} appearance={'subtle'} size="sm">Cancel</Button>
          </ButtonToolbar>
        </div>
      }
    </Panel>
  );

}

export default LargeAttachmentForm
