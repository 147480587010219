import { flatten, orderBy, sortBy, startCase } from 'lodash';
import INTL from 'tenant/intl';

const getRoleName = (u: any) => {
  const intlKey = u.userlevel.toUpperCase();
  if (INTL.hasOwnProperty(intlKey)) {
    return (INTL as any)[intlKey];
  }

  return startCase(u.userlevel === 'franchise' ? 'crew' : u.userlevel);
}

const reducer = (state: any, action: any) => {
  switch (action.type) {
    case 'SET_RECORDING_SESSION':
      return {
        ...state,
        recording: action.payload
      }

    case 'SET_MODIFIED_AT':
      return {
        ...state,
        modifiedAt: new Date().toISOString()
      }

    case 'SET_LOADED':
      return {
        ...state,
        loaded: action.payload
      }

    case 'SET_FIELDS':
      const parents = action.payload.filter((f: any) => f.parentId === 0);
      return {
        ...state,
        fields: orderBy(orderBy(action.payload.map((f: any) => ({ ...f, parentKey: parents.find((p: any) => p.id === f.parentId)?.key })), 'title', 'asc'), 'applicationGroup', 'asc'),
        services: action.payload.reduce((p: any, n: any) => {
          if ((n.key || '').trim().length > 0) {
            p[n.key] = n.title;
          }
          return p;
        }, {})
      }

    case 'SET_TEMPLATES':
      return {
        ...state,
        templates: sortBy(action.payload, 'name', 'asc')
      }

    case 'SET_COMPANIES':
      return {
        ...state,
        companies: action.payload
      }

    case 'SET_NOTIFICATION_COUNT':
      return {
        ...state,
        notificationCount: action.payload
      }

    case 'SET_USERS':
      const sortedUsersSingle: any = [];
      ['franchise', 'client', 'worker', 'manager', 'admin', 'cleaner'].forEach((role: any) => {
        sortedUsersSingle.push(sortBy(action.payload.filter((u: any) => u.userlevel === role), 'operatorName'));
      });

      return {
        ...state,
        users: flatten(sortedUsersSingle)
          .filter((u: any) => u.deletedAt === null)
          .map((u: any) => ({
            ...u,
            value: u.id,
            label: u.operatorName || u.username,
            role: getRoleName(u)
          }))
      }

    case 'SET_PROFILE':
      return {
        ...state,
        profile: action.payload
      }

    case 'SET_TIMEZONE':
      return {
        ...state,
        timeZone: action.payload
      }
      
    case 'SET_MULTIPLE':
      const sortedUsers: any = [];
      ['franchise', 'client', 'worker', 'manager', 'admin', 'cleaner'].forEach((role: any) => {
        sortedUsers.push(sortBy(action.users.filter((u: any) => u.userlevel === role), 'operatorName'));
      });

      return {
        ...state,
        fields: orderBy(orderBy(action.fields, 'title', 'asc'), 'applicationGroup', 'asc'),
        services: action.fields.reduce((p: any, n: any) => {
          if ((n.key || '').trim().length > 0) {
            p[n.key] = n.title;
          }
          return p;
        }, {}),
        templates: sortBy(action.templates, 'name', 'asc'),
        notificationCount: action.notificationCount,
        users: flatten(sortedUsers)
          .filter((u: any) => u.deletedAt === null)
          .map((u: any) => ({
            ...u,
            value: u.id,
            label: u.operatorName || u.username,
            role: getRoleName(u)
          })),
        profile: action.profile,
        companies: action.companies,
        loaded: action.loaded
      }

    default:
      return state;
  }
};

export default reducer;
