import { useState, Fragment } from 'react';
import {
  CheckPicker,
  InputGroup,
  Input,
  toaster,
  Message,
  DatePicker,
  Whisper,
  Tooltip,
  SelectPicker,
} from 'rsuite';
import { ROLE, parseNumber, FORMAT } from 'lib/env';
import { startCase, without } from 'lodash';
import { IS_ASSINIBOINE, IS_BUGABOO, IS_KALADI_KITCHENS, moneyFormatter } from 'lib/tenant';
import { CONFIG_STATUS } from 'tenant/config';
import { useViewport } from 'shared/ViewportProvider';
import { filterFieldsByParentKey } from 'lib/helpers/field';
import { usePrairieAuth } from 'contexts/AuthPrairieProvider';
import { InputNumber } from 'components/form';
import { format, parseISO } from 'date-fns';
import { useCompany } from 'lib/hooks';
import { toTimeZone } from 'lib/date';
import INTL from 'tenant/intl';
import store from 'lib/storage';

const getData = (workTypes: any, services: [string], otherServices: string, defaultSiteChecks: any, applicationGroup: string, completedServices: Array<string>) => {
  let data: any = [];

  if (applicationGroup !== 'snow_removal') {
    data = data.concat([
      {
        value: 'completed',
        label: 'Completed',
        role: 'Status',
        group: 'completions'
      },
      {
        value: 'not_completed',
        label: 'Not Completed',
        role: 'Status',
        group: 'completions'
      },
      {
        value: 'canceled',
        label: 'Canceled',
        role: 'Status',
        group: 'completions'
      }
    ]);
  }

  return data;
}

const getCostLabel = (value: string) => {
  // switch (value) {
  //   case 'per_hour':
  //     return 'Per Hour';
  //   default:
  //     return startCase(value);
  // }
  return 'Per Visit';
}

let timeout: NodeJS.Timeout;

interface IJobStatusSimple {
  services: string[],
  deIcer: string,
  handleJobUpdate: (guid: string, data: any) => void,
  handleJobDoneUpdate: (guid: string) => void,
  options: any,
  jobGuid: string,
  applicationGroup: string,
  defaultSiteChecks: any,
  siteChecks: any,
  status: string,
  completedServices: string[],
  job: any
};

const JobStatusSimple = ({
  deIcer,
  handleJobUpdate,
  handleJobDoneUpdate,
  jobGuid,
  applicationGroup,
  defaultSiteChecks,
  siteChecks,
  status,
  completedServices,
  job
}: IJobStatusSimple) => {
  const company = useCompany();
  const { isRole } = usePrairieAuth();
  const { state } = useViewport();
  const [deIcerVal, setDeIcerVal] = useState(job.props.deIcer || 0);
  const [gravelAmount, setGravelAmount] = useState(job.props?.gravelAmount || 0);
  const [gravelBins, setGravelBins] = useState(job.props?.gravelBins || 0);
  const [numberOfTrees, setNumberOfTrees] = useState(job.customFields?.numberOfTrees || 0);
  const [numberOfShurbs, setNumberOfShrubs] = useState(job.customFields?.numberOfShrubs || 0);
  const [costPerTypeCost, setCostPerTypeCost] = useState<any>(job.perCostTypeCost || job.perCostTypeCost === 0 ? job.perCostTypeCost : job.workOrderPerCostTypeCost);
  const defaultWorkTypes = filterFieldsByParentKey(state.fields, 'work_types');
  const jobsColumns = store.session.get('jobsColumns', []);

  const handleDeIcerUpdate = (val: string) => {
    setDeIcerVal(val.replace(/[^0-9.]+/g, ''));
    if (timeout) {
      clearTimeout(timeout);
    }

    timeout = setTimeout(() => {
      handleJobUpdate(jobGuid, { applicationGroup, deIcerValue: isNaN(parseFloat(val)) ? 0 : parseFloat(val) })
    }, 500);
  };

  const onGravelAmountUpdate = (val: string) => {
    setGravelAmount(val.replace(/[^0-9.]+/g, ''));
    if (timeout) {
      clearTimeout(timeout);
    }

    timeout = setTimeout(() => {
      handleJobUpdate(jobGuid, { applicationGroup, gravelAmount: isNaN(parseFloat(val)) ? 0 : parseFloat(val) })
    }, 500);
  };

  const onNumberOfTreesUpdate = (val: string) => {
    setNumberOfTrees(val.replace(/[^0-9.]+/g, ''));
    if (timeout) {
      clearTimeout(timeout);
    }

    timeout = setTimeout(() => {
      handleJobUpdate(jobGuid, { applicationGroup, numberOfTrees: isNaN(parseFloat(val)) ? 0 : parseFloat(val) })
    }, 500);
  };

  const onNumberOfShrubsUpdate = (val: string) => {
    setNumberOfShrubs(val.replace(/[^0-9.]+/g, ''));
    if (timeout) {
      clearTimeout(timeout);
    }

    timeout = setTimeout(() => {
      handleJobUpdate(jobGuid, { applicationGroup, numberOfShrubs: isNaN(parseFloat(val)) ? 0 : parseFloat(val) })
    }, 500);
  };

  const onGravelBinsUpdate = (val: string) => {
    setGravelBins(val.replace(/[^0-9.]+/g, ''));
    if (timeout) {
      clearTimeout(timeout);
    }

    timeout = setTimeout(() => {
      handleJobUpdate(jobGuid, { applicationGroup, gravelBins: isNaN(parseFloat(val)) ? 0 : parseFloat(val) })
    }, 500);
  };

  const handleCostUpdate = (val: number) => {
    setCostPerTypeCost(val);
    if (timeout) {
      clearTimeout(timeout);
    }

    timeout = setTimeout(() => {
      handleJobUpdate(jobGuid, {
        perCostTypeCost: val,
        group: 'maintenance_value'
      })
    }, 500);
  };

  return (
    <div>
      <SelectPicker
        size="sm"
        block
        searchable={false}
        data={(state.fields || []).filter((f: any) => f.parentKey === 'job_statuses')}
        labelKey='title'
        valueKey='key'
        value={job.status}
        renderValue={(label: any, item: any) => 
          <span style={{ color: (state.fields || []).find((f: any) => f.key === job.status)?.color }}>{(state.fields || []).find((f: any) => f.key === job.status)?.title}</span>}
        renderMenuItem={(label: any, item: any) => <span style={{ color: item.color }}>{label}</span>}
        onChange={(status: string) => {
          handleJobUpdate(jobGuid, { applicationGroup, status });
        }}
      />
    </div>
  );
};

export default JobStatusSimple;
