import { FORMAT } from 'lib/env';
import { Whisper, Tooltip } from 'rsuite';
import startCase from 'lodash/startCase';
import { format } from 'date-fns';
import { parseISO } from 'lib/date';

interface IDateRangeDisplay {
  handleClick: () => void,
  date: string,
  jobs: Array<any>
}

const DateRangeDisplay = ({
  handleClick,
  date,
  jobs
}: IDateRangeDisplay) => {
  return <Whisper placement="top" trigger="hover" speaker={<Tooltip>
    <div>
      {jobs.filter((j: any) => j.status === 'completed').map((j: any) => 
        <div key={j.guid}>{format(parseISO(j.startDate), FORMAT.MONTH_DATE)} - {startCase(j.status)}{j.completedByCrewName ? ` - ${j.completedByCrewName.substr(0, 4)}...` : ''}
      </div>)}Click to see all
    </div>
    </Tooltip>}>
    <a
      href="/"
      onClick={(e) => {
        e.preventDefault();
        handleClick();
      }}
      style={{ cursor: 'pointer' }}
    >
      {date && format(parseISO(date), FORMAT.MONTH_DATE)}
    </a>
  </Whisper>
}

export default DateRangeDisplay;
