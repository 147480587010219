import { gql, useApolloClient } from '@apollo/client';
import { parseISO } from 'date-fns';
import format from 'date-fns/format';
import { FORMAT, getEnv } from 'lib/env';
import { useQueryString } from 'lib/hooks';
import { startCase } from 'lodash';
import { useEffect, useState } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import {
  Col,
  Form,
  Grid,
  Loader,
  Panel,
  Row,
  DOMHelper,
  Breadcrumb,
} from 'rsuite';
import { useViewport } from 'shared/ViewportProvider';
import { moneyFormatter } from 'lib/tenant';

const GET_CASH_FLOW = gql`
  query cashFlows($limit: Int!, $offset: Int!, $where: CashFlowsWhere) {
    cashFlows(filter: {
      limit: $limit,
      offset: $offset,
      where: $where
    }) {
      edges {
        node {
          id
          guid
          userId
          totalCashIn
          totalCashOutBusiness
          totalCashOutPersonal
          netCash
          cashIn
          cashOutBusiness
          dateOnCalendar
          cashBeginning
          cashEnd
          operatorName
        }
      },
      totalCount
    }
  }
`;

interface ICashFlowView {
  drawer?: boolean,
  show?: boolean,
  guid?: any,
  action?: any,
  userId?: number,
  date?: Date,
  onHide?: () => void,
  onUpdate?: (data: any) => void
}

const CashFlowView = ({ guid, action, drawer }: ICashFlowView) => {
  const match = useRouteMatch();
  const query = useQueryString();
  const client = useApolloClient();
  const { state } = useViewport();
  const [loading, setLoading] = useState(false);
  const [cashFlows, setCashFlows] = useState<any>([]);
  const colDescriptionWidth = 3;
  const colAmountWidth = 2;
  guid = query?.guid || (match?.params as any)?.guid || guid;
  action = query?.action || action || (match?.params as any)?.action || 'compare';

  useEffect(() => {
    (async function getData() {
      try {
        setLoading(true);
        const whereFilter: any = { AND: [] };
        if (guid) {
          whereFilter.AND.push({ guid: { in: guid.split('_') } });
        }

        const data = await client.query({
          query: GET_CASH_FLOW,
          variables: {
            offset: 0,
            limit: 100,
            where: whereFilter
          },
          fetchPolicy: 'no-cache'
        });

        setCashFlows(data.data.cashFlows.edges.node);
        DOMHelper.scrollTop(window as any, 0);
      } catch (err) {
        console.log(err);
      }
      setLoading(false);
    })();
  }, [client, guid, action, state.profile.id]);


  const getLongestArray = (key: string) => {
    const array = cashFlows.map((c: any) => (c[key] || []).length);
    return Math.max(...(array.length > 0 ? array : [1]));
  }

  console.log(cashFlows);

  return <div>
    {!drawer &&
      <Breadcrumb separator=" | ">
        <Breadcrumb.Item>
          <Link to={`/app/${getEnv()}/financials/cash-flow`}>Cash Flow</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          {startCase(action)}
        </Breadcrumb.Item>
      </Breadcrumb>
    }

    <Panel className="content">
      {loading
        ? <Loader content="Loading..." />
        : <Form>
          <fieldset>
            <legend>Date</legend>
            <Grid fluid className='p-0'>
              <Row className="mb-5">
                <Col md={colDescriptionWidth} className='text-right font-medium'>Crew:</Col>
                {cashFlows.map((c: any, index: number) => <>
                  {index > 0 && <Col md={colDescriptionWidth}>&nbsp;</Col>}
                  <Col md={colAmountWidth} className='font-medium'>{c.operatorName}</Col>
                </>
                )}
              </Row>
              <Row className={'mb-5'}>
                <Col md={colDescriptionWidth} className='text-right font-medium'><label>Month:</label></Col>
                {cashFlows.map((c: any, index: number) => <>
                  {index > 0 && <Col md={colDescriptionWidth}>&nbsp;</Col>}
                  <Col md={colAmountWidth} className='font-medium'>{format(parseISO(c.dateOnCalendar), FORMAT.MONTH_DATE)}</Col>
                </>
                )}
              </Row>
            </Grid>
          </fieldset>

          <fieldset>
            <legend>Business Bank Account Cash Flow</legend>
            <Grid fluid className='p-0'>
              <Row className={'mb-5'}>
                <Col md={colDescriptionWidth} className='text-right font-medium'>
                  Balance at Beginning of Period:
                </Col>
                {cashFlows.map((c: any, index: number) => <>
                  {index > 0 && <Col md={colDescriptionWidth}>&nbsp;</Col>}
                  <Col md={colAmountWidth} className='font-medium'>{moneyFormatter.format(c.cashBeginning)}</Col>
                </>
                )}
              </Row>

              <Row>
                <Col md={colDescriptionWidth} className='text-right font-medium'>
                  Balance at End of Period:
                </Col>
                {cashFlows.map((c: any, index: number) => <>
                  {index > 0 && <Col md={colDescriptionWidth}>&nbsp;</Col>}
                  <Col md={colAmountWidth} className='font-medium'>{moneyFormatter.format(c.cashEnd)}</Col>
                </>
                )}
              </Row>
            </Grid>
          </fieldset>

          <fieldset>
            <legend>Total Cash In</legend>
            <Grid fluid className='p-0'>
              <Row className={'mb-5'}>
                {cashFlows.map(() => <>
                  <Col md={colDescriptionWidth}>Description</Col>
                  <Col md={colAmountWidth}>Amount</Col>
                </>)}
              </Row>
              {[...Array(getLongestArray('cashIn')).keys()].map((k: any) =>
                <Row className={'mb-5'}>
                  {cashFlows.map((c: any) =>
                    <>
                      <Col md={colDescriptionWidth}>{c.cashIn[k]?.description}</Col>
                      <Col md={colAmountWidth}>{c.cashIn[k]?.amount ? moneyFormatter.format(c.cashIn[k]?.amount.replace(/,/g, '')) : ''}</Col>
                    </>
                  )}
                </Row>
              )}

              <Row className={'mt-5'}>
                <Col md={colDescriptionWidth} className='text-right font-medium'>
                  Total Amount:
                </Col>
                {cashFlows.map((c: any, index: number) => <>
                  {index > 0 && <Col md={colDescriptionWidth}>&nbsp;</Col>}
                  <Col md={colAmountWidth} className='font-medium'>{moneyFormatter.format(c.totalCashIn)}</Col>
                </>)}
              </Row>
            </Grid>
          </fieldset>

          <fieldset>
            <legend>Total Cash Out</legend>
            <Grid fluid>
              <Row className={'mb-5'}>
                {cashFlows.map(() => <>
                  <Col md={colDescriptionWidth}>Business Related</Col>
                  <Col md={colAmountWidth}>Amount</Col>
                </>)}
              </Row>

              {[...Array(getLongestArray('cashOutBusiness')).keys()].map((k: any) =>
                <Row className={'mb-5'}>
                  {cashFlows.map((c: any) =>
                    <>
                      <Col md={colDescriptionWidth}>{c.cashOutBusiness[k]?.description}</Col>
                      <Col md={colAmountWidth}>{c.cashOutBusiness[k]?.amount ? moneyFormatter.format(c.cashOutBusiness[k]?.amount.replace(/,/g, '')) : ''}</Col>
                    </>
                  )}
                </Row>
              )}

              <Row className={'mt-5'}>
                <Col md={colDescriptionWidth} className='text-right font-medium'>
                  Total Business Related Amount:
                </Col>
                {cashFlows.map((c: any, index: number) => <>
                  {index > 0 && <Col md={colDescriptionWidth}>&nbsp;</Col>}
                  <Col md={colAmountWidth} className='font-medium'>{moneyFormatter.format(c.totalCashOutBusiness)}</Col>
                </>)}
              </Row>

              <Row className={'mt-5'}>
                <Col md={colDescriptionWidth} className='text-right font-medium'>
                  Total Personal/Non-Business Related Amount:
                </Col>
                {cashFlows.map((c: any, index: number) => <>
                  {index > 0 && <Col md={colDescriptionWidth}>&nbsp;</Col>}
                  <Col md={colAmountWidth} className='font-medium'>{moneyFormatter.format(c.totalCashOutPersonal)}</Col>
                </>)}
              </Row>
            </Grid>
          </fieldset>

          <fieldset>
            <legend>Net Cash</legend>
            <Grid fluid>
              <Row className={'mb-5'}>
                <Col md={colDescriptionWidth} className='text-right font-medium'>
                  Cash Difference:
                </Col>
                {cashFlows.map((c: any, index: number) => <>
                  {index > 0 && <Col md={colDescriptionWidth}>&nbsp;</Col>}
                  <Col md={colAmountWidth} className='font-medium'>{moneyFormatter.format(c.netCash)}</Col>
                </>)}
              </Row>
            </Grid>
          </fieldset>
        </Form>
      }
    </Panel>
  </div>
}

export default CashFlowView;
