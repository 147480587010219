import { gql, useApolloClient } from "@apollo/client";
import { format } from "date-fns";
import { useApi } from "lib";
import { FORMAT } from "lib/env";
import { useState } from "react";
import { isMobileOnly } from "react-device-detect"
import { Button, ButtonToolbar, DateRangePicker, Drawer } from "rsuite"
import { v4 as uuidv4 } from 'uuid';

interface IAccountStatement {
  onHide: () => void,
  customerGuid: string | undefined
}

const AccountStatement = ({
  onHide,
  customerGuid
}: IAccountStatement) => {
  const client = useApolloClient();
  const api = useApi();
  const [loading, setLoading] = useState(false);
  const [range, setRange] = useState<any>([new Date(), new Date()]);

  const handleSubmit = async (customerGuid: any, range: any) => {
    setLoading(true);

    const res = await client.query({
      query: gql`
      query export($guid: String!, $name: String!, $params: JSON) {
        export(guid: $guid, name: $name, params: $params) {
          code,
          message,
          guid
        }
      }
    `,
      variables: {
        guid: uuidv4(),
        name: 'invoiceAccountStatement',
        params: {
          customerGuid,
          range
        }
      },
      fetchPolicy: 'no-cache'
    });

    setLoading(false);
    const uri = await api.download(`/attachments/exports/download/${res.data.export.guid}/account_statement/pdf`);
    window.location.href = uri;
  }

  return (
    <Drawer backdrop='static' open onClose={onHide} size={'xs'} full={isMobileOnly}>
      <Drawer.Header>
        <Drawer.Title>Account Statement</Drawer.Title>
      </Drawer.Header>
      <Drawer.Body>
        <p className="mb-4">Select date range to export account statement for.</p>
        <DateRangePicker
          block
          showOneCalendar
          cleanable={false}
          value={range}
          renderValue={(val: any) => format(val[0], FORMAT.DAY_MONTH_DATE) + ' ~ ' + format(val[1], FORMAT.DAY_MONTH_DATE)}
          onChange={setRange}
        />
      </Drawer.Body>
      <Drawer.Footer>
        <ButtonToolbar>
          <Button
            size="sm"
            onClick={() => handleSubmit(customerGuid, range)}
            appearance="primary"
            loading={loading}
            disabled={loading}
          >Download</Button>
          <Button onClick={onHide} disabled={loading} appearance="subtle" size="sm">Close</Button>
        </ButtonToolbar>
      </Drawer.Footer>
    </Drawer>
  );
}

export default AccountStatement;
