import { DRAWER, ROLE, getEnv } from 'lib/env';
import { Fragment, useContext, useEffect, useState } from 'react';
import { isBrowser, isMobileOnly } from 'react-device-detect';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { Toggle, Tooltip, IconButton, Whisper, SelectPicker } from 'rsuite';
import { ApplicationContext } from 'shared';
import { defaultColumns, extraColumns } from 'drawers/Table';
import store from 'lib/storage';
import { IS_KALADI_KITCHENS } from 'lib/tenant';
import { MdCloudDownload, MdViewColumn } from 'react-icons/md';
import { usePrairieAuth } from 'contexts/AuthPrairieProvider';

const MapToggleField = () => {
  const history = useHistory();
  const location = useLocation();

  return <Fragment>
    {isMobileOnly
      ? <div style={{ padding: '4px' }}>
        <SelectPicker
          block
          cleanable={false}
          searchable={false}
          data={[{ label: 'Map', value: 'map' }, { label: 'List', value: 'list' }]}
          defaultValue={location.pathname.indexOf('/map') > -1 ? 'map' : 'list'}
          onChange={(val: any) => {
            if (location.pathname.indexOf('/work-orders') > -1) {
              if (location.pathname.indexOf('/map') === -1) {
                history.push(`/app/${getEnv()}/jobs/work-orders/map`);
              } else {
                history.push(`/app/${getEnv()}/jobs/work-orders/list`);
              }
            } else if (location.pathname.indexOf('/jobs') > -1) {
              if (location.pathname.indexOf('/map') === -1) {
                history.push(`/app/${getEnv()}/jobs/map`);
              } else {
                history.push(`/app/${getEnv()}/jobs/list`);
              }
            } else if (location.pathname.indexOf('/documents') > -1) {
              if (location.pathname.indexOf('/map') === -1) {
                history.push(`/app/${getEnv()}/documents/map`);
              } else {
                history.push(`/app/${getEnv()}/documents/list`);
              }
            }
          }}
        />
      </div>
      : <Toggle
        checked={location.pathname.indexOf('/map') > -1}
        checkedChildren="List"
        unCheckedChildren="Map"
        onChange={() => {
          if (location.pathname.indexOf('/work-orders') > -1) {
            if (location.pathname.indexOf('/map') === -1) {
              history.push(`/app/${getEnv()}/jobs/work-orders/map`);
            } else {
              history.push(`/app/${getEnv()}/jobs/work-orders/list`);
            }
          } else if (location.pathname.indexOf('/jobs') > -1) {
            if (location.pathname.indexOf('/map') === -1) {
              history.push(`/app/${getEnv()}/jobs/map`);
            } else {
              history.push(`/app/${getEnv()}/jobs/list`);
            }
          } else if (location.pathname.indexOf('/documents') > -1) {
            if (location.pathname.indexOf('/map') === -1) {
              history.push(`/app/${getEnv()}/workbook/documents/map`);
            } else {
              history.push(`/app/${getEnv()}/workbook/documents/list`);
            }
          }
        }}
      />
    }
  </Fragment>
}

const DetailsToggleField = () => {
  const history = useHistory();
  const location = useLocation();

  return <Fragment>
    {isMobileOnly
      ? <div style={{ padding: '4px' }}>
        <SelectPicker
          block
          cleanable={false}
          searchable={false}
          data={[{ label: 'Map', value: 'map' }, { label: 'List', value: 'list' }]}
          defaultValue={location.pathname.indexOf('/map') > -1 ? 'map' : 'list'}
          onChange={(val: any) => {
            if (location.pathname.indexOf('/work-orders') > -1) {
              if (location.pathname.indexOf('/map') === -1) {
                history.push(`/app/${getEnv()}/jobs/work-orders/map`);
              } else {
                history.push(`/app/${getEnv()}/jobs/work-orders/list`);
              }
            } else if (location.pathname.indexOf('/jobs') > -1) {
              if (location.pathname.indexOf('/map') === -1) {
                history.push(`/app/${getEnv()}/jobs/map`);
              } else {
                history.push(`/app/${getEnv()}/jobs/list`);
              }
            } else if (location.pathname.indexOf('/documents') > -1) {
              if (location.pathname.indexOf('/map') === -1) {
                history.push(`/app/${getEnv()}/documents/map`);
              } else {
                history.push(`/app/${getEnv()}/documents/list`);
              }
            }
          }}
        />
      </div>
      : <Toggle
        checked={location.pathname.indexOf('/details') > -1}
        checkedChildren="List"
        unCheckedChildren="Details"
        onChange={() => {
          if (location.pathname.indexOf('/invoices') > -1) {
            if (location.pathname.indexOf('/details') === -1) {
              history.push(`/app/${getEnv()}/financials/invoices/details`);
            } else {
              history.push(`/app/${getEnv()}/financials/invoices/list`);
            }
          }
        }}
      />
    }
  </Fragment>
}

interface IExportField {
  columns?: any
  extraParams?: any,
  extraFilter? : any
}

const ExportField = ({ columns, extraParams, extraFilter }: IExportField) => {
  const { showDrawer } = useContext(ApplicationContext);
  const [exportGroup, setExportGroup] = useState<string | undefined>(undefined);
  const [exportColumns, setExportColumns] = useState<any>([]);
  const [selectColumns, setSelectedColumns] = useState<any>([]);

  useEffect(() => {
    let group = 'default';

    // group has to match the filter key defined in the list
    if (window.location.pathname.indexOf('/work-orders') > -1) {
      group = 'workOrders';
    } else if (window.location.pathname.indexOf('/jobs') > -1) {
      group = 'jobs';
    } else if (window.location.pathname.indexOf('/documents') > -1) {
      group = 'documents';
    } else if (window.location.pathname.indexOf('/pnls') > -1) {
      group = 'pnls';
    } else if (window.location.pathname.indexOf('/invoices') > -1) {
      group = 'invoices';
    } else if (window.location.pathname.indexOf('/customers') > -1) {
      group = 'customers';
    } else if (window.location.pathname.indexOf('/resourcing/payroll') > -1) {
      group = 'employeesPayroll';
    } else if (window.location.pathname.indexOf('/financials/cash-flow') > -1) {
      group = 'cashFlow';
    }

    const visibleColumns = store.get(group + 'Columns', defaultColumns[group]);

    setExportGroup(group);

    if (IS_KALADI_KITCHENS && group === 'jobs') {
      setExportColumns([]);
    } else {
      setExportColumns((columns || (defaultColumns.hasOwnProperty(group) ? defaultColumns[group] : [])).concat(extraColumns.hasOwnProperty(group) ? extraColumns[group] : []));
    }
    setSelectedColumns(visibleColumns);
  }, []);

  return (
    <Fragment>
      {isBrowser &&
        <Whisper placement="bottom" trigger="hover" speaker={<Tooltip>Export</Tooltip>}>
          <IconButton
            onClick={() => showDrawer(DRAWER.EXPORT, {
              group: exportGroup,
              paramsId: exportGroup,
              columns: exportColumns,
              selectColumns: selectColumns,
              extraParams,
              extraFilter
            })}
            loading={false}
            appearance="link"
            icon={<MdCloudDownload size={'14px'} />}
          />
        </Whisper>
      }
    </Fragment>
  );
}

interface IColumnChooserField {
  filterKey: string
}

const ColumnChooserField = ({ filterKey }: IColumnChooserField) => {
  const { isRole } = usePrairieAuth();
  const { showDrawer } = useContext(ApplicationContext);

  return <>
    <Whisper placement="bottom" trigger="hover" speaker={<Tooltip>Columns</Tooltip>}>
      <IconButton
        size="sm"
        onClick={() => showDrawer(DRAWER.COLUMN_CHOOSER, {
          storageKey: filterKey
        })}
        appearance="link"
        icon={<MdViewColumn size={'14px'} />}
      />
    </Whisper>
  </>
}

export {
  ExportField,
  MapToggleField,
  ColumnChooserField,
  DetailsToggleField
}
