import { gql } from '@apollo/client';
import { useApi } from 'lib';
import { deviceDetect } from 'react-device-detect';
import { mapStackTrace } from 'sourcemapped-stacktrace';

const dumpLocalStorage = () => {
  let store = []
  for (let i = 0, l = localStorage.length; i < l; i++) {
    let key: any = localStorage.key(i);
    store.push({ key: key, value: localStorage.getItem(key) });
  }
  return JSON.stringify(store);
}

const useLogger = () => {
  const api = useApi();

  return {
    error: async (data: any, err: Error | undefined = undefined) => {
      if ((import.meta as any).env?.VITE_APP_ENV === 'production' && err?.stack) {
        const mappedStack: any = await new Promise((resolve, reject) => {
          mapStackTrace(err?.stack, (mappedStack: any) => {
            resolve(mappedStack.join("\n"));
          });
        });

        const input: any = {
          ...data,
          url: window.location.href,
          storage: dumpLocalStorage(),
          navigator: JSON.stringify(deviceDetect)
        };

        if (mappedStack) {
          input.notes = mappedStack.message ? (mappedStack.message + ': ' + mappedStack.stack) : mappedStack.toString();
        }

        const result = await api.post('/dev/logger/error', input)
      } else {
        if (err) {
          console.log(err);
        }
      }
    }
  }
}

const tracker = async (client: any, resource: string, guid: string) => {
  const trackResource = gql`
    mutation trackResource(
      $resource: String!
      $guid: ID!
    ) {
      trackResource(
        resource: $resource
        guid: $guid
      ) {
        success
        code
        message
      }
    }
  `;

  await client.mutate({
    mutation: trackResource,
    variables: { resource, guid }
  });
}

export {
  useLogger,
  tracker
};
