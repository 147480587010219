const getCustomFields = (form: any) => {
  return Object.keys(form).reduce((p: any, n: any) => {
    if (n.includes('customField_')) {
      p[n.replace('customField_', '')] = form[n];
    }

    return p;
  }, {})
}

const setCustomFields = (data: any) => {
  const customFields: any = {};

  Object.keys(data).map((cf: string) => {
    customFields['customField_' + cf] = data?.[cf] || '';
  });

  return customFields;
}

export {
  getCustomFields,
  setCustomFields
}