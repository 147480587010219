import {
  DateRangePicker,
  IconButton,
  Grid,
  Row,
  Col,
  Dropdown,
  Whisper,
  Popover,
  Button
} from 'rsuite';

import { useState, SetStateAction, Dispatch, useEffect, createRef, Fragment, useContext, useRef } from 'react';
import { addDays, startOfDay, endOfDay, subDays, startOfMonth, endOfMonth, format, getDaysInMonth } from 'date-fns';
import { isMobileOnly, MobileView } from 'react-device-detect';
import { differenceInDays, endOfWeek, startOfWeek } from 'date-fns/esm';
import { DRAWER, FORMAT } from 'lib/env';
import { useFilteredParams } from './FilterProvider';
import { MdChevronLeft, MdChevronRight, MdFilterAlt, MdSettings, MdViewColumn } from 'react-icons/md';
import store from 'lib/storage';
import { ApplicationContext } from 'shared';

interface IDateRangeFilter {
  filterKey?: string | undefined,
  dateRange: [Date?, Date?],
  showOneCalendar: boolean,
  handleDateRangeChange: Dispatch<SetStateAction<any>> | (() => any),
  handleDateRangeColumnChange: Dispatch<SetStateAction<any>> | (() => any),
  handleFilterToggle?: Dispatch<SetStateAction<boolean | any>> | (() => any),
  handleDateRangeClean?: () => void,
  showFilter?: boolean,
  isMobileOnly?: boolean,
  cleanable?: boolean | undefined,
  columns?: Array<{ [label: string]: string }> | undefined,
  rangeColumn?: string | undefined
  appearance?: 'default' | 'subtle' | undefined,
  placement?: 'bottomEnd' | undefined,
}

let timeout: NodeJS.Timeout;

const DateRangeFilter = ({
  filterKey,
  dateRange,
  handleDateRangeChange,
  handleDateRangeColumnChange,
  showOneCalendar,
  handleFilterToggle,
  handleDateRangeClean,
  showFilter,
  cleanable,
  columns,
  rangeColumn,
  appearance,
  placement
}: IDateRangeFilter) => {
  const { showDrawer } = useContext(ApplicationContext);
  const { params } = useFilteredParams(window.location.pathname.indexOf('jobs/calendar') > -1 ? 'jobCalendar' : 'jobs');
  const [currentRange, setCurrentRange] = useState<[Date?, Date?]>(dateRange);
  const [selectedColumn, setSelectedColumn] = useState<{ [label: string]: string }>(columns ? columns[0] : { createdAt: 'Created At' });
  const triggerRef:any = useRef();

  useEffect(() => {
    setCurrentRange(dateRange);

    if (columns !== undefined && rangeColumn !== undefined) {
      const col = columns.find((c: any) => Object.keys(c)[0] === rangeColumn);
      if (col) {
        setSelectedColumn(col);
      }
    } else if (columns !== undefined) {
      setSelectedColumn(columns[0]);
    }
  }, [dateRange]);

  const calculatePrev = (val: [Date?, Date?]) => {
    let calendarRange = store.session.get('jobCalendar-calendarView-filter') || 'custom';
    const from = (val as any)[0];
    const to = (val as any)[1];
    let difference = differenceInDays(to, from);
    let prev = [subDays(from, difference + 1), subDays(to, difference + 1)] as [Date?, Date?];

    if (getDaysInMonth(from) === difference + 1 || getDaysInMonth(from) === difference) {
      calendarRange = 'month';
    }

    if (calendarRange === 'month') {
      prev = [
        startOfMonth(subDays(from, 7)),
        endOfMonth(subDays(from, 7))
      ] as [Date?, Date?];
    } else if (calendarRange === 'week') {
      prev = [
        startOfWeek(subDays(from, 3)),
        endOfWeek(subDays(from, 3))
      ] as [Date?, Date?];
    } else if (calendarRange === 'custom') {
      // check if first and end of month, and switch by that
      if (format(startOfMonth(from), FORMAT.ISO_DATE) === format(from, FORMAT.ISO_DATE)
        && format(new Date(endOfMonth(to)), FORMAT.ISO_DATE) === format(new Date(to), FORMAT.ISO_DATE)) {
        prev = [startOfMonth(subDays(from, difference)), endOfMonth(subDays(from, difference))] as [Date?, Date?];
      }
    }

    setCurrentRange(prev);

    if (timeout) {
      clearTimeout(timeout);
    }

    timeout = setTimeout(() => {
      handleDateRangeChange(prev);
    }, 500);
  }

  const calculateNext = (val: [Date?, Date?]) => {
    let calendarRange = store.session.get('jobCalendar-calendarView-filter') || 'custom';
    const from = (val as any)[0];
    const to = (val as any)[1];
    let difference = differenceInDays(to, from);
    let next = [addDays(from, difference + 1), addDays(to, difference + 1)] as [Date?, Date?];

    if (getDaysInMonth(from) === difference + 1 || getDaysInMonth(from) === difference) {
      calendarRange = 'month';
    }

    if (calendarRange === 'month') {
      next = [
        startOfMonth(addDays(to, 7)),
        endOfMonth(addDays(to, 7))
      ] as [Date?, Date?];
    } else if (calendarRange === 'week') {
      next = [
        startOfWeek(addDays(to, 3)),
        endOfWeek(addDays(to, 3))
      ] as [Date?, Date?];
    } else if (calendarRange === 'custom') {
      // check if first and end of month, and switch by that
      if (format(startOfMonth(from), FORMAT.ISO_DATE) === format(from, FORMAT.ISO_DATE)
        && format(new Date(endOfMonth(to)), FORMAT.ISO_DATE) === format(new Date(to), FORMAT.ISO_DATE)) {
        next = [startOfMonth(addDays(to, difference)), endOfMonth(addDays(to, difference))] as [Date?, Date?];
      }
    }

    setCurrentRange(next);

    if (timeout) {
      clearTimeout(timeout);
    }

    timeout = setTimeout(() => {
      handleDateRangeChange(next);
    }, 500);
  }

  const getRanges = (): any => {
    if (window.location.pathname.indexOf('/jobs/calendar') > -1) {
      if (params.calendarView === 'month' || params.calendarView === 'week') {
        return [];
      }
    }

    return isMobileOnly
      ? [
        {
          label: 'today',
          value: [startOfDay(new Date()), endOfDay(new Date())]
        }
      ]
      : [
        {
          label: 'today',
          value: [startOfDay(new Date()), endOfDay(new Date())]
        },
        {
          label: 'yesterday',
          value: [
            startOfDay(addDays(new Date(), -1)),
            endOfDay(addDays(new Date(), -1))
          ]
        },
        {
          label: 'last7Days',
          value: [startOfDay(subDays(new Date(), 6)), endOfDay(new Date())]
        },
        {
          label: 'This Month',
          value: [startOfMonth(new Date()), endOfMonth(new Date())]
        },
        {
          label: 'Last Month',
          value: [startOfMonth(new Date().setDate(0)), endOfMonth(new Date().setDate(0))]
        }
      ];
  }

  const getOneTap = (): boolean => {
    if (window.location.pathname.indexOf('/jobs/calendar') > -1) {
      if (params.calendarView === 'month' || params.calendarView === 'week') {
        return true;
      }
    }

    return false;
  }

  const getHoverRange = (): 'month' | 'week' | undefined => {
    if (window.location.pathname.indexOf('/jobs/calendar') > -1) {
      if (params.calendarView === 'month') {
        return 'month';
      } else if (params.calendarView === 'week') {
        return 'week';
      }
    }

    return undefined;
  }

  const iconPrev = <IconButton size="sm" appearance="link" onClick={() => calculatePrev(currentRange)} icon={<MdChevronLeft size={'16px'} />} />;
  const iconNext = <IconButton size="sm" appearance="link" onClick={() => calculateNext(currentRange)} icon={<MdChevronRight size={'16px'} />} />;

  const dateRangePicker = <DateRangePicker
    size="sm"
    showOneCalendar={showOneCalendar}
    cleanable={cleanable ? cleanable : false}
    hoverRange={getHoverRange()}
    renderValue={(date: any) => {
      return dateRange.length === 2 ? `${format(date[0], 'EEE, d MMM')} ~ ${format(date[1], 'EEE, d MMM')}` : '';
    }}
    // rsuite5
    value={dateRange as any}
    onChange={handleDateRangeChange}
    onClean={handleDateRangeClean}
    block
    style={{ width: '100%' }}
    ranges={getRanges()}
    oneTap={getOneTap()}
    appearance={appearance}
    placement={placement}
  />;

  return (
    <Grid fluid>
      <Row>
        <Col xs={3} md={3} className="p-0 text-center">
          {iconPrev}
        </Col>
        {(columns && columns.length) &&
          <Col md={5} xs={3} className="p-0 text-center font-base datefilter-range">
            <Whisper
              placement="bottomStart"
              trigger="click"
              // rsuite5
              ref={triggerRef}
              speaker={<Popover>
                <Dropdown.Menu>
                  {columns.map((col: any) =>
                    <Dropdown.Item
                      onSelect={() => {
                        setSelectedColumn(col);
                        handleDateRangeColumnChange(Object.keys(col)[0]);
                        if (triggerRef?.current) {
                          (triggerRef.current as any).close();
                        }
                      }}
                      key={Object.keys(col)[0]}>{col[Object.keys(col)[0]]}
                    </Dropdown.Item>
                  )}
                </Dropdown.Menu>
              </Popover>}
            >
              <Button
                appearance="link"
                className="pl-0 pr-0">
                {isMobileOnly
                  ? <Fragment>
                    <MdSettings />
                  </Fragment>
                  : <Fragment>{selectedColumn[Object.keys(selectedColumn)[0]]}</Fragment>
                }
              </Button>
            </Whisper>
          </Col>
        }
        {isMobileOnly
          ? <Col xs={(columns && columns.length > 0) ? 10 : 13} className="p-0">{dateRangePicker}</Col>
          : <Col xs={15} md={(columns && columns.length > 0) ? 13 : 18} className="p-0">{dateRangePicker}</Col>
        }
        <Col xs={3} md={3} className="p-0 text-center">
          {iconNext}
        </Col>
        <MobileView>
          <Col xs={2} className="text-right">
            <IconButton appearance={showFilter ? 'link' : 'subtle'} onClick={handleFilterToggle}
              icon={<MdFilterAlt />} />
          </Col>
          {filterKey &&
            <Col xs={2} className="text-right">
              <IconButton appearance='subtle'
                onClick={() => showDrawer(DRAWER.COLUMN_CHOOSER, {
                  storageKey: filterKey
                })}
                icon={<MdViewColumn />} />
            </Col>
          }
        </MobileView>
      </Row>
    </Grid>
  );
};

export default DateRangeFilter;
