import { useEffect, useState, useContext } from 'react';
import { useApolloClient, gql } from '@apollo/client';
import {
  Table,
  Drawer,
  Button,
  toaster,
  Message,
  Whisper,
  Tooltip,
  SelectPicker,
} from 'rsuite';
import LegacyTrashIcon from "@rsuite/icons/legacy/Trash";
import { COLOR, FORMAT, ROLE, getEnv } from 'lib/env';
import { orderBy, reverse, round, startCase } from 'lodash';
import { isMobileOnly } from 'react-device-detect';
import { IS_BUGABOO, IS_KALADI_KITCHENS, moneyFormatter } from 'lib/tenant';
import { format, parseISO } from 'date-fns';
import CopyToClipboard from 'react-copy-to-clipboard';
import { ApplicationContext, ResponsiveTable } from 'shared';
import { usePrairieAuth } from 'contexts/AuthPrairieProvider';
import { toTimeZone } from 'lib/date';
import { useCompany } from 'lib/hooks';
import INTL from 'tenant/intl';

const GET_JOBS = gql`
  query jobs($limit: Int!, $where: JobsWhere, $order: [JobsSort]) {
    jobs(filter: {
      limit: $limit,
      where: $where
      order: $order
    }) {
      edges {
        node {
          id
          guid
          startDate
          endDate
          status
          completedAt
          createdAt
          modifiedAt
          timeSpent
          completedByCrewName
          spmh
          completedThisMonth
          workOrder {
            totalCost
          }
          timeCard {
            guid
            timeOnSite
            timeFromSite
            timeSpent
            jobGuid
            peopleOnSite
            userGuid
          }
        }
      },
      totalCount
    }
  }
`;

const { Column, HeaderCell, Cell } = Table;

const JobList = () => {
  const client = useApolloClient();
  const company = useCompany();
  const { showDrawer, showConfirmation, drawerData } = useContext(ApplicationContext);
  const [jobs, setJobs] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const { can, isRole } = usePrairieAuth();

  useEffect(() => {
    if (drawerData.workOrderGuid) {
      setJobs([]);

      (async function getWorkOrders() {
        try {
          setJobs([]);
          setLoading(true);

          const workOrder = await client.query({
            query: gql`
              query workOrder($guid: ID) {
                workOrder(guid: $guid) {
                  guid
                  children {
                    guid
                  }
                }
              }`,
            variables: {
              guid: drawerData.workOrderGuid
            }
          });

          const data = await client.query({
            query: GET_JOBS,
            variables: {
              limit: 500,
              where: {
                OR: [{ workOrderGuid: { is: drawerData.workOrderGuid } }].concat(workOrder.data.workOrder.children.map((c: any) => ({
                  workOrderGuid: { is: c.guid }
                })))
              },
              order: [
                { startDate: "DESC" }
              ]
            },
            fetchPolicy: 'no-cache'
          });

          setJobs(data.data.jobs.edges.node);
        } catch (err) {
          console.log(err);
        }

        setLoading(false)
      })();
    }
  }, [drawerData.workOrderGuid]);

  return (
    <Drawer backdrop='static' open onClose={() => showDrawer(undefined)} full={isMobileOnly} size={'md'}>
      <Drawer.Header>
        <Drawer.Title>Jobs</Drawer.Title>
      </Drawer.Header>
      <Drawer.Body>
        <ResponsiveTable
          loading={loading}
          data={reverse(jobs)}
          wordWrap
          html5
        >
          <Column>
            <HeaderCell>ID</HeaderCell>
            <Cell>
              {(row: any) => <CopyToClipboard text={row.guid} onCopy={() => toaster.push(<Message type="info" showIcon closable>Copied</Message>)}><span>{row.id}</span></CopyToClipboard>}
            </Cell>
          </Column>
          <Column flexGrow={1}>
            <HeaderCell>Date</HeaderCell>
            <Cell>
              {(row: any) => IS_KALADI_KITCHENS
                ? <span>{format(toTimeZone(parseISO(row.startDate), company.timezone), 'EEE, MMM d, yyyy h:mma')} - {format(toTimeZone(parseISO(row.endDate), company.timezone), 'h:mma')}</span>
                : <div>
                  <a href={`/app/${getEnv()}/jobs/list?query=jobGuid:${row.guid}`} target='blank'>
                    {format(toTimeZone(parseISO(row.startDate), company.timezone), FORMAT.DAY_MONTH_DATE)}
                  </a>
                </div>
              }
            </Cell>
          </Column>
          <Column flexGrow={1}>
            <HeaderCell>Status</HeaderCell>
            <Cell>
              {(row: any) => {
                return (
                  <div>
                    {(can('job:update') && !IS_BUGABOO)
                      ? <span>
                        <SelectPicker
                          size="xs"
                          cleanable={false}
                          searchable={false}
                          defaultValue={row.status || 'not_completed'}
                          data={[
                            { label: 'Completed', value: 'completed' },
                            { label: 'Not Completed', value: 'not_completed' },
                            { label: 'Canceled', value: 'canceled' },
                          ]}
                          renderValue={(status: any) => {
                            switch (status) {
                              case 'completed':
                                return startCase(status);
                              case 'not_completed':
                                return startCase(status);
                              case 'canceled':
                                return startCase(status);
                              default:
                                return 'Not Completed';
                            }
                          }}
                          onChange={async (value: any) => {
                            const response = await client.mutate({
                              mutation: gql` mutation updateJob(
                                $guid: ID!
                                $status: String
                              ) {
                                updateJob(
                                  guid: $guid
                                  status: $status
                                ) {
                                  success
                                  code
                                  message
                                }
                              }`,
                              variables: {
                                guid: row.guid,
                                status: value
                              }
                            });

                            if (response.data.updateJob.success) {
                              toaster.push(
                                <Message type="success" showIcon closable>{response.data.updateJob.message}</Message>
                              );
                            } else {
                              toaster.push(
                                <Message type="error" showIcon closable>{response.data.updateJob.message}</Message>
                              );
                            }
                          }}
                        />
                      </span>
                      : <span>
                        {(() => {
                          switch (row?.status) {
                            case 'completed':
                              return <span className="text-success">{startCase(row.status)}</span>;
                            case 'not_completed':
                              return <span className="text-danger">{startCase(row.status)}</span>;
                            case 'canceled':
                              return <span className="text-warning">{startCase(row.status)}</span>;
                            default:
                              return <span>{(!row.status && format(parseISO(row.modifiedAt), "yyyy-MM-dd'T'HH:mm") !== format(parseISO(row.createdAt), "yyyy-MM-dd'T'HH:mm"))
                                ? <Whisper placement="top" speaker={<Tooltip>This job was modified by crew and cannot be automatically deleted anymore. Click on Delete icon to remove this job.</Tooltip>}>
                                  <span className='text-message'>Modified</span>
                                </Whisper>
                                : startCase(row.status)
                              }</span>;
                          }
                        })()}
                      </span>
                    }
                  </div>
                );
              }}
            </Cell>
          </Column>
          <Column flexGrow={1}>
            <HeaderCell>Completed</HeaderCell>
            <Cell>
              {(row: any) => {
                return <span>{row.completedAt === null ? '' : format(toTimeZone(parseISO(row.completedAt), company.timezone), FORMAT.DAY_MONTH_TIME)}</span>;
              }}
            </Cell>
          </Column>
          <Column>
            <HeaderCell>Time (Hrs)</HeaderCell>
            <Cell>{(row: any) => ((row.timeCard.reduce((p: any, n: any) => p += isNaN(n.timeSpent) ? 0 : +n.timeSpent, 0) + (row.timeSpent || 0)) / 60.0).toFixed(2)}</Cell>
          </Column>
          {!IS_KALADI_KITCHENS && 
          <Column>
            <HeaderCell>SPMH</HeaderCell>
            <Cell>{(row: any) => <>
              {row.spmh 
                ? <Whisper placement='bottom' speaker={<Tooltip>{moneyFormatter.format(row.workOrder.totalCost)}/month / {row.completedThisMonth} completed / {round(row.timeSpent / 60, 2)}hrs</Tooltip>}>
                <span className='text-whisper'>{moneyFormatter.format(row.spmh)}</span>
              </Whisper>
                : <span>-</span>
              }
            </>}
            </Cell>
          </Column>
          }
          <Column>
            <HeaderCell>{INTL.CREW}</HeaderCell>
            <Cell>{(row: any) => row.completedByCrewName}</Cell>
          </Column>
          {((can('job:delete') && jobs.length > 1) || isRole(ROLE.MANAGER, ROLE.ADMIN)) &&
            <Column width={50}>
              <HeaderCell>&nbsp;</HeaderCell>
              <Cell className='link-group'>
                {(row: any) =>
                  <Whisper placement='left' speaker={<Tooltip>Delete</Tooltip>}>
                    <a href="/"
                      style={{ color: COLOR.DANGER }}
                      onClick={(e: any) => {
                        e.preventDefault();
                        showConfirmation(
                          <p>Are you sure you want to delete the job for {format(parseISO(row.startDate), FORMAT.DAY_MONTH_DATE)}?</p>,
                          'Delete Job',
                          async () => {
                            const response = await client.mutate({
                              mutation: gql`mutation deleteJob($guid: ID!) { deleteJob(guid: $guid) { success, code, message }}`,
                              variables: { guid: row.guid }
                            });

                            toaster.push(
                              <Message type="success" showIcon closable>{response.data.deleteJob.message}</Message>
                            );
                            setJobs(orderBy(jobs.filter((j: any) => j.guid !== row.guid), 'startDate', 'desc'));
                          }
                        );
                      }}><LegacyTrashIcon /></a>
                  </Whisper>
                }
              </Cell>
            </Column>
          }
        </ResponsiveTable>

      </Drawer.Body>
      <Drawer.Footer>
        <Button onClick={() => showDrawer(undefined)} appearance="subtle" size="sm">Close</Button>
      </Drawer.Footer>
    </Drawer>
  );
}

export {
  JobList
}
