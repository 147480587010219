import { gql, useApolloClient } from '@apollo/client';
import ResponsiveNav from '@rsuite/responsive-nav';
import { CaptureAmount } from 'components/invoices';
import { usePrairieAuth } from 'contexts/AuthPrairieProvider';
import { queryInvoice } from 'gql/invoices';
import { useApi } from 'lib';
import { DRAWER, FORMAT, getEnv, hasMonerisIntegration, hasStripeIntegration } from 'lib/env';
import { flatten, startCase } from 'lodash';
import { Fragment, useContext, useEffect, useState } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Tooltip, Button, ButtonToolbar, Loader, Whisper, Grid, Row, Col, Panel, DOMHelper, Drawer, Dropdown, IconButton } from 'rsuite';
import { ApplicationContext, PopoverDropdownMenu } from 'shared';
import { useViewport } from 'shared/ViewportProvider';
import INTL from 'tenant/intl';
import { format, parseISO } from 'date-fns';
import Icon from '@rsuite/icons/lib/Icon';
import { MdCreditCard, MdEdit, MdEmail, MdOutlineOutbound, MdPerson } from 'react-icons/md';

interface IInvoiceView {
  guid?: String
}

const InvoiceView = ({
  guid
}: IInvoiceView) => {
  const history = useHistory();
  const client = useApolloClient();
  const match = useRouteMatch();
  const external = guid !== undefined;
  const invoiceGuid = guid || (match as any).params.guid;
  const { state } = useViewport();
  const { showError, showDrawer } = useContext(ApplicationContext);
  const [loading, setLoading] = useState(false);
  const [invoice, setInvoice] = useState<any>(null);
  const [lastEmailText, setLastEmailText] = useState('');
  const [captureInvoice, setCaptureInvoice] = useState<undefined | string>(undefined);
  const [activeTab, setActiveTab] = useState<any>('invoice');
  const [invoiceHTML, setInvoiceHTML] = useState<string | undefined>(undefined);
  const [photos, setPhotos] = useState<any>([]);
  const [billingNotes, setBillingNotes] = useState<any>(undefined);
  const [modifiedAt, setModifiedAt] = useState<undefined | string>();
  const api = useApi();
  const { can } = usePrairieAuth();

  const renderPage = async (pageNumber: number, canvas: any, pdf: any) => {
    const page: any = await pdf.getPage(pageNumber);
    const scale = window.devicePixelRatio || 1;
    const viewport = await page.getViewport({ scale });
    canvas.height = viewport.height;
    canvas.width = viewport.width;
    canvas.style.width = external ? '100%' : '50%';
    await page.render({ canvasContext: canvas.getContext('2d'), viewport });
  }

  useEffect(() => {
    (async function getInvoice() {
      if (!external) {
        DOMHelper.scrollTop(window as any, 0);
      }

      try {
        setLoading(true);
        const html = await api.get(`/invoices/download/${invoiceGuid}/html`);
        const data = await client.query({ query: queryInvoice, variables: { guid: invoiceGuid } });
        setInvoice(data.data.invoice);
        setPhotos(flatten(([] as any).concat(data.data.invoice.photos).concat(data.data.invoice.items.map((i: any) => i.photos))));

        const customer = await client.query({
          query: gql`
            query customer($id: Int) {
              customer(id: $id) {
                billing {
                  notes
                  isDefault
                }
              }
            }`,
          variables: { id: data.data.invoice.billingId || data.data.invoice.customerId }
        });

        if (customer.data?.customer?.billing) {
          const billing = customer.data.customer.billing.find((b: any) => b.isDefault);
          if (billing) {
            setBillingNotes(billing.notes);
          }
        }

        const lastEmail = data.data.invoice.lastEmail;
        const lastEmailText = lastEmail
          ? `Emails Sent: ${lastEmail.length} ${lastEmail.length > 0 ? `<br />Last Email: <br />${lastEmail.map((date: any) => format(parseISO(date), FORMAT.DAY_MONTH_TIME24)).reverse().join('<br />')}` : ''}`
          : 'Email';
        setLastEmailText(lastEmailText);
        setInvoiceHTML(html);

        // for (let page = 1; page <= pdf.numPages; page++) {
        //   const canvas: any = document.createElement("canvas");
        //   canvas.style = 'display: block; margin: 0 0 10px 0; border: 1px solid rgba(0, 0, 0, 0.2);';
        //   viewer.appendChild(canvas);
        //   renderPage(page, canvas, pdf);
        // }

      } catch (err) {
        showError(err);
      } finally {
        setLoading(false);
      }
    })();
  }, [match, modifiedAt]);

  return (
    <div>
      {(invoice && !external) &&
        <Fragment>
          <ButtonToolbar className='mb-8'>
            <IconButton size="xs" appearance={"default"} icon={<Icon as={MdPerson} />}
              onClick={() => history.push(`/app/${getEnv()}/workbook/explorer/${invoice.customer.id}/invoices`)}>
              {invoice.customer.displayName}
            </IconButton>
            {can('invoice:update') &&
              <IconButton size="xs" appearance={"default"} icon={<Icon as={MdEdit} />}
                onClick={() => {
                  showDrawer(DRAWER.INVOICE_FORM, {
                    action: 'edit',
                    invoiceGuid: invoice.guid,
                    title: invoice?.customer?.displayName
                  }, () => setModifiedAt(new Date().toISOString()));
                }}>
                Edit
              </IconButton>
            }
            <Whisper placement="bottom" trigger="hover" speaker={<Tooltip><div className="text-left" dangerouslySetInnerHTML={{ __html: lastEmailText }}></div></Tooltip>}>
              <IconButton size="xs" onClick={() => showDrawer(DRAWER.EMAIL_FORM, {
                resources: [invoice],
                resourceGuid: invoice.guid,
                type: 'invoice',
                group: invoice.applicationGroup
              })}
              icon={<Icon as={MdEmail} />}
              >Email ({invoice.lastEmail.length})</IconButton>
            </Whisper>
            {(hasMonerisIntegration(state.companies) || hasStripeIntegration(state.companies)) &&
              <Fragment>
                {invoice.billingProfile === null
                  ? <Whisper placement="left" trigger="hover" speaker={<Tooltip>Missing billing information in profile</Tooltip>}>
                    <IconButton size="xs" disabled icon={<Icon as={MdCreditCard} />}>
                      Capture ({invoice.captures.filter((c: any) => c.response).length})
                    </IconButton>
                  </Whisper>
                  : <IconButton size="xs" onClick={() => setCaptureInvoice(invoice.guid)} icon={<Icon as={MdCreditCard} />}>
                    Capture ({invoice.captures.filter((c: any) => c.response).length})
                  </IconButton>
                }
              </Fragment>
            }

            <PopoverDropdownMenu size="xs" appearance="default" dataKey="id">
              <Dropdown.Item onSelect={() => {
                window.location.href = `${api.baseUrl}/invoices/download/${invoiceGuid}/pdf`
              }}>Download</Dropdown.Item>
              <Dropdown.Item onSelect={() => {
                showDrawer(DRAWER.WORK_ORDER_FORM, { workOrderGuid: invoice.workOrderGuid, action: 'edit' }, () => setModifiedAt(new Date().toISOString()))
              }}>{INTL.WORK_ORDER}</Dropdown.Item>
            </PopoverDropdownMenu>
          </ButtonToolbar>
        </Fragment>
      }

      {invoice ?
        <ResponsiveNav appearance="subtle" activeKey={activeTab} onSelect={setActiveTab} className="mb-8">
          <ResponsiveNav.Item eventKey="invoice">Invoice</ResponsiveNav.Item>
          <ResponsiveNav.Item eventKey="photos">Photos ({photos.length})</ResponsiveNav.Item>
        </ResponsiveNav>
        : <Loader content="Loading..." />
      }

      {(billingNotes || invoice?.publicComments || invoice?.privateComments || invoice?.workOrders[0]?.contractNotes) &&
        <Panel className="content">
          {billingNotes && <div>Billing notes: {billingNotes}</div>}
          {invoice?.publicComments && <div>Public comments: {invoice?.publicComments}</div>}
          {invoice?.privateComments && <div>Private comments: {invoice?.privateComments}</div>}
          {invoice?.workOrders[0]?.contractNotes && <div>Contract notes: {invoice?.workOrders[0].contractNotes}</div>}
          {invoice?.workOrders[0]?.workDescription && <div>Work order notes: {invoice?.workOrders[0].workDescription}</div>}
        </Panel>
      }

      {(activeTab === 'invoice' && invoiceHTML) &&
        <Panel className="content html-viewer">
          <div style={{ width: (external || isMobileOnly) ? '100%' : '50%', display: activeTab === 'invoice' ? 'block' : 'none' }} dangerouslySetInnerHTML={{ __html: invoiceHTML }}></div>
        </Panel>
      }

      {activeTab === 'photos' &&
        <Panel className="content">
          {photos.length === 0
            ? <p>No photos uploaded</p>
            : <Grid fluid>
              <Row>
                {photos.map((p: any) =>
                  <Fragment>
                    {p.photoBefore &&
                      <Col md={24} sm={24}>
                        <a href={p.photoBefore} target="_blank">
                          <img src={p.photoBefore} style={{ backgroundRepeat: 'no-repeat', marginBottom: 0, height: 'auto', width: 'auto', display: 'block' }} />
                          {p.descriptionBefore}
                        </a>
                      </Col>
                    }
                    {p.photoAfter &&
                      <Col md={24} sm={24}>
                        <a href={p.photoAfter} target="_blank">
                          <img src={p.photoAfter} style={{ backgroundRepeat: 'no-repeat', marginBottom: 0, height: 'auto', width: 'auto', display: 'block' }} />
                          {p.descriptionAfter}
                        </a>
                      </Col>
                    }
                  </Fragment>
                )}
              </Row>
            </Grid>
          }
        </Panel>
      }

      {captureInvoice &&
        <CaptureAmount
          invoiceGuid={captureInvoice}
          show
          onHide={() => setCaptureInvoice(undefined)}
          onProcessed={() => setModifiedAt(new Date().toString())}
        />
      }
    </div>
  );
}

interface IInvoiceViewDrawer {
  guid: string | undefined,
  invoice?: any | undefined,
  show: boolean,
  title: string,
  onHide: () => void,
  onCapture?: (guid: string, amount: string) => void
}

const InvoiceViewDrawer = ({ invoice, guid, show, title, onHide, onCapture }: IInvoiceViewDrawer) => {
  const { state } = useViewport();
  const history = useHistory();
  const { showError, showDrawer } = useContext(ApplicationContext);

  return (
    <Drawer open={show} full={isMobileOnly} size={'md'} onClose={onHide}>
      <Drawer.Header>
        <Drawer.Title>{title}</Drawer.Title>
      </Drawer.Header>
      <Drawer.Body>
        {guid && <InvoiceView guid={guid} />}
      </Drawer.Body>
      <Drawer.Footer>
        <ButtonToolbar>
          <Whisper placement="top" trigger="hover" speaker={<Tooltip>View in New Window</Tooltip>}>
            <IconButton target="_blank" appearance="primary"
              onClick={() => {
                history.push(`/app/${getEnv()}/invoice/view/${guid}`);
                showDrawer(undefined);
              }}
              placement="right" size="sm" icon={<Icon as={MdOutlineOutbound} onClick={(e: any) => {
                e.stopPropagation();
                e.preventDefault();
                window.open(`/app/${getEnv()}/invoice/view/${guid}`);
                showDrawer(undefined);
              }} />}>View</IconButton>
          </Whisper>
          {((hasMonerisIntegration(state.companies) || hasStripeIntegration(state.companies)) && onCapture && guid && invoice) &&
            <Fragment>
              {invoice.billingProfile === null
                ? <Whisper placement="top" trigger="hover" speaker={<Tooltip>Missing billing information in profile</Tooltip>}>
                  <Button size="sm" className="rs-btn rs-btn-default rs-btn-disabled">Capture</Button>
                </Whisper>
                : <Button size="sm" onClick={() => onCapture(guid, invoice.total)} appearance="subtle">Capture</Button>
              }
            </Fragment>
          }
          <Button onClick={onHide} appearance="subtle">Close</Button>
        </ButtonToolbar>
      </Drawer.Footer>
    </Drawer>
  );
}

export {
  InvoiceView,
  InvoiceViewDrawer
}
