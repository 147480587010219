import { useState, useEffect } from 'react';
import {
  toaster,
  Message,
  Button,
  Container,
  Content,
  FlexboxGrid,
  Loader,
  Navbar,
  Panel,
} from 'rsuite';
import { Link, Route, Switch, useHistory } from 'react-router-dom';
import AuthResetPasswordForm from './components/AuthResetPasswordForm';
import AuthSignInForm from './components/AuthSignInForm';
import { getEnv, getTenant, setHeaderTitle } from 'lib/env';
import { isMobileOnly } from 'react-device-detect';
import { useApi } from 'lib';
import store from 'lib/storage';
import { IS_APP, IS_KALADI_KITCHENS } from 'lib/tenant';
import { AuthRegisterForm as KaladikitchensRegisterForm } from './components/kaladikitchens/AuthRegisterForm';
import { AuthRegisterForm as AppRegisterForm } from './components/app/AuthRegisterForm';
import { AuthRegisterForm } from './components/app/AuthRegisterForm';
import { usePrairieAuth } from 'contexts/AuthPrairieProvider';
import AuthLogo from './components/AuthLogo';
import { useQueryString } from 'lib/hooks';

const Auth = () => {
  setHeaderTitle('Auth');
  const history = useHistory();
  const api = useApi();
  const query = useQueryString();
  const [processing, setProcessing] = useState(false);
  const [modifiedAt, setModifiedAt] = useState(new Date().toISOString());
  const { token } = usePrairieAuth();
  const [googleLoginProcessed, setGoogleLoginProcessed] = useState(false);

  useEffect(() => {
    if (document.getElementById('scrollwrapper')) {
      (document.getElementById('scrollwrapper') as any).className = 'auth';
    }

    if (IS_KALADI_KITCHENS) {
      document.title = 'CALENDAR';
    }
  }, []);

  useEffect(() => {
    if (query.error) {
      toaster.push(<Message type="error" showIcon closable>{query.error}</Message>);
    }
  }, [query]);


  useEffect(() => {
    if (window.location.pathname === '/auth/google/signin' && !googleLoginProcessed) {
      setGoogleLoginProcessed(true);
      handleSignInWithGoogle(query);
    }
  }, [query, googleLoginProcessed]);

  useEffect(() => {
    if (window.location.pathname === '/auth/google/signup' && !googleLoginProcessed) {
      setGoogleLoginProcessed(true);
      handleSignUpWithGoogle(query);
    }
  }, [query, googleLoginProcessed]);

  useEffect(() => {
    (async function logout() {
      if (window.location.pathname === '/auth/logout') {
        await api.post('/oauth/v2/logout', {});
        store.clearAll();
        store.session.clearAll();
        setModifiedAt(new Date().toISOString());
        window.location.href = '/auth';
      } else if (token) {
        window.location.href = `/app/${getEnv()}/jobs/list`;
      }
    })();
  }, [token]);

  const handleSignInWithGoogle = async (data: any) => {
    setProcessing(true);
    setGoogleLoginProcessed(true);
    const clientId = data?.state ? data?.state.replace('client_id=', '') : getTenant().id;

    const response: any = await api.post('/oauth/v2/signin', {
      provider: 'google',
      client_id: clientId,
      code: data.code
    });

    if (response.access_token) {
      window.location.href = `https://${response.client_id[0]}.prairiehq.${getEnv() === 'dev' ? 'net' : 'com'}/auth?access_token=${response.access_token}&refresh_token=${response.refresh_token}`;
    } else {
      window.location.href = `https://${response.client_id[0]}.prairiehq.${getEnv() === 'dev' ? 'net' : 'com'}/auth?error=${response.message}`;
    }
  }

  const handleSignUpWithGoogle = async (data: any) => {
    const parts = data.state.split('+');
    if (parts.length !== 2) {
      toaster.push(
        <Message type="error" showIcon closable>Invalid registration state</Message>
      );
      return;
    }

    const companyId = parts[0].replace('company_id=', '');
    const companyName = parts[1].replace('company_name=', '');

    if (!companyId || !companyName) {
      toaster.push(
        <Message type="error" showIcon closable>Invalid registration state</Message>
      );
      return;
    }

    const result: any = await api.post(`/oauth/v2/register/${companyId}`, {
      companyName,
      companyId,
      provider: 'google',
      client_id: companyId,
      code: data.code
    });
    setProcessing(false);

    if (result.success) {
      toaster.push(
        <Message type="success" showIcon closable duration={5000}>{result.message}</Message>
      );
      window.location.href = 'https://' + window.location.hostname.replace('app.', `${result.result.client_id}.`) + `?access_token=${result.result.access_token}&refresh_token=${result.result.refresh_token}`;
    } else {
      toaster.push(
        <Message type="error" showIcon closable duration={5000}>{result.message}</Message>
      );
    }
  }

  const handleResetPassword = async (form: any, formValue: any, token: string) => {
    if (!form.check()) {
      toaster.push(
        <Message type="error" showIcon closable>Reset form has errors which need to be corrected</Message>
      );
      return;
    }

    setProcessing(true);
    const result: any = await api.post('/oauth/v2/set_password', { ...formValue, token, client_id: getTenant().id });
    history.push(`/auth`);
    setProcessing(false);

    if (result.success) {
      toaster.push(<Message type="success" showIcon closable>{result.message}</Message>);
    } else {
      toaster.push(<Message type="error" showIcon closable>{result.message}</Message>);
    }
  }

  return (
    <Container>
      <Navbar>
        <Navbar.Header style={{ padding: "20px 16px" }}>
          <a href="/" className="navbar-brand logo">
            PrairieHQ
          </a>
        </Navbar.Header>
      </Navbar>

      {window.location.pathname.includes('/auth/google/signin')
        ? <Loader content="Loading..." />
        : <>
          {window.location.host.indexOf('service-desk') === -1 &&
            <Content style={{
              marginTop: window.location.pathname.indexOf('/register') > -1 ? 25 : 75
            }}>
              <div style={{ textAlign: 'center' }}>
                <AuthLogo />
              </div>
              <div>
                <FlexboxGrid justify="center">
                  <FlexboxGrid.Item colspan={isMobileOnly ? 22 : (getTenant().id === 'app' && window.location.pathname !== '/auth/register' ? 12 : 7)}>
                    <Panel className={isMobileOnly ? 'reset' : ''}>
                      <div style={{
                        border: 'solid 1px #E5E5EA',
                        borderRadius: '2px',
                        marginTop: '24px',
                        backgroundColor: '#fff'
                      }}>
                        <FlexboxGrid style={{ alignItems: 'stretch' }}>
                          <FlexboxGrid.Item style={{
                            padding: '50px 80px',
                            width: (getTenant().id === 'app' && window.location.pathname !== '/auth/register') ? '50%' : '100%'
                          }}>
                            <Switch>
                              <Route path={`/auth/reset/:token`} render={(props) =>
                                <AuthResetPasswordForm {...props} processing={processing} handleSubmit={handleResetPassword} />
                              } />
                              <Route path={`/auth/register`} render={() => {
                                if (IS_KALADI_KITCHENS) {
                                  return <KaladikitchensRegisterForm />
                                } if (IS_APP) {
                                  return <AppRegisterForm />
                                } else {
                                  return <AuthRegisterForm />
                                }
                              }} />
                              <Route render={(props) =>
                                <AuthSignInForm {...props} />
                              } />
                            </Switch>
                          </FlexboxGrid.Item>
                          {(getTenant().id === 'app' && window.location.pathname !== '/auth/register') &&
                            <FlexboxGrid.Item style={{ backgroundColor: '#0078d4', width: '50%', color: '#fff', textAlign: 'center' }}>
                              <div style={{ fontSize: '1.5rem', marginTop: '100px' }}>
                                Create an account,<br /><strong>try it for free</strong>
                              </div>
                              <Button
                                as={Link}
                                to="/auth/register"
                                appearance="ghost" style={{ color: '#fff', borderColor: '#fff', marginTop: '2rem' }}>Get started</Button>
                            </FlexboxGrid.Item>
                          }
                        </FlexboxGrid>
                      </div>
                    </Panel>
                  </FlexboxGrid.Item>
                </FlexboxGrid>
              </div>
            </Content>
          }
        </>
      }
    </Container>
  );
};

export default Auth;
