import { addDays, addMinutes, addYears, differenceInYears, format as dateFnsFormat, parseISO as dateFnsParseISO } from "date-fns";
import { utcToZonedTime, zonedTimeToUtc, format as dateFnsTzFormat } from "date-fns-tz";

const getSchedule = (
  startDate: Date,
  endDate: Date,
  startTime: Date,
  endTime: Date,
  days?: Array<string>
) => {
  const schedule = [];
  let start = new Date(+startDate);
  const end = new Date(+endDate);

  while (start <= end) {
    const startDateTime = new Date(new Date(+start).setHours(startTime.getHours(), startTime.getMinutes(), 0));

    schedule.push({
      start: startDateTime,
      end: new Date(new Date(+start).setHours(endTime.getHours(), endTime.getMinutes(), 0)),
      day: dateFnsFormat(startDateTime, 'EEE').toLowerCase()
    });

    start = addDays(start, 1);
  }

  console.log(schedule);
  return schedule;
}

const parseISO = (date: any) => {
  if (typeof (date) === 'string') {
    return dateFnsParseISO(date);
  }
  
  return date;
}

const toNearest30Minutes = (date: Date) => {
  let remainder: number
  const elapse = date.getMinutes() % 30;
  if (elapse === 0) {
    return new Date(date.setHours(new Date().getHours(), 0, 0, 0));
  } else {
    remainder = 30 - elapse;
    const rounded = addMinutes(date, remainder);
    return new Date(new Date().setHours(rounded.getHours(), rounded.getMinutes(), 0, 0));
  }
}

const toTimeZone = (date: Date, timeZone: string): Date => {
  if (!(date instanceof Date) || !timeZone) {
    return date;
  }

  return utcToZonedTime(date, timeZone);
};

const toLocalTimeZone = (date: Date, timeZone: string): Date => {
  if (!(date instanceof Date) || !timeZone) {
    return date;
  }
  return zonedTimeToUtc(date, timeZone);
};

const format = (date: Date, formatStr: string, timeZone?: string): string | undefined => {
  // if (timeZone) {
  //   return dateFnsTzFormat(date, formatStr, { timeZone });
  // }

  // return dateFnsFormat(date, formatStr);

  const parsedDate = parseISO(date);

  if (!parsedDate) {
    return undefined;
  } else {
    return dateFnsFormat(parsedDate, formatStr);
  }
};

const zonedDate = (timeZone: string): Date => {
  return toTimeZone(new Date(), timeZone);
}

const getDefaultDate = (date: Date, placement?: 'start' | 'end'): Date => {
  switch (placement) {
    case 'start':
      return new Date(new Date(+date).setHours(0, 0, 0, 0));
    case 'end':
      return new Date(new Date(+date).setHours(23, 59, 59, 59));
    default:
      return new Date(new Date(+date).setHours(9, 0, 0, 0));
  }
}

const addYearToDates = (dates: [string, string], years: number) => {
  let diffInYears = differenceInYears(new Date(), parseISO(dates[0])) || 0;
  diffInYears = diffInYears <= 0 ? 1 : diffInYears;
  return [addYears(parseISO(dates[0]), diffInYears), addYears(parseISO(dates[1]), diffInYears)];
}

export {
  toTimeZone,
  getSchedule,
  parseISO,
  toNearest30Minutes,
  zonedDate,
  format,
  toLocalTimeZone,
  getDefaultDate,
  addYearToDates
}
