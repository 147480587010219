import { gql } from "@apollo/client";

const queryInvoice = gql`
  query invoice($guid: ID) {
    invoice(guid:$guid) {
      id,
      guid
      lastEmail
      customerId
      applicationGroup
      paymentStatus
      paymentType
      workOrderMonth
      invoiceDate
      cost
      customerName
      gstRate
      gstRate2
      operatorName
      invoiceNumber
      monerisResponse
      billingProfile
      workOrderGuid
      workOrderId
      workType
      subtotal
      total
      invoiceDate
      privateComments
      publicComments
      profitability
      difficulty
      excludeContractValue
      excludeWatermark
      taxes1
      xeroInvoiceId
      photos {
        photoBefore
        photoAfter
        descriptionBefore
        descriptionAfter
      }
      captures {
        id
        guid
        response
        notes
      }
      customer {
        id
        guid
        email
        displayName
        contactDetails
        customerName
      }
      workOrders {
        guid
        workDescription
        contractNotes
        props
      }
      items {
        guid
        jobGuid
        cost
        additionalCost
        description
        formattedDescription
        invoiceDate
        costGroup
        costType
        sortOrder
        timeSpent
        photos {
          photoBefore
          photoAfter
          descriptionBefore
          descriptionAfter
        }
      }
      users {
        guid
        contactEmail
        operatorName
      }
    }
  }
`;

const queryInvoices = gql`
  query invoices(
    $limit: Int!
    $offset: Int!
    $where: InvoicesWhere
    $sort: InvoicesSort
    $order: [InvoicesSort]
  ) {
    invoices(filter: {
      limit: $limit
      offset: $offset
      where: $where
      sort: $sort
      order: $order
    }) {
      edges {
        node {
          id,
          guid
          lastEmail
          customerId
          applicationGroup
          paymentStatus
          paymentType
          workOrderMonth
          invoiceDate
          cost
          customerName
          gstRate
          gstRate2
          operatorName
          invoiceNumber
          monerisResponse
          billingProfile
          workOrderGuid
          workOrderId
          workType
          jobType
          services
          total
          workOrderStatus
          createdAt
          modifiedAt
          customer {
            id
            guid
            email
            displayName
            contactDetails
            customerName
          }
          billing {
            email
          }
          captures {
            id
            guid
            response
            notes
          }
          users {
            guid
            contactEmail
            operatorName
          }
        }
      },
      totalCount
    }
  }
`;

const QUERY_INVOICES_WITH_ITEMS = gql`
  query invoices(
    $limit: Int!
    $offset: Int!
    $where: InvoicesWhere
    $sort: InvoicesSort
    $order: [InvoicesSort]
  ) {
    invoices(filter: {
      limit: $limit
      offset: $offset
      where: $where
      sort: $sort
      order: $order
    }) {
      edges {
        node {
          id,
          guid
          lastEmail
          customerId
          applicationGroup
          paymentStatus
          paymentType
          workOrderMonth
          invoiceDate
          cost
          customerName
          gstRate
          gstRate2
          operatorName
          invoiceNumber
          monerisResponse
          billingProfile
          workOrderGuid
          workOrderId
          workType
          jobType
          services
          total
          workOrderStatus
          createdAt
          modifiedAt
          customer {
            id
            guid
            email
            displayName
            contactDetails
            customerName
          }
          billing {
            email
          }
          captures {
            id
            guid
            response
            notes
          }
          users {
            guid
            contactEmail
            operatorName
          }
          items {
            subtotal
            description
            formattedDescription
          }
        }
      },
      totalCount
    }
  }
`;

const queryInvoicesPerCustomer = gql`
  query invoices(
    $limit: Int!
    $offset: Int!
    $where: InvoicesWhere
    $sort: InvoicesSort
    $order: [InvoicesSort]
  ) {
    invoices(filter: {
      limit: $limit
      offset: $offset
      where: $where
      sort: $sort
      order: $order
    }) {
      edges {
        node {
          id,
          guid
          lastEmail
          customerId
          applicationGroup
          paymentStatus
          paymentType
          workOrderMonth
          invoiceDate
          cost
          customerName
          gstRate
          gstRate2
          operatorName
          invoiceNumber
          monerisResponse
          billingProfile
          workOrderGuid
          workOrderId
          workType
          jobType
          services
          total
          workOrderStatus
          createdAt
          modifiedAt
          customer {
            id
            guid
            email
            displayName
            contactDetails
            customerName
          }
          billing {
            email
          }
          captures {
            id
            guid
            response
            notes
          }
          workOrders {
            guid
            startDate
            endDate
          }
          users {
            guid
            contactEmail
            operatorName
          }
          # items {
          #   subtotal
          #   description
          # }
        }
      },
      totalCount
    }
  }
`;

const QUERY_INVOICE = queryInvoice;

export {
  QUERY_INVOICE,
  queryInvoice,
  queryInvoices,
  queryInvoicesPerCustomer,
  QUERY_INVOICES_WITH_ITEMS
}
