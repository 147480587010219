import { isMobileOnly } from "react-device-detect";
import { Button, Drawer } from "rsuite";

interface WorkOrderPhotosProps {
  guid?: string,
  onHide: () => void
}

const WorkOrderPhotos = ({ onHide }: WorkOrderPhotosProps) => {

  return (
    <Drawer open onClose={onHide} full={isMobileOnly} size="lg">
      <Drawer.Header>
        <Drawer.Title>Work Order Photos</Drawer.Title>
      </Drawer.Header>
      <Drawer.Body>

      </Drawer.Body>
      <Drawer.Footer>
        <Button onClick={onHide} appearance="subtle">Close</Button>
      </Drawer.Footer>
    </Drawer>
  );
}

export {
  WorkOrderPhotos
}
