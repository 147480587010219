import { gql, useApolloClient } from '@apollo/client';
import { InputNumber } from 'components/form';
import { usePrairieAuth } from 'contexts/AuthPrairieProvider';
import { parseISO, format } from 'date-fns';
import { FORMAT, getEnv, ROLE } from 'lib/env';
import { pick, startCase } from 'lodash';
import { useContext, useRef, useState, useEffect } from 'react';
import { Drawer, Form, SelectPicker, Button, Schema, toaster, Message, DatePicker } from 'rsuite';
import { ApplicationContext } from 'shared';
import { useViewport } from 'shared/ViewportProvider';
import { v4 as uuidv4 } from 'uuid';

interface IPayrollForm {
  drawer?: boolean,
  show?: boolean,
  guid?: string,
  action?: string,
  onHide: () => void,
  onUpdate?: (data: any) => void
}

const { NumberType } = Schema.Types;
const model = Schema.Model({
  hourlyPay: NumberType().isRequired('This field is required.'),
  userId: NumberType().isRequired('This field is required.'),
  employeeId: NumberType().isRequired('This field is required.'),
  hoursWorked: NumberType().isRequired('This field is required.')
});

const PayrollForm = ({ show, guid, action, drawer, onHide, onUpdate }: IPayrollForm) => {
  let form: any = useRef();
  const client = useApolloClient();
  const container = useRef() as React.MutableRefObject<HTMLDivElement>;
  const { state } = useViewport();
  const { isRole } = usePrairieAuth();
  const { showError } = useContext(ApplicationContext);
  const [saving, setSaving] = useState(false);
  const [employees, setEmployees] = useState<any>([]);
  const [formError, setFormError] = useState({});
  const [formValue, setFormValue] = useState<any>({
    userId: +state.profile.id,
    dateOnCalendar: new Date(),
    employeeId: -1,
    hourlyPay: 0,
    hoursWorked: 0,
    guid: uuidv4()
  });


  useEffect(() => {
    (async function getEmployees() {
      const employees: any = await client.query({
        query: gql`
          query employees($limit: Int!, $offset: Int!, $where: EmployeesWhere, $sort: EmployeesSort) {
            employees(filter: {
                limit: $limit
                offset: $offset
                where: $where
                sort: $sort
              }){
              edges {
                node {
                  id
                  guid
                  userId
                  employeeName
                  hourlyPay
                  operatorName
                }
              },
              totalCount
            }
          }`,
        variables: {
          offset: 0,
          limit: 999,
          where: {},
          sort: { employeeName: 'ASC' }
        }
      });

      setEmployees(employees.data.employees.edges.node.filter((e: any) => formValue.userId === e.userId));
    })();
  }, [formValue.userId])

  useEffect(() => {
    (async function getEmployeePayroll() {
      if (guid) {
        const response: any = await client.query({
          query: gql`
            query employeePayroll($guid: ID!) {
              employeePayroll(guid: $guid){
                userId
                employeeId
                hourlyPay
                hoursWorked
                dateOnCalendar,
                clockIn,
                clockOut
              },
            }`,
          variables: {
            guid
          }
        });

        const payroll = response.data.employeePayroll;

        setFormValue({
          userId: payroll.userId,
          dateOnCalendar: parseISO(payroll.dateOnCalendar),
          hourlyPay: payroll.hourlyPay,
          hoursWorked: payroll.hoursWorked,
          employeeId: payroll.employeeId,
          clockIn: typeof (payroll.clockIn) === 'string' ? parseISO(payroll.clockIn) : payroll.clockIn,
          clockOut: typeof (payroll.clockOut) === 'string' ? parseISO(payroll.clockOut) : payroll.clockOut,
          guid
        });
      }
    })();
  }, [guid]);

  useEffect(() => {
    const employee = employees.find((e: any) => e.id === formValue.employeeId);
    if (!formValue.hourlyPay) {
      setFormValue({ ...formValue, hourlyPay: employee?.hourlyPay || 0 });
    }
  }, [formValue.employeeId]);

  const handleSubmit = async () => {
    if (!form.check()) {
      return;
    }

    try {
      setSaving(true);
      const upsertEmployeePayroll = gql`
        mutation upsertEmployeePayroll($input: UpsertEmployeePayrollInput!) {
          upsertEmployeePayroll(input: $input) {
            success
            code
            message
            result
          }
        }`;

      const input = pick(formValue, ['guid', 'userId', 'employeeName', 'employeeId', 'hourlyPay', 'hoursWorked', 'dateOnCalendar', 'clockIn', 'clockOut']);
      input.hourlyPay = +input.hourlyPay;
      input.hoursWorked = +input.hoursWorked;

      const response: any = await client.mutate({
        mutation: upsertEmployeePayroll, variables: {
          input
        }
      });

      if (response.data.upsertEmployeePayroll.success) {
        toaster.push(
          <Message type="success" showIcon closable>{response.data.upsertEmployeePayroll.message}</Message>
        );

        onUpdate && onUpdate(response.data.upsertEmployeePayroll.result);
        onHide();
      } else {
        showError(response);
      }
      setSaving(false);
    } catch (err) {
      showError(err);
      setSaving(false);
    } finally {
      setSaving(false);
    }
  }

  return (
    <Drawer open onClose={onHide}>
      <Drawer.Header>
        <Drawer.Title>{startCase(action)} Employee Payroll</Drawer.Title>
      </Drawer.Header>
      <Drawer.Body>
        <div ref={container}>
          <Form
            ref={(ref: any) => form = ref}
            fluid
            formValue={formValue}
            formError={formError}
            model={model}
            onError={setFormError}
            onChange={setFormValue}
          >
            {getEnv() === 'dev' &&
              <div className="mb-12">
                <label>UUID:</label>
                <p>{formValue?.guid}</p>
              </div>
            }

            <Form.Group>
              <Form.ControlLabel className="required">Crew:</Form.ControlLabel>
              <Form.Control
                block
                searchable={true}
                cleanable={false}
                readOnly={isRole(ROLE.FRANCHISE, ROLE.WORKER)}
                name="userId"
                accepter={SelectPicker}
                data={state.users.filter((u: any) => u.userlevel === 'franchise')}
                labelKey="operatorName"
                valueKey="id" />
            </Form.Group>
            <Form.Group>
              <Form.ControlLabel className="required">Employee:</Form.ControlLabel>
              <Form.Control
                block
                searchable={true}
                cleanable={false}
                readOnly={isRole(ROLE.FRANCHISE, ROLE.WORKER)}
                name="employeeId"
                accepter={SelectPicker}
                data={employees}
                labelKey="employeeName"
                valueKey="id" />
            </Form.Group>
            <Form.Group>
              <Form.ControlLabel className="required">Date:</Form.ControlLabel>
              <Form.Control
                name="dateOnCalendar"
                accepter={DatePicker}
                block
                renderValue={(val: Date) => format(val, FORMAT.DAY_DATE)}
                cleanable={false} />
            </Form.Group>
            <Form.Group>
              <Form.ControlLabel className="required">Hourly Pay:</Form.ControlLabel>
              <Form.Control
                style={{ width: '100%' }}
                accepter={InputNumber}
                showDecimal
                name="hourlyPay" />
            </Form.Group>

            <Form.Group>
              <Form.ControlLabel className="required">Hours Worked:</Form.ControlLabel>
              <Form.Control
                style={{ width: '100%' }}
                accepter={InputNumber}
                showDecimal
                name="hoursWorked" />
              <Form.HelpText>When clock in/out hours are set, hours will be calculated automatically</Form.HelpText>
            </Form.Group>

            <Form.Group>
              <Form.ControlLabel>Clock In:</Form.ControlLabel>
              <Form.Control
                container={() => container && container.current}
                style={{ width: '100%' }}
                accepter={DatePicker}
                format='yyyy-MM-dd HH:mm'
                renderValue={(date: Date) => format(date, FORMAT.DAY_MONTH_TIME)}
                name="clockIn" />
            </Form.Group>

            <Form.Group>
              <Form.ControlLabel>Clock Out:</Form.ControlLabel>
              <Form.Control
                style={{ width: '100%' }}
                accepter={DatePicker}
                format='yyyy-MM-dd HH:mm'
                renderValue={(date: Date) => format(date, FORMAT.DAY_MONTH_TIME)}
                name="clockOut"
                container={() => container && container.current} />
            </Form.Group>
          </Form>
        </div>
      </Drawer.Body>
      <Drawer.Footer>
        <Button onClick={() => handleSubmit()} appearance="primary">Save</Button>
        <Button onClick={() => onHide()} appearance="subtle">Close</Button>
      </Drawer.Footer>
    </Drawer>
  );
}

export default PayrollForm;
