import { gql } from "@apollo/client";

const queryFields = gql`
  query fields($limit: Int!, $offset: Int!, $sort: FieldsSort, $where: FieldsWhere) {
    fields(filter: {
      limit: $limit
      offset: $offset
      sort: $sort
      where: $where
    }) {
      edges {
        node {
          guid
          id
          applicationGroup
          key
          title
          quickbooksCode
          quickbooksDescription
          isVisible
          isDefault
          visibleOnBooking
          visibleOnJob
          description
          parentId
          parentKey
          sortOrder
          defaults
          custom,
          allowFreeEntry
          deletedAt
          emailTemplateGuid
          invoiceTemplateGuid
          termsConditionsVersionGuid
          serviceDescriptionsVersionGuid
          props
          startDate
          endDate
          color
          salesPerManHourTarget
        }
      },
      totalCount
    }
  }
`;

export {
  queryFields
};
