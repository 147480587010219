import { gql, useApolloClient } from '@apollo/client';
import { parseISO } from 'date-fns';
import format from 'date-fns/format';
import subMonths from 'date-fns/subMonths';
import { FORMAT, getEnv, parseNumber } from 'lib/env';
import { useQueryString } from 'lib/hooks';
import { difference, startCase } from 'lodash';
import React, { Fragment, useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { Link, useRouteMatch } from 'react-router-dom';
import {
  ButtonToolbar,
  Button,
  toaster,
  Message,
  Col,
  Form,
  Grid,
  Loader,
  Panel,
  Row,
  DOMHelper,
  SelectPicker,
  InputGroup,
  Schema,
  Breadcrumb,
} from 'rsuite';
import { ApplicationContext, DrawerFormWrapper } from 'shared';
import { useViewport } from 'shared/ViewportProvider';
import { v4 as uuidv4 } from 'uuid';
import CashFlowFormCashLineItem from './CashFlowFormCashLineItem';

const GET_CASH_FLOW = gql`
  query cashFlows($limit: Int!, $offset: Int!, $where: CashFlowsWhere) {
    cashFlows(filter: {
      limit: $limit,
      offset: $offset,
      where: $where
    }) {
      edges {
        node {
          id
          guid
          userId
          totalCashIn
          totalCashOutBusiness
          totalCashOutPersonal
          netCash
          cashIn
          cashOutBusiness
          dateOnCalendar
          cashBeginning
          cashEnd
          operatorName
        }
      },
      totalCount
    }
  }
`;

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

const { StringType } = Schema.Types;

const model = Schema.Model({
  cashBeginning: StringType().isRequired('This field is required'),
  cashEnd: StringType().isRequired('This field is required')
});

interface ICashFlowForm {
  drawer?: boolean,
  show?: boolean,
  guid?: any,
  action?: any,
  userId?: number,
  date?: Date,
  onHide?: () => void,
  onUpdate?: (data: any) => void
}

const CashFlowForm = ({ show, guid, action, drawer, userId, date, onHide, onUpdate }: ICashFlowForm) => {
  let form: any = useRef();
  const match = useRouteMatch();
  const history = useHistory();
  const query = useQueryString();
  const client = useApolloClient();
  const { state } = useViewport();
  const { showError, showMessage } = useContext(ApplicationContext);
  const [formValue, setFormValue] = useState<any>({});
  const [formError, setFormError] = useState({});
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [months, setMonths] = useState<any>(undefined);
  const [disabledMonths, setDisabledMonths] = useState<any>(undefined);
  const [crew, setCrew] = useState(undefined);
  const colDescriptionWidth = drawer ? 9 : 6;
  const colAmountWidth = drawer ? 5 : 2;
  guid = query?.guid || (match?.params as any)?.guid || guid;
  action = query?.action || action || (match?.params as any)?.action || 'add';

  useEffect(() => {
    (async function getData() {
      try {
        setLoading(true);
        const whereFilter: any = { AND: [] };
        if (guid) {
          whereFilter.AND.push({ guid: { is: guid } });
        } else {
          whereFilter.AND.push({ userId: { is: +state.profile.id } });
        }

        const data = await client.query({
          query: GET_CASH_FLOW,
          variables: {
            offset: 0,
            limit: 100,
            where: whereFilter
          },
          fetchPolicy: 'no-cache'
        });

        DOMHelper.scrollTop(window as any, 0);

        const tmpMonths: any = [];
        const tmpDisabledMonths = data.data.cashFlows.edges.node.map((p: any) => new Date(p.dateOnCalendar).toISOString());
        const thisMonth = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
        (new Array(12).fill(0)).forEach((n: number, i: number) => {
          tmpMonths.push(subMonths(thisMonth, i).toISOString());
        });
        setMonths(tmpMonths);
        setDisabledMonths(tmpDisabledMonths);
        const today = difference(tmpMonths, tmpDisabledMonths);

        if (guid) {
          const current = data.data.cashFlows.edges.node.find((n: any) => n.guid === guid);

          const newFormValue: any = {
            guid: current.guid,
            userId: current.userId,
            dateOnCalendar: current.dateOnCalendar,
            totalCashIn: formatter.format(current.totalCashIn).replace('$', ''),
            totalCashOutBusiness: formatter.format(current.totalCashOutBusiness).replace('$', ''),
            totalCashOutPersonal: formatter.format(current.totalCashOutPersonal).replace('$', ''),
            netCash: formatter.format(current.netCash).replace('$', ''),
            cashIn: current.cashIn,
            cashOutBusiness: current.cashOutBusiness,
            cashBeginning: formatter.format(current.cashBeginning).replace('$', ''),
            cashEnd: formatter.format(current.cashEnd).replace('$', '')
          };

          if (action === 'edit') {
            if (!newFormValue.cashIn || newFormValue.cashIn.length === 0) {
              newFormValue.cashIn = [{ guid: uuidv4(), description: '', amount: '' }];
            }

            if (!newFormValue.cashOutBusiness || newFormValue.cashOutBusiness.length === 0) {
              newFormValue.cashOutBusiness = [{ guid: uuidv4(), description: '', amount: '' }];
            }
          }

          setFormValue(newFormValue);
          setCrew(current.operatorName);
        } else {
          const newFormValue: any = {
            guid: uuidv4(),
            userId: +state.profile.id,
            dateOnCalendar: today.length > 0 ? new Date(today[0] as any).toISOString() : undefined,
            totalCashIn: '0.00',
            totalCashOutBusiness: '0.00',
            totalCashOutPersonal: '0.00',
            netCash: '0.00',
            cashIn: [{
              guid: uuidv4(),
              description: '',
              amount: ''
            }],
            cashOutBusiness: [{
              guid: uuidv4(),
              description: '',
              amount: ''
            }]
          };

          setFormValue(newFormValue);
        }
      } catch (err) {
        console.log(err);
      }
      setLoading(false);
    })();
  }, [client, guid, action, state.profile.id]);

  const cleanFieldValue = (e: any) => {
    const val = (e.target.value || '').replace(/[^\d.-]/g, '').trim();

    if (val.length > 0) {
      const field = formatter.format(val).replace('$', '');
      setFormValue({ ...formValue, [e.target.name]: field });
    } else {
      setFormValue({ ...formValue, [e.target.name]: '' });
    }
  }

  const handleBlur = (group: string, guid: string, description: string, amount: string) => {
    const nextFormValue = JSON.parse(JSON.stringify(formValue));
    const keyIndex = nextFormValue[group].findIndex((g: any) => g.guid === guid);
    nextFormValue[group][keyIndex] = { ...nextFormValue[group][keyIndex], description, amount };

    setFormValue(nextFormValue);

  }

  const handleAdd = (group: string) => {
    const nextFormValue = JSON.parse(JSON.stringify(formValue));
    nextFormValue[group].push({
      guid: uuidv4(),
      description: '',
      amount: ''
    });

    setFormValue(nextFormValue);
  }

  const handleRemove = (group: string, guid: string) => {
    const nextFormValue = JSON.parse(JSON.stringify(formValue));

    if (nextFormValue[group].length === 1) {
      showMessage('Cannot remove last line item.', 'Remove ' + startCase(group));
      return;
    }

    const keyIndex = nextFormValue[group].findIndex((g: any) => g.guid === guid);
    nextFormValue[group].splice(keyIndex, 1);

    setFormValue(nextFormValue);
  }

  const handleSubmit = async (stay: boolean) => {
    if (!form.check()) {
      toaster.push(
        <Message type="error" showIcon closable>User form has errors which need to be corrected</Message>
      );
      return;
    }

    setSaving(true);

    try {
      const response: any = await client.mutate({
        mutation: gql`
          mutation upsertCashFlow($data: CashFlowInput) {
            upsertCashFlow(data: $data) {
              code
              success
              message
              result
            }
          }
        `,
        variables: {
          data: {
            guid: formValue.guid,
            userId: +formValue.userId,
            cashBeginning: parseNumber(formValue.cashBeginning),
            cashEnd: parseNumber(formValue.cashEnd),
            totalCashIn: parseNumber(formValue.totalCashIn),
            totalCashOutBusiness: parseNumber(formValue.totalCashOutBusiness),
            totalCashOutPersonal: parseNumber(formValue.totalCashOutPersonal),
            netCash: parseNumber(formValue.netCash),
            cashIn: formValue.cashIn.filter((l: any) => l.description.trim().length > 0 || l.amount.toString().trim().length > 0),
            cashOutBusiness: formValue.cashOutBusiness.filter((l: any) => l.description.trim().length > 0 || l.amount.toString().trim().length > 0),
            month: formValue.dateOnCalendar
          }
        }
      });

      if (response.data.upsertCashFlow.success) {
        toaster.push(
          <Message type="success" showIcon closable>{response.data.upsertCashFlow.message}</Message>
        );
      } else {
        showError(response);
      }

      if (drawer) {
        onUpdate && onUpdate(response.data.upsertCashFlow.result);
        onHide && onHide();
      } else {
        if (stay) {
          history.push(`/cash-flow/form/edit/${formValue.guid}`);
        } else {
          history.push(`/financials/cash-flow`);
        }
      }
    } catch (err) {
      showError(err);
    } finally {
      setSaving(false);
    }
  }

  const updateTotals = (form?: any) => {
    form.netCash = parseNumber(form.cashEnd) - parseNumber(form.cashBeginning);
    form.netCash = formatter.format(form.netCash).replace('$', '');
    setFormValue(form);
  }

  const updateGroupGroupTotals = (group: string, guid: string, amount: number) => {
    const nextFormValue = JSON.parse(JSON.stringify(formValue));
    const results = formValue[group];
    const valueIndex = results.findIndex((r: any) => r.guid === guid);
    results[valueIndex].amount = amount;

    nextFormValue[`total${startCase(group).replace(/\s/g, '')}`] = formatter.format(results.reduce((p: number, n: any) => (p += parseNumber(n.amount)), 0)).replace('$', '');
    setFormValue(nextFormValue);
  }

  const Wrapper = show === undefined
    ? <div />
    : <DrawerFormWrapper
      loading={saving}
      show={show}
      title={startCase(action) + ' Cash Flow'}
      guid={guid}
      size={'md'}
      action={action}
      form={`/app/${getEnv()}/cash-flow/form/view/${guid}`}
      onHide={onHide}
      onSave={handleSubmit}
    />;

  return React.cloneElement(Wrapper, {
    children: <div>
      {!drawer &&
        <Breadcrumb separator=" | ">
          <Breadcrumb.Item>
            <Link to={`/app/${getEnv()}/financials/cash-flow`}>Cash Flow</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            {startCase(action)}
          </Breadcrumb.Item>
        </Breadcrumb>
      }

      <Panel className="content">
        {loading
          ? <Loader content="Loading..." />
          : <Fragment>
            <Form
              fluid
              className={action === 'view' ? 'readonly' : ''}
              ref={(ref: any) => form = ref}
              formValue={formValue}
              formError={formError}
              onChange={(formValue: any, e: any) => {
                if (e.target.value === undefined) {
                  setFormValue(formValue);
                  return;
                }

                updateTotals(formValue);
              }}
              onCheck={setFormError}
              model={model}
            >

              <Form.Group>
                <Form.ControlLabel className="fieldset">Date</Form.ControlLabel>
                <Grid fluid>
                  {crew &&
                    <Row className="mb-5">
                      <Col md={colDescriptionWidth} className='text-right'><strong>Crew:</strong></Col>
                      <Col md={colAmountWidth}>{crew}</Col>
                    </Row>
                  }
                  <Row className={'mb-5'}>
                    <Col md={colDescriptionWidth}>
                      <Form.ControlLabel className={'pt-4 text-right'}>Month:</Form.ControlLabel>
                    </Col>
                    <Col md={colAmountWidth}>
                      <Form.Control
                        block
                        readOnly={action === 'view'}
                        cleanable={false}
                        name="dateOnCalendar"
                        searchable={false}
                        accepter={SelectPicker}
                        data={months && months.map((p: any) => ({
                          value: p,
                          label: format(parseISO(p), FORMAT.MONTH_DATE)
                        }))}
                        disabledItemValues={disabledMonths} />
                    </Col>
                  </Row>
                </Grid>
              </Form.Group>

              <Form.Group>
                <Form.ControlLabel className="fieldset">Business Bank Account Cash Flow</Form.ControlLabel>
                <Grid fluid>
                  <Row className={'mb-5'}>
                    <Col md={colDescriptionWidth}>
                      <Form.ControlLabel className={'pt-4 text-right required'}>Balance at Beginning of Period:</Form.ControlLabel>
                    </Col>
                    <Col md={colAmountWidth}>
                      <InputGroup inside style={{ width: '100%' }}>
                        <InputGroup.Addon>$</InputGroup.Addon>
                        <Form.Control
                          name="cashBeginning"
                          readOnly={action === 'view'}
                          style={{ width: '100%' }}
                          placeholder="0.00"
                          onBlur={cleanFieldValue} />
                      </InputGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={colDescriptionWidth}>
                      <Form.ControlLabel className={'pt-4 text-right required'}>Balance at End of Period:</Form.ControlLabel>
                    </Col>
                    <Col md={colAmountWidth}>
                      <InputGroup inside style={{ width: '100%' }}>
                        <InputGroup.Addon>$</InputGroup.Addon>
                        <Form.Control
                          name="cashEnd"
                          readOnly={action === 'view'}
                          style={{ width: '100%' }}
                          placeholder="0.00"
                          onBlur={cleanFieldValue} />
                      </InputGroup>
                    </Col>
                  </Row>
                </Grid>
              </Form.Group>

              <Form.Group>
                <Form.ControlLabel className="fieldset">Total Cash In</Form.ControlLabel>
                <Grid fluid>
                  <Row className={'mb-5'}>
                    <Col md={colDescriptionWidth}>Description</Col>
                    <Col md={colAmountWidth}>Amount</Col>
                  </Row>
                  {(action === 'view' && (!formValue.cashIn || formValue.cashIn.length === 0)) &&
                    <Row className={'mb-5'}>
                      <Col md={colDescriptionWidth} className="text-mutted">No data entered</Col>
                    </Row>
                  }
                  {formValue.cashIn && formValue.cashIn.map((c: any) =>
                    <CashFlowFormCashLineItem
                      colDescriptionWidth={colDescriptionWidth}
                      colAmountWidth={colAmountWidth}
                      readOnly={action === 'view'}
                      description={c.description}
                      guid={c.guid}
                      key={c.guid}
                      amount={c.amount}
                      group='cashIn'
                      onBlur={handleBlur}
                      onAdd={handleAdd}
                      onRemove={handleRemove}
                      onChange={updateGroupGroupTotals}
                    />
                  )}
                  <Row className={'mt-5'}>
                    <Col md={colDescriptionWidth}>
                      <Form.ControlLabel className={'pt-4 text-right'}>Total Amount:</Form.ControlLabel>
                    </Col>
                    <Col md={colAmountWidth}>
                      <InputGroup inside style={{ width: '100%' }}>
                        <InputGroup.Addon>$</InputGroup.Addon>
                        <Form.Control
                          name="totalCashIn"
                          readOnly={true}
                          style={{ width: '100%' }}
                          placeholder="0.00" />
                      </InputGroup>
                    </Col>
                  </Row>
                </Grid>
              </Form.Group>

              <Form.Group>
                <Form.ControlLabel className="fieldset">Total Cash Out</Form.ControlLabel>
                <Grid fluid>
                  <Row className={'mb-5'}>
                    <Col md={colDescriptionWidth}>Business Related</Col>
                    <Col md={colAmountWidth}>Amount</Col>
                  </Row>
                  {(action === 'view' && (!formValue.cashOutBusiness || formValue.cashOutBusiness.length === 0)) &&
                    <Row className={'mb-5'}>
                      <Col md={6} className="text-mutted">No data entered</Col>
                    </Row>
                  }
                  {formValue.cashOutBusiness && formValue.cashOutBusiness.map((c: any) =>
                    <CashFlowFormCashLineItem
                      colDescriptionWidth={colDescriptionWidth}
                      colAmountWidth={colAmountWidth}
                      readOnly={action === 'view'}
                      description={c.description}
                      guid={c.guid}
                      key={c.guid}
                      amount={c.amount}
                      group='cashOutBusiness'
                      onBlur={handleBlur}
                      onAdd={handleAdd}
                      onRemove={handleRemove}
                      onChange={updateGroupGroupTotals}
                    />
                  )}
                  <Row className={'mt-5'}>
                    <Col md={colDescriptionWidth}>
                      <Form.ControlLabel className={'pt-4 text-right'}>Total Business Related Amount:</Form.ControlLabel>
                    </Col>
                    <Col md={colAmountWidth}>
                      <InputGroup inside style={{ width: '100%' }}>
                        <InputGroup.Addon>$</InputGroup.Addon>
                        <Form.Control
                          name="totalCashOutBusiness"
                          readOnly={true}
                          style={{ width: '100%' }}
                          placeholder="0.00" />
                      </InputGroup>
                    </Col>
                  </Row>
                  <Row className={'mt-5'}>
                    <Col md={colDescriptionWidth}>
                      <Form.ControlLabel className={'pt-4 text-right'}>Total Personal/Non-Business Related Amount:</Form.ControlLabel>
                    </Col>
                    <Col md={colAmountWidth}>
                      <InputGroup inside style={{ width: '100%' }}>
                        <InputGroup.Addon>$</InputGroup.Addon>
                        <Form.Control
                          name="totalCashOutPersonal"
                          style={{ width: '100%' }}
                          placeholder="0.00"
                          onBlur={cleanFieldValue} />
                      </InputGroup>
                    </Col>
                  </Row>
                </Grid>
              </Form.Group>

              <Form.Group>
                <Form.ControlLabel className="fieldset">Net Cash</Form.ControlLabel>
                <Grid fluid>
                  <Row className={'mb-5'}>
                    <Col md={colDescriptionWidth}>
                      <Form.ControlLabel className={'pt-4 text-right'}>Cash Difference:</Form.ControlLabel>
                    </Col>
                    <Col md={colAmountWidth}>
                      <InputGroup inside style={{ width: '100%' }}>
                        <InputGroup.Addon>$</InputGroup.Addon>
                        <Form.Control
                          name="netCash"
                          readOnly
                          style={{ width: '100%' }}
                          placeholder="0.00"
                          onBlur={cleanFieldValue} />
                      </InputGroup>
                    </Col>
                  </Row>
                </Grid>
              </Form.Group>

              {(!drawer && ['add', 'edit'].includes(action)) &&
                <ButtonToolbar className="mt-24">
                  <Button appearance="primary" onClick={() => handleSubmit(false)} loading={saving}>Save</Button>
                  <Button appearance="ghost" onClick={() => handleSubmit(true)} loading={saving}>Save &amp; Stay</Button>
                </ButtonToolbar>
              }
            </Form>
          </Fragment>
        }
      </Panel>
    </div>
  });
}

export default CashFlowForm;
