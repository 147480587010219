import gql from 'graphql-tag';
import { ApolloClient } from '@apollo/client';
import filter from 'lodash/filter';
import uniq from 'lodash/uniq';

const getCustomers = async (client: ApolloClient<object>, customerId: number) => {
  const customers = [];
  const data: any = await client.query({
    query: gql`query customer($id: Int) { customer(id: $id) { id, parentId } }`,
    variables: { id: +customerId },
    fetchPolicy: 'no-cache'
  });
  const customer = data.data.customer;
  customers.push(+customer.id);
  customers.push(+customer.parentId);

  const orQuery = [ { parentId: { is: customers[0] } } ];
  if (customer.parentId) {
    orQuery.push({ id: { is: +customer.parentId } } as any)
  }

  const children = await client.query({
    query: gql`
    query customers($where: CustomersWhere) {
      customers(filter: { where: $where }) {
        edges {
          node {
            id,
            parentId
          }
        }
      }
    }`,
    variables: { where: { OR: orQuery } },
    fetchPolicy: 'no-cache'
  });

  (children as any).data.customers.edges.node.forEach((n: any) => {
    customers.push(+n.id);
    customers.push(+n.parentId);
  });

  return uniq(filter(customers));
}

const getCustomerEmails = async (client: ApolloClient<object>, customerId: number) => {
  const customers = [];
  const emails: any = [];
  const data: any = await client.query({
    query: gql`query customer($id: Int) { customer(id: $id) { id, parentId, email } }`,
    variables: { id: +customerId },
    fetchPolicy: 'no-cache'
  });
  const customer = data.data.customer;
  customers.push(+customer.id);
  customers.push(+customer.parentId);
  emails.push(customer.email);

  const orQuery = [ { parentId: { is: customers[0] } } ];
  if (customer.parentId) {
    orQuery.push({ id: { is: +customer.parentId } } as any)
  }

  const children = await client.query({
    query: gql`
    query customers($where: CustomersWhere) {
      customers(filter: { where: $where }) {
        edges {
          node {
            email
          }
        }
      }
    }`,
    variables: { where: { OR: orQuery } },
    fetchPolicy: 'no-cache'
  });

  (children as any).data.customers.edges.node.forEach((n: any) => {
    emails.push(n.email);
  });

  return uniq(emails.join(';').split(';').filter((e: any) => e));
}

const getCustomer = async (client: ApolloClient<object>, customerId: number) => {
  const data = await client.query({
    query: gql`query customer($id: Int) {
      customer(id: $id) {
        id,
        parentId,
        isActive,
        doNotService,
        doNotContact,
        displayName
      }
    }`,
    variables: { id: +customerId },
    fetchPolicy: 'no-cache'
  });
  return (data as any).data.customer;
}

const arrayRange = (start: number, stop: number, step: number) =>
  Array.from(
    { length: (stop - start) / step + 1 },
    (value, index) => start + index * step
  );


export {
  getCustomers,
  getCustomer,
  getCustomerEmails,
  arrayRange
};
