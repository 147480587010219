import { Button, ButtonGroup, Drawer, Loader } from "rsuite";
import DrawerBody from "rsuite/esm/Drawer/DrawerBody";
import { ApplicationContext, IDrawerProps } from "shared/ApplicationProvider";
import AttachmentForm from "./AttachmentForm";
import { useMutation, useQuery } from "@apollo/client";
import { QUERY_ATTACHMENTS, UPSERT_ATTACHMENTS } from "gql/attachments";
import { useContext, useEffect, useState } from "react";

interface IAttachmentDrawer extends IDrawerProps { }

const AttachmentDrawer = ({
  titleSuffix,
  resourceGuid,
  onHide
}: IAttachmentDrawer) => {
  const { showError, showSuccess } = useContext(ApplicationContext);
  const attachments = useQuery(QUERY_ATTACHMENTS, { variables: { resourceGuid } });
  const [upsertAttachment, upsertAttachmentResult] = useMutation(UPSERT_ATTACHMENTS);
  const [attachmentsForm, setAttachmentsForm] = useState([]);

  useEffect(() => {
    if (attachments?.data?.attachments?.edges?.node) {
      setAttachmentsForm(attachments?.data?.attachments?.edges?.node || []);
    }
  }, [attachments]);

  useEffect(() => {
    if (upsertAttachmentResult?.called && upsertAttachmentResult?.loading === false) {
      if (upsertAttachmentResult?.error) {
        showError(upsertAttachmentResult?.error);
        return;
      }

      showSuccess(upsertAttachmentResult?.data?.upsertAttachment?.message);
      
      if (onHide) {
        onHide();
      }
    }
  }, [upsertAttachmentResult]);

  return <Drawer open onClose={onHide}>
    <Drawer.Header>
      <Drawer.Title>
        {titleSuffix ? titleSuffix + ' - ' : ''}Upload Attachments
      </Drawer.Title>
    </Drawer.Header>
    <DrawerBody>
      {attachments?.loading
        ? <Loader content="Loading..." />
        : <AttachmentForm
          referenceGuid={resourceGuid as any}
          attachments={attachmentsForm}
          onChange={(data: any) => setAttachmentsForm(data)}
          showType={false}
          showVisibility={false}
        />
      }
    </DrawerBody>
    <Drawer.Actions>
      <ButtonGroup>
        <Button 
          disabled={upsertAttachmentResult?.called && upsertAttachmentResult?.loading}
          loading={upsertAttachmentResult?.called && upsertAttachmentResult?.loading}
          appearance='primary' 
          size='sm' 
          onClick={() => {
            upsertAttachment({ variables: { input: attachmentsForm }});  
          }}
        >Save</Button>
        <Button 
          appearance='subtle' 
          size="sm" 
          onClick={() => {
            if (onHide) {
              onHide();
            }
          }}
        >Close</Button>
      </ButtonGroup>
    </Drawer.Actions>
  </Drawer>
}

export default AttachmentDrawer;