import { gql } from "@apollo/client";

const QUERY_COMPANY = gql`
  query company($guid: ID!) {
    company(guid: $guid) {
      id
      guid
      name
      address
      phone
      website
      email
      taxRate
      taxRate2
      businessNumber
      gstNumber
      wcbNumber
      taxPrefix
      taxPrefix2
      companyName
      parentDivision
      logo
      fax
      corporateName
      companyDivision
      adminPercentage
      documentNotifications
      newDocumentStatus
      clientSignedDocumentStatus
      representativeSignedDocumentStatus
      royaltyPercentage
      laborPercentage
      corporateTax
      domainName
      salesPerManHourTarget
      timezone
      country
      emailDailyFinancials
      signature
      modifiedAt
      createdAt
      documentsFromEmail
      integrations {
        moneris
        monerisTest
        stripe
        stripeTest
        stripePublishableKey
        quickbooksDesktop
        xero
      }
    }
  }
`;

const QUERY_COMPANIES = gql`
  query companies($limit: Int!, $offset: Int!) {
    companies(filter: {
      limit: $limit,
      offset: $offset
    }) {
      edges {
        node {
          id
          guid
          name
          address
          phone
          website
          email
          taxRate
          taxRate2
          businessNumber
          gstNumber
          wcbNumber
          taxPrefix
          taxPrefix2
          companyName
          parentDivision
          logo
          fax
          corporateName
          companyDivision
          adminPercentage
          documentNotifications
          newDocumentStatus
          clientSignedDocumentStatus
          representativeSignedDocumentStatus
          royaltyPercentage
          laborPercentage
          corporateTax
          domainName
          salesPerManHourTarget
          timezone
          country
          emailDailyFinancials
          signature
          modifiedAt
          createdAt
          integrations {
            moneris
            monerisTest
            stripe
            stripeTest
            stripePublishableKey
            quickbooksDesktop
            xero
          }
        }
      },
      totalCount
    }
  }
`;

const QUERY_COMPANY_STYLE = gql`
  query company($guid: ID!) {
    company(guid: $guid) {
      globalDocumentStyle
      globalEmailStyle
    }
  }
`;

const GET_COMPANIES = QUERY_COMPANIES;
const GET_COMPANY = QUERY_COMPANY;

export {
  GET_COMPANIES,
  GET_COMPANY,
  QUERY_COMPANY,
  QUERY_COMPANIES,
  QUERY_COMPANY_STYLE
};
