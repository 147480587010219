import { useApolloClient } from "@apollo/client";
import { parseISO } from "date-fns";
import { GET_JOBS_COMPLETION_REPORT, GET_JOBS_ONDEMAND_COMPLETION_REPORT, GET_JOBS_SERVICES_COMPLETION_REPORT } from "gql/jobs";
import { getFilter } from "lib/helpers/filter";
import { uniq } from "lodash";
import { useEffect, useState } from "react";
import Chart from "react-google-charts";
import { FlexboxGrid, Loader } from "rsuite";
import { useViewport } from "shared/ViewportProvider";

interface IJobsReport {
  filter: any,
  setFilter: (filter: any) => void,
  reports: Array<string>
}

const JobsReport = ({ filter, setFilter, reports }: IJobsReport) => {
  const client = useApolloClient();
  const filterKey = 'jobs';
  const { state } = useViewport();
  const [jobsCompletionsLoading, setJobsCompletionsLoading] = useState(true);
  const [jobsOnDemandCompletionsLoading, setJobsOnDemandCompletionsLoading] = useState(true);
  const [jobsServiceCompletionsLoading, setJobsServiceCompletionsLoading] = useState(true);
  const [jobsCompletions, setJobsCompletions] = useState<any>([]);
  const [jobsOnDemandCompletions, setJobsOnDemandCompletions] = useState<any>([]);
  const [jobsServicesCompletions, setJobsServicesCompletions] = useState<any>([]);

  useEffect(() => {
    if (reports.includes('job-completion')) {
      (async function getJobs() {
        setJobsCompletionsLoading(true);
        const completions: any = {};
        const result = await client.query({
          query: GET_JOBS_COMPLETION_REPORT,
          variables: {
            offset: 0,
            limit: 99999999,
            where: getFilter(filterKey, filter, state.profile)
          },
          fetchPolicy: 'no-cache'
        });

        if (result.data?.jobsCompletionReport) {
          result.data?.jobsCompletionReport.map((j: any) => {
            if (completions.hasOwnProperty(j.startDate)) {
              completions[j.startDate][j.status] = j.count;
            } else {
              completions[j.startDate] = {
                start_date: j.startDate,
                not_completed: 0,
                completed: 0,
                canceled: 0
              }
            }
          });

          setJobsCompletions([['', 'Completed', 'Not Completed', 'Canceled']].concat(
            Object.keys(completions).map((k: any) =>
              ([parseISO(k), completions[k].completed, completions[k].not_completed, completions[k].canceled])
            )
          ));
        }
        setJobsCompletionsLoading(false);
      })();
    }
  }, [filter, reports]);

  useEffect(() => {
    if (reports.includes('job-on-demand-completion')) {
      (async function getJobs() {
        setJobsOnDemandCompletionsLoading(true);
        const completions: any = {};
        const result = await client.query({
          query: GET_JOBS_ONDEMAND_COMPLETION_REPORT,
          variables: {
            offset: 0,
            limit: 99999999,
            where: getFilter(filterKey, filter, state.profile)
          },
          fetchPolicy: 'no-cache'
        });

        if (result.data?.jobsOnDemandCompletionReport) {
          result.data?.jobsOnDemandCompletionReport.map((j: any) => {
            completions[j.startDate] = {
              start_date: j.startDate,
              completed: j.completed,
              remaining: j.remaining
            }
          });
        }

        setJobsOnDemandCompletions([['', 'Completed', 'Remaining']].concat(
          Object.keys(completions).map((k: any) =>
            ([parseISO(k), completions[k].completed, completions[k].remaining])
          )
        ));

        setJobsOnDemandCompletionsLoading(false);
      })();
    }
  }, [filter, reports]);

  useEffect(() => {
    if (reports.includes('job-service-completion')) {
      (async function getJobs() {
        setJobsServiceCompletionsLoading(true);
        const completions: any = {};
        const result = await client.query({
          query: GET_JOBS_SERVICES_COMPLETION_REPORT,
          variables: {
            offset: 0,
            limit: 99999999,
            where: getFilter(filterKey, filter, state.profile)
          },
          fetchPolicy: 'no-cache'
        });

        if (result.data?.jobsServicesCompletionReport) {
          const services = uniq(result.data?.jobsServicesCompletionReport.map((s: any) => s.service));
          setJobsServicesCompletions([['', 'Completed', 'Not Completed']].concat(
            services.map((s: any) => [
              s,
              result.data?.jobsServicesCompletionReport.find((f: any) => f.service === s && f.status === 'completed')?.count || 0,
              result.data?.jobsServicesCompletionReport.find((f: any) => f.service === s && f.status === 'not_completed')?.count || 0
            ])
          ));
        }

        setJobsServiceCompletionsLoading(false);
      })();
    }
  }, [filter, reports]);

  return <>
    {reports.length > 0 && <>
      <FlexboxGrid>
        {reports.includes('job-completion') &&
          <>
            {jobsCompletionsLoading
              ? <Loader />
              : <FlexboxGrid.Item colspan={24 / reports.length}>
                <Chart
                  width={'100%'}
                  height={300}
                  chartType="Bar"
                  loader={<div>Loading Chart</div>}
                  data={jobsCompletions}
                  chartEvents={[
                    {
                      eventName: "select",
                      callback: ({ chartWrapper }) => {
                        const data = chartWrapper.getChart().getSelection();
                        const columns = ['', 'completed', 'not_completed', 'canceled'];
                        if (data.length > 0) {
                          // const { row, column } = data[0];
                          // setFilter({ ...filter, jobStatus: columns[column] });
                          // history.push(`/app/dev/jobs/list?jobStatus=${columns[column]}`);
                        } else {
                          console.log('reset');
                        }
                      }
                    }
                  ]}
                  options={{
                    stacked: true,
                    series: [{ color: '#5cb85c' }, { color: '#d9534f' }, { color: '#f0ad4e' }],
                    is3D: false,
                    chart: {
                      title: 'Job Completions'
                    },
                    hAxis: {
                      title: 'Date',
                      format: 'MMM d',
                      slantedText: true,
                      slantedTextAngle: 45,
                      titleTextStyle: {
                        fontSize: 12
                      },
                      textStyle: {
                        fontSize: 12
                      },
                      textColor: '#ffffff'
                    },
                    vAxis: {
                      title: 'Total',
                      minValue: 0,
                      titleTextStyle: {
                        fontSize: 12
                      },
                      textStyle: {
                        fontSize: 12
                      }
                    },
                    legend: {
                      position: "none"
                    }
                  }}
                  legendToggle
                />
              </FlexboxGrid.Item>
            }
          </>
        }

        {reports.includes('job-on-demand-completion') &&
          <>
            {jobsOnDemandCompletionsLoading
              ? <Loader />
              : <FlexboxGrid.Item colspan={24 / reports.length}>
                <Chart
                  width={'100%'}
                  height={300}
                  chartType="Bar"
                  loader={<div>Loading Chart</div>}
                  data={jobsOnDemandCompletions}
                  chartEvents={[
                    {
                      eventName: "select",
                      callback: ({ chartWrapper }) => {
                        const data = chartWrapper.getChart().getSelection();
                        const columns = ['', 'completed', 'not_completed', 'canceled'];
                        if (data.length > 0) {
                          // const { row, column } = data[0];
                          // setFilter({ ...filter, jobStatus: columns[column] });
                          // history.push(`/app/dev/jobs/list?jobStatus=${columns[column]}`);
                        } else {
                          console.log('reset');
                        }
                      }
                    }
                  ]}
                  options={{
                    stacked: true,
                    series: [{ color: '#5cb85c' }, { color: '#d9534f' }],
                    is3D: false,
                    chart: {
                      title: 'On Demand Completions'
                    },
                    hAxis: {
                      title: 'Date',
                      format: 'MMM d',
                      slantedText: true,
                      slantedTextAngle: 45,
                      titleTextStyle: {
                        fontSize: 12
                      },
                      textStyle: {
                        fontSize: 12
                      },
                      textColor: '#ffffff'
                    },
                    vAxis: {
                      title: 'Total',
                      minValue: 0,
                      titleTextStyle: {
                        fontSize: 12
                      },
                      textStyle: {
                        fontSize: 12
                      }
                    },
                    legend: {
                      position: "none"
                    }
                  }}
                  legendToggle
                />
              </FlexboxGrid.Item>
            }
          </>
        }

        {reports.includes('job-service-completion') &&
          <>
            {jobsServiceCompletionsLoading
              ? <Loader />
              : <FlexboxGrid.Item colspan={24 / reports.length}>
                <Chart
                  width={'100%'}
                  height={300}
                  chartType="Bar"
                  loader={<div>Loading Chart</div>}
                  data={jobsServicesCompletions}
                  chartEvents={[
                    {
                      eventName: "select",
                      callback: ({ chartWrapper }) => {
                        const data = chartWrapper.getChart().getSelection();
                        const columns = ['', 'completed'];
                        if (data.length > 0) {
                          // const { row, column } = data[0];
                          // setFilter({ ...filter, jobStatus: columns[column] });
                          // history.push(`/app/dev/jobs/list?jobStatus=${columns[column]}`);
                        } else {
                          console.log('reset');
                        }
                      }
                    }
                  ]}
                  options={{
                    stacked: true,
                    series: [{ color: '#5cb85c' }, { color: '#d9534f' }],
                    is3D: false,
                    chart: {
                      title: 'Service Completions'
                    },
                    vAxis: {
                      title: 'Total',
                      minValue: 0,
                      titleTextStyle: {
                        fontSize: 12
                      },
                      textStyle: {
                        fontSize: 12
                      }
                    },
                    legend: {
                      position: "none"
                    }
                  }}
                  legendToggle
                />
              </FlexboxGrid.Item>
            }
          </>
        }
      </FlexboxGrid>
    </>}
  </>
}

export default JobsReport;
