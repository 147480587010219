import { gql, useApolloClient } from '@apollo/client';
import { usePrairieAuth } from 'contexts/AuthPrairieProvider';
import { format } from 'lib/date';
import { FORMAT, getEnv, ROLE } from 'lib/env';
import { IS_BUGABOO } from 'lib/tenant';
import { startCase, without } from 'lodash';
import { useState, useRef } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useHistory } from 'react-router-dom';
import { toaster, Message, AutoComplete, InputGroup } from 'rsuite';
import { v4 as uuidv4 } from 'uuid';
import SearchIcon from '@rsuite/icons/Search';
let timeout: NodeJS.Timeout;

const SearchAll = () => {
  const autoCompleteRef: any = useRef();
  const client = useApolloClient();
  const history = useHistory();
  const [data, setData] = useState<any>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const { isRole } = usePrairieAuth();

  const handleOnChange = async (search: string) => {
    setSearchTerm(search);

    if (timeout) {
      clearTimeout(timeout);
    }

    timeout = setTimeout(async () => {
      try {
        const data = await client.query({
          query: gql`{ quickSearch(filter:"${search}") { edges { node { id, guid, customerName, businessName, customerAddress, postalCode, phone, alternatePhone, customerId, parentId, entryType, applicationGroup, paymentStatus, workOrderMonth, startDate, endDate, customerEmail, resourceGroup } } } }`,
          fetchPolicy: 'no-cache'
        });
        const results: any = [];

        if (data.data.quickSearch.edges.node.length > 0) {
          without(['customer', 'document', 'work_order', 'invoice'], IS_BUGABOO ? 'invoice' : '')
            .forEach((g: string) => {
              results.push({
                resourceGroup: 'header',
                value: uuidv4(),
                label: g,
                title: startCase(g),
                guid: uuidv4()
              });

              data.data.quickSearch.edges.node.filter((n: any) => n.resourceGroup === g && n.customerId).forEach((n: any, index: number) => {
                results.push({
                  ...n,
                  header: false,
                  value: n.customerName + '__' + n.guid + '__' + n.id + '__' + index + '__' + g,
                  label: n.customerName
                });
              });
            });
        } else {
          results.push({
            guid: undefined,
            header: false,
            value: '',
            label: 'No results found'
          });
        }

        setData(results);
      } catch (err) {
        console.log(err);
      }
    }, 500);
  };

  const getLineItem = (data: any) => {
    switch (data.resourceGroup) {
      case 'customer':
        return '<div class="selectable"><p>' + (data.businessName ? data.businessName : data.customerName) +
          (typeof (data.customerAddress) === 'string' ? ' at <small class="text-muted">'
            + data.customerAddress
            + (data.postalCode ? data.postalCode : '')
            + '</small>' : '') + ' - ' +
          (typeof (data.phone) === 'string' ? ' ' + data.phone : '') +
          (data.alternatePhone > 0 ? ' ' + data.alternatePhone.trim() : '') +
          '</p></div>';
      case 'invoice':
        return '<div class="selectable"><p>#' + data.id + ' ' +
          data.customerName +
          ' for ' +
          format(data.workOrderMonth, FORMAT.MONTH_DATE) +
          ' - ' +
          (data.paymentStatus === 'paid' ? '<span class="text-success">Paid</span>' : '<span class="text-danger">Not Paid</span>') +
          '</p></div>';
      case 'document':
        return '<div class="selectable"><p>' + startCase(data.entryType) + ' #' + data.id + ' ' +
          data.customerName +
          ' on ' +
          (data.startDate ? format(data.startDate, FORMAT.MONTH_DATE) : '') +
          (data.endDate ? ' to ' + format(data.endDate, FORMAT.MONTH_DATE) : '') +
          '</p></div>';
      case 'work_order':
        return '<div class="selectable"><p>#' + data.id + ' ' +
          data.customerName +
          ' on ' +
          format(data.startDate, FORMAT.MONTH_DATE) + ' to ' + format(data.endDate, FORMAT.MONTH_DATE) +
          '</p></div>';
      case 'header':
        return '<div><strong>' + data.title + '</strong>' + (data.count === 0 ? ' - <span class="text-mutted">No results</span>' : '') + '</div>';
      default:
        return '';
    }
  }

  const handleSelect = (value: any, data: any) => {
    if (data.guid === undefined) {
      return;
    }

    setTimeout(() => {
      setSearchTerm('');
    }, 100);
    
    switch (data.resourceGroup) {
      case 'invoice':
        history.push(`/app/${getEnv()}/invoice/view/${data.guid}`);
        break;
      case 'customer':
        // history.push(`/app/${getEnv()}/workbook/explorer/${(data.parentId || 0) > 0 ? data.parentId : data.id}/profile`);
        history.push(`/app/${getEnv()}/workbook/explorer/${data?.id}/profile`);
        break;
      case 'work_order':
        history.push(`/app/${getEnv()}/workorder/edit/${data.guid}`);
        break;
      case 'document':
        history.push(`/app/${getEnv()}/document/edit/${data.guid}`);
        break;
    }
  }

  return (
    <div className='search-all'>
      {isRole(ROLE.CLIENT)
        ? <></>
        : <>
          {isMobileOnly
            ? <AutoComplete
              style={{ width: '150px' }}
              size={isMobileOnly ? 'xs' : 'sm'}
              placeholder='Search anywhere'
              data={data}
              value={searchTerm}
              onChange={handleOnChange}
              // rsuite5
              renderMenuItem={(label: any, item: any) => {
                return (
                  <div key={item.guid} dangerouslySetInnerHTML={{ __html: getLineItem(item) }}></div>
                );
              }}
              filterBy={() => true}
              onSelect={handleSelect}
              onKeyUp={(e: any) => {
                if (e.keyCode === 13) {
                  if (searchTerm.trim().length < 3) {
                    toaster.push(
                      <Message type="error" showIcon closable>Provide at least 3 characters to search</Message>
                    );
                  } else {
                    history.push(`/app/${getEnv()}/workbook/customers?filter=${encodeURIComponent(searchTerm.trim())}&request=${uuidv4()}`);
                  }
                }
              }}
            />
            : <>
              <InputGroup style={{ width: '300px' }} inside>
                <AutoComplete
                  ref={autoCompleteRef}
                  size="sm"
                  placeholder='Search anywhere'
                  data={data}
                  value={searchTerm}
                  onChange={handleOnChange}
                  filterBy={() => true}
                  // rsuite5
                  renderMenuItem={(label: any, item: any) => {
                    if (item.label === 'No results found') {
                      return (
                        <div>{item.label}</div>
                      );
                    } else {
                      return (
                        <div key={item.guid} dangerouslySetInnerHTML={{ __html: getLineItem(item) }}></div>
                      );
                    }
                  }}
                  onSelect={handleSelect}
                  onKeyUp={(e: any) => {
                    if (e.keyCode === 13) {
                      autoCompleteRef.current.close();
                      const parts = searchTerm.split('__');
                      if (parts.length > 1) {
                        history.push(`/app/${getEnv()}/workbook/explorer/${parts[2]}/profile`);
                      } else if (parts[0].trim().length < 3) {
                        toaster.push(
                          <Message type="error" showIcon closable>Provide at least 3 characters to search</Message>
                        );
                      } else {
                        history.push(`/app/${getEnv()}/workbook/customers?filter=${encodeURIComponent(parts[0].trim())}&request=${uuidv4()}`);
                      }
                    }
                  }}
                />
                <InputGroup.Addon>
                  <SearchIcon />
                </InputGroup.Addon>
              </InputGroup>
            </>
          }
        </>
      }
    </div>
  );
}

export default SearchAll;
