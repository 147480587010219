import { startCase, uniq } from "lodash";

interface ILoadingState {
  state: STATUS
  message?: string
}

enum ROLE {
  ADMIN = 'admin',
  FRANCHISE = 'franchise',
  CREW = 'franchise',
  WORKER = 'worker',
  MANAGER = 'manager',
  CLIENT = 'client',
  VIEWER = 'viewer',
  CLEANER = 'cleaner'
};

enum STATUS {
  LOADING = 'loading',
  ERROR = 'error',
  LOADED = 'loaded',
  SAVING = 'saving',
  EMAIL_SENDING = 'email_sending',
  EMAIL_SENT = 'email_sent'
};

const FORMAT_DAY_DATE = 'ddd, DD MMM';
const FORMAT_MONTH_DATE = 'MMM D, YYYY';
const FORMAT_LONG_DATE = 'ddd, MMM D, YYYY';
const FORMAT_SHORT_DATE = 'MMM D, YYYY';
const FORMAT_ISO_DATE = 'YYYY-MM-DDTHH:mm:ssZ';

const FORMAT = {
  ISO_DATE: "yyyy-MM-dd",
  ISO_DATE_TIME: "yyyy-MM-dd'T'HH:mm:ss.SSSxxx",
  DB_DATE_TIME: "yyyy-MM-dd'T'HH:mm:ss.000'Z'",
  TIME: 'hh:mm a',
  TIME24: 'HH:mm',
  SHORT_DAY_TIME24: 'MMM d, HH:mm',
  DATE_TIME: 'MMMM d, yyyy hh:mm a',
  MONTH_DATE: 'MMM d, yyyy',
  MONTH_DATE_SHORT: 'MMM d, yy',
  DAY_DATE: 'EEE, dd MMM',
  DAY_MONTH_DATE: 'EEE, MMM d, yyyy',
  DAY_MONTH_TIME: 'EEE, MMM d, yyyy hh:mm a',
  DAY_MONTH_TIME24: 'EEE, MMM d, yyyy HH:mm',
  SHORT_DAY_MONTH_TIME: 'EEE, MMM d, yy hh:mm a',
  MONTH_YEAR: 'MMMM, yyyy',
  MONTH_YEAR_SHORT: 'MMM, yy'
};

const DRAWER = {
  ADVANCED_FILTER: 'ADVANCED_FILTER',
  COLUMN_CHOOSER: 'COLUMN_CHOOSER',
  COMPANY_FORM: 'COMPANY_FORM',
  CUSTOMER_FORM: 'CUSTOMER_FORM',
  CUSTOMER_VIEW: 'CUSTOMER_VIEW',
  CUSTOMER_MAP_VIEW: 'CUSTOMER_MAP_VIEW',
  CUSTOMER_MERGE: 'CUSTOMER_MERGE',
  DAILY_FINANCIALS_FORM: 'DAILY_FINANCIALS_FORM',
  DAILY_FINANCIALS_VIEW: 'DAILY_FINANCIALS_VIEW',
  DOCUMENT_FORM: 'DOCUMENT_FORM',
  EMAIL_FORM: 'EMAIL_FORM',
  EXPORT: 'EXPORT',
  FEEDBACK: 'FEEDBACK',
  STORAGE_FORM: 'STORAGE_FORM',
  STORAGE_SPACE_FORM: 'STORAGE_SPACE_FORM',
  WORK_ORDER_FORM: 'WORK_ORDER_FORM',
  WORK_ORDER_PHOTOS: 'WORK_ORDER_PHOTOS',
  WORK_ORDER_REPEAT: 'WORK_ORDER_REPEAT',
  WORK_ORDER_COMPARE: 'WORK_ORDER_COMPARE',
  INVOICE_FORM: 'INVOICE_FORM',
  INVOICE_VIEW: 'INVOICE_VIEW',
  INVOICE_ACCOUNT_STATEMENT: 'INVOICE_ACCOUNT_STATEMENT',
  IRRIGATION_SYSTEM_FORM: 'IRRIGATION_SYSTEM_FORM', 
  IRRIGATION_SYSTEM_VIEW: 'IRRIGATION_SYSTEM_VIEW', 
  JOB_LIST: 'JOB_LIST',
  RESOURCE_HISTORY: 'RESOURCE_HISTORY',
  USER_FORM: 'USER_FORM',
  USER_MY_PREFERENCE_FORM: 'USER_MY_PREFERENCE_FORM',
  SETTINGS_FIELD_FORM: 'SETTINGS_FIELD_FORM',
  SETTINGS_ACTIONS_FORM: 'SETTINGS_ACTIONS_FORM',
  SETTINGS_COMPANIES_FORM: 'SETTINGS_COMPANIES_FORM',
  NOTE_FORM: 'NOTE_FORM',
  PHOTO_UPLOAD_FORM: 'PHOTO_UPLOAD_FORM',
  ATTACHMENT_UPLOAD_FORM: 'ATTACHMENT_UPLOAD_FORM', 
  PNL_FORM: 'PNL_FORM',
  CASH_FLOW_FORM: 'CASH_FLOW_FORM',
  TIME_OFF_FORM: 'TIME_OFF_FORM',
  EMPLOYEE_FORM: 'EMPLOYEE_FORM',
  PAYROLL_FORM: 'PAYROLL_FORM',
  CLOCK_IN_OUT: 'CLOCK_IN_OUT',
  LARGE_ATTACHMENT_FORM: 'LARGE_ATTACHMENT_FORM',
  TIME_CARD_FORM: 'TIME_CARD_FORM',
  TENANT_FORM: 'TENANT_FORM',
  SITE_REVIEW_FORM: 'SITE_REVIEW_FORM',
  SITE_REVIEW_VIEW: 'SITE_REVIEW_VIEW'
}

const COLOR = {
  SUCCESS: '#5cb85c',
  DANGER: '#d9534f',
  WARNING: '#f0ad4e',
  LINK: '#337ab7'
};

const setHeaderTitle = (title: string) => {
  document.title = 'PrairieHQ | ' + title;
}

const hasMonerisIntegration = (companies: any): boolean => {
  return companies?.length > 0 && companies[0].integrations.moneris;
}

const hasStripeIntegration = (companies: any): boolean => {
  return companies?.length > 0 && companies[0].integrations.stripe;
}

const hasXeroIntegration = (companies: any): boolean => {
  return companies?.length > 0 && companies[0].integrations.xero;
}

const hasQuickBooksDesktopIntegration = (companies: any): boolean => {
  return companies?.length > 0 && companies[0].integrations.quickbooksDesktop;
}

const getEnv = () => {
  // return 'prod';

  if (window.location.hostname.includes('.app') || window.location.hostname.includes('.net') || window.location.hostname.includes('.local') || window.location.hostname.includes('localhost')) {
    return 'dev';
  }

  const path = window.location.pathname.split('/');
  return path.length > 3 ? path[2] : 'prod';
}

const getFieldPrintable = (
  fields: any,
  parentGroupKey: string,
  services: string[],
  otherServices: string,
  separator?: string,
  valueKey?: string,
  visibilityKey?: string,
  includeNotFound?: boolean
) => {
  if (visibilityKey === undefined) {
    visibilityKey = 'visibleOnBooking';
  }

  const groupFields = fields.filter((f: any) => f.parentKey === parentGroupKey && f[visibilityKey as any]);
  const servicesNotFound = ([] as any).concat(services);
  let otherServicesIncluded = false;

  const orderedServices = groupFields.map((f: any) => {
    if (services.map((s: any) => s.trim()).includes(f.key.trim())) {
      const index = servicesNotFound.indexOf(f.key);
      servicesNotFound.splice(index, 1);

      const value = f[valueKey || 'title'] + ((!otherServicesIncluded && f.allowFreeEntry && otherServices) ? ': ' + otherServices : '');
      otherServicesIncluded = otherServicesIncluded || f.allowFreeEntry === 't';
      return value;
    }

    return null;
  }).filter((f: any) => f);

  if (separator && includeNotFound) {
    return orderedServices.concat(uniq(servicesNotFound.map((s: any) => startCase(s)))).join(separator);
  } else if (separator) {
    return uniq(orderedServices).join(separator);
  }

  return orderedServices;
}

const getTenant = () => {
  let hostname = window.location.hostname.replace('.app', '');

  if (hostname.indexOf('bugaboo') > -1) {
    return {
      id: 'bugaboo',
      name: 'Bugaboo Landscaping Ltd.',
      client_id: '8cbed09a-2813-4153-8fcb-d87f676dcb96'
    };
  } else if (hostname.indexOf('assiniboine') > -1) {
    return {
      id: 'assiniboine',
      name: 'Assiniboine Landscaping Ltd.',
      // name: 'ABC Corp',
      client_id: '94a2657f-5276-4c06-beb3-d6213802335d'
    }
  } else if (hostname.indexOf('kaladikitchens') > -1) {
    return {
      id: 'kaladikitchens',
      name: 'Kaladi Kitchens Collective',
      client_id: '1b1bbb82-ef6f-451c-962a-45e8127ebff2'
    };
  } else if (hostname.indexOf('kaladiproperties') > -1) {
    return {
      id: 'kaladiproperties',
      name: 'Kaladi Properties',
      client_id: '1d22ae34-7b97-49ef-bcd9-e88a9f5cf9bb'
    };
  } else if (hostname.indexOf('snowpatch') > -1) {
    return {
      id: 'snowpatch',
      name: 'Bugaboo Landscaping Ltd (TEST - SNO)',
      client_id: 'ed6832d7-5c96-4d2c-ad2a-2bf4df1c1c37'
    };
  }  else if (hostname.indexOf('snowpatch') > -1) {
    return {
      id: 'pigeon',
      name: 'Bugaboo Landscaping Ltd (TEST - PIG)',
      client_id: 'ed6832d7-5c96-4d2c-ad2a-2bf4df1c1c34'
    };
  } else if (hostname.indexOf('app') > -1) {
    const parts = window.location.search.split('client_id%3D');
    const partsClientId = parts.length > 1 ? parts[1].split('&')[0] : 'app';

    return {
      id: 'app',
      name: 'PrairieHQ',
      client_id: partsClientId
    };
  } else {
    return {
      id: window.location.host.split('.')[0],
      name: window.location.host.split('.')[0],
      client_id: window.location.host.split('.')[0]
    };
  }
}

const parseNumber = (val: string | number | undefined): number => {
  if (typeof (val) === 'undefined') {
    return 0;
  } else if (typeof (val) === 'number') {
    if (isNaN(val)) {
      return 0;
    }

    return val;
  } else if (typeof (val) === 'string' && (val.replace(/[^\d.-]/g, '').trim().length === 0 || val.replace(/[^\d.-]/g, '').trim() === '-')) {
    return 0;
  } else if (typeof (val) === 'string') {
    return +val.replace(/[^\d.-]/g, '').trim();
  }

  return 0;
}

const getSortType = (sort: string) => {
  if (typeof (sort) === 'undefined') {
    return 'asc';
  } else if (sort === 'asc') {
    return 'desc';
  }

  return undefined;
}

let ACL_LIST = [
  'action:create',
  'action:delete',
  'action:list',
  'action:update',
  'billing:list',
  'cash-flow:list',
  'clock-in-out:create',
  'clock-in-out:list',
  'company:create',
  'company:list',
  'company:update',
  'customer:create',
  'customer:delete',
  'customer:list',
  'daily-financial:list',
  'document:create',
  'document:delete',
  'document:email',
  'document:list',
  'document:update',
  'document:update-cost',
  'document:update-description',
  'document:view',
  'email-activity:list',
  'employee:create',
  'employee:delete',
  'employee:list',
  'field:create',
  'field:delete',
  'field:list',
  'field:update',
  'invoice:delete',
  'invoice:update',
  'invoice:list',
  'integration:list',
  'integration:update',
  'job:calendar',
  'job:delete',
  'job:list',
  'job:order',
  'job:print-list',
  'job:schedule',
  'note:create',
  'note:delete',
  'note:list',
  'note:update',
  'notification:list',
  'notification:view',
  'payroll:list',
  'pnl:delete',
  'pnl:list',
  'profile:update',
  'report:view',
  'role:list',
  'role:update',
  'seasonal-financial:list',
  'storage:create',
  'storage:delete',
  'storage:list',
  'template:create',
  'template:delete',
  'template:list',
  'template:update',
  'time-off:list',
  'user:create',
  'user:delete',
  'user:impersonate',
  'user:list',
  'user:update',
  'worker:create',
  'worker:delete',
  'worker:impersonate',
  'worker:list',
  'worker:update',
  'work-order:create',
  'work-order:compare',
  'work-order:delete',
  'work-order:list'
];

export type {
  ILoadingState,
}

export {
  ACL_LIST,
  FORMAT,
  DRAWER,
  FORMAT_LONG_DATE,
  FORMAT_SHORT_DATE,
  FORMAT_ISO_DATE,
  FORMAT_DAY_DATE,
  FORMAT_MONTH_DATE,
  COLOR,
  ROLE,
  STATUS,
  setHeaderTitle,
  hasMonerisIntegration,
  hasStripeIntegration,
  hasXeroIntegration,
  hasQuickBooksDesktopIntegration,
  getEnv,
  getFieldPrintable,
  getTenant,
  parseNumber,
  getSortType
};
