import { useRef, useState } from 'react';
import { Form, Button, Schema } from 'rsuite';
import { Link } from 'react-router-dom';

const { StringType } = Schema.Types;

const model = Schema.Model({
  username: StringType().isRequired('This field is required')
});

interface IAuthForgotPasswordForm {
  history: any,
  processing: boolean,
  handleSubmit: (form: any, formValue: any) => void,
  setShowForgotPassword: () => void
}

const AuthForgotPasswordForm = ({
  history,
  processing,
  handleSubmit,
  setShowForgotPassword
}: IAuthForgotPasswordForm) => {
  let form: any = useRef();
  const [formError, setFormError] = useState<any>({});
  const [formValue, setFormValue] = useState<any>({
    username: ''
  })

  return (
    <Form
      fluid
      ref={(ref: any) => form = ref}
      formValue={formValue}
      formError={formError}
      onChange={setFormValue}
      onCheck={setFormError}
      model={model}
    >
      <Form.Group>
        <Form.ControlLabel>Username or email address</Form.ControlLabel>
        <Form.Control name="username" />
      </Form.Group>
      <Form.Group>
        <div className='text-center'>
          <Button size="sm" appearance="primary" loading={processing} onClick={() => handleSubmit(form, formValue)}>Reset password</Button>
          <div>
            <Button size="sm" onClick={setShowForgotPassword} appearance="link" 
              // rsuite5
              as={Link}
              to="/auth/reset">Sign in</Button>
          </div>
        </div>
      </Form.Group>
    </Form>
  );
}

export default AuthForgotPasswordForm;
