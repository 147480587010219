import startCase from 'lodash/startCase';
import { isMobileOnly } from 'react-device-detect';
import { Link } from 'react-router-dom';
import { Button, ButtonToolbar, Drawer, Tooltip, Whisper } from 'rsuite';
import LegacyExternalLinkIcon from "@rsuite/icons/legacy/ExternalLink";

interface IDrawerFormWrapper {
  children?: any,
  action: string,
  show: boolean | undefined,
  title?: string | undefined,
  form?: string | undefined,
  guid?: string | undefined,
  loading?: boolean | undefined,
  size?: string,
  enforceFocus?: boolean | undefined,
  saveDisabled?: boolean | undefined,
  additionalActions?: any,
  onHide?: () => void | undefined,
  onSave?: (stay: boolean) => Promise<void> | undefined,
  showSaveButton?: boolean
}

const DrawerFormWrapper = ({
  loading,
  action,
  children,
  show,
  title,
  form,
  guid,
  size,
  enforceFocus,
  onHide,
  onSave,
  saveDisabled,
  additionalActions,
  showSaveButton
}: IDrawerFormWrapper) => {
  const defaultProps: any = {};

  if (size === undefined || isMobileOnly) {
    defaultProps.size = 'full';
  } else {
    defaultProps.size = size;
  }

  return (
    <Drawer
      enforceFocus={enforceFocus === undefined ? true : enforceFocus}
      backdrop='static'
      {...defaultProps}
      open={show}
      onClose={onHide}>
      <Drawer.Header>
        <Drawer.Title>
          {startCase(title || 'New')}
          {!isMobileOnly &&
            <div style={{ float: 'right', marginTop: '-2px' }}>
              <Button appearance='subtle' size="xs" onClick={() => {
                const el = document.getElementById('feedback');
                if (el) {
                  el.click();
                }
              }}>{isMobileOnly ? '' : 'Feedback'}</Button>
              <span> </span>
              {form &&
                <Whisper placement="bottom" trigger="hover" speaker={<Tooltip>Open in New Window</Tooltip>}>
                  <Button
                    style={{ padding: '0 12px 0 0' }}
                    as={Link}
                    target="_blank"
                    appearance="subtle"
                    to={form}><LegacyExternalLinkIcon /></Button>
                </Whisper>
              }
            </div>
          }
        </Drawer.Title>
      </Drawer.Header>
      <Drawer.Body>
        {show &&
          <div>
            {children}
          </div>
        }
      </Drawer.Body>
      <Drawer.Footer>
        <ButtonToolbar>
          {(onSave && (action !== 'view' || showSaveButton === true)) && <Button 
            size="sm" 
            disabled={saveDisabled === undefined ? false : saveDisabled}
            loading={loading} 
            onClick={() => onSave && onSave(true)} 
            appearance="primary">Save</Button>}
          {additionalActions && additionalActions}
          <Button 
            size="sm" 
            disabled={loading} 
            onClick={onHide} 
            appearance={(action === 'view' && additionalActions === undefined) ? 'primary' : 'subtle'}>Close</Button>
        </ButtonToolbar>
      </Drawer.Footer>
    </Drawer>
  );
}

export default DrawerFormWrapper;
