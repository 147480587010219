import { useContext } from 'react';
import { ApplicationContext } from 'shared';
import { UsersMyPreferencesForm as UsersMyPreferencesFormContainer } from 'containers/users/components/UsersMyPreferencesForm';
import { UsersForm as UsersFormContainer } from 'containers/users/components/UsersForm';

const UsersMyPreferencesForm = () => {
  const {
    showDrawer
  } = useContext(ApplicationContext);

  return <UsersMyPreferencesFormContainer
    onHide={() => showDrawer(undefined)}
  />
}


const UsersForm = () => {
  const {
    showDrawer,
    drawerData,
    onUpdateDrawerCallback
  } = useContext(ApplicationContext);

  return <UsersFormContainer
    guid={drawerData.userGuid as any}
    onHide={() => showDrawer(false)}
    userlevel={drawerData.userlevel}
    onUpdate={onUpdateDrawerCallback}
  />
}

export {
  UsersMyPreferencesForm,
  UsersForm
}
