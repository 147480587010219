import { Fragment, useContext } from 'react';
import {
  AdvancedFilter,
  ColumnChooser,
  CustomerForm,
  CustomerView,
  DailyFinancialsForm,
  DailyFinancialsView,
  DocumentForm,
  EmailForm,
  InvoiceForm,
  InvoiceView,
  IrrigationSystemForm,
  JobList,
  WorkOrderForm,
  WorkOrderPhotos,
  WorkOrderRepeat,
  WorkOrderCompare,
  Exporter,
  ResourceHistory,
  CustomerMapView,
  StorageForm,
  StorageSpaceForm,
  UsersForm,
  UsersMyPreferencesForm,
  SettingsActionsForm,
  SettingsFieldForm,
  SettingsCompanyForm,
  CashFlowForm,
  EmployeeForm,
  PnlsForm,
  TimeOffForm,
  PayrollForm,
  ClockInOutForm,
  NoteForm,
  InvoiceAccountStatement,
  TimeCardForm,
  TenantForm
} from 'drawers';
import { ApplicationContext } from 'shared';
import { DRAWER } from 'lib/env';
import { IrrigationSystem } from 'containers/irrigation/IrrigationSystem';
import { CustomerMerge } from 'containers/customer';
import { SiteReviewForm, SiteReviewView } from 'containers/sitereview';
import { AttachmentDrawer } from 'components/attachment';

export const ApplicationDrawer = () => {
  const { drawerData, showDrawer, onUpdateDrawerCallback } = useContext(ApplicationContext);
  const onHide = () => showDrawer(undefined);
  const drawerProps = { ...drawerData, showDrawer, onUpdateDrawerCallback, onHide };

  const getDrawer = (drawer: string | undefined) => {
    switch (drawer) {
      case DRAWER.ADVANCED_FILTER:
        return <AdvancedFilter />;
      case DRAWER.COMPANY_FORM:
        return <SettingsCompanyForm />
      case DRAWER.CUSTOMER_FORM:
        return <CustomerForm />;
      case DRAWER.CUSTOMER_VIEW:
        return <CustomerView />;
      case DRAWER.CUSTOMER_MAP_VIEW:
        return <CustomerMapView />;
      case DRAWER.CUSTOMER_MERGE:
        return <CustomerMerge drawer show onHide={() => showDrawer(undefined)} onUpdate={onUpdateDrawerCallback} customerIds={drawerData?.customerIds} action={drawerData?.action} />
      case DRAWER.DAILY_FINANCIALS_FORM:
        return <DailyFinancialsForm />;
      case DRAWER.DAILY_FINANCIALS_VIEW:
        return <DailyFinancialsView />;
      case DRAWER.DOCUMENT_FORM:
        return <DocumentForm />;
      case DRAWER.EMAIL_FORM:
        return <EmailForm />;
      case DRAWER.INVOICE_FORM:
        return <InvoiceForm />;
      case DRAWER.INVOICE_VIEW:
        return <InvoiceView />;
      case DRAWER.INVOICE_ACCOUNT_STATEMENT:
        return <InvoiceAccountStatement />;
      case DRAWER.WORK_ORDER_FORM:
        return <WorkOrderForm />;
      case DRAWER.WORK_ORDER_PHOTOS:
        return <WorkOrderPhotos />
      case DRAWER.WORK_ORDER_REPEAT:
        return <WorkOrderRepeat />
      case DRAWER.WORK_ORDER_COMPARE:
        return <WorkOrderCompare />
      case DRAWER.EXPORT:
        return <Exporter />;
      case DRAWER.COLUMN_CHOOSER:
        return <ColumnChooser />
      case DRAWER.RESOURCE_HISTORY:
        return <ResourceHistory />;
      case DRAWER.STORAGE_FORM:
        return <StorageForm />;
      case DRAWER.STORAGE_SPACE_FORM:
        return <StorageSpaceForm />
      case DRAWER.USER_FORM:
        return <UsersForm />;
      case DRAWER.USER_MY_PREFERENCE_FORM:
        return <UsersMyPreferencesForm />;
      case DRAWER.SETTINGS_FIELD_FORM:
        return <SettingsFieldForm />;
      case DRAWER.SETTINGS_ACTIONS_FORM:
        return <SettingsActionsForm />
      // case DRAWER.PHOTO_UPLOAD_FORM:
      //   return <PhotoUploadForm />
      case DRAWER.PNL_FORM:
        return <PnlsForm />
      case DRAWER.CASH_FLOW_FORM:
        return <CashFlowForm />
      case DRAWER.TIME_OFF_FORM:
        return <TimeOffForm />
      case DRAWER.EMPLOYEE_FORM:
        return <EmployeeForm />;
      case DRAWER.PAYROLL_FORM:
        return <PayrollForm />;
      case DRAWER.CLOCK_IN_OUT:
        return <ClockInOutForm />;
      case DRAWER.NOTE_FORM:
        return <NoteForm />;
      case DRAWER.JOB_LIST:
        return <JobList />;
      case DRAWER.TIME_CARD_FORM:
        return <TimeCardForm />;
      case DRAWER.TENANT_FORM:
        return <TenantForm />
      case DRAWER.IRRIGATION_SYSTEM_FORM:
        return <IrrigationSystemForm drawer show onHide={() => showDrawer(undefined)} onUpdate={onUpdateDrawerCallback} customerId={drawerData?.customerId} action={'edit'} />
      case DRAWER.IRRIGATION_SYSTEM_VIEW:
        return <IrrigationSystem drawer onHide={() => showDrawer(undefined)} customerId={(drawerData as any).customerId} />
      case DRAWER.SITE_REVIEW_FORM:
        return <SiteReviewForm {...drawerData} drawer show onUpdate={onUpdateDrawerCallback} onHide={() => showDrawer(undefined)} />
      case DRAWER.SITE_REVIEW_VIEW:
        return <SiteReviewView {...drawerData} drawer show onUpdate={onUpdateDrawerCallback} onHide={() => showDrawer(undefined)} />
      case DRAWER.ATTACHMENT_UPLOAD_FORM:
        return <AttachmentDrawer {...drawerProps} />
      default:
        return <Fragment />;
    }
  }

  return getDrawer(drawerData?.drawer);
};

export default ApplicationDrawer;
