import { ApolloClient } from '@apollo/client';
import { queryFields } from 'gql/fields';
import orderBy from 'lodash/orderBy';
import { startCase } from 'lodash';

const getFieldsByParentKey = async (client: ApolloClient<object>, parentKey: string) => {
  const results = await client.query({
    query: queryFields,
    variables: {
      offset: 0,
      limit: 999
    },
    fetchPolicy: 'no-cache'
  });

  const parent = results.data.fields.edges.node.find((n: any) => n.key === parentKey);
  const fields = parent
    ? results.data.fields.edges.node
      .filter((f: any) => +f.parentId === +parent.id && f.isVisible)
      .map((f: any) => ({ ...f, sortOrder: +f.sortOrder }))
    : [];
  return orderBy(fields, 'sortOrder', 'asc');
}

const getAllFields = async (client: ApolloClient<object>) => {
  const results = await client.query({
    query: queryFields,
    variables: {
      offset: 0,
      limit: 999,
      sort: { sortOrder: 'ASC' }
    },
    fetchPolicy: 'no-cache'
  });

  const data = results.data.fields.edges.node
    .map((f: any) => ({ ...f, sortOrder: +f.sortOrder }));
  return orderBy(data, 'sortOrder', 'asc');
}

/*
 const getFields = (key: string, services?: string[]) => {
  const parent = window.ops.find((f: any) => f.title.toLowerCase() === key.toLowerCase());
  const fields = window.ops.filter((f: any) => +f.parent_id === +parent.id && f.is_visible).map((f: any) => ({ ...f, sort_order: +f.sort_order }));
  return orderBy(fields, 'sort_order', 'asc');
}
*/

const filterFieldsByParentKey = (fields: any, parentKey: string) => {
  parentKey = parentKey.replace(/\s/g, '_');
  const parent = fields.find((n: any) => n.key === parentKey);
  if (!parent) {
    return [];
  }

  if (parentKey === 'work_types') {
    return fields.filter((f: any) => +f.parentId === +parent.id && f.isVisible && f.applicationGroup);
  } else {
    return fields.filter((f: any) => +f.parentId === +parent.id && f.isVisible);
  }
}

const fieldTitle = (lookup: any, fields: any): string[] => {
  if (!Array.isArray(lookup)) {
    lookup = [lookup];
  }

  return lookup
    .filter((f: any) => f && f.trim().length > 0)
    .map((key: any) => {
      const field = fields.find((f: any) => f.key === key);
      return field ? startCase(field.title).trim() : '';
    })
  .filter((f: any) => f.length > 0);
}

export {
  getFieldsByParentKey,
  getAllFields,
  filterFieldsByParentKey,
  fieldTitle
}
