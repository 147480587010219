import { parseNumber } from 'lib/env';
import { Fragment, useState } from 'react';
import { Col, IconButton, Input, InputGroup, Row } from 'rsuite';
import LegacyPlusIcon from "@rsuite/icons/legacy/Plus";
import LegacyTrashIcon from "@rsuite/icons/legacy/Trash";

interface ICashFlowFormCashLineItem {
  group: string,
  guid: string,
  description: string,
  amount: string,
  readOnly?: boolean | undefined,
  colDescriptionWidth: number,
  colAmountWidth: number,
  onBlur: (group: string, guid: string, description: string, amount: string) => void,
  onAdd: (group: string) => void,
  onRemove: (group: string, guid: string) => void,
  onChange: (group: string, guid: string, amount: number) => void
}

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

const CashFlowFormCashLineItem = ({
  group,
  guid,
  description,
  amount,
  readOnly,
  colDescriptionWidth,
  colAmountWidth,
  onBlur,
  onAdd,
  onRemove,
  onChange
}: ICashFlowFormCashLineItem) => {
  const [formDescription, setFormDescription] = useState(description || '');
  const [formAmount, setFormAmount] = useState(amount || '');

  return (
    <Fragment>
      <Row className={'mb-5'}>
        <Col md={colDescriptionWidth}>
          <Input
            readOnly={readOnly}
            value={formDescription}
            onChange={setFormDescription}
            onBlur={() => onBlur(group, guid, formDescription, formAmount)}
          />
        </Col>
        <Col md={colAmountWidth}>
          <InputGroup inside style={{ width: '100%' }}>
            <InputGroup.Addon>$</InputGroup.Addon>
            <Input
              readOnly={readOnly}
              value={formAmount}
              onChange={(val) => {
                setFormAmount(val);
                onChange(group, guid, parseNumber(val));
              }}
              onBlur={(e: any) => {
                setFormAmount(formatter.format(parseNumber(e.target.value)).replace('$', ''));
                onBlur(group, guid, formDescription, parseNumber(e.target.value).toString());
              }}
            />
          </InputGroup>
        </Col>
        {!readOnly &&
          <Col md={2}>
            <IconButton
              size="xs"
              appearance="link"
              onClick={() => onAdd(group)} icon={<LegacyPlusIcon />}
            />
            <IconButton
              size="xs"
              appearance="link"
              onClick={() => onRemove(group, guid)} icon={<LegacyTrashIcon />}
            />
          </Col>
        }
      </Row>
    </Fragment>
  );
}

export default CashFlowFormCashLineItem;
