import { Fragment, useMemo } from 'react';
import { Table, List, Loader } from 'rsuite';
import { v4 as uuidv4 } from 'uuid';
import { BrowserView, MobileView } from 'react-device-detect';
import { useTable, useFlexLayout } from 'react-table'
import { defaultColumns } from 'drawers/Table';
import omit from 'lodash/omit';
import useSessionStorage from 'lib/hooks/useSessionStorage';

const SortNeutral = () => <svg width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" aria-hidden="true" focusable="false" className="rs-table-cell-header-icon-sort rs-icon" aria-label="sort" data-category="direction"><path d="M7.616 3.18a.5.5 0 01.696-.071l.072.071 2.5 3a.5.5 0 01-.704.704l-.064-.064L8 4.281 5.884 6.82a.5.5 0 01-.63.115l-.074-.051a.5.5 0 01-.115-.63l.051-.074 2.5-3zM7.616 12.82a.5.5 0 00.696.071l.072-.071 2.5-3a.5.5 0 00-.704-.704l-.064.064L8 11.719 5.884 9.18a.5.5 0 00-.63-.115l-.074.051a.5.5 0 00-.115.63l.051.074 2.5 3z"></path></svg>;
const SortAsc = () => <svg width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" aria-hidden="true" focusable="false" className="rs-table-cell-header-icon-sort rs-table-cell-header-icon-sort-asc rs-icon" aria-label="sort up" data-category="direction"><path d="M8 3c.276 0 .526.112.707.293l3 3a.999.999 0 11-1.414 1.414L9 6.414V12a1 1 0 01-2 0V6.414L5.707 7.707a.999.999 0 11-1.414-1.414l3-3A.997.997 0 018 3z"></path></svg>;
const SortDesc = () => <svg width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" aria-hidden="true" focusable="false" className="rs-table-cell-header-icon-sort rs-table-cell-header-icon-sort-desc rs-icon" aria-label="sort down" data-category="direction"><path d="M8 13a.997.997 0 01-.707-.293l-3-3a.999.999 0 111.414-1.414L7 9.586V4a1 1 0 012 0v5.586l1.293-1.293a.999.999 0 111.414 1.414l-3 3A.997.997 0 018 13z"></path></svg>;

// const { Column, HeaderCell, Cell } = Table;

// const EditableColumn = ({ props }: any) => {
//   return <Column>

//   </Column>;
// }

const ResponsiveTable = (currentProps: any) => {
  const props = omit(currentProps, 'tableDataRef');
  const [visibleColumns] = useSessionStorage(props?.filterKey + 'Columns', []);
  const columnVisible = (key: string) => {
    if (!key || !props?.filterKey || !defaultColumns[props?.filterKey]) {
      return true;
    }

    if (visibleColumns.length === 0) {
      return defaultColumns[props.filterKey].includes(key);
    }

    return visibleColumns.includes(key);
  }

  const mobileChildren = (Array.isArray(props.children.flat()) ? props.children.flat().filter((c: any) => c) : [props.children.flat().filter((c: any) => c)])
    .filter((t: any) => columnVisible(t.props.children[1].props['data-column'] || t.props.children[1].props.column));

  // everything in rsuite tables needs header and cell
  // child 0 - header
  // child 1 - cell
  const tableChildren = mobileChildren.filter((c: any) => c?.props?.children.length > 1);

  const columns: any = useMemo(() => {
    const children = tableChildren.map((t: any) => {
      if (Array.isArray(t.props.children) && typeof (t.props.children[0].props.children) === 'string') {
        return {
          ...t.props,
          Header: t.props.children[0].props.children,
          id: uuidv4(),
          width: t.props.width,
          className: t.props.children[1].props.className || '',
          dataKey: t.props.children[1].props.dataKey
        };
      } else {
        return (props.data.length === 0 || props.loading)
          ? undefined
          : {
            ...t.props,
            id: uuidv4(),
            Header: () => t.props.children[0].props.children,
            width: t.props.width,
            className: t.props.children[1].props.className || '',
            dataKey: t.props.children[1].props.dataKey
          }
      }
    }).filter((t: any) => t);

    if (currentProps.tableDataRef) {
      currentProps.tableDataRef.current = children;
    }
    return children;
  }, [props.data, props.loading]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    prepareRow
  } = useTable(
    {
      columns,
      data: props.data
    },
    useFlexLayout
  );

  return <Fragment>
    <BrowserView>
      {props.html5
        ? <table className="html5" {...getTableProps()}>
          {(currentProps?.showHeader === undefined || currentProps?.showHeader === true) &&
            <thead>
              {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column: any) => (
                    <th {...column.getHeaderProps({ className: column.className })}>
                      {column.sortable
                        ? <div className="rs-table-cell-header rs-table-cell-header-sortable" style={{ cursor: 'pointer' }} onClick={(e: any) => {
                          e.preventDefault();
                          props.onSortColumn(column.dataKey);
                        }}>
                          {column.render('Header')}

                          <span className="rs-table-cell-header-sort-wrapper">
                            {(props.sortColumn === column.dataKey)
                              ? props.sortType === 'asc' ? <SortAsc /> : <SortDesc />
                              : <SortNeutral />
                            }
                          </span>
                        </div>
                        : <span>{column.render('Header')}</span>
                      }
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
          }
          <tbody {...getTableBodyProps()}>
            {(rows.length === 0 || props.loading)
              ? <tr>
                <td className="text-center no-hover">
                  {props.loading ? <Loader content="Loading..." /> : <div className="p-10">No data found</div>}
                </td>
              </tr>
              : rows.map((row: any) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells
                      .map((cell: any, index: number) => {
                        const c = mobileChildren[index];

                        return <td {...cell.getCellProps({ className: cell.column.className })}>
                          {c.props.children[1].props.children
                            ? c.props.children[1].props.children(row.original)
                            : row.original[c.props.children[1].props.dataKey]
                          }
                        </td>
                      })}
                  </tr>
                )
              }
              )}
          </tbody>
          {props.footer &&
            <tfoot>
              {footerGroups.map(footerGroup =>
                (props.footer || []).map((row: any, rowIndex: number) =>
                  <tr {...footerGroup.getFooterGroupProps()}>
                    {footerGroup.headers.map((column: any, colIndex: number) => (
                      <th {...column.getFooterProps({ className: column.className })}>
                        {props.footer?.[rowIndex]?.[colIndex] || ''}
                      </th>
                    ))}
                  </tr>
                )
              )}
            </tfoot>
          }

        </table>
        : <Table {...omit(props, 'filterKey')} autoHeight rowHeight={40}>
          {props.children.flat().filter((t: any) => t && columnVisible(t.props.children[1].props['data-column'] || t.props.children[1].props.column))}
        </Table>
      }
    </BrowserView>
    <MobileView>
      {props.loading
        ? <Loader content="Loading..." />
        : <Fragment>
          {props.data.map((d: any, index: number) =>
            <div
              key={d.guid + '-' + index}
              className={'mb-12 pb-0 card'}
              style={{ backgroundColor: '#fff' }}
            >
              <List size="sm">
                {mobileChildren.map((c: any) => c &&
                  <List.Item key={uuidv4()}>
                    {c.props.children[0].props.children.length > 1 && <strong>{c.props.children[0].props.children}:&nbsp;</strong>}
                    {c.props.children[1].props.children
                      ? <Fragment>{c.props.children[1].props.children(d)}</Fragment>
                      : <Fragment>{props.data[index][c.props.children[1].props.dataKey]}</Fragment>
                    }
                  </List.Item>
                )}
              </List>
            </div>
          )}
        </Fragment>
      }
    </MobileView>
  </Fragment >;
};

export default ResponsiveTable;

