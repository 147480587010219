import axios from 'axios';
import axiosRetry from 'axios-retry';
import { IS_LOCALHOST } from './tenant';
import { v4 as uuidv4 } from 'uuid';
axiosRetry(axios, { retries: 3 });

const useApi = () => {
  let baseUrl = `https://${window.location.host}/api`;
  let baseUrlV2 = `https://${window.location.host}/api/v2`;

  if (IS_LOCALHOST) {
    baseUrl = `https://assiniboine.prairiehq.net/api`;
  }

  let headers: any = {
    'Content-Type': 'application/json'
  }

  let options: any = {
    withCredentials: true,
    headers
  };

  return {
    baseUrl,
    options,

    download: async (path: string) => {
      return baseUrl + path;
    },

    get: async (path: string, headers?: any) => {
      const response = await axios(`${baseUrl}${path}`, { ...headers || {}, 'x-request-id': uuidv4() });
      return response.data;
    },

    getV2: async (path: string, headers?: any) => {
      const response = await axios(`${baseUrlV2}${path}`, { ...headers || {}, 'x-request-id': uuidv4() });
      return response.data;
    },

    getRaw: async (path: string) => {
      const response = await axios(`${path}`);
      return response.data;
    },

    post: async (path: string, data: any) => {
      const response: any = await axios(`${baseUrl}${path}`, {
        method: 'POST',
        data,
        withCredentials: true,
        headers: { ...headers, 'x-request-id': uuidv4() }
      });

      return response.data;
    },

    postConvertHeic: async (data: any) => {
      const response = await axios.post(`${baseUrl}/photos/convert/heic/jpg`, data, {
        responseType: 'blob',
        withCredentials: true
      });
      return response.data;
    },

    postJpgBlob: async (data: any) => {
      const response = await axios.post(`${baseUrl}/photos/convert/uri/jpg`, data, {
        responseType: 'blob',
        withCredentials: true
      });
      return response.data;
    },

    postS3: async (url: any, data: any, config: any) => {
      const response = await axios.post(url, data, config);
      return response;
    }
  }
}

export default useApi;
