import { useEffect, useState } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { Input, Drawer, Button } from 'rsuite';

interface INotes {
  show: boolean,
  handleHideDrawer: () => void,
  guid: string,
  notes: string,
  title: string,
  handleJobUpdate: (guid: string, data: any) => void
}

const Notes = ({
  guid,
  notes,
  show,
  title,
  handleHideDrawer,
  handleJobUpdate
}: INotes) => {
  const [text, setText] = useState<string | undefined>(notes);

  useEffect(() => {
    setText(notes || undefined);
  }, [notes]);

  return (
    <Drawer
      open={show}
      onClose={() => {
        handleHideDrawer();
        setText(undefined);
      }}
      size={isMobileOnly ? 'full' : 'sm'}
    >
      <Drawer.Header>
        <Drawer.Title>{title || ''}</Drawer.Title>
      </Drawer.Header>
      <Drawer.Body>
        <Input
          as={'textarea'}
          rows={15}
          style={{ width: '100%' }}
          placeholder={'Enter job notes'}
          value={text}
          onChange={setText}
        />
      </Drawer.Body>
      <Drawer.Footer>
        <Button size="sm" onClick={() => {
          handleJobUpdate(guid, { notes: text });
          setText(undefined);
        }} appearance="primary">Save</Button>
        <Button size="sm" onClick={() => {
          handleHideDrawer();
          setText(undefined);
        }} appearance="subtle">Close</Button>
      </Drawer.Footer>
    </Drawer>
  );
}

export default Notes;
