import { useState } from 'react';
import {
  Modal,
  Button,
  ButtonToolbar,
  Col,
  SelectPicker,
  Drawer,
  Form,
  DateRangePicker,
  Grid,
  Row,
} from 'rsuite';
import { FORMAT, getEnv } from 'lib/env';
import { addMonths, format, subMonths } from 'date-fns';
import { addYears, subYears } from 'date-fns/esm';
import { useFilteredParams } from 'shared/FilterProvider';
import { isMobileOnly } from 'react-device-detect';

interface IWorkOrderCompare {
  onHide: () => void,
  onUpdate: (data: any) => void
}

const WorkOrderCompare = ({
  onUpdate,
  onHide
}: IWorkOrderCompare) => {
  const filterKey = 'workOrdersCompare';
  const { params } = useFilteredParams(filterKey);
  const [firstRange, setFirstRange] = useState<any>(params.compareFirstRange ? params.compareFirstRange : [new Date(), new Date()]);
  const [secondRange, setSecondRange] = useState<any>(params.compareSecondRange ? params.compareSecondRange : undefined);
  const [compareDateColumn, setCompareDateColumn] = useState<any>(params.compareDateColumn || 'startDate');

  return (
    <Drawer backdrop='static' size="sm" full={isMobileOnly} open onClose={onHide}>
      <Drawer.Header><Drawer.Title>Compare Work Orders</Drawer.Title></Drawer.Header>
      <Drawer.Body>
        <p className='mb-12'>Provide ranges in which too compare work orders:</p>
        <Grid fluid>
          <Row>
            <Col xs={24} md={12}>
              <Form fluid>
                <Form.Group>
                  <Form.ControlLabel>Date Column:</Form.ControlLabel>
                  <Form.Control
                    name="compareDateColumn"
                    accepter={SelectPicker}
                    block
                    value={compareDateColumn}
                    onSelect={setCompareDateColumn}
                    searchable={false}
                    cleanable={false}
                    data={[
                      { value: 'startDate', label: 'Start Date' },
                      { value: 'endDate', label: 'End Date' },
                      { value: 'startEndInclusive', label: 'Overlaps Start/End' },
                    ]} />
                </Form.Group>

                <Form.Group>
                  <Form.ControlLabel>First Range:</Form.ControlLabel>
                  <Form.Control
                    name="firstRange"
                    block
                    cleanable={false}
                    value={firstRange}
                    accepter={DateRangePicker}
                    renderValue={(val: any) => format(val[0], FORMAT.DAY_MONTH_DATE) + ' ~ ' + format(val[1], FORMAT.DAY_MONTH_DATE)}
                    onChange={setFirstRange} />
                </Form.Group>
                <Form.Group>
                  <Form.ControlLabel>Second Range (to compare):</Form.ControlLabel>
                  <Form.Control
                    name="secondRange"
                    block
                    readOnly={!firstRange}
                    value={secondRange}
                    accepter={DateRangePicker}
                    renderValue={(val: any) => format(val[0], FORMAT.DAY_MONTH_DATE) + ' ~ ' + format(val[1], FORMAT.DAY_MONTH_DATE)}
                    onChange={setSecondRange}
                    ranges={[
                      {
                        label: 'Previous Month',
                        value: [
                          subMonths(firstRange[0], 1),
                          subMonths(firstRange[1], 1),
                        ]
                      },
                      {
                        label: 'Next Month',
                        value: [
                          addMonths(firstRange[0], 1),
                          addMonths(firstRange[1], 1),
                        ]
                      },
                      {
                        label: 'Previous Year',
                        value: [
                          subYears(firstRange[0], 1),
                          subYears(firstRange[1], 1),
                        ]
                      },
                      {
                        label: 'Next Year',
                        value: [
                          addYears(firstRange[0], 1),
                          addYears(firstRange[1], 1),
                        ]
                      }
                    ]} />
                  {!firstRange &&
                    <Form.HelpText>Select first range first</Form.HelpText>
                  }
                </Form.Group>
              </Form>
            </Col>
          </Row>
        </Grid>
      </Drawer.Body>
      <Drawer.Footer>
        <ButtonToolbar>
          <Button
            onClick={() => {
              onUpdate({ firstRange, secondRange, compareDateColumn });
              onHide();
            }}
            appearance="primary"
            disabled={!firstRange && !secondRange}>Compare</Button>
          <Button onClick={onHide} appearance="subtle">Close</Button>
        </ButtonToolbar>
      </Drawer.Footer>
    </Drawer>
  );
}

export { WorkOrderCompare };
