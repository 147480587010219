import { Fragment, useContext, useEffect, useState } from 'react';

import {
  Uploader,
  Button,
  Input,
  SelectPicker,
  Table,
  Form,
  Whisper,
  Tooltip,
  Progress,
  IconButton,
  Message,
  Stack,
} from 'rsuite';

import { camelCase } from 'lodash';
import fileSize from 'filesize';
import { filterFieldsByParentKey } from 'lib/helpers/field';
import { useViewport } from 'shared/ViewportProvider';
import { ApplicationContext, ResponsiveTable } from 'shared';
import IconButtonWrapper from 'shared/IconButtonWrapper';
import { gql, useApolloClient } from '@apollo/client';
import { useApi } from 'lib';
import LargeAttachmentForm from './LargeAttachmentForm';
import { getEnv } from 'lib/env';
import Icon from '@rsuite/icons/lib/Icon';
import { MdDelete, MdRefresh } from 'react-icons/md';
import { IS_BUGABOO } from 'lib/tenant';
import loadImage from 'blueimp-load-image';
import axios from 'axios';
const { Column, HeaderCell, Cell } = Table;
const { Line } = Progress;

interface IAttachmentForm {
  preUploadAction?: 'photoResize',
  destination?: string
  listType?: 'list' | 'form' | 'simple-form',
  viewMode?: 'table' | 'gallery',
  applicationGroup?: string,
  referenceGuid: string,
  attachments: any[],
  onChange: (attachments: any) => void,
  showVisibility?: boolean | undefined,
  showType?: boolean | undefined,
  showSize?: boolean | undefined,
  showList?: boolean | undefined,
  showUploadLargeFile?: boolean | undefined,
  fileGroup?: string | undefined,
  type?: string | undefined,
  handleTypeFilter?: (attachment: any) => boolean | undefined,
  disabled?: boolean | undefined,
  limitAlert?: number | undefined // in bytes
  bucket?: string | undefined // attachments | media,
}

const AttachmentForm = ({
  destination,
  listType,
  referenceGuid,
  attachments,
  applicationGroup,
  showVisibility,
  showUploadLargeFile,
  showSize,
  showType,
  fileGroup,
  disabled,
  showList,
  limitAlert,
  bucket,
  type,
  handleTypeFilter,
  viewMode,
  onChange,
  preUploadAction
}: IAttachmentForm) => {
  const client = useApolloClient();
  const api = useApi();
  const { showConfirmation } = useContext(ApplicationContext);
  const { state } = useViewport();
  const [formAttachments, setFormAttachments] = useState<any>([]);
  const [uploading, setUploading] = useState(false);
  const [percent, setPercent] = useState(0);
  const [uploadLargeFile, setUploadLargeFile] = useState(false);
  listType = listType === undefined ? 'form' : listType;

  useEffect(() => {
    setFormAttachments(attachments);
  }, [attachments]);

  const handleDescriptionUpdate = (guid: string, description: string) => {
    const nextFormAttachments = formAttachments.map((f: any) => {
      if (f.guid === guid) {
        f.description = description;
      }

      return f;
    });

    setFormAttachments(nextFormAttachments);
    onChange(nextFormAttachments);
  }

  const handleVisibilityUpdate = (guid: string, visibility: string) => {
    const nextFormAttachments = formAttachments.map((f: any) => {
      if (f.guid === guid) {
        f.visibility = visibility;
      }

      return f;
    });

    setFormAttachments(nextFormAttachments);
    onChange(nextFormAttachments);
  }

  const handleTypeUpdate = (guid: string, type: string) => {
    const nextFormAttachments = formAttachments.map((f: any) => {
      if (f.guid === guid) {
        f.type = type;
      }

      return f;
    });

    setFormAttachments(nextFormAttachments);
    onChange(nextFormAttachments);
  }

  const handleRemoveFile = (guid: string) => {
    const nextFormAttachments = [].concat(formAttachments);
    const removeIndex = nextFormAttachments.findIndex((a: any) => a.guid === guid);
    nextFormAttachments.splice(removeIndex, 1);
    setFormAttachments(nextFormAttachments);
    onChange(nextFormAttachments);
  }

  return (
    <Fragment>
      {(limitAlert && formAttachments.reduce((p: any, n: any) => p += n.fileSize, 0) > limitAlert) &&
        <Message className='mb-12' type='error'>Total size of attachments exceeds {fileSize(limitAlert)}</Message>
      }

      {!disabled &&
        <Uploader
          autoUpload={preUploadAction === 'photoResize' ? false : true}
          size='sm'
          fileList={[]}
          listType={"text"}
          draggable
          appearance='ghost'
          multiple
          withCredentials
          action={`${api.baseUrl}/attachments/${referenceGuid}/${bucket || 'attachments'}`}
          data={{
            destination,
            applicationGroup,
            fileGroup,
            type
          }}
          onProgress={(percent) => {
            setUploading(true);
            setPercent(percent);
          }}
          onSuccess={(data: any) => {
            setUploading(false);
            const result = Object.keys(data.result).reduce((p: any, n: string) => {
              p[camelCase(n)] = data.result[n];
              return p;
            }, {});

            const nextFormAttachments = [].concat(formAttachments).concat(result);
            onChange(nextFormAttachments);

            if (showList !== false) {
              setFormAttachments(nextFormAttachments);
            }
          }}
          onChange={async (photos: any, event: any) => {
            setUploading(true);
            if (preUploadAction === 'photoResize') {
              for (const photo of photos) {
                try {
                  let file: any = photo.blobFile;
                  let orientation = 1;

                  if (file.type.toLowerCase() === 'image/heic') {
                    let formData = new FormData();
                    formData.append('file', file);
                    file = await api.postConvertHeic(formData);
                  }

                  const data: any = await loadImage(file, { meta: true, maxWidth: 1000, maxHeight: 1000, canvas: true, orientation });
                  const blob: any = await new Promise(resolve => data.image.toBlob(resolve, file.type));
                  let formData = new FormData();
                  formData.append('file', blob, file.name);

                  if (destination) {
                    formData.append('destination', destination);
                  }

                  if (applicationGroup) {
                    formData.append('applicationGroup', applicationGroup);
                  }

                  if (fileGroup) {
                    formData.append('fileGroup', fileGroup);
                  }

                  if (type) {
                    formData.append('type', type);
                  }

                  const response: any = await axios({ 
                    method: 'post', 
                    url: `${api.baseUrl}/attachments/${referenceGuid}/${bucket || 'attachments'}`, 
                    data: formData, 
                    headers: { 'Content-Type': 'multipart/form-data' } 
                  });

                  const result = Object.keys(response?.data?.result).reduce((p: any, n: string) => {
                    p[camelCase(n)] = response?.data?.result[n];
                    return p;
                  }, {});

                  const nextFormAttachments = [].concat(formAttachments).concat(result);
                  onChange(nextFormAttachments);

                  if (showList !== false) {
                    setFormAttachments(nextFormAttachments);
                  }
                } catch (err) {
                  console.log(err);
                  setUploading(false);
                  event.target.value = null;
                }
              }
            }
            setUploading(false);
            event.target.value = null;
          }}
        />
      }
      {uploading && <Line className="mt-10" percent={percent} showInfo={false} strokeWidth={2} />}


      {(formAttachments || []).length > 0 &&
        <>
          {viewMode === 'gallery'
            ? <div className='mt-8'>
              <Stack wrap spacing={6} style={{ alignItems: 'top' }}>
                {formAttachments.filter((a: any) => handleTypeFilter === undefined ? true : handleTypeFilter(a)).map((file: any) =>
                  <div key={file.guid}>
                    <div>
                      <div style={{ width: '100%', position: 'relative' }}>
                        <IconButtonWrapper
                          style={{ position: 'absolute', right: '2px', top: '2px', backgroundColor: 'rgba(255, 255, 255, 0.7)' }}
                          color={'red'}
                          disabled={disabled || file?.protected}
                          onClick={async () => {
                            showConfirmation('Are you sure you want to remove this file? This action cannot be undone.', 'Remove File', async () => {
                              await client.mutate({
                                mutation: gql`mutation deleteAttachment($guid: ID!) { deleteAttachment(guid: $guid) { success, code, message }}`,
                                variables: { guid: file.guid }
                              });
                              handleRemoveFile(file.guid);
                            });
                          }}
                          appearance="link"
                          icon={'delete'}
                        />
                      </div>
                      <a href={`https://${window.location.host}/api/attachments/download/${file.guid}`} key={file.guid}>
                        <img src={`https://${window.location.host}/api/attachments/download/${file.guid}`} style={{ width: '270px' }} />
                      </a>
                    </div>
                    <div className='mt-4'>
                      <Input
                        size="xs"
                        defaultValue={file.description}
                        disabled={disabled}
                        onBlur={(e: any) => handleDescriptionUpdate(file.guid, e.target.value)}
                        placeholder='Description'
                      />
                    </div>
                  </div>
                )}
              </Stack>
            </div>
            : <ResponsiveTable loading={uploading} autoHeight={true} rowHeight={40} data={formAttachments} html5>
              <Column flexGrow={1}>
                <HeaderCell>File</HeaderCell>
                <Cell>{(file: any) => <Button size="sm" style={{ padding: 0 }} appearance="link" onClick={() =>
                  window.location.href = `https://${window.location.host}/api/attachments/download/${file.guid}`}>
                  {file.fileName}
                  {showSize && <span> ({fileSize(file.fileSize || 0)})</span>}
                </Button>
                }
                </Cell>
              </Column>

              {listType === 'form' &&
                <Column flexGrow={2}>
                  <HeaderCell>Description</HeaderCell>
                  <Cell>
                    {(file: any) => <Input size="xs" defaultValue={file.description} disabled={disabled} onBlur={(e: any) => handleDescriptionUpdate(file.guid, e.target.value)} />}
                  </Cell>
                </Column>
              }

              {((showType === undefined || showType === true) && listType === 'form') &&
                <Column flexGrow={1}>
                  <HeaderCell>Type</HeaderCell>
                  <Cell>
                    {(file: any) => <SelectPicker
                      block
                      size="xs"
                      cleanable={false}
                      defaultValue={(file as any).type || ''}
                      searchable={false}
                      disabled={disabled}
                      data={filterFieldsByParentKey(state.fields, 'attachment_types').map((f: any) => ({
                        value: f.key,
                        label: f.title
                      }))}
                      onChange={(val: any) => handleTypeUpdate(file.guid, val)}
                    />}
                  </Cell>
                </Column>
              }

              {((showVisibility === undefined || showVisibility === true) && listType === 'form') &&
                <Column flexGrow={1}>
                  <HeaderCell>Visibility</HeaderCell>
                  <Cell>{(file: any) => <span>
                    <SelectPicker
                      block
                      size="xs"
                      disabled={disabled}
                      cleanable={false}
                      defaultValue={(file as any).visibility || 'private'}
                      searchable={false}
                      data={([
                        { value: 'public', label: 'Public' },
                        { value: 'private', label: 'Private' }
                      ] as any)
                        .concat(window.location.pathname.includes('document') ? { value: 'add_to_document', label: 'Add To Document' } : null)
                        .filter((d: any) => d)}
                      onChange={(val: any) => handleVisibilityUpdate(file.guid, val)}
                    />
                  </span>
                  }
                  </Cell>
                </Column>
              }

              <Column width={50}>
                <HeaderCell className="link-group text-right">
                  {['simple-form', 'form'].includes(listType)
                    ? <Whisper placement="bottom" trigger="hover" speaker={<Tooltip>Remove All</Tooltip>}>
                      <IconButton
                        disabled={disabled}
                        onClick={() => {
                          showConfirmation('Are you sure you want to remove all files? This action cannot be undone.', 'Remove Files', async () => {
                            await client.mutate({
                              mutation: gql`mutation deleteResourceAttachment($guid: ID!) { deleteResourceAttachment(guid: $guid) { success, code, message }}`,
                              variables: { guid: referenceGuid }
                            });

                            setFormAttachments([]);
                            onChange([]);
                          });
                        }}
                        appearance="link"
                        icon={<Icon as={MdDelete} style={{ color: "#ff0000" }} />}
                      />
                    </Whisper>
                    : <span>&nbsp;</span>
                  }
                </HeaderCell>
                <Cell className="link-group text-right">
                  {(file: any) => <Fragment>
                    {['simple-form', 'form'].includes(listType as any)
                      ? <>
                        {getEnv() === 'dev' &&
                          <Whisper placement="bottom" trigger="hover" speaker={<Tooltip>Refresh Properties</Tooltip>}>
                            <IconButton appearance='link' icon={<Icon as={MdRefresh} />} onClick={async () => {
                              const result = await api.get(`/attachments/refresh/${file.guid}`);
                              setFormAttachments(formAttachments.map((a: any) => {
                                if (a.guid === file.guid) {
                                  a.fileSize = result.result.size;
                                }
                                return a;
                              }));
                            }} />
                          </Whisper>
                        }
                        <Whisper placement="bottom" trigger="hover" speaker={<Tooltip>Remove</Tooltip>}>
                          <IconButtonWrapper
                            disabled={disabled || file?.protected}
                            onClick={async () => {
                              showConfirmation('Are you sure you want to remove this file? This action cannot be undone.', 'Remove File', async () => {
                                await client.mutate({
                                  mutation: gql`mutation deleteAttachment($guid: ID!) { deleteAttachment(guid: $guid) { success, code, message }}`,
                                  variables: { guid: file.guid }
                                });
                                handleRemoveFile(file.guid);
                              });
                            }}
                            appearance="link"
                            icon={'delete'}
                          />
                        </Whisper>
                      </>
                      : <>
                        <Whisper placement="bottom" trigger="hover" speaker={<Tooltip>Download</Tooltip>}>
                          <IconButtonWrapper
                            disabled={disabled}
                            onClick={() => {
                              window.location.href = `https://${window.location.host}/api/attachments/download/${file.guid}`;
                            }}
                            appearance="link"
                            icon={'download'}
                          />
                        </Whisper>
                      </>
                    }
                  </Fragment>
                  }
                </Cell>
              </Column>
            </ResponsiveTable>
          }
        </>
      }

      {((showUploadLargeFile === undefined || showUploadLargeFile === true) && listType === 'form') &&
        <>
          {(!disabled) &&
            <Form.HelpText>
              Drag and drop or hold Ctrl (Win) or Cmnd (Mac) to upload multiple files.
              {IS_BUGABOO && <div>
                <strong><a href="/" onClick={(e: any) => {
                  e.preventDefault();
                  setUploadLargeFile(true);
                }}>Click here for files larger then 10MB</a>.</strong>
              </div>}
            </Form.HelpText>
          }

          {uploadLargeFile &&
            <LargeAttachmentForm
              handleHide={() => setUploadLargeFile(false)}
              handleUpload={onChange}
              resourceGuid={referenceGuid}
            />
          }
        </>}
    </Fragment>
  );
}

export default AttachmentForm;
