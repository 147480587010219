import { gql, useApolloClient, useQuery } from "@apollo/client";
import { AttachmentForm } from "components/attachment";
import { FormLabel } from "components/form";
import { format, parseISO } from "date-fns";
import { GET_TENANT } from "gql/tenant";
import { COLOR, FORMAT, getEnv } from "lib/env";
import { filterFieldsByParentKey } from "lib/helpers/field";
import { DEFAULT_GUID } from "lib/tenant";
import { startCase } from "lodash";
import { cloneElement, createElement, useContext, useEffect, useRef, useState } from "react";
import { MdAdd, MdDelete } from "react-icons/md";
import { Col, DatePicker, Form, Grid, IconButton, Input, InputGroup, InputNumber, Message, Row, SelectPicker, toaster } from "rsuite";
import { ApplicationContext, CustomerLookupV2, DrawerFormWrapper } from "shared";
import { useViewport } from "shared/ViewportProvider";
import INTL from "tenant/intl";
import { v4 as uuidv4 } from 'uuid';

interface ITenantForm {
  action?: string,
  guid?: string,
  customerId?: number,
  show?: boolean,
  drawer?: boolean,
  startEndDate?: [Date, Date] | undefined,
  onHide?: () => void,
  onUpdate?: (data: any) => void
}

interface ITenantContact {
  guid: string,
  name: string | undefined,
  email: string | undefined
  phone: string | undefined
}

interface ITenantRent {
  guid: string,
  date: Date | undefined,
  rentAmount: number | undefined,
  rentAmountPerSqFt: number | undefined,
  opcost: number | undefined,
  opcostPerSqFt: number | undefined
}

interface ITenantNote {
  guid: string,
  date: Date | undefined,
  note: string | undefined
}

const INITIAL_CONTACT: ITenantContact = {
  guid: uuidv4(),
  name: undefined,
  email: undefined,
  phone: undefined
};

const INITIAL_RENT: ITenantRent = {
  guid: uuidv4(),
  date: undefined,
  rentAmount: undefined,
  rentAmountPerSqFt: undefined,
  opcost: undefined,
  opcostPerSqFt: undefined
};

const INITIAL_NOTE: ITenantNote = {
  guid: uuidv4(),
  date: undefined,
  note: undefined
};

const TenantForm = ({
  guid,
  customerId,
  action,
  show,
  drawer,
  onHide,
  onUpdate
}: ITenantForm) => {
  const client = useApolloClient();
  const { showError } = useContext(ApplicationContext);
  const { state } = useViewport();
  const container = useRef() as React.MutableRefObject<HTMLDivElement>;
  const [tenantGuid, setTenantGuid] = useState(guid || uuidv4());
  const [tenantData, setTenantData] = useState<any>({});
  const [saving, setSaving] = useState(false);
  const [contacts, setContacts] = useState<Array<ITenantContact>>([{ ...INITIAL_CONTACT, guid: uuidv4() }]);
  const [rents, setRents] = useState<Array<ITenantRent>>([{ ...INITIAL_RENT, guid: uuidv4() }]);
  const [notes, setNotes] = useState<Array<ITenantNote>>([{ ...INITIAL_NOTE, guid: uuidv4() }]);
  const [attachments, setAttachments] = useState<any>([]);
  const [status, setStatus] = useState<string>('active');
  const [showCustomerRequiredTooltip, setShowCustomerRequiredTooltip] = useState(false);
  const [customerProfile, setCustomerProfile] = useState<any>({ workCustomerId: customerId, billingCustomerId: customerId });
  const [oldCustomer, setOldCustomer] = useState<any>(null);
  const [customer, setCustomer] = useState<any>(null);
  const [deposit, setDeposit] = useState<any>(undefined);
  const tenant = useQuery(GET_TENANT, { variables: { guid: guid || DEFAULT_GUID } });

  useEffect(() => {
    if (guid && tenant?.data?.tenant) {
      setContacts((tenant?.data?.tenant?.contacts || []).length > 0 ? tenant?.data?.tenant?.contacts : [{ ...INITIAL_CONTACT, guid: uuidv4() }]);
      setRents((tenant?.data?.tenant?.rents || []).length > 0 ? tenant?.data?.tenant?.rents : [{ ...INITIAL_RENT, guid: uuidv4() }]);
      setNotes((tenant?.data?.tenant?.notes || []).length > 0 ? tenant?.data?.tenant?.notes : [{ ...INITIAL_NOTE, guid: uuidv4() }]);
      setAttachments(tenant?.data?.tenant?.attachments || []);
      setDeposit(tenant?.data?.tenant?.deposit);
      setTenantData(tenant?.data?.tenant);
      setTenantGuid(guid);
      setStatus(tenant?.data?.tenant?.status);
      setCustomerProfile({ workCustomerId: tenant?.data?.tenant?.customerId, billingCustomerId: tenant?.data?.tenant?.customerId });
    }
  }, [tenant?.data?.tenant]);

  const handleCustomerIdChange = (workCustomerId: number, billCustomerId: number, userId: number | undefined) => {
    if (customerProfile?.workCustomerId !== workCustomerId || customerProfile?.billCustomerId !== billCustomerId || customerProfile?.userId !== userId) {
      setShowCustomerRequiredTooltip(false);
      setCustomerProfile({ workCustomerId, billCustomerId });
    }
  }

  const handleCustomerFound = (customer: any) => {
    if (!oldCustomer) {
      setOldCustomer(customer);
    }
    setCustomer(customer);
  }

  const handleSubmit = async () => {
    try {
      setSaving(true);

      const response: any = await client.mutate({
        mutation: gql`
            mutation upsertTenant($input: UpsertTenantInput!) {
              upsertTenant(input: $input) {
                success
                code
                message
                result
              }
            }
          `,
        variables: {
          input: {
            guid: tenantGuid,
            rents,
            contacts,
            notes,
            customerId: customerProfile.workCustomerId,
            deposit: parseFloat(deposit),
            status
          }
        }
      });


      if (response.data.upsertTenant.success) {
        toaster.push(
          <Message type="success" showIcon closable>{response.data.upsertTenant.message}</Message>
        );
      } else {
        toaster.push(
          <Message type="error" showIcon closable duration={3000}>{response.data.upsertTenant.message}</Message>
        );
      }

      setSaving(false);
      if (onUpdate) {
        onUpdate(response.data.upsertTenant);
      }
      
      if (onHide) {
        onHide();
      }
    } catch (err) {
      console.log(err);
      showError(err);
      setSaving(false);
    }
  }

  const handleUpdateRent = async (val: Date | string | undefined | null, el: any, guid?: any) => {
    const key = typeof (el) === 'string' ? el : el.target.name;
    guid = typeof (guid) === 'undefined' ? el.target.dataset.guid : guid;
    setRents(rents.map((c: any) => {
      if (c.guid === guid && key) {
        c[key] = val;
      }
      return c;
    }));
  }

  const handleUpdateNotes = async (val: Date | string | undefined | null, el: any, guid?: any) => {
    const key = typeof (el) === 'string' ? el : el.target.name;
    guid = typeof (guid) === 'undefined' ? el.target.dataset.guid : guid;
    setNotes(notes.map((c: any) => {
      if (c.guid === guid && key) {
        c[key] = val;
      }
      return c;
    }));
  }

  const handleUpdateContact = async (val: string, el: any, guid?: any) => {
    const key = typeof (el) === 'string' ? el : el.target.name;
    guid = typeof (guid) === 'undefined' ? el.target.dataset.guid : guid;
    setContacts(contacts.map((c: any) => {
      if (c.guid === guid && key) {
        c[key] = val;
      }
      return c;
    }));
  }

  const handleSetAttachments = (attachmentsData: any) => {
    setAttachments([].concat(attachmentsData));
  }

  const Wrapper = show === undefined
    ? <div />
    : <DrawerFormWrapper
      loading={saving}
      show={show}
      title={startCase(action) + INTL.TENANT}
      guid={guid}
      action={action || 'add'}
      form={`/app/${getEnv()}/customer/${action}/${guid ? guid : ''}?${customerId ? `customer_id=${customerId}` : ''}`}
      onHide={onHide}
      onSave={handleSubmit}
    />;


  return cloneElement(Wrapper, {
    children: <div ref={container}>
      <CustomerLookupV2
        key={`customer-${customerProfile.billCustomerId}-${customerProfile.workCustomerId}`}
        showBreadcrumb={false}
        showAutoCompleted={true}
        billingCustomerId={customerProfile.billCustomerId}
        workingCustomerId={customerProfile.workCustomerId}
        handleCustomerIdChange={handleCustomerIdChange}
        showRequiredTooltip={showCustomerRequiredTooltip}
        handleCustomerFound={handleCustomerFound}
        showBilling={false}
        workLabel={'Leased'}
      />

      <fieldset>
        <legend>Rent Details</legend>
        <div className="mb-24">
          <Grid fluid>
            <Row>
              <Col xs={24} md={10}>
                {getEnv() === 'dev' &&
                  <Form.Group className="mb-12">
                    <Form.ControlLabel>UUID:</Form.ControlLabel>
                    <div>{tenantGuid}</div>
                  </Form.Group>
                }

                <Form.Group className="mb-12">
                  <Form.ControlLabel>Status:</Form.ControlLabel>
                  <SelectPicker
                    block
                    searchable={false}
                    value={status}
                    onSelect={setStatus}
                    data={[
                      { label: 'Active', value: 'active' },
                      { label: 'Inactive', value: 'inactive' }
                    ]}
                  />
                </Form.Group>

                <Form.Group>
                  <Form.ControlLabel>Deposit:</Form.ControlLabel>
                  <InputNumber
                    value={deposit}
                    onChange={setDeposit}
                  // formatter={(value: any) => value ? `$${value}`.replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, '$&,') : value}
                  />
                </Form.Group>

              </Col>
            </Row>
          </Grid>
        </div>

        <legend>Contact Details</legend>
        <Grid fluid className="p-0">
          <Row>
            <Col md={4}>Name</Col>
            <Col md={4}>Phone</Col>
            <Col md={4}>Email</Col>
          </Row>
          {contacts.map((row: any, index: number) => <Row style={{ marginTop: index === 0 ? 0 : '4px' }} key={`contact-${row.guid}`}>
            <Col md={4}>
              {index === 0
                ? <InputGroup inside>
                  <Input defaultValue={row.name} data-guid={row.guid} name="name" size="xs" onChange={handleUpdateContact} />
                  <InputGroup.Addon style={{ margin: '4px 4px 4px 0', padding: '3px 4px 4px 0', fontWeight: 500 }}>Primary</InputGroup.Addon>
                </InputGroup>
                : <Input defaultValue={row.name} data-guid={row.guid} name="name" size="sm" onChange={handleUpdateContact} />
              }
            </Col>
            <Col md={4}><Input defaultValue={row.phone} data-guid={row.guid} name="phone" size="sm" onChange={handleUpdateContact} /></Col>
            <Col md={4}><Input defaultValue={row.email} data-guid={row.guid} name="email" size="sm" onChange={handleUpdateContact} /></Col>
            <Col md={2}>
              {index === 0
                ? <IconButton appearance="link" icon={<MdAdd />} onClick={() => setContacts([...contacts, { ...INITIAL_CONTACT, guid: uuidv4() }])} />
                : <IconButton appearance="link" icon={<MdDelete />} style={{ color: COLOR.DANGER }} onClick={() => setContacts(contacts.filter((c: any) => c.guid !== row.guid))} />
              }</Col>
          </Row>)}
        </Grid>
      </fieldset>

      <fieldset>
        <legend>Rent</legend>
        <Grid fluid className="p-0">
          <Row>
            <Col md={4}>Date</Col>
            <Col md={2}>Rent Amount</Col>
            <Col md={2}>Rent Amount per Sq/Ft</Col>
            <Col md={2}>Opcost</Col>
            <Col md={2}>Opcost per Sq/Ft</Col>
          </Row>
          {rents.map((row: any, index: number) => <Row style={{ marginTop: index === 0 ? 0 : '4px' }} key={`rent-${row.guid}`}>
            <Col md={4}>
              <DatePicker
                renderValue={(val: Date) => format(val, FORMAT.DAY_MONTH_DATE)}
                block
                cleanable
                oneTap
                defaultValue={typeof (row.date) === 'string' ? parseISO(row.date) : row.date}
                data-guid={row.guid}
                name="date"
                size="sm"
                onChange={(date: Date | null) => handleUpdateRent(date, 'date', row.guid)}
                calendarDefaultDate={typeof (row.date) === 'string' ? parseISO(row.date) : row.date}
              />
            </Col>
            <Col md={2}><Input defaultValue={row.rentAmount} data-guid={row.guid} name="rentAmount" size="sm" onChange={handleUpdateRent} /></Col>
            <Col md={2}><Input defaultValue={row.rentAmountPerSqFt} data-guid={row.guid} name="rentAmountPerSqFt" size="sm" onChange={handleUpdateRent} /></Col>
            <Col md={2}><Input defaultValue={row.opcost} data-guid={row.guid} name="opcost" size="sm" onChange={handleUpdateRent} /></Col>
            <Col md={2}><Input defaultValue={row.opcostPerSqFt} data-guid={row.guid} name="opcostPerSqFt" size="sm" onChange={handleUpdateRent} /></Col>
            <Col md={2}>
              {index === 0
                ? <IconButton appearance="link" icon={<MdAdd />} onClick={() => setRents([...rents, { ...INITIAL_RENT, guid: uuidv4() }])} />
                : <IconButton appearance="link" icon={<MdDelete />} style={{ color: COLOR.DANGER }} onClick={() => setRents(rents.filter((c: any) => c.guid !== row.guid))} />
              }</Col>
          </Row>)}
        </Grid>
      </fieldset>

      <fieldset>
        <legend>Notes</legend>
        <Grid fluid className="p-0">
          <Row>
            <Col md={4}>Date</Col>
            <Col md={8}>Note</Col>
          </Row>
          {notes.map((row: any, index: number) => <Row style={{ marginTop: index === 0 ? 0 : '4px' }} key={`note-${row.guid}`}>
            <Col md={4}>
              <DatePicker
                renderValue={(val: Date) => format(val, FORMAT.DAY_MONTH_DATE)}
                block
                cleanable
                oneTap
                defaultValue={typeof (row.date) === 'string' ? parseISO(row.date) : row.date}
                data-guid={row.guid}
                name="date"
                size="sm"
                onChange={(date: Date | null) => handleUpdateNotes(date, 'date', row.guid)}
                calendarDefaultDate={row.date}
              />
            </Col>
            <Col md={8}><Input defaultValue={row.note} data-guid={row.guid} name="note" size="sm" onChange={handleUpdateNotes} /></Col>
            <Col md={2}>
              {index === 0
                ? <IconButton appearance="link" icon={<MdAdd />} onClick={() => setNotes([...notes, { ...INITIAL_NOTE, guid: uuidv4() }])} />
                : <IconButton appearance="link" icon={<MdDelete />} style={{ color: COLOR.DANGER }} onClick={() => setNotes(notes.filter((c: any) => c.guid !== row.guid))} />
              }</Col>
          </Row>)}
        </Grid>
      </fieldset>

      <fieldset>
        <legend>Attachments</legend>
        <AttachmentForm
          referenceGuid={tenantGuid}
          attachments={attachments || []}
          onChange={handleSetAttachments}
          showVisibility={false}
          showType={filterFieldsByParentKey(state.fields, 'attachment_types')}
        />
      </fieldset>
    </div>
  });
}

export {
  TenantForm
}