const getSort = (id: string, order?: any) => {
  switch (id) {
    case 'work-orders':
      return getWorkOrderSort(order);
    case 'work-orders-compare':
    case 'work-orders-schedule':
      return getWorkOrderSortCompare();
    case 'jobs':
    case 'jobsondemand':
      return getJobsSort();
    case 'daily-financials':
      return getDailyFinancialsSort();
    case 'employeesPayroll':
      return getEmployeesPayrollSort();
    default:
      return {};
  }
}

const getWorkOrderSort = (order: any) => {
  const listOrder: any = [];
  if (order?.groupBy === 'endDate') {
    listOrder.push({ endDate: "DESC" });
  } else {
    listOrder.push({ startDate: "DESC" });
  }

  listOrder.concat(
    { sortOrder: "ASC" },
    { operatorName: "ASC" },
    { customerName: "ASC" },
    { id: "DESC" }
  );

  return listOrder;
}

const getWorkOrderSortCompare = () => {
  return [
    { startDate: "ASC" },
    { sortOrder: "ASC" },
    { operatorName: "ASC" },
    { customerName: "ASC" },
    { id: "DESC" }
  ];
}

const getJobsSort = () => {
  return [
    { sortDate: "ASC" },
    { sortOrder: "ASC" },
    { operatorName: "ASC" },
    { customerName: "ASC" }
  ];
}


const getDailyFinancialsSort = () => {
  return [
    { refDate: 'DESC' },
    { operatorName: 'ASC' }
  ];
}

const getEmployeesPayrollSort = () => {
  return [
    { clockIn: 'DESC' }
  ];
}

export {
  getSort
}
