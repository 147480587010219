import { ApolloClient } from '@apollo/client';
import { QUERY_COMPANIES } from 'gql/companies';

const getCompanies = async (client: ApolloClient<object>) => {
  const results: any = await client.query({
    query: QUERY_COMPANIES,
    variables: {
      offset: 0,
      limit: 999
    },
    fetchPolicy: 'no-cache'
  });
  return results.data.companies.edges.node;
}

export {
  getCompanies
};
