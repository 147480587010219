import { flatten, round, startCase } from "lodash";
import { moneyFormatter } from "./tenant";

const getWorkOrderSPMH = (data: any) => {
  const totalTimeSpent = +data.totalTimeSpent / 60;
  const totalCost = (data?.totalCost || 0) * data.monthsDuration;
  let spmh = 0;

  if (totalTimeSpent > 0) {
    spmh = totalCost / totalTimeSpent;
  }

  return {
    spmh: (isNaN(round(totalCost / totalTimeSpent, 2)) || totalTimeSpent === 0) ? 0 : round(totalCost / totalTimeSpent, 2),
    formatted: (isNaN(totalCost / totalTimeSpent) || totalTimeSpent === 0) ? moneyFormatter.format(0) : moneyFormatter.format(totalCost / totalTimeSpent),
    totalCost: round(totalCost, 2),
    totalTimeSpent: round(totalTimeSpent, 2)
  };
}

const getAverageSPMH = (jobs: any) => {
  const spmh = jobs.reduce((p: any, n: any) => p += n.spmh, 0);
  const jobCount = jobs.filter((j: any) => +j.spmh > 0).length;

  if (jobCount > 0) {
    return moneyFormatter.format(spmh / jobCount);
  }

  return '-';
}

const calculateSalesPerManHour = (jobs: any) => {
  jobs = jobs || [];

  if (!Array.isArray(jobs)) {
    jobs = [jobs];
  }

  const spmh = (jobs.reduce((p: any, j: any) => {
    const timeSpent = getJobTimeSpent(j);

    if (timeSpent < 1) {
      p += getCostPerJob(j); 
    } else {
      p += getCostPerJob(j) / timeSpent;
    }
    
    return p;
  }, 0) / jobs.length);

  return isNaN(spmh) || !isFinite(spmh) ? 0 : spmh;
}

const getCostPerJob = (job: any) => {
  const cost = job.perCostTypeCost || job.perCostTypeCost === 0 ? job.perCostTypeCost : job.workOrderPerCostTypeCost;
  if (job.costType === 'per_hour') {
    const timeSpent = getJobTimeSpent(job);
    return cost * timeSpent;
  }

  return cost;
}

const getTotal = (dailyFinancials: any, group: string) => {
  switch (group) {
    case 'expenses':
      return dailyFinancials.reduce((prev: any, next: any) => {
        prev = prev + +next.totalExpenses;
        return prev;
      }, 0);
    case 'labour':
      return dailyFinancials.reduce((prev: any, next: any) => {
        prev = prev + +next.totalWagesPaid;
        return prev;
      }, 0);
    case 'spmh':
      const jobCount = dailyFinancials.map((d: any) => d.jobs.length).reduce((p: any, n: any) => p + n, 0);

      return flatten(dailyFinancials.map((d: any) => d.jobs)).reduce((prev: any, next: any) => {
        // prev = prev + +next.salesPerManHour;
        prev = prev + (getCostPerJob(next) / (next.timeSpent / 60));
        return prev;
      }, 0) as any / jobCount;
    case 'royalty':
      return dailyFinancials.reduce((prev: any, next: any) => {
        prev = prev + +next.royaltyPaid;
        return prev;
      }, 0);
    case 'profit':
      return dailyFinancials.reduce((prev: any, next: any) => {
        prev = prev + +next.netProfit;
        return prev;
      }, 0);
    case 'sales':
      return dailyFinancials.reduce((prev: any, next: any) => {
        prev = prev + +next.grossRevenue;
        return prev;
      }, 0);
    default:
      return 0;
  }
}

const getJobManHours = (job: any, spmhTarget: any) => {
  const manHours = +(job.costType === 'per_hour'
    ? (job.workOrderTotalCost / spmhTarget)
    : (job.workOrderPerCostTypeCost / spmhTarget));

  return round(isNaN(manHours) ? 0 : manHours, 2);
}

const getJobTimeSpent = (job: any) => {
  const timeSpent = ((job.timeCard || []).reduce((p: any, n: any) => p += isNaN(n.timeSpent) ? 0 : +n.timeSpent, 0) + (job.timeSpent || 0)) / 60.0;
  return round(isNaN(timeSpent) ? 0 : timeSpent, 2);
}

const getTotalSPMH = (totalSales: any, jobs: any, labour: any):any => {
  const totalHoursWorked = labour.reduce((p: any, n: any) => p += n.hoursWorked, 0);
    
  return {
    label: `Total Sales (${round(totalSales, 2)}) / Total Hours Worked (${totalHoursWorked})`,
    value:  (isNaN(totalSales / totalHoursWorked) || totalHoursWorked === 0) ? 0 : totalSales / totalHoursWorked
  };
}

const getTimeRemaining = (job: any, hourlyEstimates: any, salesPerManHourTarget: any) => {
  if (job.costType === 'per_month') {
    return {
      label: `Estimated: ${hourlyEstimates.find((h: any) => h.guid === job?.workOrderGuid)?.hoursEstimated || 0} hrs (${moneyFormatter.format(hourlyEstimates.find((h: any) => h.guid === job?.workOrderGuid)?.totalCost || 0)}/month x ${hourlyEstimates.find((h: any) => h.guid === job?.workOrderGuid)?.monthsDuration || 1} months / ${salesPerManHourTarget} SPMH)<br />Spent: ${hourlyEstimates.find((h: any) => h.guid === job?.workOrderGuid)?.hoursSpent || 0} hrs`,
      value: hourlyEstimates.find((h: any) => h.guid === job?.workOrderGuid)?.hoursRemaining || '-'
    }
  }
  
  return {
    label: `Not Calculated on ${startCase(job.costType)}`,
    value: '-'
  }
}

const getTotalHoursWorked = (labour: any) => {
  return round(labour.reduce((p: any, n: any) => p += n.hoursWorked, 0), 2);
}

export {
  getTimeRemaining,
  calculateSalesPerManHour,
  getCostPerJob,
  getTotal,
  getAverageSPMH,
  getWorkOrderSPMH,
  getJobManHours,
  getJobTimeSpent,
  getTotalSPMH,
  getTotalHoursWorked
}