import { useContext, useEffect, useRef, useState } from 'react';
import {
  toaster,
  Message,
  Button,
  Col,
  Drawer,
  Form,
  Grid,
  Row,
  Schema,
  SelectPicker,
} from 'rsuite';
import { Editor } from '@tinymce/tinymce-react';
import { isMobileOnly } from 'react-device-detect';
import { InputMoney } from 'components/form';
import { ApplicationContext } from 'shared';
import { useViewport } from 'shared/ViewportProvider';
import { filterFieldsByParentKey } from 'lib/helpers/field';
import { pick } from 'lodash';
import { gql, useApolloClient, useQuery } from '@apollo/client';
import AttachmentForm from 'components/attachment/AttachmentForm';
import { QUERY_COMPANY } from 'gql/companies';
import INTL from 'tenant/intl';

const { StringType, NumberType } = Schema.Types;
const model = Schema.Model({
  section: NumberType().isRequired('This field is required.'),
  title: StringType().isRequired('This field is required.')
});

interface ISettingsCompanyForm {
  action: string | undefined,
  guid: string | undefined,
  onHide: () => void,
  onUpdate: (value: any) => void
}

const SettingsCompanyForm = ({
  action,
  guid,
  onHide,
  onUpdate
}: ISettingsCompanyForm) => {
  let form: any = useRef();
  const client = useApolloClient();
  const { state } = useViewport();
  const { showError } = useContext(ApplicationContext);
  const [formValue, setFormValue] = useState<any>({});
  const [formError, setFormError] = useState<any>({});
  const [saving, setSaving] = useState(false);
  const companyQuery = useQuery(QUERY_COMPANY, { variables: { guid } });

  useEffect(() => {
    setFormValue(Object.keys(companyQuery.data?.company || {}).reduce((p: any, n: any) => {
      p[n] = companyQuery.data?.company[n] || '';
      return p;
    }, {}));
  }, [companyQuery.data]);

  const handleSubmit = async (form: any) => {
    try {
      setSaving(true);
      const upsertCompany = gql`
        mutation upsertCompany($input: UpsertCompanyInput!) {
          upsertCompany(input: $input) {
            success
            code
            message
            result
          }
        }`;

      let data: any = pick({
        ...form,
        companyName: form.corporateName
      }, ['guid', 'name', 'address', 'phone', 'website', 'email', 'taxRate', 'taxRate2', 'businessNumber', 'gstNumber', 'wcbNumber', 'taxPrefix', 'taxPrefix2', 
        'companyName', 'parentDivision', 'logo', 'fax', 'corporateName', 'companyDivision', 'adminPercentage', 'royaltyPercentage', 'laborPercentage', 'corporateTax', 
        'salesPerManHourTarget', 'domainName', 'timezone', 'country', 'emailDailyFinancials', 'signature', 'newDocumentStatus', 'clientSignedDocumentStatus', 
        'representativeSignedDocumentStatus', 'documentsFromEmail']);

      if ((data.taxRate2.toString()).trim().length === 0) {
        data.taxRate2 = null;
      } else {
        data.taxRate2 = +data.taxRate2;
      }

      if ((data.taxRate.toString()).trim().length === 0) {
        data.taxRate = null;
      } else {
        data.taxRate = +data.taxRate;
      }

      data = Object.keys(data).reduce((p: any, n: any) => {
        if (data[n] === null || typeof (data[n]) === 'undefined' || data[n].toString().trim().length === 0) {
          data[n] = null;
        } else {
          p[n] = data[n];
        }

        return p;
      }, {});

      data.salesPerManHourTarget = +data.salesPerManHourTarget;

      const response: any = await client.mutate({
        mutation: upsertCompany, variables: {
          // the array is taken from the input list
          input: data
        }
      });

      if (response.data.upsertCompany.success) {
        toaster.push(
          <Message type="success" showIcon closable>{response.data.upsertCompany.message}</Message>
        );
        onUpdate(response.data.upsertCompany.response);
      } else {
        showError(response);
      }
      setSaving(false);
    } catch (err) {
      showError(err);
      setSaving(false);
    } finally {
      onHide();
      setSaving(false);
    }
  }

  return (
    <Drawer backdrop='static' open size="md" onClose={onHide} full={isMobileOnly}>
      <Drawer.Header>
        <Drawer.Title>{formValue?.guid === '-1' ? 'Add' : 'Edit'} Company</Drawer.Title>
      </Drawer.Header>
      <Drawer.Body>
        <Grid fluid>
          <Row>
            <Col xs={23}>
              <Form
                ref={(ref: any) => form = ref}
                fluid
                formValue={formValue}
                formError={formError}
                onChange={setFormValue}
                onCheck={setFormError}
              >
                <fieldset>
                  <legend>Company Information</legend>
                  <Form.Group>
                    <Grid fluid className="p-0">
                      <Row>
                        <Col xs={24} md={12}>
                          <Form.ControlLabel className="required">Corporate Name:</Form.ControlLabel>
                          <Form.Control name="corporateName" />
                          <Form.HelpText>Appears on invoice and template as company name.</Form.HelpText>
                        </Col>
                        <Col xs={24} md={12}>
                          <Form.ControlLabel>
                            Timezone:<span> </span>
                          </Form.ControlLabel>
                          <Form.Control
                            block
                            searchable
                            name="timezone"
                            accepter={SelectPicker}
                            data={(Intl as any).supportedValuesOf('timeZone').filter((n: string) => n.toLowerCase().includes('america/')).map((s: string) => ({ value: s, label: s }))} />
                        </Col>
                      </Row>
                    </Grid>
                  </Form.Group>

                  <Form.Group>
                    <Grid fluid className="p-0">
                      <Row>
                        <Col xs={24} md={12}>
                          <Form.ControlLabel className="required">Branch:</Form.ControlLabel>
                          <Form.Control name="name" />
                          <Form.HelpText>Name of the company branch, usually city name. This will appear in change companies list.</Form.HelpText>
                        </Col>
                        <Col xs={24} md={12}>
                          <Form.ControlLabel>Division:</Form.ControlLabel>
                          <Form.Control name="companyDivision" />
                          <Form.HelpText>Company parent division name. Appear on invoice as &quot;Division of&quot;.</Form.HelpText>
                        </Col>
                      </Row>
                    </Grid>
                  </Form.Group>

                  <Form.Group>
                    <Grid fluid className="p-0">
                      <Row>
                        <Col xs={24} md={12}>
                          <Form.ControlLabel className="required">Address:</Form.ControlLabel>
                          <Form.Control name="address" />
                        </Col>
                        <Col xs={24} md={12}>
                          <Form.ControlLabel className="required">Country:</Form.ControlLabel>
                          <Form.Control name="country" />
                        </Col>
                      </Row>
                    </Grid>
                  </Form.Group>

                  <Form.Group>
                    <Grid fluid className="p-0">
                      <Row>
                        <Col xs={24} md={12}>
                          <Form.ControlLabel className="required">Phone Number:</Form.ControlLabel>
                          <Form.Control name="phone" />
                        </Col>
                        <Col xs={24} md={12}>
                          <Form.ControlLabel>Fax Number:</Form.ControlLabel>
                          <Form.Control name="fax" />
                        </Col>
                      </Row>
                    </Grid>
                  </Form.Group>

                  <Form.Group>
                    <Grid fluid className="p-0">
                      <Row>
                        <Col xs={24} md={12}>
                          <Form.ControlLabel>Website:</Form.ControlLabel>
                          <Form.Control name="website" />
                        </Col>
                        <Col xs={24} md={12}>
                          <Form.ControlLabel className="required">Email:</Form.ControlLabel>
                          <Form.Control name="email" />
                          <Form.HelpText>This will appear as reply to on all outgoing emails.</Form.HelpText>
                        </Col>
                      </Row>
                    </Grid>
                  </Form.Group>

                  <Form.Group>
                    {formValue?.logo &&
                      <div>
                        <img src={formValue.logo} style={{ height: '80px', width: 'auto' }} />
                      </div>
                    }
                    <Form.ControlLabel className="required">Logo:</Form.ControlLabel>
                    <AttachmentForm
                      destination="logo"
                      attachments={[]}
                      referenceGuid={formValue.guid}
                      showList={false}
                      onChange={(data: any) => {
                        console.log(data);
                        setFormValue({
                          ...formValue,
                          logo: data[0].filePath
                        });
                      }}
                    />

                    {/* <Uploader
                      multiple={false}
                      fileList={[]}
                      onChange={(file) => {
                        if (file[0].blobFile) {
                          const blob: any = file[0].blobFile;
                          const contentType = (file[0] as any).blobFile.type;
                          const extension: string = contentType.split('/').pop();
                          const reader = new FileReader();
                          reader.readAsDataURL(blob);
                          reader.onloadend = () => {
                            const base64data = reader.result;
                            logoRef.current.src = base64data;
                            setFormValue({ ...formValue, logo: base64data, extension, contentType });
                          }
                        }
                      }}
                    /> */}
                  </Form.Group>

                  <Form.Group>
                    <Grid fluid className="p-0">
                      <Row>
                        <Col xs={24} md={8}>
                          <Form.ControlLabel>Royalty Percentage:</Form.ControlLabel>
                          <Form.Control name="royaltyPercentage" />
                        </Col>
                        <Col xs={24} md={8}>
                          <Form.ControlLabel>Admin Percentage:</Form.ControlLabel>
                          <Form.Control name="adminPercentage" />
                        </Col>
                        <Col xs={24} md={8}>
                          <Form.ControlLabel>Labor Percentage:</Form.ControlLabel>
                          <Form.Control name="laborPercentage" />
                        </Col>
                      </Row>
                    </Grid>
                  </Form.Group>
                </fieldset>


                <fieldset>
                  <legend>Tax Information</legend>
                  <Form.Group>
                    <Grid fluid className="p-0">
                      <Row className="mb-24">
                        <Col xs={24} md={12}>
                          <Form.ControlLabel className="required">Primary Sales Tax Prefix:</Form.ControlLabel>
                          <Form.Control name="taxPrefix" />
                          <Form.HelpText>Enter decimal value of percentage. e.g. GST (appear as invoice line item).</Form.HelpText>
                        </Col>
                        <Col xs={24} md={12}>
                          <Form.ControlLabel>Primary Sales Tax Rate:</Form.ControlLabel>
                          <Form.Control name="taxRate" />
                          <Form.HelpText>Enter decimal value of percentage. e.g. 0.05, 0.07, 0.10</Form.HelpText>
                        </Col>
                      </Row>
                      <Row className="mb-24">
                        <Col xs={24} md={12}>
                          <Form.ControlLabel>Secondary Sales Tax Prefix:</Form.ControlLabel>
                          <Form.Control name="taxPrefix2" />
                          <Form.HelpText>Enter decimal value of percentage. e.g. GST (appear as invoice line item).</Form.HelpText>
                        </Col>
                        <Col xs={24} md={12}>
                          <Form.ControlLabel>Secondary Sales Tax Rate:</Form.ControlLabel>
                          <Form.Control name="taxRate2" />
                          <Form.HelpText>Enter decimal value of percentage. e.g. 0.05, 0.07, 0.10</Form.HelpText>
                        </Col>
                      </Row>
                      <Row className="mb-24">
                        <Col xs={24} md={8}>
                          <Form.ControlLabel className="required">Business Number:</Form.ControlLabel>
                          <Form.Control name="businessNumber" />
                        </Col>
                        <Col xs={24} md={8}>
                          <Form.ControlLabel>Tax Number:</Form.ControlLabel>
                          <Form.Control name="gstNumber" />
                        </Col>
                        <Col xs={24} md={8}>
                          <Form.ControlLabel>WCB Number:</Form.ControlLabel>
                          <Form.Control name="wcbNumber" />
                        </Col>
                      </Row>
                    </Grid>
                  </Form.Group>
                </fieldset>

                <fieldset>
                  <legend>Application Settings</legend>
                  <Form.Group>
                    <Grid fluid className="p-0">
                      <Row>
                        <Col xs={24}>
                          <Form.ControlLabel>Daily Financials Email Recipients:</Form.ControlLabel>
                          <Form.Control name="emailDailyFinancials" />
                          <Form.HelpText>Daily financials sendout, separate multiple emails by commas. Emails are sent out at 8PM UTC/2PM MST.</Form.HelpText>
                        </Col>
                      </Row>
                      <Row className='mt-24'>
                        <Col md={12} xs={24}>
                          <Form.ControlLabel>Sales Per Man Hour Target:</Form.ControlLabel>
                          <Form.Control accepter={InputMoney} name="salesPerManHourTarget" />
                          <Form.HelpText>Automatically estimate hours to spend on site</Form.HelpText>
                        </Col>
                      </Row>
                    </Grid>
                  </Form.Group>
                  <Form.Group>
                    <Grid fluid className="p-0">
                      <Row>
                        <Col md={8} xs={24}>
                          <Form.ControlLabel>New Document Status:</Form.ControlLabel>
                          <Form.Control
                            name="newDocumentStatus"
                            accepter={SelectPicker}
                            data={filterFieldsByParentKey(state.fields, 'documents')}
                            labelKey="title"
                            valueKey="key"
                            block />
                          <Form.HelpText>Status set on new documents</Form.HelpText>
                        </Col>
                        <Col md={8} xs={24}>
                          <Form.ControlLabel>Client Signed Status:</Form.ControlLabel>
                          <Form.Control
                            name="clientSignedDocumentStatus"
                            accepter={SelectPicker}
                            data={filterFieldsByParentKey(state.fields, 'documents')}
                            labelKey="title"
                            valueKey="key"
                            block />
                          <Form.HelpText>For client signed documents</Form.HelpText>
                        </Col>
                        <Col md={8} xs={24}>
                          <Form.ControlLabel>Representative Signed Status:</Form.ControlLabel>
                          <Form.Control
                            name="representativeSignedDocumentStatus"
                            accepter={SelectPicker}
                            data={filterFieldsByParentKey(state.fields, 'documents')}
                            labelKey="title"
                            valueKey="key"
                            block />
                          <Form.HelpText>For representative signed documents</Form.HelpText>
                        </Col>
                      </Row>
                    </Grid>
                  </Form.Group>

                  <Form.Group>
                    <Grid fluid className="p-0">
                      <Row>
                        <Col xs={24}>
                          <Form.ControlLabel>{INTL.DOCUMENTS} Email:</Form.ControlLabel>
                          <Form.Control name="documentsFromEmail" />
                          <Form.HelpText>If empty {INTL.DOCUMENT} will be emailed from company email.</Form.HelpText>
                        </Col>
                      </Row>
                    </Grid>
                  </Form.Group>

                  <Form.Group>
                    <Form.ControlLabel>Signature:</Form.ControlLabel>
                    <Editor
                      toolbar='undo redo | formatselect | bold italic underline | code'
                      plugins='code'
                      value={formValue?.signature}
                      apiKey='pec9l8zp8dpcx14izwyhwnxktzs2wo8p64z6vxb4a7nwpg54'
                      init={{
                        height: 300,
                        menubar: false,
                        branding: false
                      }}
                      onEditorChange={(content) => setFormValue({ ...formValue, signature: content })}
                    />
                    <Form.HelpText>Enter signature to append to bottom of all outgoing emails.</Form.HelpText>
                  </Form.Group>

                </fieldset>
              </Form>
            </Col>
          </Row>
        </Grid>
      </Drawer.Body>
      <Drawer.Footer>
        <Button
          loading={saving}
          onClick={() => {
            if (!form.check()) {
              return;
            }

            handleSubmit(formValue);
          }} appearance="primary" size="sm">Save</Button>
        <Button onClick={() => {
          setFormError({});
          onHide();
        }} appearance="subtle" size="sm">Close</Button>
      </Drawer.Footer>
    </Drawer>
  );
}

export default SettingsCompanyForm;
