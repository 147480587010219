import { IS_KALADI_KITCHENS, IS_KALADI_PROPERTIES } from "../lib/tenant";

let INTL = {
  CLIENT: 'Client',
  CREW: 'Crew',
  CUSTOMER: 'Customer',
  CUSTOMERS: 'Customers',
  CUSTOMER_ATTACHMENT: 'Customer',
  CREW_LIST: 'Crew List',
  DOCUMENTS: 'Documents',
  DOCUMENT: 'Document',
  STATUS_LABEL: 'Status',
  WORK_CALENDAR: 'Work Calendar',
  WORK_GROUP: 'Work Group',
  WORK_TYPE: 'Services',
  WORK_MANAGER: 'Work Manager',
  WORK_ORDER: 'Work Order',
  WORK_ORDERS: 'Work Orders',
  SITE_REVIEW: 'Site Review',
  SITE_REVIEWS: 'Site Reviews',
  WORK_SCHEDULE: 'Work Schedule',
  TIME_ON_SITE: 'On Site',
  TIME_FROM_SITE: 'Off Site',
  REGISTER: 'Register',
  JOBS: 'Jobs',
  JOB: 'Job',
  VIEWER: 'Viewer',
  TENANTS: 'Tenants',
  TENANT: 'Tenant'
}

if (IS_KALADI_KITCHENS) {
  INTL = {
    ...INTL,
    CLIENT: 'Tenant',
    CREW: 'Tenant',
    CUSTOMER: 'Tenant',
    CUSTOMERS: 'Tenants',
    CUSTOMER_ATTACHMENT: 'Tenant',
    CREW_LIST: 'Schedule',
    WORK_CALENDAR: 'Calendar',
    WORK_GROUP: 'Location',
    WORK_TYPE: 'Kitchen',
    WORK_MANAGER: 'Booking Manager',
    WORK_ORDER: 'Booking',
    WORK_ORDERS: 'Bookings',
    TIME_ON_SITE: 'Clock In',
    TIME_FROM_SITE: 'Clock Out',
    REGISTER: 'Inquire',
    JOBS: 'Booking',
    JOB: 'Booking',
    VIEWER: 'Health Inspector'
  };
}

if (IS_KALADI_PROPERTIES) {
  INTL = {
    ...INTL,
    CLIENT: 'Property',
    CREW: 'User',
    CUSTOMER: 'Property',
    CUSTOMERS: 'Properties',
    CUSTOMER_ATTACHMENT: 'Unit',
    CREW_LIST: 'To-Do List',
    DOCUMENT: 'Lease',
    DOCUMENTS: 'Leases',
    WORK_CALENDAR: 'Calendar',
    WORK_GROUP: 'Work Group',
    WORK_TYPE: 'Services',
    WORK_MANAGER: 'Work Manager',
    WORK_ORDER: 'Repair & Maintenance',
    WORK_ORDERS: 'Repairs & Maintenance',
    TIME_ON_SITE: 'On Site',
    TIME_FROM_SITE: 'Off Site',
    REGISTER: 'Inquire',
    JOBS: 'Jobs',
    JOB: 'Job',
    VIEWER: 'Viewer'
  };
}

export default INTL;
