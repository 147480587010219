import { gql, useApolloClient } from "@apollo/client";
import arrayMove from "array-move";
import { FormField, FormLabel, InputMoney } from "components/form";
import { querySpaces } from "gql/storage";
import { getEnv } from "lib/env";
import { omit, orderBy, pick } from "lodash";
import startCase from "lodash/startCase";
import { cloneElement, useContext, useEffect, useRef, useState } from "react"
import { useRouteMatch } from "react-router-dom";
import { SortableContainer, SortableElement, SortableHandle } from "react-sortable-hoc";

import {
  toaster,
  Message,
  Button,
  ButtonToolbar,
  Form,
  Loader,
  Panel,
  Schema,
  Grid,
  Row,
  Col,
  Input,
  Whisper,
  Tooltip,
} from "rsuite";

import LegacyPlusIcon from "@rsuite/icons/legacy/Plus";
import LegacyTrashIcon from "@rsuite/icons/legacy/Trash";
import LegacyCopyIcon from "@rsuite/icons/legacy/Copy";
import { ApplicationContext, DrawerFormWrapper } from "shared";
import IconButtonWrapper from "shared/IconButtonWrapper";
import { v4 as uuidv4 } from 'uuid';

interface IStorageForm {
  action?: string,
  show?: boolean,
  guid?: string,
  storageGuid?: string,
  drawer?: boolean,
  onHide?: () => void,
  onUpdate?: (data: any) => void
}

const { StringType } = Schema.Types;

const model = Schema.Model({
  name: StringType().isRequired('This field is required'),
});

const DragHandle = SortableHandle(() => <span style={{ cursor: 'grab' }}>&nbsp;::</span>);
const SortableList = SortableContainer((children: any) => <Grid fluid>{children.children}</Grid>);
const SortableItem = SortableElement((props: any) => <Row className="mb-5" {...omit(props, ['sortOrder', 'parentGuid', 'customerGuid'])} />);

const StorageSpaceForm = ({ guid, storageGuid, action, drawer, show, onHide, onUpdate }: IStorageForm) => {
  let form: any = useRef();
  const client = useApolloClient();
  const match: any = useRouteMatch();
  const { showError } = useContext(ApplicationContext);
  const [loading, setLoading] = useState(true);
  const [formError, setFormError] = useState({});
  const [saving, setSaving] = useState(false);
  const [formValue, setFormValue] = useState<any>({
    guid: uuidv4(),
    name: '',
    units: [{ guid: uuidv4(), name: '', cost: 0, size: '', sort_order: 0 }]
  });
  guid = match?.params?.guid || guid;
  action = match?.params?.action || action || 'add';
  storageGuid = match?.params?.storageGuid || storageGuid;

  useEffect(() => {
    if (action === 'edit' || action === 'clone') {
      (async function getStorage() {
        try {
          setLoading(true);
          const data: any = await client.query({
            query: querySpaces,
            variables: {
              offset: 0,
              limit: 1,
              where: { guid: { is: storageGuid } }
            },
            fetchPolicy: 'no-cache'
          });

          const spaces = data.data.storage.edges.node[0].spaces.find((s: any) => s.guid === guid);
          const units = orderBy((spaces?.units || []).map((u: any) => ({
            ...u,
            sort_order: u.sortOrder,
            parent_guid: u.parentGuid,
            guid: action === 'edit' ? u.guid : uuidv4()
          })), 'sort_order', 'asc');
          setFormValue({ ...spaces, units, guid: action === 'edit' ? spaces.guid : uuidv4() });

          setLoading(false);
        } catch (err) {
          console.log(err);
        }
      })();
    } else {
      setLoading(false);
    }
  }, [client, guid, storageGuid, action]);

  const handleSubmit = async () => {
    const errorMessage = 'Form has errors which need to be corrected';
    const validation = form.check();

    if (!validation) {
      toaster.push(<Message type="error" showIcon closable>{errorMessage}</Message>);
    } else {
      setSaving(true);

      const upsertStorage = gql`
        mutation upsertStorage($input: UpsertStorageInput!) {
            upsertStorage(input: $input) {
                success
                code
                message
            }
        }
      `;

      try {
        const units = formValue.units.map((u: any) => ({
          guid: u.guid,
          name: u.name,
          cost: u.cost,
          size: u.size,
          sortOrder: u.sort_order,
          parentGuid: u.parent_guid || guid
        }));

        const response: any = await client.mutate({
          mutation: upsertStorage, variables: {
            input: {
              ...pick(formValue, ['guid', 'name']),
              units,
              parentGuid: storageGuid
            }
          }
        });

        if (response.data.upsertStorage.success) {
          toaster.push(
            <Message type="success" showIcon closable>{response.data.upsertStorage.message}</Message>
          );
          setSaving(false);

          if (onHide) {
            onHide();
          }
          if (onUpdate) {
            onUpdate(response.data.upsertStorage.result);
          }
        } else {
          toaster.push(
            <Message type="error" showIcon closable>{response.data.upsertStorage.message}</Message>
          );
          setSaving(false);
        }
      } catch (err) {
        showError(err);
        setSaving(false);
      }
    }
  }

  const handleSortEnd = ({ oldIndex, newIndex }: any) => {
    const prevUnits: any = [].concat(formValue.units);
    const units = arrayMove(prevUnits, oldIndex, newIndex).filter((s) => s).map((s: any, index: number) => {
      s.sort_order = index;
      return s;
    });
    setFormValue({ ...formValue, units: units.map((u: any, sort_order: number) => ({ ...u, sort_order })) });
  };

  const Wrapper = show === undefined
    ? <div />
    : <DrawerFormWrapper
      action={action || 'add'}
      loading={saving}
      show={show}
      title={startCase(action) + ' Space'}
      guid={guid}
      form={`/app/${getEnv()}/storage/${action}/${guid}/space/add`}
      onHide={onHide}
      onSave={handleSubmit}
    />;

  return cloneElement(Wrapper, {
    children: <Panel className="content">
      {loading
        ? <Loader content="Loading..." />
        : <Form
          ref={(ref: any) => form = ref}
          fluid
          formValue={formValue}
          formError={formError}
          onChange={setFormValue}
          onCheck={setFormError}
          model={model}
        >
          <fieldset>
            <legend>Space</legend>

            {getEnv() === 'dev' &&
              <Form.Group>
                <Form.ControlLabel>UUID:</Form.ControlLabel>
                <p>{formValue?.guid}</p>
              </Form.Group>
            }

            <Form.Group>
              <Form.ControlLabel className="required">Name:</Form.ControlLabel>
              <Form.Control name="name" />
            </Form.Group>
          </fieldset>

          <fieldset>
            <legend>Units</legend>

            <Form.Group>
              <SortableList onSortEnd={handleSortEnd} helperClass="sortableHelper" useDragHandle>
                {formValue.units.map((row: any, rowNumber: number) =>
                  <SortableItem index={row.sort_order} {...row} key={row.guid}>
                    <Col md={1} className="pr-0">
                      {rowNumber === 0 && <FormLabel tooltip="Drag to sort" label="Sort" />}
                      <DragHandle />
                    </Col>
                    <Col md={6}>
                      <FormField index={rowNumber} label="Name">
                        <Input
                          data-guid={guid}
                          value={row.name || ''}
                          onChange={(val: string) => {
                            const units: any = [].concat(formValue.units);
                            units[rowNumber].name = val;
                            setFormValue({ ...formValue, units });
                          }}
                          tabIndex={0}
                        />
                      </FormField>
                    </Col>
                    <Col md={2}>
                      <FormField index={rowNumber} label="Size">
                        <Input
                          data-guid={guid}
                          value={row.size || ''}
                          onChange={(val: string) => {
                            const units: any = [].concat(formValue.units);
                            units[rowNumber].size = val;
                            setFormValue({ ...formValue, units });
                          }}
                          tabIndex={0}
                        />
                      </FormField>
                    </Col>
                    <Col md={2}>
                      <FormField index={rowNumber} label="Cost">
                        <InputMoney
                          value={row.cost || 0}
                          onChange={(val: string) => {
                            const units: any = [].concat(formValue.units);
                            units[rowNumber].cost = +val;
                            setFormValue({ ...formValue, units });
                          }}
                          tabIndex={0}
                        />
                      </FormField>
                    </Col>
                    <Col md={3}>
                      {rowNumber === 0 && <FormLabel label="&nbsp;" />}
                      <Whisper placement="top" trigger="hover" speaker={<Tooltip>Add</Tooltip>}>
                        <IconButtonWrapper
                          appearance="link"
                          onClick={() => {
                            const units: any = [].concat(formValue.units);
                            units.push({ guid: uuidv4(), name: '', cost: 0, size: '', sort_order: 0 });
                            setFormValue({ ...formValue, units: units.map((u: any, sort_order: number) => ({ ...u, sort_order })) });
                          }}
                          icon={<LegacyPlusIcon />}
                        />
                      </Whisper>
                      <Whisper placement="top" trigger="hover" speaker={<Tooltip>Remove</Tooltip>}>
                        <IconButtonWrapper
                          appearance="link"
                          onClick={() => {
                            const units: any = [].concat(formValue.units);
                            if (units.length === 1) {
                              toaster.push(<Message type="error" showIcon closable>Cannot remove last item</Message>);
                            } else {
                              setFormValue({
                                ...formValue, units: units
                                  .filter((u: any) => u.guid !== row.guid)
                                  .map((u: any, sort_order: number) => ({ ...u, sort_order }))
                              });
                            }
                          }}
                          icon={<LegacyTrashIcon />}
                        />
                      </Whisper>
                      <Whisper placement="top" trigger="hover" speaker={<Tooltip>Duplicate</Tooltip>}>
                        <IconButtonWrapper
                          appearance="link"
                          onClick={() => {
                            const units: any = [].concat(formValue.units);
                            units.push({ ...units[rowNumber], guid: uuidv4() });
                            setFormValue({ ...formValue, units: units.map((u: any, sort_order: number) => ({ ...u, sort_order })) });
                          }}
                          icon={<LegacyCopyIcon />}
                        />
                      </Whisper>
                    </Col>
                  </SortableItem>
                )}
              </SortableList>
            </Form.Group>
          </fieldset>

          {!drawer &&
            <ButtonToolbar>
              <Button
                appearance="primary"
                onClick={() => handleSubmit()}
                loading={saving}>Save</Button>
            </ButtonToolbar>
          }
        </Form>
      }
    </Panel>
  });
}

export {
  StorageSpaceForm
}
