import { useContext } from 'react';
import { ApplicationContext } from 'shared';
import { TenantForm as TenantFormContainer } from 'containers/tenant';

const TenantForm = () => {
  const {
    drawerData,
    showDrawer,
    onUpdateDrawerCallback
  } = useContext(ApplicationContext);

  return <TenantFormContainer
    drawer
    show
    customerId={drawerData?.customerId}
    onHide={() => showDrawer(undefined)}
    onUpdate={onUpdateDrawerCallback}
    guid={drawerData?.tenantGuid}
    action={drawerData?.action}
  />
}

export {
  TenantForm
}
