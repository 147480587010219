import { useEffect, useState, useContext, Fragment } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useApolloClient, gql } from '@apollo/client';
import { DOMHelper, Loader, Col } from 'rsuite';
import { FORMAT, setHeaderTitle } from 'lib/env';
import startCase from 'lodash/startCase';
import { Filter, ApplicationContext } from 'shared';
import { useFilteredParams } from 'shared/FilterProvider';
import { getFilter } from 'lib/helpers/filter';
import { LoadScriptNext, GoogleMap, Marker, InfoWindow, OverlayView } from '@react-google-maps/api';
import { WorkOrderStatus } from './components';
import { useViewport } from 'shared/ViewportProvider';
import { ExportField, MapToggleField } from 'components/form';
import { format, parseISO } from 'date-fns';

const GET_WORKORDERS = gql`
  query workOrders($limit: Int!, $offset: Int!, $where: WorkOrdersWhere) {
    workOrders(filter: {
      limit: $limit
      offset: $offset
      where: $where
    }) {
      edges {
        node {
          id
          guid
          startDate
          endDate
          workOrderId
          parentId
          applicationGroup
          workType
          totalCost
          perCostTypeCost
          createdAt
          lastEmail
          parentGuid
          customerName
          customerId
          workerId
          isComplete
          status
          lat
          lng
          displayName
          user {
            color
            operatorName
          }
        }
      },
      totalCount
    }
  }
`;

const CANCELED = '#ec971f';
const NOT_COMPLETED = '#d9534f';
const COMPLETED = '#5cb85c';

interface IWorkOrderMap {
  showBreadCrumb?: boolean
};

const GoogleMapMarker = ({
  markers
}: any) => {
  const [infoWindowData, setInfoWindowData] = useState<any>(null);
  const [center, setCenter] = useState({ lat: 51.0447, lng: -114.0719 });

  return <Fragment>
    {infoWindowData &&
      <InfoWindow
        onCloseClick={() => setInfoWindowData(null)}
        position={{
          lat: infoWindowData.lat,
          lng: infoWindowData.lng
        }}
        options={{
          pixelOffset: new (window as any).google.maps.Size(0, -30)
        }}
      >
        <div style={{ maxWidth: '300px' }}>
          <p className="mb-5">{infoWindowData?.user?.operatorName}</p>
          <p><strong>{infoWindowData.displayName}</strong></p>
          <p><WorkOrderStatus status={infoWindowData.status || ''} /></p>
          <p>{infoWindowData.workType.split(';').map((s: string) => startCase(s)).join(', ')}</p>
          <p>
            {format(parseISO(infoWindowData.startDate), FORMAT.DAY_MONTH_DATE)} - {format(parseISO(infoWindowData.endDate), FORMAT.DAY_MONTH_DATE)}
          </p>
        </div>
      </InfoWindow>
    }

    {markers.map((e: any) => {
      return <Marker
        position={{
          lat: e.lat,
          lng: e.lng
        }}
        key={e.guid}
        icon={{ url: 'data:image/svg+xml;charset=UTF-8,' + encodeURIComponent(e.icon) }}
        onClick={() => {
          setInfoWindowData(e);
        }}
      />
    })}
  </Fragment>
}

const WorkOrderMap = ({
  showBreadCrumb
}: IWorkOrderMap) => {
  setHeaderTitle('Work Orders');
  const client = useApolloClient();
  const match = useRouteMatch();
  const history = useHistory();
  const { state } = useViewport();
  const { showError } = useContext(ApplicationContext);
  const { params } = useFilteredParams('workOrders');
  const [loading, setLoading] = useState(false);
  const [offset, setOffset] = useState(0);
  const [workOrders, setWorkOrders] = useState<any>([]);
  const [filter, setFilter] = useState(params);
  const status = {
    completed: 'M1671 566q0 40-28 68l-724 724-136 136q-28 28-68 28t-68-28l-136-136-362-362q-28-28-28-68t28-68l136-136q28-28 68-28t68 28l294 295 656-657q28-28 68-28t68 28l136 136q28 28 28 68z',
    notCompleted: 'M1088 1248v224q0 26-19 45t-45 19h-256q-26 0-45-19t-19-45v-224q0-26 19-45t45-19h256q26 0 45 19t19 45zm30-1056l-28 768q-1 26-20.5 45t-45.5 19h-256q-26 0-45.5-19t-20.5-45l-28-768q-1-26 17.5-45t44.5-19h320q26 0 44.5 19t17.5 45z',
    canceled: 'M1440 893q0-161-87-295l-754 753q137 89 297 89 111 0 211.5-43.5t173.5-116.5 116-174.5 43-212.5zm-999 299l755-754q-135-91-300-91-148 0-273 73t-198 199-73 274q0 162 89 299zm1223-299q0 157-61 300t-163.5 246-245 164-298.5 61-298.5-61-245-164-163.5-246-61-300 61-299.5 163.5-245.5 245-164 298.5-61 298.5 61 245 164 163.5 245.5 61 299.5z'
  };
  const markerTemplate = [
    '<?xml version="1.0"?>',
    '<svg x="25" y="30" width="26" height="36" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg">',
    '<path fill="{{ color }}" stroke="{{ stroke }}" d="M8 2.1c1.1 0 2.2 0.5 3 1.3 0.8 0.9 1.3 1.9 1.3 3.1s-0.5 2.5-1.3 3.3l-3 3.1-3-3.1c-0.8-0.8-1.3-2-1.3-3.3 0-1.2 0.4-2.2 1.3-3.1 0.8-0.8 1.9-1.3 3-1.3z" />' +
    '<path fill="white" transform="scale(0.0035,0.0035) translate(1400,1000)" d="{{ status }}" />' +
    '</svg>'
  ].join('\n');
  showBreadCrumb = typeof (showBreadCrumb) === 'undefined' ? true : false;

  useEffect(() => {
    (async function getWorkOrders() {
      try {
        setLoading(true);
        setWorkOrders([]);

        const data = await client.query({
          query: GET_WORKORDERS,
          variables: {
            limit: 5000,
            offset: offset,
            where: getFilter('workOrders', filter, state.profile)
          },
          fetchPolicy: 'no-cache'
        });

        const tmp = data.data.workOrders.edges.node.filter((e: any) => e.lat !== null).map((e: any) => {
          let icon = markerTemplate.replace('{{ color }}', e?.user?.color);
          if (e.status === 'completed') {
            icon = icon.replace('{{ stroke }}', COMPLETED).replace('{{ status }}', status.completed);
          } else if (e.status === 'canceled') {
            icon = icon.replace('{{ stroke }}', CANCELED).replace('{{ status }}', status.canceled);
          } else {
            icon = icon.replace('{{ stroke }}', NOT_COMPLETED).replace('{{ status }}', status.notCompleted);
          }

          return { ...e, icon };
        });

        setWorkOrders(tmp);
        setLoading(false);
        DOMHelper.scrollTop(window as any, 0);
      } catch (err) {
        showError(err);
      } finally {
        setLoading(false);
      }
    })();
  }, [offset, filter]);

  // console.log('refresh');

  return <div>
    <Filter
      id='workOrders'
      showJobStatus={true}
      showMultipleCrewFilter={true}
      onChange={(val) => { setFilter(val); setOffset(0); }}
      dateRangeColumns={[
        { startDate: 'Start Date' },
        { endDate: 'End Date' },
        { createdAt: 'Created At' },
        { modifiedAt: 'Modified At' },
        { startEndInclusive: 'Overlaps Start/End' }
      ]}
    >
      <Col md={3} xs={24} style={{ float: 'right', textAlign: 'right' }}>
        <MapToggleField />
        <ExportField />
      </Col>
    </Filter>

    <div style={{ border: '1px solid #dadce0' }}>
      <LoadScriptNext
        id="script-loader"
        googleMapsApiKey={(import.meta as any).env?.VITE_APP_GOOGLE_MAPS_API_KEY || ''}
      >
        <GoogleMap
          id='workorder-map'
          key={workOrders.length}
          mapContainerStyle={{
            height: '79vh',
            width: '100%'
          }}
          zoom={11}
          center={{ lat: 51.0447, lng: -114.0719 }}
          options={{
            controlSize: 26,
            mapTypeId: 'roadmap',
            mapTypeControlOptions: {
              style: 1,
              mapTypeIds: [
                'roadmap',
                'hybrid'
              ]
            }
          }}
        >
          {loading &&
            <OverlayView position={{ lat: 51.0447, lng: -114.0719 }} mapPaneName={OverlayView.OVERLAY_LAYER}>
              <Loader content="Loading markers..." />
            </OverlayView>
          }

          <GoogleMapMarker
            markers={workOrders}
          />
        </GoogleMap>
      </LoadScriptNext>
    </div>
  </div >;
};

export default WorkOrderMap;
